import React, { ReactElement } from 'react';

/**
 * Icon that represents a contract.
 */
const ContractIcon = (): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <title>Contract</title>
    <g clipPath="url(#clip0_9959_3438)">
      <path
        fill="#000"
        fillOpacity=".9"
        fillRule="evenodd"
        d="M17 4.17c.72 0 1.45.28 2 .83 1.1 1.1 1.1 2.9 0 4l-2 2-9 9H4v-4l9-9 2-2c.55-.55 1.28-.83 2-.83Zm.01 2c-.13 0-.38.03-.59.24l-.58.6 1.17 1.17.59-.59c.21-.22.24-.46.24-.59 0-.13-.02-.38-.24-.59a.843.843 0 0 0-.59-.24ZM6 18h1.17l8.41-8.41-1.17-1.17L6 16.83V18Zm6 0h8v2h-8v-2Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="clip0_9959_3438">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default ContractIcon;
