/**
 * An icon that displays an arrow pointing to the upper right corner.
 */

import React, { ReactElement } from 'react';
import BaseSvgProps from 'src/types/BaseSvgProps';

const OpenIcon = (props: BaseSvgProps): ReactElement => (
  <svg
    width="12"
    height="12"
    fill="none"
    viewBox="0 0 12 12"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>Open</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.95.05a1 1 0 0 1 1 1v9.9h-2V3.464l-8.193 8.193-1.414-1.414L8.536 2.05H1.05v-2h9.9Z"
    />
  </svg>
);

export default OpenIcon;
