/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "common": {
        "nested": {
          "MerchantType": {
            "values": {
              "MERCHANT_TYPE_DEFAULT": 0,
              "MERCHANT_TYPE_COMMERCIAL_ENTITY": 1,
              "MERCHANT_TYPE_POLITICAL_ENTITY": 2,
              "MERCHANT_TYPE_COMMERCIAL_ENTITY_WELLS": 3
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.common?.nested?.MerchantType) {
	$root.addJSON(jsonData);
}

/**
 * General types for all Square merchants
 *
 * SOURCE MerchantType @ squareup/common/merchant_type.proto at 9:1
 */
export const MerchantType = $root.lookupEnum('squareup.common.MerchantType').values;
