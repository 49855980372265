import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import { Unit } from 'src/MessengerTypes';
import VoicemailNumberRow from 'src/components/VoicemailNumberRow/VoicemailNumberRow';
import VerificationRow from 'src/components/NumberRow/components/VerificationRow/VerificationRow';

export type NumberRowProps = {
  unit: Unit;
  isPlaying: boolean;
  onPlay: () => void;
  onStop: () => void;
};

/**
 * Component that selects the correct row UI to display for a given number/unit.
 * This component is only expected to be rendered when the M+ feature flag is enabled.
 *
 * @param {Unit} unit
 * The unit to render in the row.
 * @param {boolean} isPlaying
 * Whether the voicemail is currently playing.
 * @param {() => void} onPlay
 * Callback executed when the voicemail is played.
 * @param {() => void} onStop
 * Callback executed when the voicemail is stopped.
 */
const NumberRow = observer(
  ({ unit, isPlaying, onPlay, onStop }: NumberRowProps): ReactElement => {
    const { subscription } = useMessengerControllerContext();

    if (
      subscription.isUnitPendingVerification(unit.token) ||
      subscription.isUnitFailedNonretryableAndNotProhibited(unit.token)
    ) {
      return (
        <VerificationRow
          unitName={unit.name}
          unitToken={unit.token}
          variant="PENDING"
        />
      );
    }

    if (subscription.isUnitRetryableFailure(unit.token)) {
      return (
        <VerificationRow
          unitName={unit.name}
          unitToken={unit.token}
          variant="FAILED_RETRYABLE"
        />
      );
    }

    return (
      <VoicemailNumberRow
        unit={unit}
        isPlaying={isPlaying}
        onPlay={onPlay}
        onStop={onStop}
      />
    );
  },
);

export default NumberRow;
