/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';
import '../common/protocols';
import '../common/location';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "bills": {
        "nested": {
          "ClientDetails": {
            "fields": {
              "headers": {
                "type": "squareup.common.Headers",
                "id": 1,
                "rule": "optional"
              },
              "userAgent": {
                "type": "string",
                "id": 2,
                "rule": "optional"
              },
              "timeZone": {
                "type": "string",
                "id": 3,
                "rule": "optional"
              },
              "geoLocation": {
                "type": "squareup.common.location.GeoLocation",
                "id": 4,
                "rule": "optional"
              },
              "deviceName": {
                "type": "string",
                "id": 5,
                "rule": "optional"
              },
              "deviceId": {
                "type": "string",
                "id": 6,
                "rule": "optional"
              },
              "deviceCredentialToken": {
                "type": "string",
                "id": 12,
                "rule": "optional"
              },
              "deviceCredentialName": {
                "type": "string",
                "id": 13,
                "rule": "optional"
              },
              "connectDetails": {
                "type": "squareup.bills.ClientDetails.ConnectDetails",
                "id": 10,
                "rule": "optional"
              },
              "invoiceDetails": {
                "type": "squareup.bills.ClientDetails.InvoiceDetails",
                "id": 11,
                "rule": "optional"
              },
              "clientIp": {
                "type": "string",
                "id": 7,
                "rule": "optional"
              },
              "developerIp": {
                "type": "string",
                "id": 8,
                "rule": "optional"
              },
              "appId": {
                "type": "string",
                "id": 9,
                "rule": "optional"
              },
              "loggableSessionToken": {
                "type": "string",
                "id": 14,
                "rule": "optional"
              }
            },
            "nested": {
              "ConnectDetails": {
                "fields": {
                  "appId": {
                    "type": "string",
                    "id": 1,
                    "rule": "optional"
                  },
                  "developerIp": {
                    "type": "string",
                    "id": 2,
                    "rule": "optional"
                  },
                  "clientIp": {
                    "type": "string",
                    "id": 3,
                    "rule": "optional"
                  },
                  "customerIp": {
                    "type": "string",
                    "id": 20,
                    "rule": "optional"
                  },
                  "websiteUrl": {
                    "type": "string",
                    "id": 4,
                    "rule": "optional"
                  },
                  "shippingFirstName": {
                    "type": "string",
                    "id": 23,
                    "rule": "optional"
                  },
                  "shippingLastName": {
                    "type": "string",
                    "id": 24,
                    "rule": "optional"
                  },
                  "shippingAddress": {
                    "type": "squareup.common.location.GlobalAddress",
                    "id": 5,
                    "rule": "optional"
                  },
                  "billingFirstName": {
                    "type": "string",
                    "id": 25,
                    "rule": "optional"
                  },
                  "billingLastName": {
                    "type": "string",
                    "id": 26,
                    "rule": "optional"
                  },
                  "billingAddress": {
                    "type": "squareup.common.location.GlobalAddress",
                    "id": 6,
                    "rule": "optional"
                  },
                  "customerEmailAddress": {
                    "type": "string",
                    "id": 7,
                    "rule": "optional"
                  },
                  "customerPhoneNumber": {
                    "type": "string",
                    "id": 22,
                    "rule": "optional"
                  },
                  "customerFirstName": {
                    "type": "string",
                    "id": 29,
                    "rule": "optional"
                  },
                  "customerLastName": {
                    "type": "string",
                    "id": 30,
                    "rule": "optional"
                  },
                  "browserFingerprint": {
                    "type": "squareup.bills.ClientDetails.ConnectDetails.BrowserFingerprint",
                    "id": 8,
                    "rule": "optional"
                  },
                  "browserFingerprintByVersion": {
                    "type": "squareup.bills.ClientDetails.ConnectDetails.BrowserFingerprintByVersion",
                    "id": 19,
                    "rule": "repeated"
                  },
                  "webCheckoutSessionId": {
                    "type": "string",
                    "id": 9,
                    "rule": "optional"
                  },
                  "paysdkClientVersion": {
                    "type": "string",
                    "id": 10,
                    "rule": "optional"
                  },
                  "revenueShare": {
                    "type": "squareup.bills.ClientDetails.ConnectDetails.RevenueShare",
                    "id": 11,
                    "rule": "optional"
                  },
                  "payerHeaders": {
                    "type": "squareup.common.Headers",
                    "id": 12,
                    "rule": "optional"
                  },
                  "avtTrackingCookie": {
                    "type": "string",
                    "id": 13,
                    "rule": "optional"
                  },
                  "tokenizationSource": {
                    "type": "squareup.bills.ClientDetails.ConnectDetails.TokenizationSource",
                    "id": 14,
                    "rule": "optional"
                  },
                  "sourceId": {
                    "type": "string",
                    "id": 27,
                    "rule": "optional"
                  },
                  "tokenizationNonce": {
                    "type": "string",
                    "id": 28,
                    "rule": "optional"
                  },
                  "userAgent": {
                    "type": "string",
                    "id": 16,
                    "rule": "optional"
                  },
                  "timeZone": {
                    "type": "string",
                    "id": 17,
                    "rule": "optional"
                  },
                  "iapRiskSignals": {
                    "type": "squareup.bills.ClientDetails.ConnectDetails.InAppPaymentsRiskSignals",
                    "id": 18,
                    "rule": "optional"
                  },
                  "customerDetailSources": {
                    "type": "squareup.bills.ClientDetails.ConnectDetails.CustomerDetailSources",
                    "id": 21,
                    "rule": "optional"
                  }
                },
                "nested": {
                  "BrowserFingerprint": {
                    "fields": {
                      "fingerprint": {
                        "type": "string",
                        "id": 1,
                        "rule": "optional"
                      },
                      "fingerprintComponentsJson": {
                        "type": "string",
                        "id": 2,
                        "rule": "optional"
                      }
                    }
                  },
                  "BrowserFingerprintByVersion": {
                    "fields": {
                      "payloadType": {
                        "type": "string",
                        "id": 1,
                        "rule": "optional"
                      },
                      "payloadJson": {
                        "type": "string",
                        "id": 2,
                        "rule": "optional"
                      }
                    }
                  },
                  "RevenueShare": {
                    "fields": {
                      "integrationId": {
                        "type": "string",
                        "id": 1,
                        "rule": "optional"
                      },
                      "merchantToken": {
                        "type": "string",
                        "id": 2,
                        "rule": "optional"
                      }
                    }
                  },
                  "TokenizationSource": {
                    "values": {
                      "UNKNOWN": 0,
                      "PAYMENT_FORM": 1,
                      "IN_APP_SDK": 2,
                      "EXTERNAL_SERVICE": 3,
                      "INTERNAL_SERVICE": 4,
                      "WEB_SDK": 5
                    }
                  },
                  "InAppPaymentsRiskSignals": {
                    "fields": {
                      "platform": {
                        "type": "string",
                        "id": 3,
                        "rule": "optional"
                      },
                      "debuggerAttached": {
                        "type": "bool",
                        "id": 1,
                        "rule": "optional"
                      },
                      "publishedToStore": {
                        "type": "bool",
                        "id": 2,
                        "rule": "optional"
                      },
                      "deviceManufacturer": {
                        "type": "string",
                        "id": 4,
                        "rule": "optional"
                      },
                      "deviceModel": {
                        "type": "string",
                        "id": 5,
                        "rule": "optional"
                      },
                      "osVersion": {
                        "type": "string",
                        "id": 6,
                        "rule": "optional"
                      },
                      "timeZone": {
                        "type": "string",
                        "id": 7,
                        "rule": "optional"
                      },
                      "locale": {
                        "type": "string",
                        "id": 8,
                        "rule": "optional"
                      },
                      "screenWidthPixels": {
                        "type": "int32",
                        "id": 9,
                        "rule": "optional"
                      },
                      "screenHeightPixels": {
                        "type": "int32",
                        "id": 10,
                        "rule": "optional"
                      },
                      "screenDensityDpi": {
                        "type": "int32",
                        "id": 11,
                        "rule": "optional"
                      },
                      "screenDensityMultiplier": {
                        "type": "string",
                        "id": 12,
                        "rule": "optional"
                      },
                      "isBatteryCharging": {
                        "type": "bool",
                        "id": 13,
                        "rule": "optional"
                      },
                      "batteryPercentage": {
                        "type": "float",
                        "id": 14,
                        "rule": "optional"
                      },
                      "squareDeviceId": {
                        "type": "string",
                        "id": 15,
                        "rule": "optional"
                      },
                      "bundleOrApplicationId": {
                        "type": "string",
                        "id": 16,
                        "rule": "optional"
                      },
                      "isRealDevice": {
                        "type": "bool",
                        "id": 17,
                        "rule": "optional"
                      },
                      "installerPackageName": {
                        "type": "string",
                        "id": 18,
                        "rule": "optional"
                      },
                      "androidId": {
                        "type": "string",
                        "id": 19,
                        "rule": "optional"
                      },
                      "abi": {
                        "type": "string",
                        "id": 20,
                        "rule": "optional"
                      }
                    }
                  },
                  "CustomerDetailSources": {
                    "fields": {
                      "customerIpSource": {
                        "type": "squareup.bills.ClientDetails.ConnectDetails.CustomerDetailSources.CustomerDetailSource",
                        "id": 1,
                        "rule": "optional"
                      },
                      "shippingAddressSource": {
                        "type": "squareup.bills.ClientDetails.ConnectDetails.CustomerDetailSources.CustomerDetailSource",
                        "id": 2,
                        "rule": "optional"
                      },
                      "billingAddressSource": {
                        "type": "squareup.bills.ClientDetails.ConnectDetails.CustomerDetailSources.CustomerDetailSource",
                        "id": 3,
                        "rule": "optional"
                      },
                      "customerEmailAddressSource": {
                        "type": "squareup.bills.ClientDetails.ConnectDetails.CustomerDetailSources.CustomerDetailSource",
                        "id": 4,
                        "rule": "optional"
                      },
                      "customerPhoneNumberSource": {
                        "type": "squareup.bills.ClientDetails.ConnectDetails.CustomerDetailSources.CustomerDetailSource",
                        "id": 5,
                        "rule": "optional"
                      },
                      "customerFirstNameSource": {
                        "type": "squareup.bills.ClientDetails.ConnectDetails.CustomerDetailSources.CustomerDetailSource",
                        "id": 10,
                        "rule": "optional"
                      },
                      "customerLastNameSource": {
                        "type": "squareup.bills.ClientDetails.ConnectDetails.CustomerDetailSources.CustomerDetailSource",
                        "id": 11,
                        "rule": "optional"
                      },
                      "shippingFirstNameSource": {
                        "type": "squareup.bills.ClientDetails.ConnectDetails.CustomerDetailSources.CustomerDetailSource",
                        "id": 6,
                        "rule": "optional"
                      },
                      "shippingLastNameSource": {
                        "type": "squareup.bills.ClientDetails.ConnectDetails.CustomerDetailSources.CustomerDetailSource",
                        "id": 7,
                        "rule": "optional"
                      },
                      "billingFirstNameSource": {
                        "type": "squareup.bills.ClientDetails.ConnectDetails.CustomerDetailSources.CustomerDetailSource",
                        "id": 8,
                        "rule": "optional"
                      },
                      "billingLastNameSource": {
                        "type": "squareup.bills.ClientDetails.ConnectDetails.CustomerDetailSources.CustomerDetailSource",
                        "id": 9,
                        "rule": "optional"
                      }
                    },
                    "nested": {
                      "CustomerDetailSource": {
                        "values": {
                          "DO_NOT_USE": 0,
                          "EXPLICIT": 1,
                          "NONCE": 2,
                          "VERIFICATION": 3,
                          "DERIVED_CLIENT_IP": 4
                        }
                      }
                    }
                  }
                }
              },
              "InvoiceDetails": {
                "fields": {
                  "clientIp": {
                    "type": "string",
                    "id": 1,
                    "rule": "optional"
                  },
                  "avtTrackingCookie": {
                    "type": "string",
                    "id": 2,
                    "rule": "optional"
                  },
                  "siftScienceDeviceId": {
                    "type": "string",
                    "id": 3,
                    "rule": "optional"
                  },
                  "kountSessionId": {
                    "type": "string",
                    "id": 4,
                    "rule": "optional"
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.bills?.nested?.ClientDetails && !$root.nested?.squareup?.nested?.bills?.nested?.ClientDetails?.nested?.ConnectDetails && !$root.nested?.squareup?.nested?.bills?.nested?.ClientDetails?.nested?.ConnectDetails?.nested?.BrowserFingerprint && !$root.nested?.squareup?.nested?.bills?.nested?.ClientDetails?.nested?.ConnectDetails?.nested?.BrowserFingerprintByVersion && !$root.nested?.squareup?.nested?.bills?.nested?.ClientDetails?.nested?.ConnectDetails?.nested?.RevenueShare && !$root.nested?.squareup?.nested?.bills?.nested?.ClientDetails?.nested?.ConnectDetails?.nested?.TokenizationSource && !$root.nested?.squareup?.nested?.bills?.nested?.ClientDetails?.nested?.ConnectDetails?.nested?.InAppPaymentsRiskSignals && !$root.nested?.squareup?.nested?.bills?.nested?.ClientDetails?.nested?.ConnectDetails?.nested?.CustomerDetailSources && !$root.nested?.squareup?.nested?.bills?.nested?.ClientDetails?.nested?.ConnectDetails?.nested?.CustomerDetailSources?.nested?.CustomerDetailSource && !$root.nested?.squareup?.nested?.bills?.nested?.ClientDetails?.nested?.InvoiceDetails) {
	$root.addJSON(jsonData);
}

/**
 * SOURCE ClientDetails @ squareup/bills/client_details.proto at 13:1
 */
export const ClientDetails = $root.lookupType('squareup.bills.ClientDetails');
fixType(ClientDetails, {
  headers: { required: true },
});
Builder.createAndAttachToType(ClientDetails);
/**
 * ConnectDetails contains additional information provided by external APIs.
 *
 * SOURCE ConnectDetails @ squareup/bills/client_details.proto at 118:3
 */
ClientDetails.ConnectDetails = $root.lookupType('squareup.bills.ClientDetails.ConnectDetails');
fixType(ClientDetails.ConnectDetails);
Builder.createAndAttachToType(ClientDetails.ConnectDetails);
/**
 * A browser fingerprint generated by fingerprintjs2 (https://github.com/Valve/fingerprintjs2)
 *
 * SOURCE BrowserFingerprint @ squareup/bills/client_details.proto at 249:5
 */
ClientDetails.ConnectDetails.BrowserFingerprint = $root.lookupType('squareup.bills.ClientDetails.ConnectDetails.BrowserFingerprint');
fixType(ClientDetails.ConnectDetails.BrowserFingerprint);
Builder.createAndAttachToType(ClientDetails.ConnectDetails.BrowserFingerprint);
/**
 * SOURCE BrowserFingerprintByVersion @ squareup/bills/client_details.proto at 268:5
 */
ClientDetails.ConnectDetails.BrowserFingerprintByVersion = $root.lookupType('squareup.bills.ClientDetails.ConnectDetails.BrowserFingerprintByVersion');
fixType(ClientDetails.ConnectDetails.BrowserFingerprintByVersion);
Builder.createAndAttachToType(ClientDetails.ConnectDetails.BrowserFingerprintByVersion);
/**
 * SOURCE RevenueShare @ squareup/bills/client_details.proto at 299:5
 */
ClientDetails.ConnectDetails.RevenueShare = $root.lookupType('squareup.bills.ClientDetails.ConnectDetails.RevenueShare');
fixType(ClientDetails.ConnectDetails.RevenueShare);
Builder.createAndAttachToType(ClientDetails.ConnectDetails.RevenueShare);
/**
 * List of sources payment data can be tokenized from *
 *
 * SOURCE TokenizationSource @ squareup/bills/client_details.proto at 332:5
 */
ClientDetails.ConnectDetails.TokenizationSource = $root.lookupEnum('squareup.bills.ClientDetails.ConnectDetails.TokenizationSource').values;
/**
 * In-app Payments SDK risk signals
 * Risk signals used by the in-app payments sdk
 * design: https://docs.google.com/document/d/1czXSwq42GqC1rxzSf3tOY7Cg3IeeYXi3UTq54dqbHrY/edit#heading=h.f1i3k2rzpj0m
 * ticket: DFS-1063
 *
 * SOURCE InAppPaymentsRiskSignals @ squareup/bills/client_details.proto at 387:5
 */
ClientDetails.ConnectDetails.InAppPaymentsRiskSignals = $root.lookupType('squareup.bills.ClientDetails.ConnectDetails.InAppPaymentsRiskSignals');
fixType(ClientDetails.ConnectDetails.InAppPaymentsRiskSignals);
Builder.createAndAttachToType(ClientDetails.ConnectDetails.InAppPaymentsRiskSignals);
/**
 * SOURCE CustomerDetailSources @ squareup/bills/client_details.proto at 412:5
 */
ClientDetails.ConnectDetails.CustomerDetailSources = $root.lookupType('squareup.bills.ClientDetails.ConnectDetails.CustomerDetailSources');
fixType(ClientDetails.ConnectDetails.CustomerDetailSources);
Builder.createAndAttachToType(ClientDetails.ConnectDetails.CustomerDetailSources);
/**
 * List of data sources where customer details can originate. *
 *
 * SOURCE CustomerDetailSource @ squareup/bills/client_details.proto at 414:7
 */
ClientDetails.ConnectDetails.CustomerDetailSources.CustomerDetailSource = $root.lookupEnum('squareup.bills.ClientDetails.ConnectDetails.CustomerDetailSources.CustomerDetailSource').values;
/**
 * SOURCE InvoiceDetails @ squareup/bills/client_details.proto at 472:3
 */
ClientDetails.InvoiceDetails = $root.lookupType('squareup.bills.ClientDetails.InvoiceDetails');
fixType(ClientDetails.InvoiceDetails);
Builder.createAndAttachToType(ClientDetails.InvoiceDetails);
