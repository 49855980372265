import React, { ReactNode, ReactElement, useRef, useEffect } from 'react';
import { observer } from 'mobx-react';
import LoadingIndicator from 'src/components/LoadingIndicator/LoadingIndicator';
import StatusBanner from 'src/components/StatusBanner/StatusBanner';
import StatusScreen from 'src/components/StatusScreen/StatusScreen';
import { Status, Transition } from 'src/MessengerTypes';
import './MessengerContent.scss';

export type MessengerContentProps = {
  transitionDirection: Transition;
  isLoading?: boolean;
  children: ReactNode;
  status: Status | null;
  onTransitionEnd?: () => void;
  loadingIndicator?: ReactNode;
};

/**
 * This component is a wrapper around the content to be displayed in
 * the body of the drawer. A page must contain both this and
 * <MessengerHeader>.
 *
 * It does not include the header content (page title, back/close and
 * 'more' buttons).
 *
 * Should only be used within <MessengerBlade></MessengerBlade>
 *
 * @example
 * Basic usage:
 * <MessengerBlade>
 *  <MarketHeader/>
 *  <MessengerContent
 *    transitionDirection="LEFT"
 *    isLoading={false}
 *    status={messenger.status.value}
 *  >
 *    This is the page content!
 *  </MessengerContent>
 * </MessengerBlade>
 *
 * With optional:
 * <MessengerContent
 *   transitionDirection="LEFT"
 *   isLoading={false}
 *   onTransitionEnd{() => { ... }}
 * >
 * @param {boolean} transitionDirection
 * Determines which direction the content slides in from.
 * If 'RIGHT', the content transitions from the right edge
 * of the drawer into the view. If 'LEFT', transition from
 * the left edge of the drawer. The logic is handled in
 * App.tsx.
 * @param {boolean} [isLoading]
 * If true, a loading indicator will be rendered instead
 * of the children.
 * @param {ReactNode} children
 * Stuff to be wrapped by this component.
 * @param {Status | null} status
 * Any status, such as error, that needs to be shown as StatusBanner
 * or StatusScreen.
 * @param {Function} [onTransitionEnd]
 * An optional callback when content have finish transition animation.
 * @param {ReactNode} [loadingIndicator]
 * An optional prop that is shown in place of the default loading spinner.
 * @author klim
 */
const MessengerContent = observer(
  (props: MessengerContentProps): ReactElement => {
    const {
      children,
      transitionDirection,
      isLoading,
      onTransitionEnd,
      status,
      loadingIndicator,
    } = props;

    // Determine transition class
    let className = 'MessengerContent';
    if (transitionDirection === 'RIGHT') {
      className += ' MessengerContent--right';
    } else if (transitionDirection === 'LEFT') {
      className += ' MessengerContent--left';
    }

    // Handle transition end callback
    const ref = useRef<HTMLDivElement>(null);
    useEffect(() => {
      if (onTransitionEnd) {
        const currentRef = ref.current;

        if (currentRef) {
          currentRef.addEventListener('animationend', onTransitionEnd);
        }

        return () => {
          if (currentRef) {
            currentRef.removeEventListener('animationend', onTransitionEnd);
          }
        };
      }
      return undefined;
    }, [onTransitionEnd, ref]);

    let content = children;
    if (isLoading) {
      content = loadingIndicator || <LoadingIndicator />;
    }

    // Handles status/error
    let banner = null;
    if (status?.scope === 'BLADE') {
      if (status.display === 'SCREEN') {
        content = (
          <StatusScreen
            action={status.action?.action}
            actionLabel={status.actionLabel}
          >
            {status.label}
          </StatusScreen>
        );
      } else if (status.display === 'BANNER') {
        banner = (
          <StatusBanner
            label={status.label}
            type={status.type}
            action={status.action}
          />
        );
      }
    }

    return (
      <div data-testid="MessengerContent" className={className} ref={ref}>
        {banner}
        {content}
      </div>
    );
  },
);

export default MessengerContent;
