import React, {
  ReactElement,
  ReactNode,
  useRef,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { observer } from 'mobx-react';
import MessengerModalContext from 'src/components/MessengerModalContext';
import { MarketModalFull } from 'src/components/Market';
import { MessengerModalFullContext } from './context/MessengerModalFullContext';

export type ModalFullLayout = 'regular' | 'wide' | 'fluid';

export type MessengerModalFullProps = {
  close: () => void;
  children: ReactNode;
  layout?: ModalFullLayout;
  className?: string;
};

/**
 * A full screen container that slide ups from the bottom that includes
 * a header with action buttons on the side and the title. This is
 * where the settings page will render on.
 *
 * The <MessengerModalFullHeader /> and <MessengerModalFullContent />
 * components must be direct children of this component; otherwise,
 * they will fail to inherit the host styling of <MarketModalFull />.
 *
 * @example
 * Basic usage:
 * <MessengerModalFull close={settings.close}>
 *  <MessengerModalFullHeader />
 *  <MessengerModalFullContent>
 *      Settings page content
 *  </MessengerModalFullContent>
 * </MessengerModalFull>
 * @param {() => void} close
 * Function to call when close icon is clicked.
 * @param {ReactNode} children
 * Component to render in the body.
 * @param {boolean} [layout]
 * (Optional) The layout for the market modal full. Defaults to regular.
 * @param {string} [className]
 * (Optional) Classname to include on the MarketModalFull element.
 */
const MessengerModalFull = observer(
  ({
    close,
    children,
    layout = 'regular',
    className,
  }: MessengerModalFullProps): ReactElement => {
    // Only load content after modal animation has ended to prevent conflict
    // with other scrolling behaviors.
    // Note that we can simplify this by directly specifying onAnimationEnd
    // function in <MarketModalFull>, but we won't be able to fire the
    // animationEnd event in tests due to Market issues.
    const [isAnimationEnded, setIsAnimationEnded] = useState(false);
    const ref = useRef<HTMLMarketModalFullElement>(null);
    const onAnimationEnd = useCallback(() => setIsAnimationEnded(true), []);
    useEffect(() => {
      const currentRef = ref.current;
      if (currentRef) {
        currentRef.addEventListener('animationend', onAnimationEnd);
        return () =>
          currentRef.removeEventListener('animationend', onAnimationEnd);
      }
      return undefined;
      // TODO (#5429): re-enable eslint rule in the next line, or remove this TODO
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref]);

    return (
      <MessengerModalContext close={close}>
        <MarketModalFull
          ref={ref}
          data-testid="MessengerModalFull"
          layout={layout}
          className={className}
        >
          <MessengerModalFullContext.Provider value={{ isAnimationEnded }}>
            {children}
          </MessengerModalFullContext.Provider>
        </MarketModalFull>
      </MessengerModalContext>
    );
  },
);

export default MessengerModalFull;
