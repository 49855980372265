/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../../Fixes';
import Builder from '../../../../Builder';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "connect": {
        "nested": {
          "v2": {
            "nested": {
              "common": {
                "nested": {
                  "DayOfWeek": {
                    "values": {
                      "SUN": 0,
                      "MON": 1,
                      "TUE": 2,
                      "WED": 3,
                      "THU": 4,
                      "FRI": 5,
                      "SAT": 6
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.common?.nested?.DayOfWeek) {
	$root.addJSON(jsonData);
}

/**
 * --
 * @desc
 * Indicates the specific day  of the week.
 * --
 *
 * SOURCE DayOfWeek @ squareup/connect/v2/common/day_of_week.proto at 15:1
 */
export const DayOfWeek = $root.lookupEnum('squareup.connect.v2.common.DayOfWeek').values;
