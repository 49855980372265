import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import CustomerDetailPage from './pages/CustomerDetailPage/CustomerDetailPage';
import MergeCustomersPage from './pages/MergeCustomersPage/MergeCustomersPage';
import useTransition from './hooks/useTransition';
import { TranscriptMessengerPage } from './MessengerTypes';
import AddCustomerPage from './pages/AddCustomerPage/AddCustomerPage';
import PastAppointmentsPage from 'src/pages/PastAppointmentsPage/PastAppointmentsPage';
import ConversationHistoryPage from 'src/pages/ConversationHistoryPage/ConversationHistoryPage';

/**
 * Component that defines the possible views that are accessible in the tertiary navigation space.
 * i.e. the customer blade content area
 */
const TertiaryAppContent = observer(() => {
  const { navigation } = useMessengerControllerContext();
  const [transitionDirection, setTransitionDirection] = useTransition(
    navigation.tertiary,
  );
  const page = navigation.secondary.currentPage as TranscriptMessengerPage;

  // When the selected transcript changes, set the transition direction to 'NONE' to prevent a transition from being shown
  useEffect(() => {
    setTransitionDirection('NONE');
  }, [page?.transcriptId]);

  switch (navigation.tertiary.currentPageName) {
    case 'CUSTOMER_DETAIL':
      return <CustomerDetailPage transitionDirection={transitionDirection} />;
    case 'CUSTOMER_MERGE_VIEW':
      return <MergeCustomersPage transitionDirection={transitionDirection} />;
    case 'CUSTOMER_ADD':
      return <AddCustomerPage transitionDirection={transitionDirection} />;
    case 'PAST_APPOINTMENTS':
      return <PastAppointmentsPage transitionDirection={transitionDirection} />;
    case 'CONVERSATION_HISTORY':
      return (
        <ConversationHistoryPage transitionDirection={transitionDirection} />
      );
    default:
      return <></>;
  }
});

export default TertiaryAppContent;
