/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../../Fixes';
import Builder from '../../../../Builder';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "connect": {
        "nested": {
          "v2": {
            "nested": {
              "common": {
                "nested": {
                  "SortOrder": {
                    "values": {
                      "DESC": 0,
                      "ASC": 1
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.common?.nested?.SortOrder) {
	$root.addJSON(jsonData);
}

/**
 * --
 * @desc The order (e.g., chronological or alphabetical) in which results from a request are returned.
 * --
 *
 * SOURCE SortOrder @ squareup/connect/v2/common/sort.proto at 17:1
 */
export const SortOrder = $root.lookupEnum('squareup.connect.v2.common.SortOrder').values;
