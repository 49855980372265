import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { Utterance } from 'src/gen/squareup/messenger/v3/messenger_service';
import { formattedEmojis, getUniqueReactions } from './utils';
import './Reactions.scss';

type IReaction = Utterance.Metadata.IReaction;

export type ReactionsProps = {
  reactions: readonly IReaction[];
};

/**
 * Component that renders a list of provided reactions.
 *
 * @example <Reactions reactions={utterance.metadata?.reactions?.reactions} />
 * @param {IReaction[]} reactions
 * The list of reactions to render.
 * @author wdetlor
 */
const Reactions = observer(
  ({ reactions }: ReactionsProps): ReactElement => (
    <div className="Reactions__container">
      <div className="Reactions__popover">
        {getUniqueReactions(reactions).map(
          ({ emoji, textDescription }: IReaction) => (
            <span
              key={emoji}
              className="Reactions__emoji"
              role="img"
              aria-label={textDescription}
            >
              {emoji && formattedEmojis[emoji] ? formattedEmojis[emoji] : emoji}
            </span>
          ),
        )}
        {reactions.length > 1 && reactions.length}
      </div>
    </div>
  ),
);

export default Reactions;
