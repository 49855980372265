import { t } from 'i18next';
import { StateCodeAndDisplay, SupportedCountry } from 'src/MessengerTypes';
import {
  GetUnitsInformationResponse,
  IUnitInformation,
} from 'src/gen/squareup/messenger/v3/messenger_service';
import { IAddress } from 'src/gen/squareup/smsphoneregistry/verification';
import { MPLUS_SUPPORTED_COUNTRIES } from 'src/i18n/countries';
import { US_STATES } from 'src/i18n/usStates';

/**
 * A mapping to convert missing field enum value to translation key (see en.json)
 */
const missingUnitFieldToTranslationKey: Record<
  GetUnitsInformationResponse.FieldForReview,
  string
> = {
  [GetUnitsInformationResponse.FieldForReview.BUSINESS_NAME]:
    'UnitInformationFieldForReview.business_name',
  [GetUnitsInformationResponse.FieldForReview.WEBSITE_URL]:
    'UnitInformationFieldForReview.website',
  [GetUnitsInformationResponse.FieldForReview.ADDRESS]:
    'UnitInformationFieldForReview.business_address',
  [GetUnitsInformationResponse.FieldForReview.CONTACT_FIRST_NAME]:
    'UnitInformationFieldForReview.first_name',
  [GetUnitsInformationResponse.FieldForReview.CONTACT_LAST_NAME]:
    'UnitInformationFieldForReview.last_name',
  [GetUnitsInformationResponse.FieldForReview.CONTACT_EMAIL]:
    'UnitInformationFieldForReview.email',
  [GetUnitsInformationResponse.FieldForReview.CONTACT_PHONE_NUMBER]:
    'UnitInformationFieldForReview.phone_number',
  // Unused
  [GetUnitsInformationResponse.FieldForReview.UNRECOGNIZED]: '',
};

/**
 * Returns a translated title and description for a missing field,
 * for displaying in the <UnitVerificationForm />
 *
 * @param {GetUnitsInformationResponse.FieldForReview} field
 * FieldForReview enum value describing the missing field
 */
export const missingUnitFieldToHeaderAndDescription = (
  field: GetUnitsInformationResponse.FieldForReview,
): { header: string; description: string } => {
  const translationObjKey = missingUnitFieldToTranslationKey[field];

  return {
    header: translationObjKey ? t(`${translationObjKey}.header`) : '',
    description: translationObjKey ? t(`${translationObjKey}.description`) : '',
  };
};

/**
 * Returns the label for the input box for a missing field,
 * for displaying in the <UnitVerificationForm />
 *
 * @param {GetUnitsInformationResponse.FieldForReview} field
 * FieldForReview enum value describing the missing field
 */
export const missingUnitFieldToInputLabel = (
  field: GetUnitsInformationResponse.FieldForReview,
): string => {
  const translationObjKey = missingUnitFieldToTranslationKey[field];
  return translationObjKey ? t(`${translationObjKey}.input_label`) : '';
};

/**
 * Returns the labels for the input boxes for a missing Address field,
 * for displaying in the <UnitVerificationForm />
 *
 * @param {SupportedCountry} country
 * Country to get address labels for. Only US is supported right now.
 */
export const missingAddressToInputLabels = (
  country?: SupportedCountry,
): IAddress => {
  const translationObjKey =
    missingUnitFieldToTranslationKey[
      GetUnitsInformationResponse.FieldForReview.ADDRESS
    ];
  if (!translationObjKey) return {};

  // TODO (teresalin): Add support for other countries as we launch M+.
  const isMPlusSupportedCountry =
    country !== undefined && MPLUS_SUPPORTED_COUNTRIES.includes(country);
  const countryKey = isMPlusSupportedCountry ? `.${country.toLowerCase()}` : '';

  return {
    address1: t(`${translationObjKey}${countryKey}.address1.input_label`),
    address2: t(`${translationObjKey}${countryKey}.address2.input_label`),
    city: t(`${translationObjKey}${countryKey}.city.input_label`),
    state: t(`${translationObjKey}${countryKey}.state.input_label`),
    zipCode: t(`${translationObjKey}${countryKey}.zipCode.input_label`),
  };
};

export const mapFieldForReviewEnumToUnitInformationKey: Record<
  Exclude<
    GetUnitsInformationResponse.FieldForReview,
    GetUnitsInformationResponse.FieldForReview.UNRECOGNIZED
  >,
  keyof IUnitInformation
> = {
  [GetUnitsInformationResponse.FieldForReview.WEBSITE_URL]: 'websiteUrl',
  [GetUnitsInformationResponse.FieldForReview.BUSINESS_NAME]: 'businessName',
  [GetUnitsInformationResponse.FieldForReview.CONTACT_FIRST_NAME]:
    'contactFirstName',
  [GetUnitsInformationResponse.FieldForReview.CONTACT_LAST_NAME]:
    'contactLastName',
  [GetUnitsInformationResponse.FieldForReview.CONTACT_EMAIL]: 'contactEmail',
  [GetUnitsInformationResponse.FieldForReview.CONTACT_PHONE_NUMBER]:
    'contactPhoneNumber',
  [GetUnitsInformationResponse.FieldForReview.ADDRESS]: 'address',
};

/**
 * Gets dropdown options for the "state" field of the Business Address,
 * given the country the user is located in.
 * Only US is supported right now.
 *
 * @param {SupportedCountry} country Country to look up the state names
 * @returns {StateCodeAndDisplay[]} List of states and their display names
 */
export const getStateOptionsForCountry = (
  country: SupportedCountry,
): StateCodeAndDisplay[] => {
  switch (country) {
    case 'US':
      return US_STATES;

    default:
      return [];
  }
};
