/**
 * Default avatar for customer.
 */
import React, { ReactElement } from 'react';

function DefaultCustomerIcon(): ReactElement {
  return (
    <svg width="16" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>Customer</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 4c0-1.1-.9-2-2-2s-2 .9-2 2 .9 2 2 2 2-.9 2-2ZM4 4c0-2.21 1.79-4 4-4s4 1.79 4 4-1.79 4-4 4-4-1.79-4-4Zm10 14h2c0-4.41-3.59-8-8-8s-8 3.59-8 8h2c0-3.31 2.69-6 6-6s6 2.69 6 6Z"
        fill="#000"
        fillOpacity=".55"
      />
    </svg>
  );
}

export default DefaultCustomerIcon;
