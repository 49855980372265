import React, { ReactElement, useState } from 'react';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import NumberRow from 'src/components/NumberRow/NumberRow';

export type NumberRowsContainerProps = {
  maxUnitsToShow?: number;
};

/**
 * Container for the rows of numbers displayed in the business numbers section of
 * the settings page and the business numbers page.
 *
 * @param {number} [maxUnitsToShow]
 * (Optional) The max number of units to include in the outputted list. If not provided, all units will be shown.
 */
const NumberRowsContainer = ({
  maxUnitsToShow,
}: NumberRowsContainerProps): ReactElement => {
  const { user } = useMessengerControllerContext();
  const [selectedUnitToken, setSelectedUnitToken] = useState<string | null>();

  const maxUnitsLength =
    maxUnitsToShow ?? user.unitsWithPendingOrDedicatedNumbers.length;

  return (
    <>
      {user.unitsWithPendingOrDedicatedNumbers
        .slice(0, maxUnitsLength)
        .map((unit) => (
          <NumberRow
            key={unit.token}
            unit={unit}
            isPlaying={selectedUnitToken === unit.token}
            onPlay={() => setSelectedUnitToken(unit.token)}
            onStop={() => setSelectedUnitToken(null)}
          />
        ))}
    </>
  );
};

export default NumberRowsContainer;
