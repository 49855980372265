/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "bills": {
        "nested": {
          "ProcessingMode": {
            "values": {
              "ONLINE": 1,
              "STORE_AND_FORWARD": 2
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.bills?.nested?.ProcessingMode) {
	$root.addJSON(jsonData);
}

/**
 * SOURCE ProcessingMode @ squareup/bills/processing_mode.proto at 7:1
 */
export const ProcessingMode = $root.lookupEnum('squareup.bills.ProcessingMode').values;
