/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../Fixes';
import Builder from '../../../Builder';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "connect": {
        "nested": {
          "v2": {
            "nested": {
              "RiskEvaluation": {
                "fields": {
                  "status": {
                    "type": "string",
                    "id": 1,
                    "rule": "optional"
                  },
                  "level": {
                    "type": "string",
                    "id": 2,
                    "rule": "optional"
                  },
                  "numericScore": {
                    "type": "double",
                    "id": 3,
                    "rule": "optional"
                  },
                  "reasons": {
                    "type": "string",
                    "id": 4,
                    "rule": "repeated"
                  },
                  "rulesFlagged": {
                    "type": "string",
                    "id": 6,
                    "rule": "repeated"
                  },
                  "action": {
                    "type": "string",
                    "id": 7,
                    "rule": "optional"
                  },
                  "createdAt": {
                    "type": "string",
                    "id": 8,
                    "rule": "optional"
                  },
                  "riskLevel": {
                    "type": "squareup.connect.v2.RiskEvaluation.RiskLevel",
                    "id": 9,
                    "rule": "optional"
                  }
                },
                "nested": {
                  "RiskLevel": {
                    "values": {
                      "DO_NOT_USE": 0,
                      "PENDING": 1,
                      "NORMAL": 2,
                      "MODERATE": 3,
                      "HIGH": 4
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.RiskEvaluation && !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.RiskEvaluation?.nested?.RiskLevel) {
	$root.addJSON(jsonData);
}

/**
 * --
 * @desc Represents fraud risk information for the associated payment.
 *
 * When you take a payment through Square's Payments API (using the `CreatePayment`
 * endpoint), Square evaluates it and assigns a risk level to the payment. Sellers
 * can use this information to determine the course of action (for example,
 * provide the goods/services or refund the payment).
 * --
 *
 * SOURCE RiskEvaluation @ squareup/riskevaluation/external/risk_evaluation.proto at 19:1
 */
export const RiskEvaluation = $root.lookupType('squareup.connect.v2.RiskEvaluation');
fixType(RiskEvaluation, {
  status: { required: true },
});
Builder.createAndAttachToType(RiskEvaluation);
/**
 * Combined enum of the internal Status and Level fields above
 *
 * SOURCE RiskLevel @ squareup/riskevaluation/external/risk_evaluation.proto at 61:3
 */
RiskEvaluation.RiskLevel = $root.lookupEnum('squareup.connect.v2.RiskEvaluation.RiskLevel').values;
