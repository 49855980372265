import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import CustomerEventCard from 'src/pages/TranscriptViewPage/components/CustomerEventCard/CustomerEventCard';
import CallIcon from 'src/svgs/CallIcon';
import { Utterance } from 'src/gen/squareup/messenger/v3/messenger_service';
import { BaseContextualEventCardProps } from 'src/types/BaseContextualEventCardProps';
import './MissedCallEventCard.scss';

/**
 * Card used in the list of utterances to represent a missed call.
 */
const MissedCallEventCard = observer(
  ({
    item,
    customerImage,
    cardRef,
  }: BaseContextualEventCardProps): ReactElement => {
    const { t } = useTranslation();

    const subtitle =
      (item.data as Utterance.Metadata.InboundCall).missedCallAction ===
      Utterance.Metadata.InboundCall.MissedCallAction.REQUESTED_TEXT
        ? t('ContextualEvent.missedCall.requested_text')
        : undefined;

    return (
      <CustomerEventCard
        className="MissedCallEventCard"
        timestampMillis={item.timestampMillis}
        icon={<CallIcon />}
        title={t('ContextualEvent.missedCall.title')}
        subtitle={subtitle}
        customerImage={customerImage}
        cardRef={cardRef}
        id={item.attachedUtterance?.utterance.id}
      />
    );
  },
);

export default MissedCallEventCard;
