/**
 * An icon for Square Go
 */
import React, { ReactElement } from 'react';

function SquareGoIcon(): ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 180 180">
      <title>Square Go</title>
      <g clipPath="url(#SquareGoIcon__a)">
        <g clipPath="url(#SquareGoIcon__b)">
          <path fill="url(#SquareGoIcon__c)" d="M0 0h180v180H0z" />
          <g filter="url(#SquareGoIcon__d)">
            <circle cx="165.234" cy="119.18" r="70.664" fill="#70A0FF" />
          </g>
          <g filter="url(#SquareGoIcon__e)">
            <circle cx="157.676" cy="15.645" r="74.356" fill="#6F6FBB" />
          </g>
          <g filter="url(#SquareGoIcon__f)">
            <circle cx="176.396" cy="3.428" r="38.584" fill="#749BFF" />
          </g>
          <g filter="url(#SquareGoIcon__g)">
            <circle
              cx="2.373"
              cy="55.107"
              r="127.002"
              fill="#FDB1F9"
              fillOpacity=".6"
            />
          </g>
          <g filter="url(#SquareGoIcon__h)">
            <circle cx="77.344" cy="164.355" r="106.523" fill="#352477" />
          </g>
          <g filter="url(#SquareGoIcon__i)">
            <circle cx="-1.582" cy="180" r="34.629" fill="#0F0047" />
          </g>
          <g filter="url(#SquareGoIcon__j)">
            <circle cx="-.088" cy=".088" r="52.822" fill="#FDB1F9" />
          </g>
          <g filter="url(#SquareGoIcon__k)">
            <circle
              cx="99.932"
              cy="87.627"
              r="27.334"
              fill="#352477"
              fillOpacity=".3"
            />
          </g>
          <g filter="url(#SquareGoIcon__l)">
            <path
              fill="url(#SquareGoIcon__m)"
              fillRule="evenodd"
              d="M33.047 90c0-31.454 25.499-56.953 56.953-56.953h45.563c6.29 0 11.39 5.1 11.39 11.39V90c0 31.454-25.499 56.953-56.953 56.953-31.454 0-56.953-25.499-56.953-56.953Zm61.51-41.006A4.556 4.556 0 0 0 90 53.55v31.894A4.556 4.556 0 0 0 94.556 90h31.894a4.556 4.556 0 0 0 4.556-4.556V53.55a4.556 4.556 0 0 0-4.556-4.556H94.556Z"
              clipRule="evenodd"
            />
          </g>
          <g
            fillRule="evenodd"
            clipRule="evenodd"
            filter="url(#SquareGoIcon__n)"
          >
            <path
              fill="url(#SquareGoIcon__o)"
              fillOpacity=".2"
              d="M33.047 90c0-31.454 25.499-56.953 56.953-56.953h45.563c6.29 0 11.39 5.1 11.39 11.39V90c0 31.454-25.499 56.953-56.953 56.953-31.454 0-56.953-25.499-56.953-56.953Zm61.51-41.006A4.556 4.556 0 0 0 90 53.55v31.894A4.556 4.556 0 0 0 94.556 90h31.894a4.556 4.556 0 0 0 4.556-4.556V53.55a4.556 4.556 0 0 0-4.556-4.556H94.556Z"
            />
            <path
              fill="url(#SquareGoIcon__p)"
              d="M33.047 90c0-31.454 25.499-56.953 56.953-56.953h45.563c6.29 0 11.39 5.1 11.39 11.39V90c0 31.454-25.499 56.953-56.953 56.953-31.454 0-56.953-25.499-56.953-56.953Zm61.51-41.006A4.556 4.556 0 0 0 90 53.55v31.894A4.556 4.556 0 0 0 94.556 90h31.894a4.556 4.556 0 0 0 4.556-4.556V53.55a4.556 4.556 0 0 0-4.556-4.556H94.556Z"
            />
          </g>
        </g>
      </g>
      <defs>
        <filter
          id="SquareGoIcon__d"
          width="352.266"
          height="352.266"
          x="-10.898"
          y="-56.953"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur
            result="effect1_foregroundBlur_602_1080"
            stdDeviation="52.734"
          />
        </filter>
        <filter
          id="SquareGoIcon__e"
          width="359.648"
          height="359.648"
          x="-22.148"
          y="-164.18"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur
            result="effect1_foregroundBlur_602_1080"
            stdDeviation="52.734"
          />
        </filter>
        <filter
          id="SquareGoIcon__f"
          width="288.105"
          height="288.105"
          x="32.344"
          y="-140.625"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur
            result="effect1_foregroundBlur_602_1080"
            stdDeviation="52.734"
          />
        </filter>
        <filter
          id="SquareGoIcon__g"
          width="464.941"
          height="464.941"
          x="-230.098"
          y="-177.363"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur
            result="effect1_foregroundBlur_602_1080"
            stdDeviation="52.734"
          />
        </filter>
        <filter
          id="SquareGoIcon__h"
          width="455.146"
          height="455.146"
          x="-150.229"
          y="-63.217"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur
            result="effect1_foregroundBlur_602_1080"
            stdDeviation="60.525"
          />
        </filter>
        <filter
          id="SquareGoIcon__i"
          width="202.852"
          height="202.852"
          x="-103.008"
          y="78.574"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur
            result="effect1_foregroundBlur_602_1080"
            stdDeviation="33.398"
          />
        </filter>
        <filter
          id="SquareGoIcon__j"
          width="246.27"
          height="246.27"
          x="-123.223"
          y="-123.047"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur
            result="effect1_foregroundBlur_602_1080"
            stdDeviation="35.156"
          />
        </filter>
        <filter
          id="SquareGoIcon__k"
          width="160.137"
          height="160.137"
          x="19.863"
          y="7.559"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur
            result="effect1_foregroundBlur_602_1080"
            stdDeviation="26.367"
          />
        </filter>
        <filter
          id="SquareGoIcon__l"
          width="222.188"
          height="249.118"
          x="-21.094"
          y="29.144"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="2.651" />
          <feGaussianBlur stdDeviation="1.318" />
          <feColorMatrix values="0 0 0 0 0.101961 0 0 0 0 0.0862745 0 0 0 0 0.184314 0 0 0 0.06 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_602_1080"
          />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="13.675" />
          <feGaussianBlur stdDeviation="8.789" />
          <feColorMatrix values="0 0 0 0 0.101961 0 0 0 0 0.0862745 0 0 0 0 0.184314 0 0 0 0.06 0" />
          <feBlend
            in2="effect1_dropShadow_602_1080"
            result="effect2_dropShadow_602_1080"
          />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="28.168" />
          <feGaussianBlur stdDeviation="10.547" />
          <feColorMatrix values="0 0 0 0 0.101961 0 0 0 0 0.0862745 0 0 0 0 0.184314 0 0 0 0.08 0" />
          <feBlend
            in2="effect2_dropShadow_602_1080"
            result="effect3_dropShadow_602_1080"
          />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="77.168" />
          <feGaussianBlur stdDeviation="27.07" />
          <feColorMatrix values="0 0 0 0 0.101961 0 0 0 0 0.0862745 0 0 0 0 0.184314 0 0 0 0.15 0" />
          <feBlend
            in2="effect3_dropShadow_602_1080"
            result="effect4_dropShadow_602_1080"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect4_dropShadow_602_1080"
            result="shape"
          />
        </filter>
        <filter
          id="SquareGoIcon__n"
          width="113.906"
          height="114.258"
          x="33.047"
          y="33.047"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy=".352" />
          <feGaussianBlur stdDeviation="8.789" />
          <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.2 0" />
          <feBlend in2="shape" result="effect1_innerShadow_602_1080" />
        </filter>
        <linearGradient
          id="SquareGoIcon__m"
          x1="146.953"
          x2="-7.734"
          y1="33.047"
          y2="198.809"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" stopOpacity=".98" />
          <stop offset="1" stopColor="#fff" stopOpacity=".75" />
        </linearGradient>
        <linearGradient
          id="SquareGoIcon__o"
          x1="87.188"
          x2="52.383"
          y1="95.801"
          y2="157.676"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6324FF" stopOpacity="0" />
          <stop offset="1" stopColor="#4324FF" />
        </linearGradient>
        <linearGradient
          id="SquareGoIcon__p"
          x1="152.314"
          x2="41.133"
          y1="13.623"
          y2="149.854"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" stopOpacity="0" />
          <stop offset="1" stopColor="#fff" stopOpacity=".1" />
        </linearGradient>
        <clipPath id="SquareGoIcon__a">
          <rect width="180" height="180" fill="#fff" rx="15%" />
        </clipPath>
        <clipPath id="SquareGoIcon__b">
          <path fill="#fff" d="M0 0h180v180H0z" />
        </clipPath>
        <radialGradient
          id="SquareGoIcon__c"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(-50.62572 289.86322 -347.84355 -60.75221 86.134 90)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".427" stopColor="#9893D1" />
          <stop offset=".542" stopColor="#785999" />
          <stop offset=".823" stopColor="#6664AD" />
          <stop offset="1" stopColor="#342B5F" />
        </radialGradient>
      </defs>
    </svg>
  );
}

export default SquareGoIcon;
