/**
 * This icon is used to send a message. It appears in the input
 * bar on the Conversation View page.
 */

import React, { ReactElement } from 'react';
import BaseSvgProps from 'src/types/BaseSvgProps';

function SendIcon(props: BaseSvgProps): ReactElement {
  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>Send</title>
      <path
        d="M20 8L0 0L2 8L0 16L20 8ZM3.81 9H12.11L2.88 12.69L3.81 9ZM12.11 7H3.81L2.89 3.31L12.11 7Z"
        fill="currentColor"
        fillOpacity="0.9"
      />
    </svg>
  );
}

export default SendIcon;
