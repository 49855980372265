/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../Fixes';
import Builder from '../../../Builder';
import '../../common/time';
import '../../connect/v2/resources/address';
import '../bills/tender';
import '../../simple_instrument_store/model';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "client": {
        "nested": {
          "orders": {
            "nested": {
              "OrderCustomer": {
                "fields": {
                  "contactToken": {
                    "type": "string",
                    "id": 1,
                    "rule": "optional"
                  },
                  "displayName": {
                    "type": "string",
                    "id": 2,
                    "rule": "optional"
                  },
                  "givenName": {
                    "type": "string",
                    "id": 3,
                    "rule": "optional"
                  },
                  "surname": {
                    "type": "string",
                    "id": 4,
                    "rule": "optional"
                  },
                  "phoneticGivenName": {
                    "type": "string",
                    "id": 5,
                    "rule": "optional"
                  },
                  "phoneticSurname": {
                    "type": "string",
                    "id": 6,
                    "rule": "optional"
                  },
                  "birthday": {
                    "type": "squareup.common.time.YearMonthDay",
                    "id": 7,
                    "rule": "optional"
                  },
                  "emailAddress": {
                    "type": "string",
                    "id": 8,
                    "rule": "optional"
                  },
                  "maskedEmailAddress": {
                    "type": "string",
                    "id": 9,
                    "rule": "optional"
                  },
                  "phoneNumber": {
                    "type": "string",
                    "id": 10,
                    "rule": "optional"
                  },
                  "maskedPhoneNumber": {
                    "type": "string",
                    "id": 11,
                    "rule": "optional"
                  },
                  "groups": {
                    "type": "squareup.client.orders.Group",
                    "id": 12,
                    "rule": "repeated"
                  },
                  "taxId": {
                    "type": "string",
                    "id": 13,
                    "rule": "optional"
                  },
                  "address": {
                    "type": "squareup.connect.v2.resources.Address",
                    "id": 14,
                    "rule": "optional"
                  },
                  "maskedAddress": {
                    "type": "string",
                    "id": 15,
                    "rule": "optional"
                  },
                  "companyName": {
                    "type": "string",
                    "id": 16,
                    "rule": "optional"
                  },
                  "memo": {
                    "type": "string",
                    "id": 17,
                    "rule": "optional"
                  },
                  "referenceId": {
                    "type": "string",
                    "id": 18,
                    "rule": "optional"
                  },
                  "timeZone": {
                    "type": "string",
                    "id": 19,
                    "rule": "optional"
                  },
                  "cardsOnFile": {
                    "type": "squareup.client.orders.CardOnFile",
                    "id": 20,
                    "rule": "repeated"
                  },
                  "emailId": {
                    "type": "string",
                    "id": 21,
                    "rule": "optional"
                  },
                  "phoneId": {
                    "type": "string",
                    "id": 22,
                    "rule": "optional"
                  },
                  "loyaltyData": {
                    "type": "squareup.client.orders.LoyaltyData",
                    "id": 23,
                    "rule": "optional"
                  },
                  "customerDetails": {
                    "type": "squareup.client.orders.OrderCustomerDetails",
                    "id": 24,
                    "keyType": "string"
                  }
                }
              },
              "LoyaltyData": {
                "fields": {
                  "loyaltyPhoneToken": {
                    "type": "string",
                    "id": 1,
                    "rule": "optional"
                  },
                  "loyaltyAccountToken": {
                    "type": "string",
                    "id": 2,
                    "rule": "optional"
                  },
                  "loyaltyBalance": {
                    "type": "int64",
                    "id": 3,
                    "rule": "optional"
                  },
                  "loyaltyEnrollmentDate": {
                    "type": "squareup.common.time.DateTime",
                    "id": 4,
                    "rule": "optional"
                  }
                }
              },
              "Group": {
                "fields": {
                  "groupToken": {
                    "type": "string",
                    "id": 1,
                    "rule": "optional"
                  },
                  "groupName": {
                    "type": "string",
                    "id": 2,
                    "rule": "optional"
                  }
                }
              },
              "CardOnFile": {
                "fields": {
                  "cardToken": {
                    "type": "string",
                    "id": 1,
                    "rule": "optional"
                  },
                  "cardholderName": {
                    "type": "string",
                    "id": 2,
                    "rule": "optional"
                  },
                  "cardBrand": {
                    "type": "squareup.client.bills.CardTender.Card.Brand",
                    "id": 3,
                    "rule": "optional"
                  },
                  "panSuffix": {
                    "type": "string",
                    "id": 4,
                    "rule": "optional"
                  },
                  "expiryDate": {
                    "type": "squareup.simple_instrument_store.ExpirationDate",
                    "id": 5,
                    "rule": "optional"
                  }
                }
              },
              "OrderCustomerDetails": {
                "fields": {
                  "contactToken": {
                    "type": "string",
                    "id": 1,
                    "rule": "optional"
                  },
                  "displayName": {
                    "type": "string",
                    "id": 2,
                    "rule": "optional"
                  },
                  "givenName": {
                    "type": "string",
                    "id": 3,
                    "rule": "optional"
                  },
                  "surname": {
                    "type": "string",
                    "id": 4,
                    "rule": "optional"
                  },
                  "emailAddress": {
                    "type": "string",
                    "id": 5,
                    "rule": "optional"
                  },
                  "maskedEmailAddress": {
                    "type": "string",
                    "id": 6,
                    "rule": "optional"
                  },
                  "phoneNumber": {
                    "type": "string",
                    "id": 7,
                    "rule": "optional"
                  },
                  "maskedPhoneNumber": {
                    "type": "string",
                    "id": 8,
                    "rule": "optional"
                  },
                  "address": {
                    "type": "squareup.connect.v2.resources.Address",
                    "id": 9,
                    "rule": "optional"
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.client?.nested?.orders?.nested?.OrderCustomer && !$root.nested?.squareup?.nested?.client?.nested?.orders?.nested?.LoyaltyData && !$root.nested?.squareup?.nested?.client?.nested?.orders?.nested?.Group && !$root.nested?.squareup?.nested?.client?.nested?.orders?.nested?.CardOnFile && !$root.nested?.squareup?.nested?.client?.nested?.orders?.nested?.OrderCustomerDetails) {
	$root.addJSON(jsonData);
}

/**
 * SOURCE OrderCustomer @ squareup/client/orders/order-customer.proto at 14:1
 */
export const OrderCustomer = $root.lookupType('squareup.client.orders.OrderCustomer');
fixType(OrderCustomer, {
  contactToken: { required: true },
});
Builder.createAndAttachToType(OrderCustomer);
/**
 * The loyalty data for the customer, if any, are stored as entries in Contact.app_fields,
 * and are differentiated by having unique values for their name fields (link), which are provided below
 *
 * SOURCE LoyaltyData @ squareup/client/orders/order-customer.proto at 89:1
 */
export const LoyaltyData = $root.lookupType('squareup.client.orders.LoyaltyData');
fixType(LoyaltyData);
Builder.createAndAttachToType(LoyaltyData);
/**
 * SOURCE Group @ squareup/client/orders/order-customer.proto at 103:1
 */
export const Group = $root.lookupType('squareup.client.orders.Group');
fixType(Group);
Builder.createAndAttachToType(Group);
/**
 * SOURCE CardOnFile @ squareup/client/orders/order-customer.proto at 111:1
 */
export const CardOnFile = $root.lookupType('squareup.client.orders.CardOnFile');
fixType(CardOnFile, {
  cardToken: { notEmpty: true },
});
Builder.createAndAttachToType(CardOnFile);
/**
 * Contains sparse data of the OrderCustomer
 *
 * SOURCE OrderCustomerDetails @ squareup/client/orders/order-customer.proto at 131:1
 */
export const OrderCustomerDetails = $root.lookupType('squareup.client.orders.OrderCustomerDetails');
fixType(OrderCustomerDetails, {
  contactToken: { required: true },
});
Builder.createAndAttachToType(OrderCustomerDetails);
