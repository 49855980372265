/**
 * A magnifying glass icon to use to display search.
 */
import React, { ReactElement } from 'react';

function SearchIcon(): ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none">
      <title>Search</title>
      <path
        d="M7.502 14.498c1.57 0 3.02-.53 4.18-1.4l4.11 4.11 1.41-1.41-4.11-4.11c.88-1.17 1.4-2.61 1.4-4.18 0-3.86-3.14-7-7-7s-7 3.14-7 7 3.15 6.99 7.01 6.99Zm0-12c2.76 0 5 2.24 5 5s-2.24 5-5 5-5-2.24-5-5 2.24-5 5-5Z"
        fill="#000"
        fillOpacity=".9"
      />
    </svg>
  );
}

export default SearchIcon;
