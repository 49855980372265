import React, { ReactElement } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { MarketRow } from 'src/components/Market';
import { ASSISTANT_ON_APPOINTMENTS_URL } from 'src/utils/url';
import { onKeyDownEnter } from 'src/utils/keyboardUtils';
import AssistantIcon from 'src/svgs/AssistantIcon';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import './AssistantComponent.scss';

type AssistantComponentProps = {
  isAssistantPage: boolean;
};

/**
 * UI for the assistant section of the transcripts list.
 *
 * @param {boolean} isAssistantPage
 * Flag indicating if this is the assistant page or not
 */
const AssistantComponent = ({
  isAssistantPage,
}: AssistantComponentProps): ReactElement => {
  const { t } = useTranslation();
  const { navigation, event } = useMessengerControllerContext();
  if (isAssistantPage) {
    return (
      <div
        className="AssistantComponent__assistant-description"
        data-testid="AssistantComponent__assistant-description"
      >
        <Trans
          i18nKey="AssistantTranscriptsListPage.description"
          components={{
            1: (
              <a
                href={ASSISTANT_ON_APPOINTMENTS_URL}
                target="_blank"
                rel="noreferrer noopener"
                className="AssistantComponent__link"
                onClick={() => event.track('Click Assistant Link')}
              />
            ),
          }}
        />
      </div>
    );
  }
  const onClick = (): void =>
    navigation.primary.navigateTo('ASSISTANT_TRANSCRIPTS_LIST');
  return (
    <MarketRow
      className="AssistantComponent__assistant-button"
      data-testid="AssistantComponent__assistant-button"
      onClick={onClick}
      onKeyDown={(e) => onKeyDownEnter(e, onClick)}
      variant="drill"
      tabIndex={0}
      interactive
    >
      <div slot="label">
        <AssistantIcon />
        <span className="AssistantComponent__assistant-button-text">
          {t('AssistantTranscriptsListPage.title')}
        </span>
      </div>
    </MarketRow>
  );
};

export default AssistantComponent;
