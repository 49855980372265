import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import { Medium } from 'src/gen/squareup/messenger/v3/messenger_service';
import {
  MarketAccessory,
  MarketButton,
  MarketRow,
} from 'src/components/Market';
import AddCustomerIcon from 'src/svgs/AddCustomerIcon';
import GeneralEventBanner from 'src/pages/TranscriptViewPage/components/GeneralEventBanner/GeneralEventBanner';

/**
 * A banner to display in orphaned transcript in blade view, to prompt the user to
 * add the customer to directory.
 *
 * @author klim
 */
const AddCustomerBanner = observer((): ReactElement | null => {
  const { transcriptView, modal, event } = useMessengerControllerContext();
  const { t } = useTranslation();
  const { transcript } = transcriptView;

  return (
    <GeneralEventBanner>
      <MarketRow data-testid="AddCustomerBanner">
        <MarketAccessory slot="leading-accessory" size="icon">
          <AddCustomerIcon />
        </MarketAccessory>
        <label slot="label">
          {transcript.displayName.maybeName
            ? transcript.displayName.maybeName
            : t('AddCustomerBanner.title')}
        </label>
        <MarketButton
          slot="trailing-accessory"
          onClick={() => {
            event.track('Click New Customer Banner Add to Directory');
            modal.openCreateCustomerSuggestionModal({
              firstName: transcript.displayName.maybeFirstName,
              lastName: transcript.displayName.maybeLastName,
              phoneNumber:
                transcript.medium === Medium.SMS
                  ? transcript.contactId
                  : undefined,
              email:
                transcript.medium === Medium.EMAIL
                  ? transcript.contactId
                  : undefined,
            });
          }}
        >
          {t('AddCustomerPage.add')}
        </MarketButton>
      </MarketRow>
    </GeneralEventBanner>
  );
});

export default AddCustomerBanner;
