import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { MarketButton } from 'src/components/Market';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import { MAX_RECORDING_DURATION_MILLISECONDS } from 'src/stores/SoundRecordingStore';
import SoundControlsContainer from 'src/components/SoundControlsContainer/SoundControlsContainer';
import StopIcon from 'src/svgs/StopIcon';
import MicrophoneIcon from 'src/svgs/MicrophoneIcon';
import {
  MessagesMicrophonePermissionDeniedError,
  MessagesMissingMicrophoneError,
} from 'src/types/Errors';
import {
  formatDurationTime,
  MILLISECONDS_TO_SECONDS_DIVISOR,
} from 'src/utils/timeUtils';
import Logger from 'src/Logger';
import './SoundRecorder.scss';

export type SoundRecorderProps = {
  onRecordingEnd?: (blob: Blob) => void;
  isLoading?: boolean;
  onStartRecording?: () => void;
  errorText?: string;
};

/**
 * Component responsible for rendering sound recording controls.
 *
 * @param {(Blob) => void} [onRecordingEnd]
 * Callback executed when the recording ends.
 * @param {boolean} [isLoading]
 * Flag indicating if the recorder should appear in the loading state.
 * @param {boolean} [onStartRecording]
 * Callback executed when the 'Start recording' button is clicked.
 * @param {string} [errorText]
 * Error text to be displayed.
 */
const SoundRecorder = observer(
  ({
    onRecordingEnd,
    isLoading,
    onStartRecording,
    errorText: customErrorText,
  }: SoundRecorderProps): ReactElement => {
    const { soundRecording } = useMessengerControllerContext();
    const { t } = useTranslation();
    const [errorText, setErrorText] = useState<string | null>(null);
    const recordingBarRef = useRef<HTMLDivElement>(null);
    const [recordingBarWidth, setRecordingBarWidth] = useState<number>(0);

    useEffect(() => {
      return () => {
        if (soundRecording.isRecording) {
          soundRecording.stop();
        }
        soundRecording.reset();
      };
    }, [soundRecording]);

    useEffect(() => {
      const resizeObserver = new ResizeObserver((entries) => {
        entries.forEach((entry) => {
          setRecordingBarWidth(entry.target.clientWidth);
        });
      });
      if (recordingBarRef?.current) {
        resizeObserver.observe(recordingBarRef.current);
      }

      return () => {
        resizeObserver.disconnect();
      };
    }, []);

    const handleStartRecording = async (): Promise<void> => {
      setErrorText(null);
      onStartRecording?.();
      try {
        await soundRecording.start(onRecordingEnd);
      } catch (error) {
        if (error instanceof MessagesMicrophonePermissionDeniedError) {
          setErrorText(t('SoundRecorder.microphone_access_denied_error'));
          return;
        }
        if (error instanceof MessagesMissingMicrophoneError) {
          setErrorText(t('SoundRecorder.missing_microphone_error'));
          return;
        }
        Logger.logWithSentry(
          'SoundRecorder - Unexpected error when starting the recording.',
          'error',
          { error },
        );
        setErrorText(t('common.error.generic'));
      }
    };
    const handleStopRecording = async (): Promise<void> => {
      try {
        const audioBlob: Blob = await soundRecording.stop();
        onRecordingEnd?.(audioBlob);
      } catch (error) {
        Logger.logWithSentry(
          'SoundRecorder - Unexpected error when stopping the recording.',
          'error',
          { error },
        );
        setErrorText(t('common.error.generic'));
      }
    };

    const recordingProgressWidth =
      (soundRecording.runTime / MAX_RECORDING_DURATION_MILLISECONDS) *
      recordingBarWidth;

    const isError = Boolean(customErrorText || errorText);

    return (
      <SoundControlsContainer
        isError={isError}
        errorText={customErrorText || errorText || ''}
        className="SoundRecorder__container"
      >
        <MarketButton
          className="SoundRecorder__primary-button"
          onClick={
            soundRecording.isRecording
              ? handleStopRecording
              : handleStartRecording
          }
          rank="tertiary"
          size="small"
          isLoading={isLoading || undefined}
        >
          {soundRecording.isRecording ? (
            <StopIcon slot="icon" />
          ) : (
            <MicrophoneIcon slot="icon" />
          )}
        </MarketButton>
        <div className="SoundRecorder__audio-bar" ref={recordingBarRef}>
          <span
            className={classNames('SoundRecorder__audio-progress', {
              'SoundRecorder__audio-is-recording':
                soundRecording.isRecording && recordingProgressWidth > 1,
            })}
            style={{ width: `${recordingProgressWidth}px` }}
          />
        </div>
        <p className="SoundRecorder__time paragraph-20">
          {formatDurationTime(
            (MAX_RECORDING_DURATION_MILLISECONDS - soundRecording.runTime) /
              MILLISECONDS_TO_SECONDS_DIVISOR,
          )}
        </p>
      </SoundControlsContainer>
    );
  },
);

export default SoundRecorder;
