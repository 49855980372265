import { observer } from 'mobx-react';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputSearchDropdown, {
  InputSearchDropdownItem,
} from 'src/components/InputSearchDropdown/InputSearchDropdown';
import { MarketLink } from 'src/components/Market';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import './AddressAutocompleteInput.scss';

const INPUT_DELAY = 200; // in ms

export type AddressAutocompleteInputProps = {
  setUseManualAddress: () => void;
};

/**
 * An input that searches google maps api for place prediction and list down all
 * the suggestions. Upon selecting a suggestion, set unitInformation's address
 * in the unit verifcation store.
 *
 * @param {Function} setUseManualAddress
 * A function to show all the address inputs instead of using autocomplete.
 * @author klim
 */
const AddressAutocompleteInput = observer(
  ({ setUseManualAddress }: AddressAutocompleteInputProps): ReactElement => {
    const { api, unitVerification } = useMessengerControllerContext();
    const { getPlacePredictions } = api.google;
    const { setAddressFromGooglePlaceId, setUnitInformation, errors } =
      unitVerification;
    const { t } = useTranslation();

    const [value, setValue] = useState('');
    const [results, setResults] = useState<InputSearchDropdownItem[]>([]);

    const search = async (query: string): Promise<void> => {
      const places = await getPlacePredictions(query, []);
      const items = places.map((place) => {
        const fullAddress = `${place.name}, ${place.address}`;
        return { id: place.placeId, name: fullAddress };
      });
      setResults(items);
    };

    const onSelect = (id: string, name: string): void => {
      setResults([]);
      setAddressFromGooglePlaceId(id, name);
    };

    const nullItem = (
      <MarketLink
        className="AddressAutocompleteInput__link"
        onClick={() => {
          setUseManualAddress();
          setUnitInformation(
            {
              unitInfoKey: 'address',
              addressKey: 'address1',
            },
            value,
          );
        }}
      >
        {t('AddressAutocompleteInput.enter_manually')}
      </MarketLink>
    );

    return (
      <InputSearchDropdown
        query={value}
        onQueryChange={setValue}
        label={t(
          'UnitInformationFieldForReview.business_address.us.address1.input_label',
        )}
        search={search}
        items={results}
        onSelect={onSelect}
        inputDelay={INPUT_DELAY}
        nullItem={nullItem}
        errorText={
          errors.address
            ? t('UnitVerificationForm.validation_errors.business_address')
            : undefined
        }
      />
    );
  },
);

export default AddressAutocompleteInput;
