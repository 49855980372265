import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import './InputPhotoCard.scss';
import { LocalFile } from 'src/MessengerTypes';
import RemovePhotoIcon from 'src/svgs/RemovePhotoIcon';

export type InputAttachmentCardProps = {
  file: LocalFile;
  onRemove: (file: LocalFile) => void;
};

/**
 * A card that renders the photo thumbnail in the input bar, representing an
 * image pending upload when the message is sent.
 *
 * @param {LocalFile} file
 * Photo file to render.
 * @param {Function} onRemove
 * Function to call when 'X' is clicked.
 * @author klim
 */
const InputPhotoCard = observer(
  (props: InputAttachmentCardProps): ReactElement => {
    const { file, onRemove } = props;

    return (
      <div className="InputPhotoCard" data-testid="InputPhotoCard">
        <img
          src={file.url}
          className="InputPhotoCard__thumbnail"
          data-testid="InputPhotoCard__thumbnail"
        />
        <div
          className="InputPhotoCard__remove"
          data-testid="InputPhotoCard__remove"
          onClick={() => onRemove(file)}
        >
          <RemovePhotoIcon />
        </div>
      </div>
    );
  },
);

export default InputPhotoCard;
