import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import TranscriptsListItem from 'src/components/TranscriptsListItem/TranscriptsListItem';
import { MarketAccessory } from 'src/components/Market';
import CustomerImage from 'src/components/CustomerImage/CustomerImage';
import TranscriptsList from 'src/components/TranscriptsList/TranscriptsList';
import TranscriptsListStore from 'src/stores/TranscriptsListStore';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import { TranscriptMessengerPage, MessengerPageName } from 'src/MessengerTypes';
import './TranscriptsBucketList.scss';

/**
 * The value of the default scroll position
 */
const DEFAULT_SCROLL_POSITION = 0;

export type TranscriptsBucketListProps = {
  id: MessengerPageName;
  transcriptsList: TranscriptsListStore;
};

/**
 * Component that renders a list of transcripts for a given bucket (i.e. either active or assistant).
 *
 * @example <TranscriptsBucketList id={id} transcriptsList={transcriptsList} />
 * @param {MessengerPageName} id
 * The page ID that this list pertains to.
 * @param {TranscriptsListStore} transcriptsList
 * The store containing the list of transcripts to render.
 */
const TranscriptsBucketList = observer(
  ({ id, transcriptsList }: TranscriptsBucketListProps): ReactElement => {
    const {
      navigation,
      listScrollPosition,
      setListScrollPosition,
      transcripts,
    } = useMessengerControllerContext();
    const { t } = useTranslation();
    const page = navigation.navStoreForUrl
      .currentPage as TranscriptMessengerPage;

    return (
      <TranscriptsList
        transcriptsList={transcriptsList}
        initialScrollPosition={
          listScrollPosition[id] || DEFAULT_SCROLL_POSITION
        }
        setScrollPosition={(listScrollPosition: number) =>
          setListScrollPosition(id, listScrollPosition)
        }
      >
        {transcriptsList.ids.map((id: number) => {
          const transcript = transcripts.get(id);
          const displayName: string =
            transcript.title || t('TranscriptsListItem.unknown_user');
          return (
            <TranscriptsListItem
              key={id}
              className="TranscriptsBucketList__row"
              transcript={transcript}
              accessory={
                <MarketAccessory slot="leading-accessory" size="image">
                  <CustomerImage
                    customerInitials={transcript.displayName.initials}
                    imageUrl={transcript.displayName.profileImageUrl}
                    customerName={displayName}
                  />
                </MarketAccessory>
              }
              title={displayName}
              onClick={() =>
                navigation.openTranscript(transcript.id, 'conversations_list')
              }
              isSelected={transcript.id === page?.transcriptId}
            />
          );
        })}
      </TranscriptsList>
    );
  },
);

export default TranscriptsBucketList;
