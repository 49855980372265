/**
 * A happy face that is used in a contextual event
 * where a positive feedback is received.
 */
import React, { ReactElement } from 'react';

function HappyIcon(): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <title>Positive</title>
      <path
        fill="#000"
        fillOpacity=".9"
        fillRule="evenodd"
        d="M3 12a9 9 0 1 1 18.001.001A9 9 0 0 1 3 12Zm2 0c0 3.86 3.14 7 7 7s7-3.14 7-7-3.14-7-7-7-7 3.14-7 7Zm4.24 1.11A2.98 2.98 0 0 0 12 14.99c1.21 0 2.3-.73 2.76-1.88l1.86.76A4.987 4.987 0 0 1 12 16.99c-2.03 0-3.84-1.22-4.62-3.12l1.86-.76ZM14.5 11a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3ZM11 9.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default HappyIcon;
