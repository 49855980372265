import { useEffect } from 'react';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';

/**
 * Hook that prevents a user from accessing a page if they are prohibited and re-directs them
 * to the Messages Plus Pricing page instead.
 */
const usePreventAccessIfProhibited = (): void => {
  const { subscription, navigation } = useMessengerControllerContext();
  useEffect(() => {
    if (subscription.isProhibited) {
      navigation.sheet.navigateTo('MESSAGES_PLUS_PRICING');
    }
  }, [subscription.isProhibited, navigation.sheet]);
};

export default usePreventAccessIfProhibited;
