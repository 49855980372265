/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "bills": {
        "nested": {
          "TranslatedName": {
            "fields": {
              "localizedName": {
                "type": "string",
                "id": 1,
                "rule": "optional"
              },
              "translationType": {
                "type": "squareup.bills.TranslatedName.TranslationType",
                "id": 2,
                "rule": "optional",
                "options": {
                  "default": "UNKNOWN"
                }
              }
            },
            "nested": {
              "TranslationType": {
                "values": {
                  "UNKNOWN": 1,
                  "CUSTOM_AMOUNT_ITEM": 2,
                  "UNITEMIZED_TAX": 3,
                  "DEFAULT_DISCOUNT": 4,
                  "DEFAULT_ITEM": 5,
                  "DEFAULT_SALES_TAX": 6,
                  "DEFAULT_TIP": 7,
                  "CHANGE": 8,
                  "REFUND": 9,
                  "CASH_REFUND": 10,
                  "INCLUSIVE_TAX": 11,
                  "DEFAULT_ITEM_MODIFIER": 12,
                  "CREDIT": 13
                }
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.bills?.nested?.TranslatedName && !$root.nested?.squareup?.nested?.bills?.nested?.TranslatedName?.nested?.TranslationType) {
	$root.addJSON(jsonData);
}

/**
 * SOURCE TranslatedName @ squareup/bills/translated_name.proto at 7:1
 */
export const TranslatedName = $root.lookupType('squareup.bills.TranslatedName');
fixType(TranslatedName);
Builder.createAndAttachToType(TranslatedName);
/**
 * This enumeration should be updated with an entry for new localized strings.
 * TODO document these, they're currently used in Dashboard
 *
 * SOURCE TranslationType @ squareup/bills/translated_name.proto at 12:3
 */
TranslatedName.TranslationType = $root.lookupEnum('squareup.bills.TranslatedName.TranslationType').values;
