/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "bills": {
        "nested": {
          "RefundState": {
            "values": {
              "UNKNOWN_DO_NOT_USE": 0,
              "PENDING": 3,
              "CANCELED": 6,
              "APPROVED": 7,
              "REJECTED": 8,
              "INVALID": 9,
              "CREATED": 1,
              "DECIDING": 2,
              "RECEIVED_APPROVED_DECISION": 4,
              "RECEIVED_REJECTED_DECISION": 5
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.bills?.nested?.RefundState) {
	$root.addJSON(jsonData);
}

/**
 * Full state transition diagram can be found here:
 * Possible state transitions:
 * PENDING -> APPROVED|REJECTED|INVALID|CANCELED
 *
 * SOURCE RefundState @ squareup/bills/refund_state.proto at 12:1
 */
export const RefundState = $root.lookupEnum('squareup.bills.RefundState').values;
