import { observer } from 'mobx-react';
import React, { ReactElement } from 'react';
import { GetUnitsInformationResponse } from 'src/gen/squareup/messenger/v3/messenger_service';
import TextInput from './TextInput';
import BusinessAddressInputs from './BusinessAddressInputs';
import { useTranslation } from 'react-i18next';

/**
 * The "Business information" section in the individual unit verification form for
 * M+ Onboarding v2. This section covers the business name and address fields.
 *
 * @example
 * Usage:
 * <BusinessInformationSection />
 */
const BusinessInformationSection = observer((): ReactElement => {
  const { t } = useTranslation();
  return (
    <>
      <h3
        className="UnitVerificationForm__section__title"
        data-testid="BusinessInformationSection"
      >
        {t('BusinessInformationSection.title')}
      </h3>
      <form
        className="market-grid-container UnitVerificationForm__section__form"
        onSubmit={(event) => {
          event.preventDefault();
        }}
      >
        <TextInput
          field={GetUnitsInformationResponse.FieldForReview.BUSINESS_NAME}
        />
        <BusinessAddressInputs />
      </form>
    </>
  );
});

export default BusinessInformationSection;
