import React, { ReactElement, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import {
  MarketBanner,
  MarketButton,
  MarketField,
  MarketTextarea,
} from 'src/components/Market';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import MessengerModalPartial from 'src/components/MessengerModalPartial/MessengerModalPartial';
import { MarketTextareaValueChangeEvent } from 'src/MarketTypes';
import { onKeyDownEnter } from 'src/utils/keyboardUtils';
import { UnitSetting } from 'src/MessengerTypes';
import {
  MessagesMarketingClassifierError,
  MessagesShaftCError,
} from 'src/types/Errors';

const MAX_TEXT_MESSAGE_LENGTH = '400'; // max text message length as chosen by UX

export type EditRequestedTextMessageModalProps = {
  unitSetting: UnitSetting;
};

/**
 * Modal to edit the text message for a voicemail.
 *
 * @param {UnitSetting} unitSetting
 * The unit setting to edit the text message for.
 * @returns {ReactElement | null}
 */
const EditRequestedTextMessageModal = observer(
  ({
    unitSetting,
  }: EditRequestedTextMessageModalProps): ReactElement | null => {
    const { modal, status, tooltip, settings } =
      useMessengerControllerContext();
    const { t } = useTranslation();
    const { unitToken, voicemail } = unitSetting;
    const [textMessage, setTextMessage] = useState<string>(
      voicemail.textMessage,
    );
    const [errorText, setErrorText] = useState<string>('');

    const onSave = async (): Promise<void> => {
      if (textMessage.trim() === '') {
        setErrorText(t('EditVoicemailPage.empty_text_message'));
        return;
      }
      try {
        await settings.saveVoicemailUnitSettings(unitToken, {
          ...voicemail,
          textMessage: textMessage.trim(),
        });
      } catch (error) {
        if (error instanceof MessagesMarketingClassifierError) {
          setErrorText(t('EditVoicemailPage.marketing_error_text'));
          return;
        }
        if (error instanceof MessagesShaftCError) {
          setErrorText(t('EditVoicemailPage.shaft_c_error_text'));
          return;
        }
        status.setModalError({
          label: t('EditVoicemailPage.edit_text_message_save_error_text'),
        });
        return;
      }
      status.setSuccess({
        label: t('EditVoicemailPage.edit_text_message_save_success_text'),
      });
      modal.closeModal();
    };

    return (
      <MessengerModalPartial
        title={t('EditVoicemailPage.edit_text_message_title')}
        close={modal.closeModal}
        primaryButton={
          <MarketButton
            rank="primary"
            onClick={onSave}
            onKeyDown={(e) => onKeyDownEnter(e, onSave)}
            isLoading={settings.voicemailSaveStatus === 'LOADING' || undefined}
          >
            {t('common.save')}
          </MarketButton>
        }
        status={status.value}
      >
        <MarketField invalid={Boolean(errorText) || undefined}>
          <MarketTextarea
            maxlength={MAX_TEXT_MESSAGE_LENGTH}
            value={textMessage}
            onMarketTextareaValueChange={(
              event: MarketTextareaValueChangeEvent,
            ) => {
              setErrorText('');
              setTextMessage(event.detail.value);
            }}
            data-testid="EditVoicemailPage__textarea"
          >
            <label>{t('EditVoicemailPage.text_message_title')}</label>
          </MarketTextarea>
          <small slot="error">{errorText}</small>
        </MarketField>
        <p className="EditVoicemailPage__text-area-length">
          {textMessage.length} / {MAX_TEXT_MESSAGE_LENGTH}
        </p>
        {tooltip.isVisible('EDIT_REQUESTED_TEXT_MESSAGE_TIP') && (
          <MarketBanner
            dismissable
            onMarketBannerDismissed={() => {
              tooltip.dismiss('EDIT_REQUESTED_TEXT_MESSAGE_TIP');
            }}
          >
            {t('EditVoicemailPage.tip_banner_text')}
          </MarketBanner>
        )}
      </MessengerModalPartial>
    );
  },
);

export default EditRequestedTextMessageModal;
