/**
 * A blue circle with a plus, used for the dropdown in MessageInput.
 */

import React, { ReactElement } from 'react';
import BaseSvgProps from 'src/types/BaseSvgProps';

function PlusIcon(props: BaseSvgProps): ReactElement {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>Create Menu</title>
      <path d="M8 14V8H14V6H8V0H6V6H0V8H6V14H8Z" />
    </svg>
  );
}

export default PlusIcon;
