import React, { ReactElement, RefObject } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Utterance as UtteranceProto } from 'src/gen/squareup/messenger/v3/messenger_service';
import { renderISO, useDate, useTime } from 'src/utils/timeUtils';
import { LocalUtterance } from 'src/MessengerTypes';
import MerchantUtteranceSendStatus from './components/MerchantUtteranceSendStatus/MerchantUtteranceSendStatus';
import Reactions from 'src/pages/TranscriptViewPage/components/Reactions/Reactions';
import UtteranceContent from './components/UtteranceContent/UtteranceContent';
import ServiceUtteranceSendStatus from './components/ServiceUtteranceSendStatus/ServiceUtteranceSendStatus';
import CustomerUtteranceFooter from './components/CustomerUtteranceFooter/CustomerUtteranceFooter';
import './Utterance.scss';
import { isFailedSendStatus } from 'src/utils/transcriptUtils';

export type UtteranceProps = {
  localUtterance: LocalUtterance;
  customerImage?: ReactElement;
  showSentStatus?: boolean;
  utteranceRef?: RefObject<HTMLDivElement>;
};

/**
 * A single utterance in the transcript view. Mainly contains the
 * text of an utterance using <UtteranceText />. If the speaker is CUSTOMER, there is an
 * option to show the customer image. Also contains an hover timestamp.
 *
 * @example
 * Basic usage:
 * <Utterance
 *   utterance={{ speakerType: 2, text: 'Updated!', spokenAtMillis: 1555498747861 }}
 * />
 *
 * With optional:
 * <Utterance
 *   utterance={{ speakerType: 2, text: 'Updated!', spokenAtMillis: 1555498747861 }}
 *   customerImage={<CustomerImage />}
 *   showSentStatus
 * />
 * @param {LocalUtterance} localUtterance
 * The utterance object, containing all the information about
 * that utterance, to render.
 * @param {ReactElement} [customerImage=undefined]
 * If provided, show the customer image on the left side of a
 * customer utterance.
 * @param {boolean} [showSentStatus=false]
 * (Optional) If true, and if sendStatus === 'SENT', show the sent
 * indicator below the utterance. Usually only shows for the last utterance
 * in the conversation.
 * @param {RefObject<HTMLDivElement>} [utteranceRef]
 * Ref that should be set on the root div element of the utterance.
 * @author klim
 */
const Utterance = observer((props: UtteranceProps): ReactElement => {
  const { localUtterance, customerImage, showSentStatus, utteranceRef } = props;
  const { t } = useTranslation();
  const { speakerType, spokenAtMillis, metadata, sendStatus } =
    localUtterance.utterance;

  if (!speakerType) {
    return <></>;
  }

  const isCustomer = speakerType === UtteranceProto.SpeakerType.CUSTOMER;
  const isMerchant = speakerType === UtteranceProto.SpeakerType.MERCHANT;
  const isAutomated =
    speakerType === UtteranceProto.SpeakerType.SERVICE ||
    speakerType === UtteranceProto.SpeakerType.BOT;

  const reactions = metadata?.reactions?.reactions || [];

  const hasError = isFailedSendStatus(sendStatus);

  return (
    <div className="Utterance" data-testid="Utterance" ref={utteranceRef}>
      <div className={`Utterance__${isCustomer ? 'customer' : 'merchant'}`}>
        {(isCustomer && customerImage) || <div />}
        <div className="Utterance__content Utterance__timestamp-container">
          <UtteranceContent
            localUtterance={localUtterance}
            hasError={hasError}
          />
          {reactions.length > 0 && <Reactions reactions={reactions} />}
          {spokenAtMillis && (
            <time
              data-testid="UtteranceTimestamp"
              className="Utterance__timestamp"
              dateTime={renderISO(spokenAtMillis)}
            >
              {t('common.time.time_on_date', {
                date: useDate(spokenAtMillis),
                time: useTime(spokenAtMillis),
              })}
            </time>
          )}
          {isMerchant && (
            <MerchantUtteranceSendStatus
              localUtterance={localUtterance}
              showSentStatus={showSentStatus}
            />
          )}
          {isAutomated && <ServiceUtteranceSendStatus hasError={hasError} />}
        </div>
      </div>
      {isCustomer && (
        <CustomerUtteranceFooter localUtterance={localUtterance} />
      )}
    </div>
  );
});

export default Utterance;
