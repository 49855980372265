// Envoy Constants
export const X_ALLOW_COOKIES = 'X-Allow-Cookies';
export const X_BLOCK_COOKIES = 'X-Block-Cookies';
export const X_BLOCK_COOKIES_DEFAULT_VALUE = 'true';

// One Trust Constants
export const CONSENT_COOKIE_KEY = 'OptanonConsent';
export const ALERT_BOX_CLOSED_COOKIE_KEY = 'OptanonAlertBoxClosed';
export const COOKIE_GROUP_KEYS = {
  strictlyNecessary: 'C0001',
  performanceAndAnalytics: 'C0002',
  functionality: 'C0003',
  retargetingOrAdvertising: 'C0004',
};
export const CONSENT_GROUP_COOKIE_SUBSTRING = 'groups=';
export const PREFERENCES_CHANGED_EVENT_KEY = 'oneTrustPreferencesChangedEvent';
export const LOCATION_STORAGE_KEY = 'oneTrustStoredLocation';
export const LOCATION_IN_EU_STORAGE_KEY = 'storedUserLocationInEU';
// These countries are derived from One Trust's reference here:
// https://community.cookiepro.com/s/article/UUID-97c0d8a2-cf62-82b6-48e2-2c2bb4ef9c67?topicId=0TO1Q000000ssK9WAI
// and can also be found in their source file: https://cdn.cookielaw.org/scripttemplates/otSDKStub.js
export const EU_COUNTRIES = [
  'BE',
  'BG',
  'CZ',
  'DK',
  'DE',
  'EE',
  'IE',
  'GR',
  'ES',
  'FR',
  'IT',
  'CY',
  'LV',
  'LT',
  'LU',
  'HU',
  'MT',
  'NL',
  'AT',
  'PL',
  'PT',
  'RO',
  'SI',
  'SK',
  'FI',
  'SE',
  'GB',
  'HR',
  'LI',
  'NO',
  'IS',
];

// Interface definitions
interface OneTrust {
  getGeolocationData: () => {
    country?: string;
    state?: string;
    province?: string;
  };
}
declare global {
  /* eslint-disable no-var */
  var OneTrust: OneTrust;
  var OnetrustActiveGroups: string;
  /* eslint-enable no-var */
}

interface LocationHash {
  country?: string;
}

export default class SqOneTrust {
  public oneTrustPreferencesSet = false;
  public strictlyNecessaryCookiesBlocked = false;
  public performanceAndAnalyticsCookiesBlocked = false;
  public functionalityCookiesBlocked = false;
  public retargetingOrAdvertisingCookiesBlocked = false;
  public locationDataHash: LocationHash = {};
  public userLocatedInEU = false;
  public allCookiesAllowed = false;
  public getAllLocationDataFromLocalStorage = false;

  public optanonConsentCookieString = '';
  public optanonAlertBoxClosedCookieString = '';
  public storedLocationHash: LocationHash = {};
  public storedLocationInEu: boolean | null = null;
  public groupConsentHash: Record<string, unknown> = {};

  constructor(defaultConfig?: { getAllLocationDataFromLocalStorage: boolean }) {
    const { getAllLocationDataFromLocalStorage = false } = defaultConfig || {};
    this.getAllLocationDataFromLocalStorage = getAllLocationDataFromLocalStorage;
    this._init();
  }

  _init(): void {
    this._setVars();
    this._setGeolocationData();
    this._onUpdatedCookiePreferences();
  }

  // Only setting the variables that can be defined without a network call
  _setVars(): void {
    this.optanonConsentCookieString = this._getCookieValue(CONSENT_COOKIE_KEY);
    this.optanonAlertBoxClosedCookieString = this._getCookieValue(
      ALERT_BOX_CLOSED_COOKIE_KEY
    );
    // These variables read from sq-one-trust generated location values in
    // localstorage to allow for a faster determination of a user's locations
    // after the initial page load. When we rolled out One Trust to production,
    // we found that the network calls for the location data were taking long
    // enough to cause a drop in events, even in markets that didn't require
    // consent, so these variables are used to hold data about a user's location
    // that can be read immediately. For more context, see the commit message
    // for this change and: https://jira.sqprod.co/browse/XMS-707
    this.storedLocationHash = this.storedLocation;
    this.storedLocationInEu = this.isStoredLocationInEU;
  }

  // Getters

  get isUserLocatedInEU(): boolean {
    if (this.locationDataHash) {
      return this.checkIfUserLocatedInEU(this.locationDataHash.country);
    } else {
      return false;
    }
  }

  get isStoredLocationInEU(): boolean | null {
    const userLocationInEu = localStorage.getItem(LOCATION_IN_EU_STORAGE_KEY);
    // localStorage stores everything as a string (except when there is no value
    // for a key), so we only want to return a boolean when a value has been set
    if (userLocationInEu !== null) {
      return userLocationInEu === 'true';
    } else {
      return userLocationInEu;
    }
  }

  get storedLocation(): LocationHash {
    let storedLocation;

    const valueFromLocalStorage =
      localStorage.getItem(LOCATION_STORAGE_KEY) || '';
    if (valueFromLocalStorage && valueFromLocalStorage.length > 0) {
      storedLocation = JSON.parse(valueFromLocalStorage) as LocationHash;
    } else {
      storedLocation = {};
    }

    return storedLocation;
  }

  // Event Behavior

  _dispatchUpdateEvent(): void {
    const updateEvent = new CustomEvent(
      PREFERENCES_CHANGED_EVENT_KEY,
      this.groupConsentHash
    );
    document.dispatchEvent(updateEvent);
  }

  _onUpdatedCookiePreferences(): void {
    // One Trust sets data to the cookie on every page load. In the majority of
    // cases this is just going to be a timestamp for when the user set their
    // preferences, and the code in this module is only concerned with changes
    // to the preferences of the tracking groups.
    if (this._oneTrustGroupChoicesSet()) {
      this._buildHashOfGroupConsentChoices();
    } else {
      // If the cookie containing the group choices does not exist,
      // use the values existing in OnetrustActiveGroups.
      // There is a race condition for non EU users who are auto-consented.
      // In this state, the cookie will not yet have the consent choices,
      // but the Application layer will. This uses those known values if a cookie is not available.
      this._buildHashOfGroupConsentFromActiveGroups();
    }

    if (Object.keys(this.groupConsentHash).length > 0) {
      // TODO: figure out if we're ok with having the event fire on every
      // page load (which is going to happen because of the changed timestamp)
      // or if we want to add a second cookie for an initial state so that
      // we can compare group preferences against it.
      this._setConsentChoices();
      this._dispatchUpdateEvent();
    }
  }

  // Geolocation behavior

  checkIfUserLocatedInEU(country?: string): boolean {
    const currentCountry = country || '';
    return EU_COUNTRIES.includes(currentCountry);
  }

  _setGeolocationData(): void {
    const storedLocationExists = Object.keys(this.storedLocation).includes(
      'country'
    );

    if (this.getAllLocationDataFromLocalStorage && storedLocationExists) {
      this.storedLocationInEu = this.isStoredLocationInEU;
      this.storedLocationHash = this.storedLocation;

      this.locationDataHash = this.storedLocation;
      // We know the boolean is defined at this point, so we can cast the type here
      this.userLocatedInEU = this.isStoredLocationInEU as boolean;
    } else if (globalThis.OneTrust) {
      this.locationDataHash = OneTrust.getGeolocationData();
      this.userLocatedInEU = this.isUserLocatedInEU;
      this._setLocationInLocalStorage();
    }
  }

  _setLocationInLocalStorage(): void {
    localStorage.setItem(
      LOCATION_STORAGE_KEY,
      JSON.stringify(this.locationDataHash)
    );
    localStorage.setItem(
      LOCATION_IN_EU_STORAGE_KEY,
      this.checkIfUserLocatedInEU(this.storedLocation.country).toString()
    );
    this.storedLocationInEu = this.isStoredLocationInEU;
    this.storedLocationHash = this.storedLocation;
  }

  // Cookie and Preference behavior

  _setConsentChoices(): void {
    if (this.optanonAlertBoxClosedCookieString.length > 0) {
      this.oneTrustPreferencesSet = true;
    }
    // TODO: figure out if there are any complexities involved with preference
    // changes here or if we can early return after this.
    if (Object.values(this.groupConsentHash).includes('0')) {
      this.allCookiesAllowed = false;
    } else if (
      Object.values(this.groupConsentHash).every((value) => value === '1')
    ) {
      this.allCookiesAllowed = true;
    }

    // Strictly necessary
    if (this.groupConsentHash[COOKIE_GROUP_KEYS.strictlyNecessary] !== '1') {
      this.strictlyNecessaryCookiesBlocked = true;
    }

    // Performance and Analytics
    if (
      this.groupConsentHash[COOKIE_GROUP_KEYS.performanceAndAnalytics] !== '1'
    ) {
      this.performanceAndAnalyticsCookiesBlocked = true;
    }

    // Functionality
    if (this.groupConsentHash[COOKIE_GROUP_KEYS.functionality] !== '1') {
      this.functionalityCookiesBlocked = true;
    }

    // Retargeting or Advertising
    if (
      this.groupConsentHash[COOKIE_GROUP_KEYS.retargetingOrAdvertising] !== '1'
    ) {
      this.retargetingOrAdvertisingCookiesBlocked = true;
    }
  }

  // Parses the One Trust Cookie to derive the user's actual preferences
  // for sharing certain cookies.
  _buildHashOfGroupConsentChoices(): Record<string, unknown> {
    const cookieDataAsArray = this.optanonConsentCookieString.split('&'); // Data delimited by ampersands
    const groupCookieDataString = cookieDataAsArray.find((dataString) => {
      return dataString.includes(CONSENT_GROUP_COOKIE_SUBSTRING);
    });
    if (!groupCookieDataString) {
      throw new Error('No group cookie data string');
    }
    const groupPreferencesStringAsArray = groupCookieDataString
      .split('=')[1]
      .split(',');
    return (this.groupConsentHash = groupPreferencesStringAsArray.reduce(
      (consentHashObject, groupString) => {
        const splitString = groupString.split(':');
        consentHashObject[splitString[0]] = splitString[1];
        return consentHashObject;
      },
      this.groupConsentHash
    ));
  }

  // Parses the application layer active groups object to find the user's consent choices
  // Will be used if the user is outside of the EU and has not yet auto-consented.
  _buildHashOfGroupConsentFromActiveGroups(): void {
    const activeGroupsString = globalThis.OnetrustActiveGroups || '';
    const activeGroups = activeGroupsString.split(',');

    activeGroups.forEach((consentChoice: string) => {
      if (consentChoice !== '') {
        this.groupConsentHash[consentChoice] = '1';
      }
    });
  }

  // Gets cookie value by iterating through different cookie keys in document.cookie
  _getCookieValue(cookieKey: string): string {
    if (typeof document === 'undefined') {
      return '';
    }

    // To prevent the for loop in the first place assign an empty array
    // in case there are no cookies at all.
    const cookies = document.cookie ? document.cookie.split('; ') : [];
    for (const cooky of cookies) {
      const parts = cooky.split('=');
      let value = parts.slice(1).join('=');

      if (value[0] === '"') {
        value = value.slice(1, -1);
      }

      try {
        const foundKey = parts[0];
        if (foundKey === cookieKey) {
          return decodeURIComponent(value);
        }
      } catch (e) {
        console.log(e);
      }
    }

    return '';
  }

  _oneTrustGroupChoicesSet(): boolean {
    return this.optanonConsentCookieString.includes(
      CONSENT_GROUP_COOKIE_SUBSTRING
    );
  }
}
