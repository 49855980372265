import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import './SearchResultItem.scss';
import CustomerImage from 'src/components/CustomerImage/CustomerImage';
import { MarketAccessory, MarketRow } from 'src/components/Market';
import { onKeyDownEnter } from 'src/utils/keyboardUtils';
import { Medium } from 'src/gen/squareup/messenger/v3/messenger_service';
import { getPrimaryDisplayContact } from 'src/utils/transcriptUtils';
import { Contact } from 'src/gen/squareup/messenger/v3/messenger_auxiliary_service';

export type SearchResultItemProps = {
  contact: Contact;
  onClick: () => void;
};

/**
 * The fixed-height component that displays an individual's
 * contact preview. It is displayed in <NewMessagePage />
 * when there are no conversations with a particular customer
 * yet, and we still need to display a contact preview.
 *
 * @example
 * Basic usage:
 * <SearchResultItem
 *  contact={contacts[i]}
 *  onClick={() => selectContact(contacts[i])}
 * />
 * @param {Contact} contact
 * This is a Contact object describing the customer that
 * we're displaying.
 * @param {Function} onClick
 * The onClick handler.
 * @author keenon
 */
const SearchResultItem = observer(
  (props: SearchResultItemProps): ReactElement => {
    const { contact, onClick } = props;
    const { t } = useTranslation();
    const { initials, name } = contact;

    const displayName = name || t('TranscriptsListItem.unknown_user');

    const email = getPrimaryDisplayContact(contact, Medium.EMAIL);
    const phone = getPrimaryDisplayContact(contact, Medium.SMS);

    const contacts = [phone, email].filter(
      (contactStr) => contactStr != null && contactStr.trim() !== '',
    );
    const contactInfo = contacts.join(', ');

    return (
      <MarketRow
        className="SearchResultItem"
        onClick={onClick}
        onKeyDown={(e) => onKeyDownEnter(e, onClick)}
        data-testid="SearchResultItem"
      >
        <MarketAccessory slot="leading-accessory" size="image">
          <CustomerImage customerInitials={initials ?? ''} />
        </MarketAccessory>
        <div slot="label">{displayName}</div>
        {contactInfo !== '' && <div slot="subtext">{contactInfo}</div>}
      </MarketRow>
    );
  },
);

export default SearchResultItem;
