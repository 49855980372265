import React, { ReactElement } from 'react';

/**
 * An icon used to represent a phone.
 */
const PhoneIcon = (): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="20"
    fill="none"
    viewBox="0 0 14 20"
  >
    <title>Phone</title>
    <path
      fill="#000"
      fillOpacity=".9"
      fillRule="evenodd"
      d="M12 3c0-.55-.45-1-1-1H3c-.55 0-1 .45-1 1v14c0 .55.45 1 1 1h8c.55 0 1-.45 1-1V3ZM3 0h8c1.66 0 3 1.34 3 3v14c0 1.66-1.34 3-3 3H3c-1.66 0-3-1.34-3-3V3c0-1.66 1.34-3 3-3Zm2.5 14.5c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5S7.83 16 7 16s-1.5-.67-1.5-1.5Z"
      clipRule="evenodd"
    />
  </svg>
);

export default PhoneIcon;
