import React, { ReactElement } from 'react';
import BaseSvgProps from 'src/types/BaseSvgProps';

/**
 * A caret icon
 */
const CaretIcon = (props: BaseSvgProps): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <title>Caret</title>
    <path
      fill="#000"
      fillOpacity=".9"
      fillRule="evenodd"
      d="M17.56 10.94a1.5 1.5 0 0 1 0 2.12l-9 9-2.12-2.12L14.378 12l-7.94-7.94L8.56 1.94l9 9Z"
      clipRule="evenodd"
    />
  </svg>
);

export default CaretIcon;
