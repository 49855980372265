/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "taxinator": {
        "nested": {
          "service": {
            "nested": {
              "SellerCode": {
                "values": {
                  "SQUARE_US": 0,
                  "SHIPSTATION_US": 1,
                  "TAXJAR_US": 2,
                  "SHOPSEEN_US": 3,
                  "FRESHKDS_US": 4,
                  "INTRAKR_US": 5,
                  "ZOHO_BOOKS_US": 6,
                  "WHISK_US": 7,
                  "TSHEETS_US": 8,
                  "INVOICEASAP_US": 9,
                  "WHEN_I_WORK_US": 10,
                  "SHOPVENTORY_US": 11,
                  "MYERP_US": 12,
                  "ONE_UP_US": 13,
                  "ECWID_US": 14,
                  "SHOGO_US": 15,
                  "HOMEBASE_US": 16,
                  "IP_COMMERCE_US": 17,
                  "SQUARE_JP": 18,
                  "KASHOO_US": 19,
                  "SQUARE_AU": 20,
                  "SIMPLE_ORDER_IL": 21,
                  "MARKETMAN_IL": 22,
                  "HUMANITY_US": 23,
                  "DEPUTY_US": 24,
                  "DEAR_HK": 25,
                  "BREEZEWORKS_US": 26,
                  "WEBGILITY_US": 27,
                  "SQUARE_CA": 28,
                  "SQUARE_IE": 29,
                  "SQUARE_UK2": 30,
                  "PEACHWORKS_US": 31,
                  "SCHEDULICITY_US": 32,
                  "DAVOS_US": 33,
                  "WEEBLY_US": 34,
                  "BIGCOMMERCE_US": 35,
                  "YELLOW_DOG_SOFTWARE_US": 36,
                  "NEWCO_US": 37,
                  "NEWCO_INT": 38,
                  "BLOCK_GLOBAL": 39
                }
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.taxinator?.nested?.service?.nested?.SellerCode) {
	$root.addJSON(jsonData);
}

/**
 * The codes for sellers as setup in Vertex.
 *
 * SOURCE SellerCode @ squareup/taxinator/service.proto at 18:1
 */
export const SellerCode = $root.lookupEnum('squareup.taxinator.service.SellerCode').values;
