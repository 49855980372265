/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../Fixes';
import Builder from '../../../Builder';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "omg": {
        "nested": {
          "notifications": {
            "nested": {
              "NotificationsExtension": {
                "fields": {
                  "orderStateOverrides": {
                    "type": "squareup.omg.notifications.OrderStateOverride",
                    "id": 1,
                    "rule": "repeated"
                  }
                }
              },
              "OrderStateOverride": {
                "fields": {
                  "uid": {
                    "type": "string",
                    "id": 1,
                    "rule": "optional"
                  },
                  "state": {
                    "type": "squareup.omg.notifications.State",
                    "id": 2,
                    "rule": "optional"
                  },
                  "publishRuleOverride": {
                    "type": "squareup.omg.notifications.PublishRuleOverride",
                    "id": 3,
                    "rule": "optional"
                  }
                }
              },
              "PublishRuleOverride": {
                "fields": {
                  "email": {
                    "type": "bool",
                    "id": 1,
                    "rule": "optional",
                    "options": {
                      "default": true
                    }
                  },
                  "sms": {
                    "type": "bool",
                    "id": 2,
                    "rule": "optional",
                    "options": {
                      "default": true
                    }
                  },
                  "webhook": {
                    "type": "bool",
                    "id": 3,
                    "rule": "optional",
                    "options": {
                      "default": true
                    }
                  }
                }
              },
              "State": {
                "values": {
                  "DO_NOT_USE": 0,
                  "OPEN": 1,
                  "COMPLETED": 2,
                  "CANCELED": 3,
                  "DRAFT": 4
                }
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.omg?.nested?.notifications?.nested?.NotificationsExtension && !$root.nested?.squareup?.nested?.omg?.nested?.notifications?.nested?.OrderStateOverride && !$root.nested?.squareup?.nested?.omg?.nested?.notifications?.nested?.PublishRuleOverride && !$root.nested?.squareup?.nested?.omg?.nested?.notifications?.nested?.State) {
	$root.addJSON(jsonData);
}

/**
 * Notifications related extension.
 *
 * SOURCE NotificationsExtension @ squareup/omg/notifications/extensions.proto at 15:1
 */
export const NotificationsExtension = $root.lookupType('squareup.omg.notifications.NotificationsExtension');
fixType(NotificationsExtension);
Builder.createAndAttachToType(NotificationsExtension);
/**
 * SOURCE OrderStateOverride @ squareup/omg/notifications/extensions.proto at 20:1
 */
export const OrderStateOverride = $root.lookupType('squareup.omg.notifications.OrderStateOverride');
fixType(OrderStateOverride);
Builder.createAndAttachToType(OrderStateOverride);
/**
 * SOURCE PublishRuleOverride @ squareup/omg/notifications/extensions.proto at 28:1
 */
export const PublishRuleOverride = $root.lookupType('squareup.omg.notifications.PublishRuleOverride');
fixType(PublishRuleOverride);
Builder.createAndAttachToType(PublishRuleOverride);
/**
 * --
 * @desc The state of the order, copied over to prevent cyclic dependency.
 * --
 *
 * SOURCE State @ squareup/omg/notifications/extensions.proto at 38:1
 */
export const State = $root.lookupEnum('squareup.omg.notifications.State').values;
