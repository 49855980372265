/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "bills": {
        "nested": {
          "CommonPointOfSaleAttributes": {
            "nested": {
              "VirtualRegister": {
                "fields": {
                  "virtualRegisterToken": {
                    "type": "string",
                    "id": 1,
                    "rule": "optional"
                  },
                  "businessDayToken": {
                    "type": "string",
                    "id": 2,
                    "rule": "optional"
                  },
                  "transactionNumber": {
                    "type": "string",
                    "id": 4,
                    "rule": "optional"
                  },
                  "receiptToken": {
                    "type": "string",
                    "id": 5,
                    "rule": "repeated"
                  }
                }
              }
            }
          },
          "SquareProductAttributes": {
            "fields": {
              "register": {
                "type": "squareup.bills.SquareProductAttributes.Register",
                "id": 1,
                "rule": "optional"
              },
              "retailPointOfSale": {
                "type": "squareup.bills.SquareProductAttributes.RetailPointOfSale",
                "id": 2,
                "rule": "optional"
              },
              "externalApi": {
                "type": "squareup.bills.SquareProductAttributes.ExternalApi",
                "id": 3,
                "rule": "optional"
              }
            },
            "nested": {
              "Register": {
                "fields": {
                  "isLegacyPaymentTransaction": {
                    "type": "bool",
                    "id": 1,
                    "rule": "optional"
                  },
                  "isLegacyRefundTransaction": {
                    "type": "bool",
                    "id": 2,
                    "rule": "optional"
                  }
                }
              },
              "RetailPointOfSale": {
                "fields": {
                  "virtualRegister": {
                    "type": "squareup.bills.CommonPointOfSaleAttributes.VirtualRegister",
                    "id": 1,
                    "rule": "optional"
                  }
                }
              },
              "ExternalApi": {
                "fields": {
                  "referenceToken": {
                    "type": "string",
                    "id": 1,
                    "rule": "optional"
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.bills?.nested?.CommonPointOfSaleAttributes?.nested?.VirtualRegister && !$root.nested?.squareup?.nested?.bills?.nested?.SquareProductAttributes && !$root.nested?.squareup?.nested?.bills?.nested?.SquareProductAttributes?.nested?.Register && !$root.nested?.squareup?.nested?.bills?.nested?.SquareProductAttributes?.nested?.RetailPointOfSale && !$root.nested?.squareup?.nested?.bills?.nested?.SquareProductAttributes?.nested?.ExternalApi) {
	$root.addJSON(jsonData);
}

/**
 * Attributes that are common to multiple POS clients.
 *
 * SOURCE CommonPointOfSaleAttributes @ squareup/bills/square_product_attributes.proto at 8:1
 */
export const CommonPointOfSaleAttributes = {};
/**
 * SOURCE VirtualRegister @ squareup/bills/square_product_attributes.proto at 9:3
 */
CommonPointOfSaleAttributes.VirtualRegister = $root.lookupType('squareup.bills.CommonPointOfSaleAttributes.VirtualRegister');
fixType(CommonPointOfSaleAttributes.VirtualRegister);
Builder.createAndAttachToType(CommonPointOfSaleAttributes.VirtualRegister);
/**
 * Product-specific bill-level attributes. At most one field should be set.
 *
 * The names of these messages correspond with the SquareProduct creating the bill.
 *
 * This is a client-specific subset of squareup.client.bills.SquareProductAttributes, so please keep them in sync.
 *
 * SOURCE SquareProductAttributes @ squareup/bills/square_product_attributes.proto at 42:1
 */
export const SquareProductAttributes = $root.lookupType('squareup.bills.SquareProductAttributes');
fixType(SquareProductAttributes);
Builder.createAndAttachToType(SquareProductAttributes);
/**
 * SOURCE Register @ squareup/bills/square_product_attributes.proto at 43:3
 */
SquareProductAttributes.Register = $root.lookupType('squareup.bills.SquareProductAttributes.Register');
fixType(SquareProductAttributes.Register);
Builder.createAndAttachToType(SquareProductAttributes.Register);
/**
 * SOURCE RetailPointOfSale @ squareup/bills/square_product_attributes.proto at 56:3
 */
SquareProductAttributes.RetailPointOfSale = $root.lookupType('squareup.bills.SquareProductAttributes.RetailPointOfSale');
fixType(SquareProductAttributes.RetailPointOfSale);
Builder.createAndAttachToType(SquareProductAttributes.RetailPointOfSale);
/**
 * SOURCE ExternalApi @ squareup/bills/square_product_attributes.proto at 61:3
 */
SquareProductAttributes.ExternalApi = $root.lookupType('squareup.bills.SquareProductAttributes.ExternalApi');
fixType(SquareProductAttributes.ExternalApi);
Builder.createAndAttachToType(SquareProductAttributes.ExternalApi);
