import {
  Cursor,
  SearchUtterancesRequest,
} from 'src/gen/squareup/messenger/v3/messenger_service';
import Services from 'src/services/Services';
import { callV3Rpc } from 'src/utils/apiUtils';
import { UtteranceSearchResult } from 'src/MessengerTypes';
import Logger from 'src/Logger';

export const DEFAULT_PAGE_SIZE = 20;

/**
 * Api responsible for search.
 */
class SearchApi {
  private _services: Services;

  constructor(services: Services) {
    this._services = services;
  }

  /**
   * Searches for utterances that match the given query.
   *
   * @param {object} filters
   * @param {string} filters.query
   * Search query.
   * @param {Cursor} [filters.cursor]
   * (Optional) Cursor to use to fetch the next page of results.
   * @param {boolean} [filters.includeAutomatedUtterances]
   * (Optional) Whether to include automated utterances in the results.
   * @returns list of search results and next cursor
   */
  searchUtterances = async (filters: {
    query: string;
    cursor?: Cursor;
    includeAutomatedUtterances?: boolean;
  }): Promise<[readonly UtteranceSearchResult[], Cursor | undefined]> => {
    const { searchResults, cursor: nextCursor } = await callV3Rpc({
      name: 'SearchUtterances',
      rpc: (x) => this._services.messagesV3.searchUtterances(x),
      request: SearchUtterancesRequest.create({
        searchTerm: filters.query,
        cursor: filters?.cursor,
        includeAutomatedUtterances: filters?.includeAutomatedUtterances ?? true,
        searchResultPageSize: DEFAULT_PAGE_SIZE,
      }),
    });

    const invalidSearchResults = searchResults.filter((result) => {
      const { utterance, transcriptId, isRead } = result;
      return !utterance || transcriptId === undefined || isRead === undefined;
    });

    if (invalidSearchResults.length > 0) {
      const error = new Error('Invalid search results');
      Logger.logWithSentry(
        'SearchApi:searchUtterances - search results returned from server are invalid.',
        'error',
        { error, invalidSearchResults },
      );

      throw error;
    }

    return [
      searchResults as unknown as readonly UtteranceSearchResult[],
      nextCursor,
    ];
  };
}

export default SearchApi;
