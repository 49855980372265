import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import { KEY_ANNOUNCEMENT_BANNER } from 'src/stores/FeatureFlagStore';
import { MarketBanner } from 'src/components/Market';

// The details of the banner, basically the JSON properties of this flag:
// https://app.launchdarkly.com/pie/staging/features/messenger-announcement-banner/variations
type BannerDetails = {
  text: string;
  url: string;
  urlLabel: string;
};

/**
 * A critical banner used to show outage information. Extracts text
 * and url from the feature flag KEY_ANNOUNCEMENT_BANNER.
 *
 * @example
 * Basic usage:
 * <OutageBanner />
 * @author klim
 */
const OutageBanner = observer((): ReactElement => {
  const { featureFlag } = useMessengerControllerContext();

  const bannerDetails = featureFlag.get(
    KEY_ANNOUNCEMENT_BANNER,
  ) as BannerDetails;

  if (!bannerDetails?.text) {
    return <></>;
  }

  return (
    <MarketBanner
      variant="critical"
      className="OutageBanner"
      data-testid="OutageBanner"
    >
      {bannerDetails.text}
      <a
        href={bannerDetails.url}
        target="_blank"
        slot="action"
        rel="noreferrer"
      >
        {bannerDetails.urlLabel}
      </a>
    </MarketBanner>
  );
});

export default OutageBanner;
