import { useEffect } from 'react';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';

/**
 * Hook that loads the saved payment method if not already loaded.
 */
const useSavedSubscriptionPaymentMethod = (): void => {
  const { subscription } = useMessengerControllerContext();
  useEffect(() => {
    if (subscription.savedPaymentMethodStatus === 'NOT_STARTED') {
      subscription.getSavedPaymentMethod();
    }
  }, [subscription]);
};

export default useSavedSubscriptionPaymentMethod;
