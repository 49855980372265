import React, { ReactElement, useEffect } from 'react';
import { observer } from 'mobx-react';
import EmptyState from 'src/components/EmptyState/EmptyState';
import { MarketButton } from 'src/components/Market';
import { useTranslation } from 'react-i18next';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import AlertIcon from 'src/svgs/AlertIcon';

export type VerificationFailedRetryableStateProps = {
  unitTokens: string[];
};

/**
 * Null state alerting the user that their verification failed (under M+ v2) and that they can retry.
 *
 * @param {string[]} unitTokens
 * Unit tokens that failed verification.
 * If only one unit is provided, the null state will link to the verification form for that unit.
 * Otherwise, the null state will link to the table of all units requiring more information.
 */
const VerificationFailedRetryableState = observer(
  ({ unitTokens }: VerificationFailedRetryableStateProps): ReactElement => {
    const { t } = useTranslation();
    const { event, navigation } = useMessengerControllerContext();

    useEffect(() => {
      event.track('View Verification Failed Retryable Banner');
    }, [event]);

    return (
      <EmptyState
        icon={<AlertIcon />}
        title={t('VerificationFailedRetryableBanner.title')}
        description={t('VerificationFailedRetryableBanner.description')}
        button={
          <MarketButton
            onClick={() => {
              event.track('Click Verification Failed Retryable Banner');
              navigation.openUnitVerificationView(unitTokens);
            }}
          >
            {t('VerificationFailedRetryableBanner.button_text')}
          </MarketButton>
        }
        dataCy="VerificationFailedRetryableState"
      />
    );
  },
);

export default VerificationFailedRetryableState;
