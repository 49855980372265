import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import {
  KEY_INVOICES_PLUGIN_ENABLED,
  KEY_SQ_ONLINE_PLUGIN_SETTING_ENABLED,
} from 'src/stores/FeatureFlagStore';
import WebsiteSettingRow from 'src/components/WebsiteSettingRow/WebsiteSettingRow';
import AppointmentIcon from 'src/svgs/AppointmentIcon';
import GlobeIcon from 'src/svgs/GlobeIcon';
import DollarSignOnPaperIcon from 'src/svgs/DollarSignOnPaperIcon';
import ProjectIcon from 'src/svgs/ProjectIcon';
import ContractIcon from 'src/svgs/ContractIcon';
import {
  CONTRACTS_DASHBOARD_URL,
  ESTIMATES_DASHBOARD_URL,
  getBookingChannelsUrl,
  getBuyerBookingFlowUrl,
  getSquareOnlineUrl,
  INVOICES_DASHBOARD_URL,
  PROJECTS_DASHBOARD_URL,
} from 'src/utils/url';
import './SurfacesList.scss';

const SurfacesList = observer(() => {
  const { user, featureFlag, settings, event, navigation } =
    useMessengerControllerContext();
  const { t } = useTranslation();

  const {
    pluginEnabledOnAppts,
    userPluginEnabledOnAppts,
    setUserPluginEnabledOnAppts,
    sqOnlineSettings,
    setUserPluginEnabledOnSqOnlineSite,
    hasSqOnlineSettings,
    canViewSqOnlineSettings,
    canEnableOnlineBookingSite,
    canEnablePluginOnAppts,
    userPluginEnabledOnSqOnlineSite,
    isOnlineBookingSiteSurfaceShown,
    isInvoiceSurfaceShown,
    isEstimateSurfaceShown,
    isProjectSurfaceShown,
    isContractSurfaceShown,
  } = settings;

  const hasSingleSqOnlineSite =
    hasSqOnlineSettings && sqOnlineSettings.length === 1;

  const onUserPluginEnabledOnApptsChanged = (enabled: boolean): void => {
    setUserPluginEnabledOnAppts(enabled);
    event.track('Click Text Us Toggle', {
      enabled,
      setting_source: 'messages_settings',
      surface: 'APPOINTMENTS_BOOKING_SITE',
    });
  };

  const onUserPluginEnabledOnSqOnlineSiteChanged = (enabled: boolean): void => {
    event.track('Click Text Us Toggle', {
      enabled,
      setting_source: 'messages_settings',
      surface: 'SQUARE_ONLINE_SITE',
    });
    setUserPluginEnabledOnSqOnlineSite(sqOnlineSettings[0], enabled);
  };

  const apptsRow = (
    <WebsiteSettingRow
      selected={userPluginEnabledOnAppts ?? pluginEnabledOnAppts.boolValue}
      onMarketRowSelected={() => onUserPluginEnabledOnApptsChanged(true)}
      onMarketRowDeselected={() => onUserPluginEnabledOnApptsChanged(false)}
      dataTestId="MessagesPluginSettings__appts-row"
      icon={<AppointmentIcon />}
      websiteLabel={t('MessagesPluginSettings.surfaces.appts_title')}
      description={
        featureFlag.get(KEY_INVOICES_PLUGIN_ENABLED)
          ? undefined
          : t('MessagesPluginSettings.surfaces.appts_description')
      }
      url={getBuyerBookingFlowUrl(user.merchantToken)}
    />
  );

  const sqOnlineRow = (
    <WebsiteSettingRow
      selected={
        hasSingleSqOnlineSite &&
        (userPluginEnabledOnSqOnlineSite?.pluginEnabled?.boolValue ??
          sqOnlineSettings[0].pluginEnabled?.boolValue)
      }
      onMarketRowSelected={() => {
        if (hasSingleSqOnlineSite) {
          onUserPluginEnabledOnSqOnlineSiteChanged(true);
        } else {
          event.track('Click Into Text Us Multiple Websites');
          navigation.sheet.navigateTo('SQ_ONLINE_SETTINGS');
        }
      }}
      onMarketRowDeselected={() => {
        if (!hasSingleSqOnlineSite) return;
        onUserPluginEnabledOnSqOnlineSiteChanged(false);
      }}
      icon={<GlobeIcon />}
      websiteLabel={
        hasSingleSqOnlineSite
          ? t('MessagesPluginSettings.surfaces.sq_online_title_single')
          : t('MessagesPluginSettings.surfaces.sq_online_title_multiple')
      }
      description={
        !featureFlag.get(KEY_INVOICES_PLUGIN_ENABLED) || !hasSingleSqOnlineSite
          ? t('MessagesPluginSettings.surfaces.sq_online_description')
          : undefined
      }
      url={
        hasSingleSqOnlineSite && sqOnlineSettings[0].url
          ? getSquareOnlineUrl(sqOnlineSettings[0].url)
          : undefined
      }
      variant={hasSingleSqOnlineSite ? 'regular' : 'drill'}
      dataTestId="MessagesPluginSettings__sq-online-row"
    />
  );

  if (featureFlag.get(KEY_INVOICES_PLUGIN_ENABLED)) {
    return (
      <>
        <h5 className="SurfacesList__title">
          {t('MessagesPluginSettings.surfaces.title')}
        </h5>
        {isOnlineBookingSiteSurfaceShown && apptsRow}
        {canViewSqOnlineSettings && sqOnlineRow}
        {isInvoiceSurfaceShown && (
          // TODO(wdetlor): Connect state to this row
          <WebsiteSettingRow
            selected={false}
            // onMarketRowSelected={}
            // onMarketRowDeselected={}
            icon={<DollarSignOnPaperIcon />}
            websiteLabel={t('MessagesPluginSettings.surfaces.invoice.title')}
            url={INVOICES_DASHBOARD_URL}
            urlLabel={t('MessagesPluginSettings.surfaces.invoice.cta_label')}
          />
        )}
        {isEstimateSurfaceShown && (
          // TODO(wdetlor): Connect state to this row
          <WebsiteSettingRow
            selected={false}
            // onMarketRowSelected={}
            // onMarketRowDeselected={}
            icon={<DollarSignOnPaperIcon />}
            websiteLabel={t('MessagesPluginSettings.surfaces.estimate.title')}
            url={ESTIMATES_DASHBOARD_URL}
            urlLabel={t('MessagesPluginSettings.surfaces.estimate.cta_label')}
          />
        )}
        {isProjectSurfaceShown && (
          // TODO(wdetlor): Connect state to this row
          <WebsiteSettingRow
            selected={false}
            // onMarketRowSelected={}
            // onMarketRowDeselected={}
            icon={<ProjectIcon />}
            websiteLabel={t('MessagesPluginSettings.surfaces.project.title')}
            url={PROJECTS_DASHBOARD_URL}
            urlLabel={t('MessagesPluginSettings.surfaces.project.cta_label')}
          />
        )}
        {isContractSurfaceShown && (
          // TODO(wdetlor): Connect state to this row
          <WebsiteSettingRow
            selected={false}
            // onMarketRowSelected={}
            // onMarketRowDeselected={}
            icon={<ContractIcon />}
            websiteLabel={t('MessagesPluginSettings.surfaces.contract.title')}
            url={CONTRACTS_DASHBOARD_URL}
            urlLabel={t('MessagesPluginSettings.surfaces.contract.cta_label')}
          />
        )}
      </>
    );
  }

  const onClickEnableOnlineBooking = (): void => {
    event.track('Click Enable Online Booking from Text Us');
  };

  return (
    <>
      <h5 className="SurfacesList__title">
        {t('MessagesPluginSettings.surfaces.title')}
      </h5>
      {featureFlag.get(KEY_SQ_ONLINE_PLUGIN_SETTING_ENABLED) &&
        canEnableOnlineBookingSite && (
          <WebsiteSettingRow
            icon={<AppointmentIcon />}
            websiteLabel={t('MessagesPluginSettings.surfaces.appts_title')}
            description={t(
              'MessagesPluginSettings.enable_online_booking_description',
            )}
            url={getBookingChannelsUrl}
            urlLabel={t(
              'MessagesPluginSettings.enable_online_booking_button_label',
            )}
            urlOnClick={onClickEnableOnlineBooking}
            toggleDisabled
            dataTestId="MessagesPluginSettings__enable-online-booking-row"
          />
        )}
      {canEnablePluginOnAppts && apptsRow}
      {canViewSqOnlineSettings && sqOnlineRow}
    </>
  );
});

export default SurfacesList;
