/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../Fixes';
import Builder from '../../../Builder';
import '../../bills/cart';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "client": {
        "nested": {
          "orders": {
            "nested": {
              "RstClientDetails": {
                "fields": {
                  "reservationInformation": {
                    "type": "squareup.bills.Cart.FeatureDetails.OpenTicket.ReservationInformation",
                    "id": 1,
                    "rule": "optional"
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.client?.nested?.orders?.nested?.RstClientDetails) {
	$root.addJSON(jsonData);
}

/**
 * Holds client-specific info for a rst order
 *
 * SOURCE RstClientDetails @ squareup/client/orders/rst-client-details.proto at 10:1
 */
export const RstClientDetails = $root.lookupType('squareup.client.orders.RstClientDetails');
fixType(RstClientDetails);
Builder.createAndAttachToType(RstClientDetails);
