/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../Fixes';
import Builder from '../../../Builder';
import '../../connect/v2/common/money';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "connect": {
        "nested": {
          "v2": {
            "nested": {
              "PaymentFeesSpec": {
                "fields": {
                  "priceAllocationSpec": {
                    "type": "squareup.connect.v2.PaymentPriceAllocationSpec",
                    "id": 1,
                    "rule": "repeated"
                  },
                  "squareFeePayer": {
                    "type": "string",
                    "id": 3,
                    "rule": "optional"
                  }
                }
              },
              "PaymentPriceAllocationSpec": {
                "fields": {
                  "party": {
                    "type": "string",
                    "id": 1,
                    "rule": "optional"
                  },
                  "priceSelectors": {
                    "type": "string",
                    "id": 2,
                    "rule": "repeated"
                  },
                  "feeCalculationAmount": {
                    "type": "squareup.connect.v2.common.Money",
                    "id": 3,
                    "rule": "optional"
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.PaymentFeesSpec && !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.PaymentPriceAllocationSpec) {
	$root.addJSON(jsonData);
}

/**
 * --
 * @desc Defines the parameters on how payment fees should be calculated and used
 * by the __CreatePayment__ endpoint.
 * --
 *
 * SOURCE PaymentFeesSpec @ squareup/fees/external/payment_price.proto at 441:1
 */
export const PaymentFeesSpec = $root.lookupType('squareup.connect.v2.PaymentFeesSpec');
fixType(PaymentFeesSpec, {
  priceAllocationSpec: { required: true },
});
Builder.createAndAttachToType(PaymentFeesSpec);
/**
 * --
 * @desc Defines the parameters to the __CalculatePaymentPrice__
 * endpoint. This endpoint calculates the amount given a payment and a specification of which
 * PaymentPrices to apply.
 * --
 *
 * SOURCE PaymentPriceAllocationSpec @ squareup/fees/external/payment_price.proto at 467:1
 */
export const PaymentPriceAllocationSpec = $root.lookupType('squareup.connect.v2.PaymentPriceAllocationSpec');
fixType(PaymentPriceAllocationSpec, {
  party: { notEmpty: true },
  priceSelectors: { required: true },
});
Builder.createAndAttachToType(PaymentPriceAllocationSpec);
