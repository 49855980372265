import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import './AttachmentsReel.scss';
import { LocalFile } from 'src/MessengerTypes';
import { Attachment } from 'src/gen/squareup/messenger/v3/messenger_service';
import InputFileCard from 'src/pages/TranscriptViewPage/components/MessageInput/components/InputFileCard/InputFileCard';
import InputPhotoCard from 'src/pages/TranscriptViewPage/components/MessageInput/components/InputPhotoCard/InputPhotoCard';

export type AttachmentsReelProps = {
  files: LocalFile[];
  onRemove: (file: LocalFile) => void;
};

/**
 * A horizontal reel that renders selected files or photos prepared for upload.
 * This component should render inside of MessageInput.
 *
 * @param {LocalFile[]} files
 * List of files to render.
 * @param {Function} onRemove
 * Function to call when a file is removed.
 * @author klim
 */
const AttachmentsReel = observer(
  (props: AttachmentsReelProps): ReactElement => {
    const { files, onRemove } = props;

    if (files.length === 0) return <></>;

    return (
      <div className="AttachmentsReel" data-testid="AttachmentsReel">
        <div className="AttachmentsReel__reel">
          {files
            .filter((file) => file.type === Attachment.AttachmentType.IMAGE)
            .map((file) => (
              <InputPhotoCard file={file} onRemove={onRemove} key={file.url} />
            ))}
          {files
            .filter((file) => file.type === Attachment.AttachmentType.FILE)
            .map((file) => (
              <InputFileCard file={file} onRemove={onRemove} key={file.url} />
            ))}
        </div>
      </div>
    );
  },
);

export default AttachmentsReel;
