import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import SearchResultItem from 'src/components/SearchResultItem/SearchResultItem';
import NewCustomerButton from './components/NewCustomerButton/NewCustomerButton';
import { LoadingStatus, SearchQueryType } from 'src/MessengerTypes';
import {
  MarketButton,
  MarketEmptyState,
  MarketList,
} from 'src/components/Market';
import { emailRegex, phoneRegex } from 'src/utils/validationUtils';
import { i18nPartialPhoneNumber } from 'src/i18n/formatValues';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import Logger from 'src/Logger';
import { Medium } from 'src/gen/squareup/messenger/v3/messenger_service';
import LoadingIndicator from 'src/components/LoadingIndicator/LoadingIndicator';
import './SearchResultsList.scss';
import PhoneBookIcon from 'src/svgs/PhoneBookIcon';
import MagnifyIcon from 'src/svgs/MagnifyIcon';
import { Contact } from 'src/gen/squareup/messenger/v3/messenger_auxiliary_service';

export type SearchResultsListProps = {
  searchResults: Contact[];
  loadingStatus: LoadingStatus;
  searchQuery: string;
  retry: () => void;
  unitToken?: string;
};

/**
 * This component renders a list of contacts as search
 * results.
 *
 * @param {Contact[]} searchResults
 * List of results that came back from Rolodex for the current query.
 * @param {loadingStatus} loadingStatus
 * The current loading status of the search results query.
 * @param {string} searchQuery
 * The query used to search the current list of results.
 * @param {Function} retry
 * Function to retry search.
 * @param {string} [unitToken]
 * The unit token to use with the returned contact method to open Messages to.
 * This will be undefined from the TranscriptListPage and defined from the NewMessagePage
 * @author eblaine, keenon, klim
 */
const SearchResultsList = observer(
  (props: SearchResultsListProps): ReactElement => {
    const { searchResults, searchQuery, loadingStatus, retry, unitToken } =
      props;
    const { event, modal, navigation, user } = useMessengerControllerContext();
    const { isSingleUnit, units } = user;
    const { t } = useTranslation();

    let queryType: SearchQueryType | undefined;
    let nullStatePrimaryText;
    if (searchQuery) {
      if (emailRegex.test(searchQuery)) {
        queryType = 'EMAIL';
        nullStatePrimaryText = t(
          'NewMessagePage.null_state.no_customers_email',
          { email: searchQuery },
        );
      } else if (phoneRegex.test(searchQuery)) {
        queryType = 'PHONE';
        nullStatePrimaryText = t(
          'NewMessagePage.null_state.no_customers_phone',
          { phone: i18nPartialPhoneNumber(searchQuery, 'US') },
        );
      } else {
        queryType = 'NAME';
        nullStatePrimaryText = t(
          'NewMessagePage.null_state.no_customers_name',
          { name: searchQuery },
        );
      }
    }

    const selectConversationByContact = async (
      contact: Contact,
    ): Promise<void> => {
      if (!contact.token) {
        Logger.warn(
          `No token for customer with contact: ${JSON.stringify(contact)}`,
        );
        return;
      }

      // Show the modal if the contact method to open is ambiguous
      // a) There is more than one contact ID
      // b) There is no unit token specified, and there is more than one possible unit token the user could choose from
      const isAmbiguousContactMethod =
        contact.contactMethods.length > 1 || (!unitToken && !isSingleUnit);
      // If coming from the new customer modal, fall through in order to default to email
      if (isAmbiguousContactMethod && modal.currentModal !== 'NEW_CUSTOMER') {
        modal.openSelectContactMethodModal(
          contact,
          'contact_click_from_contact_selector',
          unitToken,
        );
        return;
      }

      const sellerKey = unitToken || units.keys().next().value;

      const contactEmail = contact.contactMethods.find(
        (contactMethod) => contactMethod.medium === Medium.EMAIL,
      );
      const contactPhone = contact.contactMethods.find(
        (contactMethod) => contactMethod.medium === Medium.SMS,
      );

      const transcript = await navigation.openTranscriptByContactMethod(
        {
          contactHandle: contactEmail
            ? contactEmail.contactHandle
            : contactPhone?.contactHandle,
          medium: contactEmail ? Medium.EMAIL : Medium.SMS,
          sellerKey,
        },
        'contact_click_from_contact_selector',
      );

      // There is a delay from Rolodex in providing the latest customer name to Messages. To prevent the newly created
      // customer from displaying as 'Unknown Name', we immediately set the contact ID and displayName on the transcript
      transcript?.set({
        contactId: contactEmail
          ? contactEmail.contactId
          : contactPhone?.contactId,
        displayName: {
          name: contact.name,
        },
      });
    };

    const nullStateNewCustomerButton = (
      <NewCustomerButton
        queryType={queryType}
        query={searchQuery}
        selectConversationByContact={selectConversationByContact}
        isInNullState
      />
    );

    // Our list of search results
    // There are a few different forms this can take:
    // 1a. We have a non-zero number of search
    //     results. Display them.
    // 1b. We have no results, but the query was the
    //     empty string. This is unique because the person
    //     truly has no contacts.
    // 1c. No results, but the query string is non empty.
    //     This is a simple "not found"
    // 2.  We have an error. Display a generic error message
    // 3.  We are loading results. Display a loading indicator.
    let content;
    if (loadingStatus === 'SUCCESS') {
      // 1. The query has returned.
      const contacts: Contact[] = searchResults.filter((contact) => {
        // Remove results with no contact token since we can't send them
        // messages anyway. We need either a transcript or a customer
        // token to be able to send messages.
        return contact.token != null && contact.token !== '';
      });

      if (contacts.length === 0 && searchQuery === '') {
        // 1a. The person has no customers at all.
        content = (
          <MarketEmptyState className="SearchResultsList__null-state">
            <h3 slot="primary-text">
              <div className="SearchResultsList__null-state__icon">
                <PhoneBookIcon />
              </div>
              {t('NewMessagePage.null_state.no_customers')}
            </h3>
            <p slot="secondary-text">
              {t('NewMessagePage.null_state.description')}
            </p>
            {nullStateNewCustomerButton}
          </MarketEmptyState>
        );
      } else if (contacts.length === 0) {
        // 1b. The query didn't return any results
        content = (
          <MarketEmptyState className="SearchResultsList__null-state">
            <h3 slot="primary-text">
              <div className="SearchResultsList__null-state__icon">
                <MagnifyIcon />
              </div>
              {nullStatePrimaryText}
            </h3>
            <p slot="secondary-text">
              {t('NewMessagePage.null_state.description')}
            </p>
            {nullStateNewCustomerButton}
          </MarketEmptyState>
        );
      } else {
        // 1c. The query returned some results
        content = (
          <>
            <NewCustomerButton
              queryType={queryType}
              query={searchQuery}
              selectConversationByContact={selectConversationByContact}
            />
            <div className="SearchResultsList__list">
              <MarketList interactive>
                {contacts.map((contact) => (
                  <SearchResultItem
                    key={contact.token}
                    contact={contact}
                    onClick={() => {
                      event.track('Click Search Contact');
                      selectConversationByContact(contact);
                    }}
                  />
                ))}
              </MarketList>
            </div>
          </>
        );
      }
    } else if (loadingStatus === 'ERROR') {
      // 2. We have an error
      content = (
        <MarketEmptyState className="SearchResultsList__null-state">
          <h3 slot="primary-text">{t('NewMessagePage.error_loading')}</h3>
          <MarketButton slot="actions" onClick={() => retry()}>
            {t('common.retry')}
          </MarketButton>
        </MarketEmptyState>
      );
    } else {
      // 3. Loading
      content = <LoadingIndicator />;
    }

    return (
      <div className="SearchResultsList" data-testid="SearchResultsList">
        {content}
      </div>
    );
  },
);

export default SearchResultsList;
