/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../Fixes';
import Builder from '../../../Builder';
import '../../common/time';
import '../../common/currency';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "coupons": {
        "nested": {
          "v2": {
            "nested": {
              "CouponV2": {
                "fields": {
                  "token": {
                    "type": "string",
                    "id": 1,
                    "rule": "optional"
                  },
                  "panFideliusToken": {
                    "type": "string",
                    "id": 2,
                    "rule": "optional"
                  },
                  "emailToken": {
                    "type": "string",
                    "id": 3,
                    "rule": "optional"
                  },
                  "phoneToken": {
                    "type": "string",
                    "id": 4,
                    "rule": "optional"
                  },
                  "couponDefinition": {
                    "type": "squareup.coupons.v2.CouponDefinitionV2",
                    "id": 5,
                    "rule": "optional"
                  },
                  "startsAt": {
                    "type": "squareup.common.time.DateTime",
                    "id": 6,
                    "rule": "optional"
                  },
                  "expiresAt": {
                    "type": "squareup.common.time.DateTime",
                    "id": 7,
                    "rule": "optional"
                  },
                  "createdAt": {
                    "type": "squareup.common.time.DateTime",
                    "id": 8,
                    "rule": "optional"
                  },
                  "code": {
                    "type": "string",
                    "id": 9,
                    "rule": "optional"
                  },
                  "redeemed": {
                    "type": "bool",
                    "id": 10,
                    "rule": "optional"
                  },
                  "reason": {
                    "type": "squareup.coupons.v2.CouponV2.Reason",
                    "id": 11,
                    "rule": "optional"
                  },
                  "customerId": {
                    "type": "string",
                    "id": 12,
                    "rule": "optional"
                  },
                  "maxRedemptions": {
                    "type": "int64",
                    "id": 13,
                    "rule": "optional",
                    "options": {
                      "default": 1
                    }
                  },
                  "pricingRuleId": {
                    "type": "string",
                    "id": 14,
                    "rule": "optional"
                  },
                  "pricingRuleVersion": {
                    "type": "int64",
                    "id": 15,
                    "rule": "optional"
                  },
                  "maxRedemptionsPerCustomer": {
                    "type": "int32",
                    "id": 16,
                    "rule": "optional",
                    "options": {
                      "default": -1
                    }
                  }
                },
                "nested": {
                  "Reason": {
                    "values": {
                      "UNKNOWN": 0,
                      "PUNCH_CARD_REWARD": 1,
                      "MARKETING": 2,
                      "FEEDBACK": 3,
                      "CONNECT_V2_API": 4
                    }
                  }
                }
              },
              "ItemConstraintV2": {
                "fields": {
                  "type": {
                    "type": "squareup.coupons.v2.ItemConstraintV2.Type",
                    "id": 1,
                    "rule": "optional"
                  },
                  "token": {
                    "type": "string",
                    "id": 2,
                    "rule": "repeated"
                  },
                  "quantity": {
                    "type": "uint32",
                    "id": 3,
                    "rule": "optional"
                  }
                },
                "nested": {
                  "Type": {
                    "values": {
                      "UNKNOWN": 0,
                      "CATEGORY": 1,
                      "VARIATION": 2
                    }
                  }
                }
              },
              "ScopeV2": {
                "values": {
                  "UNKNOWN": 0,
                  "CART": 1,
                  "ITEM": 2
                }
              },
              "CouponConditionV2": {
                "fields": {
                  "itemConstraint": {
                    "type": "squareup.coupons.v2.ItemConstraintV2",
                    "id": 1,
                    "rule": "repeated"
                  },
                  "minSpend": {
                    "type": "squareup.common.Money",
                    "id": 2,
                    "rule": "optional"
                  }
                }
              },
              "CouponRewardV2": {
                "fields": {
                  "scope": {
                    "type": "squareup.coupons.v2.ScopeV2",
                    "id": 1,
                    "rule": "optional"
                  },
                  "type": {
                    "type": "squareup.coupons.v2.CouponRewardV2.Type",
                    "id": 2,
                    "rule": "optional"
                  },
                  "fixedDiscount": {
                    "type": "squareup.common.Money",
                    "id": 3,
                    "rule": "optional"
                  },
                  "percentageDiscount": {
                    "type": "uint32",
                    "id": 4,
                    "rule": "optional"
                  },
                  "maxDiscount": {
                    "type": "squareup.common.Money",
                    "id": 5,
                    "rule": "optional"
                  },
                  "itemConstraint": {
                    "type": "squareup.coupons.v2.ItemConstraintV2",
                    "id": 6,
                    "rule": "repeated"
                  }
                },
                "nested": {
                  "Type": {
                    "values": {
                      "UNKNOWN": 0,
                      "FIXED": 1,
                      "PERCENT": 2,
                      "FREE_DELIVERY": 3,
                      "FREE_SHIPPING": 4
                    }
                  }
                }
              },
              "CouponDefinitionV2": {
                "fields": {
                  "token": {
                    "type": "string",
                    "id": 1,
                    "rule": "optional"
                  },
                  "merchantToken": {
                    "type": "string",
                    "id": 2,
                    "rule": "optional"
                  },
                  "itemConstraintType": {
                    "type": "squareup.coupons.v2.ItemConstraintV2.Type",
                    "id": 3,
                    "rule": "optional"
                  },
                  "condition": {
                    "type": "squareup.coupons.v2.CouponConditionV2",
                    "id": 4,
                    "rule": "optional"
                  },
                  "reward": {
                    "type": "squareup.coupons.v2.CouponRewardV2",
                    "id": 5,
                    "rule": "optional"
                  },
                  "name": {
                    "type": "string",
                    "id": 6,
                    "rule": "optional"
                  },
                  "image": {
                    "type": "string",
                    "id": 7,
                    "rule": "optional"
                  },
                  "isMultiunit": {
                    "type": "bool",
                    "id": 8,
                    "rule": "optional",
                    "options": {
                      "default": false
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.coupons?.nested?.v2?.nested?.CouponV2 && !$root.nested?.squareup?.nested?.coupons?.nested?.v2?.nested?.CouponV2?.nested?.Reason && !$root.nested?.squareup?.nested?.coupons?.nested?.v2?.nested?.ItemConstraintV2 && !$root.nested?.squareup?.nested?.coupons?.nested?.v2?.nested?.ItemConstraintV2?.nested?.Type && !$root.nested?.squareup?.nested?.coupons?.nested?.v2?.nested?.ScopeV2 && !$root.nested?.squareup?.nested?.coupons?.nested?.v2?.nested?.CouponConditionV2 && !$root.nested?.squareup?.nested?.coupons?.nested?.v2?.nested?.CouponRewardV2 && !$root.nested?.squareup?.nested?.coupons?.nested?.v2?.nested?.CouponRewardV2?.nested?.Type && !$root.nested?.squareup?.nested?.coupons?.nested?.v2?.nested?.CouponDefinitionV2) {
	$root.addJSON(jsonData);
}

/**
 * A coupon is a discount that a merchant gives to a buyer,
 * that may be applied to a purchase at some point in the future, limited
 * by expiration date.
 *
 * Redemption may be via a coupon code, which is a shared secret communicated
 * to the buyer via one of the buyer's contact channels (e.g. email, phone),
 * or via a payment card that's been associated with the coupon.
 *
 * A coupon may be redeemed only once.
 *
 * Additionally:
 * - As a matter of proto modeling, it can be thought of as an instance of a CouponDefinition.
 * - In the coupons db, it corresponds to a row in the "coupons" table.
 *
 * SOURCE CouponV2 @ squareup/coupons/v2/model_v2.proto at 28:1
 */
export const CouponV2 = $root.lookupType('squareup.coupons.v2.CouponV2');
fixType(CouponV2, {
  token: { notEmpty: true },
  couponDefinition: { required: true },
  createdAt: { required: true },
  code: { notEmpty: true },
  redeemed: { required: true },
});
Builder.createAndAttachToType(CouponV2);
/**
 * If a new reason type is added, please add a new translation key to postoffice so that coupon notification emails
 * have the correct text.
 * See postoffice/config/en.yml: general_purpose_coupon_v2_notification.coupon_notification_types
 *
 * SOURCE Reason @ squareup/coupons/v2/model_v2.proto at 72:3
 */
CouponV2.Reason = $root.lookupEnum('squareup.coupons.v2.CouponV2.Reason').values;
/**
 * An item constraint is used to determine what item or items a
 * Coupon Reward or Coupon Condition applies to.
 *
 * The constraint takes one of two forms (NOT both):
 *
 * Category constraint: the constraint identities a set of
 * item categories (using the item category token). If an
 * item is in any one of the categories, it satisfies the constraint.
 *
 * Variation constraint: the constraint identifies a set of
 * item variations (using the item variation token), which
 * are effectively skus from a product catalog. If an item
 * instance is matches to any one of the variations, it
 * satisfies the constraint.
 *
 * SOURCE ItemConstraintV2 @ squareup/coupons/v2/model_v2.proto at 118:1
 */
export const ItemConstraintV2 = $root.lookupType('squareup.coupons.v2.ItemConstraintV2');
fixType(ItemConstraintV2, {
  type: { required: true },
  quantity: { required: true },
});
Builder.createAndAttachToType(ItemConstraintV2);
/**
 * SOURCE Type @ squareup/coupons/v2/model_v2.proto at 119:3
 */
ItemConstraintV2.Type = $root.lookupEnum('squareup.coupons.v2.ItemConstraintV2.Type').values;
/**
 * Scope a coupon condition or coupon reward to an item OR a whole cart.
 *
 * SOURCE ScopeV2 @ squareup/coupons/v2/model_v2.proto at 142:1
 */
export const ScopeV2 = $root.lookupEnum('squareup.coupons.v2.ScopeV2').values;
/**
 * A coupon condition determines whether a coupon may be applied, usually based on
 * what and how much a buyer is purchasing. For example, a condition may be that if a
 * buyer must purchase a pizza (the condition) in order to get a free drink (the reward).
 *
 * This information is stored in the discounts table, alongside other aspects of the
 * CouponDefinition data.
 *
 * SOURCE CouponConditionV2 @ squareup/coupons/v2/model_v2.proto at 159:1
 */
export const CouponConditionV2 = $root.lookupType('squareup.coupons.v2.CouponConditionV2');
fixType(CouponConditionV2);
Builder.createAndAttachToType(CouponConditionV2);
/**
 * A coupon reward is what the buyer gets (if the coupon can be applied). For example, the buyer
 * may get a free item, a % discount off a purchase, or a fixed dollar amount off a purchase.
 *
 * This information is stored in the discounts table, alongside other aspects of the
 * CouponDefinition data.
 *
 * SOURCE CouponRewardV2 @ squareup/coupons/v2/model_v2.proto at 173:1
 */
export const CouponRewardV2 = $root.lookupType('squareup.coupons.v2.CouponRewardV2');
fixType(CouponRewardV2, {
  scope: { required: true },
  type: { required: true },
});
Builder.createAndAttachToType(CouponRewardV2);
/**
 * SOURCE Type @ squareup/coupons/v2/model_v2.proto at 174:3
 */
CouponRewardV2.Type = $root.lookupEnum('squareup.coupons.v2.CouponRewardV2.Type').values;
/**
 * A coupon definition can be thought of as a coupon template.
 * It defines what kinds of purchases a coupon may be applied to,
 * as well as what the buyer gets when they use a coupon that's
 * based on this definition. It additional stores how the coupon
 * is represented - it's name and image.
 *
 * This all flattens into a discount row, with many item categories
 * and/or variations that are stored as serialized json lists.
 *
 * SOURCE CouponDefinitionV2 @ squareup/coupons/v2/model_v2.proto at 220:1
 */
export const CouponDefinitionV2 = $root.lookupType('squareup.coupons.v2.CouponDefinitionV2');
fixType(CouponDefinitionV2, {
  token: { notEmpty: true },
  merchantToken: { notEmpty: true },
  itemConstraintType: { required: true },
  reward: { required: true },
});
Builder.createAndAttachToType(CouponDefinitionV2);
