import React, { ReactElement, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { MarketButton, MarketHeader } from 'src/components/Market';
import MessengerContent from 'src/components/MessengerContent/MessengerContent';
import MarketBackIcon from 'src/svgs/MarketBackIcon';
import SearchResultsList from 'src/components/SearchResultsList/SearchResultsList';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import { Transition } from 'src/MessengerTypes';
import InputBar from './components/InputBar/InputBar';
import './NewMessagePage.scss';

export type NewMessagePageProps = {
  transitionDirection: Transition;
};

/**
 * The page that appears when "New message" is clicked.
 *
 * @example
 * Basic usage:
 * <NewMessagePage
 *   transitionDirection="LEFT"
 * />
 * @param {Transition} transitionDirection
 * Determines which direction the content slides in from.
 * @returns {ReactElement}
 */
const NewMessagePage = observer(
  ({ transitionDirection }: NewMessagePageProps): ReactElement => {
    const { status, navigation, newMessageSearch, user } =
      useMessengerControllerContext();
    const { activeUnits } = user;
    const { t } = useTranslation();

    useEffect(() => {
      if (newMessageSearch.status === 'NOT_STARTED') {
        newMessageSearch.init();
      }
    }, [newMessageSearch, newMessageSearch.status]);

    const [unitToken, setUnitToken] = useState<string>(
      activeUnits[0]?.token || '',
    );

    // Autofocus the input when transition is done.
    //
    // Code is pulled from TranscriptViewPage.tsx, which had
    // a similar animation-related visual bug.
    // This is to resolve a visual bug where the header animates
    // and the input bar focuses prematurely.
    // The reason is that the input in SearchBar was calling
    // autoFocus, which causes the browser to force scroll the element into the viewport.
    // While it is doing that, MessengerContent is also doing a transition animation,
    // causing a visual conflict. This fix allows the transition to end first before
    // telling the input to focus.
    const [autoFocusInput, setAutoFocusInput] = useState(false);
    const onTransitionEnd = (): void => {
      setAutoFocusInput(true);
    };

    return (
      <>
        {!navigation.secondary.isOpen && (
          <MarketHeader className="MessagesPageHeader" compact>
            <MarketButton
              slot="navigation"
              aria-label={t('common.back')}
              onClick={() => {
                navigation.primary.navigateBack();
                newMessageSearch.setQuery('');
              }}
            >
              <MarketBackIcon slot="icon" />
            </MarketButton>

            <h2>{t('NewMessagePage.default_title')}</h2>
          </MarketHeader>
        )}
        <MessengerContent
          transitionDirection={transitionDirection}
          // Hide status on the full page as otherwise both views on the full page show separate status banners
          status={!navigation.secondary.isOpen ? status.value : null}
          onTransitionEnd={onTransitionEnd}
        >
          <div className="NewMessagePage__content">
            <InputBar
              unitToken={unitToken}
              setUnitToken={setUnitToken}
              autoFocus={autoFocusInput}
            />
            <SearchResultsList
              searchResults={newMessageSearch.results}
              loadingStatus={newMessageSearch.status}
              searchQuery={newMessageSearch.query}
              retry={newMessageSearch.retry}
              unitToken={unitToken}
            />
          </div>
        </MessengerContent>
      </>
    );
  },
);

export default NewMessagePage;
