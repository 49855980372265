@import "../../../../styles/mixins/component-base.css";
@import "../../../../styles/mixins/typography.css";

/* TODO: replace fallback variable values once design tokens are merged */
:host {
  display: contents;

  & > li {
    display: flex;
    gap: var(--core-metrics-spacing-200);
  }
}

.indicator {
  display: flex;
  flex-direction: column;
  align-items: center;

  [part="icon"] {
    margin: var(--core-metrics-spacing-25) 0;
  }

  ::slotted([slot="icon"]) {
    fill: inherit;
    stroke: inherit;
  }

  &:not(.completed):not(.active) {
    fill: var(--progress-tracker-bar-incomplete-value-color, var(--core-fill-20-color));

    .default-icon.checked-icon,
    .default-icon.unchecked-icon,
    .default-icon.non-current-circle-icon {
      fill: var(--progress-tracker-bar-incomplete-value-color, var(--core-fill-20-color));
      stroke: none;
    }

    .default-icon.current-circle-icon {
      fill: none;
      stroke: var(--progress-tracker-bar-incomplete-value-color, var(--core-fill-20-color));
    }
  }

  &.active {
    fill: var(--progress-tracker-step-active-icon-color, var(--core-blue-fill-color));

    .default-icon.checked-icon,
    .default-icon.unchecked-icon,
    .default-icon.non-current-circle-icon {
      fill: var(--progress-tracker-bar-active-value-color, var(--core-blue-fill-color));
      stroke: none;
    }

    .default-icon.current-circle-icon {
      fill: none;
      stroke: var(--progress-tracker-bar-active-value-color, var(--core-blue-fill-color));
    }
  }

  &.completed {
    fill: var(--progress-tracker-step-completed-icon-color, var(--core-blue-fill-color));

    .default-icon.checked-icon,
    .default-icon.unchecked-icon,
    .default-icon.non-current-circle-icon {
      fill: var(--progress-tracker-bar-completed-value-color, var(--core-blue-fill-color));
      stroke: none;
    }

    .default-icon.current-circle-icon {
      fill: none;
      stroke: var(--progress-tracker-bar-completed-value-color, var(--core-blue-fill-color));
    }
  }

  [part="bar"] {
    display: none;
    flex: 1;
    width: var(--progress-tracker-step-bar-size, 2px);

    &.hidden {
      display: none;
    }

    &.inactive {
      display: unset;
      background-color: var(--progress-tracker-step-bar-color, var(--core-fill-20-color));
    }

    &.active {
      display: unset;
      background-color: var(--progress-tracker-step-completed-bar-color, var(--core-blue-fill-color));
    }
  }
}

[part="content"] {
  display: flex;
  flex-direction: column;
  gap: var(--core-metrics-spacing-25);
  align-items: flex-start;
  padding-top: var(--core-metrics-spacing-25);
  padding-bottom: var(--progress-tracker-step-padding-bottom-size, var(--core-metrics-spacing-200));

  ::slotted(p:first-of-type) {
    margin-top: 0;
  }

  ::slotted(p:last-of-type) {
    margin-bottom: 0;
  }
}

:host([size="small"]) ::slotted([slot="label"]) {
  @extend %medium-20;
}

:host([size="medium"]) ::slotted([slot="label"]) {
  @extend %semibold-30;
}

:host([size="large"]) {
  [part="content"] {
    padding-top: unset;
  }

  ::slotted([slot="label"]) {
    @extend %heading-20;
  }
}

:host([size="small"]),
:host([size="medium"]),
:host([size="large"]) {
  ::slotted([slot="label"]) {
    color: var(--core-text-10-color);
  }

  ::slotted([slot="subtext"]) {
    @extend %paragraph-10;

    color: var(--core-text-20-color);
  }

  ::slotted([slot="label"]),
  ::slotted([slot="subtext"]) {
    margin: 0;
  }

  ::slotted([slot="error"]) {
    @extend %paragraph-20;

    color: var(--core-critical-text-color);
  }
}
