/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../Fixes';
import Builder from '../../../Builder';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "client": {
        "nested": {
          "bills": {
            "nested": {
              "CardTender": {
                "nested": {
                  "Card": {
                    "nested": {
                      "Brand": {
                        "values": {
                          "UNKNOWN_BRAND": 1,
                          "VISA": 2,
                          "MASTERCARD": 3,
                          "AMERICAN_EXPRESS": 4,
                          "DISCOVER": 5,
                          "DISCOVER_DINERS": 6,
                          "JCB": 7,
                          "CHINA_UNIONPAY": 8,
                          "SQUARE_GIFT_CARD_V2": 9,
                          "INTERAC": 10,
                          "SQUARE_CAPITAL_CARD": 11,
                          "EFTPOS": 12,
                          "FELICA": 13,
                          "ALIPAY": 14,
                          "CASH_APP": 15,
                          "EBT": 16,
                          "PAYPAY": 17,
                          "AFTERPAY": 18,
                          "SQUARE_ACCOUNT_BALANCE": 19,
                          "RAKUTEN_PAY": 20,
                          "AU_PAY": 21,
                          "D_BARAI": 22,
                          "MERPAY": 23,
                          "WECHAT_PAY": 24
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.client?.nested?.bills?.nested?.CardTender?.nested?.Card?.nested?.Brand) {
	$root.addJSON(jsonData);
}

/**
 * SOURCE CardTender @ squareup/client/bills/tender.proto at 664:1
 */
export const CardTender = {};
/**
 * SOURCE Card @ squareup/client/bills/tender.proto at 665:3
 */
CardTender.Card = {};
/**
 * SOURCE Brand @ squareup/client/bills/tender.proto at 666:5
 */
CardTender.Card.Brand = $root.lookupEnum('squareup.client.bills.CardTender.Card.Brand').values;
