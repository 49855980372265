import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import './StatusBlock.scss';
import { onKeyDownEnter } from 'src/utils/keyboardUtils';

export type StatusBlockProps = {
  /**
   * Informational content that describes what happened.
   */
  message: string;
  /**
   * An optional function to call that will retry the failed
   * operation on user click.
   */
  retry?: (() => void) | (() => Promise<void>) | null;
};

/**
 * This is the smaller, less intrusive equivalent to StatusScreen.
 * It has a message and possibly a small CTA that appear at the same
 * level in visual hierarchy as the main unit of content on the
 * current page (e.g. a TranscriptViewItem or TranscriptsListItem).
 * An intended use case is when we have some content but were not
 * able to get more.
 *
 * @example
 * Basic usage:
 * <StatusBlock message={"Failed to load more"} />
 *
 * With optional:
 * <StatusBlock prop1={"Failed to load more"} prop2={() => retryLoadMore()} />
 * @param {string} message
 * Informational content that describes what happened.
 * @param {() => void | () => Promise<void> | null} [retry]
 * (Optional) An optional function to call that will retry the failed
 * operation on user click.
 * @author eblaine
 */
const StatusBlock = observer((props: StatusBlockProps): ReactElement => {
  const { message, retry } = props;

  const { t } = useTranslation();

  const messageElement = (
    <span className="StatusBlock__message" data-testid="StatusBlock__message">
      {`${message} `}
    </span>
  );

  return (
    <div className="StatusBlock" data-testid="StatusBlock">
      {messageElement}
      {retry != null ? (
        <span
          className="StatusBlock__button"
          data-testid="StatusBlock__button"
          tabIndex={0}
          onClick={() => retry()}
          onKeyDown={(e) => onKeyDownEnter(e, () => retry())}
        >
          {t('StatusScreen.retry')}
        </span>
      ) : null}
    </div>
  );
});

export default StatusBlock;
