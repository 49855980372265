import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import MessengerEmptyState from './components/MessengerEmptyState/MessengerEmptyState';
import TranscriptViewSkeletonState from './pages/TranscriptViewPage/components/TranscriptViewSkeletonState/TranscriptViewSkeletonState';
import { useMessengerControllerContext } from './context/MessengerControllerContext';
import NewMessagePage from './pages/NewMessagePage/NewMessagePage';
import TranscriptViewPage from './pages/TranscriptViewPage/TranscriptViewPage';
import StatusPage from './components/StatusPage/StatusPage';
import { TranscriptMessengerPage } from './MessengerTypes';

/**
 * Component that renders the content for the Secondary View.
 * This corresponds with the main content area where the conversation view
 * is shown on the full page inbox.
 *
 * @returns {ReactElement}
 */
const SecondaryAppContent = observer((): ReactElement => {
  const { status, navigation, transcriptsList } =
    useMessengerControllerContext();

  switch (navigation.secondary.currentPageName) {
    case 'NEW_MESSAGE': {
      return <NewMessagePage transitionDirection="NONE" />;
    }
    case 'STATUS': {
      return <StatusPage transitionDirection="NONE" />;
    }
    case 'TRANSCRIPT_VIEW':
    default: {
      const page = navigation.secondary.currentPage as TranscriptMessengerPage;
      const isSelectedConversation = Boolean(page?.transcriptId);
      const hasNoConversations =
        transcriptsList.status === 'SUCCESS' && transcriptsList.size === 0;
      return (
        <>
          {isSelectedConversation && (
            // App loaded and transcript selected
            <TranscriptViewPage transitionDirection="NONE" />
          )}
          {!isSelectedConversation && hasNoConversations && (
            // App loaded but no conversations available to select
            <MessengerEmptyState />
          )}
          {!isSelectedConversation && !hasNoConversations && !status.value && (
            // App is not yet loaded
            <TranscriptViewSkeletonState showHeader />
          )}
        </>
      );
    }
  }
});

export default SecondaryAppContent;
