import React, { ReactElement, useEffect } from 'react';
import { observer } from 'mobx-react';
import { MarketBanner } from 'src/components/Market';
import { useTranslation } from 'react-i18next';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import { M_PLUS_SUBSCRIPTION_FAQ_URL } from 'src/utils/url';

/**
 * Banner that is displayed when a merchant attempts to subscribe to M+ (v2),
 * but fails TFN verification and does not successfully subscribe due to being
 * deemed by carrier networks as a prohibited (SHAFT-C) merchant.
 */
const IneligibleBanner = observer((): ReactElement => {
  const { event } = useMessengerControllerContext();
  const { t } = useTranslation();

  useEffect(() => {
    event.track('View Verification Failed Banner');
  }, [event]);

  return (
    <MarketBanner
      variant="info"
      className="IneligibleBanner"
      data-testid="IneligibleBanner"
    >
      <span slot="title">{t('IneligibleBanner.title')}</span>
      {t('IneligibleBanner.description')}
      <a
        slot="action"
        href={M_PLUS_SUBSCRIPTION_FAQ_URL}
        target="_blank"
        rel="noreferrer"
        onClick={() => {
          event.track('Click Verification Failed Banner');
        }}
      >
        {t('common.learn_more')}
      </a>
    </MarketBanner>
  );
});

export default IneligibleBanner;
