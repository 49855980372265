import { makeAutoObservable, runInAction } from 'mobx';
import type MessengerController from 'src/MessengerController';
import type Api from 'src/api/Api';
import { LoadingStatus, PastAppointment } from 'src/MessengerTypes';

/**
 * Store containing the state related to a list of past appointments for an individual customer.
 */
class PastAppointmentsList {
  private _stores: MessengerController;
  private _api: Api;

  /**
   * The customer token to filter the results of the past appointments contained in the list by.
   */
  readonly customerToken: string;

  /**
   * The loading status of the list. This is used to display a loading/error state
   * when the user tries to first load the past appointments list.
   */
  status: LoadingStatus = 'NOT_STARTED';

  /**
   * The loading status associated with loading more. This is used to display a loading/error state
   * when the user tries to load more items into the existing list that is already shown.
   */
  loadMoreStatus: LoadingStatus = 'NOT_STARTED';

  /**
   * The list of past appointments that have been loaded so far.
   */
  list: PastAppointment[] = [];

  /**
   * The next page of past appointments to load. `-1` indicates there are no more pages to load.
   */
  private _nextPage = 0;

  constructor(stores: MessengerController, customerToken: string) {
    makeAutoObservable<PastAppointmentsList, '_nextPage' | '_load'>(this);

    this._stores = stores;
    this._api = stores.api;
    this.customerToken = customerToken;
  }

  init = async (): Promise<void> => {
    runInAction(() => {
      this.status = 'LOADING';
    });
    try {
      await this._load();
      runInAction(() => {
        this.status = 'SUCCESS';
      });
    } catch {
      runInAction(() => {
        this.status = 'ERROR';
      });
    }
  };

  private _load = async (): Promise<void> => {
    const [appointments, hasNextPage] =
      await this._api.appointments.getPastAppointments(
        this.customerToken,
        this._nextPage,
      );

    runInAction(() => {
      this._nextPage++;

      if (!hasNextPage) {
        this._nextPage = -1;
      }

      const updatedList = [...this.list, ...appointments];

      // De-dupe the list based on the reservationId
      this.list = updatedList.filter(
        (appt, index) =>
          updatedList.findIndex(
            (appt2) => appt2.reservationId === appt.reservationId,
          ) === index,
      );
    });
  };

  loadMore = async (): Promise<void> => {
    runInAction(() => {
      this.loadMoreStatus = 'LOADING';
    });
    try {
      await this._load();
      runInAction(() => {
        this.loadMoreStatus = 'SUCCESS';
      });
    } catch {
      runInAction(() => {
        this.loadMoreStatus = 'ERROR';
      });
    }
  };

  get hasNextPage(): boolean {
    return this._nextPage > -1;
  }
}

export default PastAppointmentsList;
