import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import StatusBlock from 'src/components/StatusBlock/StatusBlock';
import LoadingIndicator from 'src/components/LoadingIndicator/LoadingIndicator';

export type LoadMoreIndicatorProps = {
  loadMore: () => Promise<void>;
  hasMorePages: boolean;
  hasError: boolean;
};

/**
 * Component that displays a loading spinner or error message for the load more action.
 * Used to display the load status when loading either newer or older utterances in the transcript view list.
 *
 * @param {() => Promise<void>} loadMore
 * Function that loads another page of utterances. Called on retry for an error.
 * @param {boolean} hasMorePages
 * Flag indicating if there are more pages to load.
 * @param {boolean} hasError
 * Flag indicating if the load more operation has an error.
 */
const LoadMoreIndicator = observer(
  ({
    loadMore,
    hasMorePages,
    hasError,
  }: LoadMoreIndicatorProps): ReactElement | null => {
    const { t } = useTranslation();

    if (!hasMorePages) {
      return null;
    }

    if (hasError) {
      return (
        <StatusBlock
          message={t('TranscriptViewPage.error_loading_more')}
          retry={loadMore}
        />
      );
    }

    return <LoadingIndicator isSmall />;
  },
);

export default LoadMoreIndicator;
