import React, { useState, useEffect, ReactNode, ReactElement } from 'react';
import { createPortal } from 'react-dom';
import { observer } from 'mobx-react';
import { getReactRoot } from 'src/utils/shadowDomUtils';
import { MarketContext } from 'src/components/Market';
import './MessengerModalContext.scss';

export type MessengerModalContextProps = {
  children: ReactNode;
  close?: () => void;
};

/**
 * Component that renders children in a portal at the React root.
 * Also used to apply common styles to centre the content and apply a grey background.
 * Intended to be used for Dialogs and Modals.
 *
 * @example
 * Basic usage:
 * <MessengerModalContext onClick={modal.close}>
 *   <MessengerModalDialog />
 * </MessengerModalContext>
 * @param {ReactNode} children
 * Any valid React Node to render in the portal - the container element should be
 * one of the Market modal type.
 * @param {Function} [close]
 * (Optional) Function to dismiss the modal, essentially dismounting this component.
 * @author wdetlor, klim
 */
const MessengerModalContext = observer(
  ({ children, close }: MessengerModalContextProps): ReactElement => {
    const [container] = useState(() => document.createElement('div'));
    const [isMouseDown, setIsMouseDown] = useState(false);

    // Adds container node on initial render and cleans it up on unmount by removing it
    useEffect(() => {
      container.className = 'MessengerModalContext';
      container.dataset.testid = 'MessengerModalContext';
      const rootElement = getReactRoot() ?? document.body;
      rootElement.appendChild(container);
      const onEscapeClose = (e: KeyboardEvent): void => {
        if (e.key === 'Escape') {
          close?.();
        }
      };
      rootElement.addEventListener('keydown', onEscapeClose);
      return () => {
        rootElement.removeChild(container);
        rootElement.removeEventListener('keydown', onEscapeClose);
      };
      // TODO (#5429): re-enable eslint rule in the next line, or remove this TODO
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const content = (
      <MarketContext
        data-testid="MessengerModalContext__veil"
        onMouseDown={(e) => {
          if (e.target === e.currentTarget) {
            setIsMouseDown(true);
          }
        }}
        onMouseUp={(e) => {
          if (e.target === e.currentTarget && isMouseDown) {
            close?.();
          }
          setIsMouseDown(false);
        }}
      >
        {children}
      </MarketContext>
    );

    return createPortal(content, container);
  },
);

export default MessengerModalContext;
