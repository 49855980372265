import React, { ReactElement, ReactNode } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { MarketTableCell } from 'src/components/Market';
import './UnitInfoCell.scss';

export type UnitInfoCellProps = {
  isMissing?: boolean;
  children?: ReactNode;
};

/**
 * Basic component that renders a formatted missing label if there is no text to display in the cell.
 *
 * @param {boolean} [isMissing]
 * Flag indicating if the data for this cell is missing.
 * @param {string} [children]
 * Text that should be displayed in the cell.
 */
const UnitInfoCell = observer(
  ({ isMissing, children }: UnitInfoCellProps): ReactElement => {
    const { t } = useTranslation();

    if (isMissing || !children) {
      return (
        <MarketTableCell className="UnitInfoCell__missing">
          {t('UnitsToVerifyPage.missing_label')}
        </MarketTableCell>
      );
    }

    return <MarketTableCell>{children}</MarketTableCell>;
  },
);

export default UnitInfoCell;
