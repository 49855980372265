import { createContext, useContext } from 'react';

export type MessengerModalFullContextType = {
  isAnimationEnded: boolean;
};

/**
 * Context used for the MessengerModalFull component. Used to pass stateful values from the top level
 * MessengerModalFull component to the underlying content component, without intermediary components
 * having to define pass through props. Intended to be internal to MessengerModalFull.
 */
const MessengerModalFullContext = createContext<MessengerModalFullContextType>({
  isAnimationEnded: false,
});

/**
 * Wrapper to read the MessengerModalFull context from React components. Expected to be used in
 * internal MessengerModalFull components to read stateful values without intermediary props.
 */
const useMessengerModalFullContext = (): MessengerModalFullContextType => {
  return useContext(MessengerModalFullContext);
};

export { MessengerModalFullContext, useMessengerModalFullContext };
