// This file is automatically generated. Do not edit.

module.exports = {
  "ACCESSORY_BACKGROUND_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.05)",
  "ACCESSORY_BACKGROUND_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.08)",
  "ACCESSORY_BACKGROUND_RADIUS": 6,
  "ACCESSORY_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.05)",
  "ACCESSORY_MEDIUM_SIZE_HEIGHT": 40,
  "ACCESSORY_MEDIUM_SIZE_WIDTH": 40,
  "ACCESSORY_LARGE_SIZE_HEIGHT": 64,
  "ACCESSORY_LARGE_SIZE_WIDTH": 64,
  "ACCESSORY_ICON_VARIANT_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "ACCESSORY_ICON_VARIANT_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "ACCESSORY_ICON_VARIANT_MEDIUM_SIZE_HEIGHT": 24,
  "ACCESSORY_ICON_VARIANT_MEDIUM_SIZE_WIDTH": 24,
  "ACCESSORY_ICON_VARIANT_LARGE_SIZE_HEIGHT": 40,
  "ACCESSORY_ICON_VARIANT_LARGE_SIZE_WIDTH": 40,
  "ACCESSORY_ICON_VARIANT_COLOR": "rgba(0, 0, 0, 0.9)",
  "ACCESSORY_INITIALS_VARIANT_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.55)",
  "ACCESSORY_INITIALS_VARIANT_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.55)",
  "ACCESSORY_INITIALS_VARIANT_MEDIUM_SIZE_TEXT_SIZE": 16,
  "ACCESSORY_INITIALS_VARIANT_MEDIUM_SIZE_TEXT_LEADING": 24,
  "ACCESSORY_INITIALS_VARIANT_MEDIUM_SIZE_TEXT_TRACKING": 0,
  "ACCESSORY_INITIALS_VARIANT_MEDIUM_SIZE_TEXT_WEIGHT": 600,
  "ACCESSORY_INITIALS_VARIANT_MEDIUM_SIZE_TEXT_CASE": "regular",
  "ACCESSORY_INITIALS_VARIANT_MEDIUM_SIZE_TEXT_NUMBER_SPACING": "default",
  "ACCESSORY_INITIALS_VARIANT_MEDIUM_SIZE_TEXT_SCALE": "paragraph-30",
  "ACCESSORY_INITIALS_VARIANT_MEDIUM_SIZE_TEXT_FONT_FAMILY": "Square Sans Text",
  "ACCESSORY_INITIALS_VARIANT_LARGE_SIZE_TEXT_SIZE": 28,
  "ACCESSORY_INITIALS_VARIANT_LARGE_SIZE_TEXT_LEADING": 40,
  "ACCESSORY_INITIALS_VARIANT_LARGE_SIZE_TEXT_TRACKING": 0,
  "ACCESSORY_INITIALS_VARIANT_LARGE_SIZE_TEXT_WEIGHT": 500,
  "ACCESSORY_INITIALS_VARIANT_LARGE_SIZE_TEXT_CASE": "regular",
  "ACCESSORY_INITIALS_VARIANT_LARGE_SIZE_TEXT_NUMBER_SPACING": "default",
  "ACCESSORY_INITIALS_VARIANT_LARGE_SIZE_TEXT_SCALE": "heading-30",
  "ACCESSORY_INITIALS_VARIANT_LARGE_SIZE_TEXT_FONT_FAMILY": "Square Sans Display",
  "ACCESSORY_INITIALS_VARIANT_COLOR": "rgba(0, 0, 0, 0.55)",
  "ACCORDION_DISABLED_STATE_CONTENT_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.3)",
  "ACCORDION_DISABLED_STATE_CONTENT_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.3)",
  "ACCORDION_DISABLED_STATE_CONTENT_COLOR": "rgba(0, 0, 0, 0.3)",
  "ACCORDION_DISABLED_STATE_ACCESSORY_OPACITY": 0.5,
  "ACCORDION_DISABLED_STATE_SIDE_TEXT_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.3)",
  "ACCORDION_DISABLED_STATE_SIDE_TEXT_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.3)",
  "ACCORDION_DISABLED_STATE_SIDE_TEXT_COLOR": "rgba(0, 0, 0, 0.3)",
  "ACCORDION_FOCUS_STATE_CONTENT_LIGHT_MODE_COLOR": "rgb(0, 90, 217)",
  "ACCORDION_FOCUS_STATE_CONTENT_DARK_MODE_COLOR": "rgb(74, 149, 255)",
  "ACCORDION_FOCUS_STATE_CONTENT_COLOR": "rgb(0, 90, 217)",
  "ACCORDION_FOCUS_STATE_ACCESSORY_OPACITY": 1,
  "ACCORDION_FOCUS_STATE_SIDE_TEXT_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.55)",
  "ACCORDION_FOCUS_STATE_SIDE_TEXT_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.55)",
  "ACCORDION_FOCUS_STATE_SIDE_TEXT_COLOR": "rgba(0, 0, 0, 0.55)",
  "ACCORDION_HOVER_STATE_CONTENT_LIGHT_MODE_COLOR": "rgb(0, 90, 217)",
  "ACCORDION_HOVER_STATE_CONTENT_DARK_MODE_COLOR": "rgb(74, 149, 255)",
  "ACCORDION_HOVER_STATE_CONTENT_COLOR": "rgb(0, 90, 217)",
  "ACCORDION_HOVER_STATE_ACCESSORY_OPACITY": 1,
  "ACCORDION_HOVER_STATE_SIDE_TEXT_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.55)",
  "ACCORDION_HOVER_STATE_SIDE_TEXT_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.55)",
  "ACCORDION_HOVER_STATE_SIDE_TEXT_COLOR": "rgba(0, 0, 0, 0.55)",
  "ACCORDION_NORMAL_STATE_CONTENT_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "ACCORDION_NORMAL_STATE_CONTENT_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "ACCORDION_NORMAL_STATE_CONTENT_COLOR": "rgba(0, 0, 0, 0.9)",
  "ACCORDION_NORMAL_STATE_ACCESSORY_OPACITY": 1,
  "ACCORDION_NORMAL_STATE_SIDE_TEXT_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.55)",
  "ACCORDION_NORMAL_STATE_SIDE_TEXT_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.55)",
  "ACCORDION_NORMAL_STATE_SIDE_TEXT_COLOR": "rgba(0, 0, 0, 0.55)",
  "ACCORDION_PRESSED_STATE_CONTENT_LIGHT_MODE_COLOR": "rgb(0, 90, 217)",
  "ACCORDION_PRESSED_STATE_CONTENT_DARK_MODE_COLOR": "rgb(74, 149, 255)",
  "ACCORDION_PRESSED_STATE_CONTENT_COLOR": "rgb(0, 90, 217)",
  "ACCORDION_PRESSED_STATE_ACCESSORY_OPACITY": 1,
  "ACCORDION_PRESSED_STATE_SIDE_TEXT_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.55)",
  "ACCORDION_PRESSED_STATE_SIDE_TEXT_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.55)",
  "ACCORDION_PRESSED_STATE_SIDE_TEXT_COLOR": "rgba(0, 0, 0, 0.55)",
  "ACCORDION_HEADING_10_VARIANT_CONTENT_SPACING": 16,
  "ACCORDION_HEADING_10_VARIANT_SEPARATOR_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.05)",
  "ACCORDION_HEADING_10_VARIANT_SEPARATOR_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.08)",
  "ACCORDION_HEADING_10_VARIANT_SEPARATOR_SIZE": 1,
  "ACCORDION_HEADING_10_VARIANT_SEPARATOR_COLOR": "rgba(0, 0, 0, 0.05)",
  "ACCORDION_HEADING_10_VARIANT_HORIZONTAL_PADDING": 0,
  "ACCORDION_HEADING_10_VARIANT_TEXT_SIZE": 14,
  "ACCORDION_HEADING_10_VARIANT_TEXT_LEADING": 22,
  "ACCORDION_HEADING_10_VARIANT_TEXT_TRACKING": 0,
  "ACCORDION_HEADING_10_VARIANT_TEXT_WEIGHT": 700,
  "ACCORDION_HEADING_10_VARIANT_TEXT_CASE": "regular",
  "ACCORDION_HEADING_10_VARIANT_TEXT_NUMBER_SPACING": "default",
  "ACCORDION_HEADING_10_VARIANT_TEXT_SCALE": "heading-10",
  "ACCORDION_HEADING_10_VARIANT_TEXT_FONT_FAMILY": "Square Sans Text",
  "ACCORDION_HEADING_10_VARIANT_TEXT_IS_HEADER": true,
  "ACCORDION_HEADING_10_VARIANT_SIDE_TEXT_SIZE": 12,
  "ACCORDION_HEADING_10_VARIANT_SIDE_TEXT_LEADING": 18,
  "ACCORDION_HEADING_10_VARIANT_SIDE_TEXT_TRACKING": 0,
  "ACCORDION_HEADING_10_VARIANT_SIDE_TEXT_WEIGHT": 400,
  "ACCORDION_HEADING_10_VARIANT_SIDE_TEXT_CASE": "regular",
  "ACCORDION_HEADING_10_VARIANT_SIDE_TEXT_NUMBER_SPACING": "default",
  "ACCORDION_HEADING_10_VARIANT_SIDE_TEXT_SCALE": "paragraph-10",
  "ACCORDION_HEADING_10_VARIANT_SIDE_TEXT_FONT_FAMILY": "Square Sans Text",
  "ACCORDION_HEADING_10_VARIANT_VERTICAL_PADDING": 13,
  "ACCORDION_HEADING_10_VARIANT_EXPANDED_PHASE_ICON_ASSET": "chevron-down",
  "ACCORDION_HEADING_10_VARIANT_EXPANDED_PHASE_ICON_WIDTH": 16,
  "ACCORDION_HEADING_10_VARIANT_EXPANDED_PHASE_ICON_HEIGHT": 16,
  "ACCORDION_HEADING_10_VARIANT_EXPANDED_PHASE_ICON_TINTABLE": true,
  "ACCORDION_HEADING_10_VARIANT_EXPANDED_PHASE_SPACING": 8,
  "ACCORDION_HEADING_10_VARIANT_COLLAPSED_PHASE_ICON_ASSET": "chevron-up",
  "ACCORDION_HEADING_10_VARIANT_COLLAPSED_PHASE_ICON_WIDTH": 16,
  "ACCORDION_HEADING_10_VARIANT_COLLAPSED_PHASE_ICON_HEIGHT": 16,
  "ACCORDION_HEADING_10_VARIANT_COLLAPSED_PHASE_ICON_TINTABLE": true,
  "ACCORDION_HEADING_20_VARIANT_CONTENT_SPACING": 16,
  "ACCORDION_HEADING_20_VARIANT_SEPARATOR_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.05)",
  "ACCORDION_HEADING_20_VARIANT_SEPARATOR_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.08)",
  "ACCORDION_HEADING_20_VARIANT_SEPARATOR_SIZE": 1,
  "ACCORDION_HEADING_20_VARIANT_SEPARATOR_COLOR": "rgba(0, 0, 0, 0.05)",
  "ACCORDION_HEADING_20_VARIANT_HORIZONTAL_PADDING": 0,
  "ACCORDION_HEADING_20_VARIANT_TEXT_SIZE": 19,
  "ACCORDION_HEADING_20_VARIANT_TEXT_LEADING": 26,
  "ACCORDION_HEADING_20_VARIANT_TEXT_TRACKING": 0,
  "ACCORDION_HEADING_20_VARIANT_TEXT_WEIGHT": 700,
  "ACCORDION_HEADING_20_VARIANT_TEXT_CASE": "regular",
  "ACCORDION_HEADING_20_VARIANT_TEXT_NUMBER_SPACING": "default",
  "ACCORDION_HEADING_20_VARIANT_TEXT_SCALE": "heading-20",
  "ACCORDION_HEADING_20_VARIANT_TEXT_FONT_FAMILY": "Square Sans Display",
  "ACCORDION_HEADING_20_VARIANT_TEXT_IS_HEADER": true,
  "ACCORDION_HEADING_20_VARIANT_SIDE_TEXT_SIZE": 14,
  "ACCORDION_HEADING_20_VARIANT_SIDE_TEXT_LEADING": 22,
  "ACCORDION_HEADING_20_VARIANT_SIDE_TEXT_TRACKING": 0,
  "ACCORDION_HEADING_20_VARIANT_SIDE_TEXT_WEIGHT": 400,
  "ACCORDION_HEADING_20_VARIANT_SIDE_TEXT_CASE": "regular",
  "ACCORDION_HEADING_20_VARIANT_SIDE_TEXT_NUMBER_SPACING": "default",
  "ACCORDION_HEADING_20_VARIANT_SIDE_TEXT_SCALE": "paragraph-20",
  "ACCORDION_HEADING_20_VARIANT_SIDE_TEXT_FONT_FAMILY": "Square Sans Text",
  "ACCORDION_HEADING_20_VARIANT_VERTICAL_PADDING": 19,
  "ACCORDION_HEADING_20_VARIANT_EXPANDED_PHASE_ICON_ASSET": "chevron-down",
  "ACCORDION_HEADING_20_VARIANT_EXPANDED_PHASE_ICON_WIDTH": 16,
  "ACCORDION_HEADING_20_VARIANT_EXPANDED_PHASE_ICON_HEIGHT": 16,
  "ACCORDION_HEADING_20_VARIANT_EXPANDED_PHASE_ICON_TINTABLE": true,
  "ACCORDION_HEADING_20_VARIANT_EXPANDED_PHASE_SPACING": 8,
  "ACCORDION_HEADING_20_VARIANT_COLLAPSED_PHASE_ICON_ASSET": "chevron-up",
  "ACCORDION_HEADING_20_VARIANT_COLLAPSED_PHASE_ICON_WIDTH": 16,
  "ACCORDION_HEADING_20_VARIANT_COLLAPSED_PHASE_ICON_HEIGHT": 16,
  "ACCORDION_HEADING_20_VARIANT_COLLAPSED_PHASE_ICON_TINTABLE": true,
  "ACCORDION_HEADING_30_VARIANT_CONTENT_SPACING": 16,
  "ACCORDION_HEADING_30_VARIANT_HORIZONTAL_PADDING": 0,
  "ACCORDION_HEADING_30_VARIANT_SEPARATOR_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.05)",
  "ACCORDION_HEADING_30_VARIANT_SEPARATOR_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.08)",
  "ACCORDION_HEADING_30_VARIANT_SEPARATOR_SIZE": 1,
  "ACCORDION_HEADING_30_VARIANT_SEPARATOR_COLOR": "rgba(0, 0, 0, 0.05)",
  "ACCORDION_HEADING_30_VARIANT_TEXT_SIZE": 25,
  "ACCORDION_HEADING_30_VARIANT_TEXT_LEADING": 32,
  "ACCORDION_HEADING_30_VARIANT_TEXT_TRACKING": 0,
  "ACCORDION_HEADING_30_VARIANT_TEXT_WEIGHT": 700,
  "ACCORDION_HEADING_30_VARIANT_TEXT_CASE": "regular",
  "ACCORDION_HEADING_30_VARIANT_TEXT_NUMBER_SPACING": "default",
  "ACCORDION_HEADING_30_VARIANT_TEXT_SCALE": "heading-30",
  "ACCORDION_HEADING_30_VARIANT_TEXT_FONT_FAMILY": "Square Sans Display",
  "ACCORDION_HEADING_30_VARIANT_TEXT_IS_HEADER": true,
  "ACCORDION_HEADING_30_VARIANT_SIDE_TEXT_SIZE": 16,
  "ACCORDION_HEADING_30_VARIANT_SIDE_TEXT_LEADING": 24,
  "ACCORDION_HEADING_30_VARIANT_SIDE_TEXT_TRACKING": 0,
  "ACCORDION_HEADING_30_VARIANT_SIDE_TEXT_WEIGHT": 400,
  "ACCORDION_HEADING_30_VARIANT_SIDE_TEXT_CASE": "regular",
  "ACCORDION_HEADING_30_VARIANT_SIDE_TEXT_NUMBER_SPACING": "default",
  "ACCORDION_HEADING_30_VARIANT_SIDE_TEXT_SCALE": "paragraph-30",
  "ACCORDION_HEADING_30_VARIANT_SIDE_TEXT_FONT_FAMILY": "Square Sans Text",
  "ACCORDION_HEADING_30_VARIANT_VERTICAL_PADDING": 24,
  "ACCORDION_HEADING_30_VARIANT_EXPANDED_PHASE_ICON_16_FIDELITY_ASSET": "chevron-down",
  "ACCORDION_HEADING_30_VARIANT_EXPANDED_PHASE_ICON_16_FIDELITY_WIDTH": 16,
  "ACCORDION_HEADING_30_VARIANT_EXPANDED_PHASE_ICON_16_FIDELITY_HEIGHT": 16,
  "ACCORDION_HEADING_30_VARIANT_EXPANDED_PHASE_ICON_16_FIDELITY_TINTABLE": true,
  "ACCORDION_HEADING_30_VARIANT_EXPANDED_PHASE_ICON_ASSET": "chevron-down",
  "ACCORDION_HEADING_30_VARIANT_EXPANDED_PHASE_ICON_WIDTH": 16,
  "ACCORDION_HEADING_30_VARIANT_EXPANDED_PHASE_ICON_HEIGHT": 16,
  "ACCORDION_HEADING_30_VARIANT_EXPANDED_PHASE_ICON_TINTABLE": true,
  "ACCORDION_HEADING_30_VARIANT_EXPANDED_PHASE_SPACING": 8,
  "ACCORDION_HEADING_30_VARIANT_COLLAPSED_PHASE_ICON_16_FIDELITY_ASSET": "chevron-up",
  "ACCORDION_HEADING_30_VARIANT_COLLAPSED_PHASE_ICON_16_FIDELITY_WIDTH": 16,
  "ACCORDION_HEADING_30_VARIANT_COLLAPSED_PHASE_ICON_16_FIDELITY_HEIGHT": 16,
  "ACCORDION_HEADING_30_VARIANT_COLLAPSED_PHASE_ICON_16_FIDELITY_TINTABLE": true,
  "ACCORDION_HEADING_30_VARIANT_COLLAPSED_PHASE_ICON_ASSET": "chevron-up",
  "ACCORDION_HEADING_30_VARIANT_COLLAPSED_PHASE_ICON_WIDTH": 16,
  "ACCORDION_HEADING_30_VARIANT_COLLAPSED_PHASE_ICON_HEIGHT": 16,
  "ACCORDION_HEADING_30_VARIANT_COLLAPSED_PHASE_ICON_TINTABLE": true,
  "ACTION_CARD_BORDER_RADIUS": 6,
  "ACTION_CARD_BORDER_HOVER_STATE_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "ACTION_CARD_BORDER_HOVER_STATE_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "ACTION_CARD_BORDER_HOVER_STATE_WIDTH": 1,
  "ACTION_CARD_BORDER_HOVER_STATE_COLOR": "rgba(0, 0, 0, 0.9)",
  "ACTION_CARD_BORDER_SELECTED_STATE_LIGHT_MODE_COLOR": "rgb(0, 106, 255)",
  "ACTION_CARD_BORDER_SELECTED_STATE_DARK_MODE_COLOR": "rgb(0, 106, 255)",
  "ACTION_CARD_BORDER_SELECTED_STATE_WIDTH": 2,
  "ACTION_CARD_BORDER_SELECTED_STATE_COLOR": "rgb(0, 106, 255)",
  "ACTION_CARD_BORDER_DISABLED_STATE_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.15)",
  "ACTION_CARD_BORDER_DISABLED_STATE_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.15)",
  "ACTION_CARD_BORDER_DISABLED_STATE_WIDTH": 1,
  "ACTION_CARD_BORDER_DISABLED_STATE_COLOR": "rgba(0, 0, 0, 0.15)",
  "ACTION_CARD_NORMAL_STATE_CONTENT_OPACITY": 1,
  "ACTION_CARD_NORMAL_STATE_UNSELECTED_VALUE_BORDER_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.15)",
  "ACTION_CARD_NORMAL_STATE_UNSELECTED_VALUE_BORDER_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.15)",
  "ACTION_CARD_NORMAL_STATE_UNSELECTED_VALUE_BORDER_WIDTH": 1,
  "ACTION_CARD_NORMAL_STATE_UNSELECTED_VALUE_BORDER_COLOR": "rgba(0, 0, 0, 0.15)",
  "ACTION_CARD_NORMAL_STATE_SELECTED_VALUE_BORDER_LIGHT_MODE_COLOR": "rgb(0, 106, 255)",
  "ACTION_CARD_NORMAL_STATE_SELECTED_VALUE_BORDER_DARK_MODE_COLOR": "rgb(0, 106, 255)",
  "ACTION_CARD_NORMAL_STATE_SELECTED_VALUE_BORDER_WIDTH": 2,
  "ACTION_CARD_NORMAL_STATE_SELECTED_VALUE_BORDER_COLOR": "rgb(0, 106, 255)",
  "ACTION_CARD_HOVER_STATE_CONTENT_OPACITY": 1,
  "ACTION_CARD_HOVER_STATE_UNSELECTED_VALUE_BORDER_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "ACTION_CARD_HOVER_STATE_UNSELECTED_VALUE_BORDER_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "ACTION_CARD_HOVER_STATE_UNSELECTED_VALUE_BORDER_WIDTH": 1,
  "ACTION_CARD_HOVER_STATE_UNSELECTED_VALUE_BORDER_COLOR": "rgba(0, 0, 0, 0.9)",
  "ACTION_CARD_HOVER_STATE_SELECTED_VALUE_BORDER_LIGHT_MODE_COLOR": "rgb(0, 106, 255)",
  "ACTION_CARD_HOVER_STATE_SELECTED_VALUE_BORDER_DARK_MODE_COLOR": "rgb(0, 106, 255)",
  "ACTION_CARD_HOVER_STATE_SELECTED_VALUE_BORDER_WIDTH": 2,
  "ACTION_CARD_HOVER_STATE_SELECTED_VALUE_BORDER_COLOR": "rgb(0, 106, 255)",
  "ACTION_CARD_ACTIVE_STATE_CONTENT_OPACITY": 1,
  "ACTION_CARD_ACTIVE_STATE_UNSELECTED_VALUE_BORDER_LIGHT_MODE_COLOR": "rgb(0, 106, 255)",
  "ACTION_CARD_ACTIVE_STATE_UNSELECTED_VALUE_BORDER_DARK_MODE_COLOR": "rgb(0, 106, 255)",
  "ACTION_CARD_ACTIVE_STATE_UNSELECTED_VALUE_BORDER_WIDTH": 1,
  "ACTION_CARD_ACTIVE_STATE_UNSELECTED_VALUE_BORDER_COLOR": "rgb(0, 106, 255)",
  "ACTION_CARD_ACTIVE_STATE_SELECTED_VALUE_BORDER_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "ACTION_CARD_ACTIVE_STATE_SELECTED_VALUE_BORDER_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "ACTION_CARD_ACTIVE_STATE_SELECTED_VALUE_BORDER_WIDTH": 2,
  "ACTION_CARD_ACTIVE_STATE_SELECTED_VALUE_BORDER_COLOR": "rgba(0, 0, 0, 0.9)",
  "ACTION_CARD_DISABLED_STATE_CONTENT_OPACITY": 0.4,
  "ACTION_CARD_DISABLED_STATE_UNSELECTED_VALUE_BORDER_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.15)",
  "ACTION_CARD_DISABLED_STATE_UNSELECTED_VALUE_BORDER_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.15)",
  "ACTION_CARD_DISABLED_STATE_UNSELECTED_VALUE_BORDER_WIDTH": 1,
  "ACTION_CARD_DISABLED_STATE_UNSELECTED_VALUE_BORDER_COLOR": "rgba(0, 0, 0, 0.15)",
  "ACTION_CARD_DISABLED_STATE_SELECTED_VALUE_BORDER_LIGHT_MODE_COLOR": "rgb(0, 106, 255)",
  "ACTION_CARD_DISABLED_STATE_SELECTED_VALUE_BORDER_DARK_MODE_COLOR": "rgb(0, 106, 255)",
  "ACTION_CARD_DISABLED_STATE_SELECTED_VALUE_BORDER_WIDTH": 2,
  "ACTION_CARD_DISABLED_STATE_SELECTED_VALUE_BORDER_COLOR": "rgb(0, 106, 255)",
  "ACTION_CARD_BACKGROUND_LIGHT_MODE_COLOR": "rgb(255, 255, 255)",
  "ACTION_CARD_BACKGROUND_DARK_MODE_COLOR": "rgb(20, 20, 20)",
  "ACTION_CARD_BACKGROUND_COLOR": "rgb(255, 255, 255)",
  "ACTION_CARD_PADDING_HORIZONTAL_SIZE": 16,
  "ACTION_CARD_PADDING_VERTICAL_SIZE": 16,
  "ACTION_CARD_SPACING": 16,
  "ACTION_CARD_FOCUS_RING_BUFFER_SIZE": 2,
  "ACTION_CARD_FOCUS_RING_BORDER_SIZE": 2,
  "ACTION_CARD_FOCUS_RING_LIGHT_MODE_COLOR": "rgb(0, 106, 255)",
  "ACTION_CARD_FOCUS_RING_DARK_MODE_COLOR": "rgb(0, 106, 255)",
  "ACTION_CARD_FOCUS_RING_COLOR": "rgb(0, 106, 255)",
  "ACTIVITY_INDICATOR_BAR_WIDTH": 120,
  "ACTIVITY_INDICATOR_BAR_HEIGHT": 4,
  "ACTIVITY_INDICATOR_BAR_RADIUS": 2,
  "ACTIVITY_INDICATOR_RADIAL_SMALL_SIZE_DIAMETER_SIZE": 20,
  "ACTIVITY_INDICATOR_RADIAL_SMALL_SIZE_STROKE_SIZE": 2,
  "ACTIVITY_INDICATOR_RADIAL_MEDIUM_SIZE_DIAMETER_SIZE": 36,
  "ACTIVITY_INDICATOR_RADIAL_MEDIUM_SIZE_STROKE_SIZE": 4,
  "ACTIVITY_INDICATOR_RADIAL_LARGE_SIZE_DIAMETER_SIZE": 60,
  "ACTIVITY_INDICATOR_RADIAL_LARGE_SIZE_STROKE_SIZE": 6,
  "ACTIVITY_INDICATOR_RADIAL_XLARGE_SIZE_DIAMETER_SIZE": 120,
  "ACTIVITY_INDICATOR_RADIAL_XLARGE_SIZE_STROKE_SIZE": 10,
  "BANNER_BORDER_RADIUS": 6,
  "BANNER_BORDER_WIDTH": 1,
  "BANNER_DISMISS_BUTTON_NORMAL_STATE_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "BANNER_DISMISS_BUTTON_NORMAL_STATE_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "BANNER_DISMISS_BUTTON_NORMAL_STATE_COLOR": "rgba(0, 0, 0, 0.9)",
  "BANNER_DISMISS_BUTTON_PRESSED_STATE_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.55)",
  "BANNER_DISMISS_BUTTON_PRESSED_STATE_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.3)",
  "BANNER_DISMISS_BUTTON_PRESSED_STATE_COLOR": "rgba(0, 0, 0, 0.55)",
  "BANNER_DISMISS_BUTTON_ICON_ASSET": "x",
  "BANNER_DISMISS_BUTTON_ICON_WIDTH": 24,
  "BANNER_DISMISS_BUTTON_ICON_HEIGHT": 24,
  "BANNER_DISMISS_BUTTON_ICON_TINTABLE": true,
  "BANNER_BUTTON_SEPARATOR_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "BANNER_BUTTON_SEPARATOR_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "BANNER_BUTTON_SEPARATOR_HEIGHT": 8,
  "BANNER_BUTTON_SEPARATOR_OPACITY": 0.3,
  "BANNER_BUTTON_SEPARATOR_WIDTH": 1,
  "BANNER_BUTTON_SEPARATOR_COLOR": "rgba(0, 0, 0, 0.9)",
  "BANNER_BUTTON_SPACING": 12,
  "BANNER_BUTTON_TEXT_SIZE": 16,
  "BANNER_BUTTON_TEXT_LEADING": 24,
  "BANNER_BUTTON_TEXT_TRACKING": 0,
  "BANNER_BUTTON_TEXT_WEIGHT": 600,
  "BANNER_BUTTON_TEXT_CASE": "regular",
  "BANNER_BUTTON_TEXT_NUMBER_SPACING": "default",
  "BANNER_BUTTON_TEXT_SCALE": "paragraph-30",
  "BANNER_BUTTON_TEXT_FONT_FAMILY": "Square Sans Text",
  "BANNER_CONTENT_SPACING": 12,
  "BANNER_HORIZONTAL_PADDING": 16,
  "BANNER_ICON_SPACING": 12,
  "BANNER_MULTILINE_SPACING": 12,
  "BANNER_VERTICAL_PADDING": 16,
  "BANNER_TITLE_SIZE": 16,
  "BANNER_TITLE_LEADING": 24,
  "BANNER_TITLE_TRACKING": 0,
  "BANNER_TITLE_WEIGHT": 600,
  "BANNER_TITLE_CASE": "regular",
  "BANNER_TITLE_NUMBER_SPACING": "default",
  "BANNER_TITLE_SCALE": "paragraph-30",
  "BANNER_TITLE_FONT_FAMILY": "Square Sans Text",
  "BANNER_TEXT_SIZE": 16,
  "BANNER_TEXT_LEADING": 24,
  "BANNER_TEXT_TRACKING": 0,
  "BANNER_TEXT_WEIGHT": 400,
  "BANNER_TEXT_CASE": "regular",
  "BANNER_TEXT_NUMBER_SPACING": "default",
  "BANNER_TEXT_SCALE": "paragraph-30",
  "BANNER_TEXT_FONT_FAMILY": "Square Sans Text",
  "BANNER_INFO_VARIANT_LIGHT_MODE_BACKGROUND_COLOR": "rgb(230, 240, 255)",
  "BANNER_INFO_VARIANT_LIGHT_MODE_BORDER_COLOR": "rgb(204, 225, 255)",
  "BANNER_INFO_VARIANT_LIGHT_MODE_ICON_COLOR": "rgb(0, 106, 255)",
  "BANNER_INFO_VARIANT_LIGHT_MODE_TEXT_COLOR": "rgba(0, 0, 0, 0.9)",
  "BANNER_INFO_VARIANT_LIGHT_MODE_BUTTON_TEXT_COLOR": "rgb(0, 90, 217)",
  "BANNER_INFO_VARIANT_LIGHT_MODE_TEXT_LINK_TEXT_COLOR": "rgb(0, 90, 217)",
  "BANNER_INFO_VARIANT_DARK_MODE_BACKGROUND_COLOR": "rgb(0, 21, 51)",
  "BANNER_INFO_VARIANT_DARK_MODE_BORDER_COLOR": "rgb(0, 37, 89)",
  "BANNER_INFO_VARIANT_DARK_MODE_ICON_COLOR": "rgb(0, 106, 255)",
  "BANNER_INFO_VARIANT_DARK_MODE_TEXT_COLOR": "rgba(255, 255, 255, 0.95)",
  "BANNER_INFO_VARIANT_DARK_MODE_BUTTON_TEXT_COLOR": "rgb(74, 149, 255)",
  "BANNER_INFO_VARIANT_DARK_MODE_TEXT_LINK_TEXT_COLOR": "rgb(74, 149, 255)",
  "BANNER_INFO_VARIANT_BACKGROUND_COLOR": "rgb(230, 240, 255)",
  "BANNER_INFO_VARIANT_BORDER_COLOR": "rgb(204, 225, 255)",
  "BANNER_INFO_VARIANT_ICON_COLOR": "rgb(0, 106, 255)",
  "BANNER_INFO_VARIANT_TEXT_COLOR": "rgba(0, 0, 0, 0.9)",
  "BANNER_INFO_VARIANT_BUTTON_TEXT_COLOR": "rgb(0, 90, 217)",
  "BANNER_INFO_VARIANT_TEXT_LINK_TEXT_COLOR": "rgb(0, 90, 217)",
  "BANNER_SUCCESS_VARIANT_LIGHT_MODE_BACKGROUND_COLOR": "rgb(229, 255, 238)",
  "BANNER_SUCCESS_VARIANT_LIGHT_MODE_BORDER_COLOR": "rgb(204, 255, 221)",
  "BANNER_SUCCESS_VARIANT_LIGHT_MODE_ICON_COLOR": "rgb(0, 178, 59)",
  "BANNER_SUCCESS_VARIANT_LIGHT_MODE_TEXT_COLOR": "rgba(0, 0, 0, 0.9)",
  "BANNER_SUCCESS_VARIANT_LIGHT_MODE_BUTTON_TEXT_COLOR": "rgb(0, 125, 42)",
  "BANNER_SUCCESS_VARIANT_LIGHT_MODE_TEXT_LINK_TEXT_COLOR": "rgb(0, 125, 42)",
  "BANNER_SUCCESS_VARIANT_DARK_MODE_BACKGROUND_COLOR": "rgb(0, 51, 17)",
  "BANNER_SUCCESS_VARIANT_DARK_MODE_BORDER_COLOR": "rgb(0, 89, 30)",
  "BANNER_SUCCESS_VARIANT_DARK_MODE_ICON_COLOR": "rgb(0, 179, 59)",
  "BANNER_SUCCESS_VARIANT_DARK_MODE_TEXT_COLOR": "rgba(255, 255, 255, 0.95)",
  "BANNER_SUCCESS_VARIANT_DARK_MODE_BUTTON_TEXT_COLOR": "rgb(22, 217, 86)",
  "BANNER_SUCCESS_VARIANT_DARK_MODE_TEXT_LINK_TEXT_COLOR": "rgb(22, 217, 86)",
  "BANNER_SUCCESS_VARIANT_BACKGROUND_COLOR": "rgb(229, 255, 238)",
  "BANNER_SUCCESS_VARIANT_BORDER_COLOR": "rgb(204, 255, 221)",
  "BANNER_SUCCESS_VARIANT_ICON_COLOR": "rgb(0, 178, 59)",
  "BANNER_SUCCESS_VARIANT_TEXT_COLOR": "rgba(0, 0, 0, 0.9)",
  "BANNER_SUCCESS_VARIANT_BUTTON_TEXT_COLOR": "rgb(0, 125, 42)",
  "BANNER_SUCCESS_VARIANT_TEXT_LINK_TEXT_COLOR": "rgb(0, 125, 42)",
  "BANNER_WARNING_VARIANT_LIGHT_MODE_BACKGROUND_COLOR": "rgb(255, 242, 230)",
  "BANNER_WARNING_VARIANT_LIGHT_MODE_BORDER_COLOR": "rgb(255, 230, 204)",
  "BANNER_WARNING_VARIANT_LIGHT_MODE_ICON_COLOR": "rgb(255, 159, 64)",
  "BANNER_WARNING_VARIANT_LIGHT_MODE_TEXT_COLOR": "rgba(0, 0, 0, 0.9)",
  "BANNER_WARNING_VARIANT_LIGHT_MODE_BUTTON_TEXT_COLOR": "rgb(148, 92, 37)",
  "BANNER_WARNING_VARIANT_LIGHT_MODE_TEXT_LINK_TEXT_COLOR": "rgb(148, 92, 37)",
  "BANNER_WARNING_VARIANT_DARK_MODE_BACKGROUND_COLOR": "rgb(51, 26, 0)",
  "BANNER_WARNING_VARIANT_DARK_MODE_BORDER_COLOR": "rgb(89, 45, 0)",
  "BANNER_WARNING_VARIANT_DARK_MODE_ICON_COLOR": "rgb(255, 159, 64)",
  "BANNER_WARNING_VARIANT_DARK_MODE_TEXT_COLOR": "rgba(255, 255, 255, 0.95)",
  "BANNER_WARNING_VARIANT_DARK_MODE_BUTTON_TEXT_COLOR": "rgb(255, 159, 64)",
  "BANNER_WARNING_VARIANT_DARK_MODE_TEXT_LINK_TEXT_COLOR": "rgb(255, 159, 64)",
  "BANNER_WARNING_VARIANT_BACKGROUND_COLOR": "rgb(255, 242, 230)",
  "BANNER_WARNING_VARIANT_BORDER_COLOR": "rgb(255, 230, 204)",
  "BANNER_WARNING_VARIANT_ICON_COLOR": "rgb(255, 159, 64)",
  "BANNER_WARNING_VARIANT_TEXT_COLOR": "rgba(0, 0, 0, 0.9)",
  "BANNER_WARNING_VARIANT_BUTTON_TEXT_COLOR": "rgb(148, 92, 37)",
  "BANNER_WARNING_VARIANT_TEXT_LINK_TEXT_COLOR": "rgb(148, 92, 37)",
  "BANNER_CRITICAL_VARIANT_LIGHT_MODE_BACKGROUND_COLOR": "rgb(255, 229, 234)",
  "BANNER_CRITICAL_VARIANT_LIGHT_MODE_BORDER_COLOR": "rgb(255, 204, 213)",
  "BANNER_CRITICAL_VARIANT_LIGHT_MODE_ICON_COLOR": "rgb(204, 0, 35)",
  "BANNER_CRITICAL_VARIANT_LIGHT_MODE_TEXT_COLOR": "rgba(0, 0, 0, 0.9)",
  "BANNER_CRITICAL_VARIANT_LIGHT_MODE_BUTTON_TEXT_COLOR": "rgb(191, 0, 32)",
  "BANNER_CRITICAL_VARIANT_LIGHT_MODE_TEXT_LINK_TEXT_COLOR": "rgb(191, 0, 32)",
  "BANNER_CRITICAL_VARIANT_DARK_MODE_BACKGROUND_COLOR": "rgb(51, 0, 9)",
  "BANNER_CRITICAL_VARIANT_DARK_MODE_BORDER_COLOR": "rgb(89, 0, 15)",
  "BANNER_CRITICAL_VARIANT_DARK_MODE_ICON_COLOR": "rgb(204, 0, 35)",
  "BANNER_CRITICAL_VARIANT_DARK_MODE_TEXT_COLOR": "rgba(255, 255, 255, 0.95)",
  "BANNER_CRITICAL_VARIANT_DARK_MODE_BUTTON_TEXT_COLOR": "rgb(255, 90, 118)",
  "BANNER_CRITICAL_VARIANT_DARK_MODE_TEXT_LINK_TEXT_COLOR": "rgb(255, 90, 118)",
  "BANNER_CRITICAL_VARIANT_BACKGROUND_COLOR": "rgb(255, 229, 234)",
  "BANNER_CRITICAL_VARIANT_BORDER_COLOR": "rgb(255, 204, 213)",
  "BANNER_CRITICAL_VARIANT_ICON_COLOR": "rgb(204, 0, 35)",
  "BANNER_CRITICAL_VARIANT_TEXT_COLOR": "rgba(0, 0, 0, 0.9)",
  "BANNER_CRITICAL_VARIANT_BUTTON_TEXT_COLOR": "rgb(191, 0, 32)",
  "BANNER_CRITICAL_VARIANT_TEXT_LINK_TEXT_COLOR": "rgb(191, 0, 32)",
  "BANNER_INSIGHT_VARIANT_LIGHT_MODE_BACKGROUND_COLOR": "rgb(244, 230, 255)",
  "BANNER_INSIGHT_VARIANT_LIGHT_MODE_BORDER_COLOR": "rgb(234, 204, 255)",
  "BANNER_INSIGHT_VARIANT_LIGHT_MODE_ICON_COLOR": "rgb(135, 22, 217)",
  "BANNER_INSIGHT_VARIANT_LIGHT_MODE_TEXT_COLOR": "rgba(0, 0, 0, 0.9)",
  "BANNER_INSIGHT_VARIANT_LIGHT_MODE_BUTTON_TEXT_COLOR": "rgb(135, 22, 217)",
  "BANNER_INSIGHT_VARIANT_LIGHT_MODE_TEXT_LINK_TEXT_COLOR": "rgb(135, 22, 217)",
  "BANNER_INSIGHT_VARIANT_DARK_MODE_BACKGROUND_COLOR": "rgb(30, 0, 51)",
  "BANNER_INSIGHT_VARIANT_DARK_MODE_BORDER_COLOR": "rgb(52, 0, 89)",
  "BANNER_INSIGHT_VARIANT_DARK_MODE_ICON_COLOR": "rgb(135, 22, 217)",
  "BANNER_INSIGHT_VARIANT_DARK_MODE_TEXT_COLOR": "rgba(255, 255, 255, 0.95)",
  "BANNER_INSIGHT_VARIANT_DARK_MODE_BUTTON_TEXT_COLOR": "rgb(194, 110, 255)",
  "BANNER_INSIGHT_VARIANT_DARK_MODE_TEXT_LINK_TEXT_COLOR": "rgb(194, 110, 255)",
  "BANNER_INSIGHT_VARIANT_BACKGROUND_COLOR": "rgb(244, 230, 255)",
  "BANNER_INSIGHT_VARIANT_BORDER_COLOR": "rgb(234, 204, 255)",
  "BANNER_INSIGHT_VARIANT_ICON_COLOR": "rgb(135, 22, 217)",
  "BANNER_INSIGHT_VARIANT_TEXT_COLOR": "rgba(0, 0, 0, 0.9)",
  "BANNER_INSIGHT_VARIANT_BUTTON_TEXT_COLOR": "rgb(135, 22, 217)",
  "BANNER_INSIGHT_VARIANT_TEXT_LINK_TEXT_COLOR": "rgb(135, 22, 217)",
  "BUTTON_GROUP_SPACING": 16,
  "BUTTON_GROUP_OVERFLOW_BUTTON_MINIMUM_HEIGHT": 48,
  "BUTTON_GROUP_OVERFLOW_BUTTON_ICON_ASSET": "ellipsis-horizontal",
  "BUTTON_GROUP_OVERFLOW_BUTTON_ICON_WIDTH": 24,
  "BUTTON_GROUP_OVERFLOW_BUTTON_ICON_HEIGHT": 24,
  "BUTTON_GROUP_OVERFLOW_BUTTON_ICON_TINTABLE": true,
  "BUTTON_GROUP_OVERFLOW_BUTTON_WIDTH": 48,
  "BUTTON_GROUP_OVERFLOW_BUTTON_SIZE_MULTIPLIER": 1,
  "BUTTON_COMPACT_HORIZONTAL_SIZE_CLASS_SAMPLE_TOKEN": 100,
  "BUTTON_REGULAR_HORIZONTAL_SIZE_CLASS_SAMPLE_TOKEN": 200,
  "BUTTON_WIDE_HORIZONTAL_SIZE_CLASS_SAMPLE_TOKEN": 300,
  "BUTTON_COMPACT_VERTICAL_SIZE_CLASS_SAMPLE_TOKEN_2": 10,
  "BUTTON_REGULAR_VERTICAL_SIZE_CLASS_SAMPLE_TOKEN_2": 20,
  "BUTTON_TALL_VERTICAL_SIZE_CLASS_SAMPLE_TOKEN_2": 30,
  "BUTTON_SMALL_SIZE_MINIMUM_HEIGHT": 40,
  "BUTTON_SMALL_SIZE_MINIMUM_WIDTH_MULTIPLIER": 1.5,
  "BUTTON_SMALL_SIZE_TEXT_SIZE": 14,
  "BUTTON_SMALL_SIZE_TEXT_LEADING": 22,
  "BUTTON_SMALL_SIZE_TEXT_TRACKING": 0,
  "BUTTON_SMALL_SIZE_TEXT_WEIGHT": 600,
  "BUTTON_SMALL_SIZE_TEXT_CASE": "regular",
  "BUTTON_SMALL_SIZE_TEXT_NUMBER_SPACING": "default",
  "BUTTON_SMALL_SIZE_TEXT_SCALE": "paragraph-20",
  "BUTTON_SMALL_SIZE_TEXT_FONT_FAMILY": "Square Sans Text",
  "BUTTON_SMALL_SIZE_CONTENT_SPACING": 8,
  "BUTTON_SMALL_SIZE_PRIMARY_RANK_HORIZONTAL_PADDING": 12,
  "BUTTON_SMALL_SIZE_PRIMARY_RANK_VERTICAL_PADDING": 9,
  "BUTTON_SMALL_SIZE_SECONDARY_RANK_HORIZONTAL_PADDING": 12,
  "BUTTON_SMALL_SIZE_SECONDARY_RANK_VERTICAL_PADDING": 9,
  "BUTTON_SMALL_SIZE_TERTIARY_RANK_HORIZONTAL_PADDING": 8,
  "BUTTON_SMALL_SIZE_TERTIARY_RANK_VERTICAL_PADDING": 9,
  "BUTTON_MEDIUM_SIZE_MINIMUM_HEIGHT": 48,
  "BUTTON_MEDIUM_SIZE_MINIMUM_WIDTH_MULTIPLIER": 1.5,
  "BUTTON_MEDIUM_SIZE_TEXT_SIZE": 16,
  "BUTTON_MEDIUM_SIZE_TEXT_LEADING": 24,
  "BUTTON_MEDIUM_SIZE_TEXT_TRACKING": 0,
  "BUTTON_MEDIUM_SIZE_TEXT_WEIGHT": 600,
  "BUTTON_MEDIUM_SIZE_TEXT_CASE": "regular",
  "BUTTON_MEDIUM_SIZE_TEXT_NUMBER_SPACING": "default",
  "BUTTON_MEDIUM_SIZE_TEXT_SCALE": "paragraph-30",
  "BUTTON_MEDIUM_SIZE_TEXT_FONT_FAMILY": "Square Sans Text",
  "BUTTON_MEDIUM_SIZE_CONTENT_SPACING": 8,
  "BUTTON_MEDIUM_SIZE_PRIMARY_RANK_HORIZONTAL_PADDING": 16,
  "BUTTON_MEDIUM_SIZE_PRIMARY_RANK_VERTICAL_PADDING": 12,
  "BUTTON_MEDIUM_SIZE_SECONDARY_RANK_HORIZONTAL_PADDING": 16,
  "BUTTON_MEDIUM_SIZE_SECONDARY_RANK_VERTICAL_PADDING": 12,
  "BUTTON_MEDIUM_SIZE_TERTIARY_RANK_HORIZONTAL_PADDING": 8,
  "BUTTON_MEDIUM_SIZE_TERTIARY_RANK_VERTICAL_PADDING": 12,
  "BUTTON_LARGE_SIZE_MINIMUM_HEIGHT": 64,
  "BUTTON_LARGE_SIZE_MINIMUM_WIDTH_MULTIPLIER": 1.5,
  "BUTTON_LARGE_SIZE_TEXT_SIZE": 16,
  "BUTTON_LARGE_SIZE_TEXT_LEADING": 24,
  "BUTTON_LARGE_SIZE_TEXT_TRACKING": 0,
  "BUTTON_LARGE_SIZE_TEXT_WEIGHT": 600,
  "BUTTON_LARGE_SIZE_TEXT_CASE": "regular",
  "BUTTON_LARGE_SIZE_TEXT_NUMBER_SPACING": "default",
  "BUTTON_LARGE_SIZE_TEXT_SCALE": "paragraph-30",
  "BUTTON_LARGE_SIZE_TEXT_FONT_FAMILY": "Square Sans Text",
  "BUTTON_LARGE_SIZE_CONTENT_SPACING": 8,
  "BUTTON_LARGE_SIZE_PRIMARY_RANK_HORIZONTAL_PADDING": 20,
  "BUTTON_LARGE_SIZE_PRIMARY_RANK_VERTICAL_PADDING": 20,
  "BUTTON_LARGE_SIZE_SECONDARY_RANK_HORIZONTAL_PADDING": 20,
  "BUTTON_LARGE_SIZE_SECONDARY_RANK_VERTICAL_PADDING": 20,
  "BUTTON_LARGE_SIZE_TERTIARY_RANK_HORIZONTAL_PADDING": 8,
  "BUTTON_LARGE_SIZE_TERTIARY_RANK_VERTICAL_PADDING": 20,
  "BUTTON_BORDER_RADIUS": 6,
  "BUTTON_FOCUS_RING_BUFFER_SIZE": 2,
  "BUTTON_FOCUS_RING_BORDER_SIZE": 2,
  "BUTTON_FOCUS_RING_COLOR": "rgb(0, 106, 255)",
  "BUTTON_LIGHT_MODE_FOCUS_RING_COLOR": "rgb(0, 106, 255)",
  "BUTTON_DARK_MODE_FOCUS_RING_COLOR": "rgb(0, 106, 255)",
  "BUTTON_NORMAL_VARIANT_PRIMARY_RANK_NORMAL_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(0, 106, 255)",
  "BUTTON_NORMAL_VARIANT_PRIMARY_RANK_NORMAL_STATE_LIGHT_MODE_LABEL_COLOR": "rgb(255, 255, 255)",
  "BUTTON_NORMAL_VARIANT_PRIMARY_RANK_NORMAL_STATE_DARK_MODE_BACKGROUND_COLOR": "rgb(0, 106, 255)",
  "BUTTON_NORMAL_VARIANT_PRIMARY_RANK_NORMAL_STATE_DARK_MODE_LABEL_COLOR": "rgb(255, 255, 255)",
  "BUTTON_NORMAL_VARIANT_PRIMARY_RANK_NORMAL_STATE_BACKGROUND_COLOR": "rgb(0, 106, 255)",
  "BUTTON_NORMAL_VARIANT_PRIMARY_RANK_NORMAL_STATE_LABEL_COLOR": "rgb(255, 255, 255)",
  "BUTTON_NORMAL_VARIANT_PRIMARY_RANK_HOVER_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(0, 95, 229)",
  "BUTTON_NORMAL_VARIANT_PRIMARY_RANK_HOVER_STATE_LIGHT_MODE_LABEL_COLOR": "rgb(255, 255, 255)",
  "BUTTON_NORMAL_VARIANT_PRIMARY_RANK_HOVER_STATE_DARK_MODE_BACKGROUND_COLOR": "rgb(0, 95, 229)",
  "BUTTON_NORMAL_VARIANT_PRIMARY_RANK_HOVER_STATE_DARK_MODE_LABEL_COLOR": "rgb(255, 255, 255)",
  "BUTTON_NORMAL_VARIANT_PRIMARY_RANK_HOVER_STATE_BACKGROUND_COLOR": "rgb(0, 95, 229)",
  "BUTTON_NORMAL_VARIANT_PRIMARY_RANK_HOVER_STATE_LABEL_COLOR": "rgb(255, 255, 255)",
  "BUTTON_NORMAL_VARIANT_PRIMARY_RANK_PRESSED_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(0, 85, 204)",
  "BUTTON_NORMAL_VARIANT_PRIMARY_RANK_PRESSED_STATE_LIGHT_MODE_LABEL_COLOR": "rgb(255, 255, 255)",
  "BUTTON_NORMAL_VARIANT_PRIMARY_RANK_PRESSED_STATE_DARK_MODE_BACKGROUND_COLOR": "rgb(0, 85, 204)",
  "BUTTON_NORMAL_VARIANT_PRIMARY_RANK_PRESSED_STATE_DARK_MODE_LABEL_COLOR": "rgb(255, 255, 255)",
  "BUTTON_NORMAL_VARIANT_PRIMARY_RANK_PRESSED_STATE_BACKGROUND_COLOR": "rgb(0, 85, 204)",
  "BUTTON_NORMAL_VARIANT_PRIMARY_RANK_PRESSED_STATE_LABEL_COLOR": "rgb(255, 255, 255)",
  "BUTTON_NORMAL_VARIANT_PRIMARY_RANK_FOCUS_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(0, 106, 255)",
  "BUTTON_NORMAL_VARIANT_PRIMARY_RANK_FOCUS_STATE_LIGHT_MODE_LABEL_COLOR": "rgb(255, 255, 255)",
  "BUTTON_NORMAL_VARIANT_PRIMARY_RANK_FOCUS_STATE_DARK_MODE_BACKGROUND_COLOR": "rgb(0, 106, 255)",
  "BUTTON_NORMAL_VARIANT_PRIMARY_RANK_FOCUS_STATE_DARK_MODE_LABEL_COLOR": "rgb(255, 255, 255)",
  "BUTTON_NORMAL_VARIANT_PRIMARY_RANK_FOCUS_STATE_BACKGROUND_COLOR": "rgb(0, 106, 255)",
  "BUTTON_NORMAL_VARIANT_PRIMARY_RANK_FOCUS_STATE_LABEL_COLOR": "rgb(255, 255, 255)",
  "BUTTON_NORMAL_VARIANT_PRIMARY_RANK_DISABLED_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.05)",
  "BUTTON_NORMAL_VARIANT_PRIMARY_RANK_DISABLED_STATE_LIGHT_MODE_LABEL_COLOR": "rgba(0, 0, 0, 0.3)",
  "BUTTON_NORMAL_VARIANT_PRIMARY_RANK_DISABLED_STATE_DARK_MODE_BACKGROUND_COLOR": "rgba(255, 255, 255, 0.08)",
  "BUTTON_NORMAL_VARIANT_PRIMARY_RANK_DISABLED_STATE_DARK_MODE_LABEL_COLOR": "rgba(255, 255, 255, 0.3)",
  "BUTTON_NORMAL_VARIANT_PRIMARY_RANK_DISABLED_STATE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.05)",
  "BUTTON_NORMAL_VARIANT_PRIMARY_RANK_DISABLED_STATE_LABEL_COLOR": "rgba(0, 0, 0, 0.3)",
  "BUTTON_NORMAL_VARIANT_SECONDARY_RANK_NORMAL_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.05)",
  "BUTTON_NORMAL_VARIANT_SECONDARY_RANK_NORMAL_STATE_LIGHT_MODE_LABEL_COLOR": "rgb(0, 90, 217)",
  "BUTTON_NORMAL_VARIANT_SECONDARY_RANK_NORMAL_STATE_DARK_MODE_BACKGROUND_COLOR": "rgba(255, 255, 255, 0.08)",
  "BUTTON_NORMAL_VARIANT_SECONDARY_RANK_NORMAL_STATE_DARK_MODE_LABEL_COLOR": "rgb(74, 149, 255)",
  "BUTTON_NORMAL_VARIANT_SECONDARY_RANK_NORMAL_STATE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.05)",
  "BUTTON_NORMAL_VARIANT_SECONDARY_RANK_NORMAL_STATE_LABEL_COLOR": "rgb(0, 90, 217)",
  "BUTTON_NORMAL_VARIANT_SECONDARY_RANK_HOVER_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(230, 240, 255)",
  "BUTTON_NORMAL_VARIANT_SECONDARY_RANK_HOVER_STATE_LIGHT_MODE_LABEL_COLOR": "rgb(0, 90, 217)",
  "BUTTON_NORMAL_VARIANT_SECONDARY_RANK_HOVER_STATE_DARK_MODE_BACKGROUND_COLOR": "rgb(0, 21, 51)",
  "BUTTON_NORMAL_VARIANT_SECONDARY_RANK_HOVER_STATE_DARK_MODE_LABEL_COLOR": "rgb(74, 149, 255)",
  "BUTTON_NORMAL_VARIANT_SECONDARY_RANK_HOVER_STATE_BACKGROUND_COLOR": "rgb(230, 240, 255)",
  "BUTTON_NORMAL_VARIANT_SECONDARY_RANK_HOVER_STATE_LABEL_COLOR": "rgb(0, 90, 217)",
  "BUTTON_NORMAL_VARIANT_SECONDARY_RANK_PRESSED_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(204, 225, 255)",
  "BUTTON_NORMAL_VARIANT_SECONDARY_RANK_PRESSED_STATE_LIGHT_MODE_LABEL_COLOR": "rgb(0, 90, 217)",
  "BUTTON_NORMAL_VARIANT_SECONDARY_RANK_PRESSED_STATE_DARK_MODE_BACKGROUND_COLOR": "rgb(0, 37, 89)",
  "BUTTON_NORMAL_VARIANT_SECONDARY_RANK_PRESSED_STATE_DARK_MODE_LABEL_COLOR": "rgb(74, 149, 255)",
  "BUTTON_NORMAL_VARIANT_SECONDARY_RANK_PRESSED_STATE_BACKGROUND_COLOR": "rgb(204, 225, 255)",
  "BUTTON_NORMAL_VARIANT_SECONDARY_RANK_PRESSED_STATE_LABEL_COLOR": "rgb(0, 90, 217)",
  "BUTTON_NORMAL_VARIANT_SECONDARY_RANK_FOCUS_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(230, 240, 255)",
  "BUTTON_NORMAL_VARIANT_SECONDARY_RANK_FOCUS_STATE_LIGHT_MODE_LABEL_COLOR": "rgb(0, 90, 217)",
  "BUTTON_NORMAL_VARIANT_SECONDARY_RANK_FOCUS_STATE_DARK_MODE_BACKGROUND_COLOR": "rgb(0, 21, 51)",
  "BUTTON_NORMAL_VARIANT_SECONDARY_RANK_FOCUS_STATE_DARK_MODE_LABEL_COLOR": "rgb(74, 149, 255)",
  "BUTTON_NORMAL_VARIANT_SECONDARY_RANK_FOCUS_STATE_BACKGROUND_COLOR": "rgb(230, 240, 255)",
  "BUTTON_NORMAL_VARIANT_SECONDARY_RANK_FOCUS_STATE_LABEL_COLOR": "rgb(0, 90, 217)",
  "BUTTON_NORMAL_VARIANT_SECONDARY_RANK_DISABLED_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.05)",
  "BUTTON_NORMAL_VARIANT_SECONDARY_RANK_DISABLED_STATE_LIGHT_MODE_LABEL_COLOR": "rgba(0, 0, 0, 0.3)",
  "BUTTON_NORMAL_VARIANT_SECONDARY_RANK_DISABLED_STATE_DARK_MODE_BACKGROUND_COLOR": "rgba(255, 255, 255, 0.08)",
  "BUTTON_NORMAL_VARIANT_SECONDARY_RANK_DISABLED_STATE_DARK_MODE_LABEL_COLOR": "rgba(255, 255, 255, 0.3)",
  "BUTTON_NORMAL_VARIANT_SECONDARY_RANK_DISABLED_STATE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.05)",
  "BUTTON_NORMAL_VARIANT_SECONDARY_RANK_DISABLED_STATE_LABEL_COLOR": "rgba(0, 0, 0, 0.3)",
  "BUTTON_NORMAL_VARIANT_TERTIARY_RANK_NORMAL_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "BUTTON_NORMAL_VARIANT_TERTIARY_RANK_NORMAL_STATE_LIGHT_MODE_LABEL_COLOR": "rgb(0, 90, 217)",
  "BUTTON_NORMAL_VARIANT_TERTIARY_RANK_NORMAL_STATE_DARK_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "BUTTON_NORMAL_VARIANT_TERTIARY_RANK_NORMAL_STATE_DARK_MODE_LABEL_COLOR": "rgb(74, 149, 255)",
  "BUTTON_NORMAL_VARIANT_TERTIARY_RANK_NORMAL_STATE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "BUTTON_NORMAL_VARIANT_TERTIARY_RANK_NORMAL_STATE_LABEL_COLOR": "rgb(0, 90, 217)",
  "BUTTON_NORMAL_VARIANT_TERTIARY_RANK_HOVER_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(230, 240, 255)",
  "BUTTON_NORMAL_VARIANT_TERTIARY_RANK_HOVER_STATE_LIGHT_MODE_LABEL_COLOR": "rgb(0, 90, 217)",
  "BUTTON_NORMAL_VARIANT_TERTIARY_RANK_HOVER_STATE_DARK_MODE_BACKGROUND_COLOR": "rgb(0, 21, 51)",
  "BUTTON_NORMAL_VARIANT_TERTIARY_RANK_HOVER_STATE_DARK_MODE_LABEL_COLOR": "rgb(74, 149, 255)",
  "BUTTON_NORMAL_VARIANT_TERTIARY_RANK_HOVER_STATE_BACKGROUND_COLOR": "rgb(230, 240, 255)",
  "BUTTON_NORMAL_VARIANT_TERTIARY_RANK_HOVER_STATE_LABEL_COLOR": "rgb(0, 90, 217)",
  "BUTTON_NORMAL_VARIANT_TERTIARY_RANK_PRESSED_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(204, 225, 255)",
  "BUTTON_NORMAL_VARIANT_TERTIARY_RANK_PRESSED_STATE_LIGHT_MODE_LABEL_COLOR": "rgb(0, 90, 217)",
  "BUTTON_NORMAL_VARIANT_TERTIARY_RANK_PRESSED_STATE_DARK_MODE_BACKGROUND_COLOR": "rgb(0, 37, 89)",
  "BUTTON_NORMAL_VARIANT_TERTIARY_RANK_PRESSED_STATE_DARK_MODE_LABEL_COLOR": "rgb(74, 149, 255)",
  "BUTTON_NORMAL_VARIANT_TERTIARY_RANK_PRESSED_STATE_BACKGROUND_COLOR": "rgb(204, 225, 255)",
  "BUTTON_NORMAL_VARIANT_TERTIARY_RANK_PRESSED_STATE_LABEL_COLOR": "rgb(0, 90, 217)",
  "BUTTON_NORMAL_VARIANT_TERTIARY_RANK_FOCUS_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "BUTTON_NORMAL_VARIANT_TERTIARY_RANK_FOCUS_STATE_LIGHT_MODE_LABEL_COLOR": "rgb(0, 90, 217)",
  "BUTTON_NORMAL_VARIANT_TERTIARY_RANK_FOCUS_STATE_DARK_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "BUTTON_NORMAL_VARIANT_TERTIARY_RANK_FOCUS_STATE_DARK_MODE_LABEL_COLOR": "rgb(74, 149, 255)",
  "BUTTON_NORMAL_VARIANT_TERTIARY_RANK_FOCUS_STATE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "BUTTON_NORMAL_VARIANT_TERTIARY_RANK_FOCUS_STATE_LABEL_COLOR": "rgb(0, 90, 217)",
  "BUTTON_NORMAL_VARIANT_TERTIARY_RANK_DISABLED_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "BUTTON_NORMAL_VARIANT_TERTIARY_RANK_DISABLED_STATE_LIGHT_MODE_LABEL_COLOR": "rgba(0, 0, 0, 0.3)",
  "BUTTON_NORMAL_VARIANT_TERTIARY_RANK_DISABLED_STATE_DARK_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "BUTTON_NORMAL_VARIANT_TERTIARY_RANK_DISABLED_STATE_DARK_MODE_LABEL_COLOR": "rgba(255, 255, 255, 0.3)",
  "BUTTON_NORMAL_VARIANT_TERTIARY_RANK_DISABLED_STATE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "BUTTON_NORMAL_VARIANT_TERTIARY_RANK_DISABLED_STATE_LABEL_COLOR": "rgba(0, 0, 0, 0.3)",
  "BUTTON_DESTRUCTIVE_VARIANT_PRIMARY_RANK_NORMAL_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(204, 0, 35)",
  "BUTTON_DESTRUCTIVE_VARIANT_PRIMARY_RANK_NORMAL_STATE_LIGHT_MODE_LABEL_COLOR": "rgb(255, 255, 255)",
  "BUTTON_DESTRUCTIVE_VARIANT_PRIMARY_RANK_NORMAL_STATE_DARK_MODE_BACKGROUND_COLOR": "rgb(204, 0, 35)",
  "BUTTON_DESTRUCTIVE_VARIANT_PRIMARY_RANK_NORMAL_STATE_DARK_MODE_LABEL_COLOR": "rgb(255, 255, 255)",
  "BUTTON_DESTRUCTIVE_VARIANT_PRIMARY_RANK_NORMAL_STATE_BACKGROUND_COLOR": "rgb(204, 0, 35)",
  "BUTTON_DESTRUCTIVE_VARIANT_PRIMARY_RANK_NORMAL_STATE_LABEL_COLOR": "rgb(255, 255, 255)",
  "BUTTON_DESTRUCTIVE_VARIANT_PRIMARY_RANK_HOVER_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(178, 0, 30)",
  "BUTTON_DESTRUCTIVE_VARIANT_PRIMARY_RANK_HOVER_STATE_LIGHT_MODE_LABEL_COLOR": "rgb(255, 255, 255)",
  "BUTTON_DESTRUCTIVE_VARIANT_PRIMARY_RANK_HOVER_STATE_DARK_MODE_BACKGROUND_COLOR": "rgb(178, 0, 30)",
  "BUTTON_DESTRUCTIVE_VARIANT_PRIMARY_RANK_HOVER_STATE_DARK_MODE_LABEL_COLOR": "rgb(255, 255, 255)",
  "BUTTON_DESTRUCTIVE_VARIANT_PRIMARY_RANK_HOVER_STATE_BACKGROUND_COLOR": "rgb(178, 0, 30)",
  "BUTTON_DESTRUCTIVE_VARIANT_PRIMARY_RANK_HOVER_STATE_LABEL_COLOR": "rgb(255, 255, 255)",
  "BUTTON_DESTRUCTIVE_VARIANT_PRIMARY_RANK_PRESSED_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(153, 0, 26)",
  "BUTTON_DESTRUCTIVE_VARIANT_PRIMARY_RANK_PRESSED_STATE_LIGHT_MODE_LABEL_COLOR": "rgb(255, 255, 255)",
  "BUTTON_DESTRUCTIVE_VARIANT_PRIMARY_RANK_PRESSED_STATE_DARK_MODE_BACKGROUND_COLOR": "rgb(153, 0, 26)",
  "BUTTON_DESTRUCTIVE_VARIANT_PRIMARY_RANK_PRESSED_STATE_DARK_MODE_LABEL_COLOR": "rgb(255, 255, 255)",
  "BUTTON_DESTRUCTIVE_VARIANT_PRIMARY_RANK_PRESSED_STATE_BACKGROUND_COLOR": "rgb(153, 0, 26)",
  "BUTTON_DESTRUCTIVE_VARIANT_PRIMARY_RANK_PRESSED_STATE_LABEL_COLOR": "rgb(255, 255, 255)",
  "BUTTON_DESTRUCTIVE_VARIANT_PRIMARY_RANK_FOCUS_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(178, 0, 30)",
  "BUTTON_DESTRUCTIVE_VARIANT_PRIMARY_RANK_FOCUS_STATE_LIGHT_MODE_LABEL_COLOR": "rgb(255, 255, 255)",
  "BUTTON_DESTRUCTIVE_VARIANT_PRIMARY_RANK_FOCUS_STATE_DARK_MODE_BACKGROUND_COLOR": "rgb(178, 0, 30)",
  "BUTTON_DESTRUCTIVE_VARIANT_PRIMARY_RANK_FOCUS_STATE_DARK_MODE_LABEL_COLOR": "rgb(255, 255, 255)",
  "BUTTON_DESTRUCTIVE_VARIANT_PRIMARY_RANK_FOCUS_STATE_BACKGROUND_COLOR": "rgb(178, 0, 30)",
  "BUTTON_DESTRUCTIVE_VARIANT_PRIMARY_RANK_FOCUS_STATE_LABEL_COLOR": "rgb(255, 255, 255)",
  "BUTTON_DESTRUCTIVE_VARIANT_PRIMARY_RANK_DISABLED_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.05)",
  "BUTTON_DESTRUCTIVE_VARIANT_PRIMARY_RANK_DISABLED_STATE_LIGHT_MODE_LABEL_COLOR": "rgba(0, 0, 0, 0.3)",
  "BUTTON_DESTRUCTIVE_VARIANT_PRIMARY_RANK_DISABLED_STATE_DARK_MODE_BACKGROUND_COLOR": "rgba(255, 255, 255, 0.08)",
  "BUTTON_DESTRUCTIVE_VARIANT_PRIMARY_RANK_DISABLED_STATE_DARK_MODE_LABEL_COLOR": "rgba(255, 255, 255, 0.3)",
  "BUTTON_DESTRUCTIVE_VARIANT_PRIMARY_RANK_DISABLED_STATE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.05)",
  "BUTTON_DESTRUCTIVE_VARIANT_PRIMARY_RANK_DISABLED_STATE_LABEL_COLOR": "rgba(0, 0, 0, 0.3)",
  "BUTTON_DESTRUCTIVE_VARIANT_SECONDARY_RANK_NORMAL_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.05)",
  "BUTTON_DESTRUCTIVE_VARIANT_SECONDARY_RANK_NORMAL_STATE_LIGHT_MODE_LABEL_COLOR": "rgb(191, 0, 32)",
  "BUTTON_DESTRUCTIVE_VARIANT_SECONDARY_RANK_NORMAL_STATE_DARK_MODE_BACKGROUND_COLOR": "rgba(255, 255, 255, 0.08)",
  "BUTTON_DESTRUCTIVE_VARIANT_SECONDARY_RANK_NORMAL_STATE_DARK_MODE_LABEL_COLOR": "rgb(255, 90, 118)",
  "BUTTON_DESTRUCTIVE_VARIANT_SECONDARY_RANK_NORMAL_STATE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.05)",
  "BUTTON_DESTRUCTIVE_VARIANT_SECONDARY_RANK_NORMAL_STATE_LABEL_COLOR": "rgb(191, 0, 32)",
  "BUTTON_DESTRUCTIVE_VARIANT_SECONDARY_RANK_HOVER_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(255, 229, 234)",
  "BUTTON_DESTRUCTIVE_VARIANT_SECONDARY_RANK_HOVER_STATE_LIGHT_MODE_LABEL_COLOR": "rgb(191, 0, 32)",
  "BUTTON_DESTRUCTIVE_VARIANT_SECONDARY_RANK_HOVER_STATE_DARK_MODE_BACKGROUND_COLOR": "rgb(51, 0, 9)",
  "BUTTON_DESTRUCTIVE_VARIANT_SECONDARY_RANK_HOVER_STATE_DARK_MODE_LABEL_COLOR": "rgb(255, 90, 118)",
  "BUTTON_DESTRUCTIVE_VARIANT_SECONDARY_RANK_HOVER_STATE_BACKGROUND_COLOR": "rgb(255, 229, 234)",
  "BUTTON_DESTRUCTIVE_VARIANT_SECONDARY_RANK_HOVER_STATE_LABEL_COLOR": "rgb(191, 0, 32)",
  "BUTTON_DESTRUCTIVE_VARIANT_SECONDARY_RANK_PRESSED_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(255, 204, 213)",
  "BUTTON_DESTRUCTIVE_VARIANT_SECONDARY_RANK_PRESSED_STATE_LIGHT_MODE_LABEL_COLOR": "rgb(191, 0, 32)",
  "BUTTON_DESTRUCTIVE_VARIANT_SECONDARY_RANK_PRESSED_STATE_DARK_MODE_BACKGROUND_COLOR": "rgb(89, 0, 15)",
  "BUTTON_DESTRUCTIVE_VARIANT_SECONDARY_RANK_PRESSED_STATE_DARK_MODE_LABEL_COLOR": "rgb(255, 90, 118)",
  "BUTTON_DESTRUCTIVE_VARIANT_SECONDARY_RANK_PRESSED_STATE_BACKGROUND_COLOR": "rgb(255, 204, 213)",
  "BUTTON_DESTRUCTIVE_VARIANT_SECONDARY_RANK_PRESSED_STATE_LABEL_COLOR": "rgb(191, 0, 32)",
  "BUTTON_DESTRUCTIVE_VARIANT_SECONDARY_RANK_FOCUS_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(255, 229, 234)",
  "BUTTON_DESTRUCTIVE_VARIANT_SECONDARY_RANK_FOCUS_STATE_LIGHT_MODE_LABEL_COLOR": "rgb(191, 0, 32)",
  "BUTTON_DESTRUCTIVE_VARIANT_SECONDARY_RANK_FOCUS_STATE_DARK_MODE_BACKGROUND_COLOR": "rgb(51, 0, 9)",
  "BUTTON_DESTRUCTIVE_VARIANT_SECONDARY_RANK_FOCUS_STATE_DARK_MODE_LABEL_COLOR": "rgb(255, 90, 118)",
  "BUTTON_DESTRUCTIVE_VARIANT_SECONDARY_RANK_FOCUS_STATE_BACKGROUND_COLOR": "rgb(255, 229, 234)",
  "BUTTON_DESTRUCTIVE_VARIANT_SECONDARY_RANK_FOCUS_STATE_LABEL_COLOR": "rgb(191, 0, 32)",
  "BUTTON_DESTRUCTIVE_VARIANT_SECONDARY_RANK_DISABLED_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.05)",
  "BUTTON_DESTRUCTIVE_VARIANT_SECONDARY_RANK_DISABLED_STATE_LIGHT_MODE_LABEL_COLOR": "rgba(0, 0, 0, 0.3)",
  "BUTTON_DESTRUCTIVE_VARIANT_SECONDARY_RANK_DISABLED_STATE_DARK_MODE_BACKGROUND_COLOR": "rgba(255, 255, 255, 0.08)",
  "BUTTON_DESTRUCTIVE_VARIANT_SECONDARY_RANK_DISABLED_STATE_DARK_MODE_LABEL_COLOR": "rgba(255, 255, 255, 0.3)",
  "BUTTON_DESTRUCTIVE_VARIANT_SECONDARY_RANK_DISABLED_STATE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.05)",
  "BUTTON_DESTRUCTIVE_VARIANT_SECONDARY_RANK_DISABLED_STATE_LABEL_COLOR": "rgba(0, 0, 0, 0.3)",
  "BUTTON_DESTRUCTIVE_VARIANT_TERTIARY_RANK_NORMAL_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "BUTTON_DESTRUCTIVE_VARIANT_TERTIARY_RANK_NORMAL_STATE_LIGHT_MODE_LABEL_COLOR": "rgb(191, 0, 32)",
  "BUTTON_DESTRUCTIVE_VARIANT_TERTIARY_RANK_NORMAL_STATE_DARK_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "BUTTON_DESTRUCTIVE_VARIANT_TERTIARY_RANK_NORMAL_STATE_DARK_MODE_LABEL_COLOR": "rgb(255, 90, 118)",
  "BUTTON_DESTRUCTIVE_VARIANT_TERTIARY_RANK_NORMAL_STATE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "BUTTON_DESTRUCTIVE_VARIANT_TERTIARY_RANK_NORMAL_STATE_LABEL_COLOR": "rgb(191, 0, 32)",
  "BUTTON_DESTRUCTIVE_VARIANT_TERTIARY_RANK_HOVER_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(255, 229, 234)",
  "BUTTON_DESTRUCTIVE_VARIANT_TERTIARY_RANK_HOVER_STATE_LIGHT_MODE_LABEL_COLOR": "rgb(191, 0, 32)",
  "BUTTON_DESTRUCTIVE_VARIANT_TERTIARY_RANK_HOVER_STATE_DARK_MODE_BACKGROUND_COLOR": "rgb(51, 0, 9)",
  "BUTTON_DESTRUCTIVE_VARIANT_TERTIARY_RANK_HOVER_STATE_DARK_MODE_LABEL_COLOR": "rgb(255, 90, 118)",
  "BUTTON_DESTRUCTIVE_VARIANT_TERTIARY_RANK_HOVER_STATE_BACKGROUND_COLOR": "rgb(255, 229, 234)",
  "BUTTON_DESTRUCTIVE_VARIANT_TERTIARY_RANK_HOVER_STATE_LABEL_COLOR": "rgb(191, 0, 32)",
  "BUTTON_DESTRUCTIVE_VARIANT_TERTIARY_RANK_PRESSED_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(255, 204, 213)",
  "BUTTON_DESTRUCTIVE_VARIANT_TERTIARY_RANK_PRESSED_STATE_LIGHT_MODE_LABEL_COLOR": "rgb(191, 0, 32)",
  "BUTTON_DESTRUCTIVE_VARIANT_TERTIARY_RANK_PRESSED_STATE_DARK_MODE_BACKGROUND_COLOR": "rgb(89, 0, 15)",
  "BUTTON_DESTRUCTIVE_VARIANT_TERTIARY_RANK_PRESSED_STATE_DARK_MODE_LABEL_COLOR": "rgb(255, 90, 118)",
  "BUTTON_DESTRUCTIVE_VARIANT_TERTIARY_RANK_PRESSED_STATE_BACKGROUND_COLOR": "rgb(255, 204, 213)",
  "BUTTON_DESTRUCTIVE_VARIANT_TERTIARY_RANK_PRESSED_STATE_LABEL_COLOR": "rgb(191, 0, 32)",
  "BUTTON_DESTRUCTIVE_VARIANT_TERTIARY_RANK_FOCUS_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "BUTTON_DESTRUCTIVE_VARIANT_TERTIARY_RANK_FOCUS_STATE_LIGHT_MODE_LABEL_COLOR": "rgb(191, 0, 32)",
  "BUTTON_DESTRUCTIVE_VARIANT_TERTIARY_RANK_FOCUS_STATE_DARK_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "BUTTON_DESTRUCTIVE_VARIANT_TERTIARY_RANK_FOCUS_STATE_DARK_MODE_LABEL_COLOR": "rgb(255, 90, 118)",
  "BUTTON_DESTRUCTIVE_VARIANT_TERTIARY_RANK_FOCUS_STATE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "BUTTON_DESTRUCTIVE_VARIANT_TERTIARY_RANK_FOCUS_STATE_LABEL_COLOR": "rgb(191, 0, 32)",
  "BUTTON_DESTRUCTIVE_VARIANT_TERTIARY_RANK_DISABLED_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "BUTTON_DESTRUCTIVE_VARIANT_TERTIARY_RANK_DISABLED_STATE_LIGHT_MODE_LABEL_COLOR": "rgba(0, 0, 0, 0.3)",
  "BUTTON_DESTRUCTIVE_VARIANT_TERTIARY_RANK_DISABLED_STATE_DARK_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "BUTTON_DESTRUCTIVE_VARIANT_TERTIARY_RANK_DISABLED_STATE_DARK_MODE_LABEL_COLOR": "rgba(255, 255, 255, 0.3)",
  "BUTTON_DESTRUCTIVE_VARIANT_TERTIARY_RANK_DISABLED_STATE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "BUTTON_DESTRUCTIVE_VARIANT_TERTIARY_RANK_DISABLED_STATE_LABEL_COLOR": "rgba(0, 0, 0, 0.3)",
  "BUTTON_LOADING_ICON_ASSET": "radial-spinner",
  "BUTTON_LOADING_ICON_WIDTH": 24,
  "BUTTON_LOADING_ICON_HEIGHT": 24,
  "BUTTON_LOADING_ICON_TINTABLE": true,
  "CHECKBOX_WIDTH": 20,
  "CHECKBOX_HEIGHT": 20,
  "CHECKBOX_BORDER_RADIUS": 4,
  "CHECKBOX_BORDER_SIZE": 2,
  "CHECKBOX_LIGHT_MODE_FOCUS_RING_COLOR": "rgb(0, 106, 255)",
  "CHECKBOX_DARK_MODE_FOCUS_RING_COLOR": "rgb(0, 106, 255)",
  "CHECKBOX_FOCUS_RING_BUFFER_SIZE": 2,
  "CHECKBOX_FOCUS_RING_BORDER_SIZE": 2,
  "CHECKBOX_FOCUS_RING_COLOR": "rgb(0, 106, 255)",
  "CHECKBOX_NORMAL_STATE_NORMAL_VALIDITY_UNCHECKED_VALUE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "CHECKBOX_NORMAL_STATE_NORMAL_VALIDITY_UNCHECKED_VALUE_LIGHT_MODE_BORDER_COLOR": "rgba(0, 0, 0, 0.3)",
  "CHECKBOX_NORMAL_STATE_NORMAL_VALIDITY_UNCHECKED_VALUE_DARK_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "CHECKBOX_NORMAL_STATE_NORMAL_VALIDITY_UNCHECKED_VALUE_DARK_MODE_BORDER_COLOR": "rgba(255, 255, 255, 0.3)",
  "CHECKBOX_NORMAL_STATE_NORMAL_VALIDITY_UNCHECKED_VALUE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "CHECKBOX_NORMAL_STATE_NORMAL_VALIDITY_UNCHECKED_VALUE_BORDER_COLOR": "rgba(0, 0, 0, 0.3)",
  "CHECKBOX_NORMAL_STATE_NORMAL_VALIDITY_CHECKED_VALUE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(0, 106, 255)",
  "CHECKBOX_NORMAL_STATE_NORMAL_VALIDITY_CHECKED_VALUE_LIGHT_MODE_BORDER_COLOR": "rgba(0, 0, 0, 0)",
  "CHECKBOX_NORMAL_STATE_NORMAL_VALIDITY_CHECKED_VALUE_LIGHT_MODE_ICON_COLOR": "rgb(255, 255, 255)",
  "CHECKBOX_NORMAL_STATE_NORMAL_VALIDITY_CHECKED_VALUE_DARK_MODE_BACKGROUND_COLOR": "rgb(0, 106, 255)",
  "CHECKBOX_NORMAL_STATE_NORMAL_VALIDITY_CHECKED_VALUE_DARK_MODE_BORDER_COLOR": "rgba(0, 0, 0, 0)",
  "CHECKBOX_NORMAL_STATE_NORMAL_VALIDITY_CHECKED_VALUE_DARK_MODE_ICON_COLOR": "rgb(255, 255, 255)",
  "CHECKBOX_NORMAL_STATE_NORMAL_VALIDITY_CHECKED_VALUE_ICON_ASSET": "checkmark",
  "CHECKBOX_NORMAL_STATE_NORMAL_VALIDITY_CHECKED_VALUE_ICON_COLOR": "rgb(255, 255, 255)",
  "CHECKBOX_NORMAL_STATE_NORMAL_VALIDITY_CHECKED_VALUE_ICON_WIDTH": 20,
  "CHECKBOX_NORMAL_STATE_NORMAL_VALIDITY_CHECKED_VALUE_ICON_HEIGHT": 20,
  "CHECKBOX_NORMAL_STATE_NORMAL_VALIDITY_CHECKED_VALUE_ICON_TINTABLE": true,
  "CHECKBOX_NORMAL_STATE_NORMAL_VALIDITY_CHECKED_VALUE_BACKGROUND_COLOR": "rgb(0, 106, 255)",
  "CHECKBOX_NORMAL_STATE_NORMAL_VALIDITY_CHECKED_VALUE_BORDER_COLOR": "rgba(0, 0, 0, 0)",
  "CHECKBOX_NORMAL_STATE_NORMAL_VALIDITY_INDETERMINATE_VALUE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "CHECKBOX_NORMAL_STATE_NORMAL_VALIDITY_INDETERMINATE_VALUE_LIGHT_MODE_BORDER_COLOR": "rgba(0, 0, 0, 0.3)",
  "CHECKBOX_NORMAL_STATE_NORMAL_VALIDITY_INDETERMINATE_VALUE_LIGHT_MODE_ICON_COLOR": "rgb(0, 106, 255)",
  "CHECKBOX_NORMAL_STATE_NORMAL_VALIDITY_INDETERMINATE_VALUE_DARK_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "CHECKBOX_NORMAL_STATE_NORMAL_VALIDITY_INDETERMINATE_VALUE_DARK_MODE_BORDER_COLOR": "rgba(255, 255, 255, 0.3)",
  "CHECKBOX_NORMAL_STATE_NORMAL_VALIDITY_INDETERMINATE_VALUE_DARK_MODE_ICON_COLOR": "rgb(0, 106, 255)",
  "CHECKBOX_NORMAL_STATE_NORMAL_VALIDITY_INDETERMINATE_VALUE_ICON_ASSET": "indeterminate-mark",
  "CHECKBOX_NORMAL_STATE_NORMAL_VALIDITY_INDETERMINATE_VALUE_ICON_COLOR": "rgb(0, 106, 255)",
  "CHECKBOX_NORMAL_STATE_NORMAL_VALIDITY_INDETERMINATE_VALUE_ICON_WIDTH": 20,
  "CHECKBOX_NORMAL_STATE_NORMAL_VALIDITY_INDETERMINATE_VALUE_ICON_HEIGHT": 20,
  "CHECKBOX_NORMAL_STATE_NORMAL_VALIDITY_INDETERMINATE_VALUE_ICON_TINTABLE": true,
  "CHECKBOX_NORMAL_STATE_NORMAL_VALIDITY_INDETERMINATE_VALUE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "CHECKBOX_NORMAL_STATE_NORMAL_VALIDITY_INDETERMINATE_VALUE_BORDER_COLOR": "rgba(0, 0, 0, 0.3)",
  "CHECKBOX_NORMAL_STATE_INVALID_VALIDITY_UNCHECKED_VALUE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "CHECKBOX_NORMAL_STATE_INVALID_VALIDITY_UNCHECKED_VALUE_LIGHT_MODE_BORDER_COLOR": "rgb(204, 0, 35)",
  "CHECKBOX_NORMAL_STATE_INVALID_VALIDITY_UNCHECKED_VALUE_DARK_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "CHECKBOX_NORMAL_STATE_INVALID_VALIDITY_UNCHECKED_VALUE_DARK_MODE_BORDER_COLOR": "rgb(204, 0, 35)",
  "CHECKBOX_NORMAL_STATE_INVALID_VALIDITY_UNCHECKED_VALUE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "CHECKBOX_NORMAL_STATE_INVALID_VALIDITY_UNCHECKED_VALUE_BORDER_COLOR": "rgb(204, 0, 35)",
  "CHECKBOX_NORMAL_STATE_INVALID_VALIDITY_CHECKED_VALUE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(204, 0, 35)",
  "CHECKBOX_NORMAL_STATE_INVALID_VALIDITY_CHECKED_VALUE_LIGHT_MODE_BORDER_COLOR": "rgba(0, 0, 0, 0)",
  "CHECKBOX_NORMAL_STATE_INVALID_VALIDITY_CHECKED_VALUE_LIGHT_MODE_ICON_COLOR": "rgb(255, 255, 255)",
  "CHECKBOX_NORMAL_STATE_INVALID_VALIDITY_CHECKED_VALUE_DARK_MODE_BACKGROUND_COLOR": "rgb(204, 0, 35)",
  "CHECKBOX_NORMAL_STATE_INVALID_VALIDITY_CHECKED_VALUE_DARK_MODE_BORDER_COLOR": "rgba(0, 0, 0, 0)",
  "CHECKBOX_NORMAL_STATE_INVALID_VALIDITY_CHECKED_VALUE_DARK_MODE_ICON_COLOR": "rgb(255, 255, 255)",
  "CHECKBOX_NORMAL_STATE_INVALID_VALIDITY_CHECKED_VALUE_BACKGROUND_COLOR": "rgb(204, 0, 35)",
  "CHECKBOX_NORMAL_STATE_INVALID_VALIDITY_CHECKED_VALUE_BORDER_COLOR": "rgba(0, 0, 0, 0)",
  "CHECKBOX_NORMAL_STATE_INVALID_VALIDITY_CHECKED_VALUE_ICON_COLOR": "rgb(255, 255, 255)",
  "CHECKBOX_NORMAL_STATE_INVALID_VALIDITY_INDETERMINATE_VALUE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "CHECKBOX_NORMAL_STATE_INVALID_VALIDITY_INDETERMINATE_VALUE_LIGHT_MODE_BORDER_COLOR": "rgb(204, 0, 35)",
  "CHECKBOX_NORMAL_STATE_INVALID_VALIDITY_INDETERMINATE_VALUE_LIGHT_MODE_ICON_COLOR": "rgb(204, 0, 35)",
  "CHECKBOX_NORMAL_STATE_INVALID_VALIDITY_INDETERMINATE_VALUE_DARK_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "CHECKBOX_NORMAL_STATE_INVALID_VALIDITY_INDETERMINATE_VALUE_DARK_MODE_BORDER_COLOR": "rgb(204, 0, 35)",
  "CHECKBOX_NORMAL_STATE_INVALID_VALIDITY_INDETERMINATE_VALUE_DARK_MODE_ICON_COLOR": "rgb(204, 0, 35)",
  "CHECKBOX_NORMAL_STATE_INVALID_VALIDITY_INDETERMINATE_VALUE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "CHECKBOX_NORMAL_STATE_INVALID_VALIDITY_INDETERMINATE_VALUE_BORDER_COLOR": "rgb(204, 0, 35)",
  "CHECKBOX_NORMAL_STATE_INVALID_VALIDITY_INDETERMINATE_VALUE_ICON_COLOR": "rgb(204, 0, 35)",
  "CHECKBOX_HOVER_STATE_NORMAL_VALIDITY_UNCHECKED_VALUE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "CHECKBOX_HOVER_STATE_NORMAL_VALIDITY_UNCHECKED_VALUE_LIGHT_MODE_BORDER_COLOR": "rgb(0, 106, 255)",
  "CHECKBOX_HOVER_STATE_NORMAL_VALIDITY_UNCHECKED_VALUE_DARK_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "CHECKBOX_HOVER_STATE_NORMAL_VALIDITY_UNCHECKED_VALUE_DARK_MODE_BORDER_COLOR": "rgb(0, 106, 255)",
  "CHECKBOX_HOVER_STATE_NORMAL_VALIDITY_UNCHECKED_VALUE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "CHECKBOX_HOVER_STATE_NORMAL_VALIDITY_UNCHECKED_VALUE_BORDER_COLOR": "rgb(0, 106, 255)",
  "CHECKBOX_HOVER_STATE_NORMAL_VALIDITY_CHECKED_VALUE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(0, 95, 229)",
  "CHECKBOX_HOVER_STATE_NORMAL_VALIDITY_CHECKED_VALUE_LIGHT_MODE_BORDER_COLOR": "rgba(0, 0, 0, 0)",
  "CHECKBOX_HOVER_STATE_NORMAL_VALIDITY_CHECKED_VALUE_LIGHT_MODE_ICON_COLOR": "rgb(255, 255, 255)",
  "CHECKBOX_HOVER_STATE_NORMAL_VALIDITY_CHECKED_VALUE_DARK_MODE_BACKGROUND_COLOR": "rgb(0, 95, 229)",
  "CHECKBOX_HOVER_STATE_NORMAL_VALIDITY_CHECKED_VALUE_DARK_MODE_BORDER_COLOR": "rgba(0, 0, 0, 0)",
  "CHECKBOX_HOVER_STATE_NORMAL_VALIDITY_CHECKED_VALUE_DARK_MODE_ICON_COLOR": "rgb(255, 255, 255)",
  "CHECKBOX_HOVER_STATE_NORMAL_VALIDITY_CHECKED_VALUE_BACKGROUND_COLOR": "rgb(0, 95, 229)",
  "CHECKBOX_HOVER_STATE_NORMAL_VALIDITY_CHECKED_VALUE_BORDER_COLOR": "rgba(0, 0, 0, 0)",
  "CHECKBOX_HOVER_STATE_NORMAL_VALIDITY_CHECKED_VALUE_ICON_COLOR": "rgb(255, 255, 255)",
  "CHECKBOX_HOVER_STATE_NORMAL_VALIDITY_INDETERMINATE_VALUE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "CHECKBOX_HOVER_STATE_NORMAL_VALIDITY_INDETERMINATE_VALUE_LIGHT_MODE_BORDER_COLOR": "rgb(0, 106, 255)",
  "CHECKBOX_HOVER_STATE_NORMAL_VALIDITY_INDETERMINATE_VALUE_LIGHT_MODE_ICON_COLOR": "rgb(0, 106, 255)",
  "CHECKBOX_HOVER_STATE_NORMAL_VALIDITY_INDETERMINATE_VALUE_DARK_MODE_BACKGROUND_COLOR": "rgb(0, 106, 255)",
  "CHECKBOX_HOVER_STATE_NORMAL_VALIDITY_INDETERMINATE_VALUE_DARK_MODE_BORDER_COLOR": "rgb(0, 106, 255)",
  "CHECKBOX_HOVER_STATE_NORMAL_VALIDITY_INDETERMINATE_VALUE_DARK_MODE_ICON_COLOR": "rgb(0, 106, 255)",
  "CHECKBOX_HOVER_STATE_NORMAL_VALIDITY_INDETERMINATE_VALUE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "CHECKBOX_HOVER_STATE_NORMAL_VALIDITY_INDETERMINATE_VALUE_BORDER_COLOR": "rgb(0, 106, 255)",
  "CHECKBOX_HOVER_STATE_NORMAL_VALIDITY_INDETERMINATE_VALUE_ICON_COLOR": "rgb(0, 106, 255)",
  "CHECKBOX_HOVER_STATE_INVALID_VALIDITY_UNCHECKED_VALUE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "CHECKBOX_HOVER_STATE_INVALID_VALIDITY_UNCHECKED_VALUE_LIGHT_MODE_BORDER_COLOR": "rgb(178, 0, 30)",
  "CHECKBOX_HOVER_STATE_INVALID_VALIDITY_UNCHECKED_VALUE_DARK_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "CHECKBOX_HOVER_STATE_INVALID_VALIDITY_UNCHECKED_VALUE_DARK_MODE_BORDER_COLOR": "rgb(178, 0, 30)",
  "CHECKBOX_HOVER_STATE_INVALID_VALIDITY_UNCHECKED_VALUE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "CHECKBOX_HOVER_STATE_INVALID_VALIDITY_UNCHECKED_VALUE_BORDER_COLOR": "rgb(178, 0, 30)",
  "CHECKBOX_HOVER_STATE_INVALID_VALIDITY_CHECKED_VALUE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(178, 0, 30)",
  "CHECKBOX_HOVER_STATE_INVALID_VALIDITY_CHECKED_VALUE_LIGHT_MODE_BORDER_COLOR": "rgba(0, 0, 0, 0)",
  "CHECKBOX_HOVER_STATE_INVALID_VALIDITY_CHECKED_VALUE_LIGHT_MODE_ICON_COLOR": "rgb(255, 255, 255)",
  "CHECKBOX_HOVER_STATE_INVALID_VALIDITY_CHECKED_VALUE_DARK_MODE_BACKGROUND_COLOR": "rgb(178, 0, 30)",
  "CHECKBOX_HOVER_STATE_INVALID_VALIDITY_CHECKED_VALUE_DARK_MODE_BORDER_COLOR": "rgba(0, 0, 0, 0)",
  "CHECKBOX_HOVER_STATE_INVALID_VALIDITY_CHECKED_VALUE_DARK_MODE_ICON_COLOR": "rgb(255, 255, 255)",
  "CHECKBOX_HOVER_STATE_INVALID_VALIDITY_CHECKED_VALUE_BACKGROUND_COLOR": "rgb(178, 0, 30)",
  "CHECKBOX_HOVER_STATE_INVALID_VALIDITY_CHECKED_VALUE_BORDER_COLOR": "rgba(0, 0, 0, 0)",
  "CHECKBOX_HOVER_STATE_INVALID_VALIDITY_CHECKED_VALUE_ICON_COLOR": "rgb(255, 255, 255)",
  "CHECKBOX_HOVER_STATE_INVALID_VALIDITY_INDETERMINATE_VALUE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "CHECKBOX_HOVER_STATE_INVALID_VALIDITY_INDETERMINATE_VALUE_LIGHT_MODE_BORDER_COLOR": "rgb(178, 0, 30)",
  "CHECKBOX_HOVER_STATE_INVALID_VALIDITY_INDETERMINATE_VALUE_LIGHT_MODE_ICON_COLOR": "rgb(178, 0, 30)",
  "CHECKBOX_HOVER_STATE_INVALID_VALIDITY_INDETERMINATE_VALUE_DARK_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "CHECKBOX_HOVER_STATE_INVALID_VALIDITY_INDETERMINATE_VALUE_DARK_MODE_BORDER_COLOR": "rgb(178, 0, 30)",
  "CHECKBOX_HOVER_STATE_INVALID_VALIDITY_INDETERMINATE_VALUE_DARK_MODE_ICON_COLOR": "rgb(178, 0, 30)",
  "CHECKBOX_HOVER_STATE_INVALID_VALIDITY_INDETERMINATE_VALUE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "CHECKBOX_HOVER_STATE_INVALID_VALIDITY_INDETERMINATE_VALUE_BORDER_COLOR": "rgb(178, 0, 30)",
  "CHECKBOX_HOVER_STATE_INVALID_VALIDITY_INDETERMINATE_VALUE_ICON_COLOR": "rgb(178, 0, 30)",
  "CHECKBOX_PRESSED_STATE_NORMAL_VALIDITY_UNCHECKED_VALUE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "CHECKBOX_PRESSED_STATE_NORMAL_VALIDITY_UNCHECKED_VALUE_LIGHT_MODE_BORDER_COLOR": "rgb(0, 85, 204)",
  "CHECKBOX_PRESSED_STATE_NORMAL_VALIDITY_UNCHECKED_VALUE_DARK_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "CHECKBOX_PRESSED_STATE_NORMAL_VALIDITY_UNCHECKED_VALUE_DARK_MODE_BORDER_COLOR": "rgb(0, 85, 204)",
  "CHECKBOX_PRESSED_STATE_NORMAL_VALIDITY_UNCHECKED_VALUE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "CHECKBOX_PRESSED_STATE_NORMAL_VALIDITY_UNCHECKED_VALUE_BORDER_COLOR": "rgb(0, 85, 204)",
  "CHECKBOX_PRESSED_STATE_NORMAL_VALIDITY_CHECKED_VALUE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(0, 85, 204)",
  "CHECKBOX_PRESSED_STATE_NORMAL_VALIDITY_CHECKED_VALUE_LIGHT_MODE_BORDER_COLOR": "rgba(0, 0, 0, 0)",
  "CHECKBOX_PRESSED_STATE_NORMAL_VALIDITY_CHECKED_VALUE_LIGHT_MODE_ICON_COLOR": "rgb(255, 255, 255)",
  "CHECKBOX_PRESSED_STATE_NORMAL_VALIDITY_CHECKED_VALUE_DARK_MODE_BACKGROUND_COLOR": "rgb(0, 85, 204)",
  "CHECKBOX_PRESSED_STATE_NORMAL_VALIDITY_CHECKED_VALUE_DARK_MODE_BORDER_COLOR": "rgba(0, 0, 0, 0)",
  "CHECKBOX_PRESSED_STATE_NORMAL_VALIDITY_CHECKED_VALUE_DARK_MODE_ICON_COLOR": "rgb(255, 255, 255)",
  "CHECKBOX_PRESSED_STATE_NORMAL_VALIDITY_CHECKED_VALUE_BACKGROUND_COLOR": "rgb(0, 85, 204)",
  "CHECKBOX_PRESSED_STATE_NORMAL_VALIDITY_CHECKED_VALUE_BORDER_COLOR": "rgba(0, 0, 0, 0)",
  "CHECKBOX_PRESSED_STATE_NORMAL_VALIDITY_CHECKED_VALUE_ICON_COLOR": "rgb(255, 255, 255)",
  "CHECKBOX_PRESSED_STATE_NORMAL_VALIDITY_INDETERMINATE_VALUE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "CHECKBOX_PRESSED_STATE_NORMAL_VALIDITY_INDETERMINATE_VALUE_LIGHT_MODE_BORDER_COLOR": "rgb(0, 85, 204)",
  "CHECKBOX_PRESSED_STATE_NORMAL_VALIDITY_INDETERMINATE_VALUE_LIGHT_MODE_ICON_COLOR": "rgb(0, 85, 204)",
  "CHECKBOX_PRESSED_STATE_NORMAL_VALIDITY_INDETERMINATE_VALUE_DARK_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "CHECKBOX_PRESSED_STATE_NORMAL_VALIDITY_INDETERMINATE_VALUE_DARK_MODE_BORDER_COLOR": "rgb(0, 85, 204)",
  "CHECKBOX_PRESSED_STATE_NORMAL_VALIDITY_INDETERMINATE_VALUE_DARK_MODE_ICON_COLOR": "rgb(0, 85, 204)",
  "CHECKBOX_PRESSED_STATE_NORMAL_VALIDITY_INDETERMINATE_VALUE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "CHECKBOX_PRESSED_STATE_NORMAL_VALIDITY_INDETERMINATE_VALUE_BORDER_COLOR": "rgb(0, 85, 204)",
  "CHECKBOX_PRESSED_STATE_NORMAL_VALIDITY_INDETERMINATE_VALUE_ICON_COLOR": "rgb(0, 85, 204)",
  "CHECKBOX_PRESSED_STATE_INVALID_VALIDITY_UNCHECKED_VALUE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "CHECKBOX_PRESSED_STATE_INVALID_VALIDITY_UNCHECKED_VALUE_LIGHT_MODE_BORDER_COLOR": "rgb(153, 0, 26)",
  "CHECKBOX_PRESSED_STATE_INVALID_VALIDITY_UNCHECKED_VALUE_DARK_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "CHECKBOX_PRESSED_STATE_INVALID_VALIDITY_UNCHECKED_VALUE_DARK_MODE_BORDER_COLOR": "rgb(153, 0, 26)",
  "CHECKBOX_PRESSED_STATE_INVALID_VALIDITY_UNCHECKED_VALUE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "CHECKBOX_PRESSED_STATE_INVALID_VALIDITY_UNCHECKED_VALUE_BORDER_COLOR": "rgb(153, 0, 26)",
  "CHECKBOX_PRESSED_STATE_INVALID_VALIDITY_CHECKED_VALUE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(153, 0, 26)",
  "CHECKBOX_PRESSED_STATE_INVALID_VALIDITY_CHECKED_VALUE_LIGHT_MODE_BORDER_COLOR": "rgba(0, 0, 0, 0)",
  "CHECKBOX_PRESSED_STATE_INVALID_VALIDITY_CHECKED_VALUE_LIGHT_MODE_ICON_COLOR": "rgb(255, 255, 255)",
  "CHECKBOX_PRESSED_STATE_INVALID_VALIDITY_CHECKED_VALUE_DARK_MODE_BACKGROUND_COLOR": "rgb(153, 0, 26)",
  "CHECKBOX_PRESSED_STATE_INVALID_VALIDITY_CHECKED_VALUE_DARK_MODE_BORDER_COLOR": "rgba(0, 0, 0, 0)",
  "CHECKBOX_PRESSED_STATE_INVALID_VALIDITY_CHECKED_VALUE_DARK_MODE_ICON_COLOR": "rgb(255, 255, 255)",
  "CHECKBOX_PRESSED_STATE_INVALID_VALIDITY_CHECKED_VALUE_BACKGROUND_COLOR": "rgb(153, 0, 26)",
  "CHECKBOX_PRESSED_STATE_INVALID_VALIDITY_CHECKED_VALUE_BORDER_COLOR": "rgba(0, 0, 0, 0)",
  "CHECKBOX_PRESSED_STATE_INVALID_VALIDITY_CHECKED_VALUE_ICON_COLOR": "rgb(255, 255, 255)",
  "CHECKBOX_PRESSED_STATE_INVALID_VALIDITY_INDETERMINATE_VALUE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "CHECKBOX_PRESSED_STATE_INVALID_VALIDITY_INDETERMINATE_VALUE_LIGHT_MODE_BORDER_COLOR": "rgb(153, 0, 26)",
  "CHECKBOX_PRESSED_STATE_INVALID_VALIDITY_INDETERMINATE_VALUE_LIGHT_MODE_ICON_COLOR": "rgb(153, 0, 26)",
  "CHECKBOX_PRESSED_STATE_INVALID_VALIDITY_INDETERMINATE_VALUE_DARK_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "CHECKBOX_PRESSED_STATE_INVALID_VALIDITY_INDETERMINATE_VALUE_DARK_MODE_BORDER_COLOR": "rgb(153, 0, 26)",
  "CHECKBOX_PRESSED_STATE_INVALID_VALIDITY_INDETERMINATE_VALUE_DARK_MODE_ICON_COLOR": "rgb(153, 0, 26)",
  "CHECKBOX_PRESSED_STATE_INVALID_VALIDITY_INDETERMINATE_VALUE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "CHECKBOX_PRESSED_STATE_INVALID_VALIDITY_INDETERMINATE_VALUE_BORDER_COLOR": "rgb(153, 0, 26)",
  "CHECKBOX_PRESSED_STATE_INVALID_VALIDITY_INDETERMINATE_VALUE_ICON_COLOR": "rgb(153, 0, 26)",
  "CHECKBOX_FOCUS_STATE_NORMAL_VALIDITY_UNCHECKED_VALUE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "CHECKBOX_FOCUS_STATE_NORMAL_VALIDITY_UNCHECKED_VALUE_LIGHT_MODE_BORDER_COLOR": "rgb(0, 106, 255)",
  "CHECKBOX_FOCUS_STATE_NORMAL_VALIDITY_UNCHECKED_VALUE_DARK_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "CHECKBOX_FOCUS_STATE_NORMAL_VALIDITY_UNCHECKED_VALUE_DARK_MODE_BORDER_COLOR": "rgb(0, 106, 255)",
  "CHECKBOX_FOCUS_STATE_NORMAL_VALIDITY_UNCHECKED_VALUE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "CHECKBOX_FOCUS_STATE_NORMAL_VALIDITY_UNCHECKED_VALUE_BORDER_COLOR": "rgb(0, 106, 255)",
  "CHECKBOX_FOCUS_STATE_NORMAL_VALIDITY_CHECKED_VALUE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(0, 95, 229)",
  "CHECKBOX_FOCUS_STATE_NORMAL_VALIDITY_CHECKED_VALUE_LIGHT_MODE_BORDER_COLOR": "rgba(0, 0, 0, 0)",
  "CHECKBOX_FOCUS_STATE_NORMAL_VALIDITY_CHECKED_VALUE_LIGHT_MODE_ICON_COLOR": "rgb(255, 255, 255)",
  "CHECKBOX_FOCUS_STATE_NORMAL_VALIDITY_CHECKED_VALUE_DARK_MODE_BACKGROUND_COLOR": "rgb(0, 95, 229)",
  "CHECKBOX_FOCUS_STATE_NORMAL_VALIDITY_CHECKED_VALUE_DARK_MODE_BORDER_COLOR": "rgba(0, 0, 0, 0)",
  "CHECKBOX_FOCUS_STATE_NORMAL_VALIDITY_CHECKED_VALUE_DARK_MODE_ICON_COLOR": "rgb(255, 255, 255)",
  "CHECKBOX_FOCUS_STATE_NORMAL_VALIDITY_CHECKED_VALUE_BACKGROUND_COLOR": "rgb(0, 95, 229)",
  "CHECKBOX_FOCUS_STATE_NORMAL_VALIDITY_CHECKED_VALUE_BORDER_COLOR": "rgba(0, 0, 0, 0)",
  "CHECKBOX_FOCUS_STATE_NORMAL_VALIDITY_CHECKED_VALUE_ICON_COLOR": "rgb(255, 255, 255)",
  "CHECKBOX_FOCUS_STATE_NORMAL_VALIDITY_INDETERMINATE_VALUE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "CHECKBOX_FOCUS_STATE_NORMAL_VALIDITY_INDETERMINATE_VALUE_LIGHT_MODE_BORDER_COLOR": "rgb(0, 106, 255)",
  "CHECKBOX_FOCUS_STATE_NORMAL_VALIDITY_INDETERMINATE_VALUE_LIGHT_MODE_ICON_COLOR": "rgb(0, 106, 255)",
  "CHECKBOX_FOCUS_STATE_NORMAL_VALIDITY_INDETERMINATE_VALUE_DARK_MODE_BACKGROUND_COLOR": "rgb(0, 106, 255)",
  "CHECKBOX_FOCUS_STATE_NORMAL_VALIDITY_INDETERMINATE_VALUE_DARK_MODE_BORDER_COLOR": "rgb(0, 106, 255)",
  "CHECKBOX_FOCUS_STATE_NORMAL_VALIDITY_INDETERMINATE_VALUE_DARK_MODE_ICON_COLOR": "rgb(0, 106, 255)",
  "CHECKBOX_FOCUS_STATE_NORMAL_VALIDITY_INDETERMINATE_VALUE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "CHECKBOX_FOCUS_STATE_NORMAL_VALIDITY_INDETERMINATE_VALUE_BORDER_COLOR": "rgb(0, 106, 255)",
  "CHECKBOX_FOCUS_STATE_NORMAL_VALIDITY_INDETERMINATE_VALUE_ICON_COLOR": "rgb(0, 106, 255)",
  "CHECKBOX_FOCUS_STATE_INVALID_VALIDITY_UNCHECKED_VALUE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "CHECKBOX_FOCUS_STATE_INVALID_VALIDITY_UNCHECKED_VALUE_LIGHT_MODE_BORDER_COLOR": "rgb(178, 0, 30)",
  "CHECKBOX_FOCUS_STATE_INVALID_VALIDITY_UNCHECKED_VALUE_DARK_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "CHECKBOX_FOCUS_STATE_INVALID_VALIDITY_UNCHECKED_VALUE_DARK_MODE_BORDER_COLOR": "rgb(178, 0, 30)",
  "CHECKBOX_FOCUS_STATE_INVALID_VALIDITY_UNCHECKED_VALUE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "CHECKBOX_FOCUS_STATE_INVALID_VALIDITY_UNCHECKED_VALUE_BORDER_COLOR": "rgb(178, 0, 30)",
  "CHECKBOX_FOCUS_STATE_INVALID_VALIDITY_CHECKED_VALUE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(178, 0, 30)",
  "CHECKBOX_FOCUS_STATE_INVALID_VALIDITY_CHECKED_VALUE_LIGHT_MODE_BORDER_COLOR": "rgba(0, 0, 0, 0)",
  "CHECKBOX_FOCUS_STATE_INVALID_VALIDITY_CHECKED_VALUE_LIGHT_MODE_ICON_COLOR": "rgb(255, 255, 255)",
  "CHECKBOX_FOCUS_STATE_INVALID_VALIDITY_CHECKED_VALUE_DARK_MODE_BACKGROUND_COLOR": "rgb(178, 0, 30)",
  "CHECKBOX_FOCUS_STATE_INVALID_VALIDITY_CHECKED_VALUE_DARK_MODE_BORDER_COLOR": "rgba(0, 0, 0, 0)",
  "CHECKBOX_FOCUS_STATE_INVALID_VALIDITY_CHECKED_VALUE_DARK_MODE_ICON_COLOR": "rgb(255, 255, 255)",
  "CHECKBOX_FOCUS_STATE_INVALID_VALIDITY_CHECKED_VALUE_BACKGROUND_COLOR": "rgb(178, 0, 30)",
  "CHECKBOX_FOCUS_STATE_INVALID_VALIDITY_CHECKED_VALUE_BORDER_COLOR": "rgba(0, 0, 0, 0)",
  "CHECKBOX_FOCUS_STATE_INVALID_VALIDITY_CHECKED_VALUE_ICON_COLOR": "rgb(255, 255, 255)",
  "CHECKBOX_FOCUS_STATE_INVALID_VALIDITY_INDETERMINATE_VALUE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "CHECKBOX_FOCUS_STATE_INVALID_VALIDITY_INDETERMINATE_VALUE_LIGHT_MODE_BORDER_COLOR": "rgb(178, 0, 30)",
  "CHECKBOX_FOCUS_STATE_INVALID_VALIDITY_INDETERMINATE_VALUE_DARK_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "CHECKBOX_FOCUS_STATE_INVALID_VALIDITY_INDETERMINATE_VALUE_DARK_MODE_BORDER_COLOR": "rgb(178, 0, 30)",
  "CHECKBOX_FOCUS_STATE_INVALID_VALIDITY_INDETERMINATE_VALUE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "CHECKBOX_FOCUS_STATE_INVALID_VALIDITY_INDETERMINATE_VALUE_BORDER_COLOR": "rgb(178, 0, 30)",
  "CHECKBOX_DISABLED_STATE_NORMAL_VALIDITY_UNCHECKED_VALUE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.03)",
  "CHECKBOX_DISABLED_STATE_NORMAL_VALIDITY_UNCHECKED_VALUE_LIGHT_MODE_BORDER_COLOR": "rgba(0, 0, 0, 0.03)",
  "CHECKBOX_DISABLED_STATE_NORMAL_VALIDITY_UNCHECKED_VALUE_DARK_MODE_BACKGROUND_COLOR": "rgba(255, 255, 255, 0.05)",
  "CHECKBOX_DISABLED_STATE_NORMAL_VALIDITY_UNCHECKED_VALUE_DARK_MODE_BORDER_COLOR": "rgba(255, 255, 255, 0.05)",
  "CHECKBOX_DISABLED_STATE_NORMAL_VALIDITY_UNCHECKED_VALUE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.03)",
  "CHECKBOX_DISABLED_STATE_NORMAL_VALIDITY_UNCHECKED_VALUE_BORDER_COLOR": "rgba(0, 0, 0, 0.03)",
  "CHECKBOX_DISABLED_STATE_NORMAL_VALIDITY_CHECKED_VALUE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.3)",
  "CHECKBOX_DISABLED_STATE_NORMAL_VALIDITY_CHECKED_VALUE_LIGHT_MODE_BORDER_COLOR": "rgba(0, 0, 0, 0)",
  "CHECKBOX_DISABLED_STATE_NORMAL_VALIDITY_CHECKED_VALUE_LIGHT_MODE_ICON_COLOR": "rgb(255, 255, 255)",
  "CHECKBOX_DISABLED_STATE_NORMAL_VALIDITY_CHECKED_VALUE_DARK_MODE_BACKGROUND_COLOR": "rgba(255, 255, 255, 0.3)",
  "CHECKBOX_DISABLED_STATE_NORMAL_VALIDITY_CHECKED_VALUE_DARK_MODE_BORDER_COLOR": "rgba(0, 0, 0, 0)",
  "CHECKBOX_DISABLED_STATE_NORMAL_VALIDITY_CHECKED_VALUE_DARK_MODE_ICON_COLOR": "rgb(255, 255, 255)",
  "CHECKBOX_DISABLED_STATE_NORMAL_VALIDITY_CHECKED_VALUE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.3)",
  "CHECKBOX_DISABLED_STATE_NORMAL_VALIDITY_CHECKED_VALUE_BORDER_COLOR": "rgba(0, 0, 0, 0)",
  "CHECKBOX_DISABLED_STATE_NORMAL_VALIDITY_CHECKED_VALUE_ICON_COLOR": "rgb(255, 255, 255)",
  "CHECKBOX_DISABLED_STATE_NORMAL_VALIDITY_INDETERMINATE_VALUE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.03)",
  "CHECKBOX_DISABLED_STATE_NORMAL_VALIDITY_INDETERMINATE_VALUE_LIGHT_MODE_BORDER_COLOR": "rgba(0, 0, 0, 0.03)",
  "CHECKBOX_DISABLED_STATE_NORMAL_VALIDITY_INDETERMINATE_VALUE_LIGHT_MODE_ICON_COLOR": "rgba(0, 0, 0, 0.15)",
  "CHECKBOX_DISABLED_STATE_NORMAL_VALIDITY_INDETERMINATE_VALUE_DARK_MODE_BACKGROUND_COLOR": "rgba(255, 255, 255, 0.05)",
  "CHECKBOX_DISABLED_STATE_NORMAL_VALIDITY_INDETERMINATE_VALUE_DARK_MODE_BORDER_COLOR": "rgba(255, 255, 255, 0.05)",
  "CHECKBOX_DISABLED_STATE_NORMAL_VALIDITY_INDETERMINATE_VALUE_DARK_MODE_ICON_COLOR": "rgba(255, 255, 255, 0.15)",
  "CHECKBOX_DISABLED_STATE_NORMAL_VALIDITY_INDETERMINATE_VALUE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.03)",
  "CHECKBOX_DISABLED_STATE_NORMAL_VALIDITY_INDETERMINATE_VALUE_BORDER_COLOR": "rgba(0, 0, 0, 0.03)",
  "CHECKBOX_DISABLED_STATE_NORMAL_VALIDITY_INDETERMINATE_VALUE_ICON_COLOR": "rgba(0, 0, 0, 0.15)",
  "CHOICE_BUTTON_MINIMUM_WIDTH_MULTIPLER": 1.5,
  "CHOICE_BUTTON_SMALL_SIZE_TEXT_PRIMARY_SIZE": 14,
  "CHOICE_BUTTON_SMALL_SIZE_TEXT_PRIMARY_LEADING": 22,
  "CHOICE_BUTTON_SMALL_SIZE_TEXT_PRIMARY_TRACKING": 0,
  "CHOICE_BUTTON_SMALL_SIZE_TEXT_PRIMARY_WEIGHT": 600,
  "CHOICE_BUTTON_SMALL_SIZE_TEXT_PRIMARY_CASE": "regular",
  "CHOICE_BUTTON_SMALL_SIZE_TEXT_PRIMARY_NUMBER_SPACING": "default",
  "CHOICE_BUTTON_SMALL_SIZE_TEXT_PRIMARY_SCALE": "paragraph-20",
  "CHOICE_BUTTON_SMALL_SIZE_TEXT_PRIMARY_FONT_FAMILY": "Square Sans Text",
  "CHOICE_BUTTON_SMALL_SIZE_TEXT_SECONDARY_SIZE": 14,
  "CHOICE_BUTTON_SMALL_SIZE_TEXT_SECONDARY_LEADING": 22,
  "CHOICE_BUTTON_SMALL_SIZE_TEXT_SECONDARY_TRACKING": 0,
  "CHOICE_BUTTON_SMALL_SIZE_TEXT_SECONDARY_WEIGHT": 400,
  "CHOICE_BUTTON_SMALL_SIZE_TEXT_SECONDARY_CASE": "regular",
  "CHOICE_BUTTON_SMALL_SIZE_TEXT_SECONDARY_NUMBER_SPACING": "default",
  "CHOICE_BUTTON_SMALL_SIZE_TEXT_SECONDARY_SCALE": "paragraph-20",
  "CHOICE_BUTTON_SMALL_SIZE_TEXT_SECONDARY_FONT_FAMILY": "Square Sans Text",
  "CHOICE_BUTTON_SMALL_SIZE_CONTENT_SPACING": 8,
  "CHOICE_BUTTON_SMALL_SIZE_HORIZONTAL_PADDING": 12,
  "CHOICE_BUTTON_SMALL_SIZE_VERTICAL_PADDING": 9,
  "CHOICE_BUTTON_MEDIUM_SIZE_TEXT_PRIMARY_SIZE": 16,
  "CHOICE_BUTTON_MEDIUM_SIZE_TEXT_PRIMARY_LEADING": 24,
  "CHOICE_BUTTON_MEDIUM_SIZE_TEXT_PRIMARY_TRACKING": 0,
  "CHOICE_BUTTON_MEDIUM_SIZE_TEXT_PRIMARY_WEIGHT": 600,
  "CHOICE_BUTTON_MEDIUM_SIZE_TEXT_PRIMARY_CASE": "regular",
  "CHOICE_BUTTON_MEDIUM_SIZE_TEXT_PRIMARY_NUMBER_SPACING": "default",
  "CHOICE_BUTTON_MEDIUM_SIZE_TEXT_PRIMARY_SCALE": "paragraph-30",
  "CHOICE_BUTTON_MEDIUM_SIZE_TEXT_PRIMARY_FONT_FAMILY": "Square Sans Text",
  "CHOICE_BUTTON_MEDIUM_SIZE_TEXT_SECONDARY_SIZE": 16,
  "CHOICE_BUTTON_MEDIUM_SIZE_TEXT_SECONDARY_LEADING": 24,
  "CHOICE_BUTTON_MEDIUM_SIZE_TEXT_SECONDARY_TRACKING": 0,
  "CHOICE_BUTTON_MEDIUM_SIZE_TEXT_SECONDARY_WEIGHT": 400,
  "CHOICE_BUTTON_MEDIUM_SIZE_TEXT_SECONDARY_CASE": "regular",
  "CHOICE_BUTTON_MEDIUM_SIZE_TEXT_SECONDARY_NUMBER_SPACING": "default",
  "CHOICE_BUTTON_MEDIUM_SIZE_TEXT_SECONDARY_SCALE": "paragraph-30",
  "CHOICE_BUTTON_MEDIUM_SIZE_TEXT_SECONDARY_FONT_FAMILY": "Square Sans Text",
  "CHOICE_BUTTON_MEDIUM_SIZE_CONTENT_SPACING": 8,
  "CHOICE_BUTTON_MEDIUM_SIZE_HORIZONTAL_PADDING": 16,
  "CHOICE_BUTTON_MEDIUM_SIZE_VERTICAL_PADDING": 12,
  "CHOICE_BUTTON_LARGE_SIZE_TEXT_PRIMARY_SIZE": 16,
  "CHOICE_BUTTON_LARGE_SIZE_TEXT_PRIMARY_LEADING": 24,
  "CHOICE_BUTTON_LARGE_SIZE_TEXT_PRIMARY_TRACKING": 0,
  "CHOICE_BUTTON_LARGE_SIZE_TEXT_PRIMARY_WEIGHT": 600,
  "CHOICE_BUTTON_LARGE_SIZE_TEXT_PRIMARY_CASE": "regular",
  "CHOICE_BUTTON_LARGE_SIZE_TEXT_PRIMARY_NUMBER_SPACING": "default",
  "CHOICE_BUTTON_LARGE_SIZE_TEXT_PRIMARY_SCALE": "paragraph-30",
  "CHOICE_BUTTON_LARGE_SIZE_TEXT_PRIMARY_FONT_FAMILY": "Square Sans Text",
  "CHOICE_BUTTON_LARGE_SIZE_TEXT_SECONDARY_SIZE": 16,
  "CHOICE_BUTTON_LARGE_SIZE_TEXT_SECONDARY_LEADING": 24,
  "CHOICE_BUTTON_LARGE_SIZE_TEXT_SECONDARY_TRACKING": 0,
  "CHOICE_BUTTON_LARGE_SIZE_TEXT_SECONDARY_WEIGHT": 400,
  "CHOICE_BUTTON_LARGE_SIZE_TEXT_SECONDARY_CASE": "regular",
  "CHOICE_BUTTON_LARGE_SIZE_TEXT_SECONDARY_NUMBER_SPACING": "default",
  "CHOICE_BUTTON_LARGE_SIZE_TEXT_SECONDARY_SCALE": "paragraph-30",
  "CHOICE_BUTTON_LARGE_SIZE_TEXT_SECONDARY_FONT_FAMILY": "Square Sans Text",
  "CHOICE_BUTTON_LARGE_SIZE_CONTENT_SPACING": 8,
  "CHOICE_BUTTON_LARGE_SIZE_HORIZONTAL_PADDING": 20,
  "CHOICE_BUTTON_LARGE_SIZE_VERTICAL_PADDING": 20,
  "CHOICE_BUTTON_BORDER_RADIUS": 6,
  "CHOICE_BUTTON_FOCUS_RING_BUFFER_SIZE": 2,
  "CHOICE_BUTTON_FOCUS_RING_BORDER_SIZE": 2,
  "CHOICE_BUTTON_FOCUS_RING_COLOR": "rgb(0, 106, 255)",
  "CHOICE_BUTTON_LIGHT_MODE_FOCUS_RING_COLOR": "rgb(0, 106, 255)",
  "CHOICE_BUTTON_DARK_MODE_FOCUS_RING_COLOR": "rgb(0, 106, 255)",
  "CHOICE_BUTTON_UNSELECTED_VALUE_NORMAL_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.05)",
  "CHOICE_BUTTON_UNSELECTED_VALUE_NORMAL_STATE_LIGHT_MODE_LABEL_COLOR": "rgba(0, 0, 0, 0.9)",
  "CHOICE_BUTTON_UNSELECTED_VALUE_NORMAL_STATE_DARK_MODE_BACKGROUND_COLOR": "rgba(255, 255, 255, 0.08)",
  "CHOICE_BUTTON_UNSELECTED_VALUE_NORMAL_STATE_DARK_MODE_LABEL_COLOR": "rgba(255, 255, 255, 0.95)",
  "CHOICE_BUTTON_UNSELECTED_VALUE_NORMAL_STATE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.05)",
  "CHOICE_BUTTON_UNSELECTED_VALUE_NORMAL_STATE_LABEL_COLOR": "rgba(0, 0, 0, 0.9)",
  "CHOICE_BUTTON_UNSELECTED_VALUE_HOVER_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(230, 240, 255)",
  "CHOICE_BUTTON_UNSELECTED_VALUE_HOVER_STATE_LIGHT_MODE_LABEL_COLOR": "rgba(0, 0, 0, 0.9)",
  "CHOICE_BUTTON_UNSELECTED_VALUE_HOVER_STATE_DARK_MODE_BACKGROUND_COLOR": "rgb(0, 21, 51)",
  "CHOICE_BUTTON_UNSELECTED_VALUE_HOVER_STATE_DARK_MODE_LABEL_COLOR": "rgba(255, 255, 255, 0.95)",
  "CHOICE_BUTTON_UNSELECTED_VALUE_HOVER_STATE_BACKGROUND_COLOR": "rgb(230, 240, 255)",
  "CHOICE_BUTTON_UNSELECTED_VALUE_HOVER_STATE_LABEL_COLOR": "rgba(0, 0, 0, 0.9)",
  "CHOICE_BUTTON_UNSELECTED_VALUE_PRESSED_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(204, 225, 255)",
  "CHOICE_BUTTON_UNSELECTED_VALUE_PRESSED_STATE_LIGHT_MODE_LABEL_COLOR": "rgba(0, 0, 0, 0.9)",
  "CHOICE_BUTTON_UNSELECTED_VALUE_PRESSED_STATE_DARK_MODE_BACKGROUND_COLOR": "rgb(0, 37, 89)",
  "CHOICE_BUTTON_UNSELECTED_VALUE_PRESSED_STATE_DARK_MODE_LABEL_COLOR": "rgba(255, 255, 255, 0.95)",
  "CHOICE_BUTTON_UNSELECTED_VALUE_PRESSED_STATE_BACKGROUND_COLOR": "rgb(204, 225, 255)",
  "CHOICE_BUTTON_UNSELECTED_VALUE_PRESSED_STATE_LABEL_COLOR": "rgba(0, 0, 0, 0.9)",
  "CHOICE_BUTTON_UNSELECTED_VALUE_DISABLED_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.05)",
  "CHOICE_BUTTON_UNSELECTED_VALUE_DISABLED_STATE_LIGHT_MODE_LABEL_COLOR": "rgba(0, 0, 0, 0.3)",
  "CHOICE_BUTTON_UNSELECTED_VALUE_DISABLED_STATE_DARK_MODE_BACKGROUND_COLOR": "rgba(255, 255, 255, 0.08)",
  "CHOICE_BUTTON_UNSELECTED_VALUE_DISABLED_STATE_DARK_MODE_LABEL_COLOR": "rgba(255, 255, 255, 0.3)",
  "CHOICE_BUTTON_UNSELECTED_VALUE_DISABLED_STATE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.05)",
  "CHOICE_BUTTON_UNSELECTED_VALUE_DISABLED_STATE_LABEL_COLOR": "rgba(0, 0, 0, 0.3)",
  "CHOICE_BUTTON_SELECTED_VALUE_NORMAL_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.9)",
  "CHOICE_BUTTON_SELECTED_VALUE_NORMAL_STATE_LIGHT_MODE_LABEL_COLOR": "rgba(255, 255, 255, 0.95)",
  "CHOICE_BUTTON_SELECTED_VALUE_NORMAL_STATE_DARK_MODE_BACKGROUND_COLOR": "rgba(255, 255, 255, 0.95)",
  "CHOICE_BUTTON_SELECTED_VALUE_NORMAL_STATE_DARK_MODE_LABEL_COLOR": "rgba(0, 0, 0, 0.9)",
  "CHOICE_BUTTON_SELECTED_VALUE_NORMAL_STATE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.9)",
  "CHOICE_BUTTON_SELECTED_VALUE_NORMAL_STATE_LABEL_COLOR": "rgba(255, 255, 255, 0.95)",
  "CHOICE_BUTTON_SELECTED_VALUE_HOVER_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.9)",
  "CHOICE_BUTTON_SELECTED_VALUE_HOVER_STATE_LIGHT_MODE_LABEL_COLOR": "rgba(255, 255, 255, 0.95)",
  "CHOICE_BUTTON_SELECTED_VALUE_HOVER_STATE_DARK_MODE_BACKGROUND_COLOR": "rgba(255, 255, 255, 0.95)",
  "CHOICE_BUTTON_SELECTED_VALUE_HOVER_STATE_DARK_MODE_LABEL_COLOR": "rgba(0, 0, 0, 0.9)",
  "CHOICE_BUTTON_SELECTED_VALUE_HOVER_STATE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.9)",
  "CHOICE_BUTTON_SELECTED_VALUE_HOVER_STATE_LABEL_COLOR": "rgba(255, 255, 255, 0.95)",
  "CHOICE_BUTTON_SELECTED_VALUE_PRESSED_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.3)",
  "CHOICE_BUTTON_SELECTED_VALUE_PRESSED_STATE_LIGHT_MODE_LABEL_COLOR": "rgba(255, 255, 255, 0.95)",
  "CHOICE_BUTTON_SELECTED_VALUE_PRESSED_STATE_DARK_MODE_BACKGROUND_COLOR": "rgba(255, 255, 255, 0.3)",
  "CHOICE_BUTTON_SELECTED_VALUE_PRESSED_STATE_DARK_MODE_LABEL_COLOR": "rgba(0, 0, 0, 0.9)",
  "CHOICE_BUTTON_SELECTED_VALUE_PRESSED_STATE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.3)",
  "CHOICE_BUTTON_SELECTED_VALUE_PRESSED_STATE_LABEL_COLOR": "rgba(255, 255, 255, 0.95)",
  "CHOICE_BUTTON_SELECTED_VALUE_DISABLED_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.15)",
  "CHOICE_BUTTON_SELECTED_VALUE_DISABLED_STATE_LIGHT_MODE_LABEL_COLOR": "rgba(255, 255, 255, 0.95)",
  "CHOICE_BUTTON_SELECTED_VALUE_DISABLED_STATE_DARK_MODE_BACKGROUND_COLOR": "rgba(255, 255, 255, 0.15)",
  "CHOICE_BUTTON_SELECTED_VALUE_DISABLED_STATE_DARK_MODE_LABEL_COLOR": "rgba(0, 0, 0, 0.9)",
  "CHOICE_BUTTON_SELECTED_VALUE_DISABLED_STATE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.15)",
  "CHOICE_BUTTON_SELECTED_VALUE_DISABLED_STATE_LABEL_COLOR": "rgba(255, 255, 255, 0.95)",
  "CHOICE_ICON_BUTTON_MINIMUM_WIDTH_MULTIPLER": 1,
  "CHOICE_ICON_BUTTON_SMALL_SIZE_HORIZONTAL_PADDING": 8,
  "CHOICE_ICON_BUTTON_SMALL_SIZE_VERTICAL_PADDING": 8,
  "CHOICE_ICON_BUTTON_MEDIUM_SIZE_HORIZONTAL_PADDING": 12,
  "CHOICE_ICON_BUTTON_MEDIUM_SIZE_VERTICAL_PADDING": 12,
  "CHOICE_ICON_BUTTON_LARGE_SIZE_HORIZONTAL_PADDING": 16,
  "CHOICE_ICON_BUTTON_LARGE_SIZE_VERTICAL_PADDING": 16,
  "CHOICE_ICON_BUTTON_BORDER_RADIUS": 6,
  "CHOICE_ICON_BUTTON_FOCUS_RING_BUFFER_SIZE": 2,
  "CHOICE_ICON_BUTTON_FOCUS_RING_BORDER_SIZE": 2,
  "CHOICE_ICON_BUTTON_FOCUS_RING_COLOR": "rgb(0, 106, 255)",
  "CHOICE_ICON_BUTTON_LIGHT_MODE_FOCUS_RING_COLOR": "rgb(0, 106, 255)",
  "CHOICE_ICON_BUTTON_DARK_MODE_FOCUS_RING_COLOR": "rgb(0, 106, 255)",
  "CHOICE_ICON_BUTTON_UNSELECTED_VALUE_NORMAL_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.05)",
  "CHOICE_ICON_BUTTON_UNSELECTED_VALUE_NORMAL_STATE_LIGHT_MODE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "CHOICE_ICON_BUTTON_UNSELECTED_VALUE_NORMAL_STATE_DARK_MODE_BACKGROUND_COLOR": "rgba(255, 255, 255, 0.08)",
  "CHOICE_ICON_BUTTON_UNSELECTED_VALUE_NORMAL_STATE_DARK_MODE_ICON_COLOR": "rgba(255, 255, 255, 0.95)",
  "CHOICE_ICON_BUTTON_UNSELECTED_VALUE_NORMAL_STATE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.05)",
  "CHOICE_ICON_BUTTON_UNSELECTED_VALUE_NORMAL_STATE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "CHOICE_ICON_BUTTON_UNSELECTED_VALUE_HOVER_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(230, 240, 255)",
  "CHOICE_ICON_BUTTON_UNSELECTED_VALUE_HOVER_STATE_LIGHT_MODE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "CHOICE_ICON_BUTTON_UNSELECTED_VALUE_HOVER_STATE_DARK_MODE_BACKGROUND_COLOR": "rgb(0, 21, 51)",
  "CHOICE_ICON_BUTTON_UNSELECTED_VALUE_HOVER_STATE_DARK_MODE_ICON_COLOR": "rgba(255, 255, 255, 0.95)",
  "CHOICE_ICON_BUTTON_UNSELECTED_VALUE_HOVER_STATE_BACKGROUND_COLOR": "rgb(230, 240, 255)",
  "CHOICE_ICON_BUTTON_UNSELECTED_VALUE_HOVER_STATE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "CHOICE_ICON_BUTTON_UNSELECTED_VALUE_PRESSED_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(204, 225, 255)",
  "CHOICE_ICON_BUTTON_UNSELECTED_VALUE_PRESSED_STATE_LIGHT_MODE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "CHOICE_ICON_BUTTON_UNSELECTED_VALUE_PRESSED_STATE_DARK_MODE_BACKGROUND_COLOR": "rgb(0, 37, 89)",
  "CHOICE_ICON_BUTTON_UNSELECTED_VALUE_PRESSED_STATE_DARK_MODE_ICON_COLOR": "rgba(255, 255, 255, 0.95)",
  "CHOICE_ICON_BUTTON_UNSELECTED_VALUE_PRESSED_STATE_BACKGROUND_COLOR": "rgb(204, 225, 255)",
  "CHOICE_ICON_BUTTON_UNSELECTED_VALUE_PRESSED_STATE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "CHOICE_ICON_BUTTON_UNSELECTED_VALUE_DISABLED_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.05)",
  "CHOICE_ICON_BUTTON_UNSELECTED_VALUE_DISABLED_STATE_LIGHT_MODE_ICON_COLOR": "rgba(0, 0, 0, 0.3)",
  "CHOICE_ICON_BUTTON_UNSELECTED_VALUE_DISABLED_STATE_DARK_MODE_BACKGROUND_COLOR": "rgba(255, 255, 255, 0.08)",
  "CHOICE_ICON_BUTTON_UNSELECTED_VALUE_DISABLED_STATE_DARK_MODE_ICON_COLOR": "rgba(255, 255, 255, 0.3)",
  "CHOICE_ICON_BUTTON_UNSELECTED_VALUE_DISABLED_STATE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.05)",
  "CHOICE_ICON_BUTTON_UNSELECTED_VALUE_DISABLED_STATE_ICON_COLOR": "rgba(0, 0, 0, 0.3)",
  "CHOICE_ICON_BUTTON_SELECTED_VALUE_NORMAL_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.9)",
  "CHOICE_ICON_BUTTON_SELECTED_VALUE_NORMAL_STATE_LIGHT_MODE_ICON_COLOR": "rgba(255, 255, 255, 0.95)",
  "CHOICE_ICON_BUTTON_SELECTED_VALUE_NORMAL_STATE_DARK_MODE_BACKGROUND_COLOR": "rgba(255, 255, 255, 0.95)",
  "CHOICE_ICON_BUTTON_SELECTED_VALUE_NORMAL_STATE_DARK_MODE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "CHOICE_ICON_BUTTON_SELECTED_VALUE_NORMAL_STATE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.9)",
  "CHOICE_ICON_BUTTON_SELECTED_VALUE_NORMAL_STATE_ICON_COLOR": "rgba(255, 255, 255, 0.95)",
  "CHOICE_ICON_BUTTON_SELECTED_VALUE_HOVER_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.9)",
  "CHOICE_ICON_BUTTON_SELECTED_VALUE_HOVER_STATE_LIGHT_MODE_ICON_COLOR": "rgba(255, 255, 255, 0.95)",
  "CHOICE_ICON_BUTTON_SELECTED_VALUE_HOVER_STATE_DARK_MODE_BACKGROUND_COLOR": "rgba(255, 255, 255, 0.95)",
  "CHOICE_ICON_BUTTON_SELECTED_VALUE_HOVER_STATE_DARK_MODE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "CHOICE_ICON_BUTTON_SELECTED_VALUE_HOVER_STATE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.9)",
  "CHOICE_ICON_BUTTON_SELECTED_VALUE_HOVER_STATE_ICON_COLOR": "rgba(255, 255, 255, 0.95)",
  "CHOICE_ICON_BUTTON_SELECTED_VALUE_PRESSED_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.3)",
  "CHOICE_ICON_BUTTON_SELECTED_VALUE_PRESSED_STATE_LIGHT_MODE_ICON_COLOR": "rgba(255, 255, 255, 0.95)",
  "CHOICE_ICON_BUTTON_SELECTED_VALUE_PRESSED_STATE_DARK_MODE_BACKGROUND_COLOR": "rgba(255, 255, 255, 0.3)",
  "CHOICE_ICON_BUTTON_SELECTED_VALUE_PRESSED_STATE_DARK_MODE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "CHOICE_ICON_BUTTON_SELECTED_VALUE_PRESSED_STATE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.3)",
  "CHOICE_ICON_BUTTON_SELECTED_VALUE_PRESSED_STATE_ICON_COLOR": "rgba(255, 255, 255, 0.95)",
  "CHOICE_ICON_BUTTON_SELECTED_VALUE_DISABLED_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.15)",
  "CHOICE_ICON_BUTTON_SELECTED_VALUE_DISABLED_STATE_LIGHT_MODE_ICON_COLOR": "rgba(255, 255, 255, 0.95)",
  "CHOICE_ICON_BUTTON_SELECTED_VALUE_DISABLED_STATE_DARK_MODE_BACKGROUND_COLOR": "rgba(255, 255, 255, 0.15)",
  "CHOICE_ICON_BUTTON_SELECTED_VALUE_DISABLED_STATE_DARK_MODE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "CHOICE_ICON_BUTTON_SELECTED_VALUE_DISABLED_STATE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.15)",
  "CHOICE_ICON_BUTTON_SELECTED_VALUE_DISABLED_STATE_ICON_COLOR": "rgba(255, 255, 255, 0.95)",
  "COLOR_PICKER_VERTICAL_SPACING": 16,
  "COLOR_PICKER_DOTS_SIZE": 40,
  "COLOR_PICKER_DOTS_HORIZONTAL_SPACING": 14,
  "COLOR_PICKER_DOTS_VERTICAL_SPACING": 16,
  "COLOR_PICKER_DOTS_ICON_LIGHT_MODE_COLOR": "rgb(255, 255, 255)",
  "COLOR_PICKER_DOTS_ICON_DARK_MODE_COLOR": "rgb(255, 255, 255)",
  "COLOR_PICKER_DOTS_ICON_SIZE": 24,
  "COLOR_PICKER_DOTS_ICON_ASSET": "check",
  "COLOR_PICKER_DOTS_ICON_COLOR": "rgb(255, 255, 255)",
  "COLOR_PICKER_DOTS_ICON_WIDTH": 24,
  "COLOR_PICKER_DOTS_ICON_HEIGHT": 24,
  "COLOR_PICKER_DOTS_ICON_TINTABLE": true,
  "COLOR_PICKER_ADJUSTMENTS_HUE_RADIUS": 6,
  "COLOR_PICKER_ADJUSTMENTS_HUE_MIN_HEIGHT": 40,
  "COLOR_PICKER_ADJUSTMENTS_SATURATION_BRIGHTNESS_RADIUS": 12,
  "COLOR_PICKER_ADJUSTMENTS_SATURATION_BRIGHTNESS_MIN_HEIGHT": 200,
  "COLOR_PICKER_ADJUSTMENTS_SCRUBBER_SIZE": 16,
  "COLOR_PICKER_ADJUSTMENTS_SCRUBBER_WIDTH": 2,
  "COLOR_PICKER_ADJUSTMENTS_SCRUBBER_PADDING": 4,
  "COLOR_PICKER_ADJUSTMENTS_SCRUBBER_MULTIPLIER": 1.25,
  "COMBOBOX_TAGS_HORIZONTAL_SPACING": 8,
  "CONTENT_CARD_BORDER_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.15)",
  "CONTENT_CARD_BORDER_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.15)",
  "CONTENT_CARD_BORDER_WIDTH": 1,
  "CONTENT_CARD_BORDER_RADIUS": 6,
  "CONTENT_CARD_BORDER_COLOR": "rgba(0, 0, 0, 0.15)",
  "CONTENT_CARD_PADDING_HORIZONTAL_SIZE": 16,
  "CONTENT_CARD_PADDING_VERTICAL_SIZE": 16,
  "CONTENT_CARD_BACKGROUND_LIGHT_MODE_COLOR": "rgb(255, 255, 255)",
  "CONTENT_CARD_BACKGROUND_DARK_MODE_COLOR": "rgb(20, 20, 20)",
  "CONTENT_CARD_BACKGROUND_COLOR": "rgb(255, 255, 255)",
  "DATE_PICKER_MINIMUM_WIDTH": 280,
  "DATE_PICKER_HEADER_FONT_SIZE": 25,
  "DATE_PICKER_HEADER_FONT_LEADING": 32,
  "DATE_PICKER_HEADER_FONT_TRACKING": 0,
  "DATE_PICKER_HEADER_FONT_WEIGHT": 700,
  "DATE_PICKER_HEADER_FONT_CASE": "regular",
  "DATE_PICKER_HEADER_FONT_NUMBER_SPACING": "default",
  "DATE_PICKER_HEADER_FONT_SCALE": "heading-30",
  "DATE_PICKER_HEADER_FONT_FONT_FAMILY": "Square Sans Display",
  "DATE_PICKER_HEADER_FONT_IS_HEADER": true,
  "DATE_PICKER_HEADER_COLOR_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "DATE_PICKER_HEADER_COLOR_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "DATE_PICKER_HEADER_COLOR_COLOR": "rgba(0, 0, 0, 0.9)",
  "DATE_PICKER_HEADER_MINIMUM_HEIGHT": 40,
  "DATE_PICKER_HEADER_VERTICAL_SPACING": 16,
  "DATE_PICKER_HEADER_BUTTON_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "DATE_PICKER_HEADER_BUTTON_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "DATE_PICKER_HEADER_BUTTON_BACKGROUND_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.05)",
  "DATE_PICKER_HEADER_BUTTON_BACKGROUND_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.08)",
  "DATE_PICKER_HEADER_BUTTON_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.05)",
  "DATE_PICKER_HEADER_BUTTON_BORDER_RADIUS": 6,
  "DATE_PICKER_HEADER_BUTTON_PREVIOUS_YEAR_ICON_ASSET": "arrow-left",
  "DATE_PICKER_HEADER_BUTTON_PREVIOUS_YEAR_ICON_WIDTH": 24,
  "DATE_PICKER_HEADER_BUTTON_PREVIOUS_YEAR_ICON_HEIGHT": 24,
  "DATE_PICKER_HEADER_BUTTON_PREVIOUS_YEAR_ICON_TINTABLE": true,
  "DATE_PICKER_HEADER_BUTTON_NEXT_YEAR_ICON_ASSET": "arrow-right",
  "DATE_PICKER_HEADER_BUTTON_NEXT_YEAR_ICON_WIDTH": 24,
  "DATE_PICKER_HEADER_BUTTON_NEXT_YEAR_ICON_HEIGHT": 24,
  "DATE_PICKER_HEADER_BUTTON_NEXT_YEAR_ICON_TINTABLE": true,
  "DATE_PICKER_HEADER_BUTTON_PADDING": 8,
  "DATE_PICKER_HEADER_BUTTON_HORIZONTAL_SPACING": 16,
  "DATE_PICKER_HEADER_BUTTON_COLOR": "rgba(0, 0, 0, 0.9)",
  "DATE_PICKER_GRID_ITEM_WIDTH": 40,
  "DATE_PICKER_GRID_ITEM_HEIGHT": 40,
  "DATE_PICKER_GRID_ITEM_VERTICAL_PADDING": 8,
  "DATE_PICKER_GRID_ITEM_HORIZONTAL_PADDING": 0,
  "DATE_PICKER_WEEKDAYS_FONT_SIZE": 16,
  "DATE_PICKER_WEEKDAYS_FONT_LEADING": 24,
  "DATE_PICKER_WEEKDAYS_FONT_TRACKING": 0,
  "DATE_PICKER_WEEKDAYS_FONT_WEIGHT": 400,
  "DATE_PICKER_WEEKDAYS_FONT_CASE": "regular",
  "DATE_PICKER_WEEKDAYS_FONT_NUMBER_SPACING": "default",
  "DATE_PICKER_WEEKDAYS_FONT_SCALE": "paragraph-30",
  "DATE_PICKER_WEEKDAYS_FONT_FONT_FAMILY": "Square Sans Text",
  "DATE_PICKER_WEEKDAYS_COLOR_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.55)",
  "DATE_PICKER_WEEKDAYS_COLOR_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.55)",
  "DATE_PICKER_WEEKDAYS_COLOR_COLOR": "rgba(0, 0, 0, 0.55)",
  "DATE_PICKER_DATE_BACKGROUND_UNSELECTED_SELECTION_NORMAL_STATE_LIGHT_MODE_COLOR": "rgb(255, 255, 255)",
  "DATE_PICKER_DATE_BACKGROUND_UNSELECTED_SELECTION_NORMAL_STATE_DARK_MODE_COLOR": "rgb(8, 8, 8)",
  "DATE_PICKER_DATE_BACKGROUND_UNSELECTED_SELECTION_NORMAL_STATE_COLOR": "rgb(255, 255, 255)",
  "DATE_PICKER_DATE_BACKGROUND_UNSELECTED_SELECTION_DISABLED_STATE_LIGHT_MODE_COLOR": "rgb(255, 255, 255)",
  "DATE_PICKER_DATE_BACKGROUND_UNSELECTED_SELECTION_DISABLED_STATE_DARK_MODE_COLOR": "rgb(8, 8, 8)",
  "DATE_PICKER_DATE_BACKGROUND_UNSELECTED_SELECTION_DISABLED_STATE_COLOR": "rgb(255, 255, 255)",
  "DATE_PICKER_DATE_BACKGROUND_UNSELECTED_SELECTION_HOVER_STATE_LIGHT_MODE_COLOR": "rgb(230, 240, 255)",
  "DATE_PICKER_DATE_BACKGROUND_UNSELECTED_SELECTION_HOVER_STATE_DARK_MODE_COLOR": "rgb(0, 21, 51)",
  "DATE_PICKER_DATE_BACKGROUND_UNSELECTED_SELECTION_HOVER_STATE_COLOR": "rgb(230, 240, 255)",
  "DATE_PICKER_DATE_BACKGROUND_UNSELECTED_SELECTION_PRESSED_STATE_LIGHT_MODE_COLOR": "rgb(204, 225, 255)",
  "DATE_PICKER_DATE_BACKGROUND_UNSELECTED_SELECTION_PRESSED_STATE_DARK_MODE_COLOR": "rgb(0, 37, 89)",
  "DATE_PICKER_DATE_BACKGROUND_UNSELECTED_SELECTION_PRESSED_STATE_COLOR": "rgb(204, 225, 255)",
  "DATE_PICKER_DATE_BACKGROUND_RANGE_FIRST_SELECTION_NORMAL_STATE_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "DATE_PICKER_DATE_BACKGROUND_RANGE_FIRST_SELECTION_NORMAL_STATE_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "DATE_PICKER_DATE_BACKGROUND_RANGE_FIRST_SELECTION_NORMAL_STATE_COLOR": "rgba(0, 0, 0, 0.9)",
  "DATE_PICKER_DATE_BACKGROUND_RANGE_FIRST_SELECTION_DISABLED_STATE_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.15)",
  "DATE_PICKER_DATE_BACKGROUND_RANGE_FIRST_SELECTION_DISABLED_STATE_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.15)",
  "DATE_PICKER_DATE_BACKGROUND_RANGE_FIRST_SELECTION_DISABLED_STATE_COLOR": "rgba(0, 0, 0, 0.15)",
  "DATE_PICKER_DATE_BACKGROUND_RANGE_FIRST_SELECTION_HOVER_STATE_LIGHT_MODE_COLOR": "rgb(230, 240, 255)",
  "DATE_PICKER_DATE_BACKGROUND_RANGE_FIRST_SELECTION_HOVER_STATE_DARK_MODE_COLOR": "rgb(0, 21, 51)",
  "DATE_PICKER_DATE_BACKGROUND_RANGE_FIRST_SELECTION_HOVER_STATE_COLOR": "rgb(230, 240, 255)",
  "DATE_PICKER_DATE_BACKGROUND_RANGE_FIRST_SELECTION_PRESSED_STATE_LIGHT_MODE_COLOR": "rgb(204, 225, 255)",
  "DATE_PICKER_DATE_BACKGROUND_RANGE_FIRST_SELECTION_PRESSED_STATE_DARK_MODE_COLOR": "rgb(0, 37, 89)",
  "DATE_PICKER_DATE_BACKGROUND_RANGE_FIRST_SELECTION_PRESSED_STATE_COLOR": "rgb(204, 225, 255)",
  "DATE_PICKER_DATE_BACKGROUND_RANGE_MIDDLE_SELECTION_NORMAL_STATE_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.05)",
  "DATE_PICKER_DATE_BACKGROUND_RANGE_MIDDLE_SELECTION_NORMAL_STATE_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.08)",
  "DATE_PICKER_DATE_BACKGROUND_RANGE_MIDDLE_SELECTION_NORMAL_STATE_COLOR": "rgba(0, 0, 0, 0.05)",
  "DATE_PICKER_DATE_BACKGROUND_RANGE_MIDDLE_SELECTION_DISABLED_STATE_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.05)",
  "DATE_PICKER_DATE_BACKGROUND_RANGE_MIDDLE_SELECTION_DISABLED_STATE_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.08)",
  "DATE_PICKER_DATE_BACKGROUND_RANGE_MIDDLE_SELECTION_DISABLED_STATE_COLOR": "rgba(0, 0, 0, 0.05)",
  "DATE_PICKER_DATE_BACKGROUND_RANGE_MIDDLE_SELECTION_HOVER_STATE_LIGHT_MODE_COLOR": "rgb(230, 240, 255)",
  "DATE_PICKER_DATE_BACKGROUND_RANGE_MIDDLE_SELECTION_HOVER_STATE_DARK_MODE_COLOR": "rgb(0, 21, 51)",
  "DATE_PICKER_DATE_BACKGROUND_RANGE_MIDDLE_SELECTION_HOVER_STATE_COLOR": "rgb(230, 240, 255)",
  "DATE_PICKER_DATE_BACKGROUND_RANGE_MIDDLE_SELECTION_PRESSED_STATE_LIGHT_MODE_COLOR": "rgb(204, 225, 255)",
  "DATE_PICKER_DATE_BACKGROUND_RANGE_MIDDLE_SELECTION_PRESSED_STATE_DARK_MODE_COLOR": "rgb(0, 37, 89)",
  "DATE_PICKER_DATE_BACKGROUND_RANGE_MIDDLE_SELECTION_PRESSED_STATE_COLOR": "rgb(204, 225, 255)",
  "DATE_PICKER_DATE_BACKGROUND_RANGE_LAST_SELECTION_NORMAL_STATE_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "DATE_PICKER_DATE_BACKGROUND_RANGE_LAST_SELECTION_NORMAL_STATE_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "DATE_PICKER_DATE_BACKGROUND_RANGE_LAST_SELECTION_NORMAL_STATE_COLOR": "rgba(0, 0, 0, 0.9)",
  "DATE_PICKER_DATE_BACKGROUND_RANGE_LAST_SELECTION_DISABLED_STATE_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.15)",
  "DATE_PICKER_DATE_BACKGROUND_RANGE_LAST_SELECTION_DISABLED_STATE_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.15)",
  "DATE_PICKER_DATE_BACKGROUND_RANGE_LAST_SELECTION_DISABLED_STATE_COLOR": "rgba(0, 0, 0, 0.15)",
  "DATE_PICKER_DATE_BACKGROUND_RANGE_LAST_SELECTION_HOVER_STATE_LIGHT_MODE_COLOR": "rgb(230, 240, 255)",
  "DATE_PICKER_DATE_BACKGROUND_RANGE_LAST_SELECTION_HOVER_STATE_DARK_MODE_COLOR": "rgb(0, 21, 51)",
  "DATE_PICKER_DATE_BACKGROUND_RANGE_LAST_SELECTION_HOVER_STATE_COLOR": "rgb(230, 240, 255)",
  "DATE_PICKER_DATE_BACKGROUND_RANGE_LAST_SELECTION_PRESSED_STATE_LIGHT_MODE_COLOR": "rgb(204, 225, 255)",
  "DATE_PICKER_DATE_BACKGROUND_RANGE_LAST_SELECTION_PRESSED_STATE_DARK_MODE_COLOR": "rgb(0, 37, 89)",
  "DATE_PICKER_DATE_BACKGROUND_RANGE_LAST_SELECTION_PRESSED_STATE_COLOR": "rgb(204, 225, 255)",
  "DATE_PICKER_DATE_BACKGROUND_SINGLE_SELECTION_NORMAL_STATE_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "DATE_PICKER_DATE_BACKGROUND_SINGLE_SELECTION_NORMAL_STATE_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "DATE_PICKER_DATE_BACKGROUND_SINGLE_SELECTION_NORMAL_STATE_COLOR": "rgba(0, 0, 0, 0.9)",
  "DATE_PICKER_DATE_BACKGROUND_SINGLE_SELECTION_DISABLED_STATE_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.15)",
  "DATE_PICKER_DATE_BACKGROUND_SINGLE_SELECTION_DISABLED_STATE_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.15)",
  "DATE_PICKER_DATE_BACKGROUND_SINGLE_SELECTION_DISABLED_STATE_COLOR": "rgba(0, 0, 0, 0.15)",
  "DATE_PICKER_DATE_BACKGROUND_SINGLE_SELECTION_HOVER_STATE_LIGHT_MODE_COLOR": "rgb(230, 240, 255)",
  "DATE_PICKER_DATE_BACKGROUND_SINGLE_SELECTION_HOVER_STATE_DARK_MODE_COLOR": "rgb(0, 21, 51)",
  "DATE_PICKER_DATE_BACKGROUND_SINGLE_SELECTION_HOVER_STATE_COLOR": "rgb(230, 240, 255)",
  "DATE_PICKER_DATE_BACKGROUND_SINGLE_SELECTION_PRESSED_STATE_LIGHT_MODE_COLOR": "rgb(204, 225, 255)",
  "DATE_PICKER_DATE_BACKGROUND_SINGLE_SELECTION_PRESSED_STATE_DARK_MODE_COLOR": "rgb(0, 37, 89)",
  "DATE_PICKER_DATE_BACKGROUND_SINGLE_SELECTION_PRESSED_STATE_COLOR": "rgb(204, 225, 255)",
  "DATE_PICKER_DATE_TEXT_UNSELECTED_SELECTION_NORMAL_STATE_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "DATE_PICKER_DATE_TEXT_UNSELECTED_SELECTION_NORMAL_STATE_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "DATE_PICKER_DATE_TEXT_UNSELECTED_SELECTION_NORMAL_STATE_COLOR": "rgba(0, 0, 0, 0.9)",
  "DATE_PICKER_DATE_TEXT_UNSELECTED_SELECTION_DISABLED_STATE_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.3)",
  "DATE_PICKER_DATE_TEXT_UNSELECTED_SELECTION_DISABLED_STATE_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.3)",
  "DATE_PICKER_DATE_TEXT_UNSELECTED_SELECTION_DISABLED_STATE_COLOR": "rgba(0, 0, 0, 0.3)",
  "DATE_PICKER_DATE_TEXT_UNSELECTED_SELECTION_HOVER_STATE_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "DATE_PICKER_DATE_TEXT_UNSELECTED_SELECTION_HOVER_STATE_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "DATE_PICKER_DATE_TEXT_UNSELECTED_SELECTION_HOVER_STATE_COLOR": "rgba(0, 0, 0, 0.9)",
  "DATE_PICKER_DATE_TEXT_UNSELECTED_SELECTION_PRESSED_STATE_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "DATE_PICKER_DATE_TEXT_UNSELECTED_SELECTION_PRESSED_STATE_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "DATE_PICKER_DATE_TEXT_UNSELECTED_SELECTION_PRESSED_STATE_COLOR": "rgba(0, 0, 0, 0.9)",
  "DATE_PICKER_DATE_TEXT_RANGE_FIRST_SELECTION_NORMAL_STATE_LIGHT_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "DATE_PICKER_DATE_TEXT_RANGE_FIRST_SELECTION_NORMAL_STATE_DARK_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "DATE_PICKER_DATE_TEXT_RANGE_FIRST_SELECTION_NORMAL_STATE_COLOR": "rgba(255, 255, 255, 0.95)",
  "DATE_PICKER_DATE_TEXT_RANGE_FIRST_SELECTION_DISABLED_STATE_LIGHT_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "DATE_PICKER_DATE_TEXT_RANGE_FIRST_SELECTION_DISABLED_STATE_DARK_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "DATE_PICKER_DATE_TEXT_RANGE_FIRST_SELECTION_DISABLED_STATE_COLOR": "rgba(255, 255, 255, 0.95)",
  "DATE_PICKER_DATE_TEXT_RANGE_FIRST_SELECTION_HOVER_STATE_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "DATE_PICKER_DATE_TEXT_RANGE_FIRST_SELECTION_HOVER_STATE_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "DATE_PICKER_DATE_TEXT_RANGE_FIRST_SELECTION_HOVER_STATE_COLOR": "rgba(0, 0, 0, 0.9)",
  "DATE_PICKER_DATE_TEXT_RANGE_FIRST_SELECTION_PRESSED_STATE_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "DATE_PICKER_DATE_TEXT_RANGE_FIRST_SELECTION_PRESSED_STATE_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "DATE_PICKER_DATE_TEXT_RANGE_FIRST_SELECTION_PRESSED_STATE_COLOR": "rgba(0, 0, 0, 0.9)",
  "DATE_PICKER_DATE_TEXT_RANGE_MIDDLE_SELECTION_NORMAL_STATE_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "DATE_PICKER_DATE_TEXT_RANGE_MIDDLE_SELECTION_NORMAL_STATE_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "DATE_PICKER_DATE_TEXT_RANGE_MIDDLE_SELECTION_NORMAL_STATE_COLOR": "rgba(0, 0, 0, 0.9)",
  "DATE_PICKER_DATE_TEXT_RANGE_MIDDLE_SELECTION_DISABLED_STATE_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.3)",
  "DATE_PICKER_DATE_TEXT_RANGE_MIDDLE_SELECTION_DISABLED_STATE_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.3)",
  "DATE_PICKER_DATE_TEXT_RANGE_MIDDLE_SELECTION_DISABLED_STATE_COLOR": "rgba(0, 0, 0, 0.3)",
  "DATE_PICKER_DATE_TEXT_RANGE_MIDDLE_SELECTION_HOVER_STATE_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "DATE_PICKER_DATE_TEXT_RANGE_MIDDLE_SELECTION_HOVER_STATE_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "DATE_PICKER_DATE_TEXT_RANGE_MIDDLE_SELECTION_HOVER_STATE_COLOR": "rgba(0, 0, 0, 0.9)",
  "DATE_PICKER_DATE_TEXT_RANGE_MIDDLE_SELECTION_PRESSED_STATE_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "DATE_PICKER_DATE_TEXT_RANGE_MIDDLE_SELECTION_PRESSED_STATE_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "DATE_PICKER_DATE_TEXT_RANGE_MIDDLE_SELECTION_PRESSED_STATE_COLOR": "rgba(0, 0, 0, 0.9)",
  "DATE_PICKER_DATE_TEXT_RANGE_LAST_SELECTION_NORMAL_STATE_LIGHT_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "DATE_PICKER_DATE_TEXT_RANGE_LAST_SELECTION_NORMAL_STATE_DARK_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "DATE_PICKER_DATE_TEXT_RANGE_LAST_SELECTION_NORMAL_STATE_COLOR": "rgba(255, 255, 255, 0.95)",
  "DATE_PICKER_DATE_TEXT_RANGE_LAST_SELECTION_DISABLED_STATE_LIGHT_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "DATE_PICKER_DATE_TEXT_RANGE_LAST_SELECTION_DISABLED_STATE_DARK_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "DATE_PICKER_DATE_TEXT_RANGE_LAST_SELECTION_DISABLED_STATE_COLOR": "rgba(255, 255, 255, 0.95)",
  "DATE_PICKER_DATE_TEXT_RANGE_LAST_SELECTION_HOVER_STATE_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "DATE_PICKER_DATE_TEXT_RANGE_LAST_SELECTION_HOVER_STATE_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "DATE_PICKER_DATE_TEXT_RANGE_LAST_SELECTION_HOVER_STATE_COLOR": "rgba(0, 0, 0, 0.9)",
  "DATE_PICKER_DATE_TEXT_RANGE_LAST_SELECTION_PRESSED_STATE_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "DATE_PICKER_DATE_TEXT_RANGE_LAST_SELECTION_PRESSED_STATE_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "DATE_PICKER_DATE_TEXT_RANGE_LAST_SELECTION_PRESSED_STATE_COLOR": "rgba(0, 0, 0, 0.9)",
  "DATE_PICKER_DATE_TEXT_SINGLE_SELECTION_NORMAL_STATE_LIGHT_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "DATE_PICKER_DATE_TEXT_SINGLE_SELECTION_NORMAL_STATE_DARK_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "DATE_PICKER_DATE_TEXT_SINGLE_SELECTION_NORMAL_STATE_COLOR": "rgba(255, 255, 255, 0.95)",
  "DATE_PICKER_DATE_TEXT_SINGLE_SELECTION_DISABLED_STATE_LIGHT_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "DATE_PICKER_DATE_TEXT_SINGLE_SELECTION_DISABLED_STATE_DARK_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "DATE_PICKER_DATE_TEXT_SINGLE_SELECTION_DISABLED_STATE_COLOR": "rgba(255, 255, 255, 0.95)",
  "DATE_PICKER_DATE_TEXT_SINGLE_SELECTION_HOVER_STATE_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "DATE_PICKER_DATE_TEXT_SINGLE_SELECTION_HOVER_STATE_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "DATE_PICKER_DATE_TEXT_SINGLE_SELECTION_HOVER_STATE_COLOR": "rgba(0, 0, 0, 0.9)",
  "DATE_PICKER_DATE_TEXT_SINGLE_SELECTION_PRESSED_STATE_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "DATE_PICKER_DATE_TEXT_SINGLE_SELECTION_PRESSED_STATE_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "DATE_PICKER_DATE_TEXT_SINGLE_SELECTION_PRESSED_STATE_COLOR": "rgba(0, 0, 0, 0.9)",
  "DATE_PICKER_DATE_BORDER_RADIUS": 6,
  "DATE_PICKER_DATE_BORDER_NORMAL_STATE_BORDER": 2,
  "DATE_PICKER_DATE_BORDER_NORMAL_STATE_COLOR_LIGHT_MODE_COLOR": "rgb(0, 106, 255)",
  "DATE_PICKER_DATE_BORDER_NORMAL_STATE_COLOR_DARK_MODE_COLOR": "rgb(0, 106, 255)",
  "DATE_PICKER_DATE_BORDER_NORMAL_STATE_COLOR_COLOR": "rgb(0, 106, 255)",
  "DATE_PICKER_DATE_BORDER_NORMAL_STATE_OPACITY": 0,
  "DATE_PICKER_DATE_BORDER_FOCUSED_STATE_BORDER": 2,
  "DATE_PICKER_DATE_BORDER_FOCUSED_STATE_COLOR_LIGHT_MODE_COLOR": "rgb(0, 106, 255)",
  "DATE_PICKER_DATE_BORDER_FOCUSED_STATE_COLOR_DARK_MODE_COLOR": "rgb(0, 106, 255)",
  "DATE_PICKER_DATE_BORDER_FOCUSED_STATE_COLOR_COLOR": "rgb(0, 106, 255)",
  "DATE_PICKER_DATE_BORDER_FOCUSED_STATE_OPACITY": 1,
  "DATE_PICKER_DATE_BORDER_DISABLED_STATE_COLOR_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.15)",
  "DATE_PICKER_DATE_BORDER_DISABLED_STATE_COLOR_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.15)",
  "DATE_PICKER_DATE_BORDER_DISABLED_STATE_COLOR_COLOR": "rgba(0, 0, 0, 0.15)",
  "DATE_PICKER_TODAY_BORDER_WIDTH": 1,
  "DATE_PICKER_TODAY_BORDER_COLOR_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "DATE_PICKER_TODAY_BORDER_COLOR_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "DATE_PICKER_TODAY_BORDER_COLOR_COLOR": "rgba(0, 0, 0, 0.9)",
  "DATE_PICKER_TODAY_FONT_SIZE": 16,
  "DATE_PICKER_TODAY_FONT_LEADING": 24,
  "DATE_PICKER_TODAY_FONT_TRACKING": 0,
  "DATE_PICKER_TODAY_FONT_WEIGHT": 600,
  "DATE_PICKER_TODAY_FONT_CASE": "regular",
  "DATE_PICKER_TODAY_FONT_NUMBER_SPACING": "default",
  "DATE_PICKER_TODAY_FONT_SCALE": "paragraph-30",
  "DATE_PICKER_TODAY_FONT_FONT_FAMILY": "Square Sans Text",
  "DATE_PICKER_TODAY_LABEL_DISABLED_STATE_COLOR_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.15)",
  "DATE_PICKER_TODAY_LABEL_DISABLED_STATE_COLOR_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.15)",
  "DATE_PICKER_TODAY_LABEL_DISABLED_STATE_COLOR_COLOR": "rgba(0, 0, 0, 0.15)",
  "DATE_PICKER_MENU_TEXT_SIZE": 16,
  "DATE_PICKER_MENU_TEXT_LEADING": 24,
  "DATE_PICKER_MENU_TEXT_TRACKING": 0,
  "DATE_PICKER_MENU_TEXT_WEIGHT": 600,
  "DATE_PICKER_MENU_TEXT_CASE": "regular",
  "DATE_PICKER_MENU_TEXT_NUMBER_SPACING": "default",
  "DATE_PICKER_MENU_TEXT_SCALE": "paragraph-30",
  "DATE_PICKER_MENU_TEXT_FONT_FAMILY": "Square Sans Text",
  "DATE_PICKER_MENU_ROW_LABEL_SELECTED_STATE_TEXT_LIGHT_MODE_COLOR": "rgb(0, 90, 217)",
  "DATE_PICKER_MENU_ROW_LABEL_SELECTED_STATE_TEXT_DARK_MODE_COLOR": "rgb(74, 149, 255)",
  "DATE_PICKER_MENU_ROW_LABEL_SELECTED_STATE_TEXT_COLOR": "rgb(0, 90, 217)",
  "DATE_PICKER_MENU_ROW_HEIGHT": 48,
  "DATE_PICKER_MENU_ROW_WIDTH": 111,
  "DATE_PICKER_MENU_HORIZONTAL_SPACING": 32,
  "DATE_PICKER_MENU_VERTICAL_SPACING": 24,
  "DIVIDER_FILL_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.05)",
  "DIVIDER_FILL_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.08)",
  "DIVIDER_FILL_COLOR": "rgba(0, 0, 0, 0.05)",
  "DIVIDER_HEIGHT": 8,
  "DIVIDER_RADIUS": 2,
  "DIVIDER_EXTRA_SMALL_VARIANT_VERTICAL_PADDING": 0,
  "DIVIDER_SMALL_VARIANT_VERTICAL_PADDING": 8,
  "DIVIDER_MEDIUM_VARIANT_VERTICAL_PADDING": 16,
  "DIVIDER_LARGE_VARIANT_VERTICAL_PADDING": 32,
  "DROPDOWN_BUTTON_SMALL_SIZE_MINIMUM_HEIGHT": 40,
  "DROPDOWN_BUTTON_SMALL_SIZE_MINIMUM_WIDTH_MULTIPLIER": 1.5,
  "DROPDOWN_BUTTON_SMALL_SIZE_TEXT_SIZE": 14,
  "DROPDOWN_BUTTON_SMALL_SIZE_TEXT_LEADING": 22,
  "DROPDOWN_BUTTON_SMALL_SIZE_TEXT_TRACKING": 0,
  "DROPDOWN_BUTTON_SMALL_SIZE_TEXT_WEIGHT": 600,
  "DROPDOWN_BUTTON_SMALL_SIZE_TEXT_CASE": "regular",
  "DROPDOWN_BUTTON_SMALL_SIZE_TEXT_NUMBER_SPACING": "default",
  "DROPDOWN_BUTTON_SMALL_SIZE_TEXT_SCALE": "paragraph-20",
  "DROPDOWN_BUTTON_SMALL_SIZE_TEXT_FONT_FAMILY": "Square Sans Text",
  "DROPDOWN_BUTTON_SMALL_SIZE_CONTENT_SPACING": 8,
  "DROPDOWN_BUTTON_SMALL_SIZE_PRIMARY_RANK_HORIZONTAL_PADDING": 12,
  "DROPDOWN_BUTTON_SMALL_SIZE_PRIMARY_RANK_VERTICAL_PADDING": 9,
  "DROPDOWN_BUTTON_SMALL_SIZE_SECONDARY_RANK_HORIZONTAL_PADDING": 12,
  "DROPDOWN_BUTTON_SMALL_SIZE_SECONDARY_RANK_VERTICAL_PADDING": 9,
  "DROPDOWN_BUTTON_SMALL_SIZE_TERTIARY_RANK_HORIZONTAL_PADDING": 8,
  "DROPDOWN_BUTTON_SMALL_SIZE_TERTIARY_RANK_VERTICAL_PADDING": 9,
  "DROPDOWN_BUTTON_SMALL_SIZE_CARET_ICON_ASSET": "chevron-down",
  "DROPDOWN_BUTTON_SMALL_SIZE_CARET_ICON_WIDTH": 16,
  "DROPDOWN_BUTTON_SMALL_SIZE_CARET_ICON_HEIGHT": 16,
  "DROPDOWN_BUTTON_SMALL_SIZE_CARET_ICON_TINTABLE": true,
  "DROPDOWN_BUTTON_MEDIUM_SIZE_MINIMUM_HEIGHT": 48,
  "DROPDOWN_BUTTON_MEDIUM_SIZE_MINIMUM_WIDTH_MULTIPLIER": 1.5,
  "DROPDOWN_BUTTON_MEDIUM_SIZE_TEXT_SIZE": 16,
  "DROPDOWN_BUTTON_MEDIUM_SIZE_TEXT_LEADING": 24,
  "DROPDOWN_BUTTON_MEDIUM_SIZE_TEXT_TRACKING": 0,
  "DROPDOWN_BUTTON_MEDIUM_SIZE_TEXT_WEIGHT": 600,
  "DROPDOWN_BUTTON_MEDIUM_SIZE_TEXT_CASE": "regular",
  "DROPDOWN_BUTTON_MEDIUM_SIZE_TEXT_NUMBER_SPACING": "default",
  "DROPDOWN_BUTTON_MEDIUM_SIZE_TEXT_SCALE": "paragraph-30",
  "DROPDOWN_BUTTON_MEDIUM_SIZE_TEXT_FONT_FAMILY": "Square Sans Text",
  "DROPDOWN_BUTTON_MEDIUM_SIZE_CONTENT_SPACING": 8,
  "DROPDOWN_BUTTON_MEDIUM_SIZE_PRIMARY_RANK_HORIZONTAL_PADDING": 16,
  "DROPDOWN_BUTTON_MEDIUM_SIZE_PRIMARY_RANK_VERTICAL_PADDING": 12,
  "DROPDOWN_BUTTON_MEDIUM_SIZE_SECONDARY_RANK_HORIZONTAL_PADDING": 16,
  "DROPDOWN_BUTTON_MEDIUM_SIZE_SECONDARY_RANK_VERTICAL_PADDING": 12,
  "DROPDOWN_BUTTON_MEDIUM_SIZE_TERTIARY_RANK_HORIZONTAL_PADDING": 8,
  "DROPDOWN_BUTTON_MEDIUM_SIZE_TERTIARY_RANK_VERTICAL_PADDING": 12,
  "DROPDOWN_BUTTON_MEDIUM_SIZE_CARET_ICON_ASSET": "chevron-down",
  "DROPDOWN_BUTTON_MEDIUM_SIZE_CARET_ICON_WIDTH": 16,
  "DROPDOWN_BUTTON_MEDIUM_SIZE_CARET_ICON_HEIGHT": 16,
  "DROPDOWN_BUTTON_MEDIUM_SIZE_CARET_ICON_TINTABLE": true,
  "DROPDOWN_BUTTON_LARGE_SIZE_MINIMUM_HEIGHT": 64,
  "DROPDOWN_BUTTON_LARGE_SIZE_MINIMUM_WIDTH_MULTIPLIER": 1.5,
  "DROPDOWN_BUTTON_LARGE_SIZE_TEXT_SIZE": 16,
  "DROPDOWN_BUTTON_LARGE_SIZE_TEXT_LEADING": 24,
  "DROPDOWN_BUTTON_LARGE_SIZE_TEXT_TRACKING": 0,
  "DROPDOWN_BUTTON_LARGE_SIZE_TEXT_WEIGHT": 600,
  "DROPDOWN_BUTTON_LARGE_SIZE_TEXT_CASE": "regular",
  "DROPDOWN_BUTTON_LARGE_SIZE_TEXT_NUMBER_SPACING": "default",
  "DROPDOWN_BUTTON_LARGE_SIZE_TEXT_SCALE": "paragraph-30",
  "DROPDOWN_BUTTON_LARGE_SIZE_TEXT_FONT_FAMILY": "Square Sans Text",
  "DROPDOWN_BUTTON_LARGE_SIZE_CONTENT_SPACING": 8,
  "DROPDOWN_BUTTON_LARGE_SIZE_PRIMARY_RANK_HORIZONTAL_PADDING": 20,
  "DROPDOWN_BUTTON_LARGE_SIZE_PRIMARY_RANK_VERTICAL_PADDING": 20,
  "DROPDOWN_BUTTON_LARGE_SIZE_SECONDARY_RANK_HORIZONTAL_PADDING": 20,
  "DROPDOWN_BUTTON_LARGE_SIZE_SECONDARY_RANK_VERTICAL_PADDING": 20,
  "DROPDOWN_BUTTON_LARGE_SIZE_TERTIARY_RANK_HORIZONTAL_PADDING": 8,
  "DROPDOWN_BUTTON_LARGE_SIZE_TERTIARY_RANK_VERTICAL_PADDING": 20,
  "DROPDOWN_BUTTON_LARGE_SIZE_CARET_ICON_ASSET": "chevron-down",
  "DROPDOWN_BUTTON_LARGE_SIZE_CARET_ICON_WIDTH": 16,
  "DROPDOWN_BUTTON_LARGE_SIZE_CARET_ICON_HEIGHT": 16,
  "DROPDOWN_BUTTON_LARGE_SIZE_CARET_ICON_TINTABLE": true,
  "DROPDOWN_BUTTON_BORDER_RADIUS": 6,
  "DROPDOWN_BUTTON_FOCUS_RING_BUFFER_SIZE": 2,
  "DROPDOWN_BUTTON_FOCUS_RING_BORDER_SIZE": 2,
  "DROPDOWN_BUTTON_NORMAL_VARIANT_PRIMARY_RANK_NORMAL_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(0, 106, 255)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_PRIMARY_RANK_NORMAL_STATE_LIGHT_MODE_LABEL_COLOR": "rgb(255, 255, 255)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_PRIMARY_RANK_NORMAL_STATE_DARK_MODE_BACKGROUND_COLOR": "rgb(0, 106, 255)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_PRIMARY_RANK_NORMAL_STATE_DARK_MODE_LABEL_COLOR": "rgb(255, 255, 255)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_PRIMARY_RANK_NORMAL_STATE_BACKGROUND_COLOR": "rgb(0, 106, 255)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_PRIMARY_RANK_NORMAL_STATE_LABEL_COLOR": "rgb(255, 255, 255)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_PRIMARY_RANK_HOVER_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(0, 95, 229)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_PRIMARY_RANK_HOVER_STATE_LIGHT_MODE_LABEL_COLOR": "rgb(255, 255, 255)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_PRIMARY_RANK_HOVER_STATE_DARK_MODE_BACKGROUND_COLOR": "rgb(0, 95, 229)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_PRIMARY_RANK_HOVER_STATE_DARK_MODE_LABEL_COLOR": "rgb(255, 255, 255)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_PRIMARY_RANK_HOVER_STATE_BACKGROUND_COLOR": "rgb(0, 95, 229)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_PRIMARY_RANK_HOVER_STATE_LABEL_COLOR": "rgb(255, 255, 255)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_PRIMARY_RANK_PRESSED_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(0, 85, 204)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_PRIMARY_RANK_PRESSED_STATE_LIGHT_MODE_LABEL_COLOR": "rgb(255, 255, 255)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_PRIMARY_RANK_PRESSED_STATE_DARK_MODE_BACKGROUND_COLOR": "rgb(0, 85, 204)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_PRIMARY_RANK_PRESSED_STATE_DARK_MODE_LABEL_COLOR": "rgb(255, 255, 255)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_PRIMARY_RANK_PRESSED_STATE_BACKGROUND_COLOR": "rgb(0, 85, 204)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_PRIMARY_RANK_PRESSED_STATE_LABEL_COLOR": "rgb(255, 255, 255)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_PRIMARY_RANK_FOCUS_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(0, 106, 255)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_PRIMARY_RANK_FOCUS_STATE_LIGHT_MODE_LABEL_COLOR": "rgb(255, 255, 255)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_PRIMARY_RANK_FOCUS_STATE_DARK_MODE_BACKGROUND_COLOR": "rgb(0, 106, 255)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_PRIMARY_RANK_FOCUS_STATE_DARK_MODE_LABEL_COLOR": "rgb(255, 255, 255)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_PRIMARY_RANK_FOCUS_STATE_BACKGROUND_COLOR": "rgb(0, 106, 255)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_PRIMARY_RANK_FOCUS_STATE_LABEL_COLOR": "rgb(255, 255, 255)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_PRIMARY_RANK_DISABLED_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.05)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_PRIMARY_RANK_DISABLED_STATE_LIGHT_MODE_LABEL_COLOR": "rgba(0, 0, 0, 0.3)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_PRIMARY_RANK_DISABLED_STATE_DARK_MODE_BACKGROUND_COLOR": "rgba(255, 255, 255, 0.08)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_PRIMARY_RANK_DISABLED_STATE_DARK_MODE_LABEL_COLOR": "rgba(255, 255, 255, 0.3)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_PRIMARY_RANK_DISABLED_STATE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.05)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_PRIMARY_RANK_DISABLED_STATE_LABEL_COLOR": "rgba(0, 0, 0, 0.3)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_SECONDARY_RANK_NORMAL_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.05)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_SECONDARY_RANK_NORMAL_STATE_LIGHT_MODE_LABEL_COLOR": "rgb(0, 90, 217)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_SECONDARY_RANK_NORMAL_STATE_DARK_MODE_BACKGROUND_COLOR": "rgba(255, 255, 255, 0.08)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_SECONDARY_RANK_NORMAL_STATE_DARK_MODE_LABEL_COLOR": "rgb(74, 149, 255)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_SECONDARY_RANK_NORMAL_STATE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.05)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_SECONDARY_RANK_NORMAL_STATE_LABEL_COLOR": "rgb(0, 90, 217)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_SECONDARY_RANK_HOVER_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(230, 240, 255)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_SECONDARY_RANK_HOVER_STATE_LIGHT_MODE_LABEL_COLOR": "rgb(0, 90, 217)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_SECONDARY_RANK_HOVER_STATE_DARK_MODE_BACKGROUND_COLOR": "rgb(0, 21, 51)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_SECONDARY_RANK_HOVER_STATE_DARK_MODE_LABEL_COLOR": "rgb(74, 149, 255)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_SECONDARY_RANK_HOVER_STATE_BACKGROUND_COLOR": "rgb(230, 240, 255)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_SECONDARY_RANK_HOVER_STATE_LABEL_COLOR": "rgb(0, 90, 217)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_SECONDARY_RANK_PRESSED_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(204, 225, 255)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_SECONDARY_RANK_PRESSED_STATE_LIGHT_MODE_LABEL_COLOR": "rgb(0, 90, 217)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_SECONDARY_RANK_PRESSED_STATE_DARK_MODE_BACKGROUND_COLOR": "rgb(0, 37, 89)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_SECONDARY_RANK_PRESSED_STATE_DARK_MODE_LABEL_COLOR": "rgb(74, 149, 255)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_SECONDARY_RANK_PRESSED_STATE_BACKGROUND_COLOR": "rgb(204, 225, 255)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_SECONDARY_RANK_PRESSED_STATE_LABEL_COLOR": "rgb(0, 90, 217)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_SECONDARY_RANK_FOCUS_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(230, 240, 255)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_SECONDARY_RANK_FOCUS_STATE_LIGHT_MODE_LABEL_COLOR": "rgb(0, 90, 217)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_SECONDARY_RANK_FOCUS_STATE_DARK_MODE_BACKGROUND_COLOR": "rgb(0, 21, 51)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_SECONDARY_RANK_FOCUS_STATE_DARK_MODE_LABEL_COLOR": "rgb(74, 149, 255)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_SECONDARY_RANK_FOCUS_STATE_BACKGROUND_COLOR": "rgb(230, 240, 255)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_SECONDARY_RANK_FOCUS_STATE_LABEL_COLOR": "rgb(0, 90, 217)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_SECONDARY_RANK_DISABLED_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.05)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_SECONDARY_RANK_DISABLED_STATE_LIGHT_MODE_LABEL_COLOR": "rgba(0, 0, 0, 0.3)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_SECONDARY_RANK_DISABLED_STATE_DARK_MODE_BACKGROUND_COLOR": "rgba(255, 255, 255, 0.08)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_SECONDARY_RANK_DISABLED_STATE_DARK_MODE_LABEL_COLOR": "rgba(255, 255, 255, 0.3)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_SECONDARY_RANK_DISABLED_STATE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.05)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_SECONDARY_RANK_DISABLED_STATE_LABEL_COLOR": "rgba(0, 0, 0, 0.3)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_TERTIARY_RANK_NORMAL_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_TERTIARY_RANK_NORMAL_STATE_LIGHT_MODE_LABEL_COLOR": "rgb(0, 90, 217)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_TERTIARY_RANK_NORMAL_STATE_DARK_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_TERTIARY_RANK_NORMAL_STATE_DARK_MODE_LABEL_COLOR": "rgb(74, 149, 255)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_TERTIARY_RANK_NORMAL_STATE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_TERTIARY_RANK_NORMAL_STATE_LABEL_COLOR": "rgb(0, 90, 217)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_TERTIARY_RANK_HOVER_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(230, 240, 255)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_TERTIARY_RANK_HOVER_STATE_LIGHT_MODE_LABEL_COLOR": "rgb(0, 90, 217)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_TERTIARY_RANK_HOVER_STATE_DARK_MODE_BACKGROUND_COLOR": "rgb(0, 21, 51)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_TERTIARY_RANK_HOVER_STATE_DARK_MODE_LABEL_COLOR": "rgb(74, 149, 255)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_TERTIARY_RANK_HOVER_STATE_BACKGROUND_COLOR": "rgb(230, 240, 255)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_TERTIARY_RANK_HOVER_STATE_LABEL_COLOR": "rgb(0, 90, 217)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_TERTIARY_RANK_PRESSED_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(204, 225, 255)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_TERTIARY_RANK_PRESSED_STATE_LIGHT_MODE_LABEL_COLOR": "rgb(0, 90, 217)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_TERTIARY_RANK_PRESSED_STATE_DARK_MODE_BACKGROUND_COLOR": "rgb(0, 37, 89)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_TERTIARY_RANK_PRESSED_STATE_DARK_MODE_LABEL_COLOR": "rgb(74, 149, 255)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_TERTIARY_RANK_PRESSED_STATE_BACKGROUND_COLOR": "rgb(204, 225, 255)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_TERTIARY_RANK_PRESSED_STATE_LABEL_COLOR": "rgb(0, 90, 217)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_TERTIARY_RANK_FOCUS_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_TERTIARY_RANK_FOCUS_STATE_LIGHT_MODE_LABEL_COLOR": "rgb(0, 90, 217)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_TERTIARY_RANK_FOCUS_STATE_DARK_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_TERTIARY_RANK_FOCUS_STATE_DARK_MODE_LABEL_COLOR": "rgb(74, 149, 255)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_TERTIARY_RANK_FOCUS_STATE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_TERTIARY_RANK_FOCUS_STATE_LABEL_COLOR": "rgb(0, 90, 217)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_TERTIARY_RANK_DISABLED_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_TERTIARY_RANK_DISABLED_STATE_LIGHT_MODE_LABEL_COLOR": "rgba(0, 0, 0, 0.3)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_TERTIARY_RANK_DISABLED_STATE_DARK_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_TERTIARY_RANK_DISABLED_STATE_DARK_MODE_LABEL_COLOR": "rgba(255, 255, 255, 0.3)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_TERTIARY_RANK_DISABLED_STATE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "DROPDOWN_BUTTON_NORMAL_VARIANT_TERTIARY_RANK_DISABLED_STATE_LABEL_COLOR": "rgba(0, 0, 0, 0.3)",
  "DROPDOWN_ICON_BUTTON_SMALL_SIZE_MINIMUM_HEIGHT": 40,
  "DROPDOWN_ICON_BUTTON_SMALL_SIZE_WIDTH_MULTIPLIER": 1,
  "DROPDOWN_ICON_BUTTON_SMALL_SIZE_PADDING_SIZE": 8,
  "DROPDOWN_ICON_BUTTON_MEDIUM_SIZE_MINIMUM_HEIGHT": 48,
  "DROPDOWN_ICON_BUTTON_MEDIUM_SIZE_WIDTH_MULTIPLIER": 1,
  "DROPDOWN_ICON_BUTTON_MEDIUM_SIZE_PADDING_SIZE": 12,
  "DROPDOWN_ICON_BUTTON_MEDIUM_SIZE_HORIZONTAL_SPACING_SIZE": 8,
  "DROPDOWN_ICON_BUTTON_LARGE_SIZE_MINIMUM_HEIGHT": 64,
  "DROPDOWN_ICON_BUTTON_LARGE_SIZE_WIDTH_MULTIPLIER": 1,
  "DROPDOWN_ICON_BUTTON_LARGE_SIZE_PADDING_SIZE": 20,
  "DROPDOWN_ICON_BUTTON_BORDER_RADIUS": 6,
  "DROPDOWN_ICON_BUTTON_FOCUS_RING_BUFFER_SIZE": 2,
  "DROPDOWN_ICON_BUTTON_FOCUS_RING_BORDER_SIZE": 2,
  "DROPDOWN_ICON_BUTTON_NORMAL_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.05)",
  "DROPDOWN_ICON_BUTTON_NORMAL_STATE_LIGHT_MODE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "DROPDOWN_ICON_BUTTON_NORMAL_STATE_DARK_MODE_BACKGROUND_COLOR": "rgba(255, 255, 255, 0.08)",
  "DROPDOWN_ICON_BUTTON_NORMAL_STATE_DARK_MODE_ICON_COLOR": "rgba(255, 255, 255, 0.95)",
  "DROPDOWN_ICON_BUTTON_NORMAL_STATE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.05)",
  "DROPDOWN_ICON_BUTTON_NORMAL_STATE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "DROPDOWN_ICON_BUTTON_HOVER_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(230, 240, 255)",
  "DROPDOWN_ICON_BUTTON_HOVER_STATE_LIGHT_MODE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "DROPDOWN_ICON_BUTTON_HOVER_STATE_DARK_MODE_BACKGROUND_COLOR": "rgb(0, 21, 51)",
  "DROPDOWN_ICON_BUTTON_HOVER_STATE_DARK_MODE_ICON_COLOR": "rgba(255, 255, 255, 0.95)",
  "DROPDOWN_ICON_BUTTON_HOVER_STATE_BACKGROUND_COLOR": "rgb(230, 240, 255)",
  "DROPDOWN_ICON_BUTTON_HOVER_STATE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "DROPDOWN_ICON_BUTTON_PRESSED_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(204, 225, 255)",
  "DROPDOWN_ICON_BUTTON_PRESSED_STATE_LIGHT_MODE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "DROPDOWN_ICON_BUTTON_PRESSED_STATE_DARK_MODE_BACKGROUND_COLOR": "rgb(0, 37, 89)",
  "DROPDOWN_ICON_BUTTON_PRESSED_STATE_DARK_MODE_ICON_COLOR": "rgba(255, 255, 255, 0.95)",
  "DROPDOWN_ICON_BUTTON_PRESSED_STATE_BACKGROUND_COLOR": "rgb(204, 225, 255)",
  "DROPDOWN_ICON_BUTTON_PRESSED_STATE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "DROPDOWN_ICON_BUTTON_FOCUS_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(230, 240, 255)",
  "DROPDOWN_ICON_BUTTON_FOCUS_STATE_LIGHT_MODE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "DROPDOWN_ICON_BUTTON_FOCUS_STATE_DARK_MODE_BACKGROUND_COLOR": "rgb(0, 21, 51)",
  "DROPDOWN_ICON_BUTTON_FOCUS_STATE_DARK_MODE_ICON_COLOR": "rgba(255, 255, 255, 0.95)",
  "DROPDOWN_ICON_BUTTON_FOCUS_STATE_BACKGROUND_COLOR": "rgb(230, 240, 255)",
  "DROPDOWN_ICON_BUTTON_FOCUS_STATE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "DROPDOWN_ICON_BUTTON_DISABLED_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.05)",
  "DROPDOWN_ICON_BUTTON_DISABLED_STATE_LIGHT_MODE_ICON_COLOR": "rgba(0, 0, 0, 0.3)",
  "DROPDOWN_ICON_BUTTON_DISABLED_STATE_DARK_MODE_BACKGROUND_COLOR": "rgba(255, 255, 255, 0.08)",
  "DROPDOWN_ICON_BUTTON_DISABLED_STATE_DARK_MODE_ICON_COLOR": "rgba(255, 255, 255, 0.3)",
  "DROPDOWN_ICON_BUTTON_DISABLED_STATE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.05)",
  "DROPDOWN_ICON_BUTTON_DISABLED_STATE_ICON_COLOR": "rgba(0, 0, 0, 0.3)",
  "EMPTY_STATE_HEADING_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "EMPTY_STATE_HEADING_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "EMPTY_STATE_HEADING_TEXT_SIZE": 19,
  "EMPTY_STATE_HEADING_TEXT_LEADING": 26,
  "EMPTY_STATE_HEADING_TEXT_TRACKING": 0,
  "EMPTY_STATE_HEADING_TEXT_WEIGHT": 700,
  "EMPTY_STATE_HEADING_TEXT_CASE": "regular",
  "EMPTY_STATE_HEADING_TEXT_NUMBER_SPACING": "default",
  "EMPTY_STATE_HEADING_TEXT_SCALE": "heading-20",
  "EMPTY_STATE_HEADING_TEXT_FONT_FAMILY": "Square Sans Display",
  "EMPTY_STATE_HEADING_TEXT_IS_HEADER": true,
  "EMPTY_STATE_HEADING_COLOR": "rgba(0, 0, 0, 0.9)",
  "EMPTY_STATE_PARAGRAPH_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "EMPTY_STATE_PARAGRAPH_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "EMPTY_STATE_PARAGRAPH_TEXT_SIZE": 16,
  "EMPTY_STATE_PARAGRAPH_TEXT_LEADING": 24,
  "EMPTY_STATE_PARAGRAPH_TEXT_TRACKING": 0,
  "EMPTY_STATE_PARAGRAPH_TEXT_WEIGHT": 400,
  "EMPTY_STATE_PARAGRAPH_TEXT_CASE": "regular",
  "EMPTY_STATE_PARAGRAPH_TEXT_NUMBER_SPACING": "default",
  "EMPTY_STATE_PARAGRAPH_TEXT_SCALE": "paragraph-30",
  "EMPTY_STATE_PARAGRAPH_TEXT_FONT_FAMILY": "Square Sans Text",
  "EMPTY_STATE_PARAGRAPH_VERTICAL_SPACING": 8,
  "EMPTY_STATE_PARAGRAPH_COLOR": "rgba(0, 0, 0, 0.9)",
  "EMPTY_STATE_BUTTON_GROUP_VERTICAL_SPACING": 32,
  "EMPTY_STATE_HORIZONTAL_PADDING": 32,
  "EMPTY_STATE_VERTICAL_PADDING": 32,
  "EMPTY_STATE_BACKGROUND_LIGHT_MODE_COLOR": "rgb(255, 255, 255)",
  "EMPTY_STATE_BACKGROUND_DARK_MODE_COLOR": "rgb(20, 20, 20)",
  "EMPTY_STATE_BACKGROUND_COLOR": "rgb(255, 255, 255)",
  "EMPTY_STATE_BORDER_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.15)",
  "EMPTY_STATE_BORDER_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.15)",
  "EMPTY_STATE_BORDER_RADIUS": 6,
  "EMPTY_STATE_BORDER_WIDTH": 1,
  "EMPTY_STATE_BORDER_COLOR": "rgba(0, 0, 0, 0.15)",
  "FIELD_BORDER_RADIUS": 6,
  "FIELD_BORDER_SIZE": 1,
  "FIELD_ACTION_TEXT_SIZE": 14,
  "FIELD_ACTION_TEXT_LEADING": 22,
  "FIELD_ACTION_TEXT_TRACKING": 0,
  "FIELD_ACTION_TEXT_WEIGHT": 600,
  "FIELD_ACTION_TEXT_CASE": "regular",
  "FIELD_ACTION_TEXT_NUMBER_SPACING": "default",
  "FIELD_ACTION_TEXT_SCALE": "paragraph-20",
  "FIELD_ACTION_TEXT_FONT_FAMILY": "Square Sans Text",
  "FIELD_ACTION_TEXT_COLOR": "rgb(0, 106, 255)",
  "FIELD_ACTION_VERTICAL_PADDING_SIZE": 8,
  "FIELD_ERROR_MESSAGE_ICON_ASSET": "exclamation-circle",
  "FIELD_ERROR_MESSAGE_ICON_HEIGHT": 24,
  "FIELD_ERROR_MESSAGE_ICON_WIDTH": 24,
  "FIELD_ERROR_MESSAGE_ICON_BUFFER_SIZE": 8,
  "FIELD_ERROR_MESSAGE_ICON_VERTICAL_SPACING": 8,
  "FIELD_ERROR_MESSAGE_ICON_TINTABLE": true,
  "FIELD_ERROR_MESSAGE_TEXT_SIZE": 14,
  "FIELD_ERROR_MESSAGE_TEXT_LEADING": 22,
  "FIELD_ERROR_MESSAGE_TEXT_TRACKING": 0,
  "FIELD_ERROR_MESSAGE_TEXT_WEIGHT": 400,
  "FIELD_ERROR_MESSAGE_TEXT_CASE": "regular",
  "FIELD_ERROR_MESSAGE_TEXT_NUMBER_SPACING": "default",
  "FIELD_ERROR_MESSAGE_TEXT_SCALE": "paragraph-20",
  "FIELD_ERROR_MESSAGE_TEXT_FONT_FAMILY": "Square Sans Text",
  "FIELD_ERROR_MESSAGE_TEXT_VERTICAL_SPACING": 8,
  "FIELD_ERROR_MESSAGE_TEXT_COLOR": "rgb(191, 0, 32)",
  "FIELD_HELPER_TEXT_SIZE": 14,
  "FIELD_HELPER_TEXT_LEADING": 22,
  "FIELD_HELPER_TEXT_TRACKING": 0,
  "FIELD_HELPER_TEXT_WEIGHT": 400,
  "FIELD_HELPER_TEXT_CASE": "regular",
  "FIELD_HELPER_TEXT_NUMBER_SPACING": "default",
  "FIELD_HELPER_TEXT_SCALE": "paragraph-20",
  "FIELD_HELPER_TEXT_FONT_FAMILY": "Square Sans Text",
  "FIELD_HELPER_TEXT_HORIZONTAL_SPACING": 0,
  "FIELD_HELPER_TEXT_VERTICAL_SPACING": 8,
  "FIELD_HELPER_TEXT_TEXT_COLOR": "rgba(0, 0, 0, 0.55)",
  "FIELD_ICON_ASSET": "i-circle",
  "FIELD_ICON_BUFFER_SIZE": 16,
  "FIELD_ICON_HEIGHT": 24,
  "FIELD_ICON_WIDTH": 24,
  "FIELD_ICON_TINTABLE": true,
  "FIELD_IMAGE_ACCESSORY_SPACING_LEFT_SIZE": 12,
  "FIELD_INPUT_SIZE": 16,
  "FIELD_INPUT_LEADING": 24,
  "FIELD_INPUT_TRACKING": 0,
  "FIELD_INPUT_WEIGHT": 400,
  "FIELD_INPUT_CASE": "regular",
  "FIELD_INPUT_NUMBER_SPACING": "default",
  "FIELD_INPUT_SCALE": "paragraph-30",
  "FIELD_INPUT_FONT_FAMILY": "Square Sans Text",
  "FIELD_TRAILING_ACCESSORY_BUFFER_SIZE": 16,
  "FIELD_TRAILING_ACCESSORY_HEIGHT": 48,
  "FIELD_TRAILING_ACCESSORY_WIDTH": 48,
  "FIELD_TRAILING_ACCESSORY_POSITION_BOTTOM_SIZE": 8,
  "FIELD_TRAILING_ACCESSORY_POSITION_RIGHT_SIZE": 0,
  "FIELD_LIGHT_MODE_ACTION_TEXT_COLOR": "rgb(0, 106, 255)",
  "FIELD_LIGHT_MODE_ERROR_MESSAGE_TEXT_COLOR": "rgb(191, 0, 32)",
  "FIELD_LIGHT_MODE_HELPER_TEXT_TEXT_COLOR": "rgba(0, 0, 0, 0.55)",
  "FIELD_LIGHT_MODE_PLACEHOLDER_TEXT_COLOR": "rgba(0, 0, 0, 0.3)",
  "FIELD_DARK_MODE_ACTION_TEXT_COLOR": "rgb(0, 106, 255)",
  "FIELD_DARK_MODE_ERROR_MESSAGE_TEXT_COLOR": "rgb(255, 90, 118)",
  "FIELD_DARK_MODE_HELPER_TEXT_TEXT_COLOR": "rgba(255, 255, 255, 0.55)",
  "FIELD_DARK_MODE_PLACEHOLDER_TEXT_COLOR": "rgba(255, 255, 255, 0.3)",
  "FIELD_EMPTY_PHASE_LABEL_TEXT_SIZE": 16,
  "FIELD_EMPTY_PHASE_LABEL_TEXT_LEADING": 24,
  "FIELD_EMPTY_PHASE_LABEL_TEXT_TRACKING": 0,
  "FIELD_EMPTY_PHASE_LABEL_TEXT_WEIGHT": 400,
  "FIELD_EMPTY_PHASE_LABEL_TEXT_CASE": "regular",
  "FIELD_EMPTY_PHASE_LABEL_TEXT_NUMBER_SPACING": "default",
  "FIELD_EMPTY_PHASE_LABEL_TEXT_SCALE": "paragraph-30",
  "FIELD_EMPTY_PHASE_LABEL_TEXT_FONT_FAMILY": "Square Sans Text",
  "FIELD_EMPTY_PHASE_HORIZONTAL_PADDING_SIZE": 16,
  "FIELD_EMPTY_PHASE_VERTICAL_PADDING_SIZE": 20,
  "FIELD_FLOAT_PHASE_LABEL_TEXT_SIZE": 14,
  "FIELD_FLOAT_PHASE_LABEL_TEXT_LEADING": 22,
  "FIELD_FLOAT_PHASE_LABEL_TEXT_TRACKING": 0,
  "FIELD_FLOAT_PHASE_LABEL_TEXT_WEIGHT": 500,
  "FIELD_FLOAT_PHASE_LABEL_TEXT_CASE": "regular",
  "FIELD_FLOAT_PHASE_LABEL_TEXT_NUMBER_SPACING": "default",
  "FIELD_FLOAT_PHASE_LABEL_TEXT_SCALE": "paragraph-20",
  "FIELD_FLOAT_PHASE_LABEL_TEXT_FONT_FAMILY": "Square Sans Text",
  "FIELD_FLOAT_PHASE_LABEL_MARGIN_TOP_SIZE": 8,
  "FIELD_FLOAT_PHASE_LABEL_MARGIN_BOTTOM_SIZE": 2,
  "FIELD_FLOAT_PHASE_HORIZONTAL_PADDING_SIZE": 16,
  "FIELD_FLOAT_PHASE_VERTICAL_PADDING_SIZE": 8,
  "FIELD_NORMAL_STATE_EMPTY_PHASE_LIGHT_MODE_LABEL_COLOR": "rgba(0, 0, 0, 0.55)",
  "FIELD_NORMAL_STATE_EMPTY_PHASE_DARK_MODE_LABEL_COLOR": "rgba(255, 255, 255, 0.55)",
  "FIELD_NORMAL_STATE_EMPTY_PHASE_LABEL_COLOR": "rgba(0, 0, 0, 0.55)",
  "FIELD_NORMAL_STATE_FLOAT_PHASE_LIGHT_MODE_LABEL_COLOR": "rgba(0, 0, 0, 0.9)",
  "FIELD_NORMAL_STATE_FLOAT_PHASE_DARK_MODE_LABEL_COLOR": "rgba(255, 255, 255, 0.95)",
  "FIELD_NORMAL_STATE_FLOAT_PHASE_LABEL_COLOR": "rgba(0, 0, 0, 0.9)",
  "FIELD_NORMAL_STATE_NORMAL_VALIDITY_LIGHT_MODE_BORDER_COLOR": "rgba(0, 0, 0, 0.15)",
  "FIELD_NORMAL_STATE_NORMAL_VALIDITY_DARK_MODE_BORDER_COLOR": "rgba(255, 255, 255, 0.15)",
  "FIELD_NORMAL_STATE_NORMAL_VALIDITY_BORDER_COLOR": "rgba(0, 0, 0, 0.15)",
  "FIELD_NORMAL_STATE_INVALID_VALIDITY_LIGHT_MODE_BORDER_COLOR": "rgb(204, 0, 35)",
  "FIELD_NORMAL_STATE_INVALID_VALIDITY_DARK_MODE_BORDER_COLOR": "rgb(204, 0, 35)",
  "FIELD_NORMAL_STATE_INVALID_VALIDITY_BORDER_COLOR": "rgb(204, 0, 35)",
  "FIELD_NORMAL_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(255, 255, 255)",
  "FIELD_NORMAL_STATE_LIGHT_MODE_INPUT_COLOR": "rgba(0, 0, 0, 0.9)",
  "FIELD_NORMAL_STATE_DARK_MODE_BACKGROUND_COLOR": "rgb(8, 8, 8)",
  "FIELD_NORMAL_STATE_DARK_MODE_INPUT_COLOR": "rgba(255, 255, 255, 0.95)",
  "FIELD_NORMAL_STATE_BACKGROUND_COLOR": "rgb(255, 255, 255)",
  "FIELD_NORMAL_STATE_INPUT_COLOR": "rgba(0, 0, 0, 0.9)",
  "FIELD_HOVER_STATE_EMPTY_PHASE_LIGHT_MODE_LABEL_COLOR": "rgba(0, 0, 0, 0.55)",
  "FIELD_HOVER_STATE_EMPTY_PHASE_DARK_MODE_LABEL_COLOR": "rgba(255, 255, 255, 0.55)",
  "FIELD_HOVER_STATE_EMPTY_PHASE_LABEL_COLOR": "rgba(0, 0, 0, 0.55)",
  "FIELD_HOVER_STATE_FLOAT_PHASE_LIGHT_MODE_LABEL_COLOR": "rgba(0, 0, 0, 0.9)",
  "FIELD_HOVER_STATE_FLOAT_PHASE_DARK_MODE_LABEL_COLOR": "rgba(255, 255, 255, 0.95)",
  "FIELD_HOVER_STATE_FLOAT_PHASE_LABEL_COLOR": "rgba(0, 0, 0, 0.9)",
  "FIELD_HOVER_STATE_NORMAL_VALIDITY_LIGHT_MODE_BORDER_COLOR": "rgb(0, 106, 255)",
  "FIELD_HOVER_STATE_NORMAL_VALIDITY_DARK_MODE_BORDER_COLOR": "rgb(0, 106, 255)",
  "FIELD_HOVER_STATE_NORMAL_VALIDITY_BORDER_COLOR": "rgb(0, 106, 255)",
  "FIELD_HOVER_STATE_INVALID_VALIDITY_LIGHT_MODE_BORDER_COLOR": "rgb(0, 106, 255)",
  "FIELD_HOVER_STATE_INVALID_VALIDITY_DARK_MODE_BORDER_COLOR": "rgb(0, 106, 255)",
  "FIELD_HOVER_STATE_INVALID_VALIDITY_BORDER_COLOR": "rgb(0, 106, 255)",
  "FIELD_HOVER_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(255, 255, 255)",
  "FIELD_HOVER_STATE_LIGHT_MODE_INPUT_COLOR": "rgba(0, 0, 0, 0.9)",
  "FIELD_HOVER_STATE_DARK_MODE_BACKGROUND_COLOR": "rgb(8, 8, 8)",
  "FIELD_HOVER_STATE_DARK_MODE_INPUT_COLOR": "rgba(255, 255, 255, 0.95)",
  "FIELD_HOVER_STATE_BACKGROUND_COLOR": "rgb(255, 255, 255)",
  "FIELD_HOVER_STATE_INPUT_COLOR": "rgba(0, 0, 0, 0.9)",
  "FIELD_FOCUS_STATE_BORDER_SIZE": 2,
  "FIELD_FOCUS_STATE_FLOAT_PHASE_LIGHT_MODE_LABEL_COLOR": "rgba(0, 0, 0, 0.9)",
  "FIELD_FOCUS_STATE_FLOAT_PHASE_DARK_MODE_LABEL_COLOR": "rgba(255, 255, 255, 0.95)",
  "FIELD_FOCUS_STATE_FLOAT_PHASE_LABEL_COLOR": "rgba(0, 0, 0, 0.9)",
  "FIELD_FOCUS_STATE_NORMAL_VALIDITY_LIGHT_MODE_BORDER_COLOR": "rgb(0, 106, 255)",
  "FIELD_FOCUS_STATE_NORMAL_VALIDITY_DARK_MODE_BORDER_COLOR": "rgb(0, 106, 255)",
  "FIELD_FOCUS_STATE_NORMAL_VALIDITY_BORDER_COLOR": "rgb(0, 106, 255)",
  "FIELD_FOCUS_STATE_INVALID_VALIDITY_LIGHT_MODE_BORDER_COLOR": "rgb(0, 106, 255)",
  "FIELD_FOCUS_STATE_INVALID_VALIDITY_DARK_MODE_BORDER_COLOR": "rgb(0, 106, 255)",
  "FIELD_FOCUS_STATE_INVALID_VALIDITY_BORDER_COLOR": "rgb(0, 106, 255)",
  "FIELD_FOCUS_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(255, 255, 255)",
  "FIELD_FOCUS_STATE_LIGHT_MODE_INPUT_COLOR": "rgba(0, 0, 0, 0.9)",
  "FIELD_FOCUS_STATE_DARK_MODE_BACKGROUND_COLOR": "rgb(8, 8, 8)",
  "FIELD_FOCUS_STATE_DARK_MODE_INPUT_COLOR": "rgba(255, 255, 255, 0.95)",
  "FIELD_FOCUS_STATE_BACKGROUND_COLOR": "rgb(255, 255, 255)",
  "FIELD_FOCUS_STATE_INPUT_COLOR": "rgba(0, 0, 0, 0.9)",
  "FIELD_DISABLED_STATE_EMPTY_PHASE_LIGHT_MODE_LABEL_COLOR": "rgba(0, 0, 0, 0.3)",
  "FIELD_DISABLED_STATE_EMPTY_PHASE_DARK_MODE_LABEL_COLOR": "rgba(255, 255, 255, 0.3)",
  "FIELD_DISABLED_STATE_EMPTY_PHASE_LABEL_COLOR": "rgba(0, 0, 0, 0.3)",
  "FIELD_DISABLED_STATE_FLOAT_PHASE_LIGHT_MODE_LABEL_COLOR": "rgba(0, 0, 0, 0.3)",
  "FIELD_DISABLED_STATE_FLOAT_PHASE_DARK_MODE_LABEL_COLOR": "rgba(255, 255, 255, 0.3)",
  "FIELD_DISABLED_STATE_FLOAT_PHASE_LABEL_COLOR": "rgba(0, 0, 0, 0.3)",
  "FIELD_DISABLED_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.05)",
  "FIELD_DISABLED_STATE_LIGHT_MODE_BORDER_COLOR": "rgba(0, 0, 0, 0.05)",
  "FIELD_DISABLED_STATE_LIGHT_MODE_INPUT_COLOR": "rgba(0, 0, 0, 0.3)",
  "FIELD_DISABLED_STATE_DARK_MODE_BACKGROUND_COLOR": "rgba(255, 255, 255, 0.08)",
  "FIELD_DISABLED_STATE_DARK_MODE_BORDER_COLOR": "rgba(255, 255, 255, 0.08)",
  "FIELD_DISABLED_STATE_DARK_MODE_INPUT_COLOR": "rgba(255, 255, 255, 0.3)",
  "FIELD_DISABLED_STATE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.05)",
  "FIELD_DISABLED_STATE_BORDER_COLOR": "rgba(0, 0, 0, 0.05)",
  "FIELD_DISABLED_STATE_INPUT_COLOR": "rgba(0, 0, 0, 0.3)",
  "FIELD_PLACEHOLDER_TEXT_COLOR": "rgba(0, 0, 0, 0.3)",
  "FILTER_BUTTON_BORDER_RADIUS": 6,
  "FILTER_BUTTON_BORDER_SIZE": 1,
  "FILTER_BUTTON_FEEDBACK_MAXIMUM": 99,
  "FILTER_BUTTON_LABEL_CONTENT_SPACING": 8,
  "FILTER_BUTTON_ICON_FEEDBACK_TRAILING_PADDING": 2,
  "FILTER_BUTTON_ICON_CONTENT_SPACING": 4,
  "FILTER_BUTTON_SMALL_SIZE_MINIMUM_HEIGHT": 40,
  "FILTER_BUTTON_SMALL_SIZE_MINIMUM_WIDTH_MULTIPLIER": 1,
  "FILTER_BUTTON_SMALL_SIZE_LABEL_TEXT_SIZE": 14,
  "FILTER_BUTTON_SMALL_SIZE_LABEL_TEXT_LEADING": 22,
  "FILTER_BUTTON_SMALL_SIZE_LABEL_TEXT_TRACKING": 0,
  "FILTER_BUTTON_SMALL_SIZE_LABEL_TEXT_WEIGHT": 400,
  "FILTER_BUTTON_SMALL_SIZE_LABEL_TEXT_CASE": "regular",
  "FILTER_BUTTON_SMALL_SIZE_LABEL_TEXT_NUMBER_SPACING": "default",
  "FILTER_BUTTON_SMALL_SIZE_LABEL_TEXT_SCALE": "paragraph-20",
  "FILTER_BUTTON_SMALL_SIZE_LABEL_TEXT_FONT_FAMILY": "Square Sans Text",
  "FILTER_BUTTON_SMALL_SIZE_LABEL_HORIZONTAL_PADDING": 12,
  "FILTER_BUTTON_SMALL_SIZE_LABEL_VERTICAL_PADDING": 9,
  "FILTER_BUTTON_SMALL_SIZE_FEEDBACK_TEXT_SIZE": 14,
  "FILTER_BUTTON_SMALL_SIZE_FEEDBACK_TEXT_LEADING": 22,
  "FILTER_BUTTON_SMALL_SIZE_FEEDBACK_TEXT_TRACKING": 0,
  "FILTER_BUTTON_SMALL_SIZE_FEEDBACK_TEXT_WEIGHT": 600,
  "FILTER_BUTTON_SMALL_SIZE_FEEDBACK_TEXT_CASE": "regular",
  "FILTER_BUTTON_SMALL_SIZE_FEEDBACK_TEXT_NUMBER_SPACING": "default",
  "FILTER_BUTTON_SMALL_SIZE_FEEDBACK_TEXT_SCALE": "paragraph-20",
  "FILTER_BUTTON_SMALL_SIZE_FEEDBACK_TEXT_FONT_FAMILY": "Square Sans Text",
  "FILTER_BUTTON_SMALL_SIZE_ICON_TEXT_SIZE": 16,
  "FILTER_BUTTON_SMALL_SIZE_ICON_TEXT_LEADING": 24,
  "FILTER_BUTTON_SMALL_SIZE_ICON_TEXT_TRACKING": 0,
  "FILTER_BUTTON_SMALL_SIZE_ICON_TEXT_WEIGHT": 600,
  "FILTER_BUTTON_SMALL_SIZE_ICON_TEXT_CASE": "regular",
  "FILTER_BUTTON_SMALL_SIZE_ICON_TEXT_NUMBER_SPACING": "default",
  "FILTER_BUTTON_SMALL_SIZE_ICON_TEXT_SCALE": "paragraph-30",
  "FILTER_BUTTON_SMALL_SIZE_ICON_TEXT_FONT_FAMILY": "Square Sans Text",
  "FILTER_BUTTON_SMALL_SIZE_ICON_HORIZONTAL_PADDING": 8,
  "FILTER_BUTTON_SMALL_SIZE_ICON_VERTICAL_PADDING": 8,
  "FILTER_BUTTON_MEDIUM_SIZE_MINIMUM_HEIGHT": 48,
  "FILTER_BUTTON_MEDIUM_SIZE_MINIMUM_WIDTH_MULTIPLIER": 1,
  "FILTER_BUTTON_MEDIUM_SIZE_LABEL_TEXT_SIZE": 16,
  "FILTER_BUTTON_MEDIUM_SIZE_LABEL_TEXT_LEADING": 24,
  "FILTER_BUTTON_MEDIUM_SIZE_LABEL_TEXT_TRACKING": 0,
  "FILTER_BUTTON_MEDIUM_SIZE_LABEL_TEXT_WEIGHT": 400,
  "FILTER_BUTTON_MEDIUM_SIZE_LABEL_TEXT_CASE": "regular",
  "FILTER_BUTTON_MEDIUM_SIZE_LABEL_TEXT_NUMBER_SPACING": "default",
  "FILTER_BUTTON_MEDIUM_SIZE_LABEL_TEXT_SCALE": "paragraph-30",
  "FILTER_BUTTON_MEDIUM_SIZE_LABEL_TEXT_FONT_FAMILY": "Square Sans Text",
  "FILTER_BUTTON_MEDIUM_SIZE_LABEL_HORIZONTAL_PADDING": 16,
  "FILTER_BUTTON_MEDIUM_SIZE_LABEL_VERTICAL_PADDING": 12,
  "FILTER_BUTTON_MEDIUM_SIZE_FEEDBACK_TEXT_SIZE": 16,
  "FILTER_BUTTON_MEDIUM_SIZE_FEEDBACK_TEXT_LEADING": 24,
  "FILTER_BUTTON_MEDIUM_SIZE_FEEDBACK_TEXT_TRACKING": 0,
  "FILTER_BUTTON_MEDIUM_SIZE_FEEDBACK_TEXT_WEIGHT": 600,
  "FILTER_BUTTON_MEDIUM_SIZE_FEEDBACK_TEXT_CASE": "regular",
  "FILTER_BUTTON_MEDIUM_SIZE_FEEDBACK_TEXT_NUMBER_SPACING": "default",
  "FILTER_BUTTON_MEDIUM_SIZE_FEEDBACK_TEXT_SCALE": "paragraph-30",
  "FILTER_BUTTON_MEDIUM_SIZE_FEEDBACK_TEXT_FONT_FAMILY": "Square Sans Text",
  "FILTER_BUTTON_MEDIUM_SIZE_ICON_TEXT_SIZE": 16,
  "FILTER_BUTTON_MEDIUM_SIZE_ICON_TEXT_LEADING": 24,
  "FILTER_BUTTON_MEDIUM_SIZE_ICON_TEXT_TRACKING": 0,
  "FILTER_BUTTON_MEDIUM_SIZE_ICON_TEXT_WEIGHT": 600,
  "FILTER_BUTTON_MEDIUM_SIZE_ICON_TEXT_CASE": "regular",
  "FILTER_BUTTON_MEDIUM_SIZE_ICON_TEXT_NUMBER_SPACING": "default",
  "FILTER_BUTTON_MEDIUM_SIZE_ICON_TEXT_SCALE": "paragraph-30",
  "FILTER_BUTTON_MEDIUM_SIZE_ICON_TEXT_FONT_FAMILY": "Square Sans Text",
  "FILTER_BUTTON_MEDIUM_SIZE_ICON_HORIZONTAL_PADDING": 12,
  "FILTER_BUTTON_MEDIUM_SIZE_ICON_VERTICAL_PADDING": 12,
  "FILTER_BUTTON_NORMAL_STATE_BACKGROUND_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0)",
  "FILTER_BUTTON_NORMAL_STATE_BACKGROUND_DARK_MODE_COLOR": "rgba(0, 0, 0, 0)",
  "FILTER_BUTTON_NORMAL_STATE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "FILTER_BUTTON_NORMAL_STATE_LABEL_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.55)",
  "FILTER_BUTTON_NORMAL_STATE_LABEL_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.55)",
  "FILTER_BUTTON_NORMAL_STATE_LABEL_COLOR": "rgba(0, 0, 0, 0.55)",
  "FILTER_BUTTON_NORMAL_STATE_FEEDBACK_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "FILTER_BUTTON_NORMAL_STATE_FEEDBACK_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "FILTER_BUTTON_NORMAL_STATE_FEEDBACK_COLOR": "rgba(0, 0, 0, 0.9)",
  "FILTER_BUTTON_NORMAL_STATE_BORDER_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.15)",
  "FILTER_BUTTON_NORMAL_STATE_BORDER_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.15)",
  "FILTER_BUTTON_NORMAL_STATE_BORDER_COLOR": "rgba(0, 0, 0, 0.15)",
  "FILTER_BUTTON_HOVER_STATE_BACKGROUND_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.05)",
  "FILTER_BUTTON_HOVER_STATE_BACKGROUND_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.08)",
  "FILTER_BUTTON_HOVER_STATE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.05)",
  "FILTER_BUTTON_HOVER_STATE_LABEL_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.55)",
  "FILTER_BUTTON_HOVER_STATE_LABEL_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.55)",
  "FILTER_BUTTON_HOVER_STATE_LABEL_COLOR": "rgba(0, 0, 0, 0.55)",
  "FILTER_BUTTON_HOVER_STATE_FEEDBACK_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "FILTER_BUTTON_HOVER_STATE_FEEDBACK_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "FILTER_BUTTON_HOVER_STATE_FEEDBACK_COLOR": "rgba(0, 0, 0, 0.9)",
  "FILTER_BUTTON_HOVER_STATE_BORDER_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.15)",
  "FILTER_BUTTON_HOVER_STATE_BORDER_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.15)",
  "FILTER_BUTTON_HOVER_STATE_BORDER_COLOR": "rgba(0, 0, 0, 0.15)",
  "FILTER_BUTTON_PRESSED_STATE_BACKGROUND_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0)",
  "FILTER_BUTTON_PRESSED_STATE_BACKGROUND_DARK_MODE_COLOR": "rgba(0, 0, 0, 0)",
  "FILTER_BUTTON_PRESSED_STATE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "FILTER_BUTTON_PRESSED_STATE_LABEL_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.55)",
  "FILTER_BUTTON_PRESSED_STATE_LABEL_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.55)",
  "FILTER_BUTTON_PRESSED_STATE_LABEL_COLOR": "rgba(0, 0, 0, 0.55)",
  "FILTER_BUTTON_PRESSED_STATE_FEEDBACK_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "FILTER_BUTTON_PRESSED_STATE_FEEDBACK_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "FILTER_BUTTON_PRESSED_STATE_FEEDBACK_COLOR": "rgba(0, 0, 0, 0.9)",
  "FILTER_BUTTON_PRESSED_STATE_BORDER_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "FILTER_BUTTON_PRESSED_STATE_BORDER_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "FILTER_BUTTON_PRESSED_STATE_BORDER_COLOR": "rgba(0, 0, 0, 0.9)",
  "FILTER_BUTTON_DISABLED_STATE_BACKGROUND_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0)",
  "FILTER_BUTTON_DISABLED_STATE_BACKGROUND_DARK_MODE_COLOR": "rgba(0, 0, 0, 0)",
  "FILTER_BUTTON_DISABLED_STATE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "FILTER_BUTTON_DISABLED_STATE_LABEL_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.3)",
  "FILTER_BUTTON_DISABLED_STATE_LABEL_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.3)",
  "FILTER_BUTTON_DISABLED_STATE_LABEL_COLOR": "rgba(0, 0, 0, 0.3)",
  "FILTER_BUTTON_DISABLED_STATE_FEEDBACK_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.3)",
  "FILTER_BUTTON_DISABLED_STATE_FEEDBACK_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.3)",
  "FILTER_BUTTON_DISABLED_STATE_FEEDBACK_COLOR": "rgba(0, 0, 0, 0.3)",
  "FILTER_BUTTON_DISABLED_STATE_BORDER_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.15)",
  "FILTER_BUTTON_DISABLED_STATE_BORDER_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.15)",
  "FILTER_BUTTON_DISABLED_STATE_BORDER_COLOR": "rgba(0, 0, 0, 0.15)",
  "FOOTER_VERTICAL_PADDING_SIZE": 16,
  "HARDWARE_SLIDER_MINIMUM_WIDTH": 48,
  "HARDWARE_SLIDER_MINIMUM_HEIGHT": 48,
  "HARDWARE_SLIDER_TRACK_ACTIVE_HEIGHT": 40,
  "HARDWARE_SLIDER_TRACK_ACTIVE_CORNER_RADIUS": 40,
  "HARDWARE_SLIDER_TRACK_ACTIVE_PADDING_HORIZONTAL_SIZE": 8,
  "HARDWARE_SLIDER_TRACK_ACTIVE_NORMAL_STATE_BACKGROUND_LIGHT_MODE_COLOR": "rgb(0, 106, 255)",
  "HARDWARE_SLIDER_TRACK_ACTIVE_NORMAL_STATE_BACKGROUND_DARK_MODE_COLOR": "rgb(0, 106, 255)",
  "HARDWARE_SLIDER_TRACK_ACTIVE_NORMAL_STATE_BACKGROUND_COLOR": "rgb(0, 106, 255)",
  "HARDWARE_SLIDER_TRACK_ACTIVE_DISABLED_STATE_BACKGROUND_LIGHT_MODE_COLOR": "rgb(204, 225, 255)",
  "HARDWARE_SLIDER_TRACK_ACTIVE_DISABLED_STATE_BACKGROUND_DARK_MODE_COLOR": "rgb(0, 37, 89)",
  "HARDWARE_SLIDER_TRACK_ACTIVE_DISABLED_STATE_BACKGROUND_COLOR": "rgb(204, 225, 255)",
  "HARDWARE_SLIDER_TRACK_INACTIVE_HEIGHT": 16,
  "HARDWARE_SLIDER_TRACK_INACTIVE_CORNER_RADIUS": 16,
  "HARDWARE_SLIDER_TRACK_INACTIVE_NORMAL_STATE_BACKGROUND_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.15)",
  "HARDWARE_SLIDER_TRACK_INACTIVE_NORMAL_STATE_BACKGROUND_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.15)",
  "HARDWARE_SLIDER_TRACK_INACTIVE_NORMAL_STATE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.15)",
  "HARDWARE_SLIDER_TRACK_INACTIVE_DISABLED_STATE_BACKGROUND_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.05)",
  "HARDWARE_SLIDER_TRACK_INACTIVE_DISABLED_STATE_BACKGROUND_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.08)",
  "HARDWARE_SLIDER_TRACK_INACTIVE_DISABLED_STATE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.05)",
  "HARDWARE_SLIDER_ICON_SIZE": 24,
  "HARDWARE_SLIDER_ICON_TINT_LIGHT_MODE_COLOR": "rgb(255, 255, 255)",
  "HARDWARE_SLIDER_ICON_TINT_DARK_MODE_COLOR": "rgb(255, 255, 255)",
  "HARDWARE_SLIDER_ICON_TINT_COLOR": "rgb(255, 255, 255)",
  "HEADER_TOP_PADDING_COMPACT_SIZE_CLASS_SIZE": 16,
  "HEADER_TOP_PADDING_NORMAL_SIZE_CLASS_SIZE": 24,
  "HEADER_TOP_PADDING_WIDE_SIZE_CLASS_SIZE": 24,
  "HEADER_TOP_PADDING_SIZE": 24,
  "HEADER_BOTTOM_PADDING_COMPACT_SIZE_CLASS_SIZE": 16,
  "HEADER_BOTTOM_PADDING_NORMAL_SIZE_CLASS_SIZE": 16,
  "HEADER_BOTTOM_PADDING_WIDE_SIZE_CLASS_SIZE": 16,
  "HEADER_BOTTOM_PADDING_SIZE": 16,
  "HEADER_HORIZONTAL_PADDING_COMPACT_SIZE_CLASS_SIZE": 16,
  "HEADER_HORIZONTAL_PADDING_NORMAL_SIZE_CLASS_SIZE": 24,
  "HEADER_HORIZONTAL_PADDING_WIDE_SIZE_CLASS_SIZE": 24,
  "HEADER_HORIZONTAL_PADDING_SIZE": 16,
  "HEADER_NAV_ICON_BUTTON_SPACING_BUTTOM_SIZE": 24,
  "HEADER_NAV_ICON_BUTTON_SPACING_BOTTOM_SIZE": 24,
  "HEADER_CLOSE_BUTTON_ICON_ASSET": "x",
  "HEADER_CLOSE_BUTTON_ICON_WIDTH": 24,
  "HEADER_CLOSE_BUTTON_ICON_HEIGHT": 24,
  "HEADER_CLOSE_BUTTON_ICON_TINTABLE": true,
  "HEADER_MAIN_TEXT_HORIZONTAL_SPACING": 16,
  "HEADER_MAIN_TEXT_MINIMUM_WIDTH": 64,
  "HEADER_MAIN_TEXT_TRANSITION_DURATION": 160,
  "HEADER_MAIN_TEXT_NORMAL_PHASE_SPACING_TOP_SIZE": 8,
  "HEADER_MAIN_TEXT_LIGHT_MODE_TEXT_COLOR": "rgba(0, 0, 0, 0.9)",
  "HEADER_MAIN_TEXT_DARK_MODE_TEXT_COLOR": "rgba(255, 255, 255, 0.95)",
  "HEADER_MAIN_TEXT_TEXT_COLOR": "rgba(0, 0, 0, 0.9)",
  "HEADER_SUB_TEXT_SPACING_BOTTOM_SIZE": 8,
  "HEADER_SUB_TEXT_LIGHT_MODE_TEXT_COLOR": "rgba(0, 0, 0, 0.55)",
  "HEADER_SUB_TEXT_DARK_MODE_TEXT_COLOR": "rgba(255, 255, 255, 0.55)",
  "HEADER_SUB_TEXT_TEXT_COLOR": "rgba(0, 0, 0, 0.55)",
  "HEADER_DIVIDER_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.05)",
  "HEADER_DIVIDER_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.08)",
  "HEADER_DIVIDER_SIZE": 1,
  "HEADER_DIVIDER_COLOR": "rgba(0, 0, 0, 0.05)",
  "ICON_BUTTON_SMALL_SIZE_MINIMUM_HEIGHT": 40,
  "ICON_BUTTON_SMALL_SIZE_WIDTH_MULTIPLIER": 1,
  "ICON_BUTTON_SMALL_SIZE_PADDING_SIZE": 8,
  "ICON_BUTTON_MEDIUM_SIZE_MINIMUM_HEIGHT": 48,
  "ICON_BUTTON_MEDIUM_SIZE_WIDTH_MULTIPLIER": 1,
  "ICON_BUTTON_MEDIUM_SIZE_PADDING_SIZE": 12,
  "ICON_BUTTON_MEDIUM_SIZE_HORIZONTAL_SPACING_SIZE": 8,
  "ICON_BUTTON_LARGE_SIZE_MINIMUM_HEIGHT": 64,
  "ICON_BUTTON_LARGE_SIZE_WIDTH_MULTIPLIER": 1,
  "ICON_BUTTON_LARGE_SIZE_PADDING_SIZE": 20,
  "ICON_BUTTON_NORMAL_SIZE_MINIMUM_WIDTH": 48,
  "ICON_BUTTON_NORMAL_SIZE_MINIMUM_HEIGHT": 48,
  "ICON_BUTTON_NORMAL_SIZE_PADDING_SIZE": 16,
  "ICON_BUTTON_COMPACT_SIZE_MINIMUM_WIDTH": 40,
  "ICON_BUTTON_COMPACT_SIZE_MINIMUM_HEIGHT": 40,
  "ICON_BUTTON_COMPACT_SIZE_MINIMUM_PADDING_SIZE": 12,
  "ICON_BUTTON_COMPACT_SIZE_PADDING_SIZE": 12,
  "ICON_BUTTON_MINIMUM_WIDTH": 48,
  "ICON_BUTTON_MINIMUM_HEIGHT": 48,
  "ICON_BUTTON_PADDING_SIZE": 16,
  "ICON_BUTTON_FOCUS_RING_BUFFER_SIZE": 2,
  "ICON_BUTTON_FOCUS_RING_BORDER_SIZE": 2,
  "ICON_BUTTON_BORDER_RADIUS": 6,
  "ICON_BUTTON_PRIMARY_RANK_NORMAL_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(0, 106, 255)",
  "ICON_BUTTON_PRIMARY_RANK_NORMAL_STATE_LIGHT_MODE_ICON_COLOR": "rgb(255, 255, 255)",
  "ICON_BUTTON_PRIMARY_RANK_NORMAL_STATE_DARK_MODE_BACKGROUND_COLOR": "rgb(0, 106, 255)",
  "ICON_BUTTON_PRIMARY_RANK_NORMAL_STATE_DARK_MODE_ICON_COLOR": "rgb(255, 255, 255)",
  "ICON_BUTTON_PRIMARY_RANK_NORMAL_STATE_BACKGROUND_COLOR": "rgb(0, 106, 255)",
  "ICON_BUTTON_PRIMARY_RANK_NORMAL_STATE_ICON_COLOR": "rgb(255, 255, 255)",
  "ICON_BUTTON_PRIMARY_RANK_HOVER_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(0, 95, 229)",
  "ICON_BUTTON_PRIMARY_RANK_HOVER_STATE_LIGHT_MODE_ICON_COLOR": "rgb(255, 255, 255)",
  "ICON_BUTTON_PRIMARY_RANK_HOVER_STATE_DARK_MODE_BACKGROUND_COLOR": "rgb(0, 95, 229)",
  "ICON_BUTTON_PRIMARY_RANK_HOVER_STATE_DARK_MODE_ICON_COLOR": "rgb(255, 255, 255)",
  "ICON_BUTTON_PRIMARY_RANK_HOVER_STATE_BACKGROUND_COLOR": "rgb(0, 95, 229)",
  "ICON_BUTTON_PRIMARY_RANK_HOVER_STATE_ICON_COLOR": "rgb(255, 255, 255)",
  "ICON_BUTTON_PRIMARY_RANK_PRESSED_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(0, 85, 204)",
  "ICON_BUTTON_PRIMARY_RANK_PRESSED_STATE_LIGHT_MODE_ICON_COLOR": "rgb(255, 255, 255)",
  "ICON_BUTTON_PRIMARY_RANK_PRESSED_STATE_DARK_MODE_BACKGROUND_COLOR": "rgb(0, 85, 204)",
  "ICON_BUTTON_PRIMARY_RANK_PRESSED_STATE_DARK_MODE_ICON_COLOR": "rgb(255, 255, 255)",
  "ICON_BUTTON_PRIMARY_RANK_PRESSED_STATE_BACKGROUND_COLOR": "rgb(0, 85, 204)",
  "ICON_BUTTON_PRIMARY_RANK_PRESSED_STATE_ICON_COLOR": "rgb(255, 255, 255)",
  "ICON_BUTTON_PRIMARY_RANK_FOCUS_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(0, 95, 229)",
  "ICON_BUTTON_PRIMARY_RANK_FOCUS_STATE_LIGHT_MODE_ICON_COLOR": "rgb(255, 255, 255)",
  "ICON_BUTTON_PRIMARY_RANK_FOCUS_STATE_DARK_MODE_BACKGROUND_COLOR": "rgb(0, 95, 229)",
  "ICON_BUTTON_PRIMARY_RANK_FOCUS_STATE_DARK_MODE_ICON_COLOR": "rgb(255, 255, 255)",
  "ICON_BUTTON_PRIMARY_RANK_FOCUS_STATE_BACKGROUND_COLOR": "rgb(0, 95, 229)",
  "ICON_BUTTON_PRIMARY_RANK_FOCUS_STATE_ICON_COLOR": "rgb(255, 255, 255)",
  "ICON_BUTTON_PRIMARY_RANK_DISABLED_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.05)",
  "ICON_BUTTON_PRIMARY_RANK_DISABLED_STATE_LIGHT_MODE_ICON_COLOR": "rgba(0, 0, 0, 0.3)",
  "ICON_BUTTON_PRIMARY_RANK_DISABLED_STATE_DARK_MODE_BACKGROUND_COLOR": "rgba(255, 255, 255, 0.08)",
  "ICON_BUTTON_PRIMARY_RANK_DISABLED_STATE_DARK_MODE_ICON_COLOR": "rgba(255, 255, 255, 0.3)",
  "ICON_BUTTON_PRIMARY_RANK_DISABLED_STATE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.05)",
  "ICON_BUTTON_PRIMARY_RANK_DISABLED_STATE_ICON_COLOR": "rgba(0, 0, 0, 0.3)",
  "ICON_BUTTON_SECONDARY_RANK_NORMAL_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.05)",
  "ICON_BUTTON_SECONDARY_RANK_NORMAL_STATE_LIGHT_MODE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "ICON_BUTTON_SECONDARY_RANK_NORMAL_STATE_DARK_MODE_BACKGROUND_COLOR": "rgba(255, 255, 255, 0.08)",
  "ICON_BUTTON_SECONDARY_RANK_NORMAL_STATE_DARK_MODE_ICON_COLOR": "rgba(255, 255, 255, 0.95)",
  "ICON_BUTTON_SECONDARY_RANK_NORMAL_STATE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.05)",
  "ICON_BUTTON_SECONDARY_RANK_NORMAL_STATE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "ICON_BUTTON_SECONDARY_RANK_HOVER_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(230, 240, 255)",
  "ICON_BUTTON_SECONDARY_RANK_HOVER_STATE_LIGHT_MODE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "ICON_BUTTON_SECONDARY_RANK_HOVER_STATE_DARK_MODE_BACKGROUND_COLOR": "rgb(0, 21, 51)",
  "ICON_BUTTON_SECONDARY_RANK_HOVER_STATE_DARK_MODE_ICON_COLOR": "rgba(255, 255, 255, 0.95)",
  "ICON_BUTTON_SECONDARY_RANK_HOVER_STATE_BACKGROUND_COLOR": "rgb(230, 240, 255)",
  "ICON_BUTTON_SECONDARY_RANK_HOVER_STATE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "ICON_BUTTON_SECONDARY_RANK_PRESSED_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(204, 225, 255)",
  "ICON_BUTTON_SECONDARY_RANK_PRESSED_STATE_LIGHT_MODE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "ICON_BUTTON_SECONDARY_RANK_PRESSED_STATE_DARK_MODE_BACKGROUND_COLOR": "rgb(0, 37, 89)",
  "ICON_BUTTON_SECONDARY_RANK_PRESSED_STATE_DARK_MODE_ICON_COLOR": "rgba(255, 255, 255, 0.95)",
  "ICON_BUTTON_SECONDARY_RANK_PRESSED_STATE_BACKGROUND_COLOR": "rgb(204, 225, 255)",
  "ICON_BUTTON_SECONDARY_RANK_PRESSED_STATE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "ICON_BUTTON_SECONDARY_RANK_FOCUS_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(230, 240, 255)",
  "ICON_BUTTON_SECONDARY_RANK_FOCUS_STATE_LIGHT_MODE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "ICON_BUTTON_SECONDARY_RANK_FOCUS_STATE_DARK_MODE_BACKGROUND_COLOR": "rgb(0, 21, 51)",
  "ICON_BUTTON_SECONDARY_RANK_FOCUS_STATE_DARK_MODE_ICON_COLOR": "rgba(255, 255, 255, 0.95)",
  "ICON_BUTTON_SECONDARY_RANK_FOCUS_STATE_BACKGROUND_COLOR": "rgb(230, 240, 255)",
  "ICON_BUTTON_SECONDARY_RANK_FOCUS_STATE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "ICON_BUTTON_SECONDARY_RANK_DISABLED_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.05)",
  "ICON_BUTTON_SECONDARY_RANK_DISABLED_STATE_LIGHT_MODE_ICON_COLOR": "rgba(0, 0, 0, 0.3)",
  "ICON_BUTTON_SECONDARY_RANK_DISABLED_STATE_DARK_MODE_BACKGROUND_COLOR": "rgba(255, 255, 255, 0.08)",
  "ICON_BUTTON_SECONDARY_RANK_DISABLED_STATE_DARK_MODE_ICON_COLOR": "rgba(255, 255, 255, 0.3)",
  "ICON_BUTTON_SECONDARY_RANK_DISABLED_STATE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.05)",
  "ICON_BUTTON_SECONDARY_RANK_DISABLED_STATE_ICON_COLOR": "rgba(0, 0, 0, 0.3)",
  "ICON_BUTTON_TERTIARY_RANK_NORMAL_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "ICON_BUTTON_TERTIARY_RANK_NORMAL_STATE_LIGHT_MODE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "ICON_BUTTON_TERTIARY_RANK_NORMAL_STATE_DARK_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "ICON_BUTTON_TERTIARY_RANK_NORMAL_STATE_DARK_MODE_ICON_COLOR": "rgba(255, 255, 255, 0.95)",
  "ICON_BUTTON_TERTIARY_RANK_NORMAL_STATE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "ICON_BUTTON_TERTIARY_RANK_NORMAL_STATE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "ICON_BUTTON_TERTIARY_RANK_HOVER_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(230, 240, 255)",
  "ICON_BUTTON_TERTIARY_RANK_HOVER_STATE_LIGHT_MODE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "ICON_BUTTON_TERTIARY_RANK_HOVER_STATE_DARK_MODE_BACKGROUND_COLOR": "rgb(0, 21, 51)",
  "ICON_BUTTON_TERTIARY_RANK_HOVER_STATE_DARK_MODE_ICON_COLOR": "rgba(255, 255, 255, 0.95)",
  "ICON_BUTTON_TERTIARY_RANK_HOVER_STATE_BACKGROUND_COLOR": "rgb(230, 240, 255)",
  "ICON_BUTTON_TERTIARY_RANK_HOVER_STATE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "ICON_BUTTON_TERTIARY_RANK_PRESSED_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(204, 225, 255)",
  "ICON_BUTTON_TERTIARY_RANK_PRESSED_STATE_LIGHT_MODE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "ICON_BUTTON_TERTIARY_RANK_PRESSED_STATE_DARK_MODE_BACKGROUND_COLOR": "rgb(0, 37, 89)",
  "ICON_BUTTON_TERTIARY_RANK_PRESSED_STATE_DARK_MODE_ICON_COLOR": "rgba(255, 255, 255, 0.95)",
  "ICON_BUTTON_TERTIARY_RANK_PRESSED_STATE_BACKGROUND_COLOR": "rgb(204, 225, 255)",
  "ICON_BUTTON_TERTIARY_RANK_PRESSED_STATE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "ICON_BUTTON_TERTIARY_RANK_FOCUS_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(230, 240, 255)",
  "ICON_BUTTON_TERTIARY_RANK_FOCUS_STATE_LIGHT_MODE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "ICON_BUTTON_TERTIARY_RANK_FOCUS_STATE_DARK_MODE_BACKGROUND_COLOR": "rgb(0, 21, 51)",
  "ICON_BUTTON_TERTIARY_RANK_FOCUS_STATE_DARK_MODE_ICON_COLOR": "rgba(255, 255, 255, 0.95)",
  "ICON_BUTTON_TERTIARY_RANK_FOCUS_STATE_BACKGROUND_COLOR": "rgb(230, 240, 255)",
  "ICON_BUTTON_TERTIARY_RANK_FOCUS_STATE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "ICON_BUTTON_TERTIARY_RANK_DISABLED_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "ICON_BUTTON_TERTIARY_RANK_DISABLED_STATE_LIGHT_MODE_ICON_COLOR": "rgba(0, 0, 0, 0.3)",
  "ICON_BUTTON_TERTIARY_RANK_DISABLED_STATE_DARK_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "ICON_BUTTON_TERTIARY_RANK_DISABLED_STATE_DARK_MODE_ICON_COLOR": "rgba(255, 255, 255, 0.3)",
  "ICON_BUTTON_TERTIARY_RANK_DISABLED_STATE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "ICON_BUTTON_TERTIARY_RANK_DISABLED_STATE_ICON_COLOR": "rgba(0, 0, 0, 0.3)",
  "ICON_BUTTON_NORMAL_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.05)",
  "ICON_BUTTON_NORMAL_STATE_LIGHT_MODE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "ICON_BUTTON_NORMAL_STATE_DARK_MODE_BACKGROUND_COLOR": "rgba(255, 255, 255, 0.08)",
  "ICON_BUTTON_NORMAL_STATE_DARK_MODE_ICON_COLOR": "rgba(255, 255, 255, 0.95)",
  "ICON_BUTTON_NORMAL_STATE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.05)",
  "ICON_BUTTON_NORMAL_STATE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "ICON_BUTTON_HOVER_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(230, 240, 255)",
  "ICON_BUTTON_HOVER_STATE_LIGHT_MODE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "ICON_BUTTON_HOVER_STATE_DARK_MODE_BACKGROUND_COLOR": "rgb(0, 21, 51)",
  "ICON_BUTTON_HOVER_STATE_DARK_MODE_ICON_COLOR": "rgba(255, 255, 255, 0.95)",
  "ICON_BUTTON_HOVER_STATE_BACKGROUND_COLOR": "rgb(230, 240, 255)",
  "ICON_BUTTON_HOVER_STATE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "ICON_BUTTON_PRESSED_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(204, 225, 255)",
  "ICON_BUTTON_PRESSED_STATE_LIGHT_MODE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "ICON_BUTTON_PRESSED_STATE_DARK_MODE_BACKGROUND_COLOR": "rgb(0, 37, 89)",
  "ICON_BUTTON_PRESSED_STATE_DARK_MODE_ICON_COLOR": "rgba(255, 255, 255, 0.95)",
  "ICON_BUTTON_PRESSED_STATE_BACKGROUND_COLOR": "rgb(204, 225, 255)",
  "ICON_BUTTON_PRESSED_STATE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "ICON_BUTTON_FOCUS_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(230, 240, 255)",
  "ICON_BUTTON_FOCUS_STATE_LIGHT_MODE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "ICON_BUTTON_FOCUS_STATE_DARK_MODE_BACKGROUND_COLOR": "rgb(0, 21, 51)",
  "ICON_BUTTON_FOCUS_STATE_DARK_MODE_ICON_COLOR": "rgba(255, 255, 255, 0.95)",
  "ICON_BUTTON_FOCUS_STATE_BACKGROUND_COLOR": "rgb(230, 240, 255)",
  "ICON_BUTTON_FOCUS_STATE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "ICON_BUTTON_DISABLED_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.05)",
  "ICON_BUTTON_DISABLED_STATE_LIGHT_MODE_ICON_COLOR": "rgba(0, 0, 0, 0.3)",
  "ICON_BUTTON_DISABLED_STATE_DARK_MODE_BACKGROUND_COLOR": "rgba(255, 255, 255, 0.08)",
  "ICON_BUTTON_DISABLED_STATE_DARK_MODE_ICON_COLOR": "rgba(255, 255, 255, 0.3)",
  "ICON_BUTTON_DISABLED_STATE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.05)",
  "ICON_BUTTON_DISABLED_STATE_ICON_COLOR": "rgba(0, 0, 0, 0.3)",
  "ICON_BUTTON_NORMAL_VARIANT_PRIMARY_RANK_NORMAL_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(0, 106, 255)",
  "ICON_BUTTON_NORMAL_VARIANT_PRIMARY_RANK_NORMAL_STATE_LIGHT_MODE_ICON_COLOR": "rgb(255, 255, 255)",
  "ICON_BUTTON_NORMAL_VARIANT_PRIMARY_RANK_NORMAL_STATE_DARK_MODE_BACKGROUND_COLOR": "rgb(0, 106, 255)",
  "ICON_BUTTON_NORMAL_VARIANT_PRIMARY_RANK_NORMAL_STATE_DARK_MODE_ICON_COLOR": "rgb(255, 255, 255)",
  "ICON_BUTTON_NORMAL_VARIANT_PRIMARY_RANK_NORMAL_STATE_BACKGROUND_COLOR": "rgb(0, 106, 255)",
  "ICON_BUTTON_NORMAL_VARIANT_PRIMARY_RANK_NORMAL_STATE_ICON_COLOR": "rgb(255, 255, 255)",
  "ICON_BUTTON_NORMAL_VARIANT_PRIMARY_RANK_HOVER_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(0, 95, 229)",
  "ICON_BUTTON_NORMAL_VARIANT_PRIMARY_RANK_HOVER_STATE_LIGHT_MODE_ICON_COLOR": "rgb(255, 255, 255)",
  "ICON_BUTTON_NORMAL_VARIANT_PRIMARY_RANK_HOVER_STATE_DARK_MODE_BACKGROUND_COLOR": "rgb(0, 95, 229)",
  "ICON_BUTTON_NORMAL_VARIANT_PRIMARY_RANK_HOVER_STATE_DARK_MODE_ICON_COLOR": "rgb(255, 255, 255)",
  "ICON_BUTTON_NORMAL_VARIANT_PRIMARY_RANK_HOVER_STATE_BACKGROUND_COLOR": "rgb(0, 95, 229)",
  "ICON_BUTTON_NORMAL_VARIANT_PRIMARY_RANK_HOVER_STATE_ICON_COLOR": "rgb(255, 255, 255)",
  "ICON_BUTTON_NORMAL_VARIANT_PRIMARY_RANK_PRESSED_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(0, 85, 204)",
  "ICON_BUTTON_NORMAL_VARIANT_PRIMARY_RANK_PRESSED_STATE_LIGHT_MODE_ICON_COLOR": "rgb(255, 255, 255)",
  "ICON_BUTTON_NORMAL_VARIANT_PRIMARY_RANK_PRESSED_STATE_DARK_MODE_BACKGROUND_COLOR": "rgb(0, 85, 204)",
  "ICON_BUTTON_NORMAL_VARIANT_PRIMARY_RANK_PRESSED_STATE_DARK_MODE_ICON_COLOR": "rgb(255, 255, 255)",
  "ICON_BUTTON_NORMAL_VARIANT_PRIMARY_RANK_PRESSED_STATE_BACKGROUND_COLOR": "rgb(0, 85, 204)",
  "ICON_BUTTON_NORMAL_VARIANT_PRIMARY_RANK_PRESSED_STATE_ICON_COLOR": "rgb(255, 255, 255)",
  "ICON_BUTTON_NORMAL_VARIANT_PRIMARY_RANK_FOCUS_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(0, 106, 255)",
  "ICON_BUTTON_NORMAL_VARIANT_PRIMARY_RANK_FOCUS_STATE_LIGHT_MODE_ICON_COLOR": "rgb(255, 255, 255)",
  "ICON_BUTTON_NORMAL_VARIANT_PRIMARY_RANK_FOCUS_STATE_DARK_MODE_BACKGROUND_COLOR": "rgb(0, 106, 255)",
  "ICON_BUTTON_NORMAL_VARIANT_PRIMARY_RANK_FOCUS_STATE_DARK_MODE_ICON_COLOR": "rgb(255, 255, 255)",
  "ICON_BUTTON_NORMAL_VARIANT_PRIMARY_RANK_FOCUS_STATE_BACKGROUND_COLOR": "rgb(0, 106, 255)",
  "ICON_BUTTON_NORMAL_VARIANT_PRIMARY_RANK_FOCUS_STATE_ICON_COLOR": "rgb(255, 255, 255)",
  "ICON_BUTTON_NORMAL_VARIANT_PRIMARY_RANK_DISABLED_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.05)",
  "ICON_BUTTON_NORMAL_VARIANT_PRIMARY_RANK_DISABLED_STATE_LIGHT_MODE_ICON_COLOR": "rgba(0, 0, 0, 0.3)",
  "ICON_BUTTON_NORMAL_VARIANT_PRIMARY_RANK_DISABLED_STATE_DARK_MODE_BACKGROUND_COLOR": "rgba(255, 255, 255, 0.08)",
  "ICON_BUTTON_NORMAL_VARIANT_PRIMARY_RANK_DISABLED_STATE_DARK_MODE_ICON_COLOR": "rgba(255, 255, 255, 0.3)",
  "ICON_BUTTON_NORMAL_VARIANT_PRIMARY_RANK_DISABLED_STATE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.05)",
  "ICON_BUTTON_NORMAL_VARIANT_PRIMARY_RANK_DISABLED_STATE_ICON_COLOR": "rgba(0, 0, 0, 0.3)",
  "ICON_BUTTON_NORMAL_VARIANT_SECONDARY_RANK_NORMAL_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.05)",
  "ICON_BUTTON_NORMAL_VARIANT_SECONDARY_RANK_NORMAL_STATE_LIGHT_MODE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "ICON_BUTTON_NORMAL_VARIANT_SECONDARY_RANK_NORMAL_STATE_DARK_MODE_BACKGROUND_COLOR": "rgba(255, 255, 255, 0.08)",
  "ICON_BUTTON_NORMAL_VARIANT_SECONDARY_RANK_NORMAL_STATE_DARK_MODE_ICON_COLOR": "rgba(255, 255, 255, 0.95)",
  "ICON_BUTTON_NORMAL_VARIANT_SECONDARY_RANK_NORMAL_STATE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.05)",
  "ICON_BUTTON_NORMAL_VARIANT_SECONDARY_RANK_NORMAL_STATE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "ICON_BUTTON_NORMAL_VARIANT_SECONDARY_RANK_HOVER_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(230, 240, 255)",
  "ICON_BUTTON_NORMAL_VARIANT_SECONDARY_RANK_HOVER_STATE_LIGHT_MODE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "ICON_BUTTON_NORMAL_VARIANT_SECONDARY_RANK_HOVER_STATE_DARK_MODE_BACKGROUND_COLOR": "rgb(0, 21, 51)",
  "ICON_BUTTON_NORMAL_VARIANT_SECONDARY_RANK_HOVER_STATE_DARK_MODE_ICON_COLOR": "rgba(255, 255, 255, 0.95)",
  "ICON_BUTTON_NORMAL_VARIANT_SECONDARY_RANK_HOVER_STATE_BACKGROUND_COLOR": "rgb(230, 240, 255)",
  "ICON_BUTTON_NORMAL_VARIANT_SECONDARY_RANK_HOVER_STATE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "ICON_BUTTON_NORMAL_VARIANT_SECONDARY_RANK_PRESSED_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(204, 225, 255)",
  "ICON_BUTTON_NORMAL_VARIANT_SECONDARY_RANK_PRESSED_STATE_LIGHT_MODE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "ICON_BUTTON_NORMAL_VARIANT_SECONDARY_RANK_PRESSED_STATE_DARK_MODE_BACKGROUND_COLOR": "rgb(0, 37, 89)",
  "ICON_BUTTON_NORMAL_VARIANT_SECONDARY_RANK_PRESSED_STATE_DARK_MODE_ICON_COLOR": "rgba(255, 255, 255, 0.95)",
  "ICON_BUTTON_NORMAL_VARIANT_SECONDARY_RANK_PRESSED_STATE_BACKGROUND_COLOR": "rgb(204, 225, 255)",
  "ICON_BUTTON_NORMAL_VARIANT_SECONDARY_RANK_PRESSED_STATE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "ICON_BUTTON_NORMAL_VARIANT_SECONDARY_RANK_DISABLED_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.05)",
  "ICON_BUTTON_NORMAL_VARIANT_SECONDARY_RANK_DISABLED_STATE_LIGHT_MODE_ICON_COLOR": "rgba(0, 0, 0, 0.3)",
  "ICON_BUTTON_NORMAL_VARIANT_SECONDARY_RANK_DISABLED_STATE_DARK_MODE_BACKGROUND_COLOR": "rgba(255, 255, 255, 0.08)",
  "ICON_BUTTON_NORMAL_VARIANT_SECONDARY_RANK_DISABLED_STATE_DARK_MODE_ICON_COLOR": "rgba(255, 255, 255, 0.3)",
  "ICON_BUTTON_NORMAL_VARIANT_SECONDARY_RANK_DISABLED_STATE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.05)",
  "ICON_BUTTON_NORMAL_VARIANT_SECONDARY_RANK_DISABLED_STATE_ICON_COLOR": "rgba(0, 0, 0, 0.3)",
  "ICON_BUTTON_DESTRUCTIVE_VARIANT_PRIMARY_RANK_NORMAL_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(204, 0, 35)",
  "ICON_BUTTON_DESTRUCTIVE_VARIANT_PRIMARY_RANK_NORMAL_STATE_LIGHT_MODE_ICON_COLOR": "rgb(255, 255, 255)",
  "ICON_BUTTON_DESTRUCTIVE_VARIANT_PRIMARY_RANK_NORMAL_STATE_DARK_MODE_BACKGROUND_COLOR": "rgb(204, 0, 35)",
  "ICON_BUTTON_DESTRUCTIVE_VARIANT_PRIMARY_RANK_NORMAL_STATE_DARK_MODE_ICON_COLOR": "rgb(255, 255, 255)",
  "ICON_BUTTON_DESTRUCTIVE_VARIANT_PRIMARY_RANK_NORMAL_STATE_BACKGROUND_COLOR": "rgb(204, 0, 35)",
  "ICON_BUTTON_DESTRUCTIVE_VARIANT_PRIMARY_RANK_NORMAL_STATE_ICON_COLOR": "rgb(255, 255, 255)",
  "ICON_BUTTON_DESTRUCTIVE_VARIANT_PRIMARY_RANK_HOVER_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(178, 0, 30)",
  "ICON_BUTTON_DESTRUCTIVE_VARIANT_PRIMARY_RANK_HOVER_STATE_LIGHT_MODE_ICON_COLOR": "rgb(255, 255, 255)",
  "ICON_BUTTON_DESTRUCTIVE_VARIANT_PRIMARY_RANK_HOVER_STATE_DARK_MODE_BACKGROUND_COLOR": "rgb(178, 0, 30)",
  "ICON_BUTTON_DESTRUCTIVE_VARIANT_PRIMARY_RANK_HOVER_STATE_DARK_MODE_ICON_COLOR": "rgb(255, 255, 255)",
  "ICON_BUTTON_DESTRUCTIVE_VARIANT_PRIMARY_RANK_HOVER_STATE_BACKGROUND_COLOR": "rgb(178, 0, 30)",
  "ICON_BUTTON_DESTRUCTIVE_VARIANT_PRIMARY_RANK_HOVER_STATE_ICON_COLOR": "rgb(255, 255, 255)",
  "ICON_BUTTON_DESTRUCTIVE_VARIANT_PRIMARY_RANK_PRESSED_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(153, 0, 26)",
  "ICON_BUTTON_DESTRUCTIVE_VARIANT_PRIMARY_RANK_PRESSED_STATE_LIGHT_MODE_ICON_COLOR": "rgb(255, 255, 255)",
  "ICON_BUTTON_DESTRUCTIVE_VARIANT_PRIMARY_RANK_PRESSED_STATE_DARK_MODE_BACKGROUND_COLOR": "rgb(153, 0, 26)",
  "ICON_BUTTON_DESTRUCTIVE_VARIANT_PRIMARY_RANK_PRESSED_STATE_DARK_MODE_ICON_COLOR": "rgb(255, 255, 255)",
  "ICON_BUTTON_DESTRUCTIVE_VARIANT_PRIMARY_RANK_PRESSED_STATE_BACKGROUND_COLOR": "rgb(153, 0, 26)",
  "ICON_BUTTON_DESTRUCTIVE_VARIANT_PRIMARY_RANK_PRESSED_STATE_ICON_COLOR": "rgb(255, 255, 255)",
  "ICON_BUTTON_DESTRUCTIVE_VARIANT_PRIMARY_RANK_DISABLED_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.05)",
  "ICON_BUTTON_DESTRUCTIVE_VARIANT_PRIMARY_RANK_DISABLED_STATE_LIGHT_MODE_ICON_COLOR": "rgba(0, 0, 0, 0.3)",
  "ICON_BUTTON_DESTRUCTIVE_VARIANT_PRIMARY_RANK_DISABLED_STATE_DARK_MODE_BACKGROUND_COLOR": "rgba(255, 255, 255, 0.08)",
  "ICON_BUTTON_DESTRUCTIVE_VARIANT_PRIMARY_RANK_DISABLED_STATE_DARK_MODE_ICON_COLOR": "rgba(255, 255, 255, 0.3)",
  "ICON_BUTTON_DESTRUCTIVE_VARIANT_PRIMARY_RANK_DISABLED_STATE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.05)",
  "ICON_BUTTON_DESTRUCTIVE_VARIANT_PRIMARY_RANK_DISABLED_STATE_ICON_COLOR": "rgba(0, 0, 0, 0.3)",
  "ICON_BUTTON_DESTRUCTIVE_VARIANT_SECONDARY_RANK_NORMAL_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.05)",
  "ICON_BUTTON_DESTRUCTIVE_VARIANT_SECONDARY_RANK_NORMAL_STATE_LIGHT_MODE_ICON_COLOR": "rgb(191, 0, 32)",
  "ICON_BUTTON_DESTRUCTIVE_VARIANT_SECONDARY_RANK_NORMAL_STATE_DARK_MODE_BACKGROUND_COLOR": "rgba(255, 255, 255, 0.08)",
  "ICON_BUTTON_DESTRUCTIVE_VARIANT_SECONDARY_RANK_NORMAL_STATE_DARK_MODE_ICON_COLOR": "rgb(255, 90, 118)",
  "ICON_BUTTON_DESTRUCTIVE_VARIANT_SECONDARY_RANK_NORMAL_STATE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.05)",
  "ICON_BUTTON_DESTRUCTIVE_VARIANT_SECONDARY_RANK_NORMAL_STATE_ICON_COLOR": "rgb(191, 0, 32)",
  "ICON_BUTTON_DESTRUCTIVE_VARIANT_SECONDARY_RANK_HOVER_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(255, 229, 234)",
  "ICON_BUTTON_DESTRUCTIVE_VARIANT_SECONDARY_RANK_HOVER_STATE_LIGHT_MODE_ICON_COLOR": "rgb(191, 0, 32)",
  "ICON_BUTTON_DESTRUCTIVE_VARIANT_SECONDARY_RANK_HOVER_STATE_DARK_MODE_BACKGROUND_COLOR": "rgb(51, 0, 9)",
  "ICON_BUTTON_DESTRUCTIVE_VARIANT_SECONDARY_RANK_HOVER_STATE_DARK_MODE_ICON_COLOR": "rgb(255, 90, 118)",
  "ICON_BUTTON_DESTRUCTIVE_VARIANT_SECONDARY_RANK_HOVER_STATE_BACKGROUND_COLOR": "rgb(255, 229, 234)",
  "ICON_BUTTON_DESTRUCTIVE_VARIANT_SECONDARY_RANK_HOVER_STATE_ICON_COLOR": "rgb(191, 0, 32)",
  "ICON_BUTTON_DESTRUCTIVE_VARIANT_SECONDARY_RANK_PRESSED_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(255, 204, 213)",
  "ICON_BUTTON_DESTRUCTIVE_VARIANT_SECONDARY_RANK_PRESSED_STATE_LIGHT_MODE_ICON_COLOR": "rgb(191, 0, 32)",
  "ICON_BUTTON_DESTRUCTIVE_VARIANT_SECONDARY_RANK_PRESSED_STATE_DARK_MODE_BACKGROUND_COLOR": "rgb(89, 0, 15)",
  "ICON_BUTTON_DESTRUCTIVE_VARIANT_SECONDARY_RANK_PRESSED_STATE_DARK_MODE_ICON_COLOR": "rgb(255, 90, 118)",
  "ICON_BUTTON_DESTRUCTIVE_VARIANT_SECONDARY_RANK_PRESSED_STATE_BACKGROUND_COLOR": "rgb(255, 204, 213)",
  "ICON_BUTTON_DESTRUCTIVE_VARIANT_SECONDARY_RANK_PRESSED_STATE_ICON_COLOR": "rgb(191, 0, 32)",
  "ICON_BUTTON_DESTRUCTIVE_VARIANT_SECONDARY_RANK_DISABLED_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.05)",
  "ICON_BUTTON_DESTRUCTIVE_VARIANT_SECONDARY_RANK_DISABLED_STATE_LIGHT_MODE_ICON_COLOR": "rgba(0, 0, 0, 0.3)",
  "ICON_BUTTON_DESTRUCTIVE_VARIANT_SECONDARY_RANK_DISABLED_STATE_DARK_MODE_BACKGROUND_COLOR": "rgba(255, 255, 255, 0.08)",
  "ICON_BUTTON_DESTRUCTIVE_VARIANT_SECONDARY_RANK_DISABLED_STATE_DARK_MODE_ICON_COLOR": "rgba(255, 255, 255, 0.3)",
  "ICON_BUTTON_DESTRUCTIVE_VARIANT_SECONDARY_RANK_DISABLED_STATE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.05)",
  "ICON_BUTTON_DESTRUCTIVE_VARIANT_SECONDARY_RANK_DISABLED_STATE_ICON_COLOR": "rgba(0, 0, 0, 0.3)",
  "ICON_BUTTON_DESTRUCTIVE_VARIANT_TERTIARY_RANK_NORMAL_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "ICON_BUTTON_DESTRUCTIVE_VARIANT_TERTIARY_RANK_NORMAL_STATE_LIGHT_MODE_ICON_COLOR": "rgb(191, 0, 32)",
  "ICON_BUTTON_DESTRUCTIVE_VARIANT_TERTIARY_RANK_NORMAL_STATE_DARK_MODE_BACKGROUND_COLOR": "rgba(255, 255, 255, 0.08)",
  "ICON_BUTTON_DESTRUCTIVE_VARIANT_TERTIARY_RANK_NORMAL_STATE_DARK_MODE_ICON_COLOR": "rgb(255, 90, 118)",
  "ICON_BUTTON_DESTRUCTIVE_VARIANT_TERTIARY_RANK_NORMAL_STATE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "ICON_BUTTON_DESTRUCTIVE_VARIANT_TERTIARY_RANK_NORMAL_STATE_ICON_COLOR": "rgb(191, 0, 32)",
  "ICON_BUTTON_DESTRUCTIVE_VARIANT_TERTIARY_RANK_HOVER_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(255, 229, 234)",
  "ICON_BUTTON_DESTRUCTIVE_VARIANT_TERTIARY_RANK_HOVER_STATE_LIGHT_MODE_ICON_COLOR": "rgb(191, 0, 32)",
  "ICON_BUTTON_DESTRUCTIVE_VARIANT_TERTIARY_RANK_HOVER_STATE_DARK_MODE_BACKGROUND_COLOR": "rgb(51, 0, 9)",
  "ICON_BUTTON_DESTRUCTIVE_VARIANT_TERTIARY_RANK_HOVER_STATE_DARK_MODE_ICON_COLOR": "rgb(255, 90, 118)",
  "ICON_BUTTON_DESTRUCTIVE_VARIANT_TERTIARY_RANK_HOVER_STATE_BACKGROUND_COLOR": "rgb(255, 229, 234)",
  "ICON_BUTTON_DESTRUCTIVE_VARIANT_TERTIARY_RANK_HOVER_STATE_ICON_COLOR": "rgb(191, 0, 32)",
  "ICON_BUTTON_DESTRUCTIVE_VARIANT_TERTIARY_RANK_PRESSED_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(255, 204, 213)",
  "ICON_BUTTON_DESTRUCTIVE_VARIANT_TERTIARY_RANK_PRESSED_STATE_LIGHT_MODE_ICON_COLOR": "rgb(191, 0, 32)",
  "ICON_BUTTON_DESTRUCTIVE_VARIANT_TERTIARY_RANK_PRESSED_STATE_DARK_MODE_BACKGROUND_COLOR": "rgb(89, 0, 15)",
  "ICON_BUTTON_DESTRUCTIVE_VARIANT_TERTIARY_RANK_PRESSED_STATE_DARK_MODE_ICON_COLOR": "rgb(255, 90, 118)",
  "ICON_BUTTON_DESTRUCTIVE_VARIANT_TERTIARY_RANK_PRESSED_STATE_BACKGROUND_COLOR": "rgb(255, 204, 213)",
  "ICON_BUTTON_DESTRUCTIVE_VARIANT_TERTIARY_RANK_PRESSED_STATE_ICON_COLOR": "rgb(191, 0, 32)",
  "ICON_BUTTON_DESTRUCTIVE_VARIANT_TERTIARY_RANK_DISABLED_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "ICON_BUTTON_DESTRUCTIVE_VARIANT_TERTIARY_RANK_DISABLED_STATE_LIGHT_MODE_ICON_COLOR": "rgba(0, 0, 0, 0.3)",
  "ICON_BUTTON_DESTRUCTIVE_VARIANT_TERTIARY_RANK_DISABLED_STATE_DARK_MODE_BACKGROUND_COLOR": "rgba(255, 255, 255, 0.08)",
  "ICON_BUTTON_DESTRUCTIVE_VARIANT_TERTIARY_RANK_DISABLED_STATE_DARK_MODE_ICON_COLOR": "rgba(255, 255, 255, 0.3)",
  "ICON_BUTTON_DESTRUCTIVE_VARIANT_TERTIARY_RANK_DISABLED_STATE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "ICON_BUTTON_DESTRUCTIVE_VARIANT_TERTIARY_RANK_DISABLED_STATE_ICON_COLOR": "rgba(0, 0, 0, 0.3)",
  "INLINE_SECTION_HEADER_10_HEADING_SIZE": 14,
  "INLINE_SECTION_HEADER_10_HEADING_LEADING": 22,
  "INLINE_SECTION_HEADER_10_HEADING_TRACKING": 0,
  "INLINE_SECTION_HEADER_10_HEADING_WEIGHT": 700,
  "INLINE_SECTION_HEADER_10_HEADING_CASE": "regular",
  "INLINE_SECTION_HEADER_10_HEADING_NUMBER_SPACING": "default",
  "INLINE_SECTION_HEADER_10_HEADING_SCALE": "heading-10",
  "INLINE_SECTION_HEADER_10_HEADING_FONT_FAMILY": "Square Sans Text",
  "INLINE_SECTION_HEADER_10_HEADING_IS_HEADER": true,
  "INLINE_SECTION_HEADER_10_HEADING_VERTICAL_PADDING": 9,
  "INLINE_SECTION_HEADER_10_PARAGRAPH_SIZE": 16,
  "INLINE_SECTION_HEADER_10_PARAGRAPH_LEADING": 24,
  "INLINE_SECTION_HEADER_10_PARAGRAPH_TRACKING": 0,
  "INLINE_SECTION_HEADER_10_PARAGRAPH_WEIGHT": 400,
  "INLINE_SECTION_HEADER_10_PARAGRAPH_CASE": "regular",
  "INLINE_SECTION_HEADER_10_PARAGRAPH_NUMBER_SPACING": "default",
  "INLINE_SECTION_HEADER_10_PARAGRAPH_SCALE": "paragraph-30",
  "INLINE_SECTION_HEADER_10_PARAGRAPH_FONT_FAMILY": "Square Sans Text",
  "INLINE_SECTION_HEADER_10_PARAGRAPH_TOP_PADDING": 0,
  "INLINE_SECTION_HEADER_10_TEXT_LINK_TEXT_SIZE": 14,
  "INLINE_SECTION_HEADER_10_TEXT_LINK_TEXT_LEADING": 22,
  "INLINE_SECTION_HEADER_10_TEXT_LINK_TEXT_TRACKING": 0,
  "INLINE_SECTION_HEADER_10_TEXT_LINK_TEXT_WEIGHT": 600,
  "INLINE_SECTION_HEADER_10_TEXT_LINK_TEXT_CASE": "regular",
  "INLINE_SECTION_HEADER_10_TEXT_LINK_TEXT_NUMBER_SPACING": "default",
  "INLINE_SECTION_HEADER_10_TEXT_LINK_TEXT_SCALE": "paragraph-20",
  "INLINE_SECTION_HEADER_10_TEXT_LINK_TEXT_FONT_FAMILY": "Square Sans Text",
  "INLINE_SECTION_HEADER_10_TEXT_LINK_VERTICAL_PADDING": 0,
  "INLINE_SECTION_HEADER_20_HEADING_SIZE": 19,
  "INLINE_SECTION_HEADER_20_HEADING_LEADING": 26,
  "INLINE_SECTION_HEADER_20_HEADING_TRACKING": 0,
  "INLINE_SECTION_HEADER_20_HEADING_WEIGHT": 700,
  "INLINE_SECTION_HEADER_20_HEADING_CASE": "regular",
  "INLINE_SECTION_HEADER_20_HEADING_NUMBER_SPACING": "default",
  "INLINE_SECTION_HEADER_20_HEADING_SCALE": "heading-20",
  "INLINE_SECTION_HEADER_20_HEADING_FONT_FAMILY": "Square Sans Display",
  "INLINE_SECTION_HEADER_20_HEADING_IS_HEADER": true,
  "INLINE_SECTION_HEADER_20_HEADING_VERTICAL_PADDING": 7,
  "INLINE_SECTION_HEADER_20_PARAGRAPH_SIZE": 16,
  "INLINE_SECTION_HEADER_20_PARAGRAPH_LEADING": 24,
  "INLINE_SECTION_HEADER_20_PARAGRAPH_TRACKING": 0,
  "INLINE_SECTION_HEADER_20_PARAGRAPH_WEIGHT": 400,
  "INLINE_SECTION_HEADER_20_PARAGRAPH_CASE": "regular",
  "INLINE_SECTION_HEADER_20_PARAGRAPH_NUMBER_SPACING": "default",
  "INLINE_SECTION_HEADER_20_PARAGRAPH_SCALE": "paragraph-30",
  "INLINE_SECTION_HEADER_20_PARAGRAPH_FONT_FAMILY": "Square Sans Text",
  "INLINE_SECTION_HEADER_20_PARAGRAPH_TOP_PADDING": 5,
  "INLINE_SECTION_HEADER_20_TEXT_LINK_TEXT_SIZE": 16,
  "INLINE_SECTION_HEADER_20_TEXT_LINK_TEXT_LEADING": 24,
  "INLINE_SECTION_HEADER_20_TEXT_LINK_TEXT_TRACKING": 0,
  "INLINE_SECTION_HEADER_20_TEXT_LINK_TEXT_WEIGHT": 600,
  "INLINE_SECTION_HEADER_20_TEXT_LINK_TEXT_CASE": "regular",
  "INLINE_SECTION_HEADER_20_TEXT_LINK_TEXT_NUMBER_SPACING": "default",
  "INLINE_SECTION_HEADER_20_TEXT_LINK_TEXT_SCALE": "paragraph-30",
  "INLINE_SECTION_HEADER_20_TEXT_LINK_TEXT_FONT_FAMILY": "Square Sans Text",
  "INLINE_SECTION_HEADER_20_TEXT_LINK_VERTICAL_PADDING": 0,
  "INLINE_SECTION_HEADER_30_HEADING_SIZE": 25,
  "INLINE_SECTION_HEADER_30_HEADING_LEADING": 32,
  "INLINE_SECTION_HEADER_30_HEADING_TRACKING": 0,
  "INLINE_SECTION_HEADER_30_HEADING_WEIGHT": 700,
  "INLINE_SECTION_HEADER_30_HEADING_CASE": "regular",
  "INLINE_SECTION_HEADER_30_HEADING_NUMBER_SPACING": "default",
  "INLINE_SECTION_HEADER_30_HEADING_SCALE": "heading-30",
  "INLINE_SECTION_HEADER_30_HEADING_FONT_FAMILY": "Square Sans Display",
  "INLINE_SECTION_HEADER_30_HEADING_IS_HEADER": true,
  "INLINE_SECTION_HEADER_30_HEADING_VERTICAL_PADDING": 4,
  "INLINE_SECTION_HEADER_30_PARAGRAPH_SIZE": 16,
  "INLINE_SECTION_HEADER_30_PARAGRAPH_LEADING": 24,
  "INLINE_SECTION_HEADER_30_PARAGRAPH_TRACKING": 0,
  "INLINE_SECTION_HEADER_30_PARAGRAPH_WEIGHT": 400,
  "INLINE_SECTION_HEADER_30_PARAGRAPH_CASE": "regular",
  "INLINE_SECTION_HEADER_30_PARAGRAPH_NUMBER_SPACING": "default",
  "INLINE_SECTION_HEADER_30_PARAGRAPH_SCALE": "paragraph-30",
  "INLINE_SECTION_HEADER_30_PARAGRAPH_FONT_FAMILY": "Square Sans Text",
  "INLINE_SECTION_HEADER_30_PARAGRAPH_TOP_PADDING": 12,
  "INLINE_SECTION_HEADER_30_TEXT_LINK_TEXT_SIZE": 16,
  "INLINE_SECTION_HEADER_30_TEXT_LINK_TEXT_LEADING": 24,
  "INLINE_SECTION_HEADER_30_TEXT_LINK_TEXT_TRACKING": 0,
  "INLINE_SECTION_HEADER_30_TEXT_LINK_TEXT_WEIGHT": 600,
  "INLINE_SECTION_HEADER_30_TEXT_LINK_TEXT_CASE": "regular",
  "INLINE_SECTION_HEADER_30_TEXT_LINK_TEXT_NUMBER_SPACING": "default",
  "INLINE_SECTION_HEADER_30_TEXT_LINK_TEXT_SCALE": "paragraph-30",
  "INLINE_SECTION_HEADER_30_TEXT_LINK_TEXT_FONT_FAMILY": "Square Sans Text",
  "INLINE_SECTION_HEADER_30_TEXT_LINK_VERTICAL_PADDING": 0,
  "INLINE_SECTION_HEADER_HEADING_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "INLINE_SECTION_HEADER_HEADING_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "INLINE_SECTION_HEADER_HEADING_COLOR": "rgba(0, 0, 0, 0.9)",
  "INLINE_SECTION_HEADER_PARAGRAPH_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "INLINE_SECTION_HEADER_PARAGRAPH_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "INLINE_SECTION_HEADER_PARAGRAPH_COLOR": "rgba(0, 0, 0, 0.9)",
  "INLINE_SECTION_HEADER_TEXT_LINK_LIGHT_MODE_COLOR": "rgb(0, 90, 217)",
  "INLINE_SECTION_HEADER_TEXT_LINK_DARK_MODE_COLOR": "rgb(74, 149, 255)",
  "INLINE_SECTION_HEADER_TEXT_LINK_NORMAL_STATE_OPACITY": 1,
  "INLINE_SECTION_HEADER_TEXT_LINK_HOVER_STATE_OPACITY": 0.6,
  "INLINE_SECTION_HEADER_TEXT_LINK_PRESSED_STATE_OPACITY": 0.6,
  "INLINE_SECTION_HEADER_TEXT_LINK_DISABLED_STATE_OPACITY": 0.3,
  "INLINE_SECTION_HEADER_TEXT_LINK_COLOR": "rgb(0, 90, 217)",
  "INLINE_SECTION_HEADER_HORIZONTAL_SPACING": 8,
  "INLINE_STATUS_NORMAL_SIZE_TEXT_SIZE": 14,
  "INLINE_STATUS_NORMAL_SIZE_TEXT_LEADING": 22,
  "INLINE_STATUS_NORMAL_SIZE_TEXT_TRACKING": 0,
  "INLINE_STATUS_NORMAL_SIZE_TEXT_WEIGHT": 400,
  "INLINE_STATUS_NORMAL_SIZE_TEXT_CASE": "regular",
  "INLINE_STATUS_NORMAL_SIZE_TEXT_NUMBER_SPACING": "default",
  "INLINE_STATUS_NORMAL_SIZE_TEXT_SCALE": "paragraph-20",
  "INLINE_STATUS_NORMAL_SIZE_TEXT_FONT_FAMILY": "Square Sans Text",
  "INLINE_STATUS_NORMAL_SIZE_SPACING_HORIZONTAL": 4,
  "INLINE_STATUS_NORMAL_SIZE_ICON_SIZE": 20,
  "INLINE_STATUS_SUCCESS_VARIANT_TEXT_LIGHT_MODE_COLOR": "rgb(0, 125, 42)",
  "INLINE_STATUS_SUCCESS_VARIANT_TEXT_DARK_MODE_COLOR": "rgb(22, 217, 86)",
  "INLINE_STATUS_SUCCESS_VARIANT_TEXT_COLOR": "rgb(0, 125, 42)",
  "INLINE_STATUS_SUCCESS_VARIANT_ICON_LIGHT_MODE_COLOR": "rgb(0, 125, 42)",
  "INLINE_STATUS_SUCCESS_VARIANT_ICON_DARK_MODE_COLOR": "rgb(22, 217, 86)",
  "INLINE_STATUS_SUCCESS_VARIANT_ICON_COLOR": "rgb(0, 125, 42)",
  "INLINE_STATUS_WARNING_VARIANT_TEXT_LIGHT_MODE_COLOR": "rgb(148, 92, 37)",
  "INLINE_STATUS_WARNING_VARIANT_TEXT_DARK_MODE_COLOR": "rgb(255, 159, 64)",
  "INLINE_STATUS_WARNING_VARIANT_TEXT_COLOR": "rgb(148, 92, 37)",
  "INLINE_STATUS_WARNING_VARIANT_ICON_LIGHT_MODE_COLOR": "rgb(148, 92, 37)",
  "INLINE_STATUS_WARNING_VARIANT_ICON_DARK_MODE_COLOR": "rgb(255, 159, 64)",
  "INLINE_STATUS_WARNING_VARIANT_ICON_COLOR": "rgb(148, 92, 37)",
  "INLINE_STATUS_ERROR_VARIANT_TEXT_LIGHT_MODE_COLOR": "rgb(191, 0, 32)",
  "INLINE_STATUS_ERROR_VARIANT_TEXT_DARK_MODE_COLOR": "rgb(255, 90, 118)",
  "INLINE_STATUS_ERROR_VARIANT_TEXT_COLOR": "rgb(191, 0, 32)",
  "INLINE_STATUS_ERROR_VARIANT_ICON_LIGHT_MODE_COLOR": "rgb(191, 0, 32)",
  "INLINE_STATUS_ERROR_VARIANT_ICON_DARK_MODE_COLOR": "rgb(255, 90, 118)",
  "INLINE_STATUS_ERROR_VARIANT_ICON_COLOR": "rgb(191, 0, 32)",
  "MODAL_BLADE_BACKGROUND_LIGHT_MODE_COLOR": "rgb(255, 255, 255)",
  "MODAL_BLADE_BACKGROUND_DARK_MODE_COLOR": "rgb(28, 28, 28)",
  "MODAL_BLADE_BACKGROUND_COLOR": "rgb(255, 255, 255)",
  "MODAL_BLADE_WIDE_VIEWPORT_PADDING_LEFT_SIZE": 32,
  "MODAL_BLADE_WIDE_VIEWPORT_PADDING_TOP_SIZE": 32,
  "MODAL_BLADE_WIDE_VIEWPORT_PADDING_RIGHT_SIZE": 32,
  "MODAL_BLADE_WIDE_VIEWPORT_PADDING_BOTTOM_SIZE": 0,
  "MODAL_BLADE_WIDE_VIEWPORT_MAX_HEIGHT_SIZE": 500,
  "MODAL_BLADE_WIDE_VIEWPORT_CONTENT_WIDTH_SIZE": 400,
  "MODAL_BLADE_WIDE_VIEWPORT_WIDTH_SIZE": 464,
  "MODAL_BLADE_COMPACT_HORIZONTAL_SIZE_CLASS_HORIZONTAL_PADDING": 32,
  "MODAL_BLADE_REGULAR_HORIZONTAL_SIZE_CLASS_HORIZONTAL_PADDING": 32,
  "MODAL_BLADE_WIDE_HORIZONTAL_SIZE_CLASS_HORIZONTAL_PADDING": 32,
  "MODAL_BLADE_COMPACT_VERTICAL_SIZE_CLASS_VERTICAL_PADDING": 24,
  "MODAL_BLADE_REGULAR_VERTICAL_SIZE_CLASS_VERTICAL_PADDING": 24,
  "MODAL_BLADE_MAXIMUM_WIDTH_SIZE": 464,
  "MODAL_DIALOG_LIGHT_MODE_BACKGROUND_COLOR": "rgb(255, 255, 255)",
  "MODAL_DIALOG_DARK_MODE_BACKGROUND_COLOR": "rgb(28, 28, 28)",
  "MODAL_DIALOG_BORDER_RADIUS": 12,
  "MODAL_DIALOG_ANIMATION_ENTER_TRANSITION_DURATION": 240,
  "MODAL_DIALOG_ANIMATION_ENTER_TRANSITION_EASING": "cubic-bezier(0.52, 0.0, 0.74, 0.0)",
  "MODAL_DIALOG_ANIMATION_EXIT_TRANSITION_DURATION": 160,
  "MODAL_DIALOG_ANIMATION_EXIT_TRANSITION_EASING": "cubic-bezier(0.52, 0.0, 0.74, 0.0)",
  "MODAL_DIALOG_ANIMATION_DURATION": 500,
  "MODAL_DIALOG_NARROW_VIEWPORT_HORIZONTAL_PADDING_SIZE": 24,
  "MODAL_DIALOG_NARROW_VIEWPORT_VERTICAL_PADDING_SIZE": 24,
  "MODAL_DIALOG_MEDIUM_VIEWPORT_WIDTH": 480,
  "MODAL_DIALOG_MEDIUM_VIEWPORT_HORIZONTAL_PADDING_SIZE": 40,
  "MODAL_DIALOG_MEDIUM_VIEWPORT_VERTICAL_PADDING_SIZE": 24,
  "MODAL_DIALOG_WIDE_VIEWPORT_WIDTH": 480,
  "MODAL_DIALOG_WIDE_VIEWPORT_HORIZONTAL_PADDING_SIZE": 32,
  "MODAL_DIALOG_WIDE_VIEWPORT_VERTICAL_PADDING_SIZE": 24,
  "MODAL_DIALOG_COMPACT_HORIZONTAL_SIZE_CLASS_HORIZONTAL_PADDING": 32,
  "MODAL_DIALOG_REGULAR_HORIZONTAL_SIZE_CLASS_HORIZONTAL_PADDING": 32,
  "MODAL_DIALOG_WIDE_HORIZONTAL_SIZE_CLASS_HORIZONTAL_PADDING": 32,
  "MODAL_DIALOG_COMPACT_VERTICAL_SIZE_CLASS_VERTICAL_PADDING": 32,
  "MODAL_DIALOG_REGULAR_VERTICAL_SIZE_CLASS_VERTICAL_PADDING": 32,
  "MODAL_DIALOG_MAXIMUM_WIDTH_SIZE": 480,
  "MODAL_DIALOG_BACKGROUND_COLOR": "rgb(255, 255, 255)",
  "MODAL_FULL_LIGHT_MODE_BACKGROUND_COLOR": "rgb(255, 255, 255)",
  "MODAL_FULL_DARK_MODE_BACKGROUND_COLOR": "rgb(8, 8, 8)",
  "MODAL_FULL_ANIMATION_ENTER_TRANSITION_DURATION": 240,
  "MODAL_FULL_ANIMATION_ENTER_TRANSITION_EASING": "cubic-bezier(0.52, 0.0, 0.74, 0.0)",
  "MODAL_FULL_ANIMATION_EXIT_TRANSITION_DURATION": 160,
  "MODAL_FULL_ANIMATION_EXIT_TRANSITION_EASING": "cubic-bezier(0.52, 0.0, 0.74, 0.0)",
  "MODAL_FULL_FIXED_LAYOUT_WIDTH": 600,
  "MODAL_FULL_NARROW_VIEWPORT_ANIMATION_DURATION": 500,
  "MODAL_FULL_NARROW_VIEWPORT_HORIZONTAL_PADDING_SIZE": 16,
  "MODAL_FULL_NARROW_VIEWPORT_VERTICAL_PADDING_SIZE": 16,
  "MODAL_FULL_MEDIUM_VIEWPORT_ANIMATION_DURATION": 500,
  "MODAL_FULL_MEDIUM_VIEWPORT_HORIZONTAL_PADDING_SIZE": 24,
  "MODAL_FULL_MEDIUM_VIEWPORT_VERTICAL_PADDING_SIZE": 24,
  "MODAL_FULL_WIDE_VIEWPORT_ANIMATION_DURATION": 500,
  "MODAL_FULL_WIDE_VIEWPORT_HORIZONTAL_PADDING_SIZE": 32,
  "MODAL_FULL_WIDE_VIEWPORT_VERTICAL_PADDING_SIZE": 16,
  "MODAL_FULL_COMPACT_HORIZONTAL_SIZE_CLASS_HORIZONTAL_PADDING": 16,
  "MODAL_FULL_REGULAR_HORIZONTAL_SIZE_CLASS_HORIZONTAL_PADDING": 24,
  "MODAL_FULL_WIDE_HORIZONTAL_SIZE_CLASS_HORIZONTAL_PADDING": 24,
  "MODAL_FULL_COMPACT_VERTICAL_SIZE_CLASS_VERTICAL_PADDING": 12,
  "MODAL_FULL_REGULAR_VERTICAL_SIZE_CLASS_VERTICAL_PADDING": 16,
  "MODAL_FULL_BACKGROUND_COLOR": "rgb(255, 255, 255)",
  "MODAL_PARTIAL_LIGHT_MODE_BACKGROUND_COLOR": "rgb(255, 255, 255)",
  "MODAL_PARTIAL_DARK_MODE_BACKGROUND_COLOR": "rgb(28, 28, 28)",
  "MODAL_PARTIAL_BORDER_RADIUS": 12,
  "MODAL_PARTIAL_ANIMATION_ENTER_TRANSITION_DURATION": 240,
  "MODAL_PARTIAL_ANIMATION_ENTER_TRANSITION_EASING": "cubic-bezier(0.52, 0.0, 0.74, 0.0)",
  "MODAL_PARTIAL_ANIMATION_EXIT_TRANSITION_DURATION": 160,
  "MODAL_PARTIAL_ANIMATION_EXIT_TRANSITION_EASING": "cubic-bezier(0.52, 0.0, 0.74, 0.0)",
  "MODAL_PARTIAL_NARROW_VIEWPORT_ANIMATION_DURATION": 500,
  "MODAL_PARTIAL_NARROW_VIEWPORT_HORIZONTAL_PADDING_SIZE": 16,
  "MODAL_PARTIAL_NARROW_VIEWPORT_HORIZONTAL_SCREEN_BUFFER_SIZE": 0,
  "MODAL_PARTIAL_NARROW_VIEWPORT_VERTICAL_PADDING_SIZE": 16,
  "MODAL_PARTIAL_NARROW_VIEWPORT_VERTICAL_SCREEN_BUFFER_SIZE": 0,
  "MODAL_PARTIAL_MEDIUM_VIEWPORT_ANIMATION_DURATION": 500,
  "MODAL_PARTIAL_MEDIUM_VIEWPORT_WIDTH": 664,
  "MODAL_PARTIAL_MEDIUM_VIEWPORT_HORIZONTAL_PADDING_SIZE": 32,
  "MODAL_PARTIAL_MEDIUM_VIEWPORT_HORIZONTAL_SCREEN_BUFFER_SIZE": 24,
  "MODAL_PARTIAL_MEDIUM_VIEWPORT_VERTICAL_PADDING_SIZE": 32,
  "MODAL_PARTIAL_MEDIUM_VIEWPORT_VERTICAL_SCREEN_BUFFER_SIZE": 24,
  "MODAL_PARTIAL_WIDE_VIEWPORT_ANIMATION_DURATION": 500,
  "MODAL_PARTIAL_WIDE_VIEWPORT_WIDTH": 664,
  "MODAL_PARTIAL_WIDE_VIEWPORT_HORIZONTAL_PADDING_SIZE": 32,
  "MODAL_PARTIAL_WIDE_VIEWPORT_HORIZONTAL_SCREEN_BUFFER_SIZE": 24,
  "MODAL_PARTIAL_WIDE_VIEWPORT_VERTICAL_PADDING_SIZE": 32,
  "MODAL_PARTIAL_WIDE_VIEWPORT_VERTICAL_SCREEN_BUFFER_SIZE": 24,
  "MODAL_PARTIAL_COMPACT_HORIZONTAL_SIZE_CLASS_HORIZONTAL_PADDING": 32,
  "MODAL_PARTIAL_REGULAR_HORIZONTAL_SIZE_CLASS_HORIZONTAL_PADDING": 32,
  "MODAL_PARTIAL_WIDE_HORIZONTAL_SIZE_CLASS_HORIZONTAL_PADDING": 32,
  "MODAL_PARTIAL_COMPACT_VERTICAL_SIZE_CLASS_VERTICAL_PADDING": 24,
  "MODAL_PARTIAL_REGULAR_VERTICAL_SIZE_CLASS_VERTICAL_PADDING": 24,
  "MODAL_PARTIAL_MAXIMUM_WIDTH_SIZE": 664,
  "MODAL_PARTIAL_BACKGROUND_COLOR": "rgb(255, 255, 255)",
  "MODAL_POPOVER_BACKGROUND_LIGHT_MODE_COLOR": "rgb(255, 255, 255)",
  "MODAL_POPOVER_BACKGROUND_DARK_MODE_COLOR": "rgb(45, 45, 45)",
  "MODAL_POPOVER_BACKGROUND_COLOR": "rgb(255, 255, 255)",
  "MODAL_POPOVER_BORDER_RADIUS": 6,
  "MODAL_POPOVER_APPEAR_ANIMATION_PERCENTAGE": 70,
  "MODAL_POPOVER_WIDE_VIEWPORT_PADDING_LEFT_SIZE": 24,
  "MODAL_POPOVER_WIDE_VIEWPORT_PADDING_TOP_SIZE": 8,
  "MODAL_POPOVER_WIDE_VIEWPORT_PADDING_RIGHT_SIZE": 24,
  "MODAL_POPOVER_WIDE_VIEWPORT_PADDING_BOTTOM_SIZE": 8,
  "MODAL_POPOVER_WIDE_VIEWPORT_MAX_HEIGHT_SIZE": 500,
  "MODAL_POPOVER_WIDE_VIEWPORT_MIN_WIDTH_SIZE": 200,
  "MODAL_POPOVER_COMPACT_HORIZONTAL_SIZE_CLASS_HORIZONTAL_PADDING": 16,
  "MODAL_POPOVER_REGULAR_HORIZONTAL_SIZE_CLASS_HORIZONTAL_PADDING": 16,
  "MODAL_POPOVER_WIDE_HORIZONTAL_SIZE_CLASS_HORIZONTAL_PADDING": 16,
  "MODAL_POPOVER_COMPACT_VERTICAL_SIZE_CLASS_VERTICAL_PADDING": 16,
  "MODAL_POPOVER_REGULAR_VERTICAL_SIZE_CLASS_VERTICAL_PADDING": 16,
  "MODAL_SHEET_BACKGROUND_LIGHT_MODE_COLOR": "rgb(255, 255, 255)",
  "MODAL_SHEET_BACKGROUND_DARK_MODE_COLOR": "rgb(28, 28, 28)",
  "MODAL_SHEET_BACKGROUND_COLOR": "rgb(255, 255, 255)",
  "MODAL_SHEET_BORDER_RADIUS": 12,
  "MODAL_SHEET_NARROW_VIEWPORT_PADDING_LEFT_SIZE": 16,
  "MODAL_SHEET_NARROW_VIEWPORT_PADDING_TOP_SIZE": 16,
  "MODAL_SHEET_NARROW_VIEWPORT_PADDING_RIGHT_SIZE": 16,
  "MODAL_SHEET_NARROW_VIEWPORT_PADDING_BOTTOM_SIZE": 16,
  "MODAL_SHEET_NARROW_VIEWPORT_MARGIN_BOTTOM_SIZE": 0,
  "MODAL_SHEET_WIDE_VIEWPORT_PADDING_LEFT_SIZE": 32,
  "MODAL_SHEET_WIDE_VIEWPORT_PADDING_TOP_SIZE": 32,
  "MODAL_SHEET_WIDE_VIEWPORT_PADDING_RIGHT_SIZE": 32,
  "MODAL_SHEET_WIDE_VIEWPORT_PADDING_BOTTOM_SIZE": 32,
  "MODAL_SHEET_WIDE_VIEWPORT_MARGIN_BOTTOM_SIZE": 16,
  "MODAL_SHEET_HANDLE_DARK_MODE_BACKGROUND_COLOR": "rgba(255, 255, 255, 0.2)",
  "MODAL_SHEET_HANDLE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(255, 255, 255, 0.4)",
  "MODAL_SHEET_HANDLE_TOP_BUFFER_SIZE": 10,
  "MODAL_SHEET_HANDLE_PADDING_TOP_SIZE": 26,
  "MODAL_SHEET_HANDLE_PADDING_BOTTOM_SIZE": 8,
  "MODAL_SHEET_HANDLE_WIDTH": 56,
  "MODAL_SHEET_HANDLE_HEIGHT": 6,
  "MODAL_SHEET_HANDLE_BORDER_RADIUS": 100,
  "MODAL_SHEET_HANDLE_BACKGROUND_COLOR": "rgba(255, 255, 255, 0.4)",
  "MODAL_SHEET_COMPACT_HORIZONTAL_SIZE_CLASS_HORIZONTAL_PADDING": 16,
  "MODAL_SHEET_REGULAR_HORIZONTAL_SIZE_CLASS_HORIZONTAL_PADDING": 24,
  "MODAL_SHEET_WIDE_HORIZONTAL_SIZE_CLASS_HORIZONTAL_PADDING": 24,
  "MODAL_SHEET_COMPACT_VERTICAL_SIZE_CLASS_VERTICAL_PADDING": 16,
  "MODAL_SHEET_REGULAR_VERTICAL_SIZE_CLASS_VERTICAL_PADDING": 24,
  "PAGE_INDICATOR_DOT_SIZE": 6,
  "PAGE_INDICATOR_DOT_COLOR": "rgba(0, 0, 0, 0.55)",
  "PAGE_INDICATOR_SELECTED_SIZE": 8,
  "PAGE_INDICATOR_SELECTED_COLOR": "rgb(0, 106, 255)",
  "PAGE_INDICATOR_SPACING_SIZE": 6,
  "PAGE_INDICATOR_LIGHT_MODE_DOT_COLOR": "rgba(0, 0, 0, 0.55)",
  "PAGE_INDICATOR_LIGHT_MODE_SELECTED_COLOR": "rgb(0, 106, 255)",
  "PAGE_INDICATOR_DARK_MODE_DOT_COLOR": "rgba(255, 255, 255, 0.55)",
  "PAGE_INDICATOR_DARK_MODE_SELECTED_COLOR": "rgb(0, 106, 255)",
  "PAGINATION_NAV_VERTICAL_MARGIN": 16,
  "PAGINATION_NAV_HORIZONTAL_MARGIN": 8,
  "PAGINATION_NAV_CONTENT_SPACING": 8,
  "PILL_NORMAL_SIZE_TEXT_SIZE": 14,
  "PILL_NORMAL_SIZE_TEXT_LEADING": 22,
  "PILL_NORMAL_SIZE_TEXT_TRACKING": 0,
  "PILL_NORMAL_SIZE_TEXT_WEIGHT": 600,
  "PILL_NORMAL_SIZE_TEXT_CASE": "regular",
  "PILL_NORMAL_SIZE_TEXT_NUMBER_SPACING": "default",
  "PILL_NORMAL_SIZE_TEXT_SCALE": "paragraph-20",
  "PILL_NORMAL_SIZE_TEXT_FONT_FAMILY": "Square Sans Text",
  "PILL_NORMAL_SIZE_PADDING_HORIZONTAL_SIZE": 12,
  "PILL_NORMAL_SIZE_PADDING_VERTICAL_SIZE": 5,
  "PILL_NORMAL_SIZE_ICON_LEFT_PADDING": 8,
  "PILL_NORMAL_SIZE_TEXT_EDGE_PADDING": 12,
  "PILL_NORMAL_SIZE_CORNER_RADIUS": 100,
  "PILL_NORMAL_SIZE_ICON_DOT_RADIUS": 5,
  "PILL_NORMAL_SIZE_ICON_SIZE": 16,
  "PILL_NORMAL_SIZE_SPACING_HORIZONTAL": 8,
  "PILL_NORMAL_SIZE_ICON_TO_TEXT_SPACING": 4,
  "PILL_COMPACT_SIZE_TEXT_SIZE": 12,
  "PILL_COMPACT_SIZE_TEXT_LEADING": 18,
  "PILL_COMPACT_SIZE_TEXT_TRACKING": 0,
  "PILL_COMPACT_SIZE_TEXT_WEIGHT": 600,
  "PILL_COMPACT_SIZE_TEXT_CASE": "regular",
  "PILL_COMPACT_SIZE_TEXT_NUMBER_SPACING": "default",
  "PILL_COMPACT_SIZE_TEXT_SCALE": "paragraph-10",
  "PILL_COMPACT_SIZE_TEXT_FONT_FAMILY": "Square Sans Text",
  "PILL_COMPACT_SIZE_PADDING_HORIZONTAL_SIZE": 8,
  "PILL_COMPACT_SIZE_PADDING_VERTICAL_SIZE": 3,
  "PILL_COMPACT_SIZE_ICON_LEFT_PADDING": 4,
  "PILL_COMPACT_SIZE_TEXT_EDGE_PADDING": 8,
  "PILL_COMPACT_SIZE_ICON_DOT_RADIUS": 4,
  "PILL_COMPACT_SIZE_ICON_SIZE": 16,
  "PILL_COMPACT_SIZE_SPACING_HORIZONTAL": 6,
  "PILL_COMPACT_SIZE_ICON_TO_TEXT_SPACING": 3,
  "PILL_NORMAL_VARIANT_BACKGROUND_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.05)",
  "PILL_NORMAL_VARIANT_BACKGROUND_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.08)",
  "PILL_NORMAL_VARIANT_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.05)",
  "PILL_NORMAL_VARIANT_NORMAL_STATE_BACKGROUND_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.05)",
  "PILL_NORMAL_VARIANT_NORMAL_STATE_BACKGROUND_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.08)",
  "PILL_NORMAL_VARIANT_NORMAL_STATE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.05)",
  "PILL_NORMAL_VARIANT_PRESSED_STATE_BACKGROUND_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.15)",
  "PILL_NORMAL_VARIANT_PRESSED_STATE_BACKGROUND_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.15)",
  "PILL_NORMAL_VARIANT_PRESSED_STATE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.15)",
  "PILL_NORMAL_VARIANT_TEXT_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.55)",
  "PILL_NORMAL_VARIANT_TEXT_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.55)",
  "PILL_NORMAL_VARIANT_TEXT_COLOR": "rgba(0, 0, 0, 0.55)",
  "PILL_NORMAL_VARIANT_ICON_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.3)",
  "PILL_NORMAL_VARIANT_ICON_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.3)",
  "PILL_NORMAL_VARIANT_ICON_COLOR": "rgba(0, 0, 0, 0.3)",
  "PILL_EMPHASIS_VARIANT_BACKGROUND_LIGHT_MODE_COLOR": "rgb(230, 240, 255)",
  "PILL_EMPHASIS_VARIANT_BACKGROUND_DARK_MODE_COLOR": "rgb(0, 21, 51)",
  "PILL_EMPHASIS_VARIANT_BACKGROUND_COLOR": "rgb(230, 240, 255)",
  "PILL_EMPHASIS_VARIANT_NORMAL_STATE_BACKGROUND_LIGHT_MODE_COLOR": "rgb(230, 240, 255)",
  "PILL_EMPHASIS_VARIANT_NORMAL_STATE_BACKGROUND_DARK_MODE_COLOR": "rgb(0, 21, 51)",
  "PILL_EMPHASIS_VARIANT_NORMAL_STATE_BACKGROUND_COLOR": "rgb(230, 240, 255)",
  "PILL_EMPHASIS_VARIANT_PRESSED_STATE_BACKGROUND_LIGHT_MODE_COLOR": "rgb(204, 225, 255)",
  "PILL_EMPHASIS_VARIANT_PRESSED_STATE_BACKGROUND_DARK_MODE_COLOR": "rgb(0, 37, 89)",
  "PILL_EMPHASIS_VARIANT_PRESSED_STATE_BACKGROUND_COLOR": "rgb(204, 225, 255)",
  "PILL_EMPHASIS_VARIANT_TEXT_LIGHT_MODE_COLOR": "rgb(0, 90, 217)",
  "PILL_EMPHASIS_VARIANT_TEXT_DARK_MODE_COLOR": "rgb(74, 149, 255)",
  "PILL_EMPHASIS_VARIANT_TEXT_COLOR": "rgb(0, 90, 217)",
  "PILL_EMPHASIS_VARIANT_ICON_LIGHT_MODE_COLOR": "rgb(0, 106, 255)",
  "PILL_EMPHASIS_VARIANT_ICON_DARK_MODE_COLOR": "rgb(0, 106, 255)",
  "PILL_EMPHASIS_VARIANT_ICON_COLOR": "rgb(0, 106, 255)",
  "PILL_SUCCESS_VARIANT_BACKGROUND_LIGHT_MODE_COLOR": "rgb(229, 255, 238)",
  "PILL_SUCCESS_VARIANT_BACKGROUND_DARK_MODE_COLOR": "rgb(0, 51, 17)",
  "PILL_SUCCESS_VARIANT_BACKGROUND_COLOR": "rgb(229, 255, 238)",
  "PILL_SUCCESS_VARIANT_NORMAL_STATE_BACKGROUND_LIGHT_MODE_COLOR": "rgb(229, 255, 238)",
  "PILL_SUCCESS_VARIANT_NORMAL_STATE_BACKGROUND_DARK_MODE_COLOR": "rgb(0, 51, 17)",
  "PILL_SUCCESS_VARIANT_NORMAL_STATE_BACKGROUND_COLOR": "rgb(229, 255, 238)",
  "PILL_SUCCESS_VARIANT_PRESSED_STATE_BACKGROUND_LIGHT_MODE_COLOR": "rgb(204, 255, 221)",
  "PILL_SUCCESS_VARIANT_PRESSED_STATE_BACKGROUND_DARK_MODE_COLOR": "rgb(0, 89, 30)",
  "PILL_SUCCESS_VARIANT_PRESSED_STATE_BACKGROUND_COLOR": "rgb(204, 255, 221)",
  "PILL_SUCCESS_VARIANT_TEXT_LIGHT_MODE_COLOR": "rgb(0, 125, 42)",
  "PILL_SUCCESS_VARIANT_TEXT_DARK_MODE_COLOR": "rgb(22, 217, 86)",
  "PILL_SUCCESS_VARIANT_TEXT_COLOR": "rgb(0, 125, 42)",
  "PILL_SUCCESS_VARIANT_ICON_LIGHT_MODE_COLOR": "rgb(0, 178, 59)",
  "PILL_SUCCESS_VARIANT_ICON_DARK_MODE_COLOR": "rgb(0, 179, 59)",
  "PILL_SUCCESS_VARIANT_ICON_COLOR": "rgb(0, 178, 59)",
  "PILL_WARNING_VARIANT_BACKGROUND_LIGHT_MODE_COLOR": "rgb(255, 242, 230)",
  "PILL_WARNING_VARIANT_BACKGROUND_DARK_MODE_COLOR": "rgb(51, 26, 0)",
  "PILL_WARNING_VARIANT_BACKGROUND_COLOR": "rgb(255, 242, 230)",
  "PILL_WARNING_VARIANT_NORMAL_STATE_BACKGROUND_LIGHT_MODE_COLOR": "rgb(255, 242, 230)",
  "PILL_WARNING_VARIANT_NORMAL_STATE_BACKGROUND_DARK_MODE_COLOR": "rgb(51, 26, 0)",
  "PILL_WARNING_VARIANT_NORMAL_STATE_BACKGROUND_COLOR": "rgb(255, 242, 230)",
  "PILL_WARNING_VARIANT_PRESSED_STATE_BACKGROUND_LIGHT_MODE_COLOR": "rgb(255, 230, 204)",
  "PILL_WARNING_VARIANT_PRESSED_STATE_BACKGROUND_DARK_MODE_COLOR": "rgb(89, 45, 0)",
  "PILL_WARNING_VARIANT_PRESSED_STATE_BACKGROUND_COLOR": "rgb(255, 230, 204)",
  "PILL_WARNING_VARIANT_TEXT_LIGHT_MODE_COLOR": "rgb(148, 92, 37)",
  "PILL_WARNING_VARIANT_TEXT_DARK_MODE_COLOR": "rgb(255, 159, 64)",
  "PILL_WARNING_VARIANT_TEXT_COLOR": "rgb(148, 92, 37)",
  "PILL_WARNING_VARIANT_ICON_LIGHT_MODE_COLOR": "rgb(255, 159, 64)",
  "PILL_WARNING_VARIANT_ICON_DARK_MODE_COLOR": "rgb(255, 159, 64)",
  "PILL_WARNING_VARIANT_ICON_COLOR": "rgb(255, 159, 64)",
  "PILL_CRITICAL_VARIANT_BACKGROUND_LIGHT_MODE_COLOR": "rgb(255, 229, 234)",
  "PILL_CRITICAL_VARIANT_BACKGROUND_DARK_MODE_COLOR": "rgb(51, 0, 9)",
  "PILL_CRITICAL_VARIANT_BACKGROUND_COLOR": "rgb(255, 229, 234)",
  "PILL_CRITICAL_VARIANT_NORMAL_STATE_BACKGROUND_LIGHT_MODE_COLOR": "rgb(255, 229, 234)",
  "PILL_CRITICAL_VARIANT_NORMAL_STATE_BACKGROUND_DARK_MODE_COLOR": "rgb(51, 0, 9)",
  "PILL_CRITICAL_VARIANT_NORMAL_STATE_BACKGROUND_COLOR": "rgb(255, 229, 234)",
  "PILL_CRITICAL_VARIANT_PRESSED_STATE_BACKGROUND_LIGHT_MODE_COLOR": "rgb(255, 204, 213)",
  "PILL_CRITICAL_VARIANT_PRESSED_STATE_BACKGROUND_DARK_MODE_COLOR": "rgb(89, 0, 15)",
  "PILL_CRITICAL_VARIANT_PRESSED_STATE_BACKGROUND_COLOR": "rgb(255, 204, 213)",
  "PILL_CRITICAL_VARIANT_TEXT_LIGHT_MODE_COLOR": "rgb(191, 0, 32)",
  "PILL_CRITICAL_VARIANT_TEXT_DARK_MODE_COLOR": "rgb(255, 90, 118)",
  "PILL_CRITICAL_VARIANT_TEXT_COLOR": "rgb(191, 0, 32)",
  "PILL_CRITICAL_VARIANT_ICON_LIGHT_MODE_COLOR": "rgb(204, 0, 35)",
  "PILL_CRITICAL_VARIANT_ICON_DARK_MODE_COLOR": "rgb(204, 0, 35)",
  "PILL_CRITICAL_VARIANT_ICON_COLOR": "rgb(204, 0, 35)",
  "PILL_INSIGHT_VARIANT_BACKGROUND_LIGHT_MODE_COLOR": "rgb(244, 230, 255)",
  "PILL_INSIGHT_VARIANT_BACKGROUND_DARK_MODE_COLOR": "rgb(30, 0, 51)",
  "PILL_INSIGHT_VARIANT_BACKGROUND_COLOR": "rgb(244, 230, 255)",
  "PILL_INSIGHT_VARIANT_NORMAL_STATE_BACKGROUND_LIGHT_MODE_COLOR": "rgb(244, 230, 255)",
  "PILL_INSIGHT_VARIANT_NORMAL_STATE_BACKGROUND_DARK_MODE_COLOR": "rgb(30, 0, 51)",
  "PILL_INSIGHT_VARIANT_NORMAL_STATE_BACKGROUND_COLOR": "rgb(244, 230, 255)",
  "PILL_INSIGHT_VARIANT_PRESSED_STATE_BACKGROUND_LIGHT_MODE_COLOR": "rgb(234, 204, 255)",
  "PILL_INSIGHT_VARIANT_PRESSED_STATE_BACKGROUND_DARK_MODE_COLOR": "rgb(52, 0, 89)",
  "PILL_INSIGHT_VARIANT_PRESSED_STATE_BACKGROUND_COLOR": "rgb(234, 204, 255)",
  "PILL_INSIGHT_VARIANT_TEXT_LIGHT_MODE_COLOR": "rgb(135, 22, 217)",
  "PILL_INSIGHT_VARIANT_TEXT_DARK_MODE_COLOR": "rgb(194, 110, 255)",
  "PILL_INSIGHT_VARIANT_TEXT_COLOR": "rgb(135, 22, 217)",
  "PILL_INSIGHT_VARIANT_ICON_LIGHT_MODE_COLOR": "rgb(135, 22, 217)",
  "PILL_INSIGHT_VARIANT_ICON_DARK_MODE_COLOR": "rgb(135, 22, 217)",
  "PILL_INSIGHT_VARIANT_ICON_COLOR": "rgb(135, 22, 217)",
  "PILL_ALPHA_VARIANT_BACKGROUND_LIGHT_MODE_COLOR": "rgb(255, 230, 249)",
  "PILL_ALPHA_VARIANT_BACKGROUND_DARK_MODE_COLOR": "rgb(51, 0, 38)",
  "PILL_ALPHA_VARIANT_BACKGROUND_COLOR": "rgb(255, 230, 249)",
  "PILL_ALPHA_VARIANT_NORMAL_STATE_BACKGROUND_LIGHT_MODE_COLOR": "rgb(255, 230, 249)",
  "PILL_ALPHA_VARIANT_NORMAL_STATE_BACKGROUND_DARK_MODE_COLOR": "rgb(51, 0, 38)",
  "PILL_ALPHA_VARIANT_NORMAL_STATE_BACKGROUND_COLOR": "rgb(255, 230, 249)",
  "PILL_ALPHA_VARIANT_PRESSED_STATE_BACKGROUND_LIGHT_MODE_COLOR": "rgb(255, 204, 242)",
  "PILL_ALPHA_VARIANT_PRESSED_STATE_BACKGROUND_DARK_MODE_COLOR": "rgb(89, 0, 67)",
  "PILL_ALPHA_VARIANT_PRESSED_STATE_BACKGROUND_COLOR": "rgb(255, 204, 242)",
  "PILL_ALPHA_VARIANT_TEXT_LIGHT_MODE_COLOR": "rgb(168, 42, 136)",
  "PILL_ALPHA_VARIANT_TEXT_DARK_MODE_COLOR": "rgb(255, 67, 208)",
  "PILL_ALPHA_VARIANT_TEXT_COLOR": "rgb(168, 42, 136)",
  "PILL_ALPHA_VARIANT_ICON_LIGHT_MODE_COLOR": "rgb(217, 54, 176)",
  "PILL_ALPHA_VARIANT_ICON_DARK_MODE_COLOR": "rgb(217, 54, 176)",
  "PILL_ALPHA_VARIANT_ICON_COLOR": "rgb(217, 54, 176)",
  "PILL_BETA_VARIANT_BACKGROUND_LIGHT_MODE_COLOR": "rgb(230, 255, 249)",
  "PILL_BETA_VARIANT_BACKGROUND_DARK_MODE_COLOR": "rgb(0, 51, 38)",
  "PILL_BETA_VARIANT_BACKGROUND_COLOR": "rgb(230, 255, 249)",
  "PILL_BETA_VARIANT_NORMAL_STATE_BACKGROUND_LIGHT_MODE_COLOR": "rgb(230, 255, 249)",
  "PILL_BETA_VARIANT_NORMAL_STATE_BACKGROUND_DARK_MODE_COLOR": "rgb(0, 51, 38)",
  "PILL_BETA_VARIANT_NORMAL_STATE_BACKGROUND_COLOR": "rgb(230, 255, 249)",
  "PILL_BETA_VARIANT_PRESSED_STATE_BACKGROUND_LIGHT_MODE_COLOR": "rgb(204, 255, 242)",
  "PILL_BETA_VARIANT_PRESSED_STATE_BACKGROUND_DARK_MODE_COLOR": "rgb(0, 89, 67)",
  "PILL_BETA_VARIANT_PRESSED_STATE_BACKGROUND_COLOR": "rgb(204, 255, 242)",
  "PILL_BETA_VARIANT_TEXT_LIGHT_MODE_COLOR": "rgb(12, 120, 93)",
  "PILL_BETA_VARIANT_TEXT_DARK_MODE_COLOR": "rgb(22, 216, 167)",
  "PILL_BETA_VARIANT_TEXT_COLOR": "rgb(12, 120, 93)",
  "PILL_BETA_VARIANT_ICON_LIGHT_MODE_COLOR": "rgb(18, 191, 148)",
  "PILL_BETA_VARIANT_ICON_DARK_MODE_COLOR": "rgb(18, 191, 148)",
  "PILL_BETA_VARIANT_ICON_COLOR": "rgb(18, 191, 148)",
  "QRCODE_NORMAL_VARIANT_BACKGROUND_LIGHT_MODE_COLOR": "rgb(255, 255, 255)",
  "QRCODE_NORMAL_VARIANT_BACKGROUND_DARK_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "QRCODE_NORMAL_VARIANT_BACKGROUND_COLOR": "rgb(255, 255, 255)",
  "QRCODE_NORMAL_VARIANT_FOREGROUND_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "QRCODE_NORMAL_VARIANT_FOREGROUND_DARK_MODE_COLOR": "rgb(255, 255, 255)",
  "QRCODE_NORMAL_VARIANT_FOREGROUND_COLOR": "rgba(0, 0, 0, 0.9)",
  "QRCODE_NORMAL_VARIANT_ICON_LIGHT_MODE_COLOR": "rgb(0, 106, 255)",
  "QRCODE_NORMAL_VARIANT_ICON_DARK_MODE_COLOR": "rgb(0, 106, 255)",
  "QRCODE_NORMAL_VARIANT_ICON_COLOR": "rgb(0, 106, 255)",
  "QRCODE_MONOCHROME_VARIANT_BACKGROUND_LIGHT_MODE_COLOR": "rgb(255, 255, 255)",
  "QRCODE_MONOCHROME_VARIANT_BACKGROUND_DARK_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "QRCODE_MONOCHROME_VARIANT_BACKGROUND_COLOR": "rgb(255, 255, 255)",
  "QRCODE_MONOCHROME_VARIANT_FOREGROUND_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "QRCODE_MONOCHROME_VARIANT_FOREGROUND_DARK_MODE_COLOR": "rgb(255, 255, 255)",
  "QRCODE_MONOCHROME_VARIANT_FOREGROUND_COLOR": "rgba(0, 0, 0, 0.9)",
  "QRCODE_MONOCHROME_VARIANT_ICON_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "QRCODE_MONOCHROME_VARIANT_ICON_DARK_MODE_COLOR": "rgb(255, 255, 255)",
  "QRCODE_MONOCHROME_VARIANT_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "QUANTITY_INPUT_FIELD_VALUE_TEXT_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "QUANTITY_INPUT_FIELD_VALUE_TEXT_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "QUANTITY_INPUT_FIELD_VALUE_TEXT_SIZE": 48,
  "QUANTITY_INPUT_FIELD_VALUE_TEXT_LEADING": 56,
  "QUANTITY_INPUT_FIELD_VALUE_TEXT_TRACKING": 0,
  "QUANTITY_INPUT_FIELD_VALUE_TEXT_WEIGHT": 500,
  "QUANTITY_INPUT_FIELD_VALUE_TEXT_CASE": "regular",
  "QUANTITY_INPUT_FIELD_VALUE_TEXT_NUMBER_SPACING": "monospaced",
  "QUANTITY_INPUT_FIELD_VALUE_TEXT_SCALE": "heading-30",
  "QUANTITY_INPUT_FIELD_VALUE_TEXT_FONT_FAMILY": "Square Sans Display",
  "QUANTITY_INPUT_FIELD_VALUE_TEXT_PLACEHOLDER_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.3)",
  "QUANTITY_INPUT_FIELD_VALUE_TEXT_PLACEHOLDER_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.3)",
  "QUANTITY_INPUT_FIELD_VALUE_TEXT_PLACEHOLDER_COLOR": "rgba(0, 0, 0, 0.3)",
  "QUANTITY_INPUT_FIELD_VALUE_TEXT_COLOR": "rgba(0, 0, 0, 0.9)",
  "RADIO_WIDTH": 20,
  "RADIO_HEIGHT": 20,
  "RADIO_BORDER_RADIUS": 100,
  "RADIO_BORDER_SIZE": 2,
  "RADIO_SVG_RADIUS": 3,
  "RADIO_LIGHT_MODE_FOCUS_RING_COLOR": "rgb(0, 106, 255)",
  "RADIO_DARK_MODE_FOCUS_RING_COLOR": "rgb(0, 106, 255)",
  "RADIO_FOCUS_RING_BUFFER_SIZE": 2,
  "RADIO_FOCUS_RING_COLOR": "rgb(0, 106, 255)",
  "RADIO_NORMAL_STATE_NORMAL_VALIDITY_UNSELECTED_VALUE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "RADIO_NORMAL_STATE_NORMAL_VALIDITY_UNSELECTED_VALUE_LIGHT_MODE_BORDER_COLOR": "rgba(0, 0, 0, 0.3)",
  "RADIO_NORMAL_STATE_NORMAL_VALIDITY_UNSELECTED_VALUE_DARK_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "RADIO_NORMAL_STATE_NORMAL_VALIDITY_UNSELECTED_VALUE_DARK_MODE_BORDER_COLOR": "rgba(255, 255, 255, 0.3)",
  "RADIO_NORMAL_STATE_NORMAL_VALIDITY_UNSELECTED_VALUE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "RADIO_NORMAL_STATE_NORMAL_VALIDITY_UNSELECTED_VALUE_BORDER_COLOR": "rgba(0, 0, 0, 0.3)",
  "RADIO_NORMAL_STATE_NORMAL_VALIDITY_SELECTED_VALUE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(0, 106, 255)",
  "RADIO_NORMAL_STATE_NORMAL_VALIDITY_SELECTED_VALUE_LIGHT_MODE_BORDER_COLOR": "rgba(0, 0, 0, 0)",
  "RADIO_NORMAL_STATE_NORMAL_VALIDITY_SELECTED_VALUE_LIGHT_MODE_ICON_COLOR": "rgb(255, 255, 255)",
  "RADIO_NORMAL_STATE_NORMAL_VALIDITY_SELECTED_VALUE_DARK_MODE_BACKGROUND_COLOR": "rgb(0, 106, 255)",
  "RADIO_NORMAL_STATE_NORMAL_VALIDITY_SELECTED_VALUE_DARK_MODE_BORDER_COLOR": "rgba(0, 0, 0, 0)",
  "RADIO_NORMAL_STATE_NORMAL_VALIDITY_SELECTED_VALUE_DARK_MODE_ICON_COLOR": "rgb(255, 255, 255)",
  "RADIO_NORMAL_STATE_NORMAL_VALIDITY_SELECTED_VALUE_BACKGROUND_COLOR": "rgb(0, 106, 255)",
  "RADIO_NORMAL_STATE_NORMAL_VALIDITY_SELECTED_VALUE_BORDER_COLOR": "rgba(0, 0, 0, 0)",
  "RADIO_NORMAL_STATE_NORMAL_VALIDITY_SELECTED_VALUE_ICON_COLOR": "rgb(255, 255, 255)",
  "RADIO_NORMAL_STATE_INVALID_VALIDITY_UNSELECTED_VALUE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "RADIO_NORMAL_STATE_INVALID_VALIDITY_UNSELECTED_VALUE_LIGHT_MODE_BORDER_COLOR": "rgb(204, 0, 35)",
  "RADIO_NORMAL_STATE_INVALID_VALIDITY_UNSELECTED_VALUE_DARK_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "RADIO_NORMAL_STATE_INVALID_VALIDITY_UNSELECTED_VALUE_DARK_MODE_BORDER_COLOR": "rgb(204, 0, 35)",
  "RADIO_NORMAL_STATE_INVALID_VALIDITY_UNSELECTED_VALUE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "RADIO_NORMAL_STATE_INVALID_VALIDITY_UNSELECTED_VALUE_BORDER_COLOR": "rgb(204, 0, 35)",
  "RADIO_NORMAL_STATE_INVALID_VALIDITY_SELECTED_VALUE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(204, 0, 35)",
  "RADIO_NORMAL_STATE_INVALID_VALIDITY_SELECTED_VALUE_LIGHT_MODE_BORDER_COLOR": "rgba(0, 0, 0, 0)",
  "RADIO_NORMAL_STATE_INVALID_VALIDITY_SELECTED_VALUE_LIGHT_MODE_ICON_COLOR": "rgb(255, 255, 255)",
  "RADIO_NORMAL_STATE_INVALID_VALIDITY_SELECTED_VALUE_DARK_MODE_BACKGROUND_COLOR": "rgb(204, 0, 35)",
  "RADIO_NORMAL_STATE_INVALID_VALIDITY_SELECTED_VALUE_DARK_MODE_BORDER_COLOR": "rgba(0, 0, 0, 0)",
  "RADIO_NORMAL_STATE_INVALID_VALIDITY_SELECTED_VALUE_DARK_MODE_ICON_COLOR": "rgb(255, 255, 255)",
  "RADIO_NORMAL_STATE_INVALID_VALIDITY_SELECTED_VALUE_BACKGROUND_COLOR": "rgb(204, 0, 35)",
  "RADIO_NORMAL_STATE_INVALID_VALIDITY_SELECTED_VALUE_BORDER_COLOR": "rgba(0, 0, 0, 0)",
  "RADIO_NORMAL_STATE_INVALID_VALIDITY_SELECTED_VALUE_ICON_COLOR": "rgb(255, 255, 255)",
  "RADIO_HOVER_STATE_NORMAL_VALIDITY_UNSELECTED_VALUE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "RADIO_HOVER_STATE_NORMAL_VALIDITY_UNSELECTED_VALUE_LIGHT_MODE_BORDER_COLOR": "rgb(0, 106, 255)",
  "RADIO_HOVER_STATE_NORMAL_VALIDITY_UNSELECTED_VALUE_DARK_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "RADIO_HOVER_STATE_NORMAL_VALIDITY_UNSELECTED_VALUE_DARK_MODE_BORDER_COLOR": "rgb(0, 106, 255)",
  "RADIO_HOVER_STATE_NORMAL_VALIDITY_UNSELECTED_VALUE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "RADIO_HOVER_STATE_NORMAL_VALIDITY_UNSELECTED_VALUE_BORDER_COLOR": "rgb(0, 106, 255)",
  "RADIO_HOVER_STATE_NORMAL_VALIDITY_SELECTED_VALUE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(0, 95, 229)",
  "RADIO_HOVER_STATE_NORMAL_VALIDITY_SELECTED_VALUE_LIGHT_MODE_BORDER_COLOR": "rgba(0, 0, 0, 0)",
  "RADIO_HOVER_STATE_NORMAL_VALIDITY_SELECTED_VALUE_LIGHT_MODE_ICON_COLOR": "rgb(255, 255, 255)",
  "RADIO_HOVER_STATE_NORMAL_VALIDITY_SELECTED_VALUE_DARK_MODE_BACKGROUND_COLOR": "rgb(0, 95, 229)",
  "RADIO_HOVER_STATE_NORMAL_VALIDITY_SELECTED_VALUE_DARK_MODE_BORDER_COLOR": "rgba(0, 0, 0, 0)",
  "RADIO_HOVER_STATE_NORMAL_VALIDITY_SELECTED_VALUE_DARK_MODE_ICON_COLOR": "rgb(255, 255, 255)",
  "RADIO_HOVER_STATE_NORMAL_VALIDITY_SELECTED_VALUE_BACKGROUND_COLOR": "rgb(0, 95, 229)",
  "RADIO_HOVER_STATE_NORMAL_VALIDITY_SELECTED_VALUE_BORDER_COLOR": "rgba(0, 0, 0, 0)",
  "RADIO_HOVER_STATE_NORMAL_VALIDITY_SELECTED_VALUE_ICON_COLOR": "rgb(255, 255, 255)",
  "RADIO_HOVER_STATE_INVALID_VALIDITY_UNSELECTED_VALUE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "RADIO_HOVER_STATE_INVALID_VALIDITY_UNSELECTED_VALUE_LIGHT_MODE_BORDER_COLOR": "rgb(178, 0, 30)",
  "RADIO_HOVER_STATE_INVALID_VALIDITY_UNSELECTED_VALUE_DARK_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "RADIO_HOVER_STATE_INVALID_VALIDITY_UNSELECTED_VALUE_DARK_MODE_BORDER_COLOR": "rgb(178, 0, 30)",
  "RADIO_HOVER_STATE_INVALID_VALIDITY_UNSELECTED_VALUE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "RADIO_HOVER_STATE_INVALID_VALIDITY_UNSELECTED_VALUE_BORDER_COLOR": "rgb(178, 0, 30)",
  "RADIO_HOVER_STATE_INVALID_VALIDITY_SELECTED_VALUE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(178, 0, 30)",
  "RADIO_HOVER_STATE_INVALID_VALIDITY_SELECTED_VALUE_LIGHT_MODE_BORDER_COLOR": "rgba(0, 0, 0, 0)",
  "RADIO_HOVER_STATE_INVALID_VALIDITY_SELECTED_VALUE_LIGHT_MODE_ICON_COLOR": "rgb(255, 255, 255)",
  "RADIO_HOVER_STATE_INVALID_VALIDITY_SELECTED_VALUE_DARK_MODE_BACKGROUND_COLOR": "rgb(178, 0, 30)",
  "RADIO_HOVER_STATE_INVALID_VALIDITY_SELECTED_VALUE_DARK_MODE_BORDER_COLOR": "rgba(0, 0, 0, 0)",
  "RADIO_HOVER_STATE_INVALID_VALIDITY_SELECTED_VALUE_DARK_MODE_ICON_COLOR": "rgb(255, 255, 255)",
  "RADIO_HOVER_STATE_INVALID_VALIDITY_SELECTED_VALUE_BACKGROUND_COLOR": "rgb(178, 0, 30)",
  "RADIO_HOVER_STATE_INVALID_VALIDITY_SELECTED_VALUE_BORDER_COLOR": "rgba(0, 0, 0, 0)",
  "RADIO_HOVER_STATE_INVALID_VALIDITY_SELECTED_VALUE_ICON_COLOR": "rgb(255, 255, 255)",
  "RADIO_PRESSED_STATE_NORMAL_VALIDITY_UNSELECTED_VALUE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "RADIO_PRESSED_STATE_NORMAL_VALIDITY_UNSELECTED_VALUE_LIGHT_MODE_BORDER_COLOR": "rgb(0, 85, 204)",
  "RADIO_PRESSED_STATE_NORMAL_VALIDITY_UNSELECTED_VALUE_DARK_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "RADIO_PRESSED_STATE_NORMAL_VALIDITY_UNSELECTED_VALUE_DARK_MODE_BORDER_COLOR": "rgb(0, 85, 204)",
  "RADIO_PRESSED_STATE_NORMAL_VALIDITY_UNSELECTED_VALUE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "RADIO_PRESSED_STATE_NORMAL_VALIDITY_UNSELECTED_VALUE_BORDER_COLOR": "rgb(0, 85, 204)",
  "RADIO_PRESSED_STATE_NORMAL_VALIDITY_SELECTED_VALUE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(0, 85, 204)",
  "RADIO_PRESSED_STATE_NORMAL_VALIDITY_SELECTED_VALUE_LIGHT_MODE_BORDER_COLOR": "rgba(0, 0, 0, 0)",
  "RADIO_PRESSED_STATE_NORMAL_VALIDITY_SELECTED_VALUE_LIGHT_MODE_ICON_COLOR": "rgb(255, 255, 255)",
  "RADIO_PRESSED_STATE_NORMAL_VALIDITY_SELECTED_VALUE_DARK_MODE_BACKGROUND_COLOR": "rgb(0, 85, 204)",
  "RADIO_PRESSED_STATE_NORMAL_VALIDITY_SELECTED_VALUE_DARK_MODE_BORDER_COLOR": "rgba(0, 0, 0, 0)",
  "RADIO_PRESSED_STATE_NORMAL_VALIDITY_SELECTED_VALUE_DARK_MODE_ICON_COLOR": "rgb(255, 255, 255)",
  "RADIO_PRESSED_STATE_NORMAL_VALIDITY_SELECTED_VALUE_BACKGROUND_COLOR": "rgb(0, 85, 204)",
  "RADIO_PRESSED_STATE_NORMAL_VALIDITY_SELECTED_VALUE_BORDER_COLOR": "rgba(0, 0, 0, 0)",
  "RADIO_PRESSED_STATE_NORMAL_VALIDITY_SELECTED_VALUE_ICON_COLOR": "rgb(255, 255, 255)",
  "RADIO_PRESSED_STATE_INVALID_VALIDITY_UNSELECTED_VALUE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "RADIO_PRESSED_STATE_INVALID_VALIDITY_UNSELECTED_VALUE_LIGHT_MODE_BORDER_COLOR": "rgb(153, 0, 26)",
  "RADIO_PRESSED_STATE_INVALID_VALIDITY_UNSELECTED_VALUE_DARK_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "RADIO_PRESSED_STATE_INVALID_VALIDITY_UNSELECTED_VALUE_DARK_MODE_BORDER_COLOR": "rgb(153, 0, 26)",
  "RADIO_PRESSED_STATE_INVALID_VALIDITY_UNSELECTED_VALUE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "RADIO_PRESSED_STATE_INVALID_VALIDITY_UNSELECTED_VALUE_BORDER_COLOR": "rgb(153, 0, 26)",
  "RADIO_PRESSED_STATE_INVALID_VALIDITY_SELECTED_VALUE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(153, 0, 26)",
  "RADIO_PRESSED_STATE_INVALID_VALIDITY_SELECTED_VALUE_LIGHT_MODE_BORDER_COLOR": "rgba(0, 0, 0, 0)",
  "RADIO_PRESSED_STATE_INVALID_VALIDITY_SELECTED_VALUE_LIGHT_MODE_ICON_COLOR": "rgb(255, 255, 255)",
  "RADIO_PRESSED_STATE_INVALID_VALIDITY_SELECTED_VALUE_DARK_MODE_BACKGROUND_COLOR": "rgb(153, 0, 26)",
  "RADIO_PRESSED_STATE_INVALID_VALIDITY_SELECTED_VALUE_DARK_MODE_BORDER_COLOR": "rgba(0, 0, 0, 0)",
  "RADIO_PRESSED_STATE_INVALID_VALIDITY_SELECTED_VALUE_DARK_MODE_ICON_COLOR": "rgb(255, 255, 255)",
  "RADIO_PRESSED_STATE_INVALID_VALIDITY_SELECTED_VALUE_BACKGROUND_COLOR": "rgb(153, 0, 26)",
  "RADIO_PRESSED_STATE_INVALID_VALIDITY_SELECTED_VALUE_BORDER_COLOR": "rgba(0, 0, 0, 0)",
  "RADIO_PRESSED_STATE_INVALID_VALIDITY_SELECTED_VALUE_ICON_COLOR": "rgb(255, 255, 255)",
  "RADIO_FOCUS_STATE_NORMAL_VALIDITY_UNSELECTED_VALUE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "RADIO_FOCUS_STATE_NORMAL_VALIDITY_UNSELECTED_VALUE_LIGHT_MODE_BORDER_COLOR": "rgb(0, 106, 255)",
  "RADIO_FOCUS_STATE_NORMAL_VALIDITY_UNSELECTED_VALUE_DARK_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "RADIO_FOCUS_STATE_NORMAL_VALIDITY_UNSELECTED_VALUE_DARK_MODE_BORDER_COLOR": "rgb(0, 106, 255)",
  "RADIO_FOCUS_STATE_NORMAL_VALIDITY_UNSELECTED_VALUE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "RADIO_FOCUS_STATE_NORMAL_VALIDITY_UNSELECTED_VALUE_BORDER_COLOR": "rgb(0, 106, 255)",
  "RADIO_FOCUS_STATE_NORMAL_VALIDITY_SELECTED_VALUE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(0, 95, 229)",
  "RADIO_FOCUS_STATE_NORMAL_VALIDITY_SELECTED_VALUE_LIGHT_MODE_BORDER_COLOR": "rgba(0, 0, 0, 0)",
  "RADIO_FOCUS_STATE_NORMAL_VALIDITY_SELECTED_VALUE_LIGHT_MODE_ICON_COLOR": "rgb(255, 255, 255)",
  "RADIO_FOCUS_STATE_NORMAL_VALIDITY_SELECTED_VALUE_DARK_MODE_BACKGROUND_COLOR": "rgb(0, 95, 229)",
  "RADIO_FOCUS_STATE_NORMAL_VALIDITY_SELECTED_VALUE_DARK_MODE_BORDER_COLOR": "rgba(0, 0, 0, 0)",
  "RADIO_FOCUS_STATE_NORMAL_VALIDITY_SELECTED_VALUE_DARK_MODE_ICON_COLOR": "rgb(255, 255, 255)",
  "RADIO_FOCUS_STATE_NORMAL_VALIDITY_SELECTED_VALUE_BACKGROUND_COLOR": "rgb(0, 95, 229)",
  "RADIO_FOCUS_STATE_NORMAL_VALIDITY_SELECTED_VALUE_BORDER_COLOR": "rgba(0, 0, 0, 0)",
  "RADIO_FOCUS_STATE_NORMAL_VALIDITY_SELECTED_VALUE_ICON_COLOR": "rgb(255, 255, 255)",
  "RADIO_FOCUS_STATE_INVALID_VALIDITY_UNSELECTED_VALUE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "RADIO_FOCUS_STATE_INVALID_VALIDITY_UNSELECTED_VALUE_LIGHT_MODE_BORDER_COLOR": "rgb(178, 0, 30)",
  "RADIO_FOCUS_STATE_INVALID_VALIDITY_UNSELECTED_VALUE_DARK_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "RADIO_FOCUS_STATE_INVALID_VALIDITY_UNSELECTED_VALUE_DARK_MODE_BORDER_COLOR": "rgb(178, 0, 30)",
  "RADIO_FOCUS_STATE_INVALID_VALIDITY_UNSELECTED_VALUE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "RADIO_FOCUS_STATE_INVALID_VALIDITY_UNSELECTED_VALUE_BORDER_COLOR": "rgb(178, 0, 30)",
  "RADIO_FOCUS_STATE_INVALID_VALIDITY_SELECTED_VALUE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(178, 0, 30)",
  "RADIO_FOCUS_STATE_INVALID_VALIDITY_SELECTED_VALUE_LIGHT_MODE_BORDER_COLOR": "rgba(0, 0, 0, 0)",
  "RADIO_FOCUS_STATE_INVALID_VALIDITY_SELECTED_VALUE_LIGHT_MODE_ICON_COLOR": "rgb(255, 255, 255)",
  "RADIO_FOCUS_STATE_INVALID_VALIDITY_SELECTED_VALUE_DARK_MODE_BACKGROUND_COLOR": "rgb(178, 0, 30)",
  "RADIO_FOCUS_STATE_INVALID_VALIDITY_SELECTED_VALUE_DARK_MODE_BORDER_COLOR": "rgba(0, 0, 0, 0)",
  "RADIO_FOCUS_STATE_INVALID_VALIDITY_SELECTED_VALUE_DARK_MODE_ICON_COLOR": "rgb(255, 255, 255)",
  "RADIO_FOCUS_STATE_INVALID_VALIDITY_SELECTED_VALUE_BACKGROUND_COLOR": "rgb(178, 0, 30)",
  "RADIO_FOCUS_STATE_INVALID_VALIDITY_SELECTED_VALUE_BORDER_COLOR": "rgba(0, 0, 0, 0)",
  "RADIO_FOCUS_STATE_INVALID_VALIDITY_SELECTED_VALUE_ICON_COLOR": "rgb(255, 255, 255)",
  "RADIO_DISABLED_STATE_NORMAL_VALIDITY_UNSELECTED_VALUE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.03)",
  "RADIO_DISABLED_STATE_NORMAL_VALIDITY_UNSELECTED_VALUE_LIGHT_MODE_BORDER_COLOR": "rgba(0, 0, 0, 0.03)",
  "RADIO_DISABLED_STATE_NORMAL_VALIDITY_UNSELECTED_VALUE_DARK_MODE_BACKGROUND_COLOR": "rgba(255, 255, 255, 0.05)",
  "RADIO_DISABLED_STATE_NORMAL_VALIDITY_UNSELECTED_VALUE_DARK_MODE_BORDER_COLOR": "rgba(255, 255, 255, 0.05)",
  "RADIO_DISABLED_STATE_NORMAL_VALIDITY_UNSELECTED_VALUE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.03)",
  "RADIO_DISABLED_STATE_NORMAL_VALIDITY_UNSELECTED_VALUE_BORDER_COLOR": "rgba(0, 0, 0, 0.03)",
  "RADIO_DISABLED_STATE_NORMAL_VALIDITY_SELECTED_VALUE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.3)",
  "RADIO_DISABLED_STATE_NORMAL_VALIDITY_SELECTED_VALUE_LIGHT_MODE_BORDER_COLOR": "rgba(0, 0, 0, 0)",
  "RADIO_DISABLED_STATE_NORMAL_VALIDITY_SELECTED_VALUE_LIGHT_MODE_ICON_COLOR": "rgb(255, 255, 255)",
  "RADIO_DISABLED_STATE_NORMAL_VALIDITY_SELECTED_VALUE_DARK_MODE_BACKGROUND_COLOR": "rgba(255, 255, 255, 0.3)",
  "RADIO_DISABLED_STATE_NORMAL_VALIDITY_SELECTED_VALUE_DARK_MODE_BORDER_COLOR": "rgba(0, 0, 0, 0)",
  "RADIO_DISABLED_STATE_NORMAL_VALIDITY_SELECTED_VALUE_DARK_MODE_ICON_COLOR": "rgb(255, 255, 255)",
  "RADIO_DISABLED_STATE_NORMAL_VALIDITY_SELECTED_VALUE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.3)",
  "RADIO_DISABLED_STATE_NORMAL_VALIDITY_SELECTED_VALUE_BORDER_COLOR": "rgba(0, 0, 0, 0)",
  "RADIO_DISABLED_STATE_NORMAL_VALIDITY_SELECTED_VALUE_ICON_COLOR": "rgb(255, 255, 255)",
  "RICH_TEXT_TOOLBAR_BUTTON_MINIMUM_HEIGHT": 32,
  "RICH_TEXT_TOOLBAR_BUTTON_WIDTH_MULTIPLIER": 1,
  "RICH_TEXT_TOOLBAR_BUTTON_PADDING_SIZE": 4,
  "RICH_TEXT_TOOLBAR_BUTTON_UNSELECTED_VALUE_NORMAL_STATE_ICON_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "RICH_TEXT_TOOLBAR_BUTTON_UNSELECTED_VALUE_NORMAL_STATE_ICON_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "RICH_TEXT_TOOLBAR_BUTTON_UNSELECTED_VALUE_NORMAL_STATE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "RICH_TEXT_TOOLBAR_BUTTON_UNSELECTED_VALUE_HOVER_STATE_BACKGROUND_LIGHT_MODE_COLOR": "rgb(230, 240, 255)",
  "RICH_TEXT_TOOLBAR_BUTTON_UNSELECTED_VALUE_HOVER_STATE_BACKGROUND_DARK_MODE_COLOR": "rgb(0, 21, 51)",
  "RICH_TEXT_TOOLBAR_BUTTON_UNSELECTED_VALUE_HOVER_STATE_BACKGROUND_COLOR": "rgb(230, 240, 255)",
  "RICH_TEXT_TOOLBAR_BUTTON_UNSELECTED_VALUE_HOVER_STATE_ICON_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "RICH_TEXT_TOOLBAR_BUTTON_UNSELECTED_VALUE_HOVER_STATE_ICON_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "RICH_TEXT_TOOLBAR_BUTTON_UNSELECTED_VALUE_HOVER_STATE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "RICH_TEXT_TOOLBAR_BUTTON_UNSELECTED_VALUE_PRESSED_STATE_BACKGROUND_LIGHT_MODE_COLOR": "rgb(204, 225, 255)",
  "RICH_TEXT_TOOLBAR_BUTTON_UNSELECTED_VALUE_PRESSED_STATE_BACKGROUND_DARK_MODE_COLOR": "rgb(0, 37, 89)",
  "RICH_TEXT_TOOLBAR_BUTTON_UNSELECTED_VALUE_PRESSED_STATE_BACKGROUND_COLOR": "rgb(204, 225, 255)",
  "RICH_TEXT_TOOLBAR_BUTTON_UNSELECTED_VALUE_PRESSED_STATE_ICON_LIGHT_MODE_COLOR": "rgb(0, 90, 217)",
  "RICH_TEXT_TOOLBAR_BUTTON_UNSELECTED_VALUE_PRESSED_STATE_ICON_DARK_MODE_COLOR": "rgb(74, 149, 255)",
  "RICH_TEXT_TOOLBAR_BUTTON_UNSELECTED_VALUE_PRESSED_STATE_ICON_COLOR": "rgb(0, 90, 217)",
  "RICH_TEXT_TOOLBAR_BUTTON_UNSELECTED_VALUE_FOCUS_STATE_BACKGROUND_LIGHT_MODE_COLOR": "rgb(230, 240, 255)",
  "RICH_TEXT_TOOLBAR_BUTTON_UNSELECTED_VALUE_FOCUS_STATE_BACKGROUND_DARK_MODE_COLOR": "rgb(0, 21, 51)",
  "RICH_TEXT_TOOLBAR_BUTTON_UNSELECTED_VALUE_FOCUS_STATE_BACKGROUND_COLOR": "rgb(230, 240, 255)",
  "RICH_TEXT_TOOLBAR_BUTTON_UNSELECTED_VALUE_FOCUS_STATE_ICON_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "RICH_TEXT_TOOLBAR_BUTTON_UNSELECTED_VALUE_FOCUS_STATE_ICON_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "RICH_TEXT_TOOLBAR_BUTTON_UNSELECTED_VALUE_FOCUS_STATE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "RICH_TEXT_TOOLBAR_BUTTON_UNSELECTED_VALUE_DISABLED_STATE_ICON_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.3)",
  "RICH_TEXT_TOOLBAR_BUTTON_UNSELECTED_VALUE_DISABLED_STATE_ICON_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.3)",
  "RICH_TEXT_TOOLBAR_BUTTON_UNSELECTED_VALUE_DISABLED_STATE_ICON_COLOR": "rgba(0, 0, 0, 0.3)",
  "RICH_TEXT_TOOLBAR_BUTTON_SELECTED_VALUE_NORMAL_STATE_BACKGROUND_LIGHT_MODE_COLOR": "rgb(230, 240, 255)",
  "RICH_TEXT_TOOLBAR_BUTTON_SELECTED_VALUE_NORMAL_STATE_BACKGROUND_DARK_MODE_COLOR": "rgb(0, 21, 51)",
  "RICH_TEXT_TOOLBAR_BUTTON_SELECTED_VALUE_NORMAL_STATE_BACKGROUND_COLOR": "rgb(230, 240, 255)",
  "RICH_TEXT_TOOLBAR_BUTTON_SELECTED_VALUE_NORMAL_STATE_ICON_LIGHT_MODE_COLOR": "rgb(0, 90, 217)",
  "RICH_TEXT_TOOLBAR_BUTTON_SELECTED_VALUE_NORMAL_STATE_ICON_DARK_MODE_COLOR": "rgb(74, 149, 255)",
  "RICH_TEXT_TOOLBAR_BUTTON_SELECTED_VALUE_NORMAL_STATE_ICON_COLOR": "rgb(0, 90, 217)",
  "RICH_TEXT_TOOLBAR_BUTTON_SELECTED_VALUE_HOVER_STATE_BACKGROUND_LIGHT_MODE_COLOR": "rgb(230, 240, 255)",
  "RICH_TEXT_TOOLBAR_BUTTON_SELECTED_VALUE_HOVER_STATE_BACKGROUND_DARK_MODE_COLOR": "rgb(0, 21, 51)",
  "RICH_TEXT_TOOLBAR_BUTTON_SELECTED_VALUE_HOVER_STATE_BACKGROUND_COLOR": "rgb(230, 240, 255)",
  "RICH_TEXT_TOOLBAR_BUTTON_SELECTED_VALUE_HOVER_STATE_ICON_LIGHT_MODE_COLOR": "rgb(0, 90, 217)",
  "RICH_TEXT_TOOLBAR_BUTTON_SELECTED_VALUE_HOVER_STATE_ICON_DARK_MODE_COLOR": "rgb(74, 149, 255)",
  "RICH_TEXT_TOOLBAR_BUTTON_SELECTED_VALUE_HOVER_STATE_ICON_COLOR": "rgb(0, 90, 217)",
  "RICH_TEXT_TOOLBAR_BUTTON_SELECTED_VALUE_PRESSED_STATE_BACKGROUND_LIGHT_MODE_COLOR": "rgb(204, 225, 255)",
  "RICH_TEXT_TOOLBAR_BUTTON_SELECTED_VALUE_PRESSED_STATE_BACKGROUND_DARK_MODE_COLOR": "rgb(0, 37, 89)",
  "RICH_TEXT_TOOLBAR_BUTTON_SELECTED_VALUE_PRESSED_STATE_BACKGROUND_COLOR": "rgb(204, 225, 255)",
  "RICH_TEXT_TOOLBAR_BUTTON_SELECTED_VALUE_PRESSED_STATE_ICON_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "RICH_TEXT_TOOLBAR_BUTTON_SELECTED_VALUE_PRESSED_STATE_ICON_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "RICH_TEXT_TOOLBAR_BUTTON_SELECTED_VALUE_PRESSED_STATE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "RICH_TEXT_TOOLBAR_BUTTON_SELECTED_VALUE_FOCUS_STATE_BACKGROUND_LIGHT_MODE_COLOR": "rgb(230, 240, 255)",
  "RICH_TEXT_TOOLBAR_BUTTON_SELECTED_VALUE_FOCUS_STATE_BACKGROUND_DARK_MODE_COLOR": "rgb(0, 21, 51)",
  "RICH_TEXT_TOOLBAR_BUTTON_SELECTED_VALUE_FOCUS_STATE_BACKGROUND_COLOR": "rgb(230, 240, 255)",
  "RICH_TEXT_TOOLBAR_BUTTON_SELECTED_VALUE_FOCUS_STATE_ICON_LIGHT_MODE_COLOR": "rgb(0, 90, 217)",
  "RICH_TEXT_TOOLBAR_BUTTON_SELECTED_VALUE_FOCUS_STATE_ICON_DARK_MODE_COLOR": "rgb(74, 149, 255)",
  "RICH_TEXT_TOOLBAR_BUTTON_SELECTED_VALUE_FOCUS_STATE_ICON_COLOR": "rgb(0, 90, 217)",
  "RICH_TEXT_TOOLBAR_BUTTON_SELECTED_VALUE_DISABLED_STATE_ICON_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.3)",
  "RICH_TEXT_TOOLBAR_BUTTON_SELECTED_VALUE_DISABLED_STATE_ICON_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.3)",
  "RICH_TEXT_TOOLBAR_BUTTON_SELECTED_VALUE_DISABLED_STATE_ICON_COLOR": "rgba(0, 0, 0, 0.3)",
  "RICH_TEXT_TOOLBAR_DIVIDER_VERTICAL_PADDING": 0,
  "RICH_TEXT_TOOLBAR_SPACING_HORIZONTAL": 12,
  "RICH_TEXT_TOOLBAR_PADDING_VERTICAL": 4,
  "RICH_TEXT_TOOLBAR_PADDING_HORIZONTAL": 4,
  "RICH_TEXT_TOOLBAR_BACKGROUND_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.03)",
  "RICH_TEXT_TOOLBAR_BACKGROUND_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.05)",
  "RICH_TEXT_TOOLBAR_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.03)",
  "RICH_TEXT_TOOLBAR_OVERFLOW_GRADIENT_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.05)",
  "RICH_TEXT_TOOLBAR_OVERFLOW_GRADIENT_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.08)",
  "RICH_TEXT_TOOLBAR_OVERFLOW_GRADIENT_WIDTH": 16,
  "RICH_TEXT_TOOLBAR_OVERFLOW_GRADIENT_COLOR": "rgba(0, 0, 0, 0.05)",
  "RICH_TEXT_LINK_EDIT_POPOVER_SPACING_VERTICAL": 16,
  "ROW_LIGHT_MODE_FOCUS_RING_COLOR": "rgb(0, 106, 255)",
  "ROW_DARK_MODE_FOCUS_RING_COLOR": "rgb(0, 106, 255)",
  "ROW_BACKGROUND_RADIUS": 6,
  "ROW_BACKGROUND_HORIZONTAL_OUTSET_PADDING": 8,
  "ROW_BACKGROUND_VERTICAL_OUTSET_PADDING": 0,
  "ROW_SEPARATOR_HEIGHT": 1,
  "ROW_NORMAL_STATE_TEXT_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "ROW_NORMAL_STATE_TEXT_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "ROW_NORMAL_STATE_TEXT_COLOR": "rgba(0, 0, 0, 0.9)",
  "ROW_NORMAL_STATE_SUBTEXT_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.55)",
  "ROW_NORMAL_STATE_SUBTEXT_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.55)",
  "ROW_NORMAL_STATE_SUBTEXT_COLOR": "rgba(0, 0, 0, 0.55)",
  "ROW_NORMAL_STATE_TERTIARY_TEXT_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.55)",
  "ROW_NORMAL_STATE_TERTIARY_TEXT_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.55)",
  "ROW_NORMAL_STATE_TERTIARY_TEXT_COLOR": "rgba(0, 0, 0, 0.55)",
  "ROW_NORMAL_STATE_SIDE_TEXT_PRIMARY_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "ROW_NORMAL_STATE_SIDE_TEXT_PRIMARY_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "ROW_NORMAL_STATE_SIDE_TEXT_PRIMARY_COLOR": "rgba(0, 0, 0, 0.9)",
  "ROW_NORMAL_STATE_SIDE_TEXT_SECONDARY_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.55)",
  "ROW_NORMAL_STATE_SIDE_TEXT_SECONDARY_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.55)",
  "ROW_NORMAL_STATE_SIDE_TEXT_SECONDARY_COLOR": "rgba(0, 0, 0, 0.55)",
  "ROW_NORMAL_STATE_DRILL_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.3)",
  "ROW_NORMAL_STATE_DRILL_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.3)",
  "ROW_NORMAL_STATE_DRILL_COLOR": "rgba(0, 0, 0, 0.3)",
  "ROW_NORMAL_STATE_ICON_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "ROW_NORMAL_STATE_ICON_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "ROW_NORMAL_STATE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "ROW_NORMAL_STATE_SEPARATOR_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.05)",
  "ROW_NORMAL_STATE_SEPARATOR_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.08)",
  "ROW_NORMAL_STATE_SEPARATOR_COLOR": "rgba(0, 0, 0, 0.05)",
  "ROW_NORMAL_STATE_BACKGROUND_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0)",
  "ROW_NORMAL_STATE_BACKGROUND_DARK_MODE_COLOR": "rgba(0, 0, 0, 0)",
  "ROW_NORMAL_STATE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "ROW_HOVER_STATE_TEXT_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "ROW_HOVER_STATE_TEXT_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "ROW_HOVER_STATE_TEXT_COLOR": "rgba(0, 0, 0, 0.9)",
  "ROW_HOVER_STATE_SUBTEXT_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.55)",
  "ROW_HOVER_STATE_SUBTEXT_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.55)",
  "ROW_HOVER_STATE_SUBTEXT_COLOR": "rgba(0, 0, 0, 0.55)",
  "ROW_HOVER_STATE_TERTIARY_TEXT_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.55)",
  "ROW_HOVER_STATE_TERTIARY_TEXT_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.55)",
  "ROW_HOVER_STATE_TERTIARY_TEXT_COLOR": "rgba(0, 0, 0, 0.55)",
  "ROW_HOVER_STATE_SIDE_TEXT_PRIMARY_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "ROW_HOVER_STATE_SIDE_TEXT_PRIMARY_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "ROW_HOVER_STATE_SIDE_TEXT_PRIMARY_COLOR": "rgba(0, 0, 0, 0.9)",
  "ROW_HOVER_STATE_SIDE_TEXT_SECONDARY_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.55)",
  "ROW_HOVER_STATE_SIDE_TEXT_SECONDARY_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.55)",
  "ROW_HOVER_STATE_SIDE_TEXT_SECONDARY_COLOR": "rgba(0, 0, 0, 0.55)",
  "ROW_HOVER_STATE_DRILL_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.3)",
  "ROW_HOVER_STATE_DRILL_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.3)",
  "ROW_HOVER_STATE_DRILL_COLOR": "rgba(0, 0, 0, 0.3)",
  "ROW_HOVER_STATE_ICON_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "ROW_HOVER_STATE_ICON_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "ROW_HOVER_STATE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "ROW_HOVER_STATE_SEPARATOR_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0)",
  "ROW_HOVER_STATE_SEPARATOR_DARK_MODE_COLOR": "rgba(0, 0, 0, 0)",
  "ROW_HOVER_STATE_SEPARATOR_COLOR": "rgba(0, 0, 0, 0)",
  "ROW_HOVER_STATE_BACKGROUND_LIGHT_MODE_COLOR": "rgb(230, 240, 255)",
  "ROW_HOVER_STATE_BACKGROUND_DARK_MODE_COLOR": "rgb(0, 21, 51)",
  "ROW_HOVER_STATE_BACKGROUND_COLOR": "rgb(230, 240, 255)",
  "ROW_PRESSED_STATE_TEXT_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "ROW_PRESSED_STATE_TEXT_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "ROW_PRESSED_STATE_TEXT_COLOR": "rgba(0, 0, 0, 0.9)",
  "ROW_PRESSED_STATE_SUBTEXT_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.55)",
  "ROW_PRESSED_STATE_SUBTEXT_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.55)",
  "ROW_PRESSED_STATE_SUBTEXT_COLOR": "rgba(0, 0, 0, 0.55)",
  "ROW_PRESSED_STATE_TERTIARY_TEXT_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.55)",
  "ROW_PRESSED_STATE_TERTIARY_TEXT_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.55)",
  "ROW_PRESSED_STATE_TERTIARY_TEXT_COLOR": "rgba(0, 0, 0, 0.55)",
  "ROW_PRESSED_STATE_SIDE_TEXT_PRIMARY_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "ROW_PRESSED_STATE_SIDE_TEXT_PRIMARY_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "ROW_PRESSED_STATE_SIDE_TEXT_PRIMARY_COLOR": "rgba(0, 0, 0, 0.9)",
  "ROW_PRESSED_STATE_SIDE_TEXT_SECONDARY_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.55)",
  "ROW_PRESSED_STATE_SIDE_TEXT_SECONDARY_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.55)",
  "ROW_PRESSED_STATE_SIDE_TEXT_SECONDARY_COLOR": "rgba(0, 0, 0, 0.55)",
  "ROW_PRESSED_STATE_DRILL_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.3)",
  "ROW_PRESSED_STATE_DRILL_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.3)",
  "ROW_PRESSED_STATE_DRILL_COLOR": "rgba(0, 0, 0, 0.3)",
  "ROW_PRESSED_STATE_ICON_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "ROW_PRESSED_STATE_ICON_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "ROW_PRESSED_STATE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "ROW_PRESSED_STATE_SEPARATOR_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0)",
  "ROW_PRESSED_STATE_SEPARATOR_DARK_MODE_COLOR": "rgba(0, 0, 0, 0)",
  "ROW_PRESSED_STATE_SEPARATOR_COLOR": "rgba(0, 0, 0, 0)",
  "ROW_PRESSED_STATE_BACKGROUND_LIGHT_MODE_COLOR": "rgb(204, 225, 255)",
  "ROW_PRESSED_STATE_BACKGROUND_DARK_MODE_COLOR": "rgb(0, 37, 89)",
  "ROW_PRESSED_STATE_BACKGROUND_COLOR": "rgb(204, 225, 255)",
  "ROW_SELECTED_STATE_TEXT_LIGHT_MODE_COLOR": "rgb(0, 90, 217)",
  "ROW_SELECTED_STATE_TEXT_DARK_MODE_COLOR": "rgb(74, 149, 255)",
  "ROW_SELECTED_STATE_TEXT_COLOR": "rgb(0, 90, 217)",
  "ROW_SELECTED_STATE_SUBTEXT_LIGHT_MODE_COLOR": "rgb(0, 90, 217)",
  "ROW_SELECTED_STATE_SUBTEXT_DARK_MODE_COLOR": "rgb(74, 149, 255)",
  "ROW_SELECTED_STATE_SUBTEXT_COLOR": "rgb(0, 90, 217)",
  "ROW_SELECTED_STATE_TERTIARY_TEXT_LIGHT_MODE_COLOR": "rgb(0, 90, 217)",
  "ROW_SELECTED_STATE_TERTIARY_TEXT_DARK_MODE_COLOR": "rgb(74, 149, 255)",
  "ROW_SELECTED_STATE_TERTIARY_TEXT_COLOR": "rgb(0, 90, 217)",
  "ROW_SELECTED_STATE_SIDE_TEXT_PRIMARY_LIGHT_MODE_COLOR": "rgb(0, 90, 217)",
  "ROW_SELECTED_STATE_SIDE_TEXT_PRIMARY_DARK_MODE_COLOR": "rgb(74, 149, 255)",
  "ROW_SELECTED_STATE_SIDE_TEXT_PRIMARY_COLOR": "rgb(0, 90, 217)",
  "ROW_SELECTED_STATE_SIDE_TEXT_SECONDARY_LIGHT_MODE_COLOR": "rgb(0, 90, 217)",
  "ROW_SELECTED_STATE_SIDE_TEXT_SECONDARY_DARK_MODE_COLOR": "rgb(74, 149, 255)",
  "ROW_SELECTED_STATE_SIDE_TEXT_SECONDARY_COLOR": "rgb(0, 90, 217)",
  "ROW_SELECTED_STATE_DRILL_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.3)",
  "ROW_SELECTED_STATE_DRILL_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.3)",
  "ROW_SELECTED_STATE_DRILL_COLOR": "rgba(0, 0, 0, 0.3)",
  "ROW_SELECTED_STATE_ICON_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "ROW_SELECTED_STATE_ICON_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "ROW_SELECTED_STATE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "ROW_SELECTED_STATE_SEPARATOR_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0)",
  "ROW_SELECTED_STATE_SEPARATOR_DARK_MODE_COLOR": "rgba(0, 0, 0, 0)",
  "ROW_SELECTED_STATE_SEPARATOR_COLOR": "rgba(0, 0, 0, 0)",
  "ROW_SELECTED_STATE_BACKGROUND_LIGHT_MODE_COLOR": "rgb(230, 240, 255)",
  "ROW_SELECTED_STATE_BACKGROUND_DARK_MODE_COLOR": "rgb(0, 21, 51)",
  "ROW_SELECTED_STATE_BACKGROUND_COLOR": "rgb(230, 240, 255)",
  "ROW_FOCUS_STATE_TEXT_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "ROW_FOCUS_STATE_TEXT_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "ROW_FOCUS_STATE_TEXT_COLOR": "rgba(0, 0, 0, 0.9)",
  "ROW_FOCUS_STATE_SUBTEXT_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.55)",
  "ROW_FOCUS_STATE_SUBTEXT_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.55)",
  "ROW_FOCUS_STATE_SUBTEXT_COLOR": "rgba(0, 0, 0, 0.55)",
  "ROW_FOCUS_STATE_TERTIARY_TEXT_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.55)",
  "ROW_FOCUS_STATE_TERTIARY_TEXT_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.55)",
  "ROW_FOCUS_STATE_TERTIARY_TEXT_COLOR": "rgba(0, 0, 0, 0.55)",
  "ROW_FOCUS_STATE_SIDE_TEXT_PRIMARY_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "ROW_FOCUS_STATE_SIDE_TEXT_PRIMARY_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "ROW_FOCUS_STATE_SIDE_TEXT_PRIMARY_COLOR": "rgba(0, 0, 0, 0.9)",
  "ROW_FOCUS_STATE_SIDE_TEXT_SECONDARY_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.55)",
  "ROW_FOCUS_STATE_SIDE_TEXT_SECONDARY_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.55)",
  "ROW_FOCUS_STATE_SIDE_TEXT_SECONDARY_COLOR": "rgba(0, 0, 0, 0.55)",
  "ROW_FOCUS_STATE_DRILL_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.3)",
  "ROW_FOCUS_STATE_DRILL_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.3)",
  "ROW_FOCUS_STATE_DRILL_COLOR": "rgba(0, 0, 0, 0.3)",
  "ROW_FOCUS_STATE_ICON_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "ROW_FOCUS_STATE_ICON_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "ROW_FOCUS_STATE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "ROW_FOCUS_STATE_SEPARATOR_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0)",
  "ROW_FOCUS_STATE_SEPARATOR_DARK_MODE_COLOR": "rgba(0, 0, 0, 0)",
  "ROW_FOCUS_STATE_SEPARATOR_COLOR": "rgba(0, 0, 0, 0)",
  "ROW_FOCUS_STATE_BACKGROUND_LIGHT_MODE_COLOR": "rgb(230, 240, 255)",
  "ROW_FOCUS_STATE_BACKGROUND_DARK_MODE_COLOR": "rgb(0, 21, 51)",
  "ROW_FOCUS_STATE_BACKGROUND_COLOR": "rgb(230, 240, 255)",
  "ROW_DISABLED_STATE_TEXT_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.3)",
  "ROW_DISABLED_STATE_TEXT_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.3)",
  "ROW_DISABLED_STATE_TEXT_COLOR": "rgba(0, 0, 0, 0.3)",
  "ROW_DISABLED_STATE_SUBTEXT_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.3)",
  "ROW_DISABLED_STATE_SUBTEXT_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.3)",
  "ROW_DISABLED_STATE_SUBTEXT_COLOR": "rgba(0, 0, 0, 0.3)",
  "ROW_DISABLED_STATE_TERTIARY_TEXT_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.3)",
  "ROW_DISABLED_STATE_TERTIARY_TEXT_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.3)",
  "ROW_DISABLED_STATE_TERTIARY_TEXT_COLOR": "rgba(0, 0, 0, 0.3)",
  "ROW_DISABLED_STATE_SIDE_TEXT_PRIMARY_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.3)",
  "ROW_DISABLED_STATE_SIDE_TEXT_PRIMARY_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.3)",
  "ROW_DISABLED_STATE_SIDE_TEXT_PRIMARY_COLOR": "rgba(0, 0, 0, 0.3)",
  "ROW_DISABLED_STATE_SIDE_TEXT_SECONDARY_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.3)",
  "ROW_DISABLED_STATE_SIDE_TEXT_SECONDARY_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.3)",
  "ROW_DISABLED_STATE_SIDE_TEXT_SECONDARY_COLOR": "rgba(0, 0, 0, 0.3)",
  "ROW_DISABLED_STATE_DRILL_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.15)",
  "ROW_DISABLED_STATE_DRILL_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.15)",
  "ROW_DISABLED_STATE_DRILL_COLOR": "rgba(0, 0, 0, 0.15)",
  "ROW_DISABLED_STATE_SEPARATOR_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.05)",
  "ROW_DISABLED_STATE_SEPARATOR_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.08)",
  "ROW_DISABLED_STATE_SEPARATOR_COLOR": "rgba(0, 0, 0, 0.05)",
  "ROW_DISABLED_STATE_BACKGROUND_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0)",
  "ROW_DISABLED_STATE_BACKGROUND_DARK_MODE_COLOR": "rgba(0, 0, 0, 0)",
  "ROW_DISABLED_STATE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "ROW_DISABLED_STATE_IMAGE_OPACITY": 0.4,
  "ROW_DISABLED_STATE_LEADING_ACCESSORY_OPACITY": 0.4,
  "ROW_HORIZONTAL_SPACING": 12,
  "ROW_TEXT_ACCESSORY_SPACING": 8,
  "ROW_MEDIUM_SIZE_TEXT_SIZE": 16,
  "ROW_MEDIUM_SIZE_TEXT_LEADING": 24,
  "ROW_MEDIUM_SIZE_TEXT_TRACKING": 0,
  "ROW_MEDIUM_SIZE_TEXT_WEIGHT": 500,
  "ROW_MEDIUM_SIZE_TEXT_CASE": "regular",
  "ROW_MEDIUM_SIZE_TEXT_NUMBER_SPACING": "default",
  "ROW_MEDIUM_SIZE_TEXT_SCALE": "paragraph-30",
  "ROW_MEDIUM_SIZE_TEXT_FONT_FAMILY": "Square Sans Text",
  "ROW_MEDIUM_SIZE_TEXT_VERTICAL_SPACING": 2,
  "ROW_MEDIUM_SIZE_SUBTEXT_SIZE": 14,
  "ROW_MEDIUM_SIZE_SUBTEXT_LEADING": 22,
  "ROW_MEDIUM_SIZE_SUBTEXT_TRACKING": 0,
  "ROW_MEDIUM_SIZE_SUBTEXT_WEIGHT": 400,
  "ROW_MEDIUM_SIZE_SUBTEXT_CASE": "regular",
  "ROW_MEDIUM_SIZE_SUBTEXT_NUMBER_SPACING": "default",
  "ROW_MEDIUM_SIZE_SUBTEXT_SCALE": "paragraph-20",
  "ROW_MEDIUM_SIZE_SUBTEXT_FONT_FAMILY": "Square Sans Text",
  "ROW_MEDIUM_SIZE_TERTIARY_TEXT_SIZE": 14,
  "ROW_MEDIUM_SIZE_TERTIARY_TEXT_LEADING": 22,
  "ROW_MEDIUM_SIZE_TERTIARY_TEXT_TRACKING": 0,
  "ROW_MEDIUM_SIZE_TERTIARY_TEXT_WEIGHT": 400,
  "ROW_MEDIUM_SIZE_TERTIARY_TEXT_CASE": "regular",
  "ROW_MEDIUM_SIZE_TERTIARY_TEXT_NUMBER_SPACING": "default",
  "ROW_MEDIUM_SIZE_TERTIARY_TEXT_SCALE": "paragraph-20",
  "ROW_MEDIUM_SIZE_TERTIARY_TEXT_FONT_FAMILY": "Square Sans Text",
  "ROW_MEDIUM_SIZE_SIDE_TEXT_PRIMARY_SIZE": 16,
  "ROW_MEDIUM_SIZE_SIDE_TEXT_PRIMARY_LEADING": 24,
  "ROW_MEDIUM_SIZE_SIDE_TEXT_PRIMARY_TRACKING": 0,
  "ROW_MEDIUM_SIZE_SIDE_TEXT_PRIMARY_WEIGHT": 400,
  "ROW_MEDIUM_SIZE_SIDE_TEXT_PRIMARY_CASE": "regular",
  "ROW_MEDIUM_SIZE_SIDE_TEXT_PRIMARY_NUMBER_SPACING": "default",
  "ROW_MEDIUM_SIZE_SIDE_TEXT_PRIMARY_SCALE": "paragraph-30",
  "ROW_MEDIUM_SIZE_SIDE_TEXT_PRIMARY_FONT_FAMILY": "Square Sans Text",
  "ROW_MEDIUM_SIZE_SIDE_TEXT_SECONDARY_SIZE": 14,
  "ROW_MEDIUM_SIZE_SIDE_TEXT_SECONDARY_LEADING": 22,
  "ROW_MEDIUM_SIZE_SIDE_TEXT_SECONDARY_TRACKING": 0,
  "ROW_MEDIUM_SIZE_SIDE_TEXT_SECONDARY_WEIGHT": 400,
  "ROW_MEDIUM_SIZE_SIDE_TEXT_SECONDARY_CASE": "regular",
  "ROW_MEDIUM_SIZE_SIDE_TEXT_SECONDARY_NUMBER_SPACING": "default",
  "ROW_MEDIUM_SIZE_SIDE_TEXT_SECONDARY_SCALE": "paragraph-20",
  "ROW_MEDIUM_SIZE_SIDE_TEXT_SECONDARY_FONT_FAMILY": "Square Sans Text",
  "ROW_MEDIUM_SIZE_VERTICAL_PADDING": 16,
  "ROW_MEDIUM_SIZE_CARET_ICON_ASSET": "chevron-right",
  "ROW_MEDIUM_SIZE_CARET_ICON_WIDTH": 16,
  "ROW_MEDIUM_SIZE_CARET_ICON_HEIGHT": 16,
  "ROW_MEDIUM_SIZE_CARET_ICON_TINTABLE": true,
  "ROW_SMALL_SIZE_TEXT_SIZE": 14,
  "ROW_SMALL_SIZE_TEXT_LEADING": 22,
  "ROW_SMALL_SIZE_TEXT_TRACKING": 0,
  "ROW_SMALL_SIZE_TEXT_WEIGHT": 600,
  "ROW_SMALL_SIZE_TEXT_CASE": "regular",
  "ROW_SMALL_SIZE_TEXT_NUMBER_SPACING": "default",
  "ROW_SMALL_SIZE_TEXT_SCALE": "paragraph-20",
  "ROW_SMALL_SIZE_TEXT_FONT_FAMILY": "Square Sans Text",
  "ROW_SMALL_SIZE_TEXT_VERTICAL_SPACING": 2,
  "ROW_SMALL_SIZE_SUBTEXT_SIZE": 12,
  "ROW_SMALL_SIZE_SUBTEXT_LEADING": 18,
  "ROW_SMALL_SIZE_SUBTEXT_TRACKING": 0,
  "ROW_SMALL_SIZE_SUBTEXT_WEIGHT": 400,
  "ROW_SMALL_SIZE_SUBTEXT_CASE": "regular",
  "ROW_SMALL_SIZE_SUBTEXT_NUMBER_SPACING": "default",
  "ROW_SMALL_SIZE_SUBTEXT_SCALE": "paragraph-10",
  "ROW_SMALL_SIZE_SUBTEXT_FONT_FAMILY": "Square Sans Text",
  "ROW_SMALL_SIZE_TERTIARY_TEXT_SIZE": 12,
  "ROW_SMALL_SIZE_TERTIARY_TEXT_LEADING": 18,
  "ROW_SMALL_SIZE_TERTIARY_TEXT_TRACKING": 0,
  "ROW_SMALL_SIZE_TERTIARY_TEXT_WEIGHT": 400,
  "ROW_SMALL_SIZE_TERTIARY_TEXT_CASE": "regular",
  "ROW_SMALL_SIZE_TERTIARY_TEXT_NUMBER_SPACING": "default",
  "ROW_SMALL_SIZE_TERTIARY_TEXT_SCALE": "paragraph-10",
  "ROW_SMALL_SIZE_TERTIARY_TEXT_FONT_FAMILY": "Square Sans Text",
  "ROW_SMALL_SIZE_SIDE_TEXT_PRIMARY_SIZE": 14,
  "ROW_SMALL_SIZE_SIDE_TEXT_PRIMARY_LEADING": 22,
  "ROW_SMALL_SIZE_SIDE_TEXT_PRIMARY_TRACKING": 0,
  "ROW_SMALL_SIZE_SIDE_TEXT_PRIMARY_WEIGHT": 400,
  "ROW_SMALL_SIZE_SIDE_TEXT_PRIMARY_CASE": "regular",
  "ROW_SMALL_SIZE_SIDE_TEXT_PRIMARY_NUMBER_SPACING": "default",
  "ROW_SMALL_SIZE_SIDE_TEXT_PRIMARY_SCALE": "paragraph-20",
  "ROW_SMALL_SIZE_SIDE_TEXT_PRIMARY_FONT_FAMILY": "Square Sans Text",
  "ROW_SMALL_SIZE_SIDE_TEXT_SECONDARY_SIZE": 12,
  "ROW_SMALL_SIZE_SIDE_TEXT_SECONDARY_LEADING": 18,
  "ROW_SMALL_SIZE_SIDE_TEXT_SECONDARY_TRACKING": 0,
  "ROW_SMALL_SIZE_SIDE_TEXT_SECONDARY_WEIGHT": 400,
  "ROW_SMALL_SIZE_SIDE_TEXT_SECONDARY_CASE": "regular",
  "ROW_SMALL_SIZE_SIDE_TEXT_SECONDARY_NUMBER_SPACING": "default",
  "ROW_SMALL_SIZE_SIDE_TEXT_SECONDARY_SCALE": "paragraph-10",
  "ROW_SMALL_SIZE_SIDE_TEXT_SECONDARY_FONT_FAMILY": "Square Sans Text",
  "ROW_SMALL_SIZE_VERTICAL_PADDING": 9,
  "ROW_SMALL_SIZE_CARET_ICON_ASSET": "chevron-right",
  "ROW_SMALL_SIZE_CARET_ICON_WIDTH": 16,
  "ROW_SMALL_SIZE_CARET_ICON_HEIGHT": 16,
  "ROW_SMALL_SIZE_CARET_ICON_TINTABLE": true,
  "ROW_FOCUS_RING_BUFFER_SIZE": 2,
  "ROW_FOCUS_RING_BORDER_SIZE": 2,
  "ROW_FOCUS_RING_RADIUS": 6,
  "ROW_FOCUS_RING_COLOR": "rgb(0, 106, 255)",
  "ROW_DESTRUCTIVE_VARIANT_LIGHT_MODE_FOCUS_RING_COLOR": "rgb(0, 106, 255)",
  "ROW_DESTRUCTIVE_VARIANT_DARK_MODE_FOCUS_RING_COLOR": "rgb(0, 106, 255)",
  "ROW_DESTRUCTIVE_VARIANT_BACKGROUND_RADIUS": 6,
  "ROW_DESTRUCTIVE_VARIANT_BACKGROUND_HORIZONTAL_OUTSET_PADDING": 8,
  "ROW_DESTRUCTIVE_VARIANT_BACKGROUND_VERTICAL_OUTSET_PADDING": 0,
  "ROW_DESTRUCTIVE_VARIANT_SEPARATOR_HEIGHT": 1,
  "ROW_DESTRUCTIVE_VARIANT_NORMAL_STATE_TEXT_LIGHT_MODE_COLOR": "rgb(191, 0, 32)",
  "ROW_DESTRUCTIVE_VARIANT_NORMAL_STATE_TEXT_DARK_MODE_COLOR": "rgb(255, 90, 118)",
  "ROW_DESTRUCTIVE_VARIANT_NORMAL_STATE_TEXT_COLOR": "rgb(191, 0, 32)",
  "ROW_DESTRUCTIVE_VARIANT_NORMAL_STATE_SUBTEXT_LIGHT_MODE_COLOR": "rgb(191, 0, 32)",
  "ROW_DESTRUCTIVE_VARIANT_NORMAL_STATE_SUBTEXT_DARK_MODE_COLOR": "rgb(255, 90, 118)",
  "ROW_DESTRUCTIVE_VARIANT_NORMAL_STATE_SUBTEXT_COLOR": "rgb(191, 0, 32)",
  "ROW_DESTRUCTIVE_VARIANT_NORMAL_STATE_TERTIARY_TEXT_LIGHT_MODE_COLOR": "rgb(191, 0, 32)",
  "ROW_DESTRUCTIVE_VARIANT_NORMAL_STATE_TERTIARY_TEXT_DARK_MODE_COLOR": "rgb(255, 90, 118)",
  "ROW_DESTRUCTIVE_VARIANT_NORMAL_STATE_TERTIARY_TEXT_COLOR": "rgb(191, 0, 32)",
  "ROW_DESTRUCTIVE_VARIANT_NORMAL_STATE_SIDE_TEXT_PRIMARY_LIGHT_MODE_COLOR": "rgb(191, 0, 32)",
  "ROW_DESTRUCTIVE_VARIANT_NORMAL_STATE_SIDE_TEXT_PRIMARY_DARK_MODE_COLOR": "rgb(255, 90, 118)",
  "ROW_DESTRUCTIVE_VARIANT_NORMAL_STATE_SIDE_TEXT_PRIMARY_COLOR": "rgb(191, 0, 32)",
  "ROW_DESTRUCTIVE_VARIANT_NORMAL_STATE_SIDE_TEXT_SECONDARY_LIGHT_MODE_COLOR": "rgb(191, 0, 32)",
  "ROW_DESTRUCTIVE_VARIANT_NORMAL_STATE_SIDE_TEXT_SECONDARY_DARK_MODE_COLOR": "rgb(255, 90, 118)",
  "ROW_DESTRUCTIVE_VARIANT_NORMAL_STATE_SIDE_TEXT_SECONDARY_COLOR": "rgb(191, 0, 32)",
  "ROW_DESTRUCTIVE_VARIANT_NORMAL_STATE_DRILL_LIGHT_MODE_COLOR": "rgb(178, 0, 30)",
  "ROW_DESTRUCTIVE_VARIANT_NORMAL_STATE_DRILL_DARK_MODE_COLOR": "rgb(178, 0, 30)",
  "ROW_DESTRUCTIVE_VARIANT_NORMAL_STATE_DRILL_COLOR": "rgb(178, 0, 30)",
  "ROW_DESTRUCTIVE_VARIANT_NORMAL_STATE_ICON_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "ROW_DESTRUCTIVE_VARIANT_NORMAL_STATE_ICON_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "ROW_DESTRUCTIVE_VARIANT_NORMAL_STATE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "ROW_DESTRUCTIVE_VARIANT_NORMAL_STATE_SEPARATOR_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.05)",
  "ROW_DESTRUCTIVE_VARIANT_NORMAL_STATE_SEPARATOR_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.08)",
  "ROW_DESTRUCTIVE_VARIANT_NORMAL_STATE_SEPARATOR_COLOR": "rgba(0, 0, 0, 0.05)",
  "ROW_DESTRUCTIVE_VARIANT_NORMAL_STATE_BACKGROUND_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0)",
  "ROW_DESTRUCTIVE_VARIANT_NORMAL_STATE_BACKGROUND_DARK_MODE_COLOR": "rgba(0, 0, 0, 0)",
  "ROW_DESTRUCTIVE_VARIANT_NORMAL_STATE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "ROW_DESTRUCTIVE_VARIANT_HOVER_STATE_TEXT_LIGHT_MODE_COLOR": "rgb(191, 0, 32)",
  "ROW_DESTRUCTIVE_VARIANT_HOVER_STATE_TEXT_DARK_MODE_COLOR": "rgb(255, 90, 118)",
  "ROW_DESTRUCTIVE_VARIANT_HOVER_STATE_TEXT_COLOR": "rgb(191, 0, 32)",
  "ROW_DESTRUCTIVE_VARIANT_HOVER_STATE_SUBTEXT_LIGHT_MODE_COLOR": "rgb(191, 0, 32)",
  "ROW_DESTRUCTIVE_VARIANT_HOVER_STATE_SUBTEXT_DARK_MODE_COLOR": "rgb(255, 90, 118)",
  "ROW_DESTRUCTIVE_VARIANT_HOVER_STATE_SUBTEXT_COLOR": "rgb(191, 0, 32)",
  "ROW_DESTRUCTIVE_VARIANT_HOVER_STATE_TERTIARY_TEXT_LIGHT_MODE_COLOR": "rgb(191, 0, 32)",
  "ROW_DESTRUCTIVE_VARIANT_HOVER_STATE_TERTIARY_TEXT_DARK_MODE_COLOR": "rgb(255, 90, 118)",
  "ROW_DESTRUCTIVE_VARIANT_HOVER_STATE_TERTIARY_TEXT_COLOR": "rgb(191, 0, 32)",
  "ROW_DESTRUCTIVE_VARIANT_HOVER_STATE_SIDE_TEXT_PRIMARY_LIGHT_MODE_COLOR": "rgb(191, 0, 32)",
  "ROW_DESTRUCTIVE_VARIANT_HOVER_STATE_SIDE_TEXT_PRIMARY_DARK_MODE_COLOR": "rgb(255, 90, 118)",
  "ROW_DESTRUCTIVE_VARIANT_HOVER_STATE_SIDE_TEXT_PRIMARY_COLOR": "rgb(191, 0, 32)",
  "ROW_DESTRUCTIVE_VARIANT_HOVER_STATE_SIDE_TEXT_SECONDARY_LIGHT_MODE_COLOR": "rgb(191, 0, 32)",
  "ROW_DESTRUCTIVE_VARIANT_HOVER_STATE_SIDE_TEXT_SECONDARY_DARK_MODE_COLOR": "rgb(255, 90, 118)",
  "ROW_DESTRUCTIVE_VARIANT_HOVER_STATE_SIDE_TEXT_SECONDARY_COLOR": "rgb(191, 0, 32)",
  "ROW_DESTRUCTIVE_VARIANT_HOVER_STATE_DRILL_LIGHT_MODE_COLOR": "rgb(178, 0, 30)",
  "ROW_DESTRUCTIVE_VARIANT_HOVER_STATE_DRILL_DARK_MODE_COLOR": "rgb(178, 0, 30)",
  "ROW_DESTRUCTIVE_VARIANT_HOVER_STATE_DRILL_COLOR": "rgb(178, 0, 30)",
  "ROW_DESTRUCTIVE_VARIANT_HOVER_STATE_ICON_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "ROW_DESTRUCTIVE_VARIANT_HOVER_STATE_ICON_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "ROW_DESTRUCTIVE_VARIANT_HOVER_STATE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "ROW_DESTRUCTIVE_VARIANT_HOVER_STATE_SEPARATOR_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0)",
  "ROW_DESTRUCTIVE_VARIANT_HOVER_STATE_SEPARATOR_DARK_MODE_COLOR": "rgba(0, 0, 0, 0)",
  "ROW_DESTRUCTIVE_VARIANT_HOVER_STATE_SEPARATOR_COLOR": "rgba(0, 0, 0, 0)",
  "ROW_DESTRUCTIVE_VARIANT_HOVER_STATE_BACKGROUND_LIGHT_MODE_COLOR": "rgb(255, 229, 234)",
  "ROW_DESTRUCTIVE_VARIANT_HOVER_STATE_BACKGROUND_DARK_MODE_COLOR": "rgb(51, 0, 9)",
  "ROW_DESTRUCTIVE_VARIANT_HOVER_STATE_BACKGROUND_COLOR": "rgb(255, 229, 234)",
  "ROW_DESTRUCTIVE_VARIANT_PRESSED_STATE_TEXT_LIGHT_MODE_COLOR": "rgb(191, 0, 32)",
  "ROW_DESTRUCTIVE_VARIANT_PRESSED_STATE_TEXT_DARK_MODE_COLOR": "rgb(255, 90, 118)",
  "ROW_DESTRUCTIVE_VARIANT_PRESSED_STATE_TEXT_COLOR": "rgb(191, 0, 32)",
  "ROW_DESTRUCTIVE_VARIANT_PRESSED_STATE_SUBTEXT_LIGHT_MODE_COLOR": "rgb(191, 0, 32)",
  "ROW_DESTRUCTIVE_VARIANT_PRESSED_STATE_SUBTEXT_DARK_MODE_COLOR": "rgb(255, 90, 118)",
  "ROW_DESTRUCTIVE_VARIANT_PRESSED_STATE_SUBTEXT_COLOR": "rgb(191, 0, 32)",
  "ROW_DESTRUCTIVE_VARIANT_PRESSED_STATE_TERTIARY_TEXT_LIGHT_MODE_COLOR": "rgb(191, 0, 32)",
  "ROW_DESTRUCTIVE_VARIANT_PRESSED_STATE_TERTIARY_TEXT_DARK_MODE_COLOR": "rgb(255, 90, 118)",
  "ROW_DESTRUCTIVE_VARIANT_PRESSED_STATE_TERTIARY_TEXT_COLOR": "rgb(191, 0, 32)",
  "ROW_DESTRUCTIVE_VARIANT_PRESSED_STATE_SIDE_TEXT_PRIMARY_LIGHT_MODE_COLOR": "rgb(191, 0, 32)",
  "ROW_DESTRUCTIVE_VARIANT_PRESSED_STATE_SIDE_TEXT_PRIMARY_DARK_MODE_COLOR": "rgb(255, 90, 118)",
  "ROW_DESTRUCTIVE_VARIANT_PRESSED_STATE_SIDE_TEXT_PRIMARY_COLOR": "rgb(191, 0, 32)",
  "ROW_DESTRUCTIVE_VARIANT_PRESSED_STATE_SIDE_TEXT_SECONDARY_LIGHT_MODE_COLOR": "rgb(191, 0, 32)",
  "ROW_DESTRUCTIVE_VARIANT_PRESSED_STATE_SIDE_TEXT_SECONDARY_DARK_MODE_COLOR": "rgb(255, 90, 118)",
  "ROW_DESTRUCTIVE_VARIANT_PRESSED_STATE_SIDE_TEXT_SECONDARY_COLOR": "rgb(191, 0, 32)",
  "ROW_DESTRUCTIVE_VARIANT_PRESSED_STATE_DRILL_LIGHT_MODE_COLOR": "rgb(178, 0, 30)",
  "ROW_DESTRUCTIVE_VARIANT_PRESSED_STATE_DRILL_DARK_MODE_COLOR": "rgb(178, 0, 30)",
  "ROW_DESTRUCTIVE_VARIANT_PRESSED_STATE_DRILL_COLOR": "rgb(178, 0, 30)",
  "ROW_DESTRUCTIVE_VARIANT_PRESSED_STATE_ICON_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "ROW_DESTRUCTIVE_VARIANT_PRESSED_STATE_ICON_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "ROW_DESTRUCTIVE_VARIANT_PRESSED_STATE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "ROW_DESTRUCTIVE_VARIANT_PRESSED_STATE_SEPARATOR_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.05)",
  "ROW_DESTRUCTIVE_VARIANT_PRESSED_STATE_SEPARATOR_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.08)",
  "ROW_DESTRUCTIVE_VARIANT_PRESSED_STATE_SEPARATOR_COLOR": "rgba(0, 0, 0, 0.05)",
  "ROW_DESTRUCTIVE_VARIANT_PRESSED_STATE_BACKGROUND_LIGHT_MODE_COLOR": "rgb(255, 204, 213)",
  "ROW_DESTRUCTIVE_VARIANT_PRESSED_STATE_BACKGROUND_DARK_MODE_COLOR": "rgb(89, 0, 15)",
  "ROW_DESTRUCTIVE_VARIANT_PRESSED_STATE_BACKGROUND_COLOR": "rgb(255, 204, 213)",
  "ROW_DESTRUCTIVE_VARIANT_SELECTED_STATE_TEXT_LIGHT_MODE_COLOR": "rgb(191, 0, 32)",
  "ROW_DESTRUCTIVE_VARIANT_SELECTED_STATE_TEXT_DARK_MODE_COLOR": "rgb(255, 90, 118)",
  "ROW_DESTRUCTIVE_VARIANT_SELECTED_STATE_TEXT_COLOR": "rgb(191, 0, 32)",
  "ROW_DESTRUCTIVE_VARIANT_SELECTED_STATE_SUBTEXT_LIGHT_MODE_COLOR": "rgb(191, 0, 32)",
  "ROW_DESTRUCTIVE_VARIANT_SELECTED_STATE_SUBTEXT_DARK_MODE_COLOR": "rgb(255, 90, 118)",
  "ROW_DESTRUCTIVE_VARIANT_SELECTED_STATE_SUBTEXT_COLOR": "rgb(191, 0, 32)",
  "ROW_DESTRUCTIVE_VARIANT_SELECTED_STATE_TERTIARY_TEXT_LIGHT_MODE_COLOR": "rgb(191, 0, 32)",
  "ROW_DESTRUCTIVE_VARIANT_SELECTED_STATE_TERTIARY_TEXT_DARK_MODE_COLOR": "rgb(255, 90, 118)",
  "ROW_DESTRUCTIVE_VARIANT_SELECTED_STATE_TERTIARY_TEXT_COLOR": "rgb(191, 0, 32)",
  "ROW_DESTRUCTIVE_VARIANT_SELECTED_STATE_SIDE_TEXT_PRIMARY_LIGHT_MODE_COLOR": "rgb(191, 0, 32)",
  "ROW_DESTRUCTIVE_VARIANT_SELECTED_STATE_SIDE_TEXT_PRIMARY_DARK_MODE_COLOR": "rgb(255, 90, 118)",
  "ROW_DESTRUCTIVE_VARIANT_SELECTED_STATE_SIDE_TEXT_PRIMARY_COLOR": "rgb(191, 0, 32)",
  "ROW_DESTRUCTIVE_VARIANT_SELECTED_STATE_SIDE_TEXT_SECONDARY_LIGHT_MODE_COLOR": "rgb(191, 0, 32)",
  "ROW_DESTRUCTIVE_VARIANT_SELECTED_STATE_SIDE_TEXT_SECONDARY_DARK_MODE_COLOR": "rgb(255, 90, 118)",
  "ROW_DESTRUCTIVE_VARIANT_SELECTED_STATE_SIDE_TEXT_SECONDARY_COLOR": "rgb(191, 0, 32)",
  "ROW_DESTRUCTIVE_VARIANT_SELECTED_STATE_DRILL_LIGHT_MODE_COLOR": "rgb(178, 0, 30)",
  "ROW_DESTRUCTIVE_VARIANT_SELECTED_STATE_DRILL_DARK_MODE_COLOR": "rgb(178, 0, 30)",
  "ROW_DESTRUCTIVE_VARIANT_SELECTED_STATE_DRILL_COLOR": "rgb(178, 0, 30)",
  "ROW_DESTRUCTIVE_VARIANT_SELECTED_STATE_ICON_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "ROW_DESTRUCTIVE_VARIANT_SELECTED_STATE_ICON_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "ROW_DESTRUCTIVE_VARIANT_SELECTED_STATE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "ROW_DESTRUCTIVE_VARIANT_SELECTED_STATE_SEPARATOR_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0)",
  "ROW_DESTRUCTIVE_VARIANT_SELECTED_STATE_SEPARATOR_DARK_MODE_COLOR": "rgba(0, 0, 0, 0)",
  "ROW_DESTRUCTIVE_VARIANT_SELECTED_STATE_SEPARATOR_COLOR": "rgba(0, 0, 0, 0)",
  "ROW_DESTRUCTIVE_VARIANT_SELECTED_STATE_BACKGROUND_LIGHT_MODE_COLOR": "rgb(255, 229, 234)",
  "ROW_DESTRUCTIVE_VARIANT_SELECTED_STATE_BACKGROUND_DARK_MODE_COLOR": "rgb(51, 0, 9)",
  "ROW_DESTRUCTIVE_VARIANT_SELECTED_STATE_BACKGROUND_COLOR": "rgb(255, 229, 234)",
  "ROW_DESTRUCTIVE_VARIANT_FOCUS_STATE_TEXT_LIGHT_MODE_COLOR": "rgb(191, 0, 32)",
  "ROW_DESTRUCTIVE_VARIANT_FOCUS_STATE_TEXT_DARK_MODE_COLOR": "rgb(255, 90, 118)",
  "ROW_DESTRUCTIVE_VARIANT_FOCUS_STATE_TEXT_COLOR": "rgb(191, 0, 32)",
  "ROW_DESTRUCTIVE_VARIANT_FOCUS_STATE_SUBTEXT_LIGHT_MODE_COLOR": "rgb(191, 0, 32)",
  "ROW_DESTRUCTIVE_VARIANT_FOCUS_STATE_SUBTEXT_DARK_MODE_COLOR": "rgb(255, 90, 118)",
  "ROW_DESTRUCTIVE_VARIANT_FOCUS_STATE_SUBTEXT_COLOR": "rgb(191, 0, 32)",
  "ROW_DESTRUCTIVE_VARIANT_FOCUS_STATE_TERTIARY_TEXT_LIGHT_MODE_COLOR": "rgb(191, 0, 32)",
  "ROW_DESTRUCTIVE_VARIANT_FOCUS_STATE_TERTIARY_TEXT_DARK_MODE_COLOR": "rgb(255, 90, 118)",
  "ROW_DESTRUCTIVE_VARIANT_FOCUS_STATE_TERTIARY_TEXT_COLOR": "rgb(191, 0, 32)",
  "ROW_DESTRUCTIVE_VARIANT_FOCUS_STATE_SIDE_TEXT_PRIMARY_LIGHT_MODE_COLOR": "rgb(191, 0, 32)",
  "ROW_DESTRUCTIVE_VARIANT_FOCUS_STATE_SIDE_TEXT_PRIMARY_DARK_MODE_COLOR": "rgb(255, 90, 118)",
  "ROW_DESTRUCTIVE_VARIANT_FOCUS_STATE_SIDE_TEXT_PRIMARY_COLOR": "rgb(191, 0, 32)",
  "ROW_DESTRUCTIVE_VARIANT_FOCUS_STATE_SIDE_TEXT_SECONDARY_LIGHT_MODE_COLOR": "rgb(191, 0, 32)",
  "ROW_DESTRUCTIVE_VARIANT_FOCUS_STATE_SIDE_TEXT_SECONDARY_DARK_MODE_COLOR": "rgb(255, 90, 118)",
  "ROW_DESTRUCTIVE_VARIANT_FOCUS_STATE_SIDE_TEXT_SECONDARY_COLOR": "rgb(191, 0, 32)",
  "ROW_DESTRUCTIVE_VARIANT_FOCUS_STATE_DRILL_LIGHT_MODE_COLOR": "rgb(178, 0, 30)",
  "ROW_DESTRUCTIVE_VARIANT_FOCUS_STATE_DRILL_DARK_MODE_COLOR": "rgb(178, 0, 30)",
  "ROW_DESTRUCTIVE_VARIANT_FOCUS_STATE_DRILL_COLOR": "rgb(178, 0, 30)",
  "ROW_DESTRUCTIVE_VARIANT_FOCUS_STATE_ICON_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "ROW_DESTRUCTIVE_VARIANT_FOCUS_STATE_ICON_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "ROW_DESTRUCTIVE_VARIANT_FOCUS_STATE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "ROW_DESTRUCTIVE_VARIANT_FOCUS_STATE_SEPARATOR_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0)",
  "ROW_DESTRUCTIVE_VARIANT_FOCUS_STATE_SEPARATOR_DARK_MODE_COLOR": "rgba(0, 0, 0, 0)",
  "ROW_DESTRUCTIVE_VARIANT_FOCUS_STATE_SEPARATOR_COLOR": "rgba(0, 0, 0, 0)",
  "ROW_DESTRUCTIVE_VARIANT_FOCUS_STATE_BACKGROUND_LIGHT_MODE_COLOR": "rgb(255, 229, 234)",
  "ROW_DESTRUCTIVE_VARIANT_FOCUS_STATE_BACKGROUND_DARK_MODE_COLOR": "rgb(51, 0, 9)",
  "ROW_DESTRUCTIVE_VARIANT_FOCUS_STATE_BACKGROUND_COLOR": "rgb(255, 229, 234)",
  "ROW_DESTRUCTIVE_VARIANT_DISABLED_STATE_TEXT_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.3)",
  "ROW_DESTRUCTIVE_VARIANT_DISABLED_STATE_TEXT_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.3)",
  "ROW_DESTRUCTIVE_VARIANT_DISABLED_STATE_TEXT_COLOR": "rgba(0, 0, 0, 0.3)",
  "ROW_DESTRUCTIVE_VARIANT_DISABLED_STATE_SUBTEXT_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.3)",
  "ROW_DESTRUCTIVE_VARIANT_DISABLED_STATE_SUBTEXT_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.3)",
  "ROW_DESTRUCTIVE_VARIANT_DISABLED_STATE_SUBTEXT_COLOR": "rgba(0, 0, 0, 0.3)",
  "ROW_DESTRUCTIVE_VARIANT_DISABLED_STATE_TERTIARY_TEXT_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.3)",
  "ROW_DESTRUCTIVE_VARIANT_DISABLED_STATE_TERTIARY_TEXT_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.3)",
  "ROW_DESTRUCTIVE_VARIANT_DISABLED_STATE_TERTIARY_TEXT_COLOR": "rgba(0, 0, 0, 0.3)",
  "ROW_DESTRUCTIVE_VARIANT_DISABLED_STATE_SIDE_TEXT_PRIMARY_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.3)",
  "ROW_DESTRUCTIVE_VARIANT_DISABLED_STATE_SIDE_TEXT_PRIMARY_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.3)",
  "ROW_DESTRUCTIVE_VARIANT_DISABLED_STATE_SIDE_TEXT_PRIMARY_COLOR": "rgba(0, 0, 0, 0.3)",
  "ROW_DESTRUCTIVE_VARIANT_DISABLED_STATE_SIDE_TEXT_SECONDARY_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.3)",
  "ROW_DESTRUCTIVE_VARIANT_DISABLED_STATE_SIDE_TEXT_SECONDARY_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.3)",
  "ROW_DESTRUCTIVE_VARIANT_DISABLED_STATE_SIDE_TEXT_SECONDARY_COLOR": "rgba(0, 0, 0, 0.3)",
  "ROW_DESTRUCTIVE_VARIANT_DISABLED_STATE_DRILL_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.15)",
  "ROW_DESTRUCTIVE_VARIANT_DISABLED_STATE_DRILL_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.15)",
  "ROW_DESTRUCTIVE_VARIANT_DISABLED_STATE_DRILL_COLOR": "rgba(0, 0, 0, 0.15)",
  "ROW_DESTRUCTIVE_VARIANT_DISABLED_STATE_SEPARATOR_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.05)",
  "ROW_DESTRUCTIVE_VARIANT_DISABLED_STATE_SEPARATOR_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.08)",
  "ROW_DESTRUCTIVE_VARIANT_DISABLED_STATE_SEPARATOR_COLOR": "rgba(0, 0, 0, 0.05)",
  "ROW_DESTRUCTIVE_VARIANT_DISABLED_STATE_BACKGROUND_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0)",
  "ROW_DESTRUCTIVE_VARIANT_DISABLED_STATE_BACKGROUND_DARK_MODE_COLOR": "rgba(0, 0, 0, 0)",
  "ROW_DESTRUCTIVE_VARIANT_DISABLED_STATE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "ROW_DESTRUCTIVE_VARIANT_DISABLED_STATE_IMAGE_OPACITY": 0.4,
  "ROW_DESTRUCTIVE_VARIANT_DISABLED_STATE_LEADING_ACCESSORY_OPACITY": 0.4,
  "ROW_DESTRUCTIVE_VARIANT_FOCUS_RING_COLOR": "rgb(0, 106, 255)",
  "ROW_NORMAL_VARIANT_LIGHT_MODE_FOCUS_RING_COLOR": "rgb(0, 106, 255)",
  "ROW_NORMAL_VARIANT_DARK_MODE_FOCUS_RING_COLOR": "rgb(0, 106, 255)",
  "ROW_NORMAL_VARIANT_BACKGROUND_RADIUS": 6,
  "ROW_NORMAL_VARIANT_BACKGROUND_HORIZONTAL_OUTSET_PADDING": 8,
  "ROW_NORMAL_VARIANT_BACKGROUND_VERTICAL_OUTSET_PADDING": 0,
  "ROW_NORMAL_VARIANT_SEPARATOR_HEIGHT": 1,
  "ROW_NORMAL_VARIANT_NORMAL_STATE_TEXT_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "ROW_NORMAL_VARIANT_NORMAL_STATE_TEXT_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "ROW_NORMAL_VARIANT_NORMAL_STATE_TEXT_COLOR": "rgba(0, 0, 0, 0.9)",
  "ROW_NORMAL_VARIANT_NORMAL_STATE_SUBTEXT_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.55)",
  "ROW_NORMAL_VARIANT_NORMAL_STATE_SUBTEXT_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.55)",
  "ROW_NORMAL_VARIANT_NORMAL_STATE_SUBTEXT_COLOR": "rgba(0, 0, 0, 0.55)",
  "ROW_NORMAL_VARIANT_NORMAL_STATE_TERTIARY_TEXT_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.55)",
  "ROW_NORMAL_VARIANT_NORMAL_STATE_TERTIARY_TEXT_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.55)",
  "ROW_NORMAL_VARIANT_NORMAL_STATE_TERTIARY_TEXT_COLOR": "rgba(0, 0, 0, 0.55)",
  "ROW_NORMAL_VARIANT_NORMAL_STATE_SIDE_TEXT_PRIMARY_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "ROW_NORMAL_VARIANT_NORMAL_STATE_SIDE_TEXT_PRIMARY_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "ROW_NORMAL_VARIANT_NORMAL_STATE_SIDE_TEXT_PRIMARY_COLOR": "rgba(0, 0, 0, 0.9)",
  "ROW_NORMAL_VARIANT_NORMAL_STATE_SIDE_TEXT_SECONDARY_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.55)",
  "ROW_NORMAL_VARIANT_NORMAL_STATE_SIDE_TEXT_SECONDARY_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.55)",
  "ROW_NORMAL_VARIANT_NORMAL_STATE_SIDE_TEXT_SECONDARY_COLOR": "rgba(0, 0, 0, 0.55)",
  "ROW_NORMAL_VARIANT_NORMAL_STATE_DRILL_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.3)",
  "ROW_NORMAL_VARIANT_NORMAL_STATE_DRILL_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.3)",
  "ROW_NORMAL_VARIANT_NORMAL_STATE_DRILL_COLOR": "rgba(0, 0, 0, 0.3)",
  "ROW_NORMAL_VARIANT_NORMAL_STATE_ICON_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "ROW_NORMAL_VARIANT_NORMAL_STATE_ICON_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "ROW_NORMAL_VARIANT_NORMAL_STATE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "ROW_NORMAL_VARIANT_NORMAL_STATE_SEPARATOR_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.05)",
  "ROW_NORMAL_VARIANT_NORMAL_STATE_SEPARATOR_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.08)",
  "ROW_NORMAL_VARIANT_NORMAL_STATE_SEPARATOR_COLOR": "rgba(0, 0, 0, 0.05)",
  "ROW_NORMAL_VARIANT_NORMAL_STATE_BACKGROUND_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0)",
  "ROW_NORMAL_VARIANT_NORMAL_STATE_BACKGROUND_DARK_MODE_COLOR": "rgba(0, 0, 0, 0)",
  "ROW_NORMAL_VARIANT_NORMAL_STATE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "ROW_NORMAL_VARIANT_HOVER_STATE_TEXT_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "ROW_NORMAL_VARIANT_HOVER_STATE_TEXT_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "ROW_NORMAL_VARIANT_HOVER_STATE_TEXT_COLOR": "rgba(0, 0, 0, 0.9)",
  "ROW_NORMAL_VARIANT_HOVER_STATE_SUBTEXT_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.55)",
  "ROW_NORMAL_VARIANT_HOVER_STATE_SUBTEXT_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.55)",
  "ROW_NORMAL_VARIANT_HOVER_STATE_SUBTEXT_COLOR": "rgba(0, 0, 0, 0.55)",
  "ROW_NORMAL_VARIANT_HOVER_STATE_TERTIARY_TEXT_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.55)",
  "ROW_NORMAL_VARIANT_HOVER_STATE_TERTIARY_TEXT_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.55)",
  "ROW_NORMAL_VARIANT_HOVER_STATE_TERTIARY_TEXT_COLOR": "rgba(0, 0, 0, 0.55)",
  "ROW_NORMAL_VARIANT_HOVER_STATE_SIDE_TEXT_PRIMARY_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "ROW_NORMAL_VARIANT_HOVER_STATE_SIDE_TEXT_PRIMARY_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "ROW_NORMAL_VARIANT_HOVER_STATE_SIDE_TEXT_PRIMARY_COLOR": "rgba(0, 0, 0, 0.9)",
  "ROW_NORMAL_VARIANT_HOVER_STATE_SIDE_TEXT_SECONDARY_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.55)",
  "ROW_NORMAL_VARIANT_HOVER_STATE_SIDE_TEXT_SECONDARY_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.55)",
  "ROW_NORMAL_VARIANT_HOVER_STATE_SIDE_TEXT_SECONDARY_COLOR": "rgba(0, 0, 0, 0.55)",
  "ROW_NORMAL_VARIANT_HOVER_STATE_DRILL_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.3)",
  "ROW_NORMAL_VARIANT_HOVER_STATE_DRILL_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.3)",
  "ROW_NORMAL_VARIANT_HOVER_STATE_DRILL_COLOR": "rgba(0, 0, 0, 0.3)",
  "ROW_NORMAL_VARIANT_HOVER_STATE_ICON_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "ROW_NORMAL_VARIANT_HOVER_STATE_ICON_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "ROW_NORMAL_VARIANT_HOVER_STATE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "ROW_NORMAL_VARIANT_HOVER_STATE_SEPARATOR_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0)",
  "ROW_NORMAL_VARIANT_HOVER_STATE_SEPARATOR_DARK_MODE_COLOR": "rgba(0, 0, 0, 0)",
  "ROW_NORMAL_VARIANT_HOVER_STATE_SEPARATOR_COLOR": "rgba(0, 0, 0, 0)",
  "ROW_NORMAL_VARIANT_HOVER_STATE_BACKGROUND_LIGHT_MODE_COLOR": "rgb(230, 240, 255)",
  "ROW_NORMAL_VARIANT_HOVER_STATE_BACKGROUND_DARK_MODE_COLOR": "rgb(0, 21, 51)",
  "ROW_NORMAL_VARIANT_HOVER_STATE_BACKGROUND_COLOR": "rgb(230, 240, 255)",
  "ROW_NORMAL_VARIANT_PRESSED_STATE_TEXT_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "ROW_NORMAL_VARIANT_PRESSED_STATE_TEXT_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "ROW_NORMAL_VARIANT_PRESSED_STATE_TEXT_COLOR": "rgba(0, 0, 0, 0.9)",
  "ROW_NORMAL_VARIANT_PRESSED_STATE_SUBTEXT_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.55)",
  "ROW_NORMAL_VARIANT_PRESSED_STATE_SUBTEXT_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.55)",
  "ROW_NORMAL_VARIANT_PRESSED_STATE_SUBTEXT_COLOR": "rgba(0, 0, 0, 0.55)",
  "ROW_NORMAL_VARIANT_PRESSED_STATE_TERTIARY_TEXT_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.55)",
  "ROW_NORMAL_VARIANT_PRESSED_STATE_TERTIARY_TEXT_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.55)",
  "ROW_NORMAL_VARIANT_PRESSED_STATE_TERTIARY_TEXT_COLOR": "rgba(0, 0, 0, 0.55)",
  "ROW_NORMAL_VARIANT_PRESSED_STATE_SIDE_TEXT_PRIMARY_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "ROW_NORMAL_VARIANT_PRESSED_STATE_SIDE_TEXT_PRIMARY_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "ROW_NORMAL_VARIANT_PRESSED_STATE_SIDE_TEXT_PRIMARY_COLOR": "rgba(0, 0, 0, 0.9)",
  "ROW_NORMAL_VARIANT_PRESSED_STATE_SIDE_TEXT_SECONDARY_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.55)",
  "ROW_NORMAL_VARIANT_PRESSED_STATE_SIDE_TEXT_SECONDARY_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.55)",
  "ROW_NORMAL_VARIANT_PRESSED_STATE_SIDE_TEXT_SECONDARY_COLOR": "rgba(0, 0, 0, 0.55)",
  "ROW_NORMAL_VARIANT_PRESSED_STATE_DRILL_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.3)",
  "ROW_NORMAL_VARIANT_PRESSED_STATE_DRILL_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.3)",
  "ROW_NORMAL_VARIANT_PRESSED_STATE_DRILL_COLOR": "rgba(0, 0, 0, 0.3)",
  "ROW_NORMAL_VARIANT_PRESSED_STATE_ICON_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "ROW_NORMAL_VARIANT_PRESSED_STATE_ICON_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "ROW_NORMAL_VARIANT_PRESSED_STATE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "ROW_NORMAL_VARIANT_PRESSED_STATE_SEPARATOR_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0)",
  "ROW_NORMAL_VARIANT_PRESSED_STATE_SEPARATOR_DARK_MODE_COLOR": "rgba(0, 0, 0, 0)",
  "ROW_NORMAL_VARIANT_PRESSED_STATE_SEPARATOR_COLOR": "rgba(0, 0, 0, 0)",
  "ROW_NORMAL_VARIANT_PRESSED_STATE_BACKGROUND_LIGHT_MODE_COLOR": "rgb(204, 225, 255)",
  "ROW_NORMAL_VARIANT_PRESSED_STATE_BACKGROUND_DARK_MODE_COLOR": "rgb(0, 37, 89)",
  "ROW_NORMAL_VARIANT_PRESSED_STATE_BACKGROUND_COLOR": "rgb(204, 225, 255)",
  "ROW_NORMAL_VARIANT_SELECTED_STATE_TEXT_LIGHT_MODE_COLOR": "rgb(0, 90, 217)",
  "ROW_NORMAL_VARIANT_SELECTED_STATE_TEXT_DARK_MODE_COLOR": "rgb(74, 149, 255)",
  "ROW_NORMAL_VARIANT_SELECTED_STATE_TEXT_COLOR": "rgb(0, 90, 217)",
  "ROW_NORMAL_VARIANT_SELECTED_STATE_SUBTEXT_LIGHT_MODE_COLOR": "rgb(0, 90, 217)",
  "ROW_NORMAL_VARIANT_SELECTED_STATE_SUBTEXT_DARK_MODE_COLOR": "rgb(74, 149, 255)",
  "ROW_NORMAL_VARIANT_SELECTED_STATE_SUBTEXT_COLOR": "rgb(0, 90, 217)",
  "ROW_NORMAL_VARIANT_SELECTED_STATE_TERTIARY_TEXT_LIGHT_MODE_COLOR": "rgb(0, 90, 217)",
  "ROW_NORMAL_VARIANT_SELECTED_STATE_TERTIARY_TEXT_DARK_MODE_COLOR": "rgb(74, 149, 255)",
  "ROW_NORMAL_VARIANT_SELECTED_STATE_TERTIARY_TEXT_COLOR": "rgb(0, 90, 217)",
  "ROW_NORMAL_VARIANT_SELECTED_STATE_SIDE_TEXT_PRIMARY_LIGHT_MODE_COLOR": "rgb(0, 90, 217)",
  "ROW_NORMAL_VARIANT_SELECTED_STATE_SIDE_TEXT_PRIMARY_DARK_MODE_COLOR": "rgb(74, 149, 255)",
  "ROW_NORMAL_VARIANT_SELECTED_STATE_SIDE_TEXT_PRIMARY_COLOR": "rgb(0, 90, 217)",
  "ROW_NORMAL_VARIANT_SELECTED_STATE_SIDE_TEXT_SECONDARY_LIGHT_MODE_COLOR": "rgb(0, 90, 217)",
  "ROW_NORMAL_VARIANT_SELECTED_STATE_SIDE_TEXT_SECONDARY_DARK_MODE_COLOR": "rgb(74, 149, 255)",
  "ROW_NORMAL_VARIANT_SELECTED_STATE_SIDE_TEXT_SECONDARY_COLOR": "rgb(0, 90, 217)",
  "ROW_NORMAL_VARIANT_SELECTED_STATE_DRILL_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.3)",
  "ROW_NORMAL_VARIANT_SELECTED_STATE_DRILL_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.3)",
  "ROW_NORMAL_VARIANT_SELECTED_STATE_DRILL_COLOR": "rgba(0, 0, 0, 0.3)",
  "ROW_NORMAL_VARIANT_SELECTED_STATE_ICON_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "ROW_NORMAL_VARIANT_SELECTED_STATE_ICON_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "ROW_NORMAL_VARIANT_SELECTED_STATE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "ROW_NORMAL_VARIANT_SELECTED_STATE_SEPARATOR_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0)",
  "ROW_NORMAL_VARIANT_SELECTED_STATE_SEPARATOR_DARK_MODE_COLOR": "rgba(0, 0, 0, 0)",
  "ROW_NORMAL_VARIANT_SELECTED_STATE_SEPARATOR_COLOR": "rgba(0, 0, 0, 0)",
  "ROW_NORMAL_VARIANT_SELECTED_STATE_BACKGROUND_LIGHT_MODE_COLOR": "rgb(230, 240, 255)",
  "ROW_NORMAL_VARIANT_SELECTED_STATE_BACKGROUND_DARK_MODE_COLOR": "rgb(0, 21, 51)",
  "ROW_NORMAL_VARIANT_SELECTED_STATE_BACKGROUND_COLOR": "rgb(230, 240, 255)",
  "ROW_NORMAL_VARIANT_FOCUS_STATE_TEXT_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "ROW_NORMAL_VARIANT_FOCUS_STATE_TEXT_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "ROW_NORMAL_VARIANT_FOCUS_STATE_TEXT_COLOR": "rgba(0, 0, 0, 0.9)",
  "ROW_NORMAL_VARIANT_FOCUS_STATE_SUBTEXT_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.55)",
  "ROW_NORMAL_VARIANT_FOCUS_STATE_SUBTEXT_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.55)",
  "ROW_NORMAL_VARIANT_FOCUS_STATE_SUBTEXT_COLOR": "rgba(0, 0, 0, 0.55)",
  "ROW_NORMAL_VARIANT_FOCUS_STATE_TERTIARY_TEXT_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.55)",
  "ROW_NORMAL_VARIANT_FOCUS_STATE_TERTIARY_TEXT_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.55)",
  "ROW_NORMAL_VARIANT_FOCUS_STATE_TERTIARY_TEXT_COLOR": "rgba(0, 0, 0, 0.55)",
  "ROW_NORMAL_VARIANT_FOCUS_STATE_SIDE_TEXT_PRIMARY_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "ROW_NORMAL_VARIANT_FOCUS_STATE_SIDE_TEXT_PRIMARY_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "ROW_NORMAL_VARIANT_FOCUS_STATE_SIDE_TEXT_PRIMARY_COLOR": "rgba(0, 0, 0, 0.9)",
  "ROW_NORMAL_VARIANT_FOCUS_STATE_SIDE_TEXT_SECONDARY_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.55)",
  "ROW_NORMAL_VARIANT_FOCUS_STATE_SIDE_TEXT_SECONDARY_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.55)",
  "ROW_NORMAL_VARIANT_FOCUS_STATE_SIDE_TEXT_SECONDARY_COLOR": "rgba(0, 0, 0, 0.55)",
  "ROW_NORMAL_VARIANT_FOCUS_STATE_DRILL_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.3)",
  "ROW_NORMAL_VARIANT_FOCUS_STATE_DRILL_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.3)",
  "ROW_NORMAL_VARIANT_FOCUS_STATE_DRILL_COLOR": "rgba(0, 0, 0, 0.3)",
  "ROW_NORMAL_VARIANT_FOCUS_STATE_ICON_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "ROW_NORMAL_VARIANT_FOCUS_STATE_ICON_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "ROW_NORMAL_VARIANT_FOCUS_STATE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "ROW_NORMAL_VARIANT_FOCUS_STATE_SEPARATOR_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0)",
  "ROW_NORMAL_VARIANT_FOCUS_STATE_SEPARATOR_DARK_MODE_COLOR": "rgba(0, 0, 0, 0)",
  "ROW_NORMAL_VARIANT_FOCUS_STATE_SEPARATOR_COLOR": "rgba(0, 0, 0, 0)",
  "ROW_NORMAL_VARIANT_FOCUS_STATE_BACKGROUND_LIGHT_MODE_COLOR": "rgb(230, 240, 255)",
  "ROW_NORMAL_VARIANT_FOCUS_STATE_BACKGROUND_DARK_MODE_COLOR": "rgb(0, 21, 51)",
  "ROW_NORMAL_VARIANT_FOCUS_STATE_BACKGROUND_COLOR": "rgb(230, 240, 255)",
  "ROW_NORMAL_VARIANT_DISABLED_STATE_TEXT_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.3)",
  "ROW_NORMAL_VARIANT_DISABLED_STATE_TEXT_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.3)",
  "ROW_NORMAL_VARIANT_DISABLED_STATE_TEXT_COLOR": "rgba(0, 0, 0, 0.3)",
  "ROW_NORMAL_VARIANT_DISABLED_STATE_SUBTEXT_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.3)",
  "ROW_NORMAL_VARIANT_DISABLED_STATE_SUBTEXT_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.3)",
  "ROW_NORMAL_VARIANT_DISABLED_STATE_SUBTEXT_COLOR": "rgba(0, 0, 0, 0.3)",
  "ROW_NORMAL_VARIANT_DISABLED_STATE_TERTIARY_TEXT_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.3)",
  "ROW_NORMAL_VARIANT_DISABLED_STATE_TERTIARY_TEXT_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.3)",
  "ROW_NORMAL_VARIANT_DISABLED_STATE_TERTIARY_TEXT_COLOR": "rgba(0, 0, 0, 0.3)",
  "ROW_NORMAL_VARIANT_DISABLED_STATE_SIDE_TEXT_PRIMARY_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.3)",
  "ROW_NORMAL_VARIANT_DISABLED_STATE_SIDE_TEXT_PRIMARY_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.3)",
  "ROW_NORMAL_VARIANT_DISABLED_STATE_SIDE_TEXT_PRIMARY_COLOR": "rgba(0, 0, 0, 0.3)",
  "ROW_NORMAL_VARIANT_DISABLED_STATE_SIDE_TEXT_SECONDARY_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.3)",
  "ROW_NORMAL_VARIANT_DISABLED_STATE_SIDE_TEXT_SECONDARY_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.3)",
  "ROW_NORMAL_VARIANT_DISABLED_STATE_SIDE_TEXT_SECONDARY_COLOR": "rgba(0, 0, 0, 0.3)",
  "ROW_NORMAL_VARIANT_DISABLED_STATE_DRILL_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.15)",
  "ROW_NORMAL_VARIANT_DISABLED_STATE_DRILL_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.15)",
  "ROW_NORMAL_VARIANT_DISABLED_STATE_DRILL_COLOR": "rgba(0, 0, 0, 0.15)",
  "ROW_NORMAL_VARIANT_DISABLED_STATE_SEPARATOR_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.05)",
  "ROW_NORMAL_VARIANT_DISABLED_STATE_SEPARATOR_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.08)",
  "ROW_NORMAL_VARIANT_DISABLED_STATE_SEPARATOR_COLOR": "rgba(0, 0, 0, 0.05)",
  "ROW_NORMAL_VARIANT_DISABLED_STATE_BACKGROUND_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0)",
  "ROW_NORMAL_VARIANT_DISABLED_STATE_BACKGROUND_DARK_MODE_COLOR": "rgba(0, 0, 0, 0)",
  "ROW_NORMAL_VARIANT_DISABLED_STATE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0)",
  "ROW_NORMAL_VARIANT_DISABLED_STATE_IMAGE_OPACITY": 0.4,
  "ROW_NORMAL_VARIANT_DISABLED_STATE_LEADING_ACCESSORY_OPACITY": 0.4,
  "ROW_NORMAL_VARIANT_FOCUS_RING_COLOR": "rgb(0, 106, 255)",
  "SEARCH_BORDER_RADIUS": 6,
  "SEARCH_BORDER_SIZE": 1,
  "SEARCH_INPUT_SIZE": 16,
  "SEARCH_INPUT_LEADING": 24,
  "SEARCH_INPUT_TRACKING": 0,
  "SEARCH_INPUT_WEIGHT": 400,
  "SEARCH_INPUT_CASE": "regular",
  "SEARCH_INPUT_NUMBER_SPACING": "default",
  "SEARCH_INPUT_SCALE": "paragraph-30",
  "SEARCH_INPUT_FONT_FAMILY": "Square Sans Text",
  "SEARCH_HINT_TEXT_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.3)",
  "SEARCH_HINT_TEXT_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.3)",
  "SEARCH_HINT_TEXT_COLOR": "rgba(0, 0, 0, 0.3)",
  "SEARCH_NORMAL_SIZE_MINIMUM_HEIGHT": 40,
  "SEARCH_NORMAL_SIZE_SPACING_HORIZONTAL": 12,
  "SEARCH_NORMAL_SIZE_PADDING_VERTICAL": 8,
  "SEARCH_NORMAL_SIZE_TAGS_SPACING_RIGHT": 8,
  "SEARCH_LARGE_SIZE_MINIMUM_HEIGHT": 56,
  "SEARCH_LARGE_SIZE_SPACING_HORIZONTAL": 12,
  "SEARCH_LARGE_SIZE_PADDING_VERTICAL": 12,
  "SEARCH_LARGE_SIZE_TAGS_SPACING_RIGHT": 8,
  "SEARCH_NORMAL_STATE_INPUT_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "SEARCH_NORMAL_STATE_INPUT_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "SEARCH_NORMAL_STATE_INPUT_COLOR": "rgba(0, 0, 0, 0.9)",
  "SEARCH_NORMAL_STATE_LEADING_ICON_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "SEARCH_NORMAL_STATE_LEADING_ICON_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "SEARCH_NORMAL_STATE_LEADING_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "SEARCH_NORMAL_STATE_TRAILING_ICON_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.3)",
  "SEARCH_NORMAL_STATE_TRAILING_ICON_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.3)",
  "SEARCH_NORMAL_STATE_TRAILING_ICON_COLOR": "rgba(0, 0, 0, 0.3)",
  "SEARCH_NORMAL_STATE_BORDER_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.15)",
  "SEARCH_NORMAL_STATE_BORDER_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.15)",
  "SEARCH_NORMAL_STATE_BORDER_COLOR": "rgba(0, 0, 0, 0.15)",
  "SEARCH_DISABLED_STATE_INPUT_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.3)",
  "SEARCH_DISABLED_STATE_INPUT_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.3)",
  "SEARCH_DISABLED_STATE_INPUT_COLOR": "rgba(0, 0, 0, 0.3)",
  "SEARCH_DISABLED_STATE_LEADING_ICON_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.3)",
  "SEARCH_DISABLED_STATE_LEADING_ICON_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.3)",
  "SEARCH_DISABLED_STATE_LEADING_ICON_COLOR": "rgba(0, 0, 0, 0.3)",
  "SEARCH_DISABLED_STATE_TRAILING_ICON_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.15)",
  "SEARCH_DISABLED_STATE_TRAILING_ICON_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.15)",
  "SEARCH_DISABLED_STATE_TRAILING_ICON_COLOR": "rgba(0, 0, 0, 0.15)",
  "SEARCH_DISABLED_STATE_BORDER_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.05)",
  "SEARCH_DISABLED_STATE_BORDER_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.08)",
  "SEARCH_DISABLED_STATE_BORDER_COLOR": "rgba(0, 0, 0, 0.05)",
  "SEARCH_FOCUSED_STATE_BORDER_LIGHT_MODE_COLOR": "rgb(0, 106, 255)",
  "SEARCH_FOCUSED_STATE_BORDER_DARK_MODE_COLOR": "rgb(0, 106, 255)",
  "SEARCH_FOCUSED_STATE_BORDER_SIZE": 2,
  "SEARCH_FOCUSED_STATE_BORDER_COLOR": "rgb(0, 106, 255)",
  "SEGMENTED_CONTROL_BACKGROUND_NORMAL_STATE_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.05)",
  "SEGMENTED_CONTROL_BACKGROUND_NORMAL_STATE_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.08)",
  "SEGMENTED_CONTROL_BACKGROUND_NORMAL_STATE_COLOR": "rgba(0, 0, 0, 0.05)",
  "SEGMENTED_CONTROL_BACKGROUND_DISABLED_STATE_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.05)",
  "SEGMENTED_CONTROL_BACKGROUND_DISABLED_STATE_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.08)",
  "SEGMENTED_CONTROL_BACKGROUND_DISABLED_STATE_COLOR": "rgba(0, 0, 0, 0.05)",
  "SEGMENTED_CONTROL_BACKGROUND_RADIUS": 6,
  "SEGMENTED_CONTROL_PADDING_HORIZONTAL": 4,
  "SEGMENTED_CONTROL_PADDING_VERTICAL": 4,
  "SEGMENTED_CONTROL_ANIMATION_DURATION": 330,
  "SEGMENTED_CONTROL_OPTION_BACKGROUND_NORMAL_STATE_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0)",
  "SEGMENTED_CONTROL_OPTION_BACKGROUND_NORMAL_STATE_DARK_MODE_COLOR": "rgba(0, 0, 0, 0)",
  "SEGMENTED_CONTROL_OPTION_BACKGROUND_NORMAL_STATE_COLOR": "rgba(0, 0, 0, 0)",
  "SEGMENTED_CONTROL_OPTION_BACKGROUND_SELECTED_STATE_LIGHT_MODE_COLOR": "rgb(255, 255, 255)",
  "SEGMENTED_CONTROL_OPTION_BACKGROUND_SELECTED_STATE_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.15)",
  "SEGMENTED_CONTROL_OPTION_BACKGROUND_SELECTED_STATE_COLOR": "rgb(255, 255, 255)",
  "SEGMENTED_CONTROL_OPTION_BACKGROUND_HOVER_STATE_LIGHT_MODE_COLOR": "rgb(230, 240, 255)",
  "SEGMENTED_CONTROL_OPTION_BACKGROUND_HOVER_STATE_DARK_MODE_COLOR": "rgb(0, 21, 51)",
  "SEGMENTED_CONTROL_OPTION_BACKGROUND_HOVER_STATE_COLOR": "rgb(230, 240, 255)",
  "SEGMENTED_CONTROL_OPTION_BACKGROUND_PRESSED_STATE_LIGHT_MODE_COLOR": "rgb(204, 225, 255)",
  "SEGMENTED_CONTROL_OPTION_BACKGROUND_PRESSED_STATE_DARK_MODE_COLOR": "rgb(0, 37, 89)",
  "SEGMENTED_CONTROL_OPTION_BACKGROUND_PRESSED_STATE_COLOR": "rgb(204, 225, 255)",
  "SEGMENTED_CONTROL_OPTION_BACKGROUND_DISABLED_STATE_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0)",
  "SEGMENTED_CONTROL_OPTION_BACKGROUND_DISABLED_STATE_DARK_MODE_COLOR": "rgba(0, 0, 0, 0)",
  "SEGMENTED_CONTROL_OPTION_BACKGROUND_DISABLED_STATE_COLOR": "rgba(0, 0, 0, 0)",
  "SEGMENTED_CONTROL_OPTION_BACKGROUND_RADIUS": 4,
  "SEGMENTED_CONTROL_OPTION_PADDING_HORIZONTAL": 8,
  "SEGMENTED_CONTROL_OPTION_PADDING_VERTICAL": 8,
  "SEGMENTED_CONTROL_OPTION_SPACING_HORIZONTAL": 8,
  "SEGMENTED_CONTROL_OPTION_BADGE_NORMAL_STATE_OPACITY": 1,
  "SEGMENTED_CONTROL_OPTION_BADGE_SELECTED_STATE_OPACITY": 1,
  "SEGMENTED_CONTROL_OPTION_BADGE_HOVER_STATE_OPACITY": 1,
  "SEGMENTED_CONTROL_OPTION_BADGE_PRESSED_STATE_OPACITY": 1,
  "SEGMENTED_CONTROL_OPTION_BADGE_DISABLED_STATE_OPACITY": 0.3,
  "SEGMENTED_CONTROL_OPTION_LABEL_SIZE": 16,
  "SEGMENTED_CONTROL_OPTION_LABEL_LEADING": 24,
  "SEGMENTED_CONTROL_OPTION_LABEL_TRACKING": 0,
  "SEGMENTED_CONTROL_OPTION_LABEL_WEIGHT": 600,
  "SEGMENTED_CONTROL_OPTION_LABEL_CASE": "regular",
  "SEGMENTED_CONTROL_OPTION_LABEL_NUMBER_SPACING": "default",
  "SEGMENTED_CONTROL_OPTION_LABEL_SCALE": "paragraph-30",
  "SEGMENTED_CONTROL_OPTION_LABEL_FONT_FAMILY": "Square Sans Text",
  "SEGMENTED_CONTROL_OPTION_LABEL_NORMAL_STATE_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "SEGMENTED_CONTROL_OPTION_LABEL_NORMAL_STATE_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "SEGMENTED_CONTROL_OPTION_LABEL_NORMAL_STATE_COLOR": "rgba(0, 0, 0, 0.9)",
  "SEGMENTED_CONTROL_OPTION_LABEL_SELECTED_STATE_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "SEGMENTED_CONTROL_OPTION_LABEL_SELECTED_STATE_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "SEGMENTED_CONTROL_OPTION_LABEL_SELECTED_STATE_COLOR": "rgba(0, 0, 0, 0.9)",
  "SEGMENTED_CONTROL_OPTION_LABEL_HOVER_STATE_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "SEGMENTED_CONTROL_OPTION_LABEL_HOVER_STATE_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "SEGMENTED_CONTROL_OPTION_LABEL_HOVER_STATE_COLOR": "rgba(0, 0, 0, 0.9)",
  "SEGMENTED_CONTROL_OPTION_LABEL_PRESSED_STATE_LIGHT_MODE_COLOR": "rgb(0, 90, 217)",
  "SEGMENTED_CONTROL_OPTION_LABEL_PRESSED_STATE_DARK_MODE_COLOR": "rgb(74, 149, 255)",
  "SEGMENTED_CONTROL_OPTION_LABEL_PRESSED_STATE_COLOR": "rgb(0, 90, 217)",
  "SEGMENTED_CONTROL_OPTION_LABEL_DISABLED_STATE_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.3)",
  "SEGMENTED_CONTROL_OPTION_LABEL_DISABLED_STATE_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.3)",
  "SEGMENTED_CONTROL_OPTION_LABEL_DISABLED_STATE_COLOR": "rgba(0, 0, 0, 0.3)",
  "SELECT_CARET_ICON_ASSET": "chevron-down",
  "SELECT_CARET_ICON_HEIGHT": 16,
  "SELECT_CARET_ICON_WIDTH": 16,
  "SELECT_CARET_ICON_TINTABLE": true,
  "SELECT_CARET_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.3)",
  "SELECT_CARET_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.3)",
  "SELECT_CARET_COLOR": "rgba(0, 0, 0, 0.3)",
  "SKELETON_LOADER_FILL_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.05)",
  "SKELETON_LOADER_FILL_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.08)",
  "SKELETON_LOADER_FILL_COLOR": "rgba(0, 0, 0, 0.05)",
  "SKELETON_LOADER_GRADIENT_1_COLOR": "rgba(0, 0, 0, 0)",
  "SKELETON_LOADER_GRADIENT_2_COLOR": "rgba(0, 0, 0, 0.05)",
  "SKELETON_LOADER_GRADIENT_3_COLOR": "rgba(0, 0, 0, 0.08)",
  "SKELETON_LOADER_GRADIENT_LIGHT_MODE_1_COLOR": "rgba(0, 0, 0, 0)",
  "SKELETON_LOADER_GRADIENT_LIGHT_MODE_2_COLOR": "rgba(0, 0, 0, 0.05)",
  "SKELETON_LOADER_GRADIENT_LIGHT_MODE_3_COLOR": "rgba(0, 0, 0, 0.08)",
  "SKELETON_LOADER_GRADIENT_DARK_MODE_1_COLOR": "rgba(255, 255, 255, 0)",
  "SKELETON_LOADER_GRADIENT_DARK_MODE_2_COLOR": "rgba(255, 255, 255, 0.1)",
  "SKELETON_LOADER_GRADIENT_DARK_MODE_3_COLOR": "rgba(255, 255, 255, 0.15)",
  "SKELETON_LOADER_GRADIENT_WIDTH_RATIO": "0.5",
  "SKELETON_LOADER_BORDER_RADIUS": 6,
  "SKELETON_LOADER_ANIMATION_DURATION": "3000",
  "SKELETON_LOADER_ANIMATION_EASING": "cubic-bezier(0.0, 0.0, 1.0, 1.0)",
  "STEPPER_BORDER_RADIUS": 6,
  "STEPPER_NORMAL_STATE_BACKGROUND_LIGHT_MODE_COLOR": "rgb(255, 255, 255)",
  "STEPPER_NORMAL_STATE_BACKGROUND_DARK_MODE_COLOR": "rgb(8, 8, 8)",
  "STEPPER_NORMAL_STATE_BACKGROUND_COLOR": "rgb(255, 255, 255)",
  "STEPPER_NORMAL_STATE_BORDER_SIZE": 1,
  "STEPPER_NORMAL_STATE_NORMAL_VALIDITY_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.15)",
  "STEPPER_NORMAL_STATE_NORMAL_VALIDITY_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.15)",
  "STEPPER_NORMAL_STATE_NORMAL_VALIDITY_COLOR": "rgba(0, 0, 0, 0.15)",
  "STEPPER_NORMAL_STATE_INVALID_VALIDITY_LIGHT_MODE_COLOR": "rgb(204, 0, 35)",
  "STEPPER_NORMAL_STATE_INVALID_VALIDITY_DARK_MODE_COLOR": "rgb(204, 0, 35)",
  "STEPPER_NORMAL_STATE_INVALID_VALIDITY_COLOR": "rgb(204, 0, 35)",
  "STEPPER_HOVER_STATE_BACKGROUND_LIGHT_MODE_COLOR": "rgb(255, 255, 255)",
  "STEPPER_HOVER_STATE_BACKGROUND_DARK_MODE_COLOR": "rgb(8, 8, 8)",
  "STEPPER_HOVER_STATE_BACKGROUND_COLOR": "rgb(255, 255, 255)",
  "STEPPER_HOVER_STATE_BORDER_SIZE": 1,
  "STEPPER_HOVER_STATE_NORMAL_VALIDITY_LIGHT_MODE_COLOR": "rgb(0, 106, 255)",
  "STEPPER_HOVER_STATE_NORMAL_VALIDITY_DARK_MODE_COLOR": "rgb(0, 106, 255)",
  "STEPPER_HOVER_STATE_NORMAL_VALIDITY_COLOR": "rgb(0, 106, 255)",
  "STEPPER_HOVER_STATE_INVALID_VALIDITY_LIGHT_MODE_COLOR": "rgb(0, 106, 255)",
  "STEPPER_HOVER_STATE_INVALID_VALIDITY_DARK_MODE_COLOR": "rgb(0, 106, 255)",
  "STEPPER_HOVER_STATE_INVALID_VALIDITY_COLOR": "rgb(0, 106, 255)",
  "STEPPER_FOCUS_STATE_BACKGROUND_LIGHT_MODE_COLOR": "rgb(255, 255, 255)",
  "STEPPER_FOCUS_STATE_BACKGROUND_DARK_MODE_COLOR": "rgb(8, 8, 8)",
  "STEPPER_FOCUS_STATE_BACKGROUND_COLOR": "rgb(255, 255, 255)",
  "STEPPER_FOCUS_STATE_BORDER_SIZE": 2,
  "STEPPER_FOCUS_STATE_NORMAL_VALIDITY_LIGHT_MODE_COLOR": "rgb(0, 106, 255)",
  "STEPPER_FOCUS_STATE_NORMAL_VALIDITY_DARK_MODE_COLOR": "rgb(0, 106, 255)",
  "STEPPER_FOCUS_STATE_NORMAL_VALIDITY_COLOR": "rgb(0, 106, 255)",
  "STEPPER_FOCUS_STATE_INVALID_VALIDITY_LIGHT_MODE_COLOR": "rgb(0, 106, 255)",
  "STEPPER_FOCUS_STATE_INVALID_VALIDITY_DARK_MODE_COLOR": "rgb(0, 106, 255)",
  "STEPPER_FOCUS_STATE_INVALID_VALIDITY_COLOR": "rgb(0, 106, 255)",
  "STEPPER_DISABLED_STATE_BACKGROUND_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.05)",
  "STEPPER_DISABLED_STATE_BACKGROUND_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.08)",
  "STEPPER_DISABLED_STATE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.05)",
  "STEPPER_DISABLED_STATE_BORDER_SIZE": 1,
  "STEPPER_DISABLED_STATE_BORDER_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.05)",
  "STEPPER_DISABLED_STATE_BORDER_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.08)",
  "STEPPER_DISABLED_STATE_BORDER_COLOR": "rgba(0, 0, 0, 0.05)",
  "STEPPER_PADDING_SIZE": 12,
  "STEPPER_HORIZONTAL_SPACING": 12,
  "STEPPER_QUANTITY_INPUT_FIELD_VALUE_TEXT_SIZE": 16,
  "STEPPER_QUANTITY_INPUT_FIELD_VALUE_TEXT_LEADING": 24,
  "STEPPER_QUANTITY_INPUT_FIELD_VALUE_TEXT_TRACKING": 0,
  "STEPPER_QUANTITY_INPUT_FIELD_VALUE_TEXT_WEIGHT": 400,
  "STEPPER_QUANTITY_INPUT_FIELD_VALUE_TEXT_CASE": "regular",
  "STEPPER_QUANTITY_INPUT_FIELD_VALUE_TEXT_NUMBER_SPACING": "monospaced",
  "STEPPER_QUANTITY_INPUT_FIELD_VALUE_TEXT_SCALE": "paragraph-30",
  "STEPPER_QUANTITY_INPUT_FIELD_VALUE_TEXT_FONT_FAMILY": "Square Sans Text",
  "STEPPER_QUANTITY_INPUT_FIELD_VALUE_TEXT_NORMAL_STATE_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "STEPPER_QUANTITY_INPUT_FIELD_VALUE_TEXT_NORMAL_STATE_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "STEPPER_QUANTITY_INPUT_FIELD_VALUE_TEXT_NORMAL_STATE_COLOR": "rgba(0, 0, 0, 0.9)",
  "STEPPER_QUANTITY_INPUT_FIELD_VALUE_TEXT_DISABLED_STATE_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.55)",
  "STEPPER_QUANTITY_INPUT_FIELD_VALUE_TEXT_DISABLED_STATE_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.55)",
  "STEPPER_QUANTITY_INPUT_FIELD_VALUE_TEXT_DISABLED_STATE_COLOR": "rgba(0, 0, 0, 0.55)",
  "STEPPER_QUANTITY_INPUT_FIELD_VALUE_TEXT_PLACEHOLDER_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.3)",
  "STEPPER_QUANTITY_INPUT_FIELD_VALUE_TEXT_PLACEHOLDER_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.3)",
  "STEPPER_QUANTITY_INPUT_FIELD_VALUE_TEXT_PLACEHOLDER_COLOR": "rgba(0, 0, 0, 0.3)",
  "STEPPER_QUANTITY_INPUT_FIELD_MINIMUM_WIDTH_MULTIPLIER": 1.66,
  "STEPPER_BUTTON_NORMAL_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.05)",
  "STEPPER_BUTTON_NORMAL_STATE_LIGHT_MODE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "STEPPER_BUTTON_NORMAL_STATE_DARK_MODE_BACKGROUND_COLOR": "rgba(255, 255, 255, 0.08)",
  "STEPPER_BUTTON_NORMAL_STATE_DARK_MODE_ICON_COLOR": "rgba(255, 255, 255, 0.95)",
  "STEPPER_BUTTON_NORMAL_STATE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.05)",
  "STEPPER_BUTTON_NORMAL_STATE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "STEPPER_BUTTON_HOVER_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(230, 240, 255)",
  "STEPPER_BUTTON_HOVER_STATE_LIGHT_MODE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "STEPPER_BUTTON_HOVER_STATE_DARK_MODE_BACKGROUND_COLOR": "rgb(0, 21, 51)",
  "STEPPER_BUTTON_HOVER_STATE_DARK_MODE_ICON_COLOR": "rgba(255, 255, 255, 0.95)",
  "STEPPER_BUTTON_HOVER_STATE_BACKGROUND_COLOR": "rgb(230, 240, 255)",
  "STEPPER_BUTTON_HOVER_STATE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "STEPPER_BUTTON_PRESSED_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(204, 225, 255)",
  "STEPPER_BUTTON_PRESSED_STATE_LIGHT_MODE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "STEPPER_BUTTON_PRESSED_STATE_DARK_MODE_BACKGROUND_COLOR": "rgb(0, 37, 89)",
  "STEPPER_BUTTON_PRESSED_STATE_DARK_MODE_ICON_COLOR": "rgba(255, 255, 255, 0.95)",
  "STEPPER_BUTTON_PRESSED_STATE_BACKGROUND_COLOR": "rgb(204, 225, 255)",
  "STEPPER_BUTTON_PRESSED_STATE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "STEPPER_BUTTON_FOCUS_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(230, 240, 255)",
  "STEPPER_BUTTON_FOCUS_STATE_LIGHT_MODE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "STEPPER_BUTTON_FOCUS_STATE_DARK_MODE_BACKGROUND_COLOR": "rgb(0, 21, 51)",
  "STEPPER_BUTTON_FOCUS_STATE_DARK_MODE_ICON_COLOR": "rgba(255, 255, 255, 0.95)",
  "STEPPER_BUTTON_FOCUS_STATE_BACKGROUND_COLOR": "rgb(230, 240, 255)",
  "STEPPER_BUTTON_FOCUS_STATE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "STEPPER_BUTTON_DISABLED_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.05)",
  "STEPPER_BUTTON_DISABLED_STATE_LIGHT_MODE_ICON_COLOR": "rgba(0, 0, 0, 0.3)",
  "STEPPER_BUTTON_DISABLED_STATE_DARK_MODE_BACKGROUND_COLOR": "rgba(255, 255, 255, 0.08)",
  "STEPPER_BUTTON_DISABLED_STATE_DARK_MODE_ICON_COLOR": "rgba(255, 255, 255, 0.3)",
  "STEPPER_BUTTON_DISABLED_STATE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.05)",
  "STEPPER_BUTTON_DISABLED_STATE_ICON_COLOR": "rgba(0, 0, 0, 0.3)",
  "STEPPER_BUTTON_BORDER_RADIUS": 4,
  "STEPPER_BUTTON_PADDING_SIZE": 4,
  "STEPPER_BUTTON_WIDTH_MULTIPLIER": 1,
  "STEPPER_BUTTON_MINIMUM_HEIGHT": 24,
  "STEPPER_BUTTON_INCREMENT_ICON_ASSET": "plus",
  "STEPPER_BUTTON_INCREMENT_ICON_WIDTH": 24,
  "STEPPER_BUTTON_INCREMENT_ICON_HEIGHT": 24,
  "STEPPER_BUTTON_INCREMENT_ICON_TINTABLE": true,
  "STEPPER_BUTTON_INCREMENT_WIDTH": 16,
  "STEPPER_BUTTON_INCREMENT_HEIGHT": 16,
  "STEPPER_BUTTON_INCREMENT_SCALE": "paragraph-20",
  "STEPPER_BUTTON_DECREMENT_ICON_ASSET": "minus",
  "STEPPER_BUTTON_DECREMENT_ICON_WIDTH": 24,
  "STEPPER_BUTTON_DECREMENT_ICON_HEIGHT": 24,
  "STEPPER_BUTTON_DECREMENT_ICON_TINTABLE": true,
  "STEPPER_BUTTON_DECREMENT_WIDTH": 16,
  "STEPPER_BUTTON_DECREMENT_HEIGHT": 16,
  "STEPPER_BUTTON_DECREMENT_SCALE": "paragraph-20",
  "STICKY_SECTION_HEADER_TEXT_SIZE": 14,
  "STICKY_SECTION_HEADER_TEXT_LEADING": 22,
  "STICKY_SECTION_HEADER_TEXT_TRACKING": 0,
  "STICKY_SECTION_HEADER_TEXT_WEIGHT": 700,
  "STICKY_SECTION_HEADER_TEXT_CASE": "regular",
  "STICKY_SECTION_HEADER_TEXT_NUMBER_SPACING": "default",
  "STICKY_SECTION_HEADER_TEXT_SCALE": "heading-10",
  "STICKY_SECTION_HEADER_TEXT_FONT_FAMILY": "Square Sans Text",
  "STICKY_SECTION_HEADER_TEXT_IS_HEADER": true,
  "STICKY_SECTION_HEADER_TEXT_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "STICKY_SECTION_HEADER_TEXT_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "STICKY_SECTION_HEADER_TEXT_COLOR": "rgba(0, 0, 0, 0.9)",
  "STICKY_SECTION_HEADER_TEXT_LINK_LIGHT_MODE_COLOR": "rgb(0, 90, 217)",
  "STICKY_SECTION_HEADER_TEXT_LINK_DARK_MODE_COLOR": "rgb(74, 149, 255)",
  "STICKY_SECTION_HEADER_TEXT_LINK_TEXT_SIZE": 14,
  "STICKY_SECTION_HEADER_TEXT_LINK_TEXT_LEADING": 22,
  "STICKY_SECTION_HEADER_TEXT_LINK_TEXT_TRACKING": 0,
  "STICKY_SECTION_HEADER_TEXT_LINK_TEXT_WEIGHT": 600,
  "STICKY_SECTION_HEADER_TEXT_LINK_TEXT_CASE": "regular",
  "STICKY_SECTION_HEADER_TEXT_LINK_TEXT_NUMBER_SPACING": "default",
  "STICKY_SECTION_HEADER_TEXT_LINK_TEXT_SCALE": "paragraph-20",
  "STICKY_SECTION_HEADER_TEXT_LINK_TEXT_FONT_FAMILY": "Square Sans Text",
  "STICKY_SECTION_HEADER_TEXT_LINK_VERTICAL_PADDING": 0,
  "STICKY_SECTION_HEADER_TEXT_LINK_NORMAL_STATE_OPACITY": 1,
  "STICKY_SECTION_HEADER_TEXT_LINK_HOVER_STATE_OPACITY": 0.6,
  "STICKY_SECTION_HEADER_TEXT_LINK_PRESSED_STATE_OPACITY": 0.6,
  "STICKY_SECTION_HEADER_TEXT_LINK_DISABLED_STATE_OPACITY": 0.3,
  "STICKY_SECTION_HEADER_TEXT_LINK_COLOR": "rgb(0, 90, 217)",
  "STICKY_SECTION_HEADER_VERTICAL_PADDING": 9,
  "STICKY_SECTION_HEADER_HORIZONTAL_SPACING": 8,
  "STICKY_SECTION_HEADER_BACKGROUND_LIGHT_MODE_COLOR": "rgb(255, 255, 255)",
  "STICKY_SECTION_HEADER_BACKGROUND_DARK_MODE_COLOR": "rgb(8, 8, 8)",
  "STICKY_SECTION_HEADER_BACKGROUND_COLOR": "rgb(255, 255, 255)",
  "STICKY_SECTION_HEADER_SEPARATOR_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.3)",
  "STICKY_SECTION_HEADER_SEPARATOR_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.3)",
  "STICKY_SECTION_HEADER_SEPARATOR_HEIGHT": 1,
  "STICKY_SECTION_HEADER_SEPARATOR_COLOR": "rgba(0, 0, 0, 0.3)",
  "SUBTLE_BUTTON_PADDING_SIZE": 0,
  "SUBTLE_BUTTON_HORIZONTAL_SPACING_SIZE": 8,
  "SUBTLE_BUTTON_MINIMUM_HEIGHT": 24,
  "SUBTLE_BUTTON_WIDTH_MULTIPLIER": 1,
  "SUBTLE_BUTTON_NORMAL_VARIANT_PRIMARY_RANK_NORMAL_STATE_ICON_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.3)",
  "SUBTLE_BUTTON_NORMAL_VARIANT_PRIMARY_RANK_NORMAL_STATE_ICON_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.3)",
  "SUBTLE_BUTTON_NORMAL_VARIANT_PRIMARY_RANK_NORMAL_STATE_ICON_COLOR": "rgba(0, 0, 0, 0.3)",
  "SUBTLE_BUTTON_NORMAL_VARIANT_PRIMARY_RANK_HOVER_STATE_ICON_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "SUBTLE_BUTTON_NORMAL_VARIANT_PRIMARY_RANK_HOVER_STATE_ICON_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "SUBTLE_BUTTON_NORMAL_VARIANT_PRIMARY_RANK_HOVER_STATE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "SUBTLE_BUTTON_NORMAL_VARIANT_PRIMARY_RANK_PRESSED_STATE_ICON_LIGHT_MODE_COLOR": "rgb(0, 106, 255)",
  "SUBTLE_BUTTON_NORMAL_VARIANT_PRIMARY_RANK_PRESSED_STATE_ICON_DARK_MODE_COLOR": "rgb(0, 106, 255)",
  "SUBTLE_BUTTON_NORMAL_VARIANT_PRIMARY_RANK_PRESSED_STATE_ICON_COLOR": "rgb(0, 106, 255)",
  "SUBTLE_BUTTON_NORMAL_VARIANT_PRIMARY_RANK_DISABLED_STATE_ICON_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.15)",
  "SUBTLE_BUTTON_NORMAL_VARIANT_PRIMARY_RANK_DISABLED_STATE_ICON_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.15)",
  "SUBTLE_BUTTON_NORMAL_VARIANT_PRIMARY_RANK_DISABLED_STATE_ICON_COLOR": "rgba(0, 0, 0, 0.15)",
  "SUBTLE_BUTTON_DESTRUCTIVE_VARIANT_PRIMARY_RANK_NORMAL_STATE_ICON_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.3)",
  "SUBTLE_BUTTON_DESTRUCTIVE_VARIANT_PRIMARY_RANK_NORMAL_STATE_ICON_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.3)",
  "SUBTLE_BUTTON_DESTRUCTIVE_VARIANT_PRIMARY_RANK_NORMAL_STATE_ICON_COLOR": "rgba(0, 0, 0, 0.3)",
  "SUBTLE_BUTTON_DESTRUCTIVE_VARIANT_PRIMARY_RANK_HOVER_STATE_ICON_LIGHT_MODE_COLOR": "rgb(204, 0, 35)",
  "SUBTLE_BUTTON_DESTRUCTIVE_VARIANT_PRIMARY_RANK_HOVER_STATE_ICON_DARK_MODE_COLOR": "rgb(204, 0, 35)",
  "SUBTLE_BUTTON_DESTRUCTIVE_VARIANT_PRIMARY_RANK_HOVER_STATE_ICON_COLOR": "rgb(204, 0, 35)",
  "SUBTLE_BUTTON_DESTRUCTIVE_VARIANT_PRIMARY_RANK_PRESSED_STATE_ICON_LIGHT_MODE_COLOR": "rgb(153, 0, 26)",
  "SUBTLE_BUTTON_DESTRUCTIVE_VARIANT_PRIMARY_RANK_PRESSED_STATE_ICON_DARK_MODE_COLOR": "rgb(153, 0, 26)",
  "SUBTLE_BUTTON_DESTRUCTIVE_VARIANT_PRIMARY_RANK_PRESSED_STATE_ICON_COLOR": "rgb(153, 0, 26)",
  "SUBTLE_BUTTON_DESTRUCTIVE_VARIANT_PRIMARY_RANK_DISABLED_STATE_ICON_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.15)",
  "SUBTLE_BUTTON_DESTRUCTIVE_VARIANT_PRIMARY_RANK_DISABLED_STATE_ICON_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.15)",
  "SUBTLE_BUTTON_DESTRUCTIVE_VARIANT_PRIMARY_RANK_DISABLED_STATE_ICON_COLOR": "rgba(0, 0, 0, 0.15)",
  "TABLE_CELL_HOVER_STATE_BACKGROUND_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.03)",
  "TABLE_CELL_HOVER_STATE_BACKGROUND_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.05)",
  "TABLE_CELL_HOVER_STATE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.03)",
  "TABLE_CELL_PRESSED_STATE_BACKGROUND_LIGHT_MODE_COLOR": "rgb(230, 240, 255)",
  "TABLE_CELL_PRESSED_STATE_BACKGROUND_DARK_MODE_COLOR": "rgb(0, 21, 51)",
  "TABLE_CELL_PRESSED_STATE_BACKGROUND_COLOR": "rgb(230, 240, 255)",
  "TABLE_CELL_FOCUS_STATE_BACKGROUND_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.03)",
  "TABLE_CELL_FOCUS_STATE_BACKGROUND_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.05)",
  "TABLE_CELL_FOCUS_STATE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.03)",
  "TABLE_CELL_DISABLED_STATE_TEXT_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.3)",
  "TABLE_CELL_DISABLED_STATE_TEXT_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.3)",
  "TABLE_CELL_DISABLED_STATE_TEXT_COLOR": "rgba(0, 0, 0, 0.3)",
  "TAG_HORIZONTAL_SPACING": 4,
  "TAG_MINIMUM_WIDTH_MULTIPLIER": 1.5,
  "TAG_REMOVE_INDICATOR_ICON_ASSET": "x",
  "TAG_REMOVE_INDICATOR_ICON_WIDTH": 24,
  "TAG_REMOVE_INDICATOR_ICON_HEIGHT": 24,
  "TAG_REMOVE_INDICATOR_ICON_SCALE": "paragraph-10",
  "TAG_REMOVE_INDICATOR_ICON_TINTABLE": true,
  "TAG_REMOVE_INDICATOR_WIDTH": 16,
  "TAG_REMOVE_INDICATOR_HEIGHT": 16,
  "TAG_REMOVE_INDICATOR_SCALE": "paragraph-10",
  "TAG_DISABLED_STATE_DARK_MODE_BACKGROUND_COLOR": "rgba(255, 255, 255, 0.08)",
  "TAG_DISABLED_STATE_DARK_MODE_ICON_COLOR": "rgba(255, 255, 255, 0.95)",
  "TAG_DISABLED_STATE_DARK_MODE_LABEL_COLOR": "rgba(255, 255, 255, 0.3)",
  "TAG_DISABLED_STATE_DARK_MODE_REMOVE_INDICATOR_COLOR": "rgba(255, 255, 255, 0.15)",
  "TAG_DISABLED_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.05)",
  "TAG_DISABLED_STATE_LIGHT_MODE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "TAG_DISABLED_STATE_LIGHT_MODE_LABEL_COLOR": "rgba(0, 0, 0, 0.3)",
  "TAG_DISABLED_STATE_LIGHT_MODE_REMOVE_INDICATOR_COLOR": "rgba(0, 0, 0, 0.15)",
  "TAG_DISABLED_STATE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.05)",
  "TAG_DISABLED_STATE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "TAG_DISABLED_STATE_LABEL_COLOR": "rgba(0, 0, 0, 0.3)",
  "TAG_DISABLED_STATE_REMOVE_INDICATOR_COLOR": "rgba(0, 0, 0, 0.15)",
  "TAG_FOCUS_STATE_DARK_MODE_BACKGROUND_COLOR": "rgb(0, 21, 51)",
  "TAG_FOCUS_STATE_DARK_MODE_ICON_COLOR": "rgba(255, 255, 255, 0.95)",
  "TAG_FOCUS_STATE_DARK_MODE_LABEL_COLOR": "rgba(255, 255, 255, 0.95)",
  "TAG_FOCUS_STATE_DARK_MODE_REMOVE_INDICATOR_COLOR": "rgba(255, 255, 255, 0.3)",
  "TAG_FOCUS_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(230, 240, 255)",
  "TAG_FOCUS_STATE_LIGHT_MODE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "TAG_FOCUS_STATE_LIGHT_MODE_LABEL_COLOR": "rgba(0, 0, 0, 0.9)",
  "TAG_FOCUS_STATE_LIGHT_MODE_REMOVE_INDICATOR_COLOR": "rgba(0, 0, 0, 0.3)",
  "TAG_FOCUS_STATE_BACKGROUND_COLOR": "rgb(230, 240, 255)",
  "TAG_FOCUS_STATE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "TAG_FOCUS_STATE_LABEL_COLOR": "rgba(0, 0, 0, 0.9)",
  "TAG_FOCUS_STATE_REMOVE_INDICATOR_COLOR": "rgba(0, 0, 0, 0.3)",
  "TAG_HOVER_STATE_DARK_MODE_BACKGROUND_COLOR": "rgb(0, 21, 51)",
  "TAG_HOVER_STATE_DARK_MODE_ICON_COLOR": "rgba(255, 255, 255, 0.95)",
  "TAG_HOVER_STATE_DARK_MODE_LABEL_COLOR": "rgba(255, 255, 255, 0.95)",
  "TAG_HOVER_STATE_DARK_MODE_REMOVE_INDICATOR_COLOR": "rgba(255, 255, 255, 0.3)",
  "TAG_HOVER_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(230, 240, 255)",
  "TAG_HOVER_STATE_LIGHT_MODE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "TAG_HOVER_STATE_LIGHT_MODE_LABEL_COLOR": "rgba(0, 0, 0, 0.9)",
  "TAG_HOVER_STATE_LIGHT_MODE_REMOVE_INDICATOR_COLOR": "rgba(0, 0, 0, 0.3)",
  "TAG_HOVER_STATE_BACKGROUND_COLOR": "rgb(230, 240, 255)",
  "TAG_HOVER_STATE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "TAG_HOVER_STATE_LABEL_COLOR": "rgba(0, 0, 0, 0.9)",
  "TAG_HOVER_STATE_REMOVE_INDICATOR_COLOR": "rgba(0, 0, 0, 0.3)",
  "TAG_NORMAL_STATE_DARK_MODE_BACKGROUND_COLOR": "rgba(255, 255, 255, 0.08)",
  "TAG_NORMAL_STATE_DARK_MODE_ICON_COLOR": "rgba(255, 255, 255, 0.95)",
  "TAG_NORMAL_STATE_DARK_MODE_LABEL_COLOR": "rgba(255, 255, 255, 0.95)",
  "TAG_NORMAL_STATE_DARK_MODE_REMOVE_INDICATOR_COLOR": "rgba(255, 255, 255, 0.3)",
  "TAG_NORMAL_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.05)",
  "TAG_NORMAL_STATE_LIGHT_MODE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "TAG_NORMAL_STATE_LIGHT_MODE_LABEL_COLOR": "rgba(0, 0, 0, 0.9)",
  "TAG_NORMAL_STATE_LIGHT_MODE_REMOVE_INDICATOR_COLOR": "rgba(0, 0, 0, 0.3)",
  "TAG_NORMAL_STATE_BACKGROUND_COLOR": "rgba(0, 0, 0, 0.05)",
  "TAG_NORMAL_STATE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "TAG_NORMAL_STATE_LABEL_COLOR": "rgba(0, 0, 0, 0.9)",
  "TAG_NORMAL_STATE_REMOVE_INDICATOR_COLOR": "rgba(0, 0, 0, 0.3)",
  "TAG_PRESSED_STATE_DARK_MODE_BACKGROUND_COLOR": "rgb(0, 37, 89)",
  "TAG_PRESSED_STATE_DARK_MODE_ICON_COLOR": "rgba(255, 255, 255, 0.95)",
  "TAG_PRESSED_STATE_DARK_MODE_LABEL_COLOR": "rgba(255, 255, 255, 0.95)",
  "TAG_PRESSED_STATE_DARK_MODE_REMOVE_INDICATOR_COLOR": "rgba(255, 255, 255, 0.3)",
  "TAG_PRESSED_STATE_LIGHT_MODE_BACKGROUND_COLOR": "rgb(204, 225, 255)",
  "TAG_PRESSED_STATE_LIGHT_MODE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "TAG_PRESSED_STATE_LIGHT_MODE_LABEL_COLOR": "rgba(0, 0, 0, 0.9)",
  "TAG_PRESSED_STATE_LIGHT_MODE_REMOVE_INDICATOR_COLOR": "rgba(0, 0, 0, 0.3)",
  "TAG_PRESSED_STATE_BACKGROUND_COLOR": "rgb(204, 225, 255)",
  "TAG_PRESSED_STATE_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "TAG_PRESSED_STATE_LABEL_COLOR": "rgba(0, 0, 0, 0.9)",
  "TAG_PRESSED_STATE_REMOVE_INDICATOR_COLOR": "rgba(0, 0, 0, 0.3)",
  "TAG_TEXT_SIZE": 12,
  "TAG_TEXT_LEADING": 18,
  "TAG_TEXT_TRACKING": 0,
  "TAG_TEXT_WEIGHT": 400,
  "TAG_TEXT_CASE": "regular",
  "TAG_TEXT_NUMBER_SPACING": "default",
  "TAG_TEXT_SCALE": "paragraph-10",
  "TAG_TEXT_FONT_FAMILY": "Square Sans Text",
  "TAG_BASIC_FORMAT_LEFT_PADDING": 12,
  "TAG_BASIC_FORMAT_TOP_PADDING": 7,
  "TAG_BASIC_FORMAT_RIGHT_PADDING": 8,
  "TAG_BASIC_FORMAT_BOTTOM_PADDING": 7,
  "TAG_WITH_ICON_FORMAT_LEFT_PADDING": 8,
  "TAG_WITH_ICON_FORMAT_TOP_PADDING": 8,
  "TAG_WITH_ICON_FORMAT_RIGHT_PADDING": 8,
  "TAG_WITH_ICON_FORMAT_BOTTOM_PADDING": 8,
  "TEXT_LINK_GROUP_SEPARATOR_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "TEXT_LINK_GROUP_SEPARATOR_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "TEXT_LINK_GROUP_SEPARATOR_OPACITY": 0.3,
  "TEXT_LINK_GROUP_SEPARATOR_HEIGHT": 8,
  "TEXT_LINK_GROUP_SEPARATOR_WIDTH": 1,
  "TEXT_LINK_GROUP_SEPARATOR_COLOR": "rgba(0, 0, 0, 0.9)",
  "TEXT_LINK_GROUP_HORIZONTAL_SPACING": 12,
  "TEXT_LINK_GROUP_VERTICAL_SPACING": 16,
  "TEXT_LINK_SMALL_SIZE_TEXT_SIZE": 14,
  "TEXT_LINK_SMALL_SIZE_TEXT_LEADING": 22,
  "TEXT_LINK_SMALL_SIZE_TEXT_TRACKING": 0,
  "TEXT_LINK_SMALL_SIZE_TEXT_WEIGHT": 600,
  "TEXT_LINK_SMALL_SIZE_TEXT_CASE": "regular",
  "TEXT_LINK_SMALL_SIZE_TEXT_NUMBER_SPACING": "default",
  "TEXT_LINK_SMALL_SIZE_TEXT_SCALE": "paragraph-20",
  "TEXT_LINK_SMALL_SIZE_TEXT_FONT_FAMILY": "Square Sans Text",
  "TEXT_LINK_SMALL_SIZE_VERTICAL_PADDING": 0,
  "TEXT_LINK_MEDIUM_SIZE_TEXT_SIZE": 16,
  "TEXT_LINK_MEDIUM_SIZE_TEXT_LEADING": 24,
  "TEXT_LINK_MEDIUM_SIZE_TEXT_TRACKING": 0,
  "TEXT_LINK_MEDIUM_SIZE_TEXT_WEIGHT": 600,
  "TEXT_LINK_MEDIUM_SIZE_TEXT_CASE": "regular",
  "TEXT_LINK_MEDIUM_SIZE_TEXT_NUMBER_SPACING": "default",
  "TEXT_LINK_MEDIUM_SIZE_TEXT_SCALE": "paragraph-30",
  "TEXT_LINK_MEDIUM_SIZE_TEXT_FONT_FAMILY": "Square Sans Text",
  "TEXT_LINK_MEDIUM_SIZE_VERTICAL_PADDING": 0,
  "TEXT_LINK_NORMAL_STATE_OPACITY": 1,
  "TEXT_LINK_HOVER_STATE_OPACITY": 0.6,
  "TEXT_LINK_PRESSED_STATE_OPACITY": 0.6,
  "TEXT_LINK_DISABLED_STATE_OPACITY": 0.3,
  "TEXT_LINK_NORMAL_VARIANT_DARK_MODE_COLOR": "rgb(74, 149, 255)",
  "TEXT_LINK_NORMAL_VARIANT_LIGHT_MODE_COLOR": "rgb(0, 90, 217)",
  "TEXT_LINK_NORMAL_VARIANT_COLOR": "rgb(0, 90, 217)",
  "TEXT_LINK_DESTRUCTIVE_VARIANT_DARK_MODE_COLOR": "rgb(255, 90, 118)",
  "TEXT_LINK_DESTRUCTIVE_VARIANT_LIGHT_MODE_COLOR": "rgb(191, 0, 32)",
  "TEXT_LINK_DESTRUCTIVE_VARIANT_COLOR": "rgb(191, 0, 32)",
  "TEXTAREA_FONT_SIZE": 16,
  "TEXTAREA_FONT_LEADING": 24,
  "TEXTAREA_FONT_TRACKING": 0,
  "TEXTAREA_FONT_WEIGHT": 400,
  "TEXTAREA_FONT_CASE": "regular",
  "TEXTAREA_FONT_NUMBER_SPACING": "default",
  "TEXTAREA_FONT_SCALE": "paragraph-30",
  "TEXTAREA_FONT_FONT_FAMILY": "Square Sans Text",
  "TEXTAREA_HEIGHT": 112,
  "TEXTAREA_MIN_HEIGHT": 112,
  "TEXTAREA_MINIMUM_HEIGHT": 112,
  "TEXTAREA_MAXIMUM_HEIGHT": 320,
  "TEXTAREA_PLACEHOLDER_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.15)",
  "TEXTAREA_PLACEHOLDER_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.15)",
  "TEXTAREA_PLACEHOLDER_COLOR": "rgba(0, 0, 0, 0.15)",
  "TOAST_BACKGROUND_LIGHT_MODE_COLOR": "rgb(26, 26, 26)",
  "TOAST_BACKGROUND_DARK_MODE_COLOR": "rgb(255, 255, 255)",
  "TOAST_BACKGROUND_COLOR": "rgb(26, 26, 26)",
  "TOAST_ACCENT_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "TOAST_ACCENT_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "TOAST_ACCENT_COLOR": "rgba(0, 0, 0, 0.9)",
  "TOAST_MAXIMUM_WIDTH": 600,
  "TOAST_HORIZONTAL_PADDING_SIZE": 16,
  "TOAST_VERTICAL_PADDING_SIZE": 16,
  "TOAST_RADIUS": 6,
  "TOAST_ICON_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "TOAST_ICON_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "TOAST_ICON_SPACING": 12,
  "TOAST_ICON_COLOR": "rgba(0, 0, 0, 0.9)",
  "TOAST_CONTENT_SPACING": 12,
  "TOAST_BUTTON_TEXT_SIZE": 16,
  "TOAST_BUTTON_TEXT_LEADING": 24,
  "TOAST_BUTTON_TEXT_TRACKING": 0,
  "TOAST_BUTTON_TEXT_WEIGHT": 600,
  "TOAST_BUTTON_TEXT_CASE": "regular",
  "TOAST_BUTTON_TEXT_NUMBER_SPACING": "default",
  "TOAST_BUTTON_TEXT_SCALE": "paragraph-30",
  "TOAST_BUTTON_TEXT_FONT_FAMILY": "Square Sans Text",
  "TOAST_BUTTON_SPACING": 12,
  "TOAST_SEPARATOR_WIDTH": 1,
  "TOAST_SEPARATOR_HEIGHT": 8,
  "TOAST_SEPARATOR_OPACITY": 0.3,
  "TOAST_TEXT_SIZE": 16,
  "TOAST_TEXT_LEADING": 24,
  "TOAST_TEXT_TRACKING": 0,
  "TOAST_TEXT_WEIGHT": 400,
  "TOAST_TEXT_CASE": "regular",
  "TOAST_TEXT_NUMBER_SPACING": "default",
  "TOAST_TEXT_SCALE": "paragraph-30",
  "TOAST_TEXT_FONT_FAMILY": "Square Sans Text",
  "TOAST_TEXT_LIGHT_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "TOAST_TEXT_DARK_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "TOAST_TEXT_MULTILINE_SPACING": 12,
  "TOAST_TEXT_COLOR": "rgba(255, 255, 255, 0.95)",
  "TOAST_LINK_SMALL_SIZE_TEXT_SIZE": 14,
  "TOAST_LINK_SMALL_SIZE_TEXT_LEADING": 22,
  "TOAST_LINK_SMALL_SIZE_TEXT_TRACKING": 0,
  "TOAST_LINK_SMALL_SIZE_TEXT_WEIGHT": 600,
  "TOAST_LINK_SMALL_SIZE_TEXT_CASE": "regular",
  "TOAST_LINK_SMALL_SIZE_TEXT_NUMBER_SPACING": "default",
  "TOAST_LINK_SMALL_SIZE_TEXT_SCALE": "paragraph-20",
  "TOAST_LINK_SMALL_SIZE_TEXT_FONT_FAMILY": "Square Sans Text",
  "TOAST_LINK_SMALL_SIZE_VERTICAL_PADDING": 0,
  "TOAST_LINK_MEDIUM_SIZE_TEXT_SIZE": 16,
  "TOAST_LINK_MEDIUM_SIZE_TEXT_LEADING": 24,
  "TOAST_LINK_MEDIUM_SIZE_TEXT_TRACKING": 0,
  "TOAST_LINK_MEDIUM_SIZE_TEXT_WEIGHT": 600,
  "TOAST_LINK_MEDIUM_SIZE_TEXT_CASE": "regular",
  "TOAST_LINK_MEDIUM_SIZE_TEXT_NUMBER_SPACING": "default",
  "TOAST_LINK_MEDIUM_SIZE_TEXT_SCALE": "paragraph-30",
  "TOAST_LINK_MEDIUM_SIZE_TEXT_FONT_FAMILY": "Square Sans Text",
  "TOAST_LINK_MEDIUM_SIZE_VERTICAL_PADDING": 0,
  "TOAST_LINK_NORMAL_STATE_OPACITY": 1,
  "TOAST_LINK_HOVER_STATE_OPACITY": 0.6,
  "TOAST_LINK_PRESSED_STATE_OPACITY": 0.6,
  "TOAST_LINK_DISABLED_STATE_OPACITY": 0.3,
  "TOAST_LINK_NORMAL_VARIANT_DARK_MODE_COLOR": "rgb(74, 149, 255)",
  "TOAST_LINK_NORMAL_VARIANT_LIGHT_MODE_COLOR": "rgb(0, 90, 217)",
  "TOAST_LINK_NORMAL_VARIANT_COLOR": "rgb(0, 90, 217)",
  "TOAST_LINK_DESTRUCTIVE_VARIANT_DARK_MODE_COLOR": "rgb(255, 90, 118)",
  "TOAST_LINK_DESTRUCTIVE_VARIANT_LIGHT_MODE_COLOR": "rgb(191, 0, 32)",
  "TOAST_LINK_DESTRUCTIVE_VARIANT_COLOR": "rgb(191, 0, 32)",
  "TOAST_PROGRESS_BAR_LIGHT_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "TOAST_PROGRESS_BAR_DARK_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "TOAST_PROGRESS_BAR_HEIGHT": 4,
  "TOAST_PROGRESS_BAR_COLOR": "rgba(255, 255, 255, 0.95)",
  "TOAST_DISMISS_BUTTON_LIGHT_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "TOAST_DISMISS_BUTTON_DARK_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "TOAST_DISMISS_BUTTON_COLOR": "rgba(255, 255, 255, 0.95)",
  "TOAST_INFO_VARIANT_BACKGROUND_LIGHT_MODE_COLOR": "rgb(26, 26, 26)",
  "TOAST_INFO_VARIANT_BACKGROUND_DARK_MODE_COLOR": "rgb(255, 255, 255)",
  "TOAST_INFO_VARIANT_BACKGROUND_COLOR": "rgb(26, 26, 26)",
  "TOAST_INFO_VARIANT_ACCENT_LIGHT_MODE_COLOR": "rgb(0, 106, 255)",
  "TOAST_INFO_VARIANT_ACCENT_DARK_MODE_COLOR": "rgb(0, 106, 255)",
  "TOAST_INFO_VARIANT_ACCENT_COLOR": "rgb(0, 106, 255)",
  "TOAST_INFO_VARIANT_TEXT_LIGHT_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "TOAST_INFO_VARIANT_TEXT_DARK_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "TOAST_INFO_VARIANT_TEXT_COLOR": "rgba(255, 255, 255, 0.95)",
  "TOAST_INFO_VARIANT_ICON_LIGHT_MODE_COLOR": "rgb(0, 106, 255)",
  "TOAST_INFO_VARIANT_ICON_DARK_MODE_COLOR": "rgb(0, 106, 255)",
  "TOAST_INFO_VARIANT_ICON_COLOR": "rgb(0, 106, 255)",
  "TOAST_INFO_VARIANT_LINK_LIGHT_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "TOAST_INFO_VARIANT_LINK_DARK_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "TOAST_INFO_VARIANT_LINK_COLOR": "rgba(255, 255, 255, 0.95)",
  "TOAST_INFO_VARIANT_BUTTON_TEXT_LIGHT_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "TOAST_INFO_VARIANT_BUTTON_TEXT_DARK_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "TOAST_INFO_VARIANT_BUTTON_TEXT_COLOR": "rgba(255, 255, 255, 0.95)",
  "TOAST_INFO_VARIANT_PROGRESS_BAR_LIGHT_MODE_COLOR": "rgb(0, 106, 255)",
  "TOAST_INFO_VARIANT_PROGRESS_BAR_DARK_MODE_COLOR": "rgb(0, 106, 255)",
  "TOAST_INFO_VARIANT_PROGRESS_BAR_COLOR": "rgb(0, 106, 255)",
  "TOAST_INFO_VARIANT_DISMISS_BUTTON_LIGHT_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "TOAST_INFO_VARIANT_DISMISS_BUTTON_DARK_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "TOAST_INFO_VARIANT_DISMISS_BUTTON_COLOR": "rgba(255, 255, 255, 0.95)",
  "TOAST_INFO_VARIANT_SEPARATOR_LIGHT_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "TOAST_INFO_VARIANT_SEPARATOR_DARK_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "TOAST_INFO_VARIANT_SEPARATOR_COLOR": "rgba(255, 255, 255, 0.95)",
  "TOAST_SUCCESS_VARIANT_BACKGROUND_LIGHT_MODE_COLOR": "rgb(26, 26, 26)",
  "TOAST_SUCCESS_VARIANT_BACKGROUND_DARK_MODE_COLOR": "rgb(255, 255, 255)",
  "TOAST_SUCCESS_VARIANT_BACKGROUND_COLOR": "rgb(26, 26, 26)",
  "TOAST_SUCCESS_VARIANT_ACCENT_LIGHT_MODE_COLOR": "rgb(0, 178, 59)",
  "TOAST_SUCCESS_VARIANT_ACCENT_DARK_MODE_COLOR": "rgb(0, 179, 59)",
  "TOAST_SUCCESS_VARIANT_ACCENT_COLOR": "rgb(0, 178, 59)",
  "TOAST_SUCCESS_VARIANT_TEXT_LIGHT_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "TOAST_SUCCESS_VARIANT_TEXT_DARK_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "TOAST_SUCCESS_VARIANT_TEXT_COLOR": "rgba(255, 255, 255, 0.95)",
  "TOAST_SUCCESS_VARIANT_ICON_LIGHT_MODE_COLOR": "rgb(0, 178, 59)",
  "TOAST_SUCCESS_VARIANT_ICON_DARK_MODE_COLOR": "rgb(0, 179, 59)",
  "TOAST_SUCCESS_VARIANT_ICON_COLOR": "rgb(0, 178, 59)",
  "TOAST_SUCCESS_VARIANT_LINK_LIGHT_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "TOAST_SUCCESS_VARIANT_LINK_DARK_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "TOAST_SUCCESS_VARIANT_LINK_COLOR": "rgba(255, 255, 255, 0.95)",
  "TOAST_SUCCESS_VARIANT_BUTTON_TEXT_LIGHT_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "TOAST_SUCCESS_VARIANT_BUTTON_TEXT_DARK_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "TOAST_SUCCESS_VARIANT_BUTTON_TEXT_COLOR": "rgba(255, 255, 255, 0.95)",
  "TOAST_SUCCESS_VARIANT_PROGRESS_BAR_LIGHT_MODE_COLOR": "rgb(0, 178, 59)",
  "TOAST_SUCCESS_VARIANT_PROGRESS_BAR_DARK_MODE_COLOR": "rgb(0, 179, 59)",
  "TOAST_SUCCESS_VARIANT_PROGRESS_BAR_COLOR": "rgb(0, 178, 59)",
  "TOAST_SUCCESS_VARIANT_DISMISS_BUTTON_LIGHT_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "TOAST_SUCCESS_VARIANT_DISMISS_BUTTON_DARK_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "TOAST_SUCCESS_VARIANT_DISMISS_BUTTON_COLOR": "rgba(255, 255, 255, 0.95)",
  "TOAST_SUCCESS_VARIANT_SEPARATOR_LIGHT_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "TOAST_SUCCESS_VARIANT_SEPARATOR_DARK_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "TOAST_SUCCESS_VARIANT_SEPARATOR_COLOR": "rgba(255, 255, 255, 0.95)",
  "TOAST_WARNING_VARIANT_BACKGROUND_LIGHT_MODE_COLOR": "rgb(26, 26, 26)",
  "TOAST_WARNING_VARIANT_BACKGROUND_DARK_MODE_COLOR": "rgb(255, 255, 255)",
  "TOAST_WARNING_VARIANT_BACKGROUND_COLOR": "rgb(26, 26, 26)",
  "TOAST_WARNING_VARIANT_ACCENT_LIGHT_MODE_COLOR": "rgb(255, 159, 64)",
  "TOAST_WARNING_VARIANT_ACCENT_DARK_MODE_COLOR": "rgb(255, 159, 64)",
  "TOAST_WARNING_VARIANT_ACCENT_COLOR": "rgb(255, 159, 64)",
  "TOAST_WARNING_VARIANT_TEXT_LIGHT_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "TOAST_WARNING_VARIANT_TEXT_DARK_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "TOAST_WARNING_VARIANT_TEXT_COLOR": "rgba(255, 255, 255, 0.95)",
  "TOAST_WARNING_VARIANT_ICON_LIGHT_MODE_COLOR": "rgb(255, 159, 64)",
  "TOAST_WARNING_VARIANT_ICON_DARK_MODE_COLOR": "rgb(255, 159, 64)",
  "TOAST_WARNING_VARIANT_ICON_COLOR": "rgb(255, 159, 64)",
  "TOAST_WARNING_VARIANT_LINK_LIGHT_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "TOAST_WARNING_VARIANT_LINK_DARK_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "TOAST_WARNING_VARIANT_LINK_COLOR": "rgba(255, 255, 255, 0.95)",
  "TOAST_WARNING_VARIANT_BUTTON_TEXT_LIGHT_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "TOAST_WARNING_VARIANT_BUTTON_TEXT_DARK_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "TOAST_WARNING_VARIANT_BUTTON_TEXT_COLOR": "rgba(255, 255, 255, 0.95)",
  "TOAST_WARNING_VARIANT_PROGRESS_BAR_LIGHT_MODE_COLOR": "rgb(255, 159, 64)",
  "TOAST_WARNING_VARIANT_PROGRESS_BAR_DARK_MODE_COLOR": "rgb(255, 159, 64)",
  "TOAST_WARNING_VARIANT_PROGRESS_BAR_COLOR": "rgb(255, 159, 64)",
  "TOAST_WARNING_VARIANT_DISMISS_BUTTON_LIGHT_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "TOAST_WARNING_VARIANT_DISMISS_BUTTON_DARK_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "TOAST_WARNING_VARIANT_DISMISS_BUTTON_COLOR": "rgba(255, 255, 255, 0.95)",
  "TOAST_WARNING_VARIANT_SEPARATOR_LIGHT_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "TOAST_WARNING_VARIANT_SEPARATOR_DARK_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "TOAST_WARNING_VARIANT_SEPARATOR_COLOR": "rgba(255, 255, 255, 0.95)",
  "TOAST_CRITICAL_VARIANT_BACKGROUND_LIGHT_MODE_COLOR": "rgb(204, 0, 35)",
  "TOAST_CRITICAL_VARIANT_BACKGROUND_DARK_MODE_COLOR": "rgb(204, 0, 35)",
  "TOAST_CRITICAL_VARIANT_BACKGROUND_COLOR": "rgb(204, 0, 35)",
  "TOAST_CRITICAL_VARIANT_ACCENT_LIGHT_MODE_COLOR": "rgb(255, 255, 255)",
  "TOAST_CRITICAL_VARIANT_ACCENT_DARK_MODE_COLOR": "rgb(255, 255, 255)",
  "TOAST_CRITICAL_VARIANT_ACCENT_COLOR": "rgb(255, 255, 255)",
  "TOAST_CRITICAL_VARIANT_TEXT_LIGHT_MODE_COLOR": "rgb(255, 255, 255)",
  "TOAST_CRITICAL_VARIANT_TEXT_DARK_MODE_COLOR": "rgb(255, 255, 255)",
  "TOAST_CRITICAL_VARIANT_TEXT_COLOR": "rgb(255, 255, 255)",
  "TOAST_CRITICAL_VARIANT_ICON_LIGHT_MODE_COLOR": "rgb(255, 255, 255)",
  "TOAST_CRITICAL_VARIANT_ICON_DARK_MODE_COLOR": "rgb(255, 255, 255)",
  "TOAST_CRITICAL_VARIANT_ICON_COLOR": "rgb(255, 255, 255)",
  "TOAST_CRITICAL_VARIANT_LINK_LIGHT_MODE_COLOR": "rgb(255, 255, 255)",
  "TOAST_CRITICAL_VARIANT_LINK_DARK_MODE_COLOR": "rgb(255, 255, 255)",
  "TOAST_CRITICAL_VARIANT_LINK_COLOR": "rgb(255, 255, 255)",
  "TOAST_CRITICAL_VARIANT_BUTTON_TEXT_LIGHT_MODE_COLOR": "rgb(255, 255, 255)",
  "TOAST_CRITICAL_VARIANT_BUTTON_TEXT_DARK_MODE_COLOR": "rgb(255, 255, 255)",
  "TOAST_CRITICAL_VARIANT_BUTTON_TEXT_COLOR": "rgb(255, 255, 255)",
  "TOAST_CRITICAL_VARIANT_PROGRESS_BAR_LIGHT_MODE_COLOR": "rgb(255, 255, 255)",
  "TOAST_CRITICAL_VARIANT_PROGRESS_BAR_DARK_MODE_COLOR": "rgb(255, 255, 255)",
  "TOAST_CRITICAL_VARIANT_PROGRESS_BAR_COLOR": "rgb(255, 255, 255)",
  "TOAST_CRITICAL_VARIANT_DISMISS_BUTTON_LIGHT_MODE_COLOR": "rgb(255, 255, 255)",
  "TOAST_CRITICAL_VARIANT_DISMISS_BUTTON_DARK_MODE_COLOR": "rgb(255, 255, 255)",
  "TOAST_CRITICAL_VARIANT_DISMISS_BUTTON_COLOR": "rgb(255, 255, 255)",
  "TOAST_CRITICAL_VARIANT_SEPARATOR_LIGHT_MODE_COLOR": "rgb(255, 255, 255)",
  "TOAST_CRITICAL_VARIANT_SEPARATOR_DARK_MODE_COLOR": "rgb(255, 255, 255)",
  "TOAST_CRITICAL_VARIANT_SEPARATOR_COLOR": "rgb(255, 255, 255)",
  "TOAST_INSIGHT_VARIANT_BACKGROUND_LIGHT_MODE_COLOR": "rgb(255, 255, 255)",
  "TOAST_INSIGHT_VARIANT_BACKGROUND_DARK_MODE_COLOR": "rgb(20, 20, 20)",
  "TOAST_INSIGHT_VARIANT_BACKGROUND_COLOR": "rgb(255, 255, 255)",
  "TOAST_INSIGHT_VARIANT_ACCENT_LIGHT_MODE_COLOR": "rgb(135, 22, 217)",
  "TOAST_INSIGHT_VARIANT_ACCENT_DARK_MODE_COLOR": "rgb(135, 22, 217)",
  "TOAST_INSIGHT_VARIANT_ACCENT_COLOR": "rgb(135, 22, 217)",
  "TOAST_INSIGHT_VARIANT_TEXT_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "TOAST_INSIGHT_VARIANT_TEXT_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "TOAST_INSIGHT_VARIANT_TEXT_COLOR": "rgba(0, 0, 0, 0.9)",
  "TOAST_INSIGHT_VARIANT_ICON_LIGHT_MODE_COLOR": "rgb(135, 22, 217)",
  "TOAST_INSIGHT_VARIANT_ICON_DARK_MODE_COLOR": "rgb(135, 22, 217)",
  "TOAST_INSIGHT_VARIANT_ICON_COLOR": "rgb(135, 22, 217)",
  "TOAST_INSIGHT_VARIANT_LINK_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "TOAST_INSIGHT_VARIANT_LINK_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "TOAST_INSIGHT_VARIANT_LINK_COLOR": "rgba(0, 0, 0, 0.9)",
  "TOAST_INSIGHT_VARIANT_BUTTON_TEXT_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "TOAST_INSIGHT_VARIANT_BUTTON_TEXT_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "TOAST_INSIGHT_VARIANT_BUTTON_TEXT_COLOR": "rgba(0, 0, 0, 0.9)",
  "TOAST_INSIGHT_VARIANT_PROGRESS_BAR_LIGHT_MODE_COLOR": "rgb(135, 22, 217)",
  "TOAST_INSIGHT_VARIANT_PROGRESS_BAR_DARK_MODE_COLOR": "rgb(135, 22, 217)",
  "TOAST_INSIGHT_VARIANT_PROGRESS_BAR_COLOR": "rgb(135, 22, 217)",
  "TOAST_INSIGHT_VARIANT_DISMISS_BUTTON_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "TOAST_INSIGHT_VARIANT_DISMISS_BUTTON_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "TOAST_INSIGHT_VARIANT_DISMISS_BUTTON_COLOR": "rgba(0, 0, 0, 0.9)",
  "TOAST_INSIGHT_VARIANT_SEPARATOR_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "TOAST_INSIGHT_VARIANT_SEPARATOR_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "TOAST_INSIGHT_VARIANT_SEPARATOR_COLOR": "rgba(0, 0, 0, 0.9)",
  "TOGGLE_WIDTH": 40,
  "TOGGLE_HEIGHT": 24,
  "TOGGLE_BORDER_RADIUS": 100,
  "TOGGLE_BORDER_SIZE": 2,
  "TOGGLE_PADDING_VERTICAL": 5,
  "TOGGLE_PADDING_HORIZONTAL": 5,
  "TOGGLE_FOCUS_RING_BUFFER_SIZE": 0,
  "TOGGLE_FOCUS_RING_BORDER_SIZE": 2,
  "TOGGLE_FOCUS_RING_LIGHT_MODE_COLOR": "rgb(0, 106, 255)",
  "TOGGLE_FOCUS_RING_DARK_MODE_COLOR": "rgb(0, 106, 255)",
  "TOGGLE_FOCUS_RING_COLOR": "rgb(0, 106, 255)",
  "TOGGLE_NORMAL_STATE_UNSELECTED_VALUE_LIGHT_MODE_BORDER_COLOR": "rgba(0, 0, 0, 0.3)",
  "TOGGLE_NORMAL_STATE_UNSELECTED_VALUE_LIGHT_MODE_TRACK_COLOR": "rgba(0, 0, 0, 0)",
  "TOGGLE_NORMAL_STATE_UNSELECTED_VALUE_LIGHT_MODE_THUMB_COLOR": "rgba(0, 0, 0, 0.3)",
  "TOGGLE_NORMAL_STATE_UNSELECTED_VALUE_DARK_MODE_BORDER_COLOR": "rgba(255, 255, 255, 0.3)",
  "TOGGLE_NORMAL_STATE_UNSELECTED_VALUE_DARK_MODE_TRACK_COLOR": "rgba(0, 0, 0, 0)",
  "TOGGLE_NORMAL_STATE_UNSELECTED_VALUE_DARK_MODE_THUMB_COLOR": "rgba(255, 255, 255, 0.3)",
  "TOGGLE_NORMAL_STATE_UNSELECTED_VALUE_BORDER_COLOR": "rgba(0, 0, 0, 0.3)",
  "TOGGLE_NORMAL_STATE_UNSELECTED_VALUE_TRACK_COLOR": "rgba(0, 0, 0, 0)",
  "TOGGLE_NORMAL_STATE_UNSELECTED_VALUE_THUMB_COLOR": "rgba(0, 0, 0, 0.3)",
  "TOGGLE_NORMAL_STATE_SELECTED_VALUE_LIGHT_MODE_BORDER_COLOR": "rgb(0, 106, 255)",
  "TOGGLE_NORMAL_STATE_SELECTED_VALUE_LIGHT_MODE_TRACK_COLOR": "rgb(0, 106, 255)",
  "TOGGLE_NORMAL_STATE_SELECTED_VALUE_LIGHT_MODE_THUMB_COLOR": "rgb(255, 255, 255)",
  "TOGGLE_NORMAL_STATE_SELECTED_VALUE_DARK_MODE_BORDER_COLOR": "rgb(0, 106, 255)",
  "TOGGLE_NORMAL_STATE_SELECTED_VALUE_DARK_MODE_TRACK_COLOR": "rgb(0, 106, 255)",
  "TOGGLE_NORMAL_STATE_SELECTED_VALUE_DARK_MODE_THUMB_COLOR": "rgb(255, 255, 255)",
  "TOGGLE_NORMAL_STATE_SELECTED_VALUE_BORDER_COLOR": "rgb(0, 106, 255)",
  "TOGGLE_NORMAL_STATE_SELECTED_VALUE_TRACK_COLOR": "rgb(0, 106, 255)",
  "TOGGLE_NORMAL_STATE_SELECTED_VALUE_THUMB_COLOR": "rgb(255, 255, 255)",
  "TOGGLE_HOVER_STATE_UNSELECTED_VALUE_LIGHT_MODE_BORDER_COLOR": "rgb(0, 106, 255)",
  "TOGGLE_HOVER_STATE_UNSELECTED_VALUE_LIGHT_MODE_TRACK_COLOR": "rgba(0, 0, 0, 0)",
  "TOGGLE_HOVER_STATE_UNSELECTED_VALUE_LIGHT_MODE_THUMB_COLOR": "rgb(0, 106, 255)",
  "TOGGLE_HOVER_STATE_UNSELECTED_VALUE_DARK_MODE_BORDER_COLOR": "rgb(0, 106, 255)",
  "TOGGLE_HOVER_STATE_UNSELECTED_VALUE_DARK_MODE_TRACK_COLOR": "rgba(0, 0, 0, 0)",
  "TOGGLE_HOVER_STATE_UNSELECTED_VALUE_DARK_MODE_THUMB_COLOR": "rgb(0, 106, 255)",
  "TOGGLE_HOVER_STATE_UNSELECTED_VALUE_BORDER_COLOR": "rgb(0, 106, 255)",
  "TOGGLE_HOVER_STATE_UNSELECTED_VALUE_TRACK_COLOR": "rgba(0, 0, 0, 0)",
  "TOGGLE_HOVER_STATE_UNSELECTED_VALUE_THUMB_COLOR": "rgb(0, 106, 255)",
  "TOGGLE_HOVER_STATE_SELECTED_VALUE_LIGHT_MODE_BORDER_COLOR": "rgb(0, 95, 229)",
  "TOGGLE_HOVER_STATE_SELECTED_VALUE_LIGHT_MODE_TRACK_COLOR": "rgb(0, 95, 229)",
  "TOGGLE_HOVER_STATE_SELECTED_VALUE_LIGHT_MODE_THUMB_COLOR": "rgb(255, 255, 255)",
  "TOGGLE_HOVER_STATE_SELECTED_VALUE_DARK_MODE_BORDER_COLOR": "rgb(0, 95, 229)",
  "TOGGLE_HOVER_STATE_SELECTED_VALUE_DARK_MODE_TRACK_COLOR": "rgb(0, 95, 229)",
  "TOGGLE_HOVER_STATE_SELECTED_VALUE_DARK_MODE_THUMB_COLOR": "rgb(255, 255, 255)",
  "TOGGLE_HOVER_STATE_SELECTED_VALUE_BORDER_COLOR": "rgb(0, 95, 229)",
  "TOGGLE_HOVER_STATE_SELECTED_VALUE_TRACK_COLOR": "rgb(0, 95, 229)",
  "TOGGLE_HOVER_STATE_SELECTED_VALUE_THUMB_COLOR": "rgb(255, 255, 255)",
  "TOGGLE_PRESSED_STATE_UNSELECTED_VALUE_LIGHT_MODE_BORDER_COLOR": "rgb(0, 85, 204)",
  "TOGGLE_PRESSED_STATE_UNSELECTED_VALUE_LIGHT_MODE_TRACK_COLOR": "rgba(0, 0, 0, 0)",
  "TOGGLE_PRESSED_STATE_UNSELECTED_VALUE_LIGHT_MODE_THUMB_COLOR": "rgb(0, 85, 204)",
  "TOGGLE_PRESSED_STATE_UNSELECTED_VALUE_DARK_MODE_BORDER_COLOR": "rgb(0, 85, 204)",
  "TOGGLE_PRESSED_STATE_UNSELECTED_VALUE_DARK_MODE_TRACK_COLOR": "rgba(0, 0, 0, 0)",
  "TOGGLE_PRESSED_STATE_UNSELECTED_VALUE_DARK_MODE_THUMB_COLOR": "rgb(0, 85, 204)",
  "TOGGLE_PRESSED_STATE_UNSELECTED_VALUE_BORDER_COLOR": "rgb(0, 85, 204)",
  "TOGGLE_PRESSED_STATE_UNSELECTED_VALUE_TRACK_COLOR": "rgba(0, 0, 0, 0)",
  "TOGGLE_PRESSED_STATE_UNSELECTED_VALUE_THUMB_COLOR": "rgb(0, 85, 204)",
  "TOGGLE_PRESSED_STATE_SELECTED_VALUE_LIGHT_MODE_BORDER_COLOR": "rgb(0, 85, 204)",
  "TOGGLE_PRESSED_STATE_SELECTED_VALUE_LIGHT_MODE_TRACK_COLOR": "rgb(0, 85, 204)",
  "TOGGLE_PRESSED_STATE_SELECTED_VALUE_LIGHT_MODE_THUMB_COLOR": "rgb(255, 255, 255)",
  "TOGGLE_PRESSED_STATE_SELECTED_VALUE_DARK_MODE_BORDER_COLOR": "rgb(0, 85, 204)",
  "TOGGLE_PRESSED_STATE_SELECTED_VALUE_DARK_MODE_TRACK_COLOR": "rgb(0, 85, 204)",
  "TOGGLE_PRESSED_STATE_SELECTED_VALUE_DARK_MODE_THUMB_COLOR": "rgb(255, 255, 255)",
  "TOGGLE_PRESSED_STATE_SELECTED_VALUE_BORDER_COLOR": "rgb(0, 85, 204)",
  "TOGGLE_PRESSED_STATE_SELECTED_VALUE_TRACK_COLOR": "rgb(0, 85, 204)",
  "TOGGLE_PRESSED_STATE_SELECTED_VALUE_THUMB_COLOR": "rgb(255, 255, 255)",
  "TOGGLE_FOCUS_STATE_UNSELECTED_VALUE_LIGHT_MODE_BORDER_COLOR": "rgb(0, 106, 255)",
  "TOGGLE_FOCUS_STATE_UNSELECTED_VALUE_LIGHT_MODE_TRACK_COLOR": "rgba(0, 0, 0, 0)",
  "TOGGLE_FOCUS_STATE_UNSELECTED_VALUE_LIGHT_MODE_THUMB_COLOR": "rgb(0, 106, 255)",
  "TOGGLE_FOCUS_STATE_UNSELECTED_VALUE_DARK_MODE_BORDER_COLOR": "rgb(0, 106, 255)",
  "TOGGLE_FOCUS_STATE_UNSELECTED_VALUE_DARK_MODE_TRACK_COLOR": "rgba(0, 0, 0, 0)",
  "TOGGLE_FOCUS_STATE_UNSELECTED_VALUE_DARK_MODE_THUMB_COLOR": "rgb(0, 106, 255)",
  "TOGGLE_FOCUS_STATE_UNSELECTED_VALUE_BORDER_COLOR": "rgb(0, 106, 255)",
  "TOGGLE_FOCUS_STATE_UNSELECTED_VALUE_TRACK_COLOR": "rgba(0, 0, 0, 0)",
  "TOGGLE_FOCUS_STATE_UNSELECTED_VALUE_THUMB_COLOR": "rgb(0, 106, 255)",
  "TOGGLE_FOCUS_STATE_SELECTED_VALUE_LIGHT_MODE_BORDER_COLOR": "rgb(0, 95, 229)",
  "TOGGLE_FOCUS_STATE_SELECTED_VALUE_LIGHT_MODE_TRACK_COLOR": "rgb(0, 95, 229)",
  "TOGGLE_FOCUS_STATE_SELECTED_VALUE_LIGHT_MODE_THUMB_COLOR": "rgb(255, 255, 255)",
  "TOGGLE_FOCUS_STATE_SELECTED_VALUE_DARK_MODE_BORDER_COLOR": "rgb(0, 95, 229)",
  "TOGGLE_FOCUS_STATE_SELECTED_VALUE_DARK_MODE_TRACK_COLOR": "rgb(0, 95, 229)",
  "TOGGLE_FOCUS_STATE_SELECTED_VALUE_DARK_MODE_THUMB_COLOR": "rgb(255, 255, 255)",
  "TOGGLE_FOCUS_STATE_SELECTED_VALUE_BORDER_COLOR": "rgb(0, 95, 229)",
  "TOGGLE_FOCUS_STATE_SELECTED_VALUE_TRACK_COLOR": "rgb(0, 95, 229)",
  "TOGGLE_FOCUS_STATE_SELECTED_VALUE_THUMB_COLOR": "rgb(255, 255, 255)",
  "TOGGLE_DISABLED_STATE_UNSELECTED_VALUE_LIGHT_MODE_BORDER_COLOR": "rgba(0, 0, 0, 0.03)",
  "TOGGLE_DISABLED_STATE_UNSELECTED_VALUE_LIGHT_MODE_TRACK_COLOR": "rgba(0, 0, 0, 0.03)",
  "TOGGLE_DISABLED_STATE_UNSELECTED_VALUE_LIGHT_MODE_THUMB_COLOR": "rgba(0, 0, 0, 0.15)",
  "TOGGLE_DISABLED_STATE_UNSELECTED_VALUE_DARK_MODE_BORDER_COLOR": "rgba(255, 255, 255, 0.05)",
  "TOGGLE_DISABLED_STATE_UNSELECTED_VALUE_DARK_MODE_TRACK_COLOR": "rgba(255, 255, 255, 0.05)",
  "TOGGLE_DISABLED_STATE_UNSELECTED_VALUE_DARK_MODE_THUMB_COLOR": "rgba(255, 255, 255, 0.15)",
  "TOGGLE_DISABLED_STATE_UNSELECTED_VALUE_BORDER_COLOR": "rgba(0, 0, 0, 0.03)",
  "TOGGLE_DISABLED_STATE_UNSELECTED_VALUE_TRACK_COLOR": "rgba(0, 0, 0, 0.03)",
  "TOGGLE_DISABLED_STATE_UNSELECTED_VALUE_THUMB_COLOR": "rgba(0, 0, 0, 0.15)",
  "TOGGLE_DISABLED_STATE_SELECTED_VALUE_LIGHT_MODE_BORDER_COLOR": "rgba(0, 0, 0, 0)",
  "TOGGLE_DISABLED_STATE_SELECTED_VALUE_LIGHT_MODE_TRACK_COLOR": "rgba(0, 0, 0, 0.3)",
  "TOGGLE_DISABLED_STATE_SELECTED_VALUE_LIGHT_MODE_THUMB_COLOR": "rgb(255, 255, 255)",
  "TOGGLE_DISABLED_STATE_SELECTED_VALUE_DARK_MODE_BORDER_COLOR": "rgba(0, 0, 0, 0)",
  "TOGGLE_DISABLED_STATE_SELECTED_VALUE_DARK_MODE_TRACK_COLOR": "rgba(255, 255, 255, 0.3)",
  "TOGGLE_DISABLED_STATE_SELECTED_VALUE_DARK_MODE_THUMB_COLOR": "rgb(255, 255, 255)",
  "TOGGLE_DISABLED_STATE_SELECTED_VALUE_BORDER_COLOR": "rgba(0, 0, 0, 0)",
  "TOGGLE_DISABLED_STATE_SELECTED_VALUE_TRACK_COLOR": "rgba(0, 0, 0, 0.3)",
  "TOGGLE_DISABLED_STATE_SELECTED_VALUE_THUMB_COLOR": "rgb(255, 255, 255)",
  "TOOLTIP_MINIMUM_WIDTH": 40,
  "TOOLTIP_MINIMUM_HEIGHT": 40,
  "TOOLTIP_MAXIMUM_WIDTH": 400,
  "TOOLTIP_RADIUS": 6,
  "TOOLTIP_BACKGROUND_LIGHT_MODE_COLOR": "rgb(26, 26, 26)",
  "TOOLTIP_BACKGROUND_DARK_MODE_COLOR": "rgb(255, 255, 255)",
  "TOOLTIP_BACKGROUND_COLOR": "rgb(26, 26, 26)",
  "TOOLTIP_PADDING_VERTICAL_SIZE": 8,
  "TOOLTIP_PADDING_HORIZONTAL_SIZE": 12,
  "TOOLTIP_TEXT_SIZE": 14,
  "TOOLTIP_TEXT_LEADING": 22,
  "TOOLTIP_TEXT_TRACKING": 0,
  "TOOLTIP_TEXT_WEIGHT": 400,
  "TOOLTIP_TEXT_CASE": "regular",
  "TOOLTIP_TEXT_NUMBER_SPACING": "default",
  "TOOLTIP_TEXT_SCALE": "paragraph-20",
  "TOOLTIP_TEXT_FONT_FAMILY": "Square Sans Text",
  "TOOLTIP_TEXT_LIGHT_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "TOOLTIP_TEXT_DARK_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "TOOLTIP_TEXT_COLOR": "rgba(255, 255, 255, 0.95)",
  "TOOLTIP_LINK_SIZE": 14,
  "TOOLTIP_LINK_LEADING": 22,
  "TOOLTIP_LINK_TRACKING": 0,
  "TOOLTIP_LINK_WEIGHT": 600,
  "TOOLTIP_LINK_CASE": "regular",
  "TOOLTIP_LINK_NUMBER_SPACING": "default",
  "TOOLTIP_LINK_SCALE": "paragraph-20",
  "TOOLTIP_LINK_FONT_FAMILY": "Square Sans Text",
  "TOOLTIP_LINK_LIGHT_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "TOOLTIP_LINK_DARK_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "TOOLTIP_LINK_COLOR": "rgba(255, 255, 255, 0.95)",
  "TOOLTIP_ANIMATION_MOVE_TRANSITION_DURATION": 240,
  "TOOLTIP_TRIGGER_ICON_NORMAL_STATE_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.3)",
  "TOOLTIP_TRIGGER_ICON_NORMAL_STATE_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.3)",
  "TOOLTIP_TRIGGER_ICON_NORMAL_STATE_COLOR": "rgba(0, 0, 0, 0.3)",
  "TOOLTIP_TRIGGER_ICON_ACTIVE_STATE_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "TOOLTIP_TRIGGER_ICON_ACTIVE_STATE_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "TOOLTIP_TRIGGER_ICON_ACTIVE_STATE_COLOR": "rgba(0, 0, 0, 0.9)",
  "TOOLTIP_TRIGGER_ICON_DISABLED_STATE_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.15)",
  "TOOLTIP_TRIGGER_ICON_DISABLED_STATE_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.15)",
  "TOOLTIP_TRIGGER_ICON_DISABLED_STATE_COLOR": "rgba(0, 0, 0, 0.15)",
  "TOOLTIP_TRIGGER_TEXT_NORMAL_STATE_LIGHT_MODE_COLOR": "rgb(0, 90, 217)",
  "TOOLTIP_TRIGGER_TEXT_NORMAL_STATE_DARK_MODE_COLOR": "rgb(74, 149, 255)",
  "TOOLTIP_TRIGGER_TEXT_NORMAL_STATE_COLOR": "rgb(0, 90, 217)",
  "TOOLTIP_TRIGGER_TEXT_ACTIVE_STATE_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "TOOLTIP_TRIGGER_TEXT_ACTIVE_STATE_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "TOOLTIP_TRIGGER_TEXT_ACTIVE_STATE_COLOR": "rgba(0, 0, 0, 0.9)",
  "TOOLTIP_TRIGGER_TEXT_UNDERLINE_SIZE": 1,
  "TOOLTIP_TRIGGER_ANIMATION_FADE_TRANSITION_DURATION": 240,
  "CORE_ANIMATION_ENTER_TRANSITION_EASING": "cubic-bezier(0.26, 0.10, 0.48, 1.0)",
  "CORE_ANIMATION_ENTER_TRANSITION_FAST_SPEED_DURATION": 100,
  "CORE_ANIMATION_ENTER_TRANSITION_MODERATE_SPEED_DURATION": 240,
  "CORE_ANIMATION_ENTER_TRANSITION_SLOW_SPEED_DURATION": 400,
  "CORE_ANIMATION_EXIT_TRANSITION_EASING": "cubic-bezier(0.52, 0.0, 0.74, 0.0)",
  "CORE_ANIMATION_EXIT_TRANSITION_FAST_SPEED_DURATION": 100,
  "CORE_ANIMATION_EXIT_TRANSITION_MODERATE_SPEED_DURATION": 160,
  "CORE_ANIMATION_EXIT_TRANSITION_SLOW_SPEED_DURATION": 300,
  "CORE_ANIMATION_MOVE_TRANSITION_EASING": "cubic-bezier(0.76, 0.0, 0.24, 1.0)",
  "CORE_ANIMATION_MOVE_TRANSITION_FAST_SPEED_DURATION": 100,
  "CORE_ANIMATION_MOVE_TRANSITION_MODERATE_SPEED_DURATION": 240,
  "CORE_ANIMATION_MOVE_TRANSITION_SLOW_SPEED_DURATION": 400,
  "CORE_BORDER_10": 1,
  "CORE_BREAKPOINT_EXTRA_WIDE_MIN_WIDTH": 1024,
  "CORE_BREAKPOINT_WIDE_MIN_WIDTH": 800,
  "CORE_BREAKPOINT_WIDE_MIN_MAX_WIDTH": 1023,
  "CORE_BREAKPOINT_MEDIUM_MIN_WIDTH": 600,
  "CORE_BREAKPOINT_MEDIUM_MAX_WIDTH": 839,
  "CORE_BREAKPOINT_NARROW_MIN_WIDTH": 0,
  "CORE_BREAKPOINT_NARROW_MAX_WIDTH": 599,
  "CORE_TEXT_10_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "CORE_TEXT_10_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "CORE_TEXT_10_COLOR": "rgba(0, 0, 0, 0.9)",
  "CORE_TEXT_20_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.55)",
  "CORE_TEXT_20_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.55)",
  "CORE_TEXT_20_COLOR": "rgba(0, 0, 0, 0.55)",
  "CORE_TEXT_30_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.3)",
  "CORE_TEXT_30_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.3)",
  "CORE_TEXT_30_COLOR": "rgba(0, 0, 0, 0.3)",
  "CORE_TEXT_INVERSE_LIGHT_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "CORE_TEXT_INVERSE_DARK_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "CORE_TEXT_INVERSE_COLOR": "rgba(255, 255, 255, 0.95)",
  "CORE_TEXT_BLACK_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "CORE_TEXT_BLACK_DARK_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "CORE_TEXT_BLACK_COLOR": "rgba(0, 0, 0, 0.9)",
  "CORE_TEXT_WHITE_LIGHT_MODE_COLOR": "rgb(255, 255, 255)",
  "CORE_TEXT_WHITE_DARK_MODE_COLOR": "rgb(255, 255, 255)",
  "CORE_TEXT_WHITE_COLOR": "rgb(255, 255, 255)",
  "CORE_FILL_10_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "CORE_FILL_10_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "CORE_FILL_10_COLOR": "rgba(0, 0, 0, 0.9)",
  "CORE_FILL_20_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.3)",
  "CORE_FILL_20_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.3)",
  "CORE_FILL_20_COLOR": "rgba(0, 0, 0, 0.3)",
  "CORE_FILL_30_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.15)",
  "CORE_FILL_30_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.15)",
  "CORE_FILL_30_COLOR": "rgba(0, 0, 0, 0.15)",
  "CORE_FILL_40_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.05)",
  "CORE_FILL_40_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.08)",
  "CORE_FILL_40_COLOR": "rgba(0, 0, 0, 0.05)",
  "CORE_FILL_50_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.03)",
  "CORE_FILL_50_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.05)",
  "CORE_FILL_50_COLOR": "rgba(0, 0, 0, 0.03)",
  "CORE_FILL_INVERSE_LIGHT_MODE_COLOR": "rgba(255, 255, 255, 0.95)",
  "CORE_FILL_INVERSE_DARK_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "CORE_FILL_INVERSE_COLOR": "rgba(255, 255, 255, 0.95)",
  "CORE_FILL_BLACK_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "CORE_FILL_BLACK_DARK_MODE_COLOR": "rgba(0, 0, 0, 0.9)",
  "CORE_FILL_BLACK_COLOR": "rgba(0, 0, 0, 0.9)",
  "CORE_FILL_WHITE_LIGHT_MODE_COLOR": "rgb(255, 255, 255)",
  "CORE_FILL_WHITE_DARK_MODE_COLOR": "rgb(255, 255, 255)",
  "CORE_FILL_WHITE_COLOR": "rgb(255, 255, 255)",
  "CORE_DIVIDER_10_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.3)",
  "CORE_DIVIDER_10_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.3)",
  "CORE_DIVIDER_10_COLOR": "rgba(0, 0, 0, 0.3)",
  "CORE_DIVIDER_20_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.05)",
  "CORE_DIVIDER_20_DARK_MODE_COLOR": "rgba(255, 255, 255, 0.08)",
  "CORE_DIVIDER_20_COLOR": "rgba(0, 0, 0, 0.05)",
  "CORE_SURFACE_5_LIGHT_MODE_COLOR": "rgb(255, 255, 255)",
  "CORE_SURFACE_5_DARK_MODE_COLOR": "rgb(8, 8, 8)",
  "CORE_SURFACE_5_COLOR": "rgb(255, 255, 255)",
  "CORE_SURFACE_10_LIGHT_MODE_COLOR": "rgb(255, 255, 255)",
  "CORE_SURFACE_10_DARK_MODE_COLOR": "rgb(20, 20, 20)",
  "CORE_SURFACE_10_COLOR": "rgb(255, 255, 255)",
  "CORE_SURFACE_20_LIGHT_MODE_COLOR": "rgb(255, 255, 255)",
  "CORE_SURFACE_20_DARK_MODE_COLOR": "rgb(28, 28, 28)",
  "CORE_SURFACE_20_COLOR": "rgb(255, 255, 255)",
  "CORE_SURFACE_30_LIGHT_MODE_COLOR": "rgb(255, 255, 255)",
  "CORE_SURFACE_30_DARK_MODE_COLOR": "rgb(45, 45, 45)",
  "CORE_SURFACE_30_COLOR": "rgb(255, 255, 255)",
  "CORE_SURFACE_INVERSE_LIGHT_MODE_COLOR": "rgb(26, 26, 26)",
  "CORE_SURFACE_INVERSE_DARK_MODE_COLOR": "rgb(255, 255, 255)",
  "CORE_SURFACE_INVERSE_COLOR": "rgb(26, 26, 26)",
  "CORE_SURFACE_OVERLAY_LIGHT_MODE_COLOR": "rgba(0, 0, 0, 0.8)",
  "CORE_SURFACE_OVERLAY_DARK_MODE_COLOR": "rgba(0, 0, 0, 0.8)",
  "CORE_SURFACE_OVERLAY_COLOR": "rgba(0, 0, 0, 0.8)",
  "CORE_EMPHASIS_FILL_LIGHT_MODE_COLOR": "rgb(0, 106, 255)",
  "CORE_EMPHASIS_FILL_DARK_MODE_COLOR": "rgb(0, 106, 255)",
  "CORE_EMPHASIS_FILL_COLOR": "rgb(0, 106, 255)",
  "CORE_EMPHASIS_TEXT_LIGHT_MODE_COLOR": "rgb(0, 90, 217)",
  "CORE_EMPHASIS_TEXT_DARK_MODE_COLOR": "rgb(74, 149, 255)",
  "CORE_EMPHASIS_TEXT_COLOR": "rgb(0, 90, 217)",
  "CORE_EMPHASIS_10_LIGHT_MODE_COLOR": "rgb(0, 85, 204)",
  "CORE_EMPHASIS_10_DARK_MODE_COLOR": "rgb(0, 85, 204)",
  "CORE_EMPHASIS_10_COLOR": "rgb(0, 85, 204)",
  "CORE_EMPHASIS_20_LIGHT_MODE_COLOR": "rgb(0, 95, 229)",
  "CORE_EMPHASIS_20_DARK_MODE_COLOR": "rgb(0, 95, 229)",
  "CORE_EMPHASIS_20_COLOR": "rgb(0, 95, 229)",
  "CORE_EMPHASIS_30_LIGHT_MODE_COLOR": "rgb(204, 225, 255)",
  "CORE_EMPHASIS_30_DARK_MODE_COLOR": "rgb(0, 37, 89)",
  "CORE_EMPHASIS_30_COLOR": "rgb(204, 225, 255)",
  "CORE_EMPHASIS_40_LIGHT_MODE_COLOR": "rgb(230, 240, 255)",
  "CORE_EMPHASIS_40_DARK_MODE_COLOR": "rgb(0, 21, 51)",
  "CORE_EMPHASIS_40_COLOR": "rgb(230, 240, 255)",
  "CORE_SUCCESS_FILL_LIGHT_MODE_COLOR": "rgb(0, 178, 59)",
  "CORE_SUCCESS_FILL_DARK_MODE_COLOR": "rgb(0, 179, 59)",
  "CORE_SUCCESS_FILL_COLOR": "rgb(0, 178, 59)",
  "CORE_SUCCESS_TEXT_LIGHT_MODE_COLOR": "rgb(0, 125, 42)",
  "CORE_SUCCESS_TEXT_DARK_MODE_COLOR": "rgb(22, 217, 86)",
  "CORE_SUCCESS_TEXT_COLOR": "rgb(0, 125, 42)",
  "CORE_SUCCESS_10_LIGHT_MODE_COLOR": "rgb(0, 128, 42)",
  "CORE_SUCCESS_10_DARK_MODE_COLOR": "rgb(0, 128, 42)",
  "CORE_SUCCESS_10_COLOR": "rgb(0, 128, 42)",
  "CORE_SUCCESS_20_LIGHT_MODE_COLOR": "rgb(0, 153, 51)",
  "CORE_SUCCESS_20_DARK_MODE_COLOR": "rgb(0, 153, 51)",
  "CORE_SUCCESS_20_COLOR": "rgb(0, 153, 51)",
  "CORE_SUCCESS_30_LIGHT_MODE_COLOR": "rgb(204, 255, 221)",
  "CORE_SUCCESS_30_DARK_MODE_COLOR": "rgb(0, 89, 30)",
  "CORE_SUCCESS_30_COLOR": "rgb(204, 255, 221)",
  "CORE_SUCCESS_40_LIGHT_MODE_COLOR": "rgb(229, 255, 238)",
  "CORE_SUCCESS_40_DARK_MODE_COLOR": "rgb(0, 51, 17)",
  "CORE_SUCCESS_40_COLOR": "rgb(229, 255, 238)",
  "CORE_WARNING_FILL_LIGHT_MODE_COLOR": "rgb(255, 159, 64)",
  "CORE_WARNING_FILL_DARK_MODE_COLOR": "rgb(255, 159, 64)",
  "CORE_WARNING_FILL_COLOR": "rgb(255, 159, 64)",
  "CORE_WARNING_TEXT_LIGHT_MODE_COLOR": "rgb(148, 92, 37)",
  "CORE_WARNING_TEXT_DARK_MODE_COLOR": "rgb(255, 159, 64)",
  "CORE_WARNING_TEXT_COLOR": "rgb(148, 92, 37)",
  "CORE_WARNING_10_LIGHT_MODE_COLOR": "rgb(204, 128, 51)",
  "CORE_WARNING_10_DARK_MODE_COLOR": "rgb(204, 128, 51)",
  "CORE_WARNING_10_COLOR": "rgb(204, 128, 51)",
  "CORE_WARNING_20_LIGHT_MODE_COLOR": "rgb(230, 143, 57)",
  "CORE_WARNING_20_DARK_MODE_COLOR": "rgb(230, 143, 57)",
  "CORE_WARNING_20_COLOR": "rgb(230, 143, 57)",
  "CORE_WARNING_30_LIGHT_MODE_COLOR": "rgb(255, 230, 204)",
  "CORE_WARNING_30_DARK_MODE_COLOR": "rgb(89, 45, 0)",
  "CORE_WARNING_30_COLOR": "rgb(255, 230, 204)",
  "CORE_WARNING_40_LIGHT_MODE_COLOR": "rgb(255, 242, 230)",
  "CORE_WARNING_40_DARK_MODE_COLOR": "rgb(51, 26, 0)",
  "CORE_WARNING_40_COLOR": "rgb(255, 242, 230)",
  "CORE_CRITICAL_FILL_LIGHT_MODE_COLOR": "rgb(204, 0, 35)",
  "CORE_CRITICAL_FILL_DARK_MODE_COLOR": "rgb(204, 0, 35)",
  "CORE_CRITICAL_FILL_COLOR": "rgb(204, 0, 35)",
  "CORE_CRITICAL_TEXT_LIGHT_MODE_COLOR": "rgb(191, 0, 32)",
  "CORE_CRITICAL_TEXT_DARK_MODE_COLOR": "rgb(255, 90, 118)",
  "CORE_CRITICAL_TEXT_COLOR": "rgb(191, 0, 32)",
  "CORE_CRITICAL_10_LIGHT_MODE_COLOR": "rgb(153, 0, 26)",
  "CORE_CRITICAL_10_DARK_MODE_COLOR": "rgb(153, 0, 26)",
  "CORE_CRITICAL_10_COLOR": "rgb(153, 0, 26)",
  "CORE_CRITICAL_20_LIGHT_MODE_COLOR": "rgb(178, 0, 30)",
  "CORE_CRITICAL_20_DARK_MODE_COLOR": "rgb(178, 0, 30)",
  "CORE_CRITICAL_20_COLOR": "rgb(178, 0, 30)",
  "CORE_CRITICAL_30_LIGHT_MODE_COLOR": "rgb(255, 204, 213)",
  "CORE_CRITICAL_30_DARK_MODE_COLOR": "rgb(89, 0, 15)",
  "CORE_CRITICAL_30_COLOR": "rgb(255, 204, 213)",
  "CORE_CRITICAL_40_LIGHT_MODE_COLOR": "rgb(255, 229, 234)",
  "CORE_CRITICAL_40_DARK_MODE_COLOR": "rgb(51, 0, 9)",
  "CORE_CRITICAL_40_COLOR": "rgb(255, 229, 234)",
  "CORE_FOCUS_LIGHT_MODE_COLOR": "rgb(0, 106, 255)",
  "CORE_FOCUS_DARK_MODE_COLOR": "rgb(0, 106, 255)",
  "CORE_FOCUS_COLOR": "rgb(0, 106, 255)",
  "CORE_GREEN_FILL_LIGHT_MODE_COLOR": "rgb(0, 178, 59)",
  "CORE_GREEN_FILL_DARK_MODE_COLOR": "rgb(0, 179, 59)",
  "CORE_GREEN_FILL_COLOR": "rgb(0, 178, 59)",
  "CORE_GREEN_TEXT_LIGHT_MODE_COLOR": "rgb(0, 125, 42)",
  "CORE_GREEN_TEXT_DARK_MODE_COLOR": "rgb(22, 217, 86)",
  "CORE_GREEN_TEXT_COLOR": "rgb(0, 125, 42)",
  "CORE_GREEN_10_LIGHT_MODE_COLOR": "rgb(0, 128, 42)",
  "CORE_GREEN_10_DARK_MODE_COLOR": "rgb(0, 128, 42)",
  "CORE_GREEN_10_COLOR": "rgb(0, 128, 42)",
  "CORE_GREEN_20_LIGHT_MODE_COLOR": "rgb(0, 153, 51)",
  "CORE_GREEN_20_DARK_MODE_COLOR": "rgb(0, 153, 51)",
  "CORE_GREEN_20_COLOR": "rgb(0, 153, 51)",
  "CORE_GREEN_30_LIGHT_MODE_COLOR": "rgb(204, 255, 221)",
  "CORE_GREEN_30_DARK_MODE_COLOR": "rgb(0, 89, 30)",
  "CORE_GREEN_30_COLOR": "rgb(204, 255, 221)",
  "CORE_GREEN_40_LIGHT_MODE_COLOR": "rgb(229, 255, 238)",
  "CORE_GREEN_40_DARK_MODE_COLOR": "rgb(0, 51, 17)",
  "CORE_GREEN_40_COLOR": "rgb(229, 255, 238)",
  "CORE_FOREST_FILL_LIGHT_MODE_COLOR": "rgb(25, 128, 42)",
  "CORE_FOREST_FILL_DARK_MODE_COLOR": "rgb(25, 128, 42)",
  "CORE_FOREST_FILL_COLOR": "rgb(25, 128, 42)",
  "CORE_FOREST_TEXT_LIGHT_MODE_COLOR": "rgb(0, 125, 42)",
  "CORE_FOREST_TEXT_DARK_MODE_COLOR": "rgb(22, 217, 86)",
  "CORE_FOREST_TEXT_COLOR": "rgb(0, 125, 42)",
  "CORE_FOREST_10_LIGHT_MODE_COLOR": "rgb(15, 77, 25)",
  "CORE_FOREST_10_DARK_MODE_COLOR": "rgb(15, 77, 25)",
  "CORE_FOREST_10_COLOR": "rgb(15, 77, 25)",
  "CORE_FOREST_20_LIGHT_MODE_COLOR": "rgb(20, 102, 34)",
  "CORE_FOREST_20_DARK_MODE_COLOR": "rgb(20, 102, 34)",
  "CORE_FOREST_20_COLOR": "rgb(20, 102, 34)",
  "CORE_FOREST_30_LIGHT_MODE_COLOR": "rgb(204, 255, 213)",
  "CORE_FOREST_30_DARK_MODE_COLOR": "rgb(0, 89, 15)",
  "CORE_FOREST_30_COLOR": "rgb(204, 255, 213)",
  "CORE_FOREST_40_LIGHT_MODE_COLOR": "rgb(230, 255, 234)",
  "CORE_FOREST_40_DARK_MODE_COLOR": "rgb(0, 51, 8)",
  "CORE_FOREST_40_COLOR": "rgb(230, 255, 234)",
  "CORE_TEAL_FILL_LIGHT_MODE_COLOR": "rgb(18, 191, 148)",
  "CORE_TEAL_FILL_DARK_MODE_COLOR": "rgb(18, 191, 148)",
  "CORE_TEAL_FILL_COLOR": "rgb(18, 191, 148)",
  "CORE_TEAL_TEXT_LIGHT_MODE_COLOR": "rgb(12, 120, 93)",
  "CORE_TEAL_TEXT_DARK_MODE_COLOR": "rgb(22, 216, 167)",
  "CORE_TEAL_TEXT_COLOR": "rgb(12, 120, 93)",
  "CORE_TEAL_10_LIGHT_MODE_COLOR": "rgb(13, 140, 109)",
  "CORE_TEAL_10_DARK_MODE_COLOR": "rgb(13, 140, 109)",
  "CORE_TEAL_10_COLOR": "rgb(13, 140, 109)",
  "CORE_TEAL_20_LIGHT_MODE_COLOR": "rgb(16, 166, 128)",
  "CORE_TEAL_20_DARK_MODE_COLOR": "rgb(16, 166, 128)",
  "CORE_TEAL_20_COLOR": "rgb(16, 166, 128)",
  "CORE_TEAL_30_LIGHT_MODE_COLOR": "rgb(204, 255, 242)",
  "CORE_TEAL_30_DARK_MODE_COLOR": "rgb(0, 89, 67)",
  "CORE_TEAL_30_COLOR": "rgb(204, 255, 242)",
  "CORE_TEAL_40_LIGHT_MODE_COLOR": "rgb(230, 255, 249)",
  "CORE_TEAL_40_DARK_MODE_COLOR": "rgb(0, 51, 38)",
  "CORE_TEAL_40_COLOR": "rgb(230, 255, 249)",
  "CORE_BLUE_FILL_LIGHT_MODE_COLOR": "rgb(0, 106, 255)",
  "CORE_BLUE_FILL_DARK_MODE_COLOR": "rgb(0, 106, 255)",
  "CORE_BLUE_FILL_COLOR": "rgb(0, 106, 255)",
  "CORE_BLUE_TEXT_LIGHT_MODE_COLOR": "rgb(0, 90, 217)",
  "CORE_BLUE_TEXT_DARK_MODE_COLOR": "rgb(74, 149, 255)",
  "CORE_BLUE_TEXT_COLOR": "rgb(0, 90, 217)",
  "CORE_BLUE_10_LIGHT_MODE_COLOR": "rgb(0, 85, 204)",
  "CORE_BLUE_10_DARK_MODE_COLOR": "rgb(0, 85, 204)",
  "CORE_BLUE_10_COLOR": "rgb(0, 85, 204)",
  "CORE_BLUE_20_LIGHT_MODE_COLOR": "rgb(0, 95, 229)",
  "CORE_BLUE_20_DARK_MODE_COLOR": "rgb(0, 95, 229)",
  "CORE_BLUE_20_COLOR": "rgb(0, 95, 229)",
  "CORE_BLUE_30_LIGHT_MODE_COLOR": "rgb(204, 225, 255)",
  "CORE_BLUE_30_DARK_MODE_COLOR": "rgb(0, 37, 89)",
  "CORE_BLUE_30_COLOR": "rgb(204, 225, 255)",
  "CORE_BLUE_40_LIGHT_MODE_COLOR": "rgb(230, 240, 255)",
  "CORE_BLUE_40_DARK_MODE_COLOR": "rgb(0, 21, 51)",
  "CORE_BLUE_40_COLOR": "rgb(230, 240, 255)",
  "CORE_SKY_FILL_LIGHT_MODE_COLOR": "rgb(38, 147, 255)",
  "CORE_SKY_FILL_DARK_MODE_COLOR": "rgb(38, 147, 255)",
  "CORE_SKY_FILL_COLOR": "rgb(38, 147, 255)",
  "CORE_SKY_TEXT_LIGHT_MODE_COLOR": "rgb(15, 101, 186)",
  "CORE_SKY_TEXT_DARK_MODE_COLOR": "rgb(46, 151, 255)",
  "CORE_SKY_TEXT_COLOR": "rgb(15, 101, 186)",
  "CORE_SKY_10_LIGHT_MODE_COLOR": "rgb(31, 117, 204)",
  "CORE_SKY_10_DARK_MODE_COLOR": "rgb(31, 117, 204)",
  "CORE_SKY_10_COLOR": "rgb(31, 117, 204)",
  "CORE_SKY_20_LIGHT_MODE_COLOR": "rgb(34, 132, 230)",
  "CORE_SKY_20_DARK_MODE_COLOR": "rgb(34, 132, 230)",
  "CORE_SKY_20_COLOR": "rgb(34, 132, 230)",
  "CORE_SKY_30_LIGHT_MODE_COLOR": "rgb(204, 230, 255)",
  "CORE_SKY_30_DARK_MODE_COLOR": "rgb(0, 45, 89)",
  "CORE_SKY_30_COLOR": "rgb(204, 230, 255)",
  "CORE_SKY_40_LIGHT_MODE_COLOR": "rgb(230, 242, 255)",
  "CORE_SKY_40_DARK_MODE_COLOR": "rgb(0, 26, 51)",
  "CORE_SKY_40_COLOR": "rgb(230, 242, 255)",
  "CORE_PURPLE_FILL_LIGHT_MODE_COLOR": "rgb(135, 22, 217)",
  "CORE_PURPLE_FILL_DARK_MODE_COLOR": "rgb(135, 22, 217)",
  "CORE_PURPLE_FILL_COLOR": "rgb(135, 22, 217)",
  "CORE_PURPLE_TEXT_LIGHT_MODE_COLOR": "rgb(135, 22, 217)",
  "CORE_PURPLE_TEXT_DARK_MODE_COLOR": "rgb(194, 110, 255)",
  "CORE_PURPLE_TEXT_COLOR": "rgb(135, 22, 217)",
  "CORE_PURPLE_10_LIGHT_MODE_COLOR": "rgb(104, 17, 166)",
  "CORE_PURPLE_10_DARK_MODE_COLOR": "rgb(104, 17, 166)",
  "CORE_PURPLE_10_COLOR": "rgb(104, 17, 166)",
  "CORE_PURPLE_20_LIGHT_MODE_COLOR": "rgb(120, 19, 191)",
  "CORE_PURPLE_20_DARK_MODE_COLOR": "rgb(120, 19, 191)",
  "CORE_PURPLE_20_COLOR": "rgb(120, 19, 191)",
  "CORE_PURPLE_30_LIGHT_MODE_COLOR": "rgb(234, 204, 255)",
  "CORE_PURPLE_30_DARK_MODE_COLOR": "rgb(52, 0, 89)",
  "CORE_PURPLE_30_COLOR": "rgb(234, 204, 255)",
  "CORE_PURPLE_40_LIGHT_MODE_COLOR": "rgb(244, 230, 255)",
  "CORE_PURPLE_40_DARK_MODE_COLOR": "rgb(30, 0, 51)",
  "CORE_PURPLE_40_COLOR": "rgb(244, 230, 255)",
  "CORE_PINK_FILL_LIGHT_MODE_COLOR": "rgb(217, 54, 176)",
  "CORE_PINK_FILL_DARK_MODE_COLOR": "rgb(217, 54, 176)",
  "CORE_PINK_FILL_COLOR": "rgb(217, 54, 176)",
  "CORE_PINK_TEXT_LIGHT_MODE_COLOR": "rgb(168, 42, 136)",
  "CORE_PINK_TEXT_DARK_MODE_COLOR": "rgb(255, 67, 208)",
  "CORE_PINK_TEXT_COLOR": "rgb(168, 42, 136)",
  "CORE_PINK_10_LIGHT_MODE_COLOR": "rgb(166, 41, 135)",
  "CORE_PINK_10_DARK_MODE_COLOR": "rgb(166, 41, 135)",
  "CORE_PINK_10_COLOR": "rgb(166, 41, 135)",
  "CORE_PINK_20_LIGHT_MODE_COLOR": "rgb(191, 48, 155)",
  "CORE_PINK_20_DARK_MODE_COLOR": "rgb(191, 48, 155)",
  "CORE_PINK_20_COLOR": "rgb(191, 48, 155)",
  "CORE_PINK_30_LIGHT_MODE_COLOR": "rgb(255, 204, 242)",
  "CORE_PINK_30_DARK_MODE_COLOR": "rgb(89, 0, 67)",
  "CORE_PINK_30_COLOR": "rgb(255, 204, 242)",
  "CORE_PINK_40_LIGHT_MODE_COLOR": "rgb(255, 230, 249)",
  "CORE_PINK_40_DARK_MODE_COLOR": "rgb(51, 0, 38)",
  "CORE_PINK_40_COLOR": "rgb(255, 230, 249)",
  "CORE_BURGUNDY_FILL_LIGHT_MODE_COLOR": "rgb(153, 8, 56)",
  "CORE_BURGUNDY_FILL_DARK_MODE_COLOR": "rgb(153, 8, 56)",
  "CORE_BURGUNDY_FILL_COLOR": "rgb(153, 8, 56)",
  "CORE_BURGUNDY_TEXT_LIGHT_MODE_COLOR": "rgb(153, 8, 56)",
  "CORE_BURGUNDY_TEXT_DARK_MODE_COLOR": "rgb(255, 86, 142)",
  "CORE_BURGUNDY_TEXT_COLOR": "rgb(153, 8, 56)",
  "CORE_BURGUNDY_10_LIGHT_MODE_COLOR": "rgb(102, 5, 37)",
  "CORE_BURGUNDY_10_DARK_MODE_COLOR": "rgb(102, 5, 37)",
  "CORE_BURGUNDY_10_COLOR": "rgb(102, 5, 37)",
  "CORE_BURGUNDY_20_LIGHT_MODE_COLOR": "rgb(128, 6, 47)",
  "CORE_BURGUNDY_20_DARK_MODE_COLOR": "rgb(128, 6, 47)",
  "CORE_BURGUNDY_20_COLOR": "rgb(128, 6, 47)",
  "CORE_BURGUNDY_30_LIGHT_MODE_COLOR": "rgb(255, 204, 221)",
  "CORE_BURGUNDY_30_DARK_MODE_COLOR": "rgb(89, 0, 30)",
  "CORE_BURGUNDY_30_COLOR": "rgb(255, 204, 221)",
  "CORE_BURGUNDY_40_LIGHT_MODE_COLOR": "rgb(255, 230, 238)",
  "CORE_BURGUNDY_40_DARK_MODE_COLOR": "rgb(51, 0, 17)",
  "CORE_BURGUNDY_40_COLOR": "rgb(255, 230, 238)",
  "CORE_RED_FILL_LIGHT_MODE_COLOR": "rgb(204, 0, 35)",
  "CORE_RED_FILL_DARK_MODE_COLOR": "rgb(204, 0, 35)",
  "CORE_RED_FILL_COLOR": "rgb(204, 0, 35)",
  "CORE_RED_TEXT_LIGHT_MODE_COLOR": "rgb(191, 0, 32)",
  "CORE_RED_TEXT_DARK_MODE_COLOR": "rgb(255, 90, 118)",
  "CORE_RED_TEXT_COLOR": "rgb(191, 0, 32)",
  "CORE_RED_10_LIGHT_MODE_COLOR": "rgb(153, 0, 26)",
  "CORE_RED_10_DARK_MODE_COLOR": "rgb(153, 0, 26)",
  "CORE_RED_10_COLOR": "rgb(153, 0, 26)",
  "CORE_RED_20_LIGHT_MODE_COLOR": "rgb(178, 0, 30)",
  "CORE_RED_20_DARK_MODE_COLOR": "rgb(178, 0, 30)",
  "CORE_RED_20_COLOR": "rgb(178, 0, 30)",
  "CORE_RED_30_LIGHT_MODE_COLOR": "rgb(255, 204, 213)",
  "CORE_RED_30_DARK_MODE_COLOR": "rgb(89, 0, 15)",
  "CORE_RED_30_COLOR": "rgb(255, 204, 213)",
  "CORE_RED_40_LIGHT_MODE_COLOR": "rgb(255, 229, 234)",
  "CORE_RED_40_DARK_MODE_COLOR": "rgb(51, 0, 9)",
  "CORE_RED_40_COLOR": "rgb(255, 229, 234)",
  "CORE_ORANGE_FILL_LIGHT_MODE_COLOR": "rgb(242, 91, 61)",
  "CORE_ORANGE_FILL_DARK_MODE_COLOR": "rgb(242, 91, 61)",
  "CORE_ORANGE_FILL_COLOR": "rgb(242, 91, 61)",
  "CORE_ORANGE_TEXT_LIGHT_MODE_COLOR": "rgb(168, 63, 42)",
  "CORE_ORANGE_TEXT_DARK_MODE_COLOR": "rgb(255, 95, 63)",
  "CORE_ORANGE_TEXT_COLOR": "rgb(168, 63, 42)",
  "CORE_ORANGE_10_LIGHT_MODE_COLOR": "rgb(191, 72, 48)",
  "CORE_ORANGE_10_DARK_MODE_COLOR": "rgb(191, 72, 48)",
  "CORE_ORANGE_10_COLOR": "rgb(191, 72, 48)",
  "CORE_ORANGE_20_LIGHT_MODE_COLOR": "rgb(219, 82, 55)",
  "CORE_ORANGE_20_DARK_MODE_COLOR": "rgb(219, 82, 55)",
  "CORE_ORANGE_20_COLOR": "rgb(219, 82, 55)",
  "CORE_ORANGE_30_LIGHT_MODE_COLOR": "rgb(255, 213, 204)",
  "CORE_ORANGE_30_DARK_MODE_COLOR": "rgb(89, 15, 0)",
  "CORE_ORANGE_30_COLOR": "rgb(255, 213, 204)",
  "CORE_ORANGE_40_LIGHT_MODE_COLOR": "rgb(255, 234, 230)",
  "CORE_ORANGE_40_DARK_MODE_COLOR": "rgb(51, 8, 0)",
  "CORE_ORANGE_40_COLOR": "rgb(255, 234, 230)",
  "CORE_GOLD_FILL_LIGHT_MODE_COLOR": "rgb(255, 159, 64)",
  "CORE_GOLD_FILL_DARK_MODE_COLOR": "rgb(255, 159, 64)",
  "CORE_GOLD_FILL_COLOR": "rgb(255, 159, 64)",
  "CORE_GOLD_TEXT_LIGHT_MODE_COLOR": "rgb(148, 92, 37)",
  "CORE_GOLD_TEXT_DARK_MODE_COLOR": "rgb(255, 159, 64)",
  "CORE_GOLD_TEXT_COLOR": "rgb(148, 92, 37)",
  "CORE_GOLD_10_LIGHT_MODE_COLOR": "rgb(204, 128, 51)",
  "CORE_GOLD_10_DARK_MODE_COLOR": "rgb(204, 128, 51)",
  "CORE_GOLD_10_COLOR": "rgb(204, 128, 51)",
  "CORE_GOLD_20_LIGHT_MODE_COLOR": "rgb(230, 143, 57)",
  "CORE_GOLD_20_DARK_MODE_COLOR": "rgb(230, 143, 57)",
  "CORE_GOLD_20_COLOR": "rgb(230, 143, 57)",
  "CORE_GOLD_30_LIGHT_MODE_COLOR": "rgb(255, 230, 204)",
  "CORE_GOLD_30_DARK_MODE_COLOR": "rgb(89, 45, 0)",
  "CORE_GOLD_30_COLOR": "rgb(255, 230, 204)",
  "CORE_GOLD_40_LIGHT_MODE_COLOR": "rgb(255, 242, 230)",
  "CORE_GOLD_40_DARK_MODE_COLOR": "rgb(51, 26, 0)",
  "CORE_GOLD_40_COLOR": "rgb(255, 242, 230)",
  "CORE_YELLOW_FILL_LIGHT_MODE_COLOR": "rgb(255, 191, 0)",
  "CORE_YELLOW_FILL_DARK_MODE_COLOR": "rgb(255, 191, 0)",
  "CORE_YELLOW_FILL_COLOR": "rgb(255, 191, 0)",
  "CORE_YELLOW_TEXT_LIGHT_MODE_COLOR": "rgb(135, 101, 0)",
  "CORE_YELLOW_TEXT_DARK_MODE_COLOR": "rgb(255, 191, 0)",
  "CORE_YELLOW_TEXT_COLOR": "rgb(135, 101, 0)",
  "CORE_YELLOW_10_LIGHT_MODE_COLOR": "rgb(204, 153, 0)",
  "CORE_YELLOW_10_DARK_MODE_COLOR": "rgb(204, 153, 0)",
  "CORE_YELLOW_10_COLOR": "rgb(204, 153, 0)",
  "CORE_YELLOW_20_LIGHT_MODE_COLOR": "rgb(229, 172, 0)",
  "CORE_YELLOW_20_DARK_MODE_COLOR": "rgb(229, 172, 0)",
  "CORE_YELLOW_20_COLOR": "rgb(229, 172, 0)",
  "CORE_YELLOW_30_LIGHT_MODE_COLOR": "rgb(255, 242, 204)",
  "CORE_YELLOW_30_DARK_MODE_COLOR": "rgb(89, 67, 0)",
  "CORE_YELLOW_30_COLOR": "rgb(255, 242, 204)",
  "CORE_YELLOW_40_LIGHT_MODE_COLOR": "rgb(255, 249, 229)",
  "CORE_YELLOW_40_DARK_MODE_COLOR": "rgb(51, 38, 0)",
  "CORE_YELLOW_40_COLOR": "rgb(255, 249, 229)",
  "CORE_TAUPE_FILL_LIGHT_MODE_COLOR": "rgb(166, 124, 83)",
  "CORE_TAUPE_FILL_DARK_MODE_COLOR": "rgb(166, 124, 83)",
  "CORE_TAUPE_FILL_COLOR": "rgb(166, 124, 83)",
  "CORE_TAUPE_TEXT_LIGHT_MODE_COLOR": "rgb(130, 97, 65)",
  "CORE_TAUPE_TEXT_DARK_MODE_COLOR": "rgb(201, 152, 102)",
  "CORE_TAUPE_TEXT_COLOR": "rgb(130, 97, 65)",
  "CORE_TAUPE_10_LIGHT_MODE_COLOR": "rgb(115, 86, 57)",
  "CORE_TAUPE_10_DARK_MODE_COLOR": "rgb(115, 86, 57)",
  "CORE_TAUPE_10_COLOR": "rgb(115, 86, 57)",
  "CORE_TAUPE_20_LIGHT_MODE_COLOR": "rgb(140, 105, 70)",
  "CORE_TAUPE_20_DARK_MODE_COLOR": "rgb(140, 105, 70)",
  "CORE_TAUPE_20_COLOR": "rgb(140, 105, 70)",
  "CORE_TAUPE_30_LIGHT_MODE_COLOR": "rgb(255, 230, 204)",
  "CORE_TAUPE_30_DARK_MODE_COLOR": "rgb(89, 45, 0)",
  "CORE_TAUPE_30_COLOR": "rgb(255, 230, 204)",
  "CORE_TAUPE_40_LIGHT_MODE_COLOR": "rgb(255, 242, 230)",
  "CORE_TAUPE_40_DARK_MODE_COLOR": "rgb(51, 26, 0)",
  "CORE_TAUPE_40_COLOR": "rgb(255, 242, 230)",
  "CORE_BROWN_FILL_LIGHT_MODE_COLOR": "rgb(102, 74, 46)",
  "CORE_BROWN_FILL_DARK_MODE_COLOR": "rgb(102, 77, 51)",
  "CORE_BROWN_FILL_COLOR": "rgb(102, 74, 46)",
  "CORE_BROWN_TEXT_LIGHT_MODE_COLOR": "rgb(102, 74, 46)",
  "CORE_BROWN_TEXT_DARK_MODE_COLOR": "rgb(201, 152, 102)",
  "CORE_BROWN_TEXT_COLOR": "rgb(102, 74, 46)",
  "CORE_BROWN_10_LIGHT_MODE_COLOR": "rgb(51, 37, 23)",
  "CORE_BROWN_10_DARK_MODE_COLOR": "rgb(51, 38, 26)",
  "CORE_BROWN_10_COLOR": "rgb(51, 37, 23)",
  "CORE_BROWN_20_LIGHT_MODE_COLOR": "rgb(77, 55, 34)",
  "CORE_BROWN_20_DARK_MODE_COLOR": "rgb(77, 57, 38)",
  "CORE_BROWN_20_COLOR": "rgb(77, 55, 34)",
  "CORE_BROWN_30_LIGHT_MODE_COLOR": "rgb(255, 230, 204)",
  "CORE_BROWN_30_DARK_MODE_COLOR": "rgb(89, 45, 0)",
  "CORE_BROWN_30_COLOR": "rgb(255, 230, 204)",
  "CORE_BROWN_40_LIGHT_MODE_COLOR": "rgb(255, 242, 230)",
  "CORE_BROWN_40_DARK_MODE_COLOR": "rgb(51, 25, 0)",
  "CORE_BROWN_40_COLOR": "rgb(255, 242, 230)",
  "CORE_EXTRA_WIDE_VIEWPORT_GRID_COLUMN_COUNT": 4,
  "CORE_EXTRA_WIDE_VIEWPORT_GRID_HORIZONTAL_SPACING": 16,
  "CORE_EXTRA_WIDE_VIEWPORT_GRID_VERTICAL_SPACING": 16,
  "CORE_EXTRA_WIDE_VIEWPORT_GRID_ITEM_SMALL_SIZE_COLUMN_SPAN": 1,
  "CORE_EXTRA_WIDE_VIEWPORT_GRID_ITEM_MEDIUM_SIZE_COLUMN_SPAN": 2,
  "CORE_EXTRA_WIDE_VIEWPORT_GRID_ITEM_LARGE_SIZE_COLUMN_SPAN": 3,
  "CORE_EXTRA_WIDE_VIEWPORT_GRID_ITEM_FULL_SIZE_COLUMN_SPAN": 4,
  "CORE_WIDE_VIEWPORT_GRID_COLUMN_COUNT": 4,
  "CORE_WIDE_VIEWPORT_GRID_HORIZONTAL_SPACING": 16,
  "CORE_WIDE_VIEWPORT_GRID_VERTICAL_SPACING": 16,
  "CORE_WIDE_VIEWPORT_GRID_ITEM_SMALL_SIZE_COLUMN_SPAN": 1,
  "CORE_WIDE_VIEWPORT_GRID_ITEM_MEDIUM_SIZE_COLUMN_SPAN": 2,
  "CORE_WIDE_VIEWPORT_GRID_ITEM_LARGE_SIZE_COLUMN_SPAN": 3,
  "CORE_WIDE_VIEWPORT_GRID_ITEM_FULL_SIZE_COLUMN_SPAN": 4,
  "CORE_MEDIUM_VIEWPORT_GRID_COLUMN_COUNT": 2,
  "CORE_MEDIUM_VIEWPORT_GRID_HORIZONTAL_SPACING": 16,
  "CORE_MEDIUM_VIEWPORT_GRID_VERTICAL_SPACING": 16,
  "CORE_MEDIUM_VIEWPORT_GRID_ITEM_SMALL_SIZE_COLUMN_SPAN": 1,
  "CORE_MEDIUM_VIEWPORT_GRID_ITEM_MEDIUM_SIZE_COLUMN_SPAN": 1,
  "CORE_MEDIUM_VIEWPORT_GRID_ITEM_LARGE_SIZE_COLUMN_SPAN": 1,
  "CORE_MEDIUM_VIEWPORT_GRID_ITEM_FULL_SIZE_COLUMN_SPAN": 2,
  "CORE_NARROW_VIEWPORT_GRID_COLUMN_COUNT": 1,
  "CORE_NARROW_VIEWPORT_GRID_HORIZONTAL_SPACING": 16,
  "CORE_NARROW_VIEWPORT_GRID_VERTICAL_SPACING": 16,
  "CORE_NARROW_VIEWPORT_GRID_ITEM_SMALL_SIZE_COLUMN_SPAN": 1,
  "CORE_NARROW_VIEWPORT_GRID_ITEM_MEDIUM_SIZE_COLUMN_SPAN": 1,
  "CORE_NARROW_VIEWPORT_GRID_ITEM_LARGE_SIZE_COLUMN_SPAN": 1,
  "CORE_NARROW_VIEWPORT_GRID_ITEM_FULL_SIZE_COLUMN_SPAN": 1,
  "CORE_ICON_EXPAND_16_FIDELITY_ASSET": "chevron-down",
  "CORE_ICON_EXPAND_16_FIDELITY_SIZE": 16,
  "CORE_ICON_EXPAND_16_FIDELITY_WIDTH": 16,
  "CORE_ICON_EXPAND_16_FIDELITY_HEIGHT": 16,
  "CORE_ICON_EXPAND_16_FIDELITY_TINTABLE": true,
  "CORE_ICON_COLLAPSE_16_FIDELITY_ASSET": "chevron-up",
  "CORE_ICON_COLLAPSE_16_FIDELITY_SIZE": 16,
  "CORE_ICON_COLLAPSE_16_FIDELITY_WIDTH": 16,
  "CORE_ICON_COLLAPSE_16_FIDELITY_HEIGHT": 16,
  "CORE_ICON_COLLAPSE_16_FIDELITY_TINTABLE": true,
  "CORE_ICON_CONTINUE_16_FIDELITY_ASSET": "chevron-right",
  "CORE_ICON_CONTINUE_16_FIDELITY_SIZE": 16,
  "CORE_ICON_CONTINUE_16_FIDELITY_WIDTH": 16,
  "CORE_ICON_CONTINUE_16_FIDELITY_HEIGHT": 16,
  "CORE_ICON_CONTINUE_16_FIDELITY_TINTABLE": true,
  "CORE_ICON_RETURN_16_FIDELITY_ASSET": "chevron-left",
  "CORE_ICON_RETURN_16_FIDELITY_SIZE": 16,
  "CORE_ICON_RETURN_16_FIDELITY_WIDTH": 16,
  "CORE_ICON_RETURN_16_FIDELITY_HEIGHT": 16,
  "CORE_ICON_RETURN_16_FIDELITY_TINTABLE": true,
  "CORE_ICON_CLEAR_24_FIDELITY_ASSET": "x",
  "CORE_ICON_CLEAR_24_FIDELITY_SIZE": 24,
  "CORE_ICON_CLEAR_24_FIDELITY_WIDTH": 24,
  "CORE_ICON_CLEAR_24_FIDELITY_HEIGHT": 24,
  "CORE_ICON_CLEAR_24_FIDELITY_TINTABLE": true,
  "CORE_ICON_CLOSE_24_FIDELITY_ASSET": "x",
  "CORE_ICON_CLOSE_24_FIDELITY_SIZE": 24,
  "CORE_ICON_CLOSE_24_FIDELITY_WIDTH": 24,
  "CORE_ICON_CLOSE_24_FIDELITY_HEIGHT": 24,
  "CORE_ICON_CLOSE_24_FIDELITY_TINTABLE": true,
  "CORE_ICON_ADD_24_FIDELITY_ASSET": "plus",
  "CORE_ICON_ADD_24_FIDELITY_SIZE": 24,
  "CORE_ICON_ADD_24_FIDELITY_WIDTH": 24,
  "CORE_ICON_ADD_24_FIDELITY_HEIGHT": 24,
  "CORE_ICON_ADD_24_FIDELITY_TINTABLE": true,
  "CORE_ICON_REMOVE_24_FIDELITY_ASSET": "minus",
  "CORE_ICON_REMOVE_24_FIDELITY_SIZE": 24,
  "CORE_ICON_REMOVE_24_FIDELITY_WIDTH": 24,
  "CORE_ICON_REMOVE_24_FIDELITY_HEIGHT": 24,
  "CORE_ICON_REMOVE_24_FIDELITY_TINTABLE": true,
  "CORE_ICON_BACK_24_FIDELITY_ASSET": "arrow-left",
  "CORE_ICON_BACK_24_FIDELITY_SIZE": 24,
  "CORE_ICON_BACK_24_FIDELITY_WIDTH": 24,
  "CORE_ICON_BACK_24_FIDELITY_HEIGHT": 24,
  "CORE_ICON_BACK_24_FIDELITY_TINTABLE": true,
  "CORE_ICON_PREVIOUS_24_FIDELITY_ASSET": "arrow-left",
  "CORE_ICON_PREVIOUS_24_FIDELITY_SIZE": 24,
  "CORE_ICON_PREVIOUS_24_FIDELITY_WIDTH": 24,
  "CORE_ICON_PREVIOUS_24_FIDELITY_HEIGHT": 24,
  "CORE_ICON_PREVIOUS_24_FIDELITY_TINTABLE": true,
  "CORE_ICON_FORWARD_24_FIDELITY_ASSET": "arrow-right",
  "CORE_ICON_FORWARD_24_FIDELITY_SIZE": 24,
  "CORE_ICON_FORWARD_24_FIDELITY_WIDTH": 24,
  "CORE_ICON_FORWARD_24_FIDELITY_HEIGHT": 24,
  "CORE_ICON_FORWARD_24_FIDELITY_TINTABLE": true,
  "CORE_ICON_NEXT_24_FIDELITY_ASSET": "arrow-right",
  "CORE_ICON_NEXT_24_FIDELITY_SIZE": 24,
  "CORE_ICON_NEXT_24_FIDELITY_WIDTH": 24,
  "CORE_ICON_NEXT_24_FIDELITY_HEIGHT": 24,
  "CORE_ICON_NEXT_24_FIDELITY_TINTABLE": true,
  "CORE_ICON_ASCEND_24_FIDELITY_ASSET": "arrow-up",
  "CORE_ICON_ASCEND_24_FIDELITY_SIZE": 24,
  "CORE_ICON_ASCEND_24_FIDELITY_WIDTH": 24,
  "CORE_ICON_ASCEND_24_FIDELITY_HEIGHT": 24,
  "CORE_ICON_ASCEND_24_FIDELITY_TINTABLE": true,
  "CORE_ICON_DESCEND_24_FIDELITY_ASSET": "arrow-down",
  "CORE_ICON_DESCEND_24_FIDELITY_SIZE": 24,
  "CORE_ICON_DESCEND_24_FIDELITY_WIDTH": 24,
  "CORE_ICON_DESCEND_24_FIDELITY_HEIGHT": 24,
  "CORE_ICON_DESCEND_24_FIDELITY_TINTABLE": true,
  "CORE_ICON_MORE_24_FIDELITY_ASSET": "ellipsis-horizontal",
  "CORE_ICON_MORE_24_FIDELITY_SIZE": 24,
  "CORE_ICON_MORE_24_FIDELITY_WIDTH": 24,
  "CORE_ICON_MORE_24_FIDELITY_HEIGHT": 24,
  "CORE_ICON_MORE_24_FIDELITY_TINTABLE": true,
  "CORE_ICON_MENU_24_FIDELITY_ASSET": "hamburger-lines",
  "CORE_ICON_MENU_24_FIDELITY_SIZE": 24,
  "CORE_ICON_MENU_24_FIDELITY_WIDTH": 24,
  "CORE_ICON_MENU_24_FIDELITY_HEIGHT": 24,
  "CORE_ICON_MENU_24_FIDELITY_TINTABLE": true,
  "CORE_ICON_LOADING_24_FIDELITY_ASSET": "radial-spinner",
  "CORE_ICON_LOADING_24_FIDELITY_SIZE": 24,
  "CORE_ICON_LOADING_24_FIDELITY_WIDTH": 24,
  "CORE_ICON_LOADING_24_FIDELITY_HEIGHT": 24,
  "CORE_ICON_LOADING_24_FIDELITY_TINTABLE": true,
  "CORE_ICON_SEARCH_24_FIDELITY_ASSET": "magnifying-glass",
  "CORE_ICON_SEARCH_24_FIDELITY_SIZE": 24,
  "CORE_ICON_SEARCH_24_FIDELITY_WIDTH": 24,
  "CORE_ICON_SEARCH_24_FIDELITY_HEIGHT": 24,
  "CORE_ICON_SEARCH_24_FIDELITY_TINTABLE": true,
  "CORE_ICON_DRAG_24_FIDELITY_ASSET": "grip-dots-vertical",
  "CORE_ICON_DRAG_24_FIDELITY_SIZE": 24,
  "CORE_ICON_DRAG_24_FIDELITY_WIDTH": 24,
  "CORE_ICON_DRAG_24_FIDELITY_HEIGHT": 24,
  "CORE_ICON_DRAG_24_FIDELITY_TINTABLE": true,
  "CORE_ICON_WARN_24_FIDELITY_ASSET": "exclamation-circle",
  "CORE_ICON_WARN_24_FIDELITY_SIZE": 24,
  "CORE_ICON_WARN_24_FIDELITY_WIDTH": 24,
  "CORE_ICON_WARN_24_FIDELITY_HEIGHT": 24,
  "CORE_ICON_WARN_24_FIDELITY_TINTABLE": true,
  "CORE_ICON_WARNING_24_FIDELITY_ASSET": "exclamation-circle",
  "CORE_ICON_WARNING_24_FIDELITY_SIZE": 24,
  "CORE_ICON_WARNING_24_FIDELITY_WIDTH": 24,
  "CORE_ICON_WARNING_24_FIDELITY_HEIGHT": 24,
  "CORE_ICON_WARNING_24_FIDELITY_TINTABLE": true,
  "CORE_ICON_SUCCEED_24_FIDELITY_ASSET": "checkmark-circle",
  "CORE_ICON_SUCCEED_24_FIDELITY_SIZE": 24,
  "CORE_ICON_SUCCEED_24_FIDELITY_WIDTH": 24,
  "CORE_ICON_SUCCEED_24_FIDELITY_HEIGHT": 24,
  "CORE_ICON_SUCCEED_24_FIDELITY_TINTABLE": true,
  "CORE_ICON_SUCCESS_24_FIDELITY_ASSET": "checkmark-circle",
  "CORE_ICON_SUCCESS_24_FIDELITY_SIZE": 24,
  "CORE_ICON_SUCCESS_24_FIDELITY_WIDTH": 24,
  "CORE_ICON_SUCCESS_24_FIDELITY_HEIGHT": 24,
  "CORE_ICON_SUCCESS_24_FIDELITY_TINTABLE": true,
  "CORE_ICON_INFORM_24_FIDELITY_ASSET": "i-circle",
  "CORE_ICON_INFORM_24_FIDELITY_SIZE": 24,
  "CORE_ICON_INFORM_24_FIDELITY_WIDTH": 24,
  "CORE_ICON_INFORM_24_FIDELITY_HEIGHT": 24,
  "CORE_ICON_INFORM_24_FIDELITY_TINTABLE": true,
  "CORE_ICON_INFO_24_FIDELITY_ASSET": "i-circle",
  "CORE_ICON_INFO_24_FIDELITY_SIZE": 24,
  "CORE_ICON_INFO_24_FIDELITY_WIDTH": 24,
  "CORE_ICON_INFO_24_FIDELITY_HEIGHT": 24,
  "CORE_ICON_INFO_24_FIDELITY_TINTABLE": true,
  "CORE_ICON_DENY_24_FIDELITY_ASSET": "no-entry-circle",
  "CORE_ICON_DENY_24_FIDELITY_SIZE": 24,
  "CORE_ICON_DENY_24_FIDELITY_WIDTH": 24,
  "CORE_ICON_DENY_24_FIDELITY_HEIGHT": 24,
  "CORE_ICON_DENY_24_FIDELITY_TINTABLE": true,
  "CORE_ICON_DENIED_24_FIDELITY_ASSET": "no-entry-circle",
  "CORE_ICON_DENIED_24_FIDELITY_SIZE": 24,
  "CORE_ICON_DENIED_24_FIDELITY_WIDTH": 24,
  "CORE_ICON_DENIED_24_FIDELITY_HEIGHT": 24,
  "CORE_ICON_DENIED_24_FIDELITY_TINTABLE": true,
  "CORE_ICON_CANCEL_24_FIDELITY_ASSET": "x-circle",
  "CORE_ICON_CANCEL_24_FIDELITY_SIZE": 24,
  "CORE_ICON_CANCEL_24_FIDELITY_WIDTH": 24,
  "CORE_ICON_CANCEL_24_FIDELITY_HEIGHT": 24,
  "CORE_ICON_CANCEL_24_FIDELITY_TINTABLE": true,
  "CORE_ICON_CANCELLED_24_FIDELITY_ASSET": "x-circle",
  "CORE_ICON_CANCELLED_24_FIDELITY_SIZE": 24,
  "CORE_ICON_CANCELLED_24_FIDELITY_WIDTH": 24,
  "CORE_ICON_CANCELLED_24_FIDELITY_HEIGHT": 24,
  "CORE_ICON_CANCELLED_24_FIDELITY_TINTABLE": true,
  "CORE_ICON_RECOMMEND_24_FIDELITY_ASSET": "lightning-bolt",
  "CORE_ICON_RECOMMEND_24_FIDELITY_SIZE": 24,
  "CORE_ICON_RECOMMEND_24_FIDELITY_WIDTH": 24,
  "CORE_ICON_RECOMMEND_24_FIDELITY_HEIGHT": 24,
  "CORE_ICON_RECOMMEND_24_FIDELITY_TINTABLE": true,
  "CORE_ICON_RECOMMENDATION_24_FIDELITY_ASSET": "lightning-bolt",
  "CORE_ICON_RECOMMENDATION_24_FIDELITY_SIZE": 24,
  "CORE_ICON_RECOMMENDATION_24_FIDELITY_WIDTH": 24,
  "CORE_ICON_RECOMMENDATION_24_FIDELITY_HEIGHT": 24,
  "CORE_ICON_RECOMMENDATION_24_FIDELITY_TINTABLE": true,
  "CORE_ICON_REFRESH_24_FIDELITY_ASSET": "dual-rotating-arrows",
  "CORE_ICON_REFRESH_24_FIDELITY_SIZE": 24,
  "CORE_ICON_REFRESH_24_FIDELITY_WIDTH": 24,
  "CORE_ICON_REFRESH_24_FIDELITY_HEIGHT": 24,
  "CORE_ICON_REFRESH_24_FIDELITY_TINTABLE": true,
  "CORE_ICON_CYCLE_24_FIDELITY_ASSET": "dual-rotating-arrows",
  "CORE_ICON_CYCLE_24_FIDELITY_SIZE": 24,
  "CORE_ICON_CYCLE_24_FIDELITY_WIDTH": 24,
  "CORE_ICON_CYCLE_24_FIDELITY_HEIGHT": 24,
  "CORE_ICON_CYCLE_24_FIDELITY_TINTABLE": true,
  "CORE_ICON_DELETE_24_FIDELITY_ASSET": "trashcan",
  "CORE_ICON_DELETE_24_FIDELITY_SIZE": 24,
  "CORE_ICON_DELETE_24_FIDELITY_WIDTH": 24,
  "CORE_ICON_DELETE_24_FIDELITY_HEIGHT": 24,
  "CORE_ICON_DELETE_24_FIDELITY_TINTABLE": true,
  "CORE_ICON_CHECKMARK_24_FIDELITY_ASSET": "check",
  "CORE_ICON_CHECKMARK_24_FIDELITY_SIZE": 24,
  "CORE_ICON_CHECKMARK_24_FIDELITY_WIDTH": 24,
  "CORE_ICON_CHECKMARK_24_FIDELITY_HEIGHT": 24,
  "CORE_ICON_CHECKMARK_24_FIDELITY_TINTABLE": true,
  "CORE_ICON_HELP_24_FIDELITY_ASSET": "question-mark-circle",
  "CORE_ICON_HELP_24_FIDELITY_SIZE": 24,
  "CORE_ICON_HELP_24_FIDELITY_WIDTH": 24,
  "CORE_ICON_HELP_24_FIDELITY_HEIGHT": 24,
  "CORE_ICON_HELP_24_FIDELITY_TINTABLE": true,
  "CORE_BASE_SIZE": 8,
  "CORE_TEXT_SIZE": 16,
  "CORE_SPACE_10_SIZE": 8,
  "CORE_METRICS_SPACING_25": 2,
  "CORE_METRICS_SPACING_50": 4,
  "CORE_METRICS_SPACING_100": 8,
  "CORE_METRICS_SPACING_150": 12,
  "CORE_METRICS_SPACING_200": 16,
  "CORE_METRICS_SPACING_300": 24,
  "CORE_METRICS_SPACING_400": 32,
  "CORE_METRICS_SPACING_500": 40,
  "CORE_METRICS_SPACING_600": 48,
  "CORE_METRICS_SPACING_800": 64,
  "CORE_METRICS_SPACING_1000": 80,
  "CORE_METRICS_SPACING_1500": 120,
  "CORE_METRICS_SPACING_2000": 160,
  "CORE_RADIUS_10": 6,
  "CORE_RADIUS_20": 12,
  "CORE_HORIZONTAL_SIZE_CLASS_REGULAR_THRESHOLD": 600,
  "CORE_HORIZONTAL_SIZE_CLASS_WIDE_THRESHOLD": 1200,
  "CORE_VERTICAL_SIZE_CLASS_REGULAR_THRESHOLD": 600,
  "CORE_VERTICAL_SIZE_CLASS_TALL_THRESHOLD": 864,
  "CORE_SMALL_SIZE_MINIMUM_HEIGHT": 40,
  "CORE_MEDIUM_SIZE_MINIMUM_HEIGHT": 48,
  "CORE_LARGE_SIZE_MINIMUM_HEIGHT": 64,
  "CORE_FOCUS_RING_BUFFER_SIZE": 2,
  "CORE_FOCUS_RING_BORDER_SIZE": 2,
  "CORE_FOCUS_RING_LIGHT_MODE_COLOR": "rgb(0, 106, 255)",
  "CORE_FOCUS_RING_DARK_MODE_COLOR": "rgb(0, 106, 255)",
  "CORE_FOCUS_RING_COLOR": "rgb(0, 106, 255)",
  "CORE_TYPE_DISPLAY_10_SIZE": 32,
  "CORE_TYPE_DISPLAY_10_LEADING": 40,
  "CORE_TYPE_DISPLAY_10_TRACKING": 0,
  "CORE_TYPE_DISPLAY_10_WEIGHT": 700,
  "CORE_TYPE_DISPLAY_10_CASE": "regular",
  "CORE_TYPE_DISPLAY_10_NUMBER_SPACING": "default",
  "CORE_TYPE_DISPLAY_10_SCALE": "heading-30",
  "CORE_TYPE_DISPLAY_10_FONT_FAMILY": "Square Sans Display",
  "CORE_TYPE_DISPLAY_20_SIZE": 48,
  "CORE_TYPE_DISPLAY_20_LEADING": 56,
  "CORE_TYPE_DISPLAY_20_TRACKING": 0,
  "CORE_TYPE_DISPLAY_20_WEIGHT": 500,
  "CORE_TYPE_DISPLAY_20_CASE": "regular",
  "CORE_TYPE_DISPLAY_20_NUMBER_SPACING": "default",
  "CORE_TYPE_DISPLAY_20_SCALE": "heading-30",
  "CORE_TYPE_DISPLAY_20_FONT_FAMILY": "Square Sans Display",
  "CORE_TYPE_DISPLAY_FONT_FAMILY": "Square Sans Display",
  "CORE_TYPE_HEADING_5_SIZE": 12,
  "CORE_TYPE_HEADING_5_LEADING": 20,
  "CORE_TYPE_HEADING_5_TRACKING": 0.05,
  "CORE_TYPE_HEADING_5_WEIGHT": 500,
  "CORE_TYPE_HEADING_5_CASE": "uppercase",
  "CORE_TYPE_HEADING_5_NUMBER_SPACING": "default",
  "CORE_TYPE_HEADING_5_SCALE": "heading-5",
  "CORE_TYPE_HEADING_5_FONT_FAMILY": "Square Sans Text",
  "CORE_TYPE_HEADING_5_IS_HEADER": true,
  "CORE_TYPE_HEADING_10_SIZE": 14,
  "CORE_TYPE_HEADING_10_LEADING": 22,
  "CORE_TYPE_HEADING_10_TRACKING": 0,
  "CORE_TYPE_HEADING_10_WEIGHT": 700,
  "CORE_TYPE_HEADING_10_CASE": "regular",
  "CORE_TYPE_HEADING_10_NUMBER_SPACING": "default",
  "CORE_TYPE_HEADING_10_SCALE": "heading-10",
  "CORE_TYPE_HEADING_10_FONT_FAMILY": "Square Sans Text",
  "CORE_TYPE_HEADING_10_IS_HEADER": true,
  "CORE_TYPE_HEADING_20_SIZE": 19,
  "CORE_TYPE_HEADING_20_LEADING": 26,
  "CORE_TYPE_HEADING_20_TRACKING": 0,
  "CORE_TYPE_HEADING_20_WEIGHT": 700,
  "CORE_TYPE_HEADING_20_CASE": "regular",
  "CORE_TYPE_HEADING_20_NUMBER_SPACING": "default",
  "CORE_TYPE_HEADING_20_SCALE": "heading-20",
  "CORE_TYPE_HEADING_20_FONT_FAMILY": "Square Sans Display",
  "CORE_TYPE_HEADING_20_IS_HEADER": true,
  "CORE_TYPE_HEADING_30_SIZE": 25,
  "CORE_TYPE_HEADING_30_LEADING": 32,
  "CORE_TYPE_HEADING_30_TRACKING": 0,
  "CORE_TYPE_HEADING_30_WEIGHT": 700,
  "CORE_TYPE_HEADING_30_CASE": "regular",
  "CORE_TYPE_HEADING_30_NUMBER_SPACING": "default",
  "CORE_TYPE_HEADING_30_SCALE": "heading-30",
  "CORE_TYPE_HEADING_30_FONT_FAMILY": "Square Sans Display",
  "CORE_TYPE_HEADING_30_IS_HEADER": true,
  "CORE_TYPE_PARAGRAPH_10_SIZE": 12,
  "CORE_TYPE_PARAGRAPH_10_LEADING": 18,
  "CORE_TYPE_PARAGRAPH_10_TRACKING": 0,
  "CORE_TYPE_PARAGRAPH_10_WEIGHT": 400,
  "CORE_TYPE_PARAGRAPH_10_CASE": "regular",
  "CORE_TYPE_PARAGRAPH_10_NUMBER_SPACING": "default",
  "CORE_TYPE_PARAGRAPH_10_SCALE": "paragraph-10",
  "CORE_TYPE_PARAGRAPH_10_FONT_FAMILY": "Square Sans Text",
  "CORE_TYPE_PARAGRAPH_20_SIZE": 14,
  "CORE_TYPE_PARAGRAPH_20_LEADING": 22,
  "CORE_TYPE_PARAGRAPH_20_TRACKING": 0,
  "CORE_TYPE_PARAGRAPH_20_WEIGHT": 400,
  "CORE_TYPE_PARAGRAPH_20_CASE": "regular",
  "CORE_TYPE_PARAGRAPH_20_NUMBER_SPACING": "default",
  "CORE_TYPE_PARAGRAPH_20_SCALE": "paragraph-20",
  "CORE_TYPE_PARAGRAPH_20_FONT_FAMILY": "Square Sans Text",
  "CORE_TYPE_PARAGRAPH_30_SIZE": 16,
  "CORE_TYPE_PARAGRAPH_30_LEADING": 24,
  "CORE_TYPE_PARAGRAPH_30_TRACKING": 0,
  "CORE_TYPE_PARAGRAPH_30_WEIGHT": 400,
  "CORE_TYPE_PARAGRAPH_30_CASE": "regular",
  "CORE_TYPE_PARAGRAPH_30_NUMBER_SPACING": "default",
  "CORE_TYPE_PARAGRAPH_30_SCALE": "paragraph-30",
  "CORE_TYPE_PARAGRAPH_30_FONT_FAMILY": "Square Sans Text",
  "CORE_TYPE_MEDIUM_10_SIZE": 12,
  "CORE_TYPE_MEDIUM_10_LEADING": 18,
  "CORE_TYPE_MEDIUM_10_TRACKING": 0,
  "CORE_TYPE_MEDIUM_10_WEIGHT": 500,
  "CORE_TYPE_MEDIUM_10_CASE": "regular",
  "CORE_TYPE_MEDIUM_10_NUMBER_SPACING": "default",
  "CORE_TYPE_MEDIUM_10_SCALE": "paragraph-10",
  "CORE_TYPE_MEDIUM_10_FONT_FAMILY": "Square Sans Text",
  "CORE_TYPE_MEDIUM_20_SIZE": 14,
  "CORE_TYPE_MEDIUM_20_LEADING": 22,
  "CORE_TYPE_MEDIUM_20_TRACKING": 0,
  "CORE_TYPE_MEDIUM_20_WEIGHT": 500,
  "CORE_TYPE_MEDIUM_20_CASE": "regular",
  "CORE_TYPE_MEDIUM_20_NUMBER_SPACING": "default",
  "CORE_TYPE_MEDIUM_20_SCALE": "paragraph-20",
  "CORE_TYPE_MEDIUM_20_FONT_FAMILY": "Square Sans Text",
  "CORE_TYPE_MEDIUM_30_SIZE": 16,
  "CORE_TYPE_MEDIUM_30_LEADING": 24,
  "CORE_TYPE_MEDIUM_30_TRACKING": 0,
  "CORE_TYPE_MEDIUM_30_WEIGHT": 500,
  "CORE_TYPE_MEDIUM_30_CASE": "regular",
  "CORE_TYPE_MEDIUM_30_NUMBER_SPACING": "default",
  "CORE_TYPE_MEDIUM_30_SCALE": "paragraph-30",
  "CORE_TYPE_MEDIUM_30_FONT_FAMILY": "Square Sans Text",
  "CORE_TYPE_MEDIUM_WEIGHT": 500,
  "CORE_TYPE_SEMIBOLD_10_SIZE": 12,
  "CORE_TYPE_SEMIBOLD_10_LEADING": 18,
  "CORE_TYPE_SEMIBOLD_10_TRACKING": 0,
  "CORE_TYPE_SEMIBOLD_10_WEIGHT": 600,
  "CORE_TYPE_SEMIBOLD_10_CASE": "regular",
  "CORE_TYPE_SEMIBOLD_10_NUMBER_SPACING": "default",
  "CORE_TYPE_SEMIBOLD_10_SCALE": "paragraph-10",
  "CORE_TYPE_SEMIBOLD_10_FONT_FAMILY": "Square Sans Text",
  "CORE_TYPE_SEMIBOLD_20_SIZE": 14,
  "CORE_TYPE_SEMIBOLD_20_LEADING": 22,
  "CORE_TYPE_SEMIBOLD_20_TRACKING": 0,
  "CORE_TYPE_SEMIBOLD_20_WEIGHT": 600,
  "CORE_TYPE_SEMIBOLD_20_CASE": "regular",
  "CORE_TYPE_SEMIBOLD_20_NUMBER_SPACING": "default",
  "CORE_TYPE_SEMIBOLD_20_SCALE": "paragraph-20",
  "CORE_TYPE_SEMIBOLD_20_FONT_FAMILY": "Square Sans Text",
  "CORE_TYPE_SEMIBOLD_30_SIZE": 16,
  "CORE_TYPE_SEMIBOLD_30_LEADING": 24,
  "CORE_TYPE_SEMIBOLD_30_TRACKING": 0,
  "CORE_TYPE_SEMIBOLD_30_WEIGHT": 600,
  "CORE_TYPE_SEMIBOLD_30_CASE": "regular",
  "CORE_TYPE_SEMIBOLD_30_NUMBER_SPACING": "default",
  "CORE_TYPE_SEMIBOLD_30_SCALE": "paragraph-30",
  "CORE_TYPE_SEMIBOLD_30_FONT_FAMILY": "Square Sans Text",
  "CORE_TYPE_SEMIBOLD_WEIGHT": 600,
  "CORE_TYPE_REGULAR_WEIGHT": 400,
  "CORE_TYPE_BOLD_WEIGHT": 700,
  "CORE_TYPE_FONT_FAMILY": "Square Sans Text",
  "CORE_TYPE_MONO_FONT_FAMILY": "Square Sans Mono"
};
