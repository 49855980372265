import { makeObservable, observable, runInAction } from 'mobx';
import Logger from 'src/Logger';

/**
 * The IndexedDB version. Note that every schema change, and in particular
 * any newly created table, must increment this version and create the appropriate
 * migration in {@link #migrateDatabase()} below.
 *
 * Version history:
 *   0. Empty database
 *   1. [2020-05-04; gabor@] Added TABLE_LOCAL_UTTERANCE
 *   2. [2020-09-08; eblaine@] Changed schema for client-side IDs to be
 *      JSON strings: '{ "dbKey": "<message dest>", "uuid": "<uuid>" }'.
 *      Cleared everybody's data that's not in this format.
 *   3. [2020-12-16]: Deprecate MessageDestination for MediumWithInformation.
 *      Cleared everybody's data that's not in this format.
 *   4. [2021-01-27]: Change MediumWithInformation to new MessageDestination.
 *      Cleared everybody's data that's not in this format.
 *   5. [2024-02-27]: Changed schema for client-side IDs to be
 *      JSON strings: '{ "transcriptId": "<transcriptId>", "uuid": "<uuid>" }'.
 */
const DB_VERSION = 5;

/**
 * A simple wrapper around IndexedDB to provide Lazy/Promise interfaces
 * to the basic actions for a local database. Also provides functions
 * to add, remove, and change sendStatus of locally stored utterances.
 * If IndexedDB is not supported a Map will be used instead.
 */
export default class IndexedDB<K extends IDBValidKey, V> {
  /**
   * A map that is used as alternative when IndexedDB is not supported.
   */
  localIndexedDB: Map<K, V> = new Map();

  /**
   * The IndexedDB database we're connecting to. This is usually the default database
   * for Messenger.
   */
  database: string;

  /**
   * The IndexedDB table we're writing to. Note that the types on this table
   * are not checked -- it's the caller's responsibility to make sure that the same types
   * are used with the same store.
   */
  store: string;

  /**
   * If true, IndexedDB has loaded and persisted any local items. From here on, we shoul
   * use the underlying IndexedDB implementation.
   */
  isStorageReady = false;

  /**
   * Create a new IndexedDB connection.
   *
   * @param {string} store The database store (i.e., "table") to use
   * @param {string} database The name of the database to connect to. Defaults to 'messenger',
   *                          and shouldn't need to be changed for most cases.
   */
  constructor(store: string, database = 'messenger') {
    makeObservable(this, {
      isStorageReady: observable,
    });

    this.store = store;
    this.database = database;
    this.init();
  }
  init = (): void => {
    if ('indexedDB' in window) {
      const request: IDBOpenDBRequest = window.indexedDB.open(
        this.database,
        DB_VERSION,
      );
      request.onsuccess = () => {
        // If the database is on the correct version but doesn't have the table,
        // it will not go through the `onupgradeneeded` flow, skipping the table
        // creation. Since `createObjectStore` can only be called in a version
        // change transaction (through `onupgradeneeded`), we must reset the
        // database if the table is missing.
        if (!request.result.objectStoreNames.contains(this.store)) {
          // First close the db before deleting it
          request.result.close();

          const deleteDatabaseRequest: IDBOpenDBRequest =
            window.indexedDB.deleteDatabase(this.database);

          deleteDatabaseRequest.onsuccess = () => {
            // After deleting the database, we need to re-open it and let it run
            // through the `onupgradeneeded` flow to create the table.
            this.init();
          };
          deleteDatabaseRequest.onerror = () => {
            Logger.warn(
              `Could not reset IndexedDB database: ${deleteDatabaseRequest.error}`,
            );
          };
          return;
        }
        this._persist(new Map()).then(() => {
          this.localIndexedDB.clear();
          runInAction(() => {
            this.isStorageReady = true;
          });
        });
      };
      request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
        if (event.oldVersion != null && event.newVersion != null) {
          this.migrateDatabase(request.result, event.newVersion);
        }
      };
      request.onerror = (e) => {
        const request = e.target as IDBOpenDBRequest;
        Logger.warn(`Could not initialize IndexedDB: ${request.error}`);
      };
    } else {
      Logger.warn("This browser doesn't support IndexedDB");
    }
  };

  /**
   * A utility function for persisting all of the local entries to the real IndexedDB
   * instance. This is called recursively on completion to ensure that any entries added
   * while persisting are also persisted.
   *
   * @template K
   * @template V
   * @param {Map<K,V>} alreadySaved The set of entries that have already been persisted to disk.
   *                                This is used to prevent infinitely recursion on persisting
   *                                keys that are already persisted, but still detecting when new
   *                                keys have been added.
   * @private
   */
  _persist = (alreadySaved: Map<K, V>): Promise<void> => {
    const promises: Promise<V>[] = [];
    this.localIndexedDB.forEach((value: V, key: K) => {
      if (!alreadySaved.has(key) || alreadySaved.get(key) !== value) {
        promises.push(this.set(key, value, true));
        alreadySaved.set(key, value);
      }
    });
    if (promises.length > 0) {
      return Promise.all(promises).then(() => this._persist(alreadySaved));
    }
    return Promise.resolve();
  };

  /**
   * Get the value associated to a key in a store.
   *
   * @param {any} key - The key to access the value
   */
  get = (key: K): Promise<V | null> =>
    new Promise((resolve, reject) => {
      if (!this.isStorageReady) {
        resolve(this.localIndexedDB.get(key) ?? null);
      } else {
        const oRequest: IDBOpenDBRequest = window.indexedDB.open(this.database);
        oRequest.onsuccess = () => {
          const db: IDBDatabase = oRequest.result;
          try {
            const tx: IDBTransaction = db.transaction(this.store, 'readonly');

            const st: IDBObjectStore = tx.objectStore(this.store);
            const gRequest: IDBRequest<V> = st.get(key);
            gRequest.onsuccess = () => {
              resolve(gRequest.result);
            };
            gRequest.onerror = () => {
              reject(gRequest.error);
            };
          } catch (error) {
            reject(error);
          }
        };
        oRequest.onerror = () => {
          reject(oRequest.error);
        };
      }
    });

  /**
   * Get all the keys in a store.
   */
  getAllKeys = (): Promise<K[]> =>
    new Promise((resolve, reject) => {
      if (!this.isStorageReady) {
        resolve([...this.localIndexedDB.keys()]);
      } else {
        const oRequest: IDBOpenDBRequest = window.indexedDB.open(this.database);
        oRequest.onsuccess = () => {
          const db: IDBDatabase = oRequest.result;
          try {
            const tx: IDBTransaction = db.transaction(this.store, 'readonly');

            const st: IDBObjectStore = tx.objectStore(this.store);
            const gRequest: IDBRequest<IDBValidKey[]> = st.getAllKeys();
            gRequest.onsuccess = () => {
              resolve(gRequest.result as K[]);
            };
            gRequest.onerror = () => {
              reject(gRequest.error);
            };
          } catch (error) {
            reject(error);
          }
        };
        oRequest.onerror = () => {
          reject(oRequest.error);
        };
      }
    });

  /**
   * Set the value associated to a key in a store.
   *
   * Note that {value} and its descendant properties (if it is an object)
   * should NOT be Proxy types as IndexedDB is unable to store them.
   * It is the responsibility of the caller of this function to ensure
   * that the value passed in is in vanilla JS object form. This can be done
   * using toJS() from mobx.
   *
   * @template K
   * @template V
   * @param {K} key - The key to access the value
   * @param {V} value - The value to set
   * @param {boolean} forceSync - If true, force writing to IndexDB, even if it's not yet
   *                              loaded completely.
   */
  set = (key: K, value: V, forceSync = false): Promise<V> =>
    new Promise((resolve, reject) => {
      if (!this.isStorageReady && !forceSync) {
        this.localIndexedDB.set(key, value);
        resolve(value);
      } else {
        const oRequest: IDBOpenDBRequest = window.indexedDB.open(this.database);
        oRequest.onsuccess = () => {
          const db: IDBDatabase = oRequest.result;
          try {
            const tx: IDBTransaction = db.transaction(this.store, 'readwrite');

            const st: IDBObjectStore = tx.objectStore(this.store);
            const sRequest: IDBRequest<IDBValidKey> = st.put(value, key);
            sRequest.onsuccess = () => {
              resolve(value);
            };
            sRequest.onerror = () => {
              reject(sRequest.error);
            };
          } catch (error) {
            reject(error);
          }
        };
        oRequest.onerror = () => {
          reject(oRequest.error);
        };
      }
    });

  /**
   * Remove the key-value pair in a store.
   *
   * @param {any} key - The key to remove
   */
  remove = (key: K): Promise<void> =>
    new Promise((resolve, reject) => {
      if (!this.isStorageReady) {
        if (this.localIndexedDB.delete(key)) {
          resolve();
        }
        reject(new Error(`${key} is not a present in store ${this.store}`));
      } else {
        const oRequest: IDBOpenDBRequest = window.indexedDB.open(this.database);
        oRequest.onsuccess = () => {
          const db: IDBDatabase = oRequest.result;
          try {
            const tx: IDBTransaction = db.transaction(this.store, 'readwrite');
            const st: IDBObjectStore = tx.objectStore(this.store);
            const rRequest: IDBRequest<undefined> = st.delete(key);
            rRequest.onsuccess = () => {
              resolve();
            };
            rRequest.onerror = () => {
              reject(rRequest.error);
            };
          } catch (error) {
            reject(error);
          }
        };
        oRequest.onerror = () => {
          reject(oRequest.error);
        };
      }
    });

  /**
   * Run a migration on the database to the target version (|newVersion|)
   *
   * @param {IDBDatabase} db The database instance we're migrating.
   * @param {number} finalVersion The new version of the database to migrate to.
   */
  migrateDatabase(db: IDBDatabase, finalVersion: number): void {
    Logger.log(`Migrating IndexedDB to ${finalVersion}`);
    const objectStoreNames = db.objectStoreNames;

    if (finalVersion > DB_VERSION) {
      Logger.warn(`DB version not supported: ${finalVersion}`);
    }

    // Final version is guaranteed to be 1 or greater because window.indexedDB.open cannot be called with version 0
    // Since all versions require a `local_utterances` table to be present and cleared, we can run this migration once;
    // otherwise a version change from 1 to 4 would clear the table 3 times.
    if (objectStoreNames.contains(this.store)) {
      db.deleteObjectStore(this.store);
    }
    db.createObjectStore(this.store);
  }
}
