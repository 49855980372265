import React, { ReactElement } from 'react';
import BaseSvgProps from 'src/types/BaseSvgProps';

/**
 * Icon representing the action to stop (square shape).
 */
const StopIcon = (props: BaseSvgProps): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <title>Stop</title>
    <path
      fill="#000"
      fillOpacity=".9"
      d="M14.4 0H1.6A1.6 1.6 0 0 0 0 1.6v12.8A1.6 1.6 0 0 0 1.6 16h12.8a1.6 1.6 0 0 0 1.6-1.6V1.6A1.6 1.6 0 0 0 14.4 0Z"
    />
  </svg>
);

export default StopIcon;
