/**
 * A helper function to make a callback when the 'enter' key is pressed.
 *
 * @param {KeyboardEvent} e
 * @param {Function} callback
 */
export function onKeyDownEnter(
  e: React.KeyboardEvent<HTMLElement>,
  callback: () => void,
): void {
  if (e.key === 'Enter') {
    callback();
  }
}
