import { CDP } from '@squareup/cdp';
import UserJourney, {
  MutableResult,
  Track,
  UserOutcome,
} from '@squareup/user-journey-web';
import Logger from 'src/Logger';
import type MessengerController from 'src/MessengerController';
import { getEnvironment } from 'src/utils/initUtils';

const UJ_CDP_PRODUCTION_API_KEY = '2ed1fd2d-3161-429d-8e5e-c47f62fdb364';
const UJ_CDP_STAGING_API_KEY = '842efe30-fdd8-4b5a-a887-b377f0283251';

/**
 * Names of User Journey events that are tracked.
 */
type UJEventName = 'messenger-send-message';

/**
 * Names of User Journey variants that are tracked.
 */
type UJVariantName = 'files-attached' | 'photos-attached';

/**
 * Store that handles User Journey tracking for critical jobs to be done,
 * using the @squareup/user-journey-web library under the hood.
 * Supports starting, ending, and adding frustration and other metadata to
 * User Journey events.
 */
class UserJourneyStore {
  private _stores: MessengerController;

  private _cdp?: CDP;

  /**
   * UserJourney instance.
   */
  private _uj?: UserJourney;

  /**
   * Whether the store has been initialized.
   */
  isInitialized = false;

  constructor(stores: MessengerController) {
    this._stores = stores;
  }

  init(): void {
    if (this.isInitialized) return;

    const sqOneTrust = this._stores.services.sqOneTrust.getSqOneTrust();
    const merchantToken = this._stores.user.merchantToken;

    if (sqOneTrust.performanceAndAnalyticsCookiesBlocked) {
      // Do not initialize if analytics cookies are blocked
      Logger.log(
        'Instrumentation not initialized as performance and analytics cookies are blocked',
      );
    } else if (!window.localStorage) {
      // Based on https://square.slack.com/archives/CT2R75XHA/p1726075285853249
      // it is recommended to check for local storage, but it may not be necessary
      // if the library adds the check for it
      // TODO (teresalin): Remove if cdp library adds check for localStorage
      Logger.log(
        'Instrumentation not initialized as localStorage is not available',
      );
    } else {
      const environment = getEnvironment();
      const isProduction = environment === 'production';
      this._cdp = new CDP({
        apiKey: isProduction
          ? UJ_CDP_PRODUCTION_API_KEY
          : UJ_CDP_STAGING_API_KEY,
        environment,
      });

      this._cdp.identifyV2({
        entityId: merchantToken,
        entityType: 'merchant',
      });

      this._uj = new UserJourney<UJEventName, UJVariantName>({
        // Should match applicationId here https://docs.google.com/spreadsheets/d/18flx2whKaLmdf3LmJoBoRJzZ86W_fmdK6RBKyjZF2nU/edit?gid=0#gid=0
        applicationId: 'messages',
        track: this._track,
        showLogs: getEnvironment() !== 'production',
      });
    }

    this.isInitialized = true;
  }

  _track: Track = (eventName, eventProps, options) => {
    if (!this._cdp) {
      Logger.logWithDatadog(
        new Error('CDP is not initialized. Cannot track user journey.'),
        {
          eventName,
          eventProps,
          options,
        },
      );
      return;
    }

    this._cdp.trackV2({
      eventName,
      eventProps,
      options,
    });
  };

  /**
   * Wrapper function around UserJourney methods, to catch and log errors.
   *
   * @param {object} args
   * @param {UJEventName} args.event Name of the UserJourney event
   * @param {() => void} args.userJourneyAction Underlying UserJourney method to execute
   * @param {string} args.action Name of the action to log with any errors
   */
  _executeAction = (args: {
    event: UJEventName;
    userJourneyAction: () => void;
    action:
      | 'start'
      | 'end'
      | 'addVariant'
      | 'addFrustrationSignal'
      | 'addTag'
      | 'addBatchMetadata'
      | 'addBatchMetadataAndEnd';
  }): void => {
    const { event, action, userJourneyAction } = args;
    try {
      userJourneyAction();
    } catch (error) {
      Logger.logWithDatadog(
        new Error(`Error executing ${action} for user journey ${event}`, {
          cause: error,
        }),
      );
    }
  };

  start = (event: UJEventName, variant?: UJVariantName): void => {
    this._executeAction({
      action: 'start',
      event,
      userJourneyAction: () => this._uj?.start(event, variant),
    });
  };

  end = (
    event: UJEventName,
    outcome: UserOutcome,
    metadata?: Partial<MutableResult<UJVariantName, string, string, string>>,
  ): void => {
    if (metadata) {
      this._executeAction({
        action: 'addBatchMetadataAndEnd',
        event,
        userJourneyAction: () =>
          this._uj?.addBatchMetadataAndEnd(event, metadata, outcome),
      });
    } else {
      this._executeAction({
        action: 'end',
        event,
        userJourneyAction: () => this._uj?.end(event, outcome),
      });
    }
  };

  addVariant = (event: UJEventName, variant: UJVariantName): void => {
    this._executeAction({
      action: 'addVariant',
      event,
      userJourneyAction: () => this._uj?.setVariant(event, variant),
    });
  };

  addFrustrationSignal = (event: UJEventName, frustration: string): void => {
    this._executeAction({
      action: 'addFrustrationSignal',
      event,
      userJourneyAction: () =>
        this._uj?.addFrustrationSignal(event, frustration),
    });
  };

  addTag = (event: UJEventName, tag: string): void => {
    this._executeAction({
      action: 'addTag',
      event,
      userJourneyAction: () => this._uj?.addTag(event, tag),
    });
  };

  addBatchMetadata = (
    event: UJEventName,
    metadata: Partial<MutableResult<UJVariantName, string, string, string>>,
  ): void => {
    this._executeAction({
      action: 'addBatchMetadata',
      event,
      userJourneyAction: () => this._uj?.addBatchMetadata(event, metadata),
    });
  };
}

export default UserJourneyStore;
