/**
 * Utils properties and functions for rendering photos.
 */
import {
  Attachment,
  IAttachment,
} from 'src/gen/squareup/messenger/v3/messenger_service';
import { TranscriptViewItem, LocalUtterance, Photo } from 'src/MessengerTypes';

// Id of the list where photos are rendered, used to lazily load via IntersectionObserverAPI
export const PHOTOS_INTERSECTION_OBSERVER_ROOT_ID = 'TranscriptViewItemsList';
// Append to photos urls, for optimization on server side
export const PHOTOS_THUMBNAIL_QUERY_PARAM = '&width=168&height=168&fit=crop';

/**
 * A function to get Photos information from Attachments.
 * TOOD(klim): Filter by mime_type once we support other file types
 *
 * @param {IAttachment[]} attachments
 * The attachments to convert into photos
 */
export function getPhotosFromAttachments(
  attachments: readonly IAttachment[],
): Photo[] {
  const photos: Photo[] = [];
  attachments.forEach((attachment) => {
    if (attachment.type === Attachment.AttachmentType.IMAGE && attachment.url) {
      let url = `${attachment.url}?`;
      if (attachment.token) {
        url += `token=${attachment.token}`;
      }
      photos.push({
        attachmentId: attachment.id,
        url,
        hash: attachment.hash,
      });
    }
  });
  return photos;
}

/**
 * Returns a list of photos for a given utterance.
 *
 * @param {LocalUtterance} localUtterance
 * Utterance to get the photos from.
 * @returns {Photo[]}
 */
export const getPhotosFromLocalUtterance = (
  localUtterance: LocalUtterance,
): Photo[] => {
  const { photos, utterance, externalAttachments } = localUtterance;
  if (photos && photos.length > 0) {
    return photos;
  }
  if (utterance.attachments && utterance.attachments.length > 0) {
    return getPhotosFromAttachments(utterance.attachments);
  }
  if (externalAttachments) {
    return externalAttachments.map((externalAttachment) => ({
      url: externalAttachment.url || '',
      isLocal: true,
    }));
  }
  return [];
};

/**
 * Function to parse the list of photos from a list of conversation view items.
 *
 * @param {TranscriptViewItem[]} viewItems
 * The list of view items to retrieve photos from.
 * @returns {Photo[]}
 * The list of the photos associated with the current view items.
 */
export const getPhotosFromViewItems = (
  viewItems: TranscriptViewItem[],
): Photo[] => {
  let attachments: IAttachment[] = [];
  const localUtterancePhotos: Photo[] = [];

  // Extract attachments from utterances
  viewItems.forEach((item) => {
    // TODO(wdetlor): Replace with getUtteranceFromTranscriptViewItem once implementation is available on master branch
    if (item.dataType === 'UTTERANCE') {
      const utterance = item.data as LocalUtterance;
      if (utterance.utterance.metadata?.replyToUtterance?.attachments) {
        attachments.push(
          ...utterance.utterance.metadata.replyToUtterance.attachments,
        );
      }
      if (utterance.utterance.attachments) {
        attachments.push(...utterance.utterance.attachments);
      }
      if (utterance.photos?.length) {
        localUtterancePhotos.push(...utterance.photos);
      }
    } else if (item.dataType === 'SQUARE_GO_REVIEW' && item.attachedUtterance) {
      localUtterancePhotos.push(
        ...getPhotosFromLocalUtterance(item.attachedUtterance),
      );
    }
  });

  // De-duplicate the array
  // Local utterance photos are not included in this step because they do not have an attachment id
  attachments = attachments.filter((attachment, index) => {
    return (
      index ===
      attachments.findIndex((item) => {
        return item.id === attachment.id;
      })
    );
  });

  return [
    ...getPhotosFromAttachments(attachments),
    ...localUtterancePhotos,
  ].reverse();
};
