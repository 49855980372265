import React, { ReactElement, RefObject } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import SuggestionRow from 'src/pages/TranscriptViewPage/components/Suggestions/components/SuggestionRow/SuggestionRow';
import PhotoIcon from 'src/svgs/PhotoIcon';

export type PhotoSuggestionProps = {
  photoInputRef: RefObject<HTMLInputElement>;
  trackClickSuggestion: () => void;
};

/**
 * Renders a photo suggestion, which opens the system file viewer to select
 * image to upload.
 *
 * @example
 * Basic usage:
 * <PhotoSuggestion
 *   trackClickSuggestions={() => {...}}
 * />
 * @param {RefObject} photoInputRef
 * The reference to the photo input element.
 * @param {Function} trackClickSuggestions
 * The function to track when the suggestion is clicked.
 * @author klim
 */
const PhotoSuggestion = observer(
  ({
    photoInputRef,
    trackClickSuggestion,
  }: PhotoSuggestionProps): ReactElement => {
    const { t } = useTranslation();

    return (
      <SuggestionRow
        onClick={() => {
          trackClickSuggestion();
          photoInputRef.current?.click();
        }}
        text={t('SmartSuggestions.photo')}
        icon={<PhotoIcon color="#0055CC" />}
      />
    );
  },
);

export default PhotoSuggestion;
