/**
 * Get the indexes of a specific char in a string.
 *
 * @param {string} text - the text to search from
 * @param {string} char - the char to search
 */
export function getAllIndexesOfChar(text: string, char: string): number[] {
  const results = [];
  for (let i = 0; i < text.length; i++) {
    if (text.charAt(i) === char) {
      results.push(i);
    }
  }
  return results;
}

/**
 * Separate a string into substrings group by quotes. Only supports
 * double quotes (").
 * e.g.
 * input: this is "square messages" at your service.
 * output:
 * 1: `this is `
 * 2: `"square messages"`
 * 3: ` at your service.`
 *
 * @param {string} text
 */
export function separateTextByQuotes(text: string): string[] {
  const indexes = getAllIndexesOfChar(text, '"');

  // Case 0: no match
  if (indexes.length === 0) {
    return [text];
  }

  const results = [text.slice(0, indexes[0])];
  for (let i = 0; i < indexes.length; i++) {
    if ((i + 1) % 2 === 0) {
      // Case 1: outside of quotes, e.g. "abc" (here) <--
      if (i + 1 < indexes.length) {
        // Case 1a: there is another quote ahead, slice after
        // the current quote to before the next quote
        results.push(text.slice(indexes[i] + 1, indexes[i + 1]));
      } else {
        // Case 1b: there isn't another quote ahead, slice after
        // the current quote till end of text
        results.push(text.slice(indexes[i] + 1, text.length));
      }
    } else {
      // Case 2: within quotes, e.g. "(here) <--"
      if (i + 1 < indexes.length) {
        // Case 2a: there is an ending quote, slice from current quote
        // to next quote
        results.push(text.slice(indexes[i], indexes[i + 1] + 1));
      } else {
        // Case 2b: there is no closing quote (incomplete quote), slice
        // from current quote till end of text
        results.push(text.slice(indexes[i], text.length));
      }
    }
  }

  return results.filter((substring) => substring !== '');
}
