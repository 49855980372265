import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { MarketBanner } from 'src/components/Market';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import { onKeyDownEnter } from 'src/utils/keyboardUtils';
import './NoUnitsAvailableToRegisterBanner.scss';

/**
 * Banner rendered to indicate to the user that there are no available units to register.
 */
const NoUnitsAvailableToRegisterBanner = observer((): ReactElement => {
  const { navigation } = useMessengerControllerContext();
  const { t } = useTranslation();

  const onClick = (): void => navigation.openSheet('SETTINGS');

  return (
    <MarketBanner
      variant="success"
      className="NoUnitsAvailableToRegisterBanner"
    >
      {t('NoUnitsAvailableToRegisterBanner.text')}
      <a
        slot="action"
        onClick={onClick}
        onKeyDown={(e) => onKeyDownEnter(e, onClick)}
      >
        {t('NoUnitsAvailableToRegisterBanner.primary_button_text')}
      </a>
    </MarketBanner>
  );
});

export default NoUnitsAvailableToRegisterBanner;
