import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { Trans, useTranslation } from 'react-i18next';
import { MarketLink, MarketList } from 'src/components/Market';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import Link from 'src/components/Link/Link';
import { getMPlusSubscriptionDashboardUrl } from 'src/utils/url';
import NumberRowsContainer from 'src/components/NumberRowsContainer/NumberRowsContainer';
import './BusinessNumbersSetting.scss';

const MAX_UNITS_TO_SHOW = 3;

/**
 * Component that renders the business numbers section of the settings page.
 */
const BusinessNumbersSetting = observer((): ReactElement => {
  const { navigation, user, subscription, event } =
    useMessengerControllerContext();
  const { t } = useTranslation();
  const { unitsWithPendingOrDedicatedNumbers, units } = user;

  const logGetNumbersBackEvent = (): void => {
    event.track('Click Settings Get Numbers Back');
  };

  const mPlusSubscriptionDashboardUrl = getMPlusSubscriptionDashboardUrl;

  return (
    <div className="BusinessNumbers__container">
      <MarketList transient>
        <h3>
          {t('SettingsPage.business_numbers.title', {
            count: unitsWithPendingOrDedicatedNumbers.length,
          })}
        </h3>
        <p className="paragraph-30">
          {subscription.isSubscriptionDelinquent ? (
            <Trans
              i18nKey={
                unitsWithPendingOrDedicatedNumbers.length === 1
                  ? 'SettingsPage.business_numbers.terminated_text'
                  : 'SettingsPage.business_numbers.terminated_text_multiple'
              }
              components={{
                1: (
                  <MarketLink
                    href={mPlusSubscriptionDashboardUrl}
                    target="_self"
                    onClick={logGetNumbersBackEvent}
                  />
                ),
              }}
            />
          ) : (
            !subscription.isExpiring &&
            unitsWithPendingOrDedicatedNumbers.length < units.size && (
              <>
                <MarketLink
                  href={mPlusSubscriptionDashboardUrl}
                  target="_self"
                  onClick={() => {
                    event.track('Click Settings Number Action', {
                      free_trial: subscription.isFreeTrial,
                      action_type_name: 'add_additional_number',
                    });
                  }}
                >
                  {t('SettingsPage.business_numbers.desc_link')}
                </MarketLink>
                {t('SettingsPage.business_numbers.desc_text')}
              </>
            )
          )}
        </p>
        <NumberRowsContainer maxUnitsToShow={MAX_UNITS_TO_SHOW} />
      </MarketList>
      {unitsWithPendingOrDedicatedNumbers.length > MAX_UNITS_TO_SHOW && (
        <Link
          className="BusinessNumbers__view-all-link"
          onClick={() => {
            event.track('Click Settings Number Action', {
              free_trial: subscription.isFreeTrial,
              action_type_name: 'view_all_lines',
            });
            navigation.sheet.navigateTo('BUSINESS_NUMBERS');
          }}
        >
          {t('SettingsPage.business_numbers.view_all_label', {
            count: unitsWithPendingOrDedicatedNumbers.length,
          })}
        </Link>
      )}
    </div>
  );
});

export default BusinessNumbersSetting;
