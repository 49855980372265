import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import {
  MarketButton,
  MarketPill,
  MarketRow,
  MarketTooltip,
} from 'src/components/Market';
import './VerificationRow.scss';
import { SUPPORT_URL } from 'src/utils/url';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import { onKeyDownEnter } from 'src/utils/keyboardUtils';

export type VerificationRowProps = {
  unitName: string;
  unitToken: string;
  variant: 'PENDING' | 'FAILED_RETRYABLE';
};

/**
 * Component that renders the row UI for a unit in a not verified status.
 *
 * @param {string} unitName
 * Name of the unit.
 * @param {string} unitToken
 * Token of the unit.
 * @param {string} variant
 * Either 'PENDING' which shows a pill, or 'FAILED_RETRYABLE' which shows
 * a button to resubmit TFV.
 */
const VerificationRow = observer(
  ({ unitName, unitToken, variant }: VerificationRowProps): ReactElement => {
    const { t } = useTranslation();
    const { navigation, event } = useMessengerControllerContext();

    const supportTooltip = (
      <MarketTooltip interaction="click" className="VerificationRow__tooltip">
        <div slot="content">
          {t('VerificationRow.tooltip.description')}
          <a
            href={SUPPORT_URL}
            target="_blank"
            rel="noopener noreferrer"
            className="VerificationRow__tooltip__link"
          >
            {t('VerificationRow.tooltip.button_label')}
          </a>
        </div>
      </MarketTooltip>
    );

    let trailingAccessory;
    switch (variant) {
      case 'FAILED_RETRYABLE': {
        const onClick = (): void => {
          event.track('Click Settings Review Resubmit');
          navigation.openSheet({
            name: 'UNIT_VERIFICATION_FORM',
            unitToken,
          });
        };

        trailingAccessory = (
          <MarketButton
            size="small"
            rank="primary"
            onClick={onClick}
            onKeyDown={(e) => onKeyDownEnter(e, onClick)}
          >
            {t('VerificationRow.resubmit_label')}
          </MarketButton>
        );
        break;
      }
      case 'PENDING':
      default:
        trailingAccessory = (
          <MarketPill variant="warning">
            {t('VerificationRow.in_progress_label')}
          </MarketPill>
        );
    }

    return (
      <MarketRow>
        <label slot="label" className="VerificationRow__row-label">
          {t('VerificationRow.title')}
        </label>
        <p slot="subtext">{unitName}</p>
        <div
          slot="trailing-accessory"
          className="VerificationRow__trailing-accessory"
        >
          {trailingAccessory}
          {supportTooltip}
        </div>
      </MarketRow>
    );
  },
);

export default VerificationRow;
