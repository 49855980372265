/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';
import './permissions';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "multipass": {
        "nested": {
          "Permissions": {
            "fields": {
              "value": {
                "type": "squareup.multipass.RequestPermissionFlags",
                "id": 1,
                "rule": "repeated"
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.multipass?.nested?.Permissions) {
	$root.addJSON(jsonData);
}

/**
 * RPC method option specifying the permissions required to access the method.
 *
 * SOURCE Permissions @ squareup/multipass/options.proto at 38:1
 */
export const Permissions = $root.lookupType('squareup.multipass.Permissions');
fixType(Permissions);
Builder.createAndAttachToType(Permissions);
