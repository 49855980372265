/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "httprpc": {
        "nested": {
          "MIMEType": {
            "values": {
              "NONE": 0,
              "APPLICATION_JSON": 1,
              "APPLICATION_X_PROTOOBUF": 2
            }
          },
          "HttpServiceOptions": {
            "fields": {
              "path": {
                "type": "string",
                "id": 1,
                "rule": "optional"
              },
              "requestContentType": {
                "type": "squareup.httprpc.MIMEType",
                "id": 3,
                "rule": "optional",
                "options": {
                  "default": "APPLICATION_X_PROTOOBUF"
                }
              },
              "responseContentType": {
                "type": "squareup.httprpc.MIMEType",
                "id": 4,
                "rule": "optional",
                "options": {
                  "default": "APPLICATION_X_PROTOOBUF"
                }
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.httprpc?.nested?.MIMEType && !$root.nested?.squareup?.nested?.httprpc?.nested?.HttpServiceOptions) {
	$root.addJSON(jsonData);
}

/**
 * Mime types used at square
 *
 * SOURCE MIMEType @ squareup/httprpc/httprpc.proto at 23:1
 */
export const MIMEType = $root.lookupEnum('squareup.httprpc.MIMEType').values;
/**
 * SOURCE HttpServiceOptions @ squareup/httprpc/httprpc.proto at 31:1
 */
export const HttpServiceOptions = $root.lookupType('squareup.httprpc.HttpServiceOptions');
fixType(HttpServiceOptions);
Builder.createAndAttachToType(HttpServiceOptions);
