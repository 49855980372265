import { MarketField, MarketInputText } from 'src/components/Market';
import { observer } from 'mobx-react';
import React, { ReactElement } from 'react';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import {
  GetUnitsInformationResponse,
  IUnitInformation,
} from 'src/gen/squareup/messenger/v3/messenger_service';
import TextInput from './TextInput';
import { i18nPhonePlaceholder } from 'src/i18n/formatValues';
import { missingUnitFieldToInputLabel } from './utils';
import { useTranslation } from 'react-i18next';

/**
 * The "Contact information" section in the individual unit verification form for
 * M+ Onboarding v2. This section covers the name, phone number, and email inputs.
 *
 * @example
 * Usage:
 * <ContactInformationSection />
 */
const ContactInformationSection = observer((): ReactElement => {
  const {
    unitVerification: {
      setUnitInformation,
      errors,
      unitToken,
      getDisplayValue,
    },
    user,
  } = useMessengerControllerContext();
  const { t } = useTranslation();
  const handleMarketInputValueChange = (
    value: string,
    field: keyof IUnitInformation,
  ): void => {
    setUnitInformation(
      {
        unitInfoKey: field,
      },
      value,
    );
  };

  return (
    <>
      <h3
        className="UnitVerificationForm__section__title"
        data-testid="ContactInformationSection"
      >
        {t('ContactInformationSection.title')}
      </h3>
      <form
        className="market-grid-container UnitVerificationForm__section__form"
        onSubmit={(event) => {
          event.preventDefault();
        }}
      >
        <MarketField
          className="market-grid-item-medium"
          invalid={Boolean(errors?.contactFirstName) || undefined}
          data-testid="ContactInformationSection__contactFirstName"
        >
          <MarketInputText
            key={`${unitToken}-contactFirstName`}
            value={getDisplayValue('contactFirstName') ?? ''}
          >
            <label>
              {missingUnitFieldToInputLabel(
                GetUnitsInformationResponse.FieldForReview.CONTACT_FIRST_NAME,
              )}
            </label>
            <input
              slot="input"
              data-testid="ContactInformationSection__contactFirstName__input"
              type="text"
              onChange={(e) => {
                handleMarketInputValueChange(
                  e.target.value,
                  'contactFirstName',
                );
              }}
              value={getDisplayValue('contactFirstName') ?? ''}
            />
          </MarketInputText>
          {Boolean(errors?.contactFirstName) && (
            <small slot="error">{errors?.contactFirstName}</small>
          )}
        </MarketField>
        <MarketField
          className="market-grid-item-medium"
          invalid={Boolean(errors?.contactLastName) || undefined}
          data-testid="ContactInformationSection__contactLastName"
        >
          <MarketInputText
            key={`${unitToken}-address-zipCode`}
            value={getDisplayValue('contactLastName') ?? ''}
          >
            <label>
              {missingUnitFieldToInputLabel(
                GetUnitsInformationResponse.FieldForReview.CONTACT_LAST_NAME,
              )}
            </label>
            <input
              slot="input"
              data-testid="ContactInformationSection__contactLastName__input"
              type="text"
              onChange={(e) => {
                handleMarketInputValueChange(e.target.value, 'contactLastName');
              }}
              value={getDisplayValue('contactLastName') ?? ''}
            />
          </MarketInputText>
          {Boolean(errors?.contactLastName) && (
            <small slot="error">{errors?.contactLastName}</small>
          )}
        </MarketField>

        <TextInput
          field={
            GetUnitsInformationResponse.FieldForReview.CONTACT_PHONE_NUMBER
          }
          placeholder={i18nPhonePlaceholder(user.countryCode)}
        />
        <TextInput
          field={GetUnitsInformationResponse.FieldForReview.CONTACT_EMAIL}
        />
      </form>
    </>
  );
});

export default ContactInformationSection;
