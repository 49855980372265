/**
 * An icon representing order.
 */
import React, { ReactElement } from 'react';

function OrderIcon(): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <title>Order</title>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M10 8a2 2 0 1 1 4 0h-4ZM8 8a4 4 0 1 1 8 0h2a1 1 0 0 1 1 1v5.877l.97 3.88A1 1 0 0 1 19 20H5a1 1 0 0 1-.97-1.242L5 14.877V9a1 1 0 0 1 1-1h2Zm-1 7v-5h10v5c0 .082.01.163.03.242L17.72 18H6.28l.69-2.758A1 1 0 0 0 7 15Zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default OrderIcon;
