:host {
  display: flex;
}

@media only screen and (max-width: 800px) {
  :host([mobile-menu-position="top"]) {
    flex-direction: column;

    list-view {
      margin-bottom: var(--date-picker-menu-vertical-spacing);
    }
  }

  :host([mobile-menu-position="bottom"]) {
    flex-direction: column-reverse;

    list-view {
      margin-top: var(--date-picker-menu-vertical-spacing);
    }
  }
}

.left-nav {
  margin-right: var(--date-picker-header-button-horizontal-spacing);
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--date-picker-header-minimum-height);
  margin-bottom: var(--date-picker-header-vertical-spacing);
}

nav {
  display: flex;
}

group-view {
  display: grid;
  grid-template-rows: repeat(auto-fill, 1fr);
  grid-template-columns: repeat(7, 1fr);
  gap: var(--date-picker-grid-item-vertical-padding) var(--date-picker-grid-item-horizontal-padding);
  box-sizing: border-box;
}
