/**
 * A phone book. To denote customer or the customer directory.
 */

import React, { ReactElement } from 'react';

function PhoneBookIcon(): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      viewBox="0 0 192 192"
    >
      <title>Directory</title>
      <defs>
        <linearGradient
          id="PhoneBookIcon__a"
          x1="4.43"
          x2="94.19"
          y1="18.69"
          y2="174.15"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".28" stopColor="#006aff" />
          <stop offset=".58" stopColor="#caf236" />
          <stop offset=".86" stopColor="#e4ff80" />
        </linearGradient>
        <linearGradient
          id="PhoneBookIcon__b"
          x1="139.79"
          x2="97.82"
          y1="60.65"
          y2="133.33"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".24" stopColor="#006aff" />
          <stop offset=".66" stopColor="#caf236" />
          <stop offset="1" stopColor="#e4ff80" />
        </linearGradient>
      </defs>
      <path
        fill="#121212"
        d="M144 168H64V24h80c13.25 0 24 10.75 24 24v96c0 13.25-10.75 24-24 24Z"
      />
      <path
        fill="url(#PhoneBookIcon__a)"
        d="M48 24h16v144H48c-13.25 0-24-10.75-24-24V48c0-13.25 10.75-24 24-24Z"
      />
      <circle cx="116" cy="84" r="36.25" fill="#fff" />
      <path
        fill="none"
        stroke="#fff"
        strokeMiterlimit="10"
        strokeWidth="8"
        d="M80 140h72"
      />
      <path
        fill="url(#PhoneBookIcon__b)"
        d="M87.62 106.53C92.42 95.62 103.32 88 116 88s23.58 7.62 28.38 18.53c-6.64 8.35-16.88 13.72-28.38 13.72s-21.74-5.36-28.38-13.72ZM128 72c0-6.63-5.37-12-12-12s-12 5.37-12 12 5.37 12 12 12 12-5.37 12-12Z"
      />
    </svg>
  );
}

export default PhoneBookIcon;
