/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "objc": {
        "nested": {
          "ServiceOptions": {
            "fields": {
              "generateJsonStubs": {
                "type": "bool",
                "id": 1,
                "rule": "optional",
                "options": {
                  "default": false
                }
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.objc?.nested?.ServiceOptions) {
	$root.addJSON(jsonData);
}

/**
 * SOURCE ServiceOptions @ squareup/objc/objc-rpc.proto at 6:1
 */
export const ServiceOptions = $root.lookupType('squareup.objc.ServiceOptions');
fixType(ServiceOptions);
Builder.createAndAttachToType(ServiceOptions);
