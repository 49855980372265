import { useEffect, useState } from 'react';
import { getMobileMaxWidth } from 'src/utils/mobile';

/**
 * Determines if the mobile view should be rendered for the current screen size.
 *
 * @returns {boolean} True if mobile and false if not
 */
const useIsMobile = (): boolean => {
  const mediaQueryList = window.matchMedia(
    `(max-width: ${getMobileMaxWidth()})`,
  );
  const [isMobile, setIsMobile] = useState<boolean>(mediaQueryList.matches);

  useEffect(() => {
    const onChange = (event: MediaQueryListEvent): void => {
      setIsMobile(event.matches);
    };
    mediaQueryList.addEventListener?.('change', onChange);
    return () => {
      mediaQueryList.removeEventListener?.('change', onChange);
    };
  }, [mediaQueryList]);

  return isMobile;
};

export default useIsMobile;
