import {
  ConsentStatus,
  ExecuteGraphQLRequest,
  IAttachment,
  IExecuteGraphQLRequest,
  IExecuteGraphQLResponse,
  IExternalAttachment,
  IRequestConsentResponse,
  ISendMessageRequest,
  ISendMessageResponse,
  IStagedAttachment,
  IUtterance,
  RequestConsentRequest,
  SendMessageRequest,
  Status,
} from 'src/gen/squareup/messenger/v3/messenger_service';
import { v4 as uuidv4 } from 'uuid';
import Services from 'src/services/Services';
import { callRpc, callV3Rpc } from 'src/utils/apiUtils';
import { CheckoutLink, Coupon, Money } from 'src/MessengerTypes';
import { currencyKeyToCode } from 'src/utils/moneyUtils';
import Logger from 'src/Logger';
import { checkoutLinkGraphQLToProto } from 'src/utils/transcriptUtils';
import {
  ICheckConsentRequest,
  CheckConsentResponse,
  CheckConsentRequest,
} from 'src/gen/squareup/messenger/v2/messenger_service';

const isSellerAndCustomerInfo = (
  id: number | SendMessageRequest.ISellerAndCustomerInfo,
): id is SendMessageRequest.ISellerAndCustomerInfo =>
  typeof id !== 'number' &&
  id?.sellerKey !== undefined &&
  id?.customerToken !== undefined &&
  id?.medium !== undefined;

type AmountCouponData = {
  amount: number;
  currencyCode: string;
};

type PercentageCouponData = {
  percentage: number;
};

const isPercentageCoupon = (
  data: AmountCouponData | PercentageCouponData,
): data is PercentageCouponData =>
  (data as PercentageCouponData)?.percentage !== undefined;

/**
 * Api responsible for operations related to sending messages.
 */
class MessagingApi {
  private _services: Services;

  constructor(services: Services) {
    this._services = services;
  }

  /**
   * Method to send a message on a provided transcript.
   *
   * @param {object} args
   * @param {number | SendMessageRequest.ISellerAndCustomerInfo} args.id
   * An identifier to indicate which transcript to send the message on.
   * Either a transcript ID or a set of seller and customer info.
   * @param {IUtterance} args.utterance
   * The utterance to send.
   * @param {boolean} args.confirmedConsent
   * Flag indicating whether the user as confirmed consent for this operation.
   * @param {IStagedAttachment[]} args.stagedAttachments
   * The list of attachments to send with the message.
   * @param {IExternalAttachment[]} args.externalAttachments
   * The list of external attachments to send with the message.
   * @returns {{ statusCode: Status.Code, consentStatus: ConsentStatus, utteranceId?: number }}
   * The resulting status of the operation and the associated utterance ID.
   */
  sendMessage = async ({
    id,
    utterance,
    confirmedConsent,
    stagedAttachments,
    externalAttachments = [],
  }: {
    id: number | SendMessageRequest.ISellerAndCustomerInfo;
    utterance: IUtterance;
    confirmedConsent: boolean;
    stagedAttachments: IStagedAttachment[];
    externalAttachments?: IExternalAttachment[];
  }): Promise<{
    statusCode: Status.Code;
    utteranceId?: number;
  }> => {
    const request: ISendMessageRequest = {
      idempotencyKey: uuidv4(),
      transcriptId: !isSellerAndCustomerInfo(id) ? id : undefined,
      sellerAndCustomerInfo: isSellerAndCustomerInfo(id) ? id : undefined,
      plainText: utterance.plainText,
      metadata: utterance.metadata,
      confirmedConsent,
      // note that stagedAttachments and attachmentIds are mutually exclusive,
      // the former for freshly uploaded photos, while the latter is for retries
      stagedAttachments,
      attachmentIds: utterance.attachments?.map(
        (attachment: IAttachment) => attachment.id ?? 0,
      ),
      externalAttachments,
    };
    let response: ISendMessageResponse;
    try {
      response = await callV3Rpc({
        name: 'SendMessage',
        rpc: (x) => this._services.messagesV3.sendMessage(x),
        request: SendMessageRequest.create(request),
      });
    } catch (error) {
      // The error thrown is the 'send message' response type
      response = error as ISendMessageResponse;

      if (response?.status?.code === Status.Code.FAILED) {
        // Only log to sentry in the event of an unexpected failure, as there are valid error cases (i.e. blocked, rate limited, etc.)
        Logger.logWithSentry(
          'TranscriptsApi:sendMessage - Error response returned when sending message to the server.',
          'error',
          {
            error,
          },
        );
      }
    }
    return {
      statusCode:
        response?.status?.code ?? Status.Code.STATUS_CODE_UNRECOGNIZED,
      utteranceId: response?.utteranceId,
    };
  };

  /**
   * Method that requests a coupon be created by the server, and returns the Coupon
   *
   * @param {object} args
   * @param {string} [args.customerToken]
   * The customer token to create the coupon for.
   * @param {AmountCouponData | PercentageCouponData} args.data
   * Data signifying what type of coupon to create and of what value. Either an amount or percentage coupon.
   * @returns {Promise<Coupon>}
   * Resolves with the coupon that was created.
   */
  createCoupon = async ({
    customerToken,
    data,
  }: {
    customerToken?: string;
    data: AmountCouponData | PercentageCouponData;
  }): Promise<Coupon> => {
    let valueQuery;
    if (isPercentageCoupon(data)) {
      valueQuery = `percentage: ${data.percentage}`;
    } else {
      valueQuery = `amount: {
        amount: ${data.amount},
        currency: "${data.currencyCode}"
      }`;
    }
    if (customerToken) {
      valueQuery += `, customerToken: "${customerToken}"`;
    }
    const request: IExecuteGraphQLRequest = {
      idempotencyKey: `${customerToken}_${uuidv4()}`,
      query: `
          mutation {
            createCoupon(
              ${valueQuery}
            ) {
              id,
              code,
              name,
              expirationMillis,
              amount {
                amount,
                currency
              }
              percentage
            }
          }`,
    };

    const response: IExecuteGraphQLResponse = await callV3Rpc({
      name: 'ExecuteGraphQL - Create Coupon',
      rpc: (x) => this._services.messagesV3.executeGraphQL(x),
      request: ExecuteGraphQLRequest.create(request),
    });

    if (response != null && response.response != null) {
      const graphql = JSON.parse(response.response);
      if (graphql.data && graphql.data.createCoupon) {
        const rawCoupon = graphql.data.createCoupon;
        const commonCouponData = {
          id: rawCoupon.id,
          code: rawCoupon.code, // 6 characters coupon code
          name: rawCoupon.name, // Backend generated, for e.g. $1 off your next purchase
          expirationMillis: rawCoupon.expirationMillis,
        };
        if (isPercentageCoupon(data)) {
          return {
            ...commonCouponData,
            percentage: rawCoupon.percentage,
          };
        }
        return {
          ...commonCouponData,
          amount: {
            amount: rawCoupon?.amount?.amount,
            currency: currencyKeyToCode(rawCoupon.amount?.currency ?? ''),
          },
        };
      }
    }
    throw new Error('Error creating coupon.');
  };

  /**
   * Method that requests a checkout link be created by the server, and returns the created checkout link.
   *
   * @param {object} args
   * @param {string} args.unitToken
   * The token of the unit to associate the created checkout link with.
   * @param {Required<Money>} args.amount
   * The details of the amount and currency for the checkout link.
   * @returns {CheckoutLink}
   * The checkout link that was created by the server.
   */
  createCheckoutLink = async ({
    unitToken,
    amount,
  }: {
    unitToken: string;
    amount: Required<Money>;
  }): Promise<CheckoutLink> => {
    const request = {
      idempotencyKey: uuidv4(),
      query: `
          mutation {
            createCheckoutLink(
              type: "ONE_TIME_LINK",
              amount: {
                amount: ${amount.amount},
                currency: "${amount.currency}"
              },
              unitToken: "${unitToken}"
            ) {
              id
              type
              url
              amount {
                amount
                currency
              }
              imageUrl
              title
            }
          }`,
    };

    const response: IExecuteGraphQLResponse = await callV3Rpc({
      name: 'ExecuteGraphQL - Create Checkout Link',
      rpc: (x) => this._services.messagesV3.executeGraphQL(x),
      request: ExecuteGraphQLRequest.create(request),
    });

    if (response != null && response.response != null) {
      const graphql = JSON.parse(response.response);
      if (graphql.data && graphql.data.createCheckoutLink) {
        const rawCheckoutLink = graphql.data.createCheckoutLink;
        return checkoutLinkGraphQLToProto({
          type: rawCheckoutLink.type,
          id: rawCheckoutLink.id,
          url: rawCheckoutLink.url,
          // The following fields come back with a value, or with null, or not present. We standardize to
          // `undefined` for all of them if they are either null or not present.
          // `amount` won't be present if the amount is chosen by the customer
          amount: rawCheckoutLink.amount ?? undefined,
          imageUrl: rawCheckoutLink.imageUrl ?? undefined,
          title: rawCheckoutLink.title ?? undefined,
        });
      }
    }
    throw new Error('GraphQL did not respond with a checkout link.');
  };

  /**
   * Requests consent for a merchant to message a customer on a given transcript.
   *
   * @param {number} transcriptId
   * The transcript ID to request consent for.
   * @returns {ConsentStatus}
   * The latest consent status after requesting consent.
   */
  requestConsent = async (transcriptId: number): Promise<ConsentStatus> => {
    const response: IRequestConsentResponse = await callV3Rpc({
      name: 'RequestConsent',
      rpc: (x) => this._services.messagesV3.requestConsent(x),
      request: RequestConsentRequest.create({ transcriptId }),
    });
    return response.consentStatus || ConsentStatus.CONSENT_STATUS_UNRECOGNIZED;
  };

  /**
   * Check consent for either a (contact ID, unit, medium) or a (customer, unit,
   * medium).
   *
   * @param {ICheckConsentRequest} requestData - the entity that would be receiving
   * a message
   * @returns {Promise<CheckConsentResponse>}
   */
  checkConsent = (
    requestData: ICheckConsentRequest,
  ): Promise<CheckConsentResponse> => {
    return callRpc({
      name: 'CheckConsent',
      rpc: (x) => this._services.messages.checkConsent(x),
      request: CheckConsentRequest.create(requestData),
    });
  };
}

export default MessagingApi;
