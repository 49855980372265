/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../../Fixes';
import Builder from '../../../../Builder';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "connect": {
        "nested": {
          "v2": {
            "nested": {
              "common": {
                "nested": {
                  "TimeRange": {
                    "fields": {
                      "startAt": {
                        "type": "string",
                        "id": 1,
                        "rule": "optional"
                      },
                      "endAt": {
                        "type": "string",
                        "id": 2,
                        "rule": "optional"
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.common?.nested?.TimeRange) {
	$root.addJSON(jsonData);
}

/**
 * --
 * @desc Represents a generic time range. The start and end values are
 * represented in RFC 3339 format. Time ranges are customized to be
 * inclusive or exclusive based on the needs of a particular endpoint.
 * Refer to the relevant endpoint-specific documentation to determine
 * how time ranges are handled.
 * --
 *
 * SOURCE TimeRange @ squareup/connect/v2/common/date.proto at 22:1
 */
export const TimeRange = $root.lookupType('squareup.connect.v2.common.TimeRange');
fixType(TimeRange);
Builder.createAndAttachToType(TimeRange);
