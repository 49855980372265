/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';
import '../common/location';
import '../merchants/api';
import './common';
import '../common/gateways';
import '../common/currency_codes';
import '../common/merchant_type';
import '../common/currency';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "roster": {
        "nested": {
          "service": {
            "nested": {
              "Subscription": {
                "fields": {
                  "productKey": {
                    "type": "string",
                    "id": 1,
                    "rule": "optional"
                  },
                  "status": {
                    "type": "squareup.roster.service.Subscription.Status",
                    "id": 2,
                    "rule": "optional"
                  }
                },
                "nested": {
                  "Status": {
                    "values": {
                      "PENDING": 0,
                      "FREE_TRIAL": 1,
                      "ACTIVE": 2,
                      "CANCELED": 3,
                      "IN_GRACE": 4,
                      "DELINQUENT": 5,
                      "TERMINATED": 6
                    }
                  }
                }
              },
              "MerchantProfileLookupResponse": {
                "nested": {
                  "CanadianMerchantProfile": {
                    "fields": {
                      "gstHstNumber": {
                        "type": "string",
                        "id": 1,
                        "rule": "optional"
                      },
                      "qstNumber": {
                        "type": "string",
                        "id": 2,
                        "rule": "optional"
                      }
                    }
                  }
                }
              },
              "PayerLookupResponse": {
                "fields": {
                  "emailId": {
                    "type": "int64",
                    "id": 1,
                    "rule": "optional"
                  },
                  "obfuscatedEmail": {
                    "type": "string",
                    "id": 2,
                    "rule": "optional"
                  },
                  "phoneId": {
                    "type": "int64",
                    "id": 3,
                    "rule": "optional"
                  },
                  "obfuscatedPhone": {
                    "type": "string",
                    "id": 4,
                    "rule": "optional"
                  },
                  "name": {
                    "type": "string",
                    "id": 5,
                    "rule": "optional"
                  },
                  "hasActivePaymentSource": {
                    "type": "bool",
                    "id": 6,
                    "rule": "optional"
                  },
                  "automaticReceiptOptOut": {
                    "type": "bool",
                    "id": 7,
                    "rule": "optional"
                  },
                  "permissions": {
                    "type": "string",
                    "id": 8,
                    "rule": "repeated"
                  },
                  "payerToken": {
                    "type": "string",
                    "id": 9,
                    "rule": "optional"
                  },
                  "profileImageUrl": {
                    "type": "string",
                    "id": 10,
                    "rule": "optional"
                  }
                }
              },
              "PrivateInformation": {
                "fields": {
                  "type": {
                    "type": "string",
                    "id": 1,
                    "rule": "optional"
                  },
                  "tinFideliusToken": {
                    "type": "string",
                    "id": 2,
                    "rule": "optional"
                  },
                  "createdAt": {
                    "type": "int64",
                    "id": 3,
                    "rule": "optional"
                  }
                }
              },
              "UnderwritingDecision": {
                "fields": {
                  "state": {
                    "type": "string",
                    "id": 1,
                    "rule": "optional"
                  }
                }
              },
              "UserLookupStatus": {
                "values": {
                  "INVALID": 0,
                  "SUCCESS": 1,
                  "FAILURE": 2,
                  "ERROR": 3
                }
              },
              "UserLookupResponse": {
                "fields": {
                  "status": {
                    "type": "squareup.roster.service.UserLookupStatus",
                    "id": 1,
                    "rule": "optional"
                  },
                  "message": {
                    "type": "string",
                    "id": 2,
                    "rule": "optional"
                  },
                  "legacyUserId": {
                    "type": "string",
                    "id": 3,
                    "rule": "optional"
                  },
                  "token": {
                    "type": "string",
                    "id": 4,
                    "rule": "optional"
                  },
                  "userToken": {
                    "type": "string",
                    "id": 5,
                    "rule": "optional"
                  },
                  "name": {
                    "type": "string",
                    "id": 6,
                    "rule": "optional"
                  },
                  "secondaryName": {
                    "type": "string",
                    "id": 7,
                    "rule": "optional"
                  },
                  "tertiaryName": {
                    "type": "string",
                    "id": 8,
                    "rule": "optional"
                  },
                  "address": {
                    "type": "squareup.common.location.Address",
                    "id": 9,
                    "rule": "optional"
                  },
                  "userLocale": {
                    "type": "squareup.merchants.api.UserLocale",
                    "id": 10,
                    "rule": "optional"
                  },
                  "phone": {
                    "type": "squareup.common.location.Phone",
                    "id": 11,
                    "rule": "optional"
                  },
                  "categoryCode": {
                    "type": "string",
                    "id": 12,
                    "rule": "optional"
                  },
                  "permissions": {
                    "type": "string",
                    "id": 13,
                    "rule": "repeated"
                  },
                  "userTokensCanTakePaymentsTo": {
                    "type": "string",
                    "id": 14,
                    "rule": "repeated"
                  },
                  "transactionFund": {
                    "type": "squareup.merchants.api.TransactionFund",
                    "id": 15,
                    "rule": "optional"
                  },
                  "gatewayMerchantTokens": {
                    "type": "squareup.roster.service.UserLookupResponse.GatewayMerchantToken",
                    "id": 16,
                    "rule": "repeated"
                  },
                  "fees": {
                    "type": "squareup.merchants.api.UserFees",
                    "id": 17,
                    "rule": "optional"
                  },
                  "supportsTabs": {
                    "type": "bool",
                    "id": 18,
                    "rule": "optional"
                  },
                  "businessType": {
                    "type": "string",
                    "id": 19,
                    "rule": "optional"
                  },
                  "tippingPreference": {
                    "type": "squareup.roster.common.TippingPreference",
                    "id": 20,
                    "rule": "optional"
                  },
                  "email": {
                    "type": "string",
                    "id": 21,
                    "rule": "optional"
                  },
                  "delegateCustomId": {
                    "type": "string",
                    "id": 22,
                    "rule": "optional"
                  },
                  "profileImageUrl": {
                    "type": "string",
                    "id": 23,
                    "rule": "optional"
                  },
                  "gatewayHints": {
                    "type": "squareup.common.gateways.GatewayName",
                    "id": 24,
                    "rule": "repeated"
                  },
                  "shippingPhone": {
                    "type": "squareup.common.location.Phone",
                    "id": 25,
                    "rule": "optional"
                  },
                  "businessName": {
                    "type": "string",
                    "id": 26,
                    "rule": "optional"
                  },
                  "secondaryBusinessName": {
                    "type": "string",
                    "id": 27,
                    "rule": "optional"
                  },
                  "tertiaryBusinessName": {
                    "type": "string",
                    "id": 28,
                    "rule": "optional"
                  },
                  "userName": {
                    "type": "string",
                    "id": 29,
                    "rule": "optional"
                  },
                  "currencyCode": {
                    "type": "squareup.common.CurrencyCode",
                    "id": 30,
                    "rule": "optional"
                  },
                  "merchantType": {
                    "type": "squareup.common.MerchantType",
                    "id": 31,
                    "rule": "optional"
                  },
                  "tzName": {
                    "type": "string",
                    "id": 32,
                    "rule": "optional"
                  },
                  "shippingEnabled": {
                    "type": "bool",
                    "id": 33,
                    "rule": "optional"
                  },
                  "pickupEnabled": {
                    "type": "bool",
                    "id": 34,
                    "rule": "optional"
                  },
                  "businessSubtype": {
                    "type": "string",
                    "id": 35,
                    "rule": "optional"
                  },
                  "frozen": {
                    "type": "bool",
                    "id": 36,
                    "rule": "optional"
                  },
                  "subscription": {
                    "type": "squareup.roster.service.Subscription",
                    "id": 37,
                    "rule": "repeated"
                  },
                  "subscriptionFeatureStatus": {
                    "type": "squareup.roster.common.SubscriptionFeatureStatus",
                    "id": 49,
                    "rule": "repeated"
                  },
                  "shippingAddress": {
                    "type": "squareup.common.location.GlobalAddress",
                    "id": 38,
                    "rule": "optional"
                  },
                  "isMultiunit": {
                    "type": "bool",
                    "id": 39,
                    "rule": "optional"
                  },
                  "businessCategory": {
                    "type": "string",
                    "id": 40,
                    "rule": "optional"
                  },
                  "businessSubCategory": {
                    "type": "string",
                    "id": 41,
                    "rule": "optional"
                  },
                  "personalFirstName": {
                    "type": "string",
                    "id": 42,
                    "rule": "optional"
                  },
                  "personalLastName": {
                    "type": "string",
                    "id": 43,
                    "rule": "optional"
                  },
                  "parentMerchantToken": {
                    "type": "string",
                    "id": 44,
                    "rule": "optional"
                  },
                  "privateInformation": {
                    "type": "squareup.roster.service.PrivateInformation",
                    "id": 45,
                    "rule": "repeated"
                  },
                  "underwritingDecision": {
                    "type": "squareup.roster.service.UnderwritingDecision",
                    "id": 46,
                    "rule": "optional"
                  },
                  "canadianMerchantProfile": {
                    "type": "squareup.roster.service.MerchantProfileLookupResponse.CanadianMerchantProfile",
                    "id": 47
                  },
                  "ukMerchantProfile": {
                    "type": "squareup.roster.service.UserLookupResponse.UkMerchantProfile",
                    "id": 48
                  },
                  "frMerchantProfile": {
                    "type": "squareup.roster.service.UserLookupResponse.FrMerchantProfile",
                    "id": 50
                  }
                },
                "nested": {
                  "GatewayMerchantToken": {
                    "fields": {
                      "gateway": {
                        "type": "string",
                        "id": 1,
                        "rule": "optional"
                      },
                      "merchantToken": {
                        "type": "string",
                        "id": 2,
                        "rule": "optional"
                      },
                      "disabledTimestamp": {
                        "type": "int64",
                        "id": 3,
                        "rule": "optional"
                      }
                    }
                  },
                  "UkMerchantProfile": {
                    "fields": {
                      "vatNumber": {
                        "type": "string",
                        "id": 1,
                        "rule": "optional"
                      },
                      "vatCustomerClass": {
                        "type": "squareup.roster.service.UserLookupResponse.UkMerchantProfile.VatCustomerClass",
                        "id": 2,
                        "rule": "optional"
                      }
                    },
                    "nested": {
                      "VatCustomerClass": {
                        "values": {
                          "b2b_null": 1,
                          "b2b": 2
                        }
                      }
                    }
                  },
                  "FrMerchantProfile": {
                    "fields": {
                      "siren": {
                        "type": "string",
                        "id": 1,
                        "rule": "optional"
                      },
                      "tva": {
                        "type": "string",
                        "id": 2,
                        "rule": "optional"
                      },
                      "nic": {
                        "type": "string",
                        "id": 3,
                        "rule": "optional"
                      }
                    }
                  },
                  "country_specific_merchant_profile": {
                    "oneof": [
                      "canadianMerchantProfile",
                      "ukMerchantProfile",
                      "frMerchantProfile"
                    ]
                  }
                }
              },
              "EmployeeLookupResponse": {
                "fields": {
                  "status": {
                    "type": "squareup.roster.service.UserLookupStatus",
                    "id": 1,
                    "rule": "optional"
                  },
                  "merchantToken": {
                    "type": "string",
                    "id": 2,
                    "rule": "optional"
                  },
                  "firstName": {
                    "type": "string",
                    "id": 3,
                    "rule": "optional"
                  },
                  "lastName": {
                    "type": "string",
                    "id": 4,
                    "rule": "optional"
                  },
                  "employeeNumber": {
                    "type": "string",
                    "id": 5,
                    "rule": "optional"
                  },
                  "active": {
                    "type": "bool",
                    "id": 6,
                    "rule": "optional"
                  },
                  "token": {
                    "type": "string",
                    "id": 7,
                    "rule": "optional"
                  },
                  "personToken": {
                    "type": "string",
                    "id": 8,
                    "rule": "optional"
                  },
                  "merchantSuppliedEmail": {
                    "type": "string",
                    "id": 9,
                    "rule": "optional"
                  },
                  "phone": {
                    "type": "squareup.common.location.Phone",
                    "id": 10,
                    "rule": "optional"
                  },
                  "credentialEmail": {
                    "type": "string",
                    "id": 11,
                    "rule": "optional"
                  },
                  "hasPasscode": {
                    "type": "bool",
                    "id": 12,
                    "rule": "optional"
                  },
                  "canAccessPayroll": {
                    "type": "bool",
                    "id": 13,
                    "rule": "optional"
                  },
                  "isAccountOwner": {
                    "type": "bool",
                    "id": 14,
                    "rule": "optional"
                  },
                  "isOwner": {
                    "type": "bool",
                    "id": 15,
                    "rule": "optional"
                  },
                  "hourlyWageRate": {
                    "type": "squareup.common.Money",
                    "id": 16,
                    "rule": "optional"
                  },
                  "annualWageRate": {
                    "type": "squareup.common.Money",
                    "id": 17,
                    "rule": "optional"
                  },
                  "wageType": {
                    "type": "squareup.roster.service.EmployeeLookupResponse.WageType",
                    "id": 18,
                    "rule": "optional"
                  },
                  "hoursWorkedPerWeek": {
                    "type": "uint32",
                    "id": 19,
                    "rule": "optional"
                  },
                  "flsaExempt": {
                    "type": "bool",
                    "id": 20,
                    "rule": "optional"
                  }
                },
                "nested": {
                  "WageType": {
                    "values": {
                      "HOURLY": 0,
                      "ANNUAL": 1
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.roster?.nested?.service?.nested?.Subscription && !$root.nested?.squareup?.nested?.roster?.nested?.service?.nested?.Subscription?.nested?.Status && !$root.nested?.squareup?.nested?.roster?.nested?.service?.nested?.MerchantProfileLookupResponse?.nested?.CanadianMerchantProfile && !$root.nested?.squareup?.nested?.roster?.nested?.service?.nested?.PayerLookupResponse && !$root.nested?.squareup?.nested?.roster?.nested?.service?.nested?.PrivateInformation && !$root.nested?.squareup?.nested?.roster?.nested?.service?.nested?.UnderwritingDecision && !$root.nested?.squareup?.nested?.roster?.nested?.service?.nested?.UserLookupStatus && !$root.nested?.squareup?.nested?.roster?.nested?.service?.nested?.UserLookupResponse && !$root.nested?.squareup?.nested?.roster?.nested?.service?.nested?.UserLookupResponse?.nested?.GatewayMerchantToken && !$root.nested?.squareup?.nested?.roster?.nested?.service?.nested?.UserLookupResponse?.nested?.UkMerchantProfile && !$root.nested?.squareup?.nested?.roster?.nested?.service?.nested?.UserLookupResponse?.nested?.UkMerchantProfile?.nested?.VatCustomerClass && !$root.nested?.squareup?.nested?.roster?.nested?.service?.nested?.UserLookupResponse?.nested?.FrMerchantProfile && !$root.nested?.squareup?.nested?.roster?.nested?.service?.nested?.EmployeeLookupResponse && !$root.nested?.squareup?.nested?.roster?.nested?.service?.nested?.EmployeeLookupResponse?.nested?.WageType) {
	$root.addJSON(jsonData);
}

/**
 * IMPORTANT!
 * DO NOT USE squareup.validation on responses! It's non-trivially expensive, and on batch endpoints
 * can cause large amounts of work to be done on large responses. Since we're moving towards
 * batching for all endpoints, we should not be using validation at all on responses.
 * This proto will be deprecated soon in favor of SubscriptionFeatureStatus.
 *
 * SOURCE Subscription @ squareup/roster/service.proto at 30:1
 */
export const Subscription = $root.lookupType('squareup.roster.service.Subscription');
fixType(Subscription);
Builder.createAndAttachToType(Subscription);
/**
 * SOURCE Status @ squareup/roster/service.proto at 31:3
 */
Subscription.Status = $root.lookupEnum('squareup.roster.service.Subscription.Status').values;
/**
 * SOURCE MerchantProfileLookupResponse @ squareup/roster/service.proto at 147:1
 */
export const MerchantProfileLookupResponse = {};
/**
 * SOURCE CanadianMerchantProfile @ squareup/roster/service.proto at 148:3
 */
MerchantProfileLookupResponse.CanadianMerchantProfile = $root.lookupType('squareup.roster.service.MerchantProfileLookupResponse.CanadianMerchantProfile');
fixType(MerchantProfileLookupResponse.CanadianMerchantProfile);
Builder.createAndAttachToType(MerchantProfileLookupResponse.CanadianMerchantProfile);
/**
 * SOURCE PayerLookupResponse @ squareup/roster/service.proto at 336:1
 */
export const PayerLookupResponse = $root.lookupType('squareup.roster.service.PayerLookupResponse');
fixType(PayerLookupResponse);
Builder.createAndAttachToType(PayerLookupResponse);
/**
 * SOURCE PrivateInformation @ squareup/roster/service.proto at 404:1
 */
export const PrivateInformation = $root.lookupType('squareup.roster.service.PrivateInformation');
fixType(PrivateInformation);
Builder.createAndAttachToType(PrivateInformation);
/**
 * SOURCE UnderwritingDecision @ squareup/roster/service.proto at 418:1
 */
export const UnderwritingDecision = $root.lookupType('squareup.roster.service.UnderwritingDecision');
fixType(UnderwritingDecision);
Builder.createAndAttachToType(UnderwritingDecision);
/**
 * SOURCE UserLookupStatus @ squareup/roster/service.proto at 534:1
 */
export const UserLookupStatus = $root.lookupEnum('squareup.roster.service.UserLookupStatus').values;
/**
 * SOURCE UserLookupResponse @ squareup/roster/service.proto at 545:1
 */
export const UserLookupResponse = $root.lookupType('squareup.roster.service.UserLookupResponse');
fixType(UserLookupResponse);
Builder.createAndAttachToType(UserLookupResponse);
/**
 * SOURCE GatewayMerchantToken @ squareup/roster/service.proto at 546:3
 */
UserLookupResponse.GatewayMerchantToken = $root.lookupType('squareup.roster.service.UserLookupResponse.GatewayMerchantToken');
fixType(UserLookupResponse.GatewayMerchantToken);
Builder.createAndAttachToType(UserLookupResponse.GatewayMerchantToken);
/**
 * SOURCE UkMerchantProfile @ squareup/roster/service.proto at 685:3
 */
UserLookupResponse.UkMerchantProfile = $root.lookupType('squareup.roster.service.UserLookupResponse.UkMerchantProfile');
fixType(UserLookupResponse.UkMerchantProfile);
Builder.createAndAttachToType(UserLookupResponse.UkMerchantProfile);
/**
 * SOURCE VatCustomerClass @ squareup/roster/service.proto at 689:5
 */
UserLookupResponse.UkMerchantProfile.VatCustomerClass = $root.lookupEnum('squareup.roster.service.UserLookupResponse.UkMerchantProfile.VatCustomerClass').values;
/**
 * SOURCE FrMerchantProfile @ squareup/roster/service.proto at 697:3
 */
UserLookupResponse.FrMerchantProfile = $root.lookupType('squareup.roster.service.UserLookupResponse.FrMerchantProfile');
fixType(UserLookupResponse.FrMerchantProfile);
Builder.createAndAttachToType(UserLookupResponse.FrMerchantProfile);
/**
 * A set of employees matching EmployeeLookupRequest or EmployeeBatchLookupRequest.
 *
 * SOURCE EmployeeLookupResponse @ squareup/roster/service.proto at 844:1
 */
export const EmployeeLookupResponse = $root.lookupType('squareup.roster.service.EmployeeLookupResponse');
fixType(EmployeeLookupResponse);
Builder.createAndAttachToType(EmployeeLookupResponse);
/**
 * Employee's wage type. WageType.HOURLY if paid hourly, ANNUAL if salaried annually
 *
 * SOURCE WageType @ squareup/roster/service.proto at 897:3
 */
EmployeeLookupResponse.WageType = $root.lookupEnum('squareup.roster.service.EmployeeLookupResponse.WageType').values;
