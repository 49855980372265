import React, { ReactElement, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import './CodeSnippet.scss';
import { MarketContentCard, MarketPopover } from 'src/components/Market';
import CopyIcon from 'src/svgs/CopyIcon';
import { separateTextByQuotes } from './utils';
import { useTranslation } from 'react-i18next';

export type CodeSnippetProps = {
  title: string;
  snippet: string;
  onCopied?: () => void;
};

const COPIED_POPOVER_DURATION = 1000;

/**
 * A block showing a code snippet with a button to copy to clipboard.
 *
 * @example
 * <CodeSnippet
 *   title="My code snippet"
 *   snippet="<div>Hello world</div>"
 *   onCopied={() => pasteItEverywhere()}
 * />
 * @param {string} title
 * The title to render in the header.
 * @param {string} snippet
 * The code snippet to render in the body.
 * @param {Function} [onCopied]
 * (Optional) A callback after copy is clicked.
 * @author klim
 */
const CodeSnippet = observer((props: CodeSnippetProps): ReactElement => {
  const { title, snippet, onCopied } = props;
  const { t } = useTranslation();

  const [showCopiedPopover, setShowCopiedPopover] = useState(false);
  useEffect(() => {
    if (showCopiedPopover) {
      const timeout = setTimeout(
        () => setShowCopiedPopover(false),
        COPIED_POPOVER_DURATION,
      );
      return () => clearTimeout(timeout);
    }
    return undefined;
  }, [showCopiedPopover]);

  return (
    <>
      <MarketContentCard className="CodeSnippet CodeSnippet__header">
        <span>{title}</span>
        <div
          className="CodeSnippet__copy"
          onClick={() =>
            navigator.clipboard.writeText(snippet).then(() => {
              setShowCopiedPopover(true);
              onCopied?.();
            })
          }
          data-testid="CodeSnippet__copy"
        >
          <CopyIcon />
          {showCopiedPopover && (
            <MarketPopover className="CodeSnippet__copy__popover">
              {t('CodeSnippet.copied')}
            </MarketPopover>
          )}
        </div>
      </MarketContentCard>
      <MarketContentCard
        className="CodeSnippet CodeSnippet__body"
        data-testid="CodeSnippet__body"
      >
        <ol>
          {snippet.split('\n').map((line, lineIndex) => {
            const substrings = separateTextByQuotes(line);

            return (
              <li key={lineIndex}>
                {substrings.map((substring, index) => {
                  if (substring.startsWith('"')) {
                    return (
                      <span key={index} className="CodeSnippet__quote">
                        {substring}
                      </span>
                    );
                  } else {
                    return substring;
                  }
                })}
              </li>
            );
          })}
        </ol>
      </MarketContentCard>
    </>
  );
});

export default CodeSnippet;
