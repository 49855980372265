export class MessagesAuthenticationError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'MessagesAuthenticationError';
  }
}

export class MessagesAuthorizationError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'MessagesAuthorizationError';
  }
}

export class MessagesMicrophonePermissionDeniedError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'MessagesMicrophonePermissionDeniedError';
  }
}

export class MessagesMissingMicrophoneError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'MessagesMissingMicrophoneError';
  }
}

export class MessagesMarketingClassifierError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'MessagesMarketingClassifierError';
  }
}

export class MessagesShaftCError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'MessagesShaftCError';
  }
}
