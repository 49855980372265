import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import './MergeCustomersSkeletonState.scss';

/**
 * Number of skeleton state customers to display in the skeleton state.
 * A rough approximation to fill the screen without going over.
 */
const NUM_OF_SKELETON_CUSTOMERS = 4;

/**
 * Renders a skeleton loading state representing the merge customers page.
 */
const MergeCustomersSkeletonState = observer((): ReactElement => {
  return (
    <div
      className="MergeCustomersSkeletonState__container"
      data-testid="MergeCustomersSkeletonState"
    >
      {Array.from({ length: NUM_OF_SKELETON_CUSTOMERS }).map((_, index) => (
        <div
          key={index}
          className="MergeCustomersSkeletonState__item-container"
        >
          <div className="MergeCustomersSkeletonState__image" />
          <div className="MergeCustomersSkeletonState__text-container">
            <div className="MergeCustomersSkeletonState__title" />
            <div className="MergeCustomersSkeletonState__text" />
            <div className="MergeCustomersSkeletonState__text" />
            <div className="MergeCustomersSkeletonState__text" />
          </div>
        </div>
      ))}
    </div>
  );
});

export default MergeCustomersSkeletonState;
