/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "bills": {
        "nested": {
          "ISO8601Date": {
            "fields": {
              "dateString": {
                "type": "string",
                "id": 1,
                "rule": "optional"
              }
            }
          },
          "DatePair": {
            "fields": {
              "clientDate": {
                "type": "squareup.bills.ISO8601Date",
                "id": 1,
                "rule": "optional"
              },
              "serverDate": {
                "type": "squareup.bills.ISO8601Date",
                "id": 2,
                "rule": "optional"
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.bills?.nested?.ISO8601Date && !$root.nested?.squareup?.nested?.bills?.nested?.DatePair) {
	$root.addJSON(jsonData);
}

/**
 * SOURCE ISO8601Date @ squareup/bills/types.proto at 8:1
 */
export const ISO8601Date = $root.lookupType('squareup.bills.ISO8601Date');
fixType(ISO8601Date);
Builder.createAndAttachToType(ISO8601Date);
/**
 * DatePair does not use the square common time representation.
 * This is because the Beemo team requested that they get passed the
 * Raw dates from the client because of how critical the data is to the operation
 * of the reporting system.
 *
 * SOURCE DatePair @ squareup/bills/types.proto at 22:1
 */
export const DatePair = $root.lookupType('squareup.bills.DatePair');
fixType(DatePair);
Builder.createAndAttachToType(DatePair);
