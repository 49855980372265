import { ReactNode } from 'react';
import { t } from 'i18next';
import {
  IUtterance,
  Utterance,
} from 'src/gen/squareup/messenger/v3/messenger_service';
import { HighlightSegment } from 'src/MessengerTypes';
import { getTextWithHighlights } from 'src/pages/TranscriptViewPage/components/Utterance/components/UtteranceText/utils';

const TRUNCATION_PREFIX = '...';

/**
 * Returns adjusted highlight segments relative to the start index provided.
 *
 * @param {number} startIndex
 * The relative start index to adjust the highlight segments to.
 * @param {HighlightSegment[]} highlightSegments
 * Array of tuples representing the start and end of each highlight segment.
 */
const getHighlightSegmentsForPositionedText = (
  startIndex: number,
  highlightSegments: HighlightSegment[],
): HighlightSegment[] =>
  highlightSegments
    .map(
      ([start, end]): HighlightSegment => [
        start - startIndex,
        end - startIndex,
      ],
    )
    .filter(([, end]) => end > 0);

/**
 * Truncates the given plain text such that the preview text includes the first highlight segment and surrounding text.
 * Follows the algorithm defined here --> https://docs.google.com/document/d/1iAh1Ags4FWYNvJ2hekFdoIlRc4fT7EEdjv8KaBU88m0/edit#bookmark=id.a73wrifvoe
 * i.e. "Lorem ipsum dolor sit amet, consectetur adipiscing elit.", [6, 11] --> "Lorem <b>ipsum</b> dolor sit amet, consectetur adipiscing elit."
 *
 * @param {string} plainText
 * The plain text to truncate.
 * @param {HighlightSegment[]} highlightSegments
 * Array of tuples representing the start and end of each highlight segment.
 */
const getSearchResultPreviewTextPositionedAroundHighlight = (
  plainText: string,
  highlightSegments: HighlightSegment[],
): ReactNode[] => {
  const [segmentStartIndex, segmentEndIndex] = highlightSegments[0];
  const MAX_CHARACTERS = 60; // Assumption made on the max characters available to display in the preview
  const highlightLength = segmentEndIndex - segmentStartIndex;
  const numCharsBeforeFirstHighlight = segmentStartIndex;
  const numCharsAfterFirstHighlight = plainText.length - segmentEndIndex;
  const numCharsToShowExcludingHighlight = MAX_CHARACTERS - highlightLength;

  if (highlightLength >= MAX_CHARACTERS) {
    if (numCharsBeforeFirstHighlight === 0) {
      return getTextWithHighlights(plainText, highlightSegments);
    }

    const result = getTextWithHighlights(
      plainText.slice(segmentStartIndex),
      getHighlightSegmentsForPositionedText(
        segmentStartIndex,
        highlightSegments,
      ),
    );
    result.unshift(TRUNCATION_PREFIX);
    return result;
  }

  if (numCharsBeforeFirstHighlight <= numCharsToShowExcludingHighlight) {
    return getTextWithHighlights(plainText, highlightSegments);
  }

  const halfOfNumCharsToShowExcludingHighlight = Math.floor(
    numCharsToShowExcludingHighlight / 2,
  );
  if (numCharsAfterFirstHighlight <= halfOfNumCharsToShowExcludingHighlight) {
    const result = getTextWithHighlights(
      plainText.slice(-MAX_CHARACTERS),
      getHighlightSegmentsForPositionedText(
        Math.max(plainText.length - MAX_CHARACTERS, 0),
        highlightSegments,
      ),
    );
    result.unshift(TRUNCATION_PREFIX);
    return result;
  }

  const startIndex = Math.max(
    0,
    segmentStartIndex - halfOfNumCharsToShowExcludingHighlight,
  );
  const result = getTextWithHighlights(
    plainText.slice(startIndex),
    getHighlightSegmentsForPositionedText(startIndex, highlightSegments),
  );
  result.unshift(TRUNCATION_PREFIX);
  return result;
};

/**
 * Retrieves the preview text for a search result utterance, with the highlight segments wrapped in a span to highlight them.
 * Truncates the search result such that the preview text includes the first highlight segment and surrounding text.
 *
 * @param {IUtterance} utterance
 * The utterance to retrieve the preview text for.
 * @param {HighlightSegment[]} highlightSegments
 * Array of tuples representing the start and end of each highlight segment.
 */
export const getSearchResultPreviewText = (
  utterance: IUtterance,
  highlightSegments: HighlightSegment[],
): ReactNode[] => {
  const { plainText = '', speakerType } = utterance;

  if (plainText && highlightSegments.length > 0) {
    const result = getSearchResultPreviewTextPositionedAroundHighlight(
      plainText,
      highlightSegments,
    );
    if (speakerType === Utterance.SpeakerType.MERCHANT) {
      result.unshift(t('TranscriptsListItem.utterance_preview.you_only'));
    }
    return result;
  }

  return [plainText];
};
