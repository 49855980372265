import { h } from '@stencil/core';

export const checkedIcon = () => (
  <svg
    part="icon"
    class="default-icon checked-icon"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5.88683 7.62327L5.71005 7.4465L5.53327 7.62327L4.12327 9.03327L3.9465 9.21005L4.12327 9.38683L7.12327 12.3868C7.37411 12.6377 7.68955 12.75 8.01005 12.75C8.33627 12.75 8.64735 12.6239 8.89224 12.3913L8.89229 12.3914L8.89683 12.3868L13.8968 7.38683L14.0736 7.21005L13.8968 7.03327L12.4868 5.62327L12.3105 5.44691L12.1337 5.62286L8.00046 9.73691L5.88683 7.62327ZM0.25 9C0.25 4.16807 4.16807 0.25 9 0.25C13.8319 0.25 17.75 4.16807 17.75 9C17.75 13.8319 13.8319 17.75 9 17.75C4.16807 17.75 0.25 13.8319 0.25 9Z" />
  </svg>
);

export const uncheckedIcon = () => (
  <svg
    part="icon"
    class="default-icon unchecked-icon"
    width="18"
    height="18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9 16C12.866 16 16 12.866 16 9C16 5.13401 12.866 2 9 2C5.13401 2 2 5.13401 2 9C2 12.866 5.13401 16 9 16ZM9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18Z"
    />
  </svg>
);

export const currentCircleIcon = () => (
  <svg
    part="icon"
    class="default-icon current-circle-icon"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="9" cy="9" r="7" stroke-width="4" />
  </svg>
);

export const nonCurrentCircleIcon = () => (
  <svg
    part="icon"
    class="default-icon non-current-circle-icon"
    width="8"
    height="8"
    viewBox="0 0 8 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 4a4 4 0 1 0 8 0a4 4 0 1 0 -8 0" />
  </svg>
);
