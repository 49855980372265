import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { MarketButton, MarketHeader } from 'src/components/Market';
import OpenIcon from 'src/svgs/OpenIcon';
import MarketBackIcon from 'src/svgs/MarketBackIcon';
import MarketCloseIcon from 'src/svgs/MarketCloseIcon';
import ComposeIcon from 'src/svgs/ComposeIcon';
import SettingsIcon from 'src/svgs/SettingsIcon';
import { KEY_MESSAGES_PLUS } from 'src/stores/FeatureFlagStore';
import {
  FULL_PAGE_INBOX_URL,
  getInboxTranscriptViewUrlWithOrigin,
} from 'src/utils/url';
import useIsMobile from 'src/hooks/useIsMobile';
import AnnouncementTooltip from 'src/components/AnnouncementTooltip/AnnouncementTooltip';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import './TranscriptsListPageHeader.scss';

export type TranscriptsListPageHeaderProps = {
  isAssistantPage?: boolean;
  title?: string;
};

/**
 * Renders the header for the TranscriptsList page.
 *
 * @param {boolean} [isAssistantPage]
 * Flag indicating if the header is being used on the assistant page.
 * @param {string} [title]
 * Optional title for the page header.
 */
const TranscriptsListPageHeader = observer(
  ({
    isAssistantPage,
    title,
  }: TranscriptsListPageHeaderProps): ReactElement => {
    const { t } = useTranslation();
    const isMobile = useIsMobile();
    const {
      navigation,
      event,
      tooltip,
      newMessageSearch,
      user,
      featureFlag,
      transcriptsList,
      subscription,
    } = useMessengerControllerContext();

    // Back button is only shown for Assistants page (both Blade and Inbox)
    // or on the Blade when using search
    const isBackButtonShown =
      isAssistantPage ||
      (!navigation.isFullPageMessenger &&
        navigation.primary.currentPageName === 'SEARCH');

    // Close button is only shown on Blade, and only when the back button is not shown
    const isCloseButtonShown =
      !isBackButtonShown && !navigation.isFullPageMessenger;

    // Only shown on Blade and desktop only
    const isOpenFullPageInboxButtonShown =
      !isMobile && !navigation.secondary.isOpen;

    let inboxUrl = FULL_PAGE_INBOX_URL;
    if (transcriptsList.size > 0) {
      inboxUrl = getInboxTranscriptViewUrlWithOrigin(transcriptsList.ids[0]);
    }

    return (
      <MarketHeader
        className="MessagesPageHeader"
        showNavigation={isBackButtonShown || isCloseButtonShown || undefined}
      >
        {(isBackButtonShown || isCloseButtonShown) && (
          <MarketButton
            slot="navigation"
            aria-label={
              isBackButtonShown ? t('common.back') : t('common.close')
            }
            onClick={navigation.primary.navigateBack}
          >
            {isBackButtonShown ? (
              <MarketBackIcon slot="icon" />
            ) : (
              <MarketCloseIcon slot="icon" />
            )}
          </MarketButton>
        )}
        <h2
          className="TranscriptsListPageHeader__title"
          data-cy="MessagesPageTitle"
        >
          {title || t('common.messenger')}
          {navigation.isFullPageMessenger &&
            user.unreadTranscriptsCount > 0 && (
              <span className="TranscriptsListPageHeader__unread-count">
                {user.unreadTranscriptsCount}
              </span>
            )}
        </h2>
        {isOpenFullPageInboxButtonShown && (
          <MarketButton
            slot="actions"
            href={inboxUrl}
            target="_blank"
            onClick={() => {
              event.track('Click Open Full Page Inbox');
              tooltip.dismiss('FULL_PAGE_INBOX');
            }}
            data-testid="TranscriptsListPageHeader__open-inbox"
          >
            <OpenIcon slot="icon" />
          </MarketButton>
        )}
        {isOpenFullPageInboxButtonShown &&
          tooltip.isVisible('FULL_PAGE_INBOX') && (
            <div className="TranscriptsListPageHeader__tooltip">
              <AnnouncementTooltip
                text={t('FullPageInboxLaunchBanner.tooltip_text')}
                onDismiss={() => {
                  event.track('Click Full Page Inbox Launch Tooltip Dismiss');
                  tooltip.dismiss('FULL_PAGE_INBOX');
                }}
                onRender={() => {
                  event.track('View Full Page Inbox Launch Tooltip');
                }}
              />
            </div>
          )}
        <div slot="actions">
          <MarketButton
            aria-label={t('SettingsPage.title')}
            onClick={() => navigation.openSheet('SETTINGS')}
          >
            <SettingsIcon slot="icon" />
          </MarketButton>
          {featureFlag.get(KEY_MESSAGES_PLUS) &&
            tooltip.isVisible('VIEW_NUMBER') &&
            subscription.recentlyVerifiedAndSubscribedNumbers.length > 0 && (
              <div className="TranscriptsListPageHeader__view-number-tooltip">
                <AnnouncementTooltip
                  text={t('TranscriptsListPageHeader.view_number')}
                  onDismiss={() => {
                    tooltip.dismiss('VIEW_NUMBER');
                  }}
                />
              </div>
            )}
        </div>
        <MarketButton
          rank="primary"
          slot="actions"
          aria-label={t('NewMessagePage.default_title')}
          onClick={() => {
            if (navigation.secondary.isOpen) {
              newMessageSearch.setQuery('');
              navigation.secondary.navigateTo('NEW_MESSAGE');
            } else {
              navigation.primary.navigateTo('NEW_MESSAGE');
            }
          }}
        >
          <ComposeIcon slot="icon" />
        </MarketButton>
      </MarketHeader>
    );
  },
);

export default TranscriptsListPageHeader;
