import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { MarketToast, MarketToaster } from 'src/components/Market';
import { Status, StatusType } from 'src/MessengerTypes';
import './MessengerToast.scss';

type MessengerToastProps = {
  status: Status | null;
  clearStatus: () => void;
};

type MarketToastVariant = 'critical' | 'warning' | 'success';

const getToastVariant = (statusType: StatusType): MarketToastVariant => {
  switch (statusType) {
    case 'ERROR':
      return 'critical';
    case 'WARNING':
      return 'warning';
    case 'SUCCESS':
    default:
      return 'success';
  }
};

/**
 * Component that renders a global toast alert depending on the status
 * set in the messenger state. This is one of the UI status options that can be chosen.
 * The status banner appears as an overlay in the messenger chat while the toast
 * appears as a toast in the bottom left of the screen.
 *
 * @example <MessengerToast status={messenger.status.value} clearStatus={messenger.clearStatusOverride}/>
 * @param {Status | null} status
 * Current status set for the messenger application
 * @param {() => void} clearStatus
 * Callback that clears the current status set in state
 * @returns {ReactElement | null} Toast component or null
 * @author wdetlor
 */
const MessengerToast = observer(
  ({ status, clearStatus }: MessengerToastProps): ReactElement | null => {
    const { t } = useTranslation();

    if (status?.scope !== 'WINDOW' || status?.display !== 'TOAST') {
      return null;
    }

    return (
      <MarketToaster>
        <MarketToast
          persistent // Always persistent as visibility is controlled via status state
          variant={getToastVariant(status?.type)}
          onMarketToastManuallyDismissed={clearStatus}
          dismissButtonAriaLabel={t('MessengerToast.dismiss')}
        >
          {status?.label}
        </MarketToast>
      </MarketToaster>
    );
  },
);

export default MessengerToast;
