import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { MarketRow } from 'src/components/Market';
import { Unit } from 'src/MessengerTypes';

export type LocationSectionProps = {
  label: string;
  units: Unit[];
  hideLabel?: boolean;
};

/**
 * A section with a label followed by a list of units.
 *
 * @param {string} label
 * The label for the section.
 * @param {Unit[]} units
 * The list of units to render.
 * @param {boolean} [hideLabel]
 * (Optional) If true, hide the label.
 * @author klim
 */
const LocationSection = observer(
  ({ label, units, hideLabel }: LocationSectionProps): ReactElement => {
    return (
      <>
        {!hideLabel && <h5>{label}</h5>}
        {units.map((unit) => (
          <MarketRow key={unit.token} value={unit.token}>
            {unit.name}
          </MarketRow>
        ))}
      </>
    );
  },
);

export default LocationSection;
