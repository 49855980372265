import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { MarketButton, MarketEmptyState } from 'src/components/Market';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import { useTranslation } from 'react-i18next';
import MessengerContent from 'src/components/MessengerContent/MessengerContent';
import { Medium } from 'src/gen/squareup/messenger/v3/messenger_service';
import PhoneBookIcon from 'src/svgs/PhoneBookIcon';
import './AddCustomerPage.scss';
import { AddCustomerMessengerPage, Transition } from 'src/MessengerTypes';

export type AddCustomerPageProps = {
  transitionDirection: Transition;
};

/**
 * A page to display in the tertiary view of inbox for orphaned transcript, to prompt the user to
 * add the customer to directory.
 *
 * @author klim
 */
const AddCustomerPage = observer(
  ({ transitionDirection }: AddCustomerPageProps): ReactElement | null => {
    const { modal, transcripts, navigation, event } =
      useMessengerControllerContext();
    const { t } = useTranslation();

    const nav = navigation.tertiary.isOpen
      ? navigation.tertiary
      : navigation.primary;
    const page = nav.currentPage as AddCustomerMessengerPage;

    if (!page || !page.transcriptId) {
      return null;
    }

    const transcript = transcripts.get(page.transcriptId);

    return (
      <MessengerContent
        transitionDirection={transitionDirection}
        isLoading={false}
        status={null}
      >
        <MarketEmptyState className="AddCustomerPage">
          <PhoneBookIcon />
          <h3 slot="primary-text">{t('AddCustomerPage.title')}</h3>
          <p className="paragraph-30" slot="secondary-text">
            {t('AddCustomerPage.description')}
          </p>
          <MarketButton
            slot="actions"
            onClick={() => {
              event.track('Click New Customer Banner Add to Directory');
              modal.openCreateCustomerSuggestionModal({
                firstName: transcript.displayName.maybeFirstName,
                lastName: transcript.displayName.maybeLastName,
                phoneNumber:
                  transcript.medium === Medium.SMS
                    ? transcript.contactId
                    : undefined,
                email:
                  transcript.medium === Medium.EMAIL
                    ? transcript.contactId
                    : undefined,
              });
            }}
          >
            {t('AddCustomerPage.add')}
          </MarketButton>
        </MarketEmptyState>
      </MessengerContent>
    );
  },
);

export default AddCustomerPage;
