/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../../Fixes';
import Builder from '../../../../Builder';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "connect": {
        "nested": {
          "v2": {
            "nested": {
              "common": {
                "nested": {
                  "Coordinates": {
                    "fields": {
                      "latitude": {
                        "type": "double",
                        "id": 1,
                        "rule": "optional"
                      },
                      "longitude": {
                        "type": "double",
                        "id": 2,
                        "rule": "optional"
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.common?.nested?.Coordinates) {
	$root.addJSON(jsonData);
}

/**
 * --
 * @desc
 * Latitude and longitude coordinates.
 * --
 *
 * SOURCE Coordinates @ squareup/connect/v2/common/coordinates.proto at 17:1
 */
export const Coordinates = $root.lookupType('squareup.connect.v2.common.Coordinates');
fixType(Coordinates);
Builder.createAndAttachToType(Coordinates);
