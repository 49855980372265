/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "bills": {
        "nested": {
          "CardData": {
            "nested": {
              "KeyedCard": {
                "nested": {
                  "Expiry": {
                    "fields": {
                      "month": {
                        "type": "string",
                        "id": 1,
                        "rule": "optional"
                      },
                      "year": {
                        "type": "string",
                        "id": 2,
                        "rule": "optional"
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.bills?.nested?.CardData?.nested?.KeyedCard?.nested?.Expiry) {
	$root.addJSON(jsonData);
}

/**
 * SOURCE CardData @ squareup/bills/card_data.proto at 10:1
 */
export const CardData = {};
/**
 * SOURCE KeyedCard @ squareup/bills/card_data.proto at 11:3
 */
CardData.KeyedCard = {};
/**
 * SOURCE Expiry @ squareup/bills/card_data.proto at 14:5
 */
CardData.KeyedCard.Expiry = $root.lookupType('squareup.bills.CardData.KeyedCard.Expiry');
fixType(CardData.KeyedCard.Expiry);
Builder.createAndAttachToType(CardData.KeyedCard.Expiry);
