import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import { MarketButton, MarketHeader } from 'src/components/Market';
import MessengerContent from 'src/components/MessengerContent/MessengerContent';
import {
  PastAppointmentsPage as PastAppointmentsMessengerPage,
  Transition,
} from 'src/MessengerTypes';
import PastAppointmentsList from 'src/components/PastAppointmentsList/PastAppointmentsList';
import BackIcon from 'src/svgs/MarketBackIcon';

export type PastAppointmentsPageProps = {
  transitionDirection: Transition;
};

/**
 * Page used to display a list of past appointments for a customer.
 *
 * @param {Transition} transitionDirection
 * The direction of the transition animation.
 */
const PastAppointmentsPage = observer(
  ({ transitionDirection }: PastAppointmentsPageProps): ReactElement | null => {
    const { navigation, customers } = useMessengerControllerContext();
    const { t } = useTranslation();

    const nav = navigation.tertiary.isOpen
      ? navigation.tertiary
      : navigation.primary;
    const page = nav.currentPage as PastAppointmentsMessengerPage;

    if (!page || !page.customerToken) {
      return null;
    }

    const { pastAppointments } = customers.get(page.customerToken);

    return (
      <>
        <MarketHeader compact>
          <div slot="navigation">
            <MarketButton
              aria-label={t('common.back')}
              onClick={nav.navigateBack}
            >
              <BackIcon slot="icon" />
            </MarketButton>
          </div>
          <h2>{t('CustomerDetailPage.past_appointments_title')}</h2>
        </MarketHeader>
        <MessengerContent
          transitionDirection={transitionDirection}
          isLoading={pastAppointments.status === 'LOADING'}
          status={
            pastAppointments.status === 'ERROR'
              ? {
                  scope: 'BLADE',
                  display: 'SCREEN',
                  type: 'ERROR',
                  label: t('common.error.try_again'),
                  action: {
                    action: pastAppointments.init,
                  },
                  actionLabel: t('common.retry'),
                }
              : null
          }
        >
          <PastAppointmentsList appointments={pastAppointments} />
        </MessengerContent>
      </>
    );
  },
);

export default PastAppointmentsPage;
