import { use } from 'i18next';
import ICU from 'i18next-icu';
import { initReactI18next } from 'react-i18next';
import { SupportedLocale } from 'src/MessengerTypes';
import en from './translations/en.json';
import es from './translations/es_us/es_us.json';
import enCA from './translations/en_ca/en_ca.json';
import frCA from './translations/fr_ca/fr_ca.json';

// Keep track of whether we've already finished setting up i18n
let i18nInitialized = false;

/**
 * Setup internationalization. See detectionUtils for how a language is
 * chosen.
 *
 * @param {SupportedLocale} locale
 */
export default function setup(locale: SupportedLocale): Promise<void> {
  if (i18nInitialized) {
    // Case: Initialized internationalization in an earlier setup() call.
    // See race in index.tsx
    return Promise.resolve();
  }

  return new Promise((resolve, reject) => {
    // Set up internationalization
    use(initReactI18next)
      .use(ICU)
      .init(
        {
          lng: locale,
          fallbackLng: 'en',
          lowerCaseLng: true,
          resources: {
            es,
            en,
            enCA,
            frCA,
            'en-us': en,
            'es-us': es,
            'en-ca': enCA,
            'fr-ca': frCA,
            // TODO(eblaine): add back in other languages when the translations come from Shuttle
          },
          returnNull: false,
        },
        (err) => {
          if (err == null) {
            i18nInitialized = true;
            resolve();
          } else {
            reject(err);
          }
        },
      );
  });
}
