import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { getVerifyIdentityUrl } from 'src/utils/url';
import { MarketButton } from 'src/components/Market';
import MessengerModalDialog from 'src/components/MessengerModalDialog/MessengerModalDialog';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import './UnverifiedModal.scss';

export type UnverifiedModalProps = {
  variant?: 'MESSAGE_BLOCKED' | 'SUBSCRIPTION_BLOCKED';
};

/**
 * A modal to let the merchant know that they will need to verify
 * their identity before they start using Messages.
 *
 * @param {string} [variant]
 * (Optional) The variant of the modal to display.
 * Accepted values are 'MESSAGE_BLOCKED' or 'SUBSCRIPTION_BLOCKED'.
 * Defaults to 'MESSAGE_BLOCKED'.
 * @example
 * Basic usage:
 * <UnverifiedModal />
 * @author eblaine
 */
const UnverifiedModal = observer(
  ({ variant = 'MESSAGE_BLOCKED' }: UnverifiedModalProps): ReactElement => {
    const { modal, event } = useMessengerControllerContext();
    const { closeModal } = modal;
    const { t } = useTranslation();

    const title =
      variant === 'SUBSCRIPTION_BLOCKED'
        ? t('UnverifiedModal.messagesPlusPricingTitle')
        : t('UnverifiedModal.title');
    const description =
      variant === 'SUBSCRIPTION_BLOCKED'
        ? t('UnverifiedModal.messagesPlusPricingDescription')
        : t('UnverifiedModal.description');

    const primaryButton = (
      <MarketButton
        rank="primary"
        onClick={() => {
          event.track('Click IDV Modal Verify Identity');
          window.open(getVerifyIdentityUrl, '_blank');
        }}
      >
        {t('UnverifiedModal.verifyIdentity')}
      </MarketButton>
    );

    const secondaryButton = (
      <MarketButton
        rank="secondary"
        onClick={() => {
          closeModal();
          event.track('Click IDV Modal Dismiss');
        }}
      >
        {t('UnverifiedModal.dismiss')}
      </MarketButton>
    );

    return (
      <MessengerModalDialog
        title={title}
        primaryButton={primaryButton}
        secondaryButton={secondaryButton}
        close={closeModal}
      >
        <p className="UnverifiedModal__description">{description}</p>
      </MessengerModalDialog>
    );
  },
);

export default UnverifiedModal;
