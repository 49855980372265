import { Component, Host, h, Element, Prop, Watch, Listen, EventEmitter, Event, Method } from '@stencil/core';
import { getNamespacedTagFor } from '../../../utils/namespace';
import { TMarketTableV2Selection, MarketTableV2SelectionChangeEventDetail } from '../market-table-v2/types';
import { isDraggable, TMarketDragEventDetail, Draggable } from '../../../utils/draggable';
import { TMarketReorderableOptions, Reorderable, TMarketReorderEventDetail } from '../../../utils/reorderable';
import { TMarketDragCoords } from '../../../utils/gesture/types';

/**
 * @slot - Default slot for children rows
 * @slot parent - Slot for for the parent row
 */

@Component({
  tag: 'market-table-v2-group',
  styleUrl: 'market-table-v2-group.css',
  shadow: true,
})
export class MarketTableV2Group {
  private parent: HTMLMarketTableV2RowElement;
  private rows: Array<HTMLMarketTableV2RowElement>;
  private groups: Array<HTMLMarketTableV2GroupElement>;
  private children: Array<HTMLMarketTableV2RowElement | HTMLMarketTableV2GroupElement>;
  private drag: Draggable;
  private reorder: Reorderable;

  @Element() el: HTMLMarketTableV2GroupElement;

  /**
   * Whether the group is collapsible.
   */
  @Prop({ reflect: true }) readonly collapsible: boolean = false;

  /**
   * Whether the group is expanded or collapsed, when `collapsible` is `true`.
   */
  @Prop({ reflect: true, mutable: true }) collapsed: boolean = false;

  /**
   * Whether the group is drag & drop enabled.
   */
  @Prop({ reflect: true }) readonly dragEnabled: boolean = false;

  /**
   * Indentation level
   */
  @Prop({ reflect: true }) readonly indent: number = 0;

  /**
   * Whether the group is reorderable or not.
   * Setting to `internal` enables reordering table rows internally
   * while `external` also allows dragging to & from other tables.
   */
  @Prop({ reflect: true }) readonly reorderable: TMarketReorderableOptions;

  /**
   * Whether the group is selected.
   * Relevant if the group has rows with slotted controls.
   */
  @Prop({ mutable: true }) selected: TMarketTableV2Selection = 'false';

  /**
   * @internal
   * Fired when the group selection state changes. Used internally in table components.
   */
  @Event({ bubbles: true, composed: true })
  marketInternalTableV2GroupSelectionChange: EventEmitter<MarketTableV2SelectionChangeEventDetail>;

  /**
   * Fired when the group's rows are reordered.
   * If a row was dropped into this group from an external source, `oldIndex` is `-1`.
   * If a row was removed from this group and dropped into an external source, `newIndex` is `-1`.
   */
  @Event({ bubbles: true, composed: true })
  marketTableV2RowsReordered: EventEmitter<TMarketReorderEventDetail>;

  /**
   * Fired when the group's collapsed state changes.
   */
  @Event({ bubbles: true, composed: true })
  marketTableV2GroupCollapsedChange: EventEmitter<{ previous: boolean; current: boolean }>;

  @Listen('marketTableV2CellCaretClicked')
  onMarketTableV2CellNewCaretClicked(e: CustomEvent<void>) {
    e.stopPropagation();
    const { collapsed, marketTableV2GroupCollapsedChange } = this;
    const newCollapsed = !collapsed;
    this.collapsed = newCollapsed;
    const { defaultPrevented } = marketTableV2GroupCollapsedChange.emit({
      previous: collapsed,
      current: newCollapsed,
    });
    if (defaultPrevented) this.collapsed = collapsed;
  }

  @Listen('marketInternalTableV2RowSelectionChange')
  @Listen('marketInternalTableV2GroupSelectionChange')
  async onMarketTableV2SelectionChange(e: CustomEvent<MarketTableV2SelectionChangeEventDetail>) {
    const { el, parent } = this;
    const { target, detail } = e;
    const { current } = detail;

    // oddly, a component's instance can catch its own event
    // before it bubbles, so prevent an infinite loop!
    if (target === el) return;
    e.stopImmediatePropagation();

    if (target === parent) {
      // if the target is the parent, propagate values downward
      await this.setSelected(current);
    } else {
      // the target is a child, and it's complicated...
      await this.setSelectedFromChildEvent(e);
    }
  }

  // These marketDragHandle listeners are for dragging this entire group,
  // which is triggered by dragging the handle of the parent row.
  // We ONLY want to listen for drag handle events on the parent,
  // so we return early if the target is NOT the parent.

  @Listen('marketDragHandleDragStart')
  async onDragHandleStart(e: CustomEvent<TMarketDragCoords>) {
    e.stopImmediatePropagation();
    const { el, parent } = this;
    const { target, detail: coords } = e;
    if (parent !== target) return;
    const anchor = parent.dragHandlePosition === 'leading' ? 'left' : 'right';
    const drag = new Draggable(el, { anchor });
    this.drag = drag;
    await drag.start(coords);
    parent.classList.add('market-drag-placeholder');
  }

  @Listen('marketDragHandleDragMove')
  onDragHandleMove(e: CustomEvent<TMarketDragCoords>) {
    e.stopImmediatePropagation();
    const { parent, drag } = this;
    const { target, detail: coords } = e;
    if (parent !== target) return;
    drag.move(coords);
  }

  @Listen('marketDragHandleDragEnd')
  async onDragHandleDragEnd(e: CustomEvent<TMarketDragCoords>) {
    e.stopImmediatePropagation();
    const { parent, drag } = this;
    const { target, detail: coords } = e;
    if (parent !== target) return;
    await drag.end(coords);
    parent.classList.remove('market-drag-placeholder');
    drag.destroy();
  }

  // These marketDrag listeners are for dragging WITHIN this group.
  // If the dragged element is the parent row, we return early.

  @Listen('marketDragMove')
  onDragMove(e: CustomEvent<TMarketDragEventDetail>) {
    const { parent, reorder } = this;
    const { draggedEl } = e.detail;
    if (parent === draggedEl) return;
    e.stopImmediatePropagation();
    reorder?.dragMove(e);
  }

  @Listen('marketDragLeave')
  onDragLeave(e: CustomEvent<TMarketDragEventDetail>) {
    const { parent, reorder } = this;
    const { draggedEl } = e.detail;
    if (parent === draggedEl) return;
    e.stopImmediatePropagation();
    reorder?.dragLeave();
  }

  @Listen('marketDragEnd')
  onDragEnd(e: CustomEvent<TMarketDragEventDetail>) {
    const { parent, reorder } = this;
    const { draggedEl } = e.detail;
    if (parent === draggedEl) return;
    reorder?.dragEnd(e);
  }

  @Listen('marketDragDrop')
  onDragDrop(e: CustomEvent<TMarketDragEventDetail>) {
    const { parent, reorder } = this;
    const { draggedEl } = e.detail;
    if (parent === draggedEl) return;
    reorder?.dragDrop(e);
  }

  @Watch('indent')
  @Watch('collapsed')
  @Watch('collapsible')
  propagateNestedState() {
    const { parent, children, groups, rows, indent, collapsible, collapsed } = this;

    groups.forEach((group) => {
      group.collapsible = collapsible;
    });

    if (collapsible) {
      const hasChildren = children.length > 0;
      if (parent) {
        parent.caret = hasChildren ? (collapsed ? 'down' : 'up') : undefined;
        parent.indent = hasChildren ? indent : indent + 1;
      }
      groups.forEach((group) => {
        group.indent = indent + 1;
        group.collapsible = collapsible;
      });
      rows.forEach((row) => {
        // child rows get extra indentation to account for no caret
        row.indent = indent + 2;
      });
    } else {
      if (parent) {
        parent.caret = undefined;
        parent.indent = indent;
      }
      children.forEach((child) => {
        child.indent = indent + 1;
      });
      groups.forEach((group) => {
        group.collapsible = collapsible;
      });
    }
  }

  @Watch('dragEnabled')
  watchDragEnabled() {
    const { parent, children, dragEnabled } = this;
    if (parent) parent.dragEnabled = dragEnabled;
    children?.forEach((child) => {
      child.dragEnabled = dragEnabled;
    });
  }

  @Watch('reorderable')
  watchReorderable() {
    const { el, reorder, reorderable, marketTableV2RowsReordered } = this;

    reorder?.destroy();

    const reorderEnabled = ['internal', 'external'].includes(reorderable);
    if (reorderEnabled) {
      const rowTagName = getNamespacedTagFor('market-table-v2-row');
      const groupTagName = getNamespacedTagFor('market-table-v2-group');

      this.reorder = new Reorderable({
        el,
        accepts: [`${rowTagName}:not([header]):not([footer]):not([slot="parent"])`, groupTagName],
        event: marketTableV2RowsReordered,
      });
    }

    this.syncDragEnabled();
  }

  /**
   * @internal
   * Sets selection on the group and propagates the value
   * downwards to its children rows and upwards to any parent groups or tables.
   */
  @Method()
  async setSelected(selected: TMarketTableV2Selection, { silent = false } = {}) {
    const { parent, children, marketInternalTableV2GroupSelectionChange, selected: prevSelected } = this;

    // return if no values have changed
    if (prevSelected === selected) return Promise.resolve();

    // fire the internal selection event
    if (!silent) {
      marketInternalTableV2GroupSelectionChange.emit({
        current: selected,
        previous: prevSelected,
      });
    }

    // propagate the new values
    this.selected = selected;

    // this direction is top -> down, so don't fire events to avoid infinite loop
    await parent?.setSelected(selected, { silent: true });
    children?.forEach(async (child) => {
      await child.setSelected(selected, { silent: true });
    });

    return Promise.resolve();
  }

  private async setSelectedFromChildEvent(e: CustomEvent<MarketTableV2SelectionChangeEventDetail>) {
    const { parent, children, marketInternalTableV2GroupSelectionChange, selected: prevSelected } = this;
    const { target, detail } = e;
    const { current: childSelected } = detail;

    // get an array of what the children's selected values would be AFTER this event
    const childrenSelected = children.map((child) => {
      // if the target was THIS child, it will be new event value (not .selected)
      if (target === child) return childSelected;
      // otherwise, get the current value directly from this child
      return child.selected;
    });

    // what this group's selected value would be AFTER this event
    const groupSelected = childrenSelected.every((val) => val === 'true')
      ? 'true'
      : childrenSelected.every((val) => val === 'false')
      ? 'false'
      : 'indeterminate';

    // return if no values have changed
    if (prevSelected === groupSelected) return;

    // fire the internal selection event
    marketInternalTableV2GroupSelectionChange.emit({
      current: groupSelected,
      previous: prevSelected,
    });

    // propagate the new values
    this.selected = groupSelected;
    await parent.setSelected(groupSelected, { silent: true });
  }

  private getElements() {
    this.parent = [...this.el.children].find((child) => {
      return child.tagName === getNamespacedTagFor('market-table-v2-row').toUpperCase() && child.slot === 'parent';
    }) as HTMLMarketTableV2RowElement;

    this.rows = [...this.el.children].filter((child) => {
      return child.tagName === getNamespacedTagFor('market-table-v2-row').toUpperCase() && child.slot !== 'parent';
    }) as Array<HTMLMarketTableV2RowElement>;

    this.groups = [...this.el.children].filter((child) => {
      return child.tagName === getNamespacedTagFor('market-table-v2-group').toUpperCase() && child.slot !== 'parent';
    }) as Array<HTMLMarketTableV2GroupElement>;

    this.children = [...this.groups, ...this.rows];
  }

  private getStyles() {
    const { indent } = this;
    return { '--drag-cursor-indent-level': indent.toString() };
  }

  private syncDragEnabled() {
    const { parent, rows, groups, reorderable } = this;

    const reorderEnabled = ['internal', 'external'].includes(reorderable);

    if (parent) parent.dragEnabled = reorderEnabled;

    rows?.forEach((row) => {
      if (!isDraggable(row)) return;
      row.dragEnabled = reorderEnabled;
    });

    groups?.forEach((group) => {
      group.dragEnabled = reorderEnabled;
      group.reorderable = reorderable;
    });
  }

  private onSlotChange() {
    this.getElements();
    this.propagateNestedState();
    this.syncDragEnabled();
  }

  connectedCallback() {
    this.getElements();
    this.propagateNestedState();
    this.syncDragEnabled();
  }

  componentDidRender() {
    this.watchReorderable();
  }

  render() {
    return (
      <Host class="market-table-v2-group" style={this.getStyles()}>
        <slot name="parent" onSlotchange={() => this.onSlotChange()}></slot>
        <div class="children">
          <slot onSlotchange={() => this.onSlotChange()}></slot>
        </div>
      </Host>
    );
  }
}
