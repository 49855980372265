/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';
import '../roster/service';
import '../common/countries';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "bills": {
        "nested": {
          "Merchant": {
            "fields": {
              "merchantToken": {
                "type": "string",
                "id": 1,
                "rule": "optional"
              },
              "readOnlyRosterUser": {
                "type": "squareup.roster.service.UserLookupResponse",
                "id": 2,
                "rule": "optional"
              },
              "readOnlyBusinessName": {
                "type": "string",
                "id": 3,
                "rule": "optional"
              },
              "readOnlyBusinessProfilePhotoUrl": {
                "type": "string",
                "id": 4,
                "rule": "optional"
              },
              "readOnlyDisplayDetails": {
                "type": "squareup.bills.Merchant.DisplayDetails",
                "id": 5,
                "rule": "optional"
              },
              "countryCode": {
                "type": "squareup.common.countries.Country",
                "id": 6,
                "rule": "optional"
              }
            },
            "nested": {
              "DisplayDetails": {
                "fields": {
                  "businessName": {
                    "type": "string",
                    "id": 1,
                    "rule": "optional"
                  },
                  "businessProfilePhotoUrl": {
                    "type": "string",
                    "id": 2,
                    "rule": "optional"
                  },
                  "nickName": {
                    "type": "string",
                    "id": 3,
                    "rule": "optional"
                  },
                  "taxProfile": {
                    "type": "squareup.bills.Merchant.DisplayDetails.TaxProfile",
                    "id": 4,
                    "rule": "optional"
                  }
                },
                "nested": {
                  "TaxProfile": {
                    "fields": {
                      "uk": {
                        "type": "squareup.bills.Merchant.DisplayDetails.TaxProfile.UK",
                        "id": 1
                      },
                      "fr": {
                        "type": "squareup.bills.Merchant.DisplayDetails.TaxProfile.FR",
                        "id": 2
                      }
                    },
                    "nested": {
                      "UK": {
                        "fields": {
                          "vatNumber": {
                            "type": "string",
                            "id": 1,
                            "rule": "optional"
                          }
                        }
                      },
                      "FR": {
                        "fields": {
                          "tvaNumber": {
                            "type": "string",
                            "id": 1,
                            "rule": "optional"
                          }
                        }
                      },
                      "TaxProfile": {
                        "oneof": [
                          "uk",
                          "fr"
                        ]
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.bills?.nested?.Merchant && !$root.nested?.squareup?.nested?.bills?.nested?.Merchant?.nested?.DisplayDetails && !$root.nested?.squareup?.nested?.bills?.nested?.Merchant?.nested?.DisplayDetails?.nested?.TaxProfile && !$root.nested?.squareup?.nested?.bills?.nested?.Merchant?.nested?.DisplayDetails?.nested?.TaxProfile?.nested?.UK && !$root.nested?.squareup?.nested?.bills?.nested?.Merchant?.nested?.DisplayDetails?.nested?.TaxProfile?.nested?.FR) {
	$root.addJSON(jsonData);
}

/**
 * SOURCE Merchant @ squareup/bills/merchant.proto at 11:1
 */
export const Merchant = $root.lookupType('squareup.bills.Merchant');
fixType(Merchant);
Builder.createAndAttachToType(Merchant);
/**
 * SOURCE DisplayDetails @ squareup/bills/merchant.proto at 37:3
 */
Merchant.DisplayDetails = $root.lookupType('squareup.bills.Merchant.DisplayDetails');
fixType(Merchant.DisplayDetails);
Builder.createAndAttachToType(Merchant.DisplayDetails);
/**
 * SOURCE TaxProfile @ squareup/bills/merchant.proto at 50:5
 */
Merchant.DisplayDetails.TaxProfile = $root.lookupType('squareup.bills.Merchant.DisplayDetails.TaxProfile');
fixType(Merchant.DisplayDetails.TaxProfile);
Builder.createAndAttachToType(Merchant.DisplayDetails.TaxProfile);
/**
 * SOURCE UK @ squareup/bills/merchant.proto at 51:7
 */
Merchant.DisplayDetails.TaxProfile.UK = $root.lookupType('squareup.bills.Merchant.DisplayDetails.TaxProfile.UK');
fixType(Merchant.DisplayDetails.TaxProfile.UK);
Builder.createAndAttachToType(Merchant.DisplayDetails.TaxProfile.UK);
/**
 * SOURCE FR @ squareup/bills/merchant.proto at 57:7
 */
Merchant.DisplayDetails.TaxProfile.FR = $root.lookupType('squareup.bills.Merchant.DisplayDetails.TaxProfile.FR');
fixType(Merchant.DisplayDetails.TaxProfile.FR);
Builder.createAndAttachToType(Merchant.DisplayDetails.TaxProfile.FR);
