/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';
import '../roster/service';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "bills": {
        "nested": {
          "MobileStaff": {
            "fields": {
              "userToken": {
                "type": "string",
                "id": 1,
                "rule": "optional"
              },
              "readOnlyRosterUser": {
                "type": "squareup.roster.service.UserLookupResponse",
                "id": 2,
                "rule": "optional"
              },
              "readOnlyName": {
                "type": "string",
                "id": 3,
                "rule": "optional"
              },
              "readOnlyProfilePhotoUrl": {
                "type": "string",
                "id": 4,
                "rule": "optional"
              },
              "readOnlyMobileStaffId": {
                "type": "string",
                "id": 5,
                "rule": "optional"
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.bills?.nested?.MobileStaff) {
	$root.addJSON(jsonData);
}

/**
 * SOURCE MobileStaff @ squareup/bills/mobile_staff.proto at 9:1
 */
export const MobileStaff = $root.lookupType('squareup.bills.MobileStaff');
fixType(MobileStaff);
Builder.createAndAttachToType(MobileStaff);
