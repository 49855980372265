import { Component, Host, h, Element, Prop } from '@stencil/core';

import { checkedIcon, currentCircleIcon, nonCurrentCircleIcon, uncheckedIcon } from './icons';
import { classNames } from '../../../../utils/classnames';
import { getNamespacedTagFor } from '../../../../utils/namespace';

/**
 * @slot label - Primary text for the step
 * @slot subtext - Secondary text for the step
 * @slot error - Error text for the step, displayed below the label and subtext
 * @part icon - The `<market-accessory>` that contains the icon
 * @part bar - The bar that is rendered after the icon
 * @part content - The container for the text content
 */
@Component({
  tag: 'market-progress-tracker-step',
  styleUrl: 'market-progress-tracker-step.css',
  shadow: true,
})
export class MarketProgressTrackerStep {
  @Element() el: HTMLMarketProgressTrackerStepElement;

  /**
   * String for setting the step's size
   *
   * @default 'medium'
   */
  @Prop({ reflect: true }) readonly size: 'large' | 'medium' | 'small' = 'medium';

  /**
   * **DEPRECATED (v4.14.0)** Use `indicator` instead.
   * @deprecated
   *
   * String for setting the icons used to indicate progress
   */
  @Prop() readonly variant: 'circle' | 'check';

  /**
   * String for setting the type of icons used to indicate progress
   *
   * This will be disregarded when `[slot="icon"]` is provided.
   *
   * @default 'circle'
   */
  @Prop() readonly indicator: 'circle' | 'check' = 'circle';

  /**
   * Whether or not this step is completed
   *
   * @default false
   */
  @Prop({ reflect: true }) readonly completed: boolean = false;

  /**
   * Whether or not this step is the active step
   *
   * @default false
   */
  @Prop({ reflect: true }) readonly active: boolean = false;

  /**
   * @internal
   * Type of bar shown after the indicator
   *
   * @default 'hidden'
   */
  @Prop() readonly bar: 'active' | 'inactive' | 'hidden' = 'hidden';

  render() {
    const { active, bar, completed, el, indicator: _indicator, variant } = this;
    const MarketAccessoryTagName = getNamespacedTagFor('market-accessory');
    const indicator = variant ?? _indicator;
    const hasError = el.querySelector('[slot="error"]');

    return (
      <Host class="market-progress-tracker-step" has-error={hasError ? true : null}>
        <li>
          <div class={classNames('indicator', { active, completed })}>
            <MarketAccessoryTagName size="icon" part="icon">
              <slot name="icon">
                {completed && indicator === 'check' && checkedIcon()}
                {!completed && indicator === 'check' && uncheckedIcon()}
                {active && indicator === 'circle' && currentCircleIcon()}
                {!active && indicator === 'circle' && nonCurrentCircleIcon()}
              </slot>
            </MarketAccessoryTagName>
            <div class={bar} part="bar"></div>
          </div>
          <div part="content">
            <slot name="label"></slot>
            <slot name="subtext"></slot>
            <slot name="error"></slot>
            <slot></slot>
          </div>
        </li>
      </Host>
    );
  }
}
