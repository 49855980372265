/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../Fixes';
import Builder from '../../../Builder';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "appointments": {
        "nested": {
          "api": {
            "nested": {
              "OpenHours": {
                "fields": {
                  "startDow0": {
                    "type": "int64",
                    "id": 1,
                    "rule": "optional"
                  },
                  "startDow1": {
                    "type": "int64",
                    "id": 2,
                    "rule": "optional"
                  },
                  "startDow2": {
                    "type": "int64",
                    "id": 3,
                    "rule": "optional"
                  },
                  "startDow3": {
                    "type": "int64",
                    "id": 4,
                    "rule": "optional"
                  },
                  "startDow4": {
                    "type": "int64",
                    "id": 5,
                    "rule": "optional"
                  },
                  "startDow5": {
                    "type": "int64",
                    "id": 6,
                    "rule": "optional"
                  },
                  "startDow6": {
                    "type": "int64",
                    "id": 7,
                    "rule": "optional"
                  },
                  "endDow0": {
                    "type": "int64",
                    "id": 8,
                    "rule": "optional"
                  },
                  "endDow1": {
                    "type": "int64",
                    "id": 9,
                    "rule": "optional"
                  },
                  "endDow2": {
                    "type": "int64",
                    "id": 10,
                    "rule": "optional"
                  },
                  "endDow3": {
                    "type": "int64",
                    "id": 11,
                    "rule": "optional"
                  },
                  "endDow4": {
                    "type": "int64",
                    "id": 12,
                    "rule": "optional"
                  },
                  "endDow5": {
                    "type": "int64",
                    "id": 13,
                    "rule": "optional"
                  },
                  "endDow6": {
                    "type": "int64",
                    "id": 14,
                    "rule": "optional"
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.appointments?.nested?.api?.nested?.OpenHours) {
	$root.addJSON(jsonData);
}

/**
 * Appointments hour format - represents when a business/staff is open.
 * Values are offsets in seconds from midnight.
 * Values > 86400 represent the next day, to allow for an hour that spans midnight.
 *
 * SOURCE OpenHours @ squareup/appointments/api/model.proto at 409:1
 */
export const OpenHours = $root.lookupType('squareup.appointments.api.OpenHours');
fixType(OpenHours);
Builder.createAndAttachToType(OpenHours);
