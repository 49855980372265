import React, { ReactElement, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Trans, useTranslation } from 'react-i18next';
import MessengerModalPartial from 'src/components/MessengerModalPartial/MessengerModalPartial';
import { GoogleMapsPlaceDetails } from 'src/MessengerTypes';
import './ReviewSetupModal.scss';
import { getGoogleReviewUrl, GOOGLE_BUSINESS_URL } from 'src/utils/url';
import {
  MarketButton,
  MarketContentCard,
  MarketField,
  MarketInputText,
  MarketList,
  MarketRow,
  MarketTextarea,
} from 'src/components/Market';
import {
  MarketInputValueChangeEvent,
  MarketTextareaValueChangeEvent,
} from 'src/MarketTypes';
import { useDebouncedCallback } from 'use-debounce';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import Logger from 'src/Logger';
import BetaPill from 'src/components/BetaPill/BetaPill';

// The delay (in ms) before we call Google Maps API to search for location.
const INPUT_TYPE_DELAY = 500;

/**
 * A modal to set up Google reviews at a unit level.
 */
const ReviewSetupModal = observer((): ReactElement => {
  const { user, modal, event, status, settings, api } =
    useMessengerControllerContext();
  const { closeModal: close, reviewSetupUnitSetting } = modal;
  const { track } = event;
  const { value: statusValue, set: setStatus, clear: clearStatus } = status;
  const { getPlacePredictions, getPlaceDetails } = api.google;
  const { t } = useTranslation();

  if (!reviewSetupUnitSetting) {
    Logger.logWithSentry(
      'Cannot render <ReviewSetupModal /> without modal.reviewSetupUnitSetting.',
      'error',
      { modal },
    );
    return <></>;
  }

  const {
    unitToken,
    placeId: savedPlaceId,
    reviewMessage: savedReviewMessage,
    url: savedUrl,
    address,
  } = reviewSetupUnitSetting;
  const [placeId, setPlaceId] = useState(savedPlaceId);
  const [businessSearch, setBusinessSearch] = useState('');
  const [placePredictions, setPlacePredictions] = useState<
    GoogleMapsPlaceDetails[]
  >([]);
  const defaultReviewMessage = t('ReviewSetupModal.default_message', {
    businessName: user.businessName,
  });
  const [reviewMessage, setReviewMessage] = useState<string>(
    savedReviewMessage || defaultReviewMessage,
  );
  const [url, setUrl] = useState(savedUrl);
  const [invalidSearch, setInvalidSearch] = useState(false);

  const unitDetails = user.units.get(unitToken);
  const unitName = unitDetails?.name ?? '';
  const unitAddress = unitDetails?.address ?? '';

  // On mount, search Google Maps API for matching location
  useEffect(() => {
    if (savedPlaceId) {
      // There is already a linked business, get the details
      if (address) {
        setBusinessSearch(address);
        return;
      }

      getPlaceDetails(savedPlaceId)
        .then((details) => {
          setBusinessSearch(`${details.name}, ${details.address}`);
        })
        .catch(() => {
          // savedPlaceId is invalid
          setPlaceId('');
        });
    } else {
      // No linked business, try to predict based on Square address
      getPlacePredictions(unitAddress)
        .then((predictions) => {
          setPlacePredictions(predictions);
          if (predictions.length === 0) {
            track('View Review Setup Modal Empty Business Name', {
              unitToken,
              unitAddress,
            });
          }
        })
        .catch(() => {
          setInvalidSearch(true);
        });
    }
    // TODO (#5429): re-enable eslint rule in the next line, or remove this TODO
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Get shorten url when placeId changes
  useEffect(() => {
    if (placeId === '') {
      setUrl('');
    } else if (url === '') {
      settings
        .getReviewShortUrlFromPlaceId(placeId)
        .then((newUrl) => setUrl(newUrl));
    }
    // TODO (#5429): re-enable eslint rule in the next line, or remove this TODO
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placeId]);

  // Set up header buttons
  const primaryButton = (
    <MarketButton
      rank="primary"
      onClick={() => {
        // Disallow 'Done' if no Google location is selected
        if (placeId === '') {
          setStatus({
            label: t('ReviewSetupModal.no_location_error'),
            type: 'ERROR',
            display: 'BANNER',
            scope: 'MODAL',
            action: {
              action: async () => {
                await clearStatus();
              },
            },
          });
          return;
        }

        const reviewMessageToSave =
          reviewMessage !== defaultReviewMessage ? reviewMessage : '';
        if (reviewMessageToSave !== '') {
          track('Edit Review Setup Modal Default Message');
        }
        track('Click Review Setup Modal Done', {
          unit_token: unitToken,
          place_id: placeId,
          custom_message: reviewMessageToSave,
        });
        settings.setUserUnitSetting({
          ...reviewSetupUnitSetting,
          unitToken,
          placeId,
          reviewMessage: reviewMessageToSave,
          url,
          address: businessSearch || undefined,
        });
        close();
      }}
    >
      {t('ReviewSetupModal.done')}
    </MarketButton>
  );

  const secondaryButton = (
    <MarketButton
      rank="secondary"
      onClick={() => {
        track('Click Review Setup Modal Unlink');
        settings.setUserUnitSetting({
          ...reviewSetupUnitSetting,
          unitToken,
          placeId: '',
          reviewMessage: savedReviewMessage ?? '',
          url: '',
          address: undefined,
        });
        close();
      }}
    >
      {t('ReviewSetupModal.unlink')}
    </MarketButton>
  );

  // Input to enter business name and searches for a google location
  // after a typing delay (to prevent firing the endpoint too often)
  const textDebounceOnChange = useDebouncedCallback((text) => {
    if (text !== '') {
      getPlacePredictions(text).then((predictions) => {
        setPlacePredictions(predictions);
        if (predictions.length === 0) {
          setInvalidSearch(true);
          track('View Review Setup Modal No Business Predictions');
        }
      });
    }
  }, INPUT_TYPE_DELAY);
  useEffect(() => {
    // Cancel the debounce on component unmount
    return textDebounceOnChange.cancel;
    // TODO (#5429): re-enable eslint rule in the next line, or remove this TODO
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const businessNameInput = (
    <MarketField
      className="market-grid-item-full"
      invalid={invalidSearch || undefined}
    >
      <MarketInputText
        value={businessSearch}
        onMarketInputValueChange={(event: MarketInputValueChangeEvent) => {
          const text = event.detail.value; // eslint-disable-line unicorn/consistent-destructuring
          setPlaceId('');
          setBusinessSearch(text);
          setInvalidSearch(false);
          textDebounceOnChange(text);
        }}
        data-testid="ReviewSetupModal__business-name"
      >
        <label>{t('ReviewSetupModal.business_name')}</label>
        {businessSearch !== '' && (
          <MarketButton
            slot="trailing-accessory"
            onClick={() => {
              setPlaceId('');
              setBusinessSearch('');
              setInvalidSearch(false);
              setPlacePredictions([]);
              track('Click Review Setup Modal Clear');
            }}
          >
            {t('ReviewSetupModal.clear')}
          </MarketButton>
        )}
      </MarketInputText>
      {invalidSearch && (
        <small slot="error" className="ReviewSetupModal__no-business-error">
          <Trans
            i18nKey="ReviewSetupModal.invalid_search"
            components={{
              1: (
                <a
                  href={GOOGLE_BUSINESS_URL}
                  onClick={() =>
                    track(
                      'Click Review Setup Modal Update Your Google Business Profile Link',
                    )
                  }
                  target="_blank"
                  rel="noreferrer"
                />
              ),
            }}
          />
        </small>
      )}
    </MarketField>
  );

  // Show predictions list if present and if we don't already have
  // a place id
  let predictionList;
  if (placePredictions.length > 0 && placeId === '') {
    predictionList = (
      <div className="market-grid-item-full">
        <h5>{t('ReviewSetupModal.prediction_title', { unitName })}</h5>
        <MarketList interactive>
          {placePredictions.map((prediction) => (
            <MarketRow
              key={prediction.placeId}
              onClick={() => {
                setPlaceId(prediction.placeId);
                clearStatus();
                setPlacePredictions([]);
                track('Click Review Setup Modal Business Prediction');
                getPlaceDetails(prediction.placeId).then((details) => {
                  setBusinessSearch(`${details.name}, ${details.address}`);
                });
              }}
            >
              <label slot="label">{prediction.name}</label>
              <p slot="subtext">{prediction.address}</p>
            </MarketRow>
          ))}
        </MarketList>
      </div>
    );
  }

  // If there is a place id, we allow the default message to be customized
  // and also show a message preview with a clickable link to the review url
  let defaultMessage;
  if (placeId !== '') {
    defaultMessage = (
      <>
        <MarketTextarea
          className="market-grid-item-full"
          value={reviewMessage}
          onMarketTextareaValueChange={(
            event: MarketTextareaValueChangeEvent,
          ) => setReviewMessage(event.detail.value)} // eslint-disable-line unicorn/consistent-destructuring
          data-testid="ReviewSetupModal__default-message"
        >
          <label>{t('ReviewSetupModal.message')}</label>
        </MarketTextarea>
        <MarketContentCard
          className="ReviewSetupModal__preview market-grid-item-full"
          data-testid="ReviewSetupModal__preview"
        >
          <label className="semibold-20">{t('ReviewSetupModal.preview')}</label>
          <span className="paragraph-30">
            {`${reviewMessage} `}
            <a
              href={url || getGoogleReviewUrl(placeId)}
              target="_blank"
              onClick={() => track('Click Review Setup Modal Preview Link')}
              rel="noreferrer"
            >
              {url || getGoogleReviewUrl(placeId)}
            </a>
          </span>
        </MarketContentCard>
      </>
    );
  }

  const title = (
    <>
      {t('ReviewSetupModal.title')}
      <BetaPill />
    </>
  );
  return (
    <MessengerModalPartial
      title={title}
      close={() => {
        close();
        track('Click Review Setup Modal Dismiss');
      }}
      primaryButton={primaryButton}
      secondaryButton={savedPlaceId && savedPlaceId !== '' && secondaryButton}
      status={statusValue}
    >
      <p className="paragraph-30">{t('ReviewSetupModal.description')}</p>
      <form
        className="market-grid-container"
        onSubmit={(event) => {
          event.preventDefault();
        }}
      >
        {businessNameInput}
        {predictionList}
        {defaultMessage}
      </form>
    </MessengerModalPartial>
  );
});

export default ReviewSetupModal;
