import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Logger from 'src/Logger';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import Transcript from 'src/stores/objects/Transcript';
import { TranscriptMessengerPage } from 'src/MessengerTypes';

const useTranscriptErrorHandling = (): void => {
  const { t } = useTranslation();
  const { navigation, transcripts, status } = useMessengerControllerContext();
  const page = navigation.navStoreForUrl.currentPage as TranscriptMessengerPage;

  let transcript: Transcript | undefined;
  if (page.transcriptId) {
    transcript = transcripts.get(page.transcriptId);
  }

  useEffect(() => {
    // Display an error if either the selectedTranscriptId is missing or if the transcript load was never initiated.
    // Should not be possible to enter this state but adding this as a fallback
    if (!page.transcriptId || transcript?.status === 'NOT_STARTED') {
      Logger.logWithSentry(
        'TranscriptViewPage - Invalid setup for the transcript view page.',
        'error',
        {
          transcriptId: page.transcriptId,
        },
      );
      status.set({
        display: 'SCREEN',
        label: t('TranscriptViewPage.error_loading_first'),
        type: 'ERROR',
        scope: 'BLADE',
      });
      // Clean up function to clear status if we navigate away from the transcript view
      return () => {
        status.clear();
      };
    }

    // Display an error if there was an issue loading the transcript
    if (transcript?.status === 'ERROR') {
      status.set({
        action: { action: transcript.load },
        actionLabel: t('StatusScreen.retry'),
        display: 'SCREEN',
        label: t('TranscriptViewPage.error_loading_first'),
        type: 'ERROR',
        scope: 'BLADE',
      });
      // Clean up function to clear status if we navigate away from the transcript view
      return () => {
        status.clear();
      };
    }

    return undefined;
    // TODO (#5429): re-enable eslint rule in the next line, or remove this TODO
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page.transcriptId, transcript?.status]);
};

export default useTranscriptErrorHandling;
