/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../Fixes';
import Builder from '../../../Builder';
import '../../appointments/api/public';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "omg": {
        "nested": {
          "appts": {
            "nested": {
              "ApptsOrderExtension": {
                "fields": {
                  "booking": {
                    "type": "squareup.connect.v2.bookings.service.Booking",
                    "id": 1,
                    "rule": "optional"
                  },
                  "classBooking": {
                    "type": "squareup.omg.appts.ApptsOrderExtension.ClassBooking",
                    "id": 2,
                    "rule": "optional"
                  },
                  "writeOptions": {
                    "type": "squareup.omg.appts.ApptsOrderExtension.WriteOptions",
                    "id": 3,
                    "rule": "optional"
                  }
                },
                "nested": {
                  "ClassBookingStatus": {
                    "values": {
                      "CLASS_BOOKING_STATUS_UNSPECIFIED": 0,
                      "CLASS_BOOKING_PENDING": 1,
                      "CLASS_BOOKING_ACCEPTED": 2,
                      "CLASS_BOOKING_CANCELED_BY_BUYER": 3,
                      "CLASS_BOOKING_CANCELED_BY_SELLER": 4,
                      "CLASS_BOOKING_NO_SHOW": 5
                    }
                  },
                  "ClassBooking": {
                    "fields": {
                      "creatorDetails": {
                        "type": "squareup.connect.v2.bookings.service.Booking.CreatorDetails",
                        "id": 1,
                        "rule": "optional"
                      },
                      "status": {
                        "type": "squareup.omg.appts.ApptsOrderExtension.ClassBookingStatus",
                        "id": 2,
                        "rule": "optional"
                      }
                    }
                  },
                  "SendMessagePreference": {
                    "values": {
                      "DEFAULT": 0,
                      "FORCE_SEND": 1,
                      "FORCE_DO_NOT_SEND": 2
                    }
                  },
                  "MessagingOptions": {
                    "fields": {
                      "suppressMerchantMessages": {
                        "type": "bool",
                        "id": 1,
                        "rule": "optional"
                      },
                      "sendCustomClientMessageBodyToClient": {
                        "type": "bool",
                        "id": 2,
                        "rule": "optional"
                      },
                      "customClientMessageBody": {
                        "type": "string",
                        "id": 3,
                        "rule": "optional"
                      },
                      "sendSms": {
                        "type": "squareup.omg.appts.ApptsOrderExtension.SendMessagePreference",
                        "id": 4,
                        "rule": "optional"
                      },
                      "sendEmail": {
                        "type": "squareup.omg.appts.ApptsOrderExtension.SendMessagePreference",
                        "id": 5,
                        "rule": "optional"
                      }
                    }
                  },
                  "WriteOptions": {
                    "fields": {
                      "messagingOptions": {
                        "type": "squareup.omg.appts.ApptsOrderExtension.MessagingOptions",
                        "id": 1,
                        "rule": "optional"
                      },
                      "actorEmployeeToken": {
                        "type": "string",
                        "id": 2,
                        "rule": "optional"
                      },
                      "remoteIp": {
                        "type": "string",
                        "id": 3,
                        "rule": "optional"
                      },
                      "userAgent": {
                        "type": "string",
                        "id": 4,
                        "rule": "optional"
                      },
                      "originalDateStart": {
                        "type": "string",
                        "id": 5,
                        "rule": "optional"
                      }
                    }
                  }
                }
              },
              "ApptsLineItemExtension": {
                "fields": {
                  "appointmentSegment": {
                    "type": "squareup.connect.v2.bookings.service.AppointmentSegment",
                    "id": 1,
                    "rule": "optional"
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.omg?.nested?.appts?.nested?.ApptsOrderExtension && !$root.nested?.squareup?.nested?.omg?.nested?.appts?.nested?.ApptsOrderExtension?.nested?.ClassBookingStatus && !$root.nested?.squareup?.nested?.omg?.nested?.appts?.nested?.ApptsOrderExtension?.nested?.ClassBooking && !$root.nested?.squareup?.nested?.omg?.nested?.appts?.nested?.ApptsOrderExtension?.nested?.SendMessagePreference && !$root.nested?.squareup?.nested?.omg?.nested?.appts?.nested?.ApptsOrderExtension?.nested?.MessagingOptions && !$root.nested?.squareup?.nested?.omg?.nested?.appts?.nested?.ApptsOrderExtension?.nested?.WriteOptions && !$root.nested?.squareup?.nested?.omg?.nested?.appts?.nested?.ApptsLineItemExtension) {
	$root.addJSON(jsonData);
}

/**
 * Extensions for APPTS-specific features
 *
 * SOURCE ApptsOrderExtension @ squareup/omg/appts/extensions.proto at 16:1
 */
export const ApptsOrderExtension = $root.lookupType('squareup.omg.appts.ApptsOrderExtension');
fixType(ApptsOrderExtension);
Builder.createAndAttachToType(ApptsOrderExtension);
/**
 * SOURCE ClassBookingStatus @ squareup/omg/appts/extensions.proto at 20:3
 */
ApptsOrderExtension.ClassBookingStatus = $root.lookupEnum('squareup.omg.appts.ApptsOrderExtension.ClassBookingStatus').values;
/**
 * SOURCE ClassBooking @ squareup/omg/appts/extensions.proto at 40:3
 */
ApptsOrderExtension.ClassBooking = $root.lookupType('squareup.omg.appts.ApptsOrderExtension.ClassBooking');
fixType(ApptsOrderExtension.ClassBooking);
Builder.createAndAttachToType(ApptsOrderExtension.ClassBooking);
/**
 * SOURCE SendMessagePreference @ squareup/omg/appts/extensions.proto at 49:3
 */
ApptsOrderExtension.SendMessagePreference = $root.lookupEnum('squareup.omg.appts.ApptsOrderExtension.SendMessagePreference').values;
/**
 * SOURCE MessagingOptions @ squareup/omg/appts/extensions.proto at 55:3
 */
ApptsOrderExtension.MessagingOptions = $root.lookupType('squareup.omg.appts.ApptsOrderExtension.MessagingOptions');
fixType(ApptsOrderExtension.MessagingOptions);
Builder.createAndAttachToType(ApptsOrderExtension.MessagingOptions);
/**
 * SOURCE WriteOptions @ squareup/omg/appts/extensions.proto at 63:3
 */
ApptsOrderExtension.WriteOptions = $root.lookupType('squareup.omg.appts.ApptsOrderExtension.WriteOptions');
fixType(ApptsOrderExtension.WriteOptions);
Builder.createAndAttachToType(ApptsOrderExtension.WriteOptions);
/**
 * Extensions for APPTS-specific features
 *
 * SOURCE ApptsLineItemExtension @ squareup/omg/appts/extensions.proto at 87:1
 */
export const ApptsLineItemExtension = $root.lookupType('squareup.omg.appts.ApptsLineItemExtension');
fixType(ApptsLineItemExtension);
Builder.createAndAttachToType(ApptsLineItemExtension);
