import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import './TranscriptViewSkeletonState.scss';

export type TranscriptViewSkeletonStateProps = {
  showHeader?: boolean;
};

/**
 * Skeleton state for the transcript view to show while the list of utterances is loading.
 *
 * @example <TranscriptViewSkeletonState />
 * @param {boolean} [showHeader]
 * Shows the skeleton state for the header when true.
 * @returns {ReactElement}
 */
const TranscriptViewSkeletonState = observer(
  ({ showHeader }: TranscriptViewSkeletonStateProps): ReactElement => (
    <div>
      {showHeader && (
        <div className="TranscriptViewSkeletonState__header"></div>
      )}
      <div
        className="TranscriptViewSkeletonState__container"
        data-testid="TranscriptViewSkeletonState"
      >
        <div className="TranscriptViewSkeletonState__timestamp"></div>
        <div className="TranscriptViewSkeletonState__merchant TranscriptViewSkeletonState__small" />
        <div className="TranscriptViewSkeletonState__timestamp"></div>
        <div className="TranscriptViewSkeletonState__customer TranscriptViewSkeletonState__large">
          <div className="TranscriptViewSkeletonState__customer-image" />
          <div className="TranscriptViewSkeletonState__customer-text" />
        </div>
        <div className="TranscriptViewSkeletonState__merchant TranscriptViewSkeletonState__x-large"></div>
        <div className="TranscriptViewSkeletonState__timestamp"></div>
        <div className="TranscriptViewSkeletonState__customer TranscriptViewSkeletonState__medium">
          <div className="TranscriptViewSkeletonState__customer-image" />
          <div className="TranscriptViewSkeletonState__customer-text" />
        </div>
        <div className="TranscriptViewSkeletonState__merchant TranscriptViewSkeletonState__x-small"></div>
      </div>
    </div>
  ),
);

export default TranscriptViewSkeletonState;
