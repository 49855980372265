import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { IAttachment } from 'src/gen/squareup/messenger/v3/messenger_service';
import PdfFileIcon from 'src/svgs/PdfFileIcon';
import ImageFileIcon from 'src/svgs/ImageFileIcon';
import GenericFileIcon from 'src/svgs/GenericFileIcon';
import EventCard from 'src/pages/TranscriptViewPage/components/EventCard/EventCard';
import { useTranslation } from 'react-i18next';
import { bytesToSizeLabel } from 'src/utils/fileUtils';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';

export type FileEventCardProps = {
  file: IAttachment;
  hasError?: boolean;
  utteranceId?: number;
};

/**
 * Card rendered within an utterance to represent a file attached to it.
 *
 * @param {IAttachment} file
 * File attachment to render as card.
 * @param {boolean} [hasError]
 * If true, show a red border around the file card.
 * @param {number} [utteranceId]
 * The id of utterance that the file is uploaded to. Used for tracking.
 * @author klim
 */
const FileEventCard = observer((props: FileEventCardProps): ReactElement => {
  const { file, hasError, utteranceId } = props;
  const { event, transcriptView } = useMessengerControllerContext();
  const { t } = useTranslation();

  let icon;
  switch (file.mimeType) {
    case 'application/pdf':
      icon = <PdfFileIcon />;
      break;
    case 'image/png':
    case 'image/jpeg':
    case 'image/tiff':
    case 'image/gif':
      icon = <ImageFileIcon />;
      break;
    default:
      icon = <GenericFileIcon />;
  }

  // If the attachment is staged, we need to append the s3 auth token to acess the url.
  // If token is missing, then it is likely a local url.
  const url = file.token ? `${file.url}?token=${file.token}` : file.url;

  return (
    <EventCard
      icon={icon}
      title={file.name ?? ''}
      subtitle={bytesToSizeLabel(file.sizeBytes ?? 0)}
      link={url}
      linkText={t('common.download')}
      hasError={hasError}
      track={() => {
        event.track('Click Download Attachment', {
          utterance_id: utteranceId,
          transcript_id: transcriptView.transcript.id,
          attachment_name: file.name,
        });
      }}
    />
  );
});

export default FileEventCard;
