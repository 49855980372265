import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { t } from 'i18next';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import GeneralEventBannerRow from 'src/pages/TranscriptViewPage/components/GeneralEventBanner/GeneralEventBannerRow';
import { Appointment, TranscriptViewItem } from 'src/MessengerTypes';
import { useDate, useTime } from 'src/utils/timeUtils';
import {
  getEditAppointmentUrl,
  getEditRecurringAppointmentUrl,
} from 'src/utils/url';
import AppointmentIcon from 'src/svgs/AppointmentIcon';

export type AppointmentEventBannerProps = {
  item: TranscriptViewItem;
};

/**
 * The UI for upcoming appointment reminders, displayed in a banner above the text of the conversation.
 *
 * @param {TranscriptViewItem} item
 * The view item associated with the upcoming appointment reminder.
 */
const AppointmentEventBanner = observer(
  ({ item }: AppointmentEventBannerProps): ReactElement => {
    const { event } = useMessengerControllerContext();
    const { timestampMillis, data } = item;
    const appointment = data as Appointment;

    let subtitle = '';
    let link: string;

    const title = t('common.time.date_at_time', {
      date: useDate(timestampMillis),
      time: useTime(timestampMillis),
    });

    const itemNames = appointment.itemNames?.join(', ') ?? '';
    const staffNames = appointment.staffNames?.join(', ') ?? '';
    if (itemNames !== '' && staffNames !== '') {
      subtitle = `${itemNames}・${staffNames}`;
    } else {
      subtitle = itemNames || staffNames;
    }

    if (appointment.reservationId) {
      link = appointment.isRecurring
        ? getEditRecurringAppointmentUrl(
            appointment.reservationId,
            `${appointment.dateStart}`,
          )
        : getEditAppointmentUrl(appointment.reservationId);
    }

    return (
      <GeneralEventBannerRow
        icon={<AppointmentIcon />}
        title={title}
        subtitle={subtitle}
        onClick={() => {
          event.track('Click View Appointment Next');
          window.open(link, '_blank');
        }}
        variant="APPOINTMENT_EVENT"
      />
    );
  },
);

export default AppointmentEventBanner;
