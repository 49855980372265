/**
 * An icon for request payment smart action.
 */
import React, { ReactElement } from 'react';

function RequestPaymentIcon(): ReactElement {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Request payment</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.77778 0C1.3172 0 0.855672 0.157892 0.506782 0.506782C0.157892 0.855672 0 1.3172 0 1.77778V14.2222C0 14.6828 0.157892 15.1443 0.506782 15.4932C0.855673 15.8421 1.3172 16 1.77778 16H14.2222C14.6828 16 15.1443 15.8421 15.4932 15.4932C15.8421 15.1443 16 14.6828 16 14.2222V1.77778C16 1.3172 15.8421 0.855673 15.4932 0.506782C15.1443 0.157892 14.6828 0 14.2222 0H1.77778ZM2 14V2H8C7.44772 2 7 2.44772 7 3V3.37931C6.7778 3.44985 6.54806 3.55123 6.32747 3.69556C5.65636 4.13469 5.2 4.88838 5.2 5.95252C5.2 7.02624 5.88039 7.6976 6.42428 8.05645C6.9354 8.39368 7.48085 8.56329 7.66981 8.62205L7.69938 8.63129C7.84537 8.6773 8.23297 8.80781 8.56836 9.03253C8.92404 9.27085 9 9.45799 9 9.58102C9 10.2099 8.51388 10.6035 8 10.6035C7.56491 10.6035 7.17385 10.2584 6.86187 9.72821C6.5818 9.25221 5.96887 9.09338 5.49288 9.37346C5.01688 9.65354 4.85805 10.2665 5.13813 10.7425C5.43459 11.2463 6.03514 12.0666 7 12.4257V13C7 13.5523 7.44772 14 8 14H2ZM8 14C8.55228 14 9 13.5523 9 13V12.4287C10.0963 12.0392 11 11.019 11 9.58102C11 8.45897 10.2426 7.74691 9.68164 7.37102C9.10037 6.98155 8.48797 6.78285 8.30062 6.7238L8.28747 6.71965C8.10615 6.66256 7.79457 6.56445 7.52572 6.38707C7.25294 6.20709 7.2 6.06847 7.2 5.95252C7.2 5.7177 7.24648 5.58843 7.28168 5.52158C7.31609 5.45623 7.36133 5.40918 7.42253 5.36914C7.5698 5.27278 7.79869 5.2275 8 5.2275C8.12101 5.2275 8.32963 5.2691 8.548 5.37976C8.76355 5.48898 8.91622 5.62849 8.9968 5.7603C9.28486 6.23151 9.90037 6.37998 10.3716 6.09192C10.8428 5.80386 10.9913 5.18834 10.7032 4.71713C10.3838 4.19462 9.91145 3.82853 9.452 3.59572C9.30636 3.52192 9.15464 3.45774 9 3.40455V3C9 2.44772 8.55228 2 8 2H14V14H8Z"
        fill="#0055CC"
      />
    </svg>
  );
}

export default RequestPaymentIcon;
