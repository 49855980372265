import i18next, { t } from 'i18next';
import { useState, useEffect } from 'react';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';

// Divisor used to convert from microseconds to days
export const USEC_TO_DAYS_DIVISOR = 86400000000;

// Divisor used to convert from milliseconds to days
export const MILLISECONDS_TO_DAYS_DIVISOR = 86400000;

// Multiplier to convert from milliseconds to microseconds
export const MILLI_TO_MICRO_MULTIPLIER = 1000;

// Multiplier to convert microseconds (usec) to milliseconds (ms)
export const MICROSECONDS_TO_MILLISECONDS_MULTIPLIER = 0.001;

// Number of milliseconds in 72 hours
export const SEVENTY_TWO_HOURS_IN_MILLISECONDS = 259200000;

// Interval of one minute (in milliseconds)
export const MINUTE_INTERVAL = 60000;

// Number of days in a week
export const WEEK_TO_DAYS_MULTIPLIER = 7;

// Number of seconds in a minute
export const SECONDS_IN_MINUTE = 60;

// Divisor used to convert from milliseconds to seconds
export const MILLISECONDS_TO_SECONDS_DIVISOR = 1000;

/**
 * There's an issue with our fetch mock where times come back as Long.
 * Adding this method seems easier than debugging the problem -- it
 * converts a Long to an int.
 * Source taken from: https://github.com/dcodeIO/long.js/blob/master/src/long.js
 *
 * @type {Long}
 * @param {Long | number} long - the long to convert, won't convert if this
 * is already a number
 * @returns {number} - Long value converted to an int
 */
export function longToInt(long: Long | number): number {
  if (typeof long === 'number') {
    return long;
  }
  return long.unsigned ? long.low >>> 0 : long.low;
}

/**
 * This renders a date as localized text. Omits the year if it is
 * in the current year.
 *
 * For example: renderDate(1585936712597) returns "4/3/2020"
 *
 * @param {number | Long} msFromEpoch The milliseconds since 1970/1/1
 * @param {Intl.DateTimeFormatOptions} [dateOptions]
 * Optional date formatting options to include in the outputted text.
 * @returns {string}
 */
export function renderDate(
  msFromEpoch: number | Long,
  dateOptions?: Intl.DateTimeFormatOptions,
): string {
  const ensureNumber = longToInt(msFromEpoch);
  const date = new Date(ensureNumber);
  let options: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'short' };
  // Note(klim): we can only stub Date.now in test, hence new Date() have to be
  // used with Date.now() so that test can pass
  const now = new Date(Date.now());
  if (date.getFullYear() !== now.getFullYear()) {
    options = { ...options, year: 'numeric' };
  }
  return date.toLocaleDateString(i18next.language, {
    ...options,
    ...dateOptions,
  });
}

/**
 * Returns the beginning of a date, in milliseconds since epoch.
 *
 * @param {number | Long} msFromEpoch The milliseconds since 1970/1/1
 * @returns The milliseconds since 1970/1/1 until the beginning of the calendar date.
 */
const getStartOfDateInMs = (msFromEpoch: number | Long): number => {
  // Convert to calendar date string (i.e. '01/01/2020') to remove hour, minute, etc. information
  const dateStr = renderDate(msFromEpoch, { year: 'numeric' });

  // Start timestamp of calendar date in milliseconds since epoch
  return Date.parse(dateStr);
};

export type RelativeDateFormatOptions = {
  /**
   * Whether the day of the week or month should be abbreviated
   */
  abbreviated?: boolean;
  /**
   * If date is today, do not return a relative date string.
   * For example, in the transcript list, instead of displaying
   * "Today at 9:15 PM", we just show "9:15 PM"
   */
  omitDateForToday?: boolean;
  /**
   * If date is yesterday, use the day of the week (i.e. "Monday")
   * rather than "Yesterday".
   */
  useDayOfWeekForYesterday?: boolean;
};
/**
 * This method turns a relative date for a timestamp. It assumes that the translations
 * have been initialized. The rules for determining relative date are as follows,
 * ordered by precedence:
 *
 * 1. Before the current calendar year: Full date (i.e. "December 31, 2022")
 * 2. Within the current calendar year, but beyond a week (>=7 days) ago: Month and day (i.e. "January 1")
 * 3. Within the last 7 days, but before the previous calendar date: Day of the week (i.e. "Wednesday")
 * 4. Within the previous calendar date: "Yesterday", or day of week if `useDayOfWeekForYesterday` is true
 * 5. Within the current calendar date: "Today", or undefined if `omitDateForToday` is true
 *
 * Months and days of the week are abbreviated if the `abbreviated` option is true.
 *
 * Dates in the future will be rendered as "Today", on the assumption that they're the
 * result of clock drift on a server someplace and are not serious.
 *
 * NOTE: This does not update once it is rendered. To have the timestamp magically update
 * as time pass, use useRelativeDateWithTimestamp() instead.
 *
 * @param {number | Long} msFromEpoch The milliseconds since 1970/1/1
 * @param {RelativeDateFormatOptions} options
 * (Optional) Formatting options for the date. If not provided, everything is assumed false.
 * @returns {string}
 */
export function renderRelativeDate(
  msFromEpoch: number | Long,
  options?: RelativeDateFormatOptions,
): string | undefined {
  const ensureNumber = longToInt(msFromEpoch);
  const {
    abbreviated = false,
    omitDateForToday = false,
    useDayOfWeekForYesterday = false,
  } = options || {};

  // Timestamp for Jan 1 of this year
  const currentYearStart = new Date(Date.now());
  currentYearStart.setMonth(0); // 0 is Jan
  currentYearStart.setDate(1);

  // Start of calendar date in ms from epoch
  const date = getStartOfDateInMs(msFromEpoch);
  const dateNow = getStartOfDateInMs(Date.now());
  const dateCurrentYear = getStartOfDateInMs(currentYearStart.getTime());

  // Number of days between date and current date
  const daysFromDateToNow = (dateNow - date) / MILLISECONDS_TO_DAYS_DIVISOR;

  // Number of days between date and beginning of the current year
  // If this is positive, then the date is in the current year.
  // If negative, then the date is before the current year.
  const daysFromDateToBeginningOfYear =
    (date - dateCurrentYear) / MILLISECONDS_TO_DAYS_DIVISOR;

  // Whether weekday or month should be abbreviated.
  // For example: "Thursday" would be abbreviated as "Thu" ,
  // or "March" would be abbreviated as "Mar".
  const abbreviation = abbreviated ? 'short' : 'long';

  if (daysFromDateToBeginningOfYear < 0) {
    // Before this calendar year, return month, year, and date (i.e. "December 31, 2022")
    return new Date(ensureNumber).toLocaleDateString(i18next.language, {
      year: 'numeric',
      month: abbreviation,
      day: 'numeric',
    });
  } else if (daysFromDateToNow >= WEEK_TO_DAYS_MULTIPLIER) {
    // 7 or more days in the past, return month and day (i.e. "January 21")
    return new Date(ensureNumber).toLocaleDateString(i18next.language, {
      month: abbreviation,
      day: 'numeric',
    });
  } else if (daysFromDateToNow > 1) {
    // Before yesterday and not further than a week (2-7 days in the past),
    // return day of the week (i.e. Monday, Tuesday, ...)
    return new Date(ensureNumber).toLocaleDateString(i18next.language, {
      weekday: abbreviation,
    });
  } else if (daysFromDateToNow > 0) {
    // Within previous calendar day
    return useDayOfWeekForYesterday
      ? new Date(ensureNumber).toLocaleDateString(i18next.language, {
          weekday: abbreviation,
        })
      : t('common.time.yesterday');
  } else {
    // Within current calendar day, or slightly in the future due to clock drift
    return omitDateForToday ? undefined : t('common.time.today');
  }
}

/**
 * This renders a timestamp as an absolute time.
 *
 * For example: renderTimestamp(1585936712597) returns "4/3/2020, 10:58:32 AM"
 *
 * @param {number | Long} msFromEpoch The milliseconds since 1970/1/1
 */
export function renderTimestamp(msFromEpoch: number | Long): string {
  const ensureNumber = longToInt(msFromEpoch);
  return `${new Date(ensureNumber).toLocaleDateString(
    i18next.language,
  )}, ${new Date(ensureNumber).toLocaleTimeString(i18next.language)}`;
}

/**
 * This renders a timestamp in millis as a localized time
 *
 * For example: renderTime(1585936712597) returns 10:58 AM
 *
 * @param {number | Long} msFromEpoch
 * @param {string | undefined} [timeZone]
 * (Optional) If desired, constrain the time to render for a particular timezone.
 * If not specified, the browser's timezone will be used.
 */
export function renderTime(
  msFromEpoch: number | Long,
  timeZone?: string,
): string {
  const ensureNumber = longToInt(msFromEpoch);
  return new Date(ensureNumber).toLocaleTimeString(i18next.language, {
    hour: 'numeric',
    minute: 'numeric',
    timeZone,
  });
}

/**
 * Returns a formatted date time string in the format "Nov 7, 2023, 12:30 PM"
 *
 * @param {number} msFromEpoch
 * The milliseconds since 1970/1/1 to format.
 * @param {string} timeZone
 * The timezone to format the date time in.
 * @param {Intl.DateTimeFormatOptions} [options]
 * Optional date formatting options to include in the outputted text.
 */
export const renderDateTime = (
  msFromEpoch: number,
  timeZone: string,
  options?: Intl.DateTimeFormatOptions,
): string => {
  const date = new Date(msFromEpoch);
  const isCurrentYear = date.getFullYear() === new Date().getFullYear();
  return new Date(msFromEpoch).toLocaleTimeString(i18next.language, {
    year: isCurrentYear ? undefined : 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZone,
    ...options,
  });
};

/**
 * Renders a date ISO string
 *
 * @param {number | Long} msFromEpoch
 */
export function renderISO(msFromEpoch: number | Long): string {
  const ensureNumber = longToInt(msFromEpoch);
  return new Date(ensureNumber).toISOString();
}

/**
 * This returns a tuple of relative date and timestamp, with live updates, as a React
 * custom hook (hence the "use" prefix). This returns a tuple that will "magically"
 * self-update as time passes, if it's called within a functional component's body.
 *
 * A very simple example usage:
 *
 * const Timestamp = (params: {msFromEpoch: number}) => {
 *  const [relativeDate, timestamp] = useRelativeDateWithTimestamp(params.msFromEpoch);
 *  return <div>`${relativeDate} at ${timestamp}`</div>;
 * };
 *
 * @param {number | Long} msFromEpoch
 * The milliseconds since 1970/1/1
 * @param {RelativeDateFormatOptions} [dateFormatOptions]
 * (Optional) Override to customize the formatting of the relative date.
 */
export function useRelativeDateWithTimestamp(
  msFromEpoch: number | Long,
  dateFormatOptions?: RelativeDateFormatOptions,
): [string | undefined, string] {
  const { user } = useMessengerControllerContext();
  const [relativeDate, setRelativeDate] = useState<string | undefined>(
    renderRelativeDate(msFromEpoch, dateFormatOptions),
  );
  const [timestamp, setTimestamp] = useState<string>(
    renderTime(msFromEpoch, user.timezone),
  );

  useEffect(() => {
    const refreshDateAndTime = (): void => {
      setRelativeDate(renderRelativeDate(msFromEpoch, dateFormatOptions));
      setTimestamp(renderTime(msFromEpoch, user.timezone));
    };

    // Refresh the date and time immediately in case a new message came in
    refreshDateAndTime();

    const interval = setInterval(() => {
      refreshDateAndTime();
    }, MINUTE_INTERVAL);

    // This is the cleanup hook
    return () => {
      clearInterval(interval);
    };
  }, [msFromEpoch, user.timezone, dateFormatOptions]);

  return [relativeDate, timestamp];
}

/**
 * This is just a naked re-mapping of renderTimestamp, to provide symmetry
 * with the "use" prefix of the useRelativeTimestamp() call. Timestamps don't
 * change, and therefore don't need to use hooks to re-render, but programmers
 * may change "useRelativeTimestamp" to "useTi_" and expect autocomplete to do
 * the right thing. So this is here for them :)
 *
 * @param {number | Long} msFromEpoch The milliseconds since 1970/1/1
 */
export function useTimestamp(msFromEpoch: number | Long): string {
  const ensureNumber = longToInt(msFromEpoch);
  return renderTimestamp(ensureNumber);
}

/**
 * This is just a naked re-mapping of renderTimestamp, to provide symmetry
 * with the "use" prefix of the useRelativeTimestamp() call. Timestamps don't
 * change, and therefore don't need to use hooks to re-render, but programmers
 * may change "useRelativeTimestamp" to "useDa_" and expect autocomplete to do
 * the right thing. So this is here for them :)
 *
 * @param {number | Long} msFromEpoch The milliseconds since 1970/1/1
 * @param {Intl.DateTimeFormatOptions} [dateOptions]
 * Optional date formatting options to include in the outputted text.
 */
export function useDate(
  msFromEpoch: number | Long,
  dateOptions?: Intl.DateTimeFormatOptions,
): string {
  const ensureNumber = longToInt(msFromEpoch);
  return renderDate(ensureNumber, dateOptions);
}

/**
 * Hook to render a formatted long date string given a time in microseconds.
 *
 * @param {number} epochTimeInMicroseconds
 * The epoch time in microseconds of the date to format.
 */
export const useLongDateFromMicroseconds = (
  epochTimeInMicroseconds: number,
): string => {
  return useDate(
    epochTimeInMicroseconds * MICROSECONDS_TO_MILLISECONDS_MULTIPLIER,
    {
      year: 'numeric',
      month: 'long',
    },
  );
};

/**
 * This is just a naked re-mapping of renderTime, to provide symmetry
 * with the "use" prefix of the renderTime() call. Timestamps don't
 * change, and therefore don't need to use hooks to re-render, but programmers
 * may change "renderTime" to "useTi_" and expect autocomplete to do
 * the right thing. So this is here for them :)
 *
 * @param {number | Long} msFromEpoch The milliseconds since 1970/1/1
 */
export function useTime(msFromEpoch: number | Long): string {
  const ensureNumber = longToInt(msFromEpoch);
  return renderTime(ensureNumber);
}

/**
 * Returns true if both parameter is in the same date, regardless of time.
 *
 * @param {number | Long} a
 * @param {number | Long} b
 */
export function isSameDate(a: Long | number, b: Long | number): boolean {
  return renderDate(a) === renderDate(b);
}

/**
 * Generates a formatted time stamp string used to display the duration time. i.e. "00:10"
 *
 * @param {number} runTimeInSeconds
 * The time in seconds to format.
 */
export const formatDurationTime = (runTimeInSeconds: number): string => {
  let minutes = Math.floor(runTimeInSeconds / SECONDS_IN_MINUTE);
  let seconds = Math.round(runTimeInSeconds % SECONDS_IN_MINUTE);

  // if seconds is rounded up to 60, bump by one minute instead
  if (seconds === SECONDS_IN_MINUTE) {
    minutes++;
    seconds = 0;
  }

  return `${minutes < 10 ? '0' : ''}${minutes}:${
    seconds < 10 ? '0' : ''
  }${seconds}`;
};
