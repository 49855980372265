import React, { ReactElement, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import MessengerModalDialog from 'src/components/MessengerModalDialog/MessengerModalDialog';
import {
  MarketButton,
  MarketList,
  MarketRow,
  MarketSelect,
} from 'src/components/Market';
import { onKeyDownEnter } from 'src/utils/keyboardUtils';
import { useTranslation } from 'react-i18next';
import { Medium } from 'src/gen/squareup/messenger/v3/messenger_service';
import { SHADOW_REACT_ROOT_SELECTOR } from 'src/utils/shadowDomUtils';
import './FileEmailOnlyDialog.scss';
import Logger from 'src/Logger';
import { Contact } from 'src/gen/squareup/messenger/v3/messenger_auxiliary_service';

/**
 * A modal to inform user that files can only be sent via email, and offers an
 * option to switch to an email transcript if it is available.
 *
 * @author klim
 */
const FileEmailOnlyDialog = observer((): ReactElement => {
  const { modal, customers, navigation, transcripts, event } =
    useMessengerControllerContext();
  const { t } = useTranslation();
  const [selectedContact, setSelectedContact] =
    useState<Contact.ContactMethod>();

  if (!modal.fileEmailOnlyTranscriptId) {
    Logger.logWithSentry(
      'Transcript id missing when rendering FileEmailOnlyDialog',
      'error',
    );
    modal.closeModal();
    return <></>;
  }

  const transcript = transcripts.get(modal.fileEmailOnlyTranscriptId);
  const { customerTokens, sellerKey, displayName, customerDetailsStatus, id } =
    transcript;

  const emailContacts = customers.getAllContactMethods(
    customerTokens ?? [],
    Medium.EMAIL,
  );
  useEffect(() => {
    if (
      customerDetailsStatus === 'SUCCESS' &&
      !selectedContact &&
      emailContacts.length > 0
    ) {
      setSelectedContact(emailContacts[0]);
    }
  }, [customerDetailsStatus, selectedContact, emailContacts]);

  let primaryButton;
  let secondaryButton;
  let emailSelector;
  if (emailContacts.length > 0) {
    const openEmailTranscript = (): void => {
      event.track('Click Sending File Via Email Modal', {
        action_type_name: 'switch_to_email',
        transcript_id: id,
      });

      navigation.openTranscriptByContactMethod(
        {
          contactHandle: selectedContact?.contactHandle,
          medium: Medium.EMAIL,
          sellerKey,
        },
        'files_sms_to_email_medium_switcher',
      );
      modal.closeModal();
    };
    primaryButton = (
      <MarketButton
        rank="primary"
        onClick={openEmailTranscript}
        onKeyDown={(e) => onKeyDownEnter(e, openEmailTranscript)}
      >
        {t('FileEmailOnlyDialog.switch')}
      </MarketButton>
    );

    const close = (): void => {
      event.track('Click Sending File Via Email Modal', {
        action_type_name: 'close',
        transcript_id: id,
      });

      modal.closeModal();
    };
    secondaryButton = (
      <MarketButton
        rank="secondary"
        onClick={close}
        onKeyDown={(e) => onKeyDownEnter(e, close)}
      >
        {t('common.close')}
      </MarketButton>
    );

    if (emailContacts.length > 1) {
      emailSelector = (
        <MarketSelect
          className="FileEmailOnlyDialog__select"
          data-testid="FileEmailOnlyDialog__select"
          value={selectedContact?.contactHandle}
          onMarketSelectValueDidChange={(e) => {
            const selected = emailContacts.find(
              (email) => email.contactHandle === e.detail.value,
            );
            setSelectedContact(selected);
          }}
          popoverContainer={SHADOW_REACT_ROOT_SELECTOR}
        >
          <label>{t('common.medium.email')}</label>
          <MarketList slot="list">
            {emailContacts.map((email) => (
              <MarketRow key={email.contactHandle} value={email.contactHandle}>
                {email.displayContact}
              </MarketRow>
            ))}
          </MarketList>
        </MarketSelect>
      );
    }
  } else {
    const onClick = (): void => {
      event.track('Click Sending File Via Email Modal', {
        action_type_name: 'ok',
        transcript_id: id,
      });

      modal.closeModal();
    };

    primaryButton = (
      <MarketButton
        rank="primary"
        onClick={onClick}
        onKeyDown={(e) => onKeyDownEnter(e, onClick)}
      >
        {t('common.okay')}
      </MarketButton>
    );
  }

  return (
    <MessengerModalDialog
      title={t('FileEmailOnlyDialog.title')}
      close={modal.closeModal}
      primaryButton={primaryButton}
      secondaryButton={secondaryButton}
      isLoading={customerDetailsStatus === 'LOADING'}
    >
      {emailContacts.length > 0
        ? t('FileEmailOnlyDialog.description_with_email', {
            name: displayName?.name,
          })
        : t('FileEmailOnlyDialog.description_without_email', {
            name: displayName?.name,
          })}
      {emailSelector}
    </MessengerModalDialog>
  );
});

export default FileEmailOnlyDialog;
