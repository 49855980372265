/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "bills": {
        "nested": {
          "AtoDetails": {
            "fields": {
              "atoTag": {
                "type": "squareup.bills.AtoDetails.AtoTag",
                "id": 1,
                "rule": "optional"
              },
              "version": {
                "type": "int64",
                "id": 2,
                "rule": "optional"
              }
            },
            "nested": {
              "AtoTag": {
                "values": {
                  "UNKNOWN_DO_NOT_USE": 0,
                  "FRAUD_HIDE": 1,
                  "FRAUD_SHOW": 2,
                  "UNTAGGED": 3
                }
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.bills?.nested?.AtoDetails && !$root.nested?.squareup?.nested?.bills?.nested?.AtoDetails?.nested?.AtoTag) {
	$root.addJSON(jsonData);
}

/**
 * Account Take Over details for when a bill has been marked as ATO Fraud.
 *
 * SOURCE AtoDetails @ squareup/bills/ato.proto at 8:1
 */
export const AtoDetails = $root.lookupType('squareup.bills.AtoDetails');
fixType(AtoDetails);
Builder.createAndAttachToType(AtoDetails);
/**
 * Option to indicate whether or not to hide the bill, or if it was previously tagged.
 *
 * SOURCE AtoTag @ squareup/bills/ato.proto at 11:3
 */
AtoDetails.AtoTag = $root.lookupEnum('squareup.bills.AtoDetails.AtoTag').values;
