import React, { ReactElement } from 'react';
import BaseSvgProps from 'src/types/BaseSvgProps';

/**
 * Icon representing a play button (triangle pointing to the right).
 */
const PlayIcon = (props: BaseSvgProps): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <title>Play</title>
    <path
      fill="#000"
      fillOpacity=".9"
      fillRule="evenodd"
      d="M15.586 8.709A.816.816 0 0 0 16 8a.816.816 0 0 0-.414-.709L1.242.11a.837.837 0 0 0-.828 0A.816.816 0 0 0 0 .818v14.364c0 .292.158.562.414.708a.837.837 0 0 0 .828 0l14.344-7.18Z"
      clipRule="evenodd"
    />
  </svg>
);

export default PlayIcon;
