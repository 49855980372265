import React, { ReactElement } from 'react';

/**
 * An icon used to represent a phone with an unread message.
 */
const PhoneMessageIcon = (): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="22"
    fill="none"
    viewBox="0 0 19 22"
  >
    <title>Phone Message</title>
    <path
      fill="#000"
      fillOpacity=".9"
      fillRule="evenodd"
      d="M12.279 0a5.767 5.767 0 0 0-5.148 8.37l-1.13 3.676 3.675-1.131A5.767 5.767 0 1 0 12.279 0ZM8.512 5.767a3.767 3.767 0 1 1 1.8 3.214l-.385-.236-.904.278.278-.904-.236-.385a3.745 3.745 0 0 1-.553-1.967Z"
      clipRule="evenodd"
    />
    <path
      fill="#000"
      fillOpacity=".9"
      d="M4 4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-4.5h2V18a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V6a4 4 0 0 1 4-4h1v2H4Z"
    />
    <path
      fill="#000"
      fillOpacity=".9"
      d="M8.5 16.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"
    />
  </svg>
);

export default PhoneMessageIcon;
