import Services from 'src/services/Services';
import ContactsApi from './ContactsApi';
import MerchantApi from './MerchantApi';
import SettingsApi from './SettingsApi';
import FileApi from './FileApi';
import ContextualEventsApi from './ContextualEventsApi';
import TranscriptsApi from './TranscriptsApi';
import MessagingApi from './MessagingApi';
import SubscriptionApi from './SubscriptionApi';
import GoogleApi from './GoogleApi';
import SearchApi from './SearchApi';
import WeeblyApi from './WeeblyApi';
import AppointmentsApi from 'src/api/AppointmentsApi';

/**
 * Object that maintains a reference to each Api used.
 */
class Api {
  contacts: ContactsApi;
  merchant: MerchantApi;
  settings: SettingsApi;
  file: FileApi;
  contextualEvents: ContextualEventsApi;
  transcripts: TranscriptsApi;
  messaging: MessagingApi;
  subscription: SubscriptionApi;
  google: GoogleApi;
  search: SearchApi;
  weebly: WeeblyApi;
  appointments: AppointmentsApi;

  constructor(services: Services) {
    this.contacts = new ContactsApi(services);
    this.merchant = new MerchantApi(services);
    this.settings = new SettingsApi(services);
    this.file = new FileApi(services);
    this.contextualEvents = new ContextualEventsApi(services);
    this.transcripts = new TranscriptsApi(services);
    this.messaging = new MessagingApi(services);
    this.subscription = new SubscriptionApi(services);
    this.google = new GoogleApi(services);
    this.search = new SearchApi(services);
    this.weebly = new WeeblyApi(services);
    this.appointments = new AppointmentsApi(services);
  }
}

export default Api;
