import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { MarketButton, MarketEmptyState } from 'src/components/Market';
import {
  TrackingName,
  TrackingProperties,
} from 'src/stores/EventTrackingStore';
import { ASSISTANT_ON_APPOINTMENTS_URL } from 'src/utils/url';
import './MessengerEmptyAssistantState.scss';
import ChatBubblesIcon from 'src/svgs/ChatBubblesIcon';

export type MessengerEmptyAssistantStateProps = {
  track: (name: TrackingName, properties?: TrackingProperties) => void;
};

/**
 * Component that renders the assistant empty state for the Message application.
 *
 * @example <MessengerEmptyAssistantState track={messenger.track} />
 * @param {(name: TrackingName, properties?: TrackingProperties) => void} track
 * Callback that logs a tracking event when called.
 * @returns {ReactElement}
 */
const MessengerEmptyAssistantState = observer(
  ({ track }: MessengerEmptyAssistantStateProps): ReactElement => {
    const { t } = useTranslation();

    return (
      <MarketEmptyState className="MessengerEmptyAssistantState__content">
        <div slot="primary-text">
          <ChatBubblesIcon />
          <h3>{t('MessengerEmptyAssistantState.title')}</h3>
        </div>
        <p slot="secondary-text">
          {t('MessengerEmptyAssistantState.description')}
        </p>
        <MarketButton
          rank="primary"
          slot="actions"
          href={ASSISTANT_ON_APPOINTMENTS_URL}
          target="_blank"
          onClick={() => {
            track('Click Assistant Link');
          }}
        >
          {t('MessengerEmptyAssistantState.primary_button_text')}
        </MarketButton>
      </MarketEmptyState>
    );
  },
);

export default MessengerEmptyAssistantState;
