// Classes and data-test-ids
export const PHOTO_GALLERY_VIEWER_CLASS = 'PhotosGallery__viewer';
export const PHOTO_GALLERY_VIEWER_PHOTO_CLASS = `${PHOTO_GALLERY_VIEWER_CLASS}__photo`;
export const PHOTO_GALLERY_VIEWER_SIDE_CLASS = `${PHOTO_GALLERY_VIEWER_CLASS}__side`;
export const PHOTO_GALLERY_BUTTON_CLASS = 'PhotoGallery__button';
export const PHOTO_GALLERY_NEXT_BUTTON_CLASS = `${PHOTO_GALLERY_BUTTON_CLASS}__next`;
export const PHOTO_GALLERY_PREV_BUTTON_TEST_ID = `${PHOTO_GALLERY_BUTTON_CLASS}__prev`;
export const PHOTO_GALLERY_CLOSE_BUTTON_CLASS = `${PHOTO_GALLERY_BUTTON_CLASS}__close`;
export const PHOTO_GALLERY_CAROUSEL_CLASS = 'PhotosGallery__carousel';
export const PHOTO_GALLERY_CAROUSEL_ROW_CLASS = `${PHOTO_GALLERY_CAROUSEL_CLASS}__row`;

// The start of the id of the thumbails, used to get the element to scroll to their position.
export const PHOTO_GALLERY_CAROUSEL_THUMBNAIL_CLASS = `${PHOTO_GALLERY_CAROUSEL_CLASS}__thumbnail`;

export const CAROUSEL_PHOTO_CLASS = 'CarouselPhoto__photo';
export const CAROUSEL_PLACEHOLDER_CLASS = 'CarouselPhoto__Placeholder';
