import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import MessengerModalDialog from 'src/components/MessengerModalDialog/MessengerModalDialog';
import { useTranslation } from 'react-i18next';
import { MarketButton } from 'src/components/Market';
import { bytesToSizeLabel } from 'src/utils/fileUtils';
import { onKeyDownEnter } from 'src/utils/keyboardUtils';

/**
 * A dialog to show that the total files size selected by the user
 * has exceed the limit allowed.
 *
 * @author klim
 */
const FileLimitReachedDialog = observer((): ReactElement => {
  const { modal, event, transcriptView } = useMessengerControllerContext();
  const { t } = useTranslation();

  const onClick = (): void => {
    event.track('Click File Oversized Modal', {
      transcript_id: transcriptView.transcript.id,
    });
    modal.closeModal();
  };

  return (
    <MessengerModalDialog
      title={t('FileLimitReachedDialog.title')}
      close={modal.closeModal}
      primaryButton={
        <MarketButton
          rank="primary"
          onClick={onClick}
          onKeyDown={(e) => onKeyDownEnter(e, onClick)}
        >
          {t('common.dismiss')}
        </MarketButton>
      }
    >
      {t('FileLimitReachedDialog.description', {
        maxLimit: bytesToSizeLabel(modal.fileSizeMaxLimit ?? 0),
        exceedAmount: bytesToSizeLabel(modal.fileSizeExceedAmount ?? 0),
      })}
    </MessengerModalDialog>
  );
});

export default FileLimitReachedDialog;
