import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import PastAppointmentsListStore from 'src/stores/objects/PastAppointmentsList';
import MessengerList from 'src/components/MessengerList/MessengerList';
import {
  MarketAccessory,
  MarketBanner,
  MarketRow,
} from 'src/components/Market';
import {
  getEditAppointmentUrl,
  getEditRecurringAppointmentUrl,
} from 'src/utils/url';
import AppointmentIcon from 'src/svgs/AppointmentIcon';
import { renderDateTime } from 'src/utils/timeUtils';
import './PastAppointmentsList.scss';

export type PastAppointmentsListProps = {
  appointments: PastAppointmentsListStore;
  maxSize?: number;
};

/**
 * Component that renders a list of past appointments.
 *
 * @param {PastAppointmentsListStore} appointments
 * The store containing the list of past appointments to display.
 * @param {number} [maxSize]
 * (Optional) The maximum number of appointments to display.
 */
const PastAppointmentsList = observer(
  ({ appointments, maxSize }: PastAppointmentsListProps): ReactElement => {
    const { user } = useMessengerControllerContext();
    const { t } = useTranslation();

    const isPartialListShown =
      maxSize !== undefined && appointments.list.length > maxSize;

    if (appointments.status === 'ERROR') {
      return (
        <MarketBanner variant="critical">
          {t('PastAppointmentsList.error_label')}
        </MarketBanner>
      );
    }

    return (
      <div className="PastAppointmentsList">
        <MessengerList
          loadNextPage={appointments.loadMore}
          loadMoreStatus={appointments.loadMoreStatus}
          hasNextPage={isPartialListShown ? false : appointments.hasNextPage}
          initialScrollPosition={0}
          transient
        >
          {appointments.list
            .slice(0, maxSize)
            .map(
              ({
                isRecurring,
                reservationId,
                timestampMillis,
                itemNames,
                staffName,
              }) => {
                const link = isRecurring
                  ? getEditRecurringAppointmentUrl(
                      reservationId,
                      `${timestampMillis}`,
                    )
                  : getEditAppointmentUrl(reservationId);

                const title = itemNames
                  ? itemNames?.join(', ')
                  : t('ContextualEvent.appointment.appointment');

                return (
                  <MarketRow
                    key={reservationId}
                    className="PastAppointmentsList__item"
                    data-testid={`PastAppointmentsList__item-${reservationId}`}
                    href={link}
                    target="_blank"
                    tabIndex={0}
                    variant="drill"
                  >
                    <label slot="label">{title}</label>
                    <MarketAccessory
                      size="image"
                      slot="leading-accessory"
                      className="PastAppointmentsList__item-icon"
                    >
                      <AppointmentIcon />
                    </MarketAccessory>
                    <p slot="subtext">
                      {t('PastAppointmentsList.item_label_text', {
                        dateTime: renderDateTime(
                          timestampMillis,
                          user.timezone,
                        ),
                        staffNames: staffName ?? '',
                      })}
                    </p>
                  </MarketRow>
                );
              },
            )}
        </MessengerList>
      </div>
    );
  },
);

export default PastAppointmentsList;
