import { observer } from 'mobx-react';
import React, {
  ReactElement,
  RefObject,
  useState,
  useEffect,
  useRef,
} from 'react';
import { MarketContentCard } from 'src/components/Market';
import 'src/pages/TranscriptViewPage/components/Utterance/components/UtterancePhotos/UtterancePhotos.scss';
import './CarouselPhoto.scss';
import { PHOTOS_THUMBNAIL_QUERY_PARAM } from 'src/utils/photoUtils';
import { Photo } from 'src/MessengerTypes';
import { CAROUSEL_PHOTO_CLASS, CAROUSEL_PLACEHOLDER_CLASS } from './constants';

export type CarouselPhotoProps = {
  photo: Photo;
  uniqueKey?: string;
  isSelected?: boolean;
  onClick?: () => void;
  containerRef?: RefObject<HTMLDivElement>;
  isParentLoaded?: boolean;
};

const INTERSECTION_ROOT_MARGIN = '100px'; // Increase intersection region by 100px
const INTERSECTION_THRESHOLD = 0.01; // Start loading photo when intersects at least 1%

// Delay (in ms) before creating IntersectionObserver.

/**
 * Component for rendering the thumbnails in the Photo Gallery carousel.
 * The thumbnail photo is lazy loaded.
 *
 * @example
 * Basic usage:
 * <CarouselPhoto photo={photo} />
 *
 * With optional:
 * <CarouselPhoto
 *    photo={photo}
 *    onClick={() => clickFunction()}
 *    isSelected
 *    containerRef={parentRef}
 * />
 * @param {Photo} photo
 * Photo that will be rendered in the thumbnail.
 * @param {string} [uniqueKey]
 * (Optional) Unique key for the photo.
 * This is used in the Photo Gallery to auto-scroll to the element,
 * and must be the index of the photo in the list of thumbnails.
 * @param {boolean} [isSelected]
 * (Optional) Whether this thumbnail is selected.
 * In the Photo Gallery, this corresponds to the photo selected in the enlarged view.
 * @param {Function} [onClick]
 * (Optional) Function to call when the button is clicked.
 * @param {RefObject<HTMLDivElement>} [containerRef]
 * (Optional) Reference to the containing element, whose boundaries will dictate
 * whether or not the photo should be rendered. This element will be passed into the
 * IntersectionObserver.
 * @param {boolean} [isParentLoaded]
 * (Optional) Whether parent container has been loaded in DOM or not.
 * If a non-null-or-undefined value is passed in, then IntersectionObserver will wait
 * until this value is `true` before being created.
 * @author teresalin
 */
const CarouselPhoto = observer(
  ({
    photo,
    uniqueKey,
    isSelected,
    onClick,
    containerRef,
    isParentLoaded,
  }: CarouselPhotoProps): ReactElement => {
    const [isVisible, setIsVisible] = useState(false);

    const photoRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (
        !containerRef?.current ||
        !photoRef?.current ||
        isParentLoaded == null
      ) {
        // If containerRef is passed in, then neither of the refs should be null, but in the case that they are,
        // we should fallback to `isVisible` set to true so that the thumbnails load.
        return setIsVisible(true);
      }

      // <CarouselPhoto> might be "mounted" but not yet available in the DOM, due to how React Portals
      // are inserted. We need to wait until the modal has truly been inserted into the DOM, before
      // creating the IntersectionObserver. Unfortunately, neither checking for the shadow root nor
      // using MutationObserver would work, so the workaround is to listen to the `onAnimationEnd`
      // event on the modal element (<PhotoGallery/> has a fade-in animation).
      if (!isParentLoaded) return undefined;

      const intersectionObserver = new IntersectionObserver(
        (entries: IntersectionObserverEntry[]): void => {
          const intersectionEntry = entries[0];

          if (intersectionEntry.isIntersecting) {
            setIsVisible(true);
          }
        },
        {
          root: containerRef.current,
          rootMargin: INTERSECTION_ROOT_MARGIN,
          threshold: INTERSECTION_THRESHOLD,
        },
      );
      if (photoRef.current) {
        intersectionObserver.observe(photoRef.current);
      }

      return () => {
        intersectionObserver.disconnect();
      };
      // TODO (#5429): re-enable eslint rule in the next line, or remove this TODO
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isParentLoaded]);

    const className = `${CAROUSEL_PHOTO_CLASS} ${isSelected ? 'selected' : ''}`;
    let url = photo.url;
    // only optimize if it is not a local photo
    if (!photo.isLocal) {
      url += PHOTOS_THUMBNAIL_QUERY_PARAM;
    }

    const photoElement = (
      <MarketContentCard
        className={className}
        id={`${CAROUSEL_PHOTO_CLASS}${uniqueKey ?? ''}`}
      >
        <img src={url} onClick={onClick} data-testid={CAROUSEL_PHOTO_CLASS} />
      </MarketContentCard>
    );

    return (
      <div ref={photoRef}>
        {isVisible ? (
          photoElement
        ) : (
          <div
            className={`${CAROUSEL_PHOTO_CLASS} ${CAROUSEL_PLACEHOLDER_CLASS}`}
            data-testid={CAROUSEL_PLACEHOLDER_CLASS}
          />
        )}
      </div>
    );
  },
);

export default CarouselPhoto;
