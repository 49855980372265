import React, { ReactNode } from 'react';
import { HighlightSegment } from 'src/MessengerTypes';

export type GetHighlightSegmentsForSubstringArgs = {
  substringStartIndex: number;
  substringEndIndex: number;
  isHighlightEnabled: boolean;
  highlightSegments: HighlightSegment[];
};

/**
 * Given the start and end index of a substring, returns an array of highlight segments that are within the substring.
 * If the highlight segments are not enabled or there are no highlight segments, returns an empty array.
 * If the highlight segments are enabled but there are no segments within the substring, returns an empty array.
 * If the highlight segments are enabled and there are segments within the substring, returns an array of tuples
 * representing the start and end indices of each segment within the substring.
 *
 * @param {object} args
 * @param {number} args.substringStartIndex
 * The start index of the substring.
 * @param {number} args.substringEndIndex
 * The end index of the substring.
 * @param {boolean} args.isHighlightEnabled
 * Whether highlighting is enabled.
 * @param {HighlightSegment[]} args.highlightSegments
 * Array of tuples representing the start and end of each highlight segment, relative to the substring.
 */
export const getHighlightSegmentsForSubstring = ({
  substringStartIndex,
  substringEndIndex,
  isHighlightEnabled,
  highlightSegments,
}: GetHighlightSegmentsForSubstringArgs): HighlightSegment[] => {
  if (!isHighlightEnabled || highlightSegments.length === 0) {
    return [];
  }

  const substringHighlightSegments: HighlightSegment[] = [];

  highlightSegments.forEach((segment) => {
    const isHighlightSegmentIndexInSubstringIndices = (
      index: number,
    ): boolean => index >= substringStartIndex && index <= substringEndIndex;
    const [segmentStartIndex, segmentEndIndex] = segment;
    const isSegmentStartInIndices =
      isHighlightSegmentIndexInSubstringIndices(segmentStartIndex);
    const isSegmentEndInIndices =
      isHighlightSegmentIndexInSubstringIndices(segmentEndIndex);

    if (isSegmentStartInIndices && isSegmentEndInIndices) {
      // Indices of range relative to start of substring where the highlight would be
      substringHighlightSegments.push([
        segmentStartIndex - substringStartIndex,
        segmentEndIndex - substringStartIndex,
      ]);
    } else if (isSegmentStartInIndices) {
      // Indices of start of highlight relative to start of substring and end of substring
      substringHighlightSegments.push([
        segmentStartIndex - substringStartIndex,
        substringEndIndex - substringStartIndex,
      ]);
    } else if (isSegmentEndInIndices) {
      // Indices of start of substring and end of highlight relative to start of substring
      substringHighlightSegments.push([
        0,
        segmentEndIndex - substringStartIndex,
      ]);
    }
  });

  return substringHighlightSegments;
};

/**
 * Given a string and a set of highlight segments, returns an array of ReactNodes
 * that represent the original text with the highlight segments wrapped in a span to highlight them.
 *
 * @param {string} text
 * The text to highlight
 * @param {HighlightSegment[]} highlightSegments
 * Array of tuples representing the start and end of each highlight segment
 * @returns {ReactNode[]} - Array of ReactNodes representing the original text with highlight segments
 */
export const getTextWithHighlights = (
  text: string,
  highlightSegments: HighlightSegment[],
): ReactNode[] => {
  if (highlightSegments.length === 0) {
    return [text];
  }

  const textWithHighlights: ReactNode[] = [];

  // Add from the start of the text to the beginning of the first highlight
  textWithHighlights.push(text.slice(0, highlightSegments[0][0]));

  // Iteratively add the highlight segment and the text between it and
  // the next segment, or the end if there is no next segment
  for (let i = 0; i < highlightSegments.length; i++) {
    const [segmentStart, segmentEnd] = highlightSegments[i];
    const hasNextHighlight = i + 1 < highlightSegments.length;
    textWithHighlights.push(
      // Portion of text to highlight
      <span key={i} className="UtteranceText__highlight">
        {text.slice(segmentStart, segmentEnd)}
      </span>,
      // Text between highlight segment and start of next segment (or to the end of text if no next segment)
      text.slice(
        segmentEnd,
        hasNextHighlight ? highlightSegments[i + 1][0] : text.length,
      ),
    );
  }

  return textWithHighlights;
};
