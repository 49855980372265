/**
 * A phone icon for indicating calls.
 */

import React, { ReactElement } from 'react';

function CallIcon(): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <title>Call</title>
      <path
        fill="#000"
        fillOpacity=".9"
        fillRule="evenodd"
        d="m4.198.31 5.597 5.597-2.312 2.312a26.864 26.864 0 0 0 4.237 4.25l2.318-2.32 5.657 5.658-3.032 3.031c-.645.645-1.591 1.003-2.563.809C7.506 18.327 1.68 12.499.358 5.905c-.194-.972.164-1.918.808-2.563L4.198.31Zm0 2.828L2.581 4.756c-.23.23-.31.516-.262.756 1.162 5.803 6.371 11.012 12.174 12.174.24.048.527-.032.756-.262l1.617-1.617-2.828-2.829-2.137 2.137-.694-.516a28.72 28.72 0 0 1-5.855-5.872l-.512-.693 2.127-2.127-2.769-2.769Z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default CallIcon;
