/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "common": {
        "nested": {
          "Header": {
            "fields": {
              "name": {
                "type": "string",
                "id": 1,
                "rule": "optional"
              },
              "value": {
                "type": "string",
                "id": 2,
                "rule": "optional"
              }
            }
          },
          "Headers": {
            "fields": {
              "header": {
                "type": "squareup.common.Header",
                "id": 1,
                "rule": "repeated"
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.common?.nested?.Header && !$root.nested?.squareup?.nested?.common?.nested?.Headers) {
	$root.addJSON(jsonData);
}

/**
 * An HTTP or email header.
 *
 * SOURCE Header @ squareup/common/protocols.proto at 9:1
 */
export const Header = $root.lookupType('squareup.common.Header');
fixType(Header);
Builder.createAndAttachToType(Header);
/**
 * A set of HTTP or email headers.
 *
 * SOURCE Headers @ squareup/common/protocols.proto at 15:1
 */
export const Headers = $root.lookupType('squareup.common.Headers');
fixType(Headers);
Builder.createAndAttachToType(Headers);
