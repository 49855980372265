import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import MessengerModalDialog from 'src/components/MessengerModalDialog/MessengerModalDialog';
import { useTranslation } from 'react-i18next';
import { MarketButton } from 'src/components/Market';
import { onKeyDownEnter } from 'src/utils/keyboardUtils';

/**
 * A dialog to show that a file sent contains virus.
 *
 * @author klim
 */
const FileVirusDetectedModal = observer((): ReactElement => {
  const { modal, transcriptView, event } = useMessengerControllerContext();
  const { t } = useTranslation();

  const onClick = (): void => {
    event.track('Click File Virus Modal', {
      transcript_id: transcriptView.transcript.id,
    });
    modal.closeModal();
  };

  return (
    <MessengerModalDialog
      title={t('FileVirusDetectedModal.title')}
      close={modal.closeModal}
      primaryButton={
        <MarketButton
          rank="primary"
          onClick={onClick}
          onKeyDown={(e) => onKeyDownEnter(e, onClick)}
        >
          {t('common.okay')}
        </MarketButton>
      }
    >
      {t('FileVirusDetectedModal.description')}
    </MessengerModalDialog>
  );
});

export default FileVirusDetectedModal;
