import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { MessengerModalFullContent } from 'src/components/MessengerModalFull';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import { MarketButton, MarketHeader } from 'src/components/Market';
import GlobeIcon from 'src/svgs/GlobeIcon';
import MarketBackIcon from 'src/svgs/MarketBackIcon';
import { useTranslation } from 'react-i18next';
import { MessengerSettings } from 'src/gen/squareup/messenger/v3/messenger_service';
import WebsiteSettingRow from 'src/components/WebsiteSettingRow/WebsiteSettingRow';
import Logger from 'src/Logger';
import { LoadingStatus } from 'src/MessengerTypes';
import { getSquareOnlineUrl } from 'src/utils/url';

/**
 * Renders the plugin settings for Square Online websites, when the merchant has more than
 * one Square Online site.
 */
const SqOnlineSettingsPage = observer((): ReactElement => {
  const { settings, status, navigation, api, event } =
    useMessengerControllerContext();
  const { t } = useTranslation();
  const [saveStatus, setSaveStatus] = useState<LoadingStatus>('NOT_STARTED');
  const {
    sqOnlineSettings,
    updateSqOnlineSettingOnSave,
    hasSqOnlineSettings,
    hasSqOnlinePermissions,
  } = settings;

  const isLoading =
    settings.status === 'LOADING' || settings.status === 'NOT_STARTED';

  useEffect(() => {
    if (settings.status === 'NOT_STARTED') {
      settings.init();
    }
  }, [settings, settings.status]);

  const saveSetting = async (
    setting: MessengerSettings.ISquareOnlineWebsiteSetting,
    enabled: boolean,
  ): Promise<void> => {
    setSaveStatus('LOADING');
    event.track('Click Text Us Toggle', {
      enabled,
      setting_source: 'messages_settings',
      surface: 'SQUARE_ONLINE_SITE',
    });

    const updatedSetting = {
      ...setting,
      pluginEnabled: {
        ...setting.pluginEnabled,
        boolValue: enabled,
      },
    };
    try {
      await api.settings.saveSettings({
        squareOnlineWebsiteSettings: [updatedSetting],
      });
      updateSqOnlineSettingOnSave(updatedSetting);
      setSaveStatus('SUCCESS');
    } catch (error) {
      Logger.error(`Unable to save Sq Online setting: ${error}`);
      status.setError({
        label: t('EditVoicemailPage.edit_toggle_error_text'),
      });
      setSaveStatus('ERROR');
      return;
    }
    status.setSuccess({
      label: t('EditVoicemailPage.edit_toggle_success_text'),
    });
  };

  const navigateBackToSettings = useCallback((): void => {
    navigation.sheet.clearNavigation();
    navigation.sheet.navigateTo('SETTINGS');
  }, [navigation]);

  useEffect(() => {
    if (!hasSqOnlinePermissions && !isLoading) {
      status.setError({
        label: t('common.error.permission_view'),
        timerMillis: 5000,
      });
      navigateBackToSettings();
    }
  }, [hasSqOnlinePermissions, isLoading, status, t, navigateBackToSettings]);

  useEffect(() => {
    if (!hasSqOnlineSettings && !isLoading) {
      status.setError({
        label: t('SqOnlineSettingsPage.no_websites_error'),
        timerMillis: 5000,
      });
      navigateBackToSettings();
    }
  }, [hasSqOnlineSettings, isLoading, status, t, navigateBackToSettings]);

  return (
    <>
      <MarketHeader showNavigation>
        <MarketButton
          rank="secondary"
          slot="navigation"
          onClick={() => {
            if (navigation.sheet.canNavigateBack) {
              navigation.sheet.navigateBack();
              return;
            }
            navigateBackToSettings();
          }}
        >
          <MarketBackIcon slot="icon" />
        </MarketButton>
        <h2>{t('MessagesPluginSettings.surfaces.sq_online_title_multiple')}</h2>
        <p>{t('SqOnlineSettingsPage.description')}</p>
      </MarketHeader>
      <MessengerModalFullContent status={status.value} isLoading={isLoading}>
        {sqOnlineSettings.map((website) => (
          <WebsiteSettingRow
            key={`sq-online-row-${website.id}`}
            selected={website.pluginEnabled?.boolValue}
            onMarketRowSelected={() => {
              saveSetting(website, true);
            }}
            onMarketRowDeselected={() => {
              saveSetting(website, false);
            }}
            icon={<GlobeIcon />}
            websiteLabel={
              website.title ||
              t('MessagesPluginSettings.surfaces.sq_online_title_single')
            }
            description={t(
              'MessagesPluginSettings.surfaces.sq_online_description',
            )}
            url={website.url && getSquareOnlineUrl(website.url)}
            disabled={saveStatus === 'LOADING' || undefined}
            dataTestId="SqOnlineSettingsPage__website-row"
          />
        ))}
      </MessengerModalFullContent>
    </>
  );
});

export default SqOnlineSettingsPage;
