import Logger from 'src/Logger';
import { Status } from 'src/gen/squareup/messenger/v2/messenger_service';
import { Status as StatusV3 } from 'src/gen/squareup/messenger/v3/messenger_service';
import { Status as AuxiliaryStatus } from 'src/gen/squareup/messenger/v3/messenger_auxiliary_service';

const _callRpc = async <I, O>({
  name,
  rpc,
  request,
  successStatusCode,
  isExternalService,
}: {
  name: string;
  rpc: (req: I) => Promise<O>;
  request: I;
  successStatusCode: Status.Code | StatusV3.Code;
  isExternalService?: boolean;
}): Promise<O> => {
  const rpcId = Logger.logRpcRequest(name, request);
  try {
    const response: any = await rpc(request); // eslint-disable-line @typescript-eslint/no-explicit-any

    if (isExternalService || response?.status?.code === successStatusCode) {
      Logger.logRpcResponse(rpcId, name, response);
      return response;
    } else {
      throw response;
    }
  } catch (error) {
    Logger.logRpcError(rpcId, name, error);
    throw error;
  }
};

/**
 * A generalized function to call a proto RPC. This handles the promise resolution and associated
 * logging. Note that the actual RPC implementation is left to the |rpc| parameter -- this is just
 * a wrapper around that to make some logging and error handling around the call more
 * ergonomic. It throws an error if a success status code is not returned, and we are not calling
 * an external service.
 *
 * @template I
 * @template O
 * @param {object} args
 * @param {string} args.name
 * The human-readable name of the RPC, used to log the RPC call.
 * @param {(req: I) => Promise<O>} args.rpc
 * The implementation of the RPC. This is generally a function on the generated proto service.
 * @param {boolean} [args.isExternalService]
 * True if we are calling an external service.
 * @param {I} args.request
 * The input to the RPC. This is generally a proto request value.
 * @returns {Promise<O>}
 */
export const callRpc = <I, O>(args: {
  name: string;
  rpc: (req: I) => Promise<O>;
  request: I;
  isExternalService?: boolean;
}): Promise<O> => _callRpc({ ...args, successStatusCode: Status.Code.SUCCESS });

/**
 * A generalized function to call a proto RPC. This handles the promise resolution and associated
 * logging. Note that the actual RPC implementation is left to the |rpc| parameter -- this is just
 * a wrapper around that to make some logging and error handling around the call more
 * ergonomic.
 *
 * @template I
 * @template O
 * @param {object} args
 * @param {string} args.name
 * The human-readable name of the RPC, used to log the RPC call.
 * @param {(req: I) => Promise<O>} args.rpc
 * The implementation of the RPC. This is generally a function on the generated proto service.
 * @param {I} args.request
 * The input to the RPC. This is generally a proto request value.
 * @returns {Promise<O>}
 */
export const callV3Rpc = <I, O>(args: {
  name: string;
  rpc: (req: I) => Promise<O>;
  request: I;
}): Promise<O> =>
  _callRpc({ ...args, successStatusCode: StatusV3.Code.SUCCESS });

/**
 * A generalized function to call a MessengerAuxiliaryService RPC. This handles the promise resolution
 * and associated logging. Note that the actual RPC implementation is left to the |rpc| parameter --
 * this is just a wrapper around that to make some logging and error handling around the call more
 * ergonomic.
 *
 * @template I
 * @template O
 * @param {object} args
 * @param {string} args.name
 * The human-readable name of the RPC, used to log the RPC call.
 * @param {(req: I) => Promise<O>} args.rpc
 * The implementation of the RPC. This is generally a function on the generated proto service.
 * @param {I} args.request
 * The input to the RPC. This is generally a proto request value.
 * @returns {Promise<O>}
 */
export const callAuxiliaryRpc = <I, O>(args: {
  name: string;
  rpc: (req: I) => Promise<O>;
  request: I;
}): Promise<O> =>
  _callRpc({ ...args, successStatusCode: AuxiliaryStatus.Code.SUCCESS });

/**
 * Copied straight from @square/wirejs because our generated
 * code expects Uint8Array, but we get back an ArrayBuffer
 *
 * @param {Response} response
 * The response object to parse.
 */
export const parseResponse = (response: Response): Promise<Uint8Array> =>
  response
    .arrayBuffer()
    .then((arrayBuffer: ArrayBuffer) => new Uint8Array(arrayBuffer));
