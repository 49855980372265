// TODO(wdetlor): [CONV-1099] Migrate to Market Component when the Market coach mark component is available https://jira.sqprod.co/browse/UI-2389
import React, { ReactElement, useEffect } from 'react';
import { observer } from 'mobx-react';
import TooltipCloseIcon from 'src/svgs/TooltipCloseIcon';
import './AnnouncementTooltip.scss';
import { onKeyDownEnter } from 'src/utils/keyboardUtils';

export type AnnouncementTooltipProps = {
  text: string;
  onDismiss: () => void;
  onRender?: () => void;
};

/**
 * Tooltip component used to announce new feature launches.
 *
 * @example <AnnouncementTooltip text='some text' onDismiss={() => tooltip.dismiss('REVIEWS')} />
 * @param {string} text
 * The text to display in the tooltip.
 * @param {() => void} onDismiss
 * Callback that will be executed when the dismiss action is triggered.
 * @param {() => void} [onRender]
 * Callback that will be executed when the tooltip is rendered.
 * @returns {ReactElement}
 */
const AnnouncementTooltip = observer(
  ({ text, onDismiss, onRender }: AnnouncementTooltipProps): ReactElement => {
    useEffect(() => {
      onRender && onRender();
      // TODO (#5429): re-enable eslint rule in the next line, or remove this TODO
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <div className="AnnouncementTooltip__container">
        <div className="AnnouncementTooltip__content">
          {text}
          <div
            className="AnnouncementTooltip__close"
            data-testid="AnnouncementTooltip__close"
            onClick={onDismiss}
            onKeyDown={(e) => onKeyDownEnter(e, onDismiss)}
            role="button"
            tabIndex={0}
          >
            <TooltipCloseIcon />
          </div>
        </div>
      </div>
    );
  },
);

export default AnnouncementTooltip;
