import React, { ReactElement, useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { MarketInputText } from 'src/components/Market';
import { MarketInputValueChangeEvent } from 'src/MarketTypes';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import './InputBar.scss';
import LocationSelector from 'src/components/LocationSelector/LocationSelector';

export type InputBarProps = {
  unitToken: string;
  setUnitToken: (unitToken: string) => void;
  autoFocus?: boolean;
};

/**
 * Component that renders the input bar at the top of the NewMessage page.
 *
 * @example <InputBar />
 * @param {string} unitToken
 * The unit token to use for opening a new message.
 * @param {(unitToken: string) => void} setUnitToken
 * Function to be called anytime the unit token changes.
 * @param {boolean} [autoFocus]
 * Will focus the bar on render if true
 * @returns {ReactElement}
 */
const InputBar = observer(
  ({ unitToken, setUnitToken, autoFocus }: InputBarProps): ReactElement => {
    const { newMessageSearch } = useMessengerControllerContext();
    const { query, setQuery } = newMessageSearch;
    const { t } = useTranslation();

    const inputRef = useRef<HTMLMarketInputTextElement>(null);

    // Set initial focus to the input
    useEffect(() => {
      if (autoFocus) {
        inputRef?.current?.setFocus?.();
      }
    }, [autoFocus]);

    return (
      <div className="InputBar__inputs">
        <MarketInputText
          className="InputBar__search"
          value={query}
          onMarketInputValueChange={(event: MarketInputValueChangeEvent) =>
            setQuery(event.detail.value)
          }
          ref={inputRef}
        >
          <label>{t('InputBar.label')}</label>
        </MarketInputText>
        <LocationSelector
          label={t('InputBar.select_unit_label')}
          unitToken={unitToken}
          setUnitToken={setUnitToken}
          className="InputBar__unit_selector"
        />
      </div>
    );
  },
);

export default InputBar;
