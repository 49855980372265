import Services from 'src/services/Services';
import { callAuxiliaryRpc } from 'src/utils/apiUtils';
import {
  Contact,
  CreateContactRequest,
  SearchContactsRequest,
  GetContactsRequest,
  ICreateContactRequest,
} from 'src/gen/squareup/messenger/v3/messenger_auxiliary_service';

/**
 * Api responsible for containing calls related to customer contacts.
 */
class ContactsApi {
  private _services: Services;

  constructor(services: Services) {
    this._services = services;
  }

  /**
   * Method that searches for contacts based on a query.
   *
   * @param {string} query
   * The query to use to filter the list of contacts by.
   * @returns {Contact[]}
   * The list of contacts.
   */
  searchContacts = async (query: string): Promise<Contact[]> => {
    const { contacts } = await callAuxiliaryRpc({
      name: 'SearchContacts',
      rpc: (x) => this._services.messagesAuxiliary.searchContacts(x),
      request: SearchContactsRequest.create({
        query,
      }),
    });

    return [...contacts];
  };

  /**
   * Create a new customer in Rolodex based on some parameters.
   * Used when a person searches for a customer and hits the "New
   * Customer" button. Will return null if anything goes wrong,
   * including if for some reason the returned contact has no
   * contact token.
   *
   * @param {object} args
   * @param {string} args.givenName
   * @param {string} args.surname
   * @param {string} args.emailAddress
   * @param {string} args.phoneNumber
   * @returns {Promise<Contact|null>} - null if anything went
   * wrong
   */
  createContact = async (
    args: ICreateContactRequest,
  ): Promise<Contact | null> => {
    const { givenName, surname, emailAddress, phoneNumber } = args;

    const { contact } = await callAuxiliaryRpc({
      name: 'CreateContact',
      rpc: (x) => this._services.messagesAuxiliary.createContact(x),
      request: CreateContactRequest.create({
        givenName,
        surname,
        emailAddress,
        phoneNumber,
      }),
    });

    return contact;
  };

  /**
   * Retrieves customer information for a given list of customer tokens.
   *
   * @param {string[]} contactTokens
   * The list of customer tokens to look up metadata for.
   * @returns {Promise<Contact[]>}
   * Corresponding customer metadata for the provided customer tokens.
   */
  getContacts = async (contactTokens: string[]): Promise<Contact[]> => {
    const { contacts } = await callAuxiliaryRpc({
      name: 'GetContacts',
      rpc: (x) => this._services.messagesAuxiliary.getContacts(x),
      request: GetContactsRequest.create({
        contactTokens,
      }),
    });

    return [...contacts];
  };
}

export default ContactsApi;
