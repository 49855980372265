/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../Fixes';
import Builder from '../../../Builder';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "waiter": {
        "nested": {
          "private": {
            "nested": {
              "extensions": {
                "nested": {
                  "DeleteReason": {
                    "values": {
                      "UNKNOWN": 100,
                      "USER_ACTION": 200,
                      "DUPLICATION": 300
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.waiter?.nested?.private?.nested?.extensions?.nested?.DeleteReason) {
	$root.addJSON(jsonData);
}

/**
 * SOURCE DeleteReason @ squareup/waiter/private/extensions.proto at 8:1
 */
export const DeleteReason = $root.lookupEnum('squareup.waiter.private.extensions.DeleteReason').values;
