import { post } from './api';
import {
  createUserToken,
  formatAttributes,
  getExclusionReason,
  isSuccess,
} from './utils';
import { handleAxiosError, createErrorResult } from './error-handling';
import {
  TokenType,
  GetActivateExperimentOptions,
  GetActivateExperimentRequest,
  GetActivateExperimentResponse,
  ExclusionReason,
  Result,
} from './types';
import { recordExclusion } from './record-exclusion';

export async function getActivateExperiment({
  experimentName,
  token,
  tokenType = TokenType.ANONYMOUS_VISITOR,
  userAttributes,
  projectId,
  cdpKey,
  env,
  hasUnsafeDefaults = false,
}: GetActivateExperimentOptions): Promise<
  Result & {
    variantName: string | undefined;
  }
> {
  const userToken = createUserToken(token, tokenType);
  const formattedAttributes = formatAttributes(userAttributes);

  const data = {
    experimentName,
    userToken,
    ...(formattedAttributes && {
      userAttributes: { userAttributes: formattedAttributes },
    }),
    projectId,
    cdpKey,
  };

  try {
    const { variantName, status } = await post<
      GetActivateExperimentRequest,
      GetActivateExperimentResponse
    >('get-activate-experiment', data, env);

    if (isSuccess(status)) {
      return { success: true, variantName };
    }

    recordExclusion({
      experimentName,
      exclusionReason: getExclusionReason(status),
      token,
      tokenType,
      userAttributes,
      cdpKey,
      env,
    });

    // only /get-activate-experiment returns '' for an undefined variant
    // all other endpoints return undefined
    return {
      variantName: variantName === '' ? undefined : variantName,
      ...createErrorResult(status),
    };
  } catch (err: unknown) {
    recordExclusion({
      experimentName,
      exclusionReason: ExclusionReason.EXPERIMENT_MISCONFIGURED,
      token,
      tokenType,
      userAttributes,
      cdpKey,
      env,
    });

    return {
      variantName: undefined,
      ...handleAxiosError(err, hasUnsafeDefaults),
    };
  }
}
