import React, { ReactElement } from 'react';

/**
 * A triangle with an exclamation mark inside, indicating a warning or error.
 */
const AlertIcon = (): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="96"
    height="97"
    fill="none"
    viewBox="0 0 96 97"
  >
    <title>Warning</title>
    <path
      fill="url(#paint0_linear_2315_17265)"
      d="M82.83 88.633H13.17c-6.125 0-10.02-6.56-7.085-11.935l34.83-63.86c3.06-5.61 11.11-5.61 14.17 0l34.83 63.855c2.935 5.38-.96 11.935-7.085 11.935v.005Z"
    />
    <path
      fill="#121212"
      d="M50.945 63.867h-5.82l-3.13-24.005v-11.2h12v11.2L50.94 63.867h.005ZM42 80.632v-12h12v12H42Z"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2315_17265"
        x1="18.865"
        x2="63.305"
        y1="28.333"
        y2="105.308"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D9FF80" />
        <stop offset=".25" stopColor="#BFE736" />
        <stop offset=".75" stopColor="#006AFF" />
      </linearGradient>
    </defs>
  </svg>
);

export default AlertIcon;
