import {
  getActivateExperiment,
  getExperiments,
} from '@squareup/browser-experiments';
import {
  Result,
  Attributes,
} from '@squareup/browser-experiments/dist/types/types/types';
import { makeAutoObservable } from 'mobx';
import type MessengerController from 'src/MessengerController';
import { Environment } from 'src/MessengerTypes';
import { getEnvironment } from 'src/utils/initUtils';
import {
  ExperimentName,
  MOBILE_EXPERIMENT_NAMES,
  DASHBOARD_EXPERIMENT_NAMES,
} from 'src/utils/experimentUtils';
import { CDP_PRODUCTION_API_KEY, CDP_STAGING_API_KEY } from './sharedKeys';

type ProjectEnvironment = 'dashboard' | 'mobile';

// Type of each item in the result of getActivateExperiment
type GetActivateExperimentResult = Result & { variantName: string | undefined };

// Type of each item in the result of getExperiments
type ExperimentResult = Result & {
  experimentName: string;
  variantName: string | undefined;
};

// Dashboard Optimizely Full Stack project
// See https://app.optimizely.com/v2/projects/16952400626
const DASHBOARD_PROJECT_ID = '16952400626';

// Mobile Optimizely Full Stack project
// See https://app.optimizely.com/v2/projects/17149011059
const MOBILE_PROJECT_ID = '17149011059';

/**
 * Store responsible for managing the state of Optimizely experiments for
 * A/B testing.
 * Uses the browser-experiments library to fetch and activate experiments.
 * A user can be bucketed into an experiment variant (activation), if the user's
 * attributes meet the audience criteria for the experiment.
 *
 * For experiments shared between mobile and web platforms, use the Mobile
 * Optimizely Full Stack project. For experiments specific to web, use the
 * Dashboard Optimizely Full Stack project.
 */
export default class ExperimentStore {
  private _stores: MessengerController;
  private _env: Environment;

  // Map of experiments to the variants the user has been bucketed into.
  // Only activated experiments are stored here.
  activatedExperiments: {
    [key in ExperimentName]?: string;
  } = {};

  constructor(stores: MessengerController) {
    makeAutoObservable(this);

    this._stores = stores;
    this._env = getEnvironment();
  }

  /**
   * Wrapper around BrowserExperiment's getExperiments method:
   * https://github.com/squareup/browser-experiments?tab=readme-ov-file#getexperimentsoptions-getexperimentsoptions--promise-success-boolean-experimentname-string-variantname-string-error--type-string-message-string--
   * Fetches experiments without triggering an impression or activation.
   * Can be used for debugging purposes as it does not impact metrics.
   * Returns the variant that the user would be bucketed into, but does not
   * actually bucket (or "activate") the experiment for the user.
   *
   * @param {ProjectEnvironment} projectEnv
   * Whether to fetch experiments from the Mobile Optimizely Full Stack project,
   * or the Dashboard Optimizely Full Stack project.
   * @param {Attributes} [userAttributes]
   * (Optional) Attributes to pass to the Optimizely SDK that would qualify the
   * user for the experiment. It is important to note that if these do not satisfy
   * the Audiences criteria specified for the experiment, the user will not be
   * bucketed into the experiment.
   * @returns {Promise<ExperimentResult[]>}
   */
  getExperiments = async (
    projectEnv: ProjectEnvironment,
    userAttributes?: Attributes,
  ): Promise<ExperimentResult[]> => {
    const mobileProject = projectEnv === 'mobile';
    const experiments = await getExperiments({
      experimentNames: mobileProject
        ? [...MOBILE_EXPERIMENT_NAMES]
        : [...DASHBOARD_EXPERIMENT_NAMES],
      token: this._stores.user.merchantToken,
      projectId: mobileProject ? MOBILE_PROJECT_ID : DASHBOARD_PROJECT_ID,
      env: this._env,
      userAttributes,
    });
    return experiments;
  };

  /**
   * Returns the variant the user is bucketed into, for the given experiment
   * and user attributes.
   *
   * Calls BrowserExperiment's getActivateExperiment method under the hood:
   * https://github.com/squareup/browser-experiments?tab=readme-ov-file#getactivateexperimentoptions-getactivateexperimentoptions--promise-success-boolean-variantname-string-error--type-string-message-string--
   * Retrieves variations for a requested experiment and buckets the user into
   * the experiment.
   * If the request was unsuccessful, a "Record Exclusion" event is sent to the CDP source.
   * For a given user, experiment, and user attributes, an impression is triggered
   * once every 30 days, and the user will remain bucketed into the same variant.
   *
   * @param {ExperimentName} experimentName
   * Name of the experiment to activate.
   * @param {Attributes} [userAttributes]
   * (Optional) Attributes to pass to the Optimizely SDK that would qualify the
   * user for the experiment. It is important to note that if these do not satisfy
   * the Audiences criteria specified for the experiment, the user will not be
   * bucketed into the experiment.
   * @returns {Promise<string | undefined>}
   * Returns a promise that resolves to the name of the variant that the user was
   * bucketed into (if successful), or `undefined` if the user did not qualify.
   */
  getVariationForExperiment = async (
    experimentName: ExperimentName,
    userAttributes?: Attributes,
  ): Promise<GetActivateExperimentResult> => {
    const bucket = this.activatedExperiments[experimentName];
    if (bucket)
      return {
        success: true,
        variantName: bucket,
      };

    const mobileProject = ([...MOBILE_EXPERIMENT_NAMES] as string[]).includes(
      experimentName,
    );
    const result = await getActivateExperiment({
      experimentName,
      token: this._stores.user.merchantToken,
      projectId: mobileProject ? MOBILE_PROJECT_ID : DASHBOARD_PROJECT_ID,
      cdpKey:
        this._env === 'production'
          ? CDP_PRODUCTION_API_KEY
          : CDP_STAGING_API_KEY,
      env: this._env,
      userAttributes,
    });
    const { success, variantName } = result;
    if (success) {
      this.activatedExperiments[experimentName] = variantName;
    }
    return result;
  };
}
