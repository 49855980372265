import React, { cloneElement, ReactElement, useEffect } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { MarketButton } from 'src/components/Market';
import Sound from 'src/stores/objects/Sound';
import SoundControlsContainer from 'src/components/SoundControlsContainer/SoundControlsContainer';
import SoundScrubber from 'src/components/SoundScrubber/SoundScrubber';
import PlayIcon from 'src/svgs/PlayIcon';
import PauseIcon from 'src/svgs/PauseIcon';
import './SoundPlayer.scss';

export type SoundPlayerProps = {
  sound: Sound;
  soundLoadErrorText: string;
  onPlay?: () => void;
  onPause?: () => void;
  onScrub?: () => void;
  className?: string;
  trailingAccessory?: ReactElement;
};

/**
 * Component that renders a set of controls to play, pause, and scrub a provided sound.
 *
 * @param {Sound} sound
 * The object representing the sound for this player.
 * @param {string} soundLoadErrorText
 * The translated error text displayed when there is a load error for the provided sound object.
 * @param {() => void} [onPlay]
 * (Optional) Callback executed when the player is played.
 * @param {() => void} [onPause]
 * (Optional) Callback executed when the player is paused.
 * @param {() => void} [onScrub]
 * (Optional) Callback executed when the player is scrubbed.
 * @param {string} [className]
 * (Optional) Classname added to the root container to allow custom styling.
 * @param {ReactElement} [trailingAccessory]
 * (Optional) Custom element to appear to the right of the slider.
 */
const SoundPlayer = observer(
  ({
    sound,
    soundLoadErrorText,
    onPlay,
    onPause,
    onScrub,
    className,
    trailingAccessory,
  }: SoundPlayerProps): ReactElement => {
    useEffect(() => {
      return () => {
        sound.stop();
      };
    }, [sound]);

    const handlePlay = (): void => {
      sound.play();
      onPlay?.();
    };
    const handlePause = (): void => {
      sound.pause();
      onPause?.();
    };

    const isError = sound.status === 'ERROR';

    return (
      <SoundControlsContainer
        isError={isError}
        errorText={soundLoadErrorText}
        className={className}
      >
        <MarketButton
          className={classNames('SoundPlayer__play-button', {
            'SoundPlayer__disabled-button': isError,
          })}
          onClick={sound.isPlaying ? handlePause : handlePlay}
          isLoading={sound.status === 'LOADING' || undefined}
          disabled={isError || undefined}
          rank="tertiary"
          size="small"
        >
          {sound.isPlaying ? (
            <PauseIcon slot="icon" />
          ) : (
            <PlayIcon slot="icon" />
          )}
        </MarketButton>
        <SoundScrubber sound={sound} onScrub={onScrub} />
        {trailingAccessory &&
          cloneElement(trailingAccessory, {
            disabled: isError || undefined,
          })}
      </SoundControlsContainer>
    );
  },
);

export default SoundPlayer;
