/**
 * This icon is used in a button and should be used in navigation
 * where we want to close the component we are on.
 */

import React, { ReactElement } from 'react';

type CloseIconProps = {
  isSmall?: boolean;
};

function CloseIcon(props: CloseIconProps): ReactElement {
  const length = props.isSmall ? '15' : '20';

  return (
    <svg
      width={length}
      height={length}
      viewBox="10 10 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="CloseIcon"
    >
      <title>Close</title>
      <path
        d="M12 12L28 28"
        stroke="#71767B"
        strokeWidth="2"
        className="svg-icon__stroke"
      />
      <path
        d="M28 12L12 28"
        stroke="#71767B"
        strokeWidth="2"
        className="svg-icon__stroke"
      />
    </svg>
  );
}

export default CloseIcon;
