/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';
import '../common/time';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "api": {
        "nested": {
          "items": {
            "nested": {
              "OnlineStoreItemData": {
                "fields": {
                  "version": {
                    "type": "int32",
                    "id": 1,
                    "rule": "optional"
                  },
                  "type": {
                    "type": "squareup.api.items.OnlineStoreItemData.OnlineStoreItemType",
                    "id": 2,
                    "rule": "optional"
                  },
                  "eventData": {
                    "type": "squareup.api.items.OnlineStoreEventData",
                    "id": 3,
                    "rule": "optional"
                  },
                  "donationData": {
                    "type": "squareup.api.items.OnlineStoreDonationData",
                    "id": 4,
                    "rule": "optional"
                  },
                  "pickupData": {
                    "type": "squareup.api.items.OnlineStorePickupData",
                    "id": 5,
                    "rule": "optional"
                  },
                  "optedOutForAutoSharing": {
                    "type": "bool",
                    "id": 6,
                    "rule": "optional"
                  }
                },
                "nested": {
                  "OnlineStoreItemType": {
                    "values": {
                      "UNKNOWN_DO_NOT_SET": 0,
                      "GENERIC": 1,
                      "EVENT": 2,
                      "SERVICE": 3,
                      "DONATION": 4,
                      "GIFT_CARD": 5
                    }
                  }
                }
              },
              "OnlineStoreEventData": {
                "fields": {
                  "startTime": {
                    "type": "squareup.common.time.DateTime",
                    "id": 1,
                    "rule": "optional"
                  },
                  "endTime": {
                    "type": "squareup.common.time.DateTime",
                    "id": 2,
                    "rule": "optional"
                  },
                  "location": {
                    "type": "string",
                    "id": 3,
                    "rule": "optional"
                  },
                  "admissionTime": {
                    "type": "squareup.common.time.DateTime",
                    "id": 4,
                    "rule": "optional"
                  },
                  "venue": {
                    "type": "string",
                    "id": 5,
                    "rule": "optional"
                  }
                }
              },
              "OnlineStoreDonationData": {
                "fields": {
                  "targetAmountCents": {
                    "type": "int32",
                    "id": 1,
                    "rule": "optional"
                  }
                }
              },
              "OnlineStorePickupData": {
                "fields": {
                  "pickupAddress": {
                    "type": "string",
                    "id": 1,
                    "rule": "optional"
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.api?.nested?.items?.nested?.OnlineStoreItemData && !$root.nested?.squareup?.nested?.api?.nested?.items?.nested?.OnlineStoreItemData?.nested?.OnlineStoreItemType && !$root.nested?.squareup?.nested?.api?.nested?.items?.nested?.OnlineStoreEventData && !$root.nested?.squareup?.nested?.api?.nested?.items?.nested?.OnlineStoreDonationData && !$root.nested?.squareup?.nested?.api?.nested?.items?.nested?.OnlineStorePickupData) {
	$root.addJSON(jsonData);
}

/**
 * SOURCE OnlineStoreItemData @ squareup/items/onlinestore.proto at 10:1
 */
export const OnlineStoreItemData = $root.lookupType('squareup.api.items.OnlineStoreItemData');
fixType(OnlineStoreItemData);
Builder.createAndAttachToType(OnlineStoreItemData);
/**
 * Clients must handle future unknown new enum values gracefully with default behavior.
 *
 * SOURCE OnlineStoreItemType @ squareup/items/onlinestore.proto at 16:3
 */
OnlineStoreItemData.OnlineStoreItemType = $root.lookupEnum('squareup.api.items.OnlineStoreItemData.OnlineStoreItemType').values;
/**
 * SOURCE OnlineStoreEventData @ squareup/items/onlinestore.proto at 33:1
 */
export const OnlineStoreEventData = $root.lookupType('squareup.api.items.OnlineStoreEventData');
fixType(OnlineStoreEventData);
Builder.createAndAttachToType(OnlineStoreEventData);
/**
 * SOURCE OnlineStoreDonationData @ squareup/items/onlinestore.proto at 43:1
 */
export const OnlineStoreDonationData = $root.lookupType('squareup.api.items.OnlineStoreDonationData');
fixType(OnlineStoreDonationData);
Builder.createAndAttachToType(OnlineStoreDonationData);
/**
 * NOT USED AT ALL
 *
 * SOURCE OnlineStorePickupData @ squareup/items/onlinestore.proto at 48:1
 */
export const OnlineStorePickupData = $root.lookupType('squareup.api.items.OnlineStorePickupData');
fixType(OnlineStorePickupData);
Builder.createAndAttachToType(OnlineStorePickupData);
