/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../Fixes';
import Builder from '../../../Builder';
import '../../roster/mds';
import '../../common/time';
import '../../bills/cart';
import '../../rolodex/data';
import '../common/model';
import '../../common/location';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "appointments": {
        "nested": {
          "merchant": {
            "nested": {
              "LocationType": {
                "values": {
                  "PHONE": 1,
                  "PREMISES": 2,
                  "CUSTOMER": 3,
                  "VIDEO_CALL": 4
                }
              },
              "ReservationStatusCode": {
                "values": {
                  "UNKNOWN": 0,
                  "PENDING": 1,
                  "DECLINED": 2,
                  "ACCEPTED": 3,
                  "CANCELLED_BY_BUYER": 4,
                  "CANCELLED_BY_SELLER": 5,
                  "NO_SHOW": 6,
                  "TEMPORARY": 7
                }
              },
              "InstrumentState": {
                "values": {
                  "UNKNOWN_STATE": 0,
                  "NOT_CHARGED": 1,
                  "CHARGE_IN_PROGRESS": 2,
                  "CHARGED": 3,
                  "FAILED_TO_CHARGE": 4,
                  "CHARGE_FAILED_RETRIABLE": 5,
                  "UNKNOWN_INSTRUMENT_STATE": 6,
                  "CANCELLED": 7,
                  "EXPIRED": 8,
                  "PRE_AUTHORIZED": 9,
                  "REFUNDED": 10
                }
              },
              "PaymentType": {
                "values": {
                  "NO_SHOW_PAYMENT": 1,
                  "PREPAYMENT": 2,
                  "CHECKOUT": 3,
                  "DEPOSIT": 4
                }
              },
              "Staff": {
                "fields": {
                  "id": {
                    "type": "string",
                    "id": 1,
                    "rule": "optional"
                  },
                  "employee": {
                    "type": "squareup.roster.mds.Employment",
                    "id": 2,
                    "rule": "optional"
                  }
                }
              },
              "Instrument": {
                "fields": {
                  "depositAmountCents": {
                    "type": "int32",
                    "id": 1,
                    "rule": "optional"
                  },
                  "state": {
                    "type": "squareup.appointments.merchant.InstrumentState",
                    "id": 2,
                    "rule": "optional"
                  },
                  "lastFourOfPan": {
                    "type": "string",
                    "id": 3,
                    "rule": "optional"
                  },
                  "enforcementPeriodStart": {
                    "type": "squareup.common.time.DateTime",
                    "id": 4,
                    "rule": "optional"
                  },
                  "enforcementPeriodEnd": {
                    "type": "squareup.common.time.DateTime",
                    "id": 5,
                    "rule": "optional"
                  },
                  "paymentType": {
                    "type": "squareup.appointments.merchant.PaymentType",
                    "id": 6,
                    "rule": "optional"
                  }
                }
              },
              "Reservation": {
                "fields": {
                  "reservationId": {
                    "type": "string",
                    "id": 1,
                    "rule": "optional"
                  },
                  "schedule": {
                    "type": "squareup.appointments.merchant.Schedule",
                    "id": 2,
                    "rule": "optional"
                  },
                  "buyerNote": {
                    "type": "string",
                    "id": 5,
                    "rule": "optional"
                  },
                  "sellerNote": {
                    "type": "string",
                    "id": 6,
                    "rule": "optional"
                  },
                  "unitToken": {
                    "type": "string",
                    "id": 7,
                    "rule": "optional"
                  },
                  "location": {
                    "type": "squareup.appointments.merchant.Location",
                    "id": 8,
                    "rule": "optional"
                  },
                  "transitionTime": {
                    "type": "int32",
                    "id": 9,
                    "rule": "optional"
                  },
                  "statusCode": {
                    "type": "squareup.appointments.merchant.ReservationStatusCode",
                    "id": 10,
                    "rule": "optional"
                  },
                  "cart": {
                    "type": "squareup.bills.Cart",
                    "id": 11,
                    "rule": "optional"
                  },
                  "createdAt": {
                    "type": "squareup.common.time.DateTime",
                    "id": 12,
                    "rule": "optional"
                  },
                  "staff": {
                    "type": "squareup.appointments.merchant.Staff",
                    "id": 13,
                    "rule": "optional"
                  },
                  "customer": {
                    "type": "squareup.rolodex.Contact",
                    "id": 14,
                    "rule": "optional"
                  },
                  "instrumentPrepayment": {
                    "type": "squareup.appointments.merchant.Instrument",
                    "id": 15,
                    "rule": "repeated"
                  },
                  "instrumentNoShow": {
                    "type": "squareup.appointments.merchant.Instrument",
                    "id": 16,
                    "rule": "repeated"
                  },
                  "instrumentCheckout": {
                    "type": "squareup.appointments.merchant.Instrument",
                    "id": 17,
                    "rule": "repeated"
                  },
                  "contractTokens": {
                    "type": "string",
                    "id": 18,
                    "rule": "repeated"
                  },
                  "reservationSegments": {
                    "type": "squareup.appointments.common.ReservationSegment",
                    "id": 19,
                    "rule": "repeated"
                  },
                  "creatorStaff": {
                    "type": "squareup.appointments.merchant.Staff",
                    "id": 3
                  },
                  "creatorCustomer": {
                    "type": "squareup.rolodex.Contact",
                    "id": 4
                  }
                },
                "nested": {
                  "creator": {
                    "oneof": [
                      "creatorStaff",
                      "creatorCustomer"
                    ]
                  }
                }
              },
              "Schedule": {
                "fields": {
                  "dateStart": {
                    "type": "squareup.common.time.DateTime",
                    "id": 1,
                    "rule": "optional"
                  },
                  "dateEnd": {
                    "type": "squareup.common.time.DateTime",
                    "id": 2,
                    "rule": "optional"
                  },
                  "isAllDay": {
                    "type": "bool",
                    "id": 3,
                    "rule": "optional"
                  },
                  "reservationIdFirstOccurrence": {
                    "type": "string",
                    "id": 4,
                    "rule": "optional"
                  },
                  "rrule": {
                    "type": "string",
                    "id": 5,
                    "rule": "optional"
                  },
                  "exdates": {
                    "type": "string",
                    "id": 6,
                    "rule": "optional"
                  },
                  "tzid": {
                    "type": "string",
                    "id": 7,
                    "rule": "optional"
                  }
                }
              },
              "Location": {
                "fields": {
                  "locationType": {
                    "type": "squareup.appointments.merchant.LocationType",
                    "id": 1,
                    "rule": "optional"
                  },
                  "address": {
                    "type": "squareup.common.location.Address",
                    "id": 2,
                    "rule": "optional"
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.appointments?.nested?.merchant?.nested?.LocationType && !$root.nested?.squareup?.nested?.appointments?.nested?.merchant?.nested?.ReservationStatusCode && !$root.nested?.squareup?.nested?.appointments?.nested?.merchant?.nested?.InstrumentState && !$root.nested?.squareup?.nested?.appointments?.nested?.merchant?.nested?.PaymentType && !$root.nested?.squareup?.nested?.appointments?.nested?.merchant?.nested?.Staff && !$root.nested?.squareup?.nested?.appointments?.nested?.merchant?.nested?.Instrument && !$root.nested?.squareup?.nested?.appointments?.nested?.merchant?.nested?.Reservation && !$root.nested?.squareup?.nested?.appointments?.nested?.merchant?.nested?.Schedule && !$root.nested?.squareup?.nested?.appointments?.nested?.merchant?.nested?.Location) {
	$root.addJSON(jsonData);
}

/**
 * To generate protos after editing:
 * $ bundle exec rake sq:protos:compile
 * Reservation Model and relationships belonging to the Reservation.
 *
 * SOURCE LocationType @ squareup/appointments/merchant/model.proto at 22:1
 */
export const LocationType = $root.lookupEnum('squareup.appointments.merchant.LocationType').values;
/**
 * SOURCE ReservationStatusCode @ squareup/appointments/merchant/model.proto at 36:1
 */
export const ReservationStatusCode = $root.lookupEnum('squareup.appointments.merchant.ReservationStatusCode').values;
/**
 * SOURCE InstrumentState @ squareup/appointments/merchant/model.proto at 87:1
 */
export const InstrumentState = $root.lookupEnum('squareup.appointments.merchant.InstrumentState').values;
/**
 * SOURCE PaymentType @ squareup/appointments/merchant/model.proto at 122:1
 */
export const PaymentType = $root.lookupEnum('squareup.appointments.merchant.PaymentType').values;
/**
 * SOURCE Staff @ squareup/appointments/merchant/model.proto at 136:1
 */
export const Staff = $root.lookupType('squareup.appointments.merchant.Staff');
fixType(Staff);
Builder.createAndAttachToType(Staff);
/**
 * SOURCE Instrument @ squareup/appointments/merchant/model.proto at 141:1
 */
export const Instrument = $root.lookupType('squareup.appointments.merchant.Instrument');
fixType(Instrument);
Builder.createAndAttachToType(Instrument);
/**
 * SOURCE Reservation @ squareup/appointments/merchant/model.proto at 223:1
 */
export const Reservation = $root.lookupType('squareup.appointments.merchant.Reservation');
fixType(Reservation);
Builder.createAndAttachToType(Reservation);
/**
 * SOURCE Schedule @ squareup/appointments/merchant/model.proto at 291:1
 */
export const Schedule = $root.lookupType('squareup.appointments.merchant.Schedule');
fixType(Schedule);
Builder.createAndAttachToType(Schedule);
/**
 * SOURCE Location @ squareup/appointments/merchant/model.proto at 317:1
 */
export const Location = $root.lookupType('squareup.appointments.merchant.Location');
fixType(Location);
Builder.createAndAttachToType(Location);
