import React, { ReactElement, ReactNode } from 'react';
import { observer } from 'mobx-react';
import { Utterance } from 'src/gen/squareup/messenger/v3/messenger_service';
import {
  getUtterancePreviewFromTranscript,
  getUtterancePreviewText,
  isFailedSendStatus,
} from 'src/utils/transcriptUtils';
import { UtterancePreview } from 'src/MessengerTypes';
import Logger from 'src/Logger';
import Transcript from 'src/stores/objects/Transcript';
import MessengerListItem from 'src/components/MessengerListItem/MessengerListItem';

export type TranscriptsListItemProps = {
  transcript: Transcript;
  accessory: ReactNode;
  title: string;
  onClick: () => void;
  isSelected: boolean;
  className?: string;
};

/**
 * The fixed-height component that displays an individual's
 * transcript preview. It should contain a timestamped
 * recent utterance, a read/unread indicator, a profile
 * abbreviation, and a buyer name.
 *
 * @example <TranscriptsListItem transcript={this.transcripts.get(id)} />
 * @param {Transcript} transcript
 * Data source of what to display in the list item.
 * @param {ReactNode} accessory
 * The accessory node to render in the internal market row.
 * @param {string} title
 * The title to display for the transcript list item.
 * @param {() => void} onClick
 * Callback function to be called when the row is clicked.
 * @param {boolean} isSelected
 * Flag indicating if the row is styled as selected.
 * @param {string} [className]
 * Class name to apply to the root element of the row.
 */
const TranscriptsListItem = observer(
  ({
    transcript,
    accessory,
    title,
    onClick,
    isSelected,
    className,
  }: TranscriptsListItemProps): ReactElement => {
    const utterancePreview: UtterancePreview | null =
      getUtterancePreviewFromTranscript(transcript);

    const time = utterancePreview?.utterance?.spokenAtMillis || 0;
    const snippet = utterancePreview?.utterance
      ? getUtterancePreviewText(utterancePreview)
      : '';
    const sendStatus = utterancePreview?.utterance?.sendStatus;
    const previewIsFailed = Boolean(
      sendStatus && isFailedSendStatus(sendStatus),
    );
    const isUnread = utterancePreview?.isUnread || false;
    const hasUnreadMissedCall =
      utterancePreview?.utterance.metadata?.inboundCall?.missedCallAction ===
        Utterance.Metadata.InboundCall.MissedCallAction.NO_ACTION && isUnread;

    // If the timestamp goes away, the whole app crashes
    // with a "fewer hooks rendered than expected" error. To alleviate the
    // issue, we always have a timestamp, but sometimes its value is Jan 1 1970
    // and it has visibility: hidden.
    const showTimestamp = time !== 0;
    // Note that the only reason showTimestamp would be false is if we didn't have
    // a preview utterance. This shouldn't happen,
    // but we don't want the app to crash if it does.
    if (!showTimestamp) {
      Logger.warn(`Hiding timestamp for transcript ${transcript.id}`);
    }

    const speakerType =
      utterancePreview?.utterance.speakerType ||
      Utterance.SpeakerType.SPEAKER_TYPE_UNRECOGNIZED;

    return (
      <MessengerListItem
        id={transcript.id}
        accessory={accessory}
        onClick={onClick}
        time={time}
        title={title}
        description={snippet}
        isAssistant={
          speakerType === Utterance.SpeakerType.SERVICE ||
          speakerType === Utterance.SpeakerType.BOT
        }
        isUnread={isUnread}
        isUrgent={previewIsFailed || hasUnreadMissedCall}
        isSelected={isSelected}
        className={className}
      />
    );
  },
);

export default TranscriptsListItem;
