import {
  GetUnitsInformationResponse,
  UnitInformation,
} from 'src/gen/squareup/messenger/v3/messenger_service';
import { SupportedCountry } from 'src/MessengerTypes';
import { i18nPhoneNumber } from 'src/i18n/formatValues';

// All valid fields to display
type ValidFieldForReview = Exclude<
  GetUnitsInformationResponse.FieldForReview,
  GetUnitsInformationResponse.FieldForReview.UNRECOGNIZED
>;

// A mapping of FieldForReview and UnitInformation value.
type FieldForReviewAndUnitInformation = {
  names: ValidFieldForReview[];
  value: string;
  labelKey: string;
};

/**
 * Transform UnitInformation into an array with properties needed for rendering.
 *
 * @param {UnitInformation} unitInformation
 * @param {SupportedCountry} countryCode
 * @returns {FieldForReviewAndUnitInformation[]}
 */
export const combineFieldForReviewAndUnitInformation = (
  unitInformation: UnitInformation,
  countryCode: SupportedCountry,
): FieldForReviewAndUnitInformation[] => {
  return [
    {
      names: [GetUnitsInformationResponse.FieldForReview.BUSINESS_NAME],
      value: unitInformation.businessName,
      labelKey: 'UnitsToVerifyPage.location_col_title',
    },
    {
      names: [GetUnitsInformationResponse.FieldForReview.ADDRESS],
      value: unitInformation.address
        ? Object.values(unitInformation.address)
            .filter((value) => value)
            .join(' ')
        : '',
      labelKey: 'UnitsToVerifyPage.address_col_title',
    },
    {
      names: [
        GetUnitsInformationResponse.FieldForReview.CONTACT_FIRST_NAME,
        GetUnitsInformationResponse.FieldForReview.CONTACT_LAST_NAME,
      ],
      value: `${unitInformation.contactFirstName} ${unitInformation.contactLastName}`,
      labelKey: 'UnitsToVerifyPage.contact_col_title',
    },
    {
      names: [GetUnitsInformationResponse.FieldForReview.CONTACT_PHONE_NUMBER],
      value:
        i18nPhoneNumber(unitInformation.contactPhoneNumber, countryCode) || '',
      labelKey: 'UnitsToVerifyPage.phone_col_title',
    },
    {
      names: [GetUnitsInformationResponse.FieldForReview.CONTACT_EMAIL],
      value: unitInformation.contactEmail,
      labelKey: 'UnitsToVerifyPage.email_col_title',
    },
    {
      names: [GetUnitsInformationResponse.FieldForReview.WEBSITE_URL],
      value: unitInformation.websiteUrl,
      labelKey: 'UnitsToVerifyPage.website_col_title',
    },
  ];
};
