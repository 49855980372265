import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Medium } from 'src/gen/squareup/messenger/v3/messenger_service';
import { MarketAccessory, MarketList, MarketRow } from 'src/components/Market';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import { onKeyDownEnter } from 'src/utils/keyboardUtils';
import CustomerImage from 'src/components/CustomerImage/CustomerImage';
import {
  amountToCurrencyString,
  currencyCodeToKey,
} from 'src/utils/moneyUtils';
import { getPrimaryDisplayContact } from 'src/utils/transcriptUtils';
import { MergeCustomersMessengerPage } from 'src/MessengerTypes';
import './CustomersList.scss';
import { Contact } from 'src/gen/squareup/messenger/v3/messenger_auxiliary_service';

export type CustomersListProps = {
  customers: Contact[];
};

/**
 * Renders the list of customers provided with their corresponding details.
 *
 * @param {Contact[]} customers
 * The customers and their metadata to display in the UI.
 */
const CustomersList = observer(
  ({ customers }: CustomersListProps): ReactElement => {
    const { navigation, transcripts, user, event } =
      useMessengerControllerContext();
    const { t } = useTranslation();

    const nav = navigation.tertiary.isOpen
      ? navigation.tertiary
      : navigation.primary;

    const page = nav.currentPage as MergeCustomersMessengerPage;
    const transcript = transcripts.get(page.transcriptId);

    return (
      <MarketList interactive className="CustomersList__list">
        {customers.map((customer) => {
          const email = getPrimaryDisplayContact(customer, Medium.EMAIL);
          const phone = getPrimaryDisplayContact(customer, Medium.SMS);
          return (
            <MarketRow
              key={customer.token}
              variant="drill"
              className="CustomersList__contact-row"
              onClick={() => {
                event.track('Click Customer in Merge Customers View');
                navigation.openCustomerDetailView(
                  transcript.id,
                  customer.token,
                );
              }}
              onKeyDown={(e) =>
                onKeyDownEnter(e, () => {
                  event.track('Click Customer in Merge Customers View');
                  navigation.openCustomerDetailView(
                    transcript.id,
                    customer.token,
                  );
                })
              }
            >
              <MarketAccessory slot="leading-accessory" size="image">
                <CustomerImage customerInitials={customer.initials ?? ''} />
              </MarketAccessory>
              <div slot="label">{customer.name}</div>
              <div slot="subtext" className="CustomersList__subtext">
                {phone && <div>{phone}</div>}
                {email && <div>{email}</div>}
                <div>
                  {`${t('CustomerDetailPage.transaction_count_label', {
                    transactionCount: customer.transactionCount,
                  })} | ${t('CustomerDetailPage.total_spend_label', {
                    amount: amountToCurrencyString(
                      customer.totalGpv?.amount || 0,
                      customer.totalGpv?.currency
                        ? currencyCodeToKey(customer.totalGpv.currency)
                        : user.currencyCode,
                      user.locale,
                    ),
                  })}`}
                </div>
              </div>
            </MarketRow>
          );
        })}
      </MarketList>
    );
  },
);

export default CustomersList;
