import React, { ReactElement, useState, useRef } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { Unit } from 'src/MessengerTypes';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import './DeveloperTools.scss';

export const developerToolsConsoleId = 'DeveloperTools__console';
type DeveloperToolsPage = 'CONSOLE' | 'USER';

/**
 * Displays information that has been logged in the console, such as request
 * and response of network calls. Objects that are logged will be represented by
 * nested collapsible elements. Otherwise, it is shown as a string.
 *
 * It enables testing to be much easier on dashboard as console logs from messenger
 * does not propagate to our host apps. This component work hand in hand with Logger.ts,
 * when every log instance will create an element containing the stringified message and
 * insert it into this component.
 *
 * This component is strictly used for testing and debugging purposes only and
 * should not be shown for real merchants. It is rendered in the <App /> level and
 * can be activated by adding merchant token to here:
 * https://app.launchdarkly.com/pie/staging/features/messenger-allow-dev-tools/targeting
 *
 * @author klim
 */
const DeveloperTools = observer((): ReactElement => {
  const { user, navigation } = useMessengerControllerContext();
  const [page, setPage] = useState<DeveloperToolsPage>('CONSOLE');
  const [useMonospace, setUseMonospace] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  // Combine all data we wish to display under the "Current User" tab:
  // 1. Dashboard current user fields, e.g. merchant token
  // 2. List of accessible unit tokens and their names
  const userDisplayFields = {
    merchantToken: user.merchantToken,
    mainUnitToken: user.mainUnitToken,
    countryCode: user.countryCode,
    currencyCode: user.currencyCode,
    locale: user.locale,
    businessName: user.businessName,
    timezone: user.timezone,
    currentEmployee: user.currentEmployee,
    unreadTranscriptCount: user.unreadTranscriptsCount,
    isAuthorized: user.isAuthorized,
    isAuthenticated: user.isAuthenticated,
    status: user.status,
    units: {} as Record<string, string>,
  };
  user.units.forEach((unit: Unit, unitToken: string) => {
    userDisplayFields.units[unitToken] = unit.name;
  });

  return (
    <div
      className={classNames('DeveloperTools__container', {
        DeveloperTools__monospace: useMonospace,
        DeveloperTools__hidden: !navigation.primary.isOpen,
      })}
      onClick={(e) => e.stopPropagation()}
      onKeyDown={(e) => e.stopPropagation()}
    >
      <div className="DeveloperTools__navigation">
        <div className="DeveloperTools__navigation__left">
          <input
            type="radio"
            className="DeveloperTools__navigation__item"
            id="DeveloperTools__navigation__console"
            checked={page === 'CONSOLE'}
            onClick={() => setPage('CONSOLE')}
            onChange={() => {
              /* placeholder to prevent no onChange warning on console */
            }}
          />
          <label htmlFor="DeveloperTools__navigation__console">Console</label>
          <input
            type="radio"
            className="DeveloperTools__navigation__item"
            id="DeveloperTools__navigation__user"
            checked={page === 'USER'}
            onClick={() => setPage('USER')}
            onChange={() => {
              /* placeholder to prevent no onChange warning on console */
            }}
          />
          <label htmlFor="DeveloperTools__navigation__user">Current User</label>
        </div>
        <div className="DeveloperTools__navigation__right">
          <input
            type="checkbox"
            id="DeveloperTools__navigation__monospace"
            checked={useMonospace}
            onChange={(e) => setUseMonospace(e.target.checked)}
          />
          <label htmlFor="DeveloperTools__navigation__monospace">
            Monospace
          </label>
          <button
            className="DeveloperTools__button"
            onClick={() => {
              if (ref && ref.current) {
                ref.current.innerHTML = '';
              }
            }}
          >
            Clear
          </button>
        </div>
      </div>
      <div
        className={classNames('DeveloperTools__body', {
          DeveloperTools__hidden: page !== 'CONSOLE',
        })}
        id={developerToolsConsoleId}
        ref={ref}
      />
      <div
        className={classNames('DeveloperTools__body', {
          DeveloperTools__hidden: page !== 'USER',
        })}
      >
        {JSON.stringify(userDisplayFields, null, 4)}
      </div>
    </div>
  );
});

export default DeveloperTools;
