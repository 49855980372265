/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../Fixes';
import Builder from '../../../Builder';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "xyz": {
    "nested": {
      "block": {
        "nested": {
          "onetable": {
            "nested": {
              "annotations": {
                "nested": {
                  "QueryField": {
                    "fields": {
                      "name": {
                        "type": "string",
                        "id": 1,
                        "rule": "optional"
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.xyz?.nested?.block?.nested?.onetable?.nested?.annotations?.nested?.QueryField) {
	$root.addJSON(jsonData);
}

/**
 * SOURCE QueryField @ xyz/block/onetable/annotations.proto at 38:1
 */
export const QueryField = $root.lookupType('xyz.block.onetable.annotations.QueryField');
fixType(QueryField);
Builder.createAndAttachToType(QueryField);
