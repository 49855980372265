import React, { ReactElement, ReactNode } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { MarketRow } from 'src/components/Market';
import { onKeyDownEnter } from 'src/utils/keyboardUtils';
import AssistantIcon from 'src/svgs/AssistantIcon';
import { useRelativeDateWithTimestamp } from 'src/utils/timeUtils';
import './MessengerListItem.scss';

export type MessengerListItemProps = {
  id: number;
  onClick: () => void;
  accessory?: ReactNode;
  time: number;
  title: string;
  description: ReactNode;
  isAssistant?: boolean;
  isUnread?: boolean;
  isUrgent?: boolean;
  isSelected?: boolean;
  className?: string;
};

/**
 * Generic list item component that renders the UI for a common list item that appears in Messages.
 * Typically, contains a title, accessory, description, timestamp, and unread/assistant indicator.
 *
 * @param {number} id
 * A unique identifier to attach to this list item.
 * @param {() => void} onClick
 * Callback to be executed when the item is clicked.
 * @param {ReactNode} [accessory]
 * Element to be inserted in the accessory slot of the row.
 * @param {number} time
 * The epoch time to display this row as.
 * @param {string} title
 * Text to display as the title of the row.
 * @param {ReactNode} description
 * Text to display as the description of the row.
 * @param {boolean} [isAssistant]
 * Flag indicating if this instance represents an assistant item.
 * @param {boolean} [isUnread]
 * Flag indicating if this instance represents an unread item.
 * @param {boolean} [isUrgent]
 * Flag indicating if this instance represents an urgent item.
 * @param {boolean} [isSelected]
 * Flag indicating if this instance represents a selected item.
 * @param {string} [className]
 * Classname that will be added to the root element that makes up this component.
 */
const MessengerListItem = observer(
  ({
    id,
    onClick,
    accessory,
    time,
    title,
    description,
    isAssistant,
    isUnread,
    isUrgent,
    isSelected,
    className,
  }: MessengerListItemProps): ReactElement => {
    const [relativeDate, timestamp] = useRelativeDateWithTimestamp(time, {
      abbreviated: true,
      omitDateForToday: true,
    });

    const timestampElement = (
      <div
        slot="side-label"
        className={classNames('MessengerListItem__timestamp', {
          'MessengerListItem__timestamp--unread': isUnread,
        })}
      >
        {relativeDate ?? timestamp}
      </div>
    );

    const isInlineTimestamp = !(isAssistant || isUnread);

    return (
      <MarketRow
        className={classNames('MessengerListItem', className, {
          'MessengerListItem__full-width': isInlineTimestamp,
        })}
        data-testid={`MessengerListItem__${id}`}
        data-cy="MessengerListItem"
        onClick={onClick}
        onKeyDown={(e) => onKeyDownEnter(e, onClick)}
        tabIndex={0}
        selected={isSelected || undefined}
      >
        {accessory}
        <div
          slot="label"
          className={classNames('MessengerListItem__name', {
            'MessengerListItem__name--unread': isUnread,
          })}
          title={title}
          data-testid="MessengerListItem__name"
        >
          <div className="MessengerListItem__title">{title}</div>
          {isInlineTimestamp && time !== 0 && timestampElement}
        </div>
        <div
          slot="subtext"
          className={classNames('MessengerListItem__preview', {
            'MessengerListItem__preview--unread': isUnread,
            'MessengerListItem__preview--urgent': isUrgent,
          })}
        >
          {description}
        </div>
        {!isInlineTimestamp && time !== 0 && timestampElement}
        {!isInlineTimestamp && (
          <div slot="side-subtext">
            {isAssistant && (
              <div
                className="MessengerListItem__automated-icon"
                data-testid="MessengerListItem__automated-icon"
              >
                <AssistantIcon />
              </div>
            )}
            {!isAssistant && isUnread && (
              <div
                className="MessengerListItem__unread"
                data-testid="MessengerListItem__unread"
              />
            )}
          </div>
        )}
      </MarketRow>
    );
  },
);

export default MessengerListItem;
