/**
 * A copy icon.
 */
import React, { ReactElement } from 'react';

function CopyIcon(): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
    >
      <title>Copy</title>
      <path
        fill="#000"
        fillOpacity=".9"
        fillRule="evenodd"
        d="M7 2h8a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1ZM4 3a3 3 0 0 1 3-3h8a3 3 0 0 1 3 3v8a3 3 0 0 1-3 3h-1v1a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V7a3 3 0 0 1 3-3h1V3Zm0 3H3a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1H7a3 3 0 0 1-3-3V6Z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default CopyIcon;
