import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import './GeneralEventBanner.scss';
import { MarketAccessory, MarketRow } from 'src/components/Market';

export type GeneralEventBannerRowProps = {
  icon: ReactElement;
  title: string;
  subtitle: string;
  subtext?: string;
  onClick?: () => void;
  variant?: 'APPOINTMENT_EVENT' | 'CUSTOMER_DETAIL_EVENT';
};

/**
 * Component that renders an individual item in an overlay banner. Intended to be used
 * as a child of the GeneralEventBanner component.
 *
 * @param {ReactElement} icon
 * The icon to display in the row.
 * @param {string} title
 * The title of the row.
 * @param {string} subtitle
 * The title to display on the right of the row.
 * @param {string} [subtext]
 * Secondary text to display below the title.
 * @param {() => void} [onClick]
 * Callback function that is called when the row is clicked.
 * @param {'APPOINTMENT_EVENT' | 'CUSTOMER_DETAIL_EVENT'} [variant]
 * The variant of the row. This is used for differentiating the rows in testing.
 */
const GeneralEventBannerRow = observer(
  ({
    icon,
    title,
    subtitle,
    subtext,
    onClick,
    variant,
  }: GeneralEventBannerRowProps): ReactElement => {
    return (
      <MarketRow
        variant="drill"
        onClick={onClick}
        data-testid="GeneralEventBanner"
        data-cy={`GeneralEventBanner-${variant}`}
        interactive
        transient
      >
        <MarketAccessory slot="leading-accessory" size="icon">
          {icon}
        </MarketAccessory>
        <label slot="label">{title}</label>
        {subtext && <div slot="subtext">{subtext}</div>}
        <label slot="side-label">{subtitle}</label>
      </MarketRow>
    );
  },
);

export default GeneralEventBannerRow;
