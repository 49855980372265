/**
 * An location pin used for google reviews settings.
 */
import React, { ReactElement } from 'react';

function LocationPinIcon(props: { color?: string }): ReactElement {
  const { color } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="20"
      fill="none"
      viewBox="0 0 14 20"
    >
      <title>Location</title>
      <path
        fill={color || '#000'}
        fillOpacity=".9"
        fillRule="evenodd"
        d="M7 0c3.86 0 7 3.14 7 7 0 3.52-2.61 6.44-6 6.92V20H6v-6.08c-3.39-.49-6-3.4-6-6.92 0-3.86 3.14-7 7-7ZM2 7c0 2.76 2.24 5 5 5s5-2.24 5-5-2.24-5-5-5-5 2.24-5 5Zm6.5 0a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default LocationPinIcon;
