import {
  CheckoutLink,
  CheckoutLinkLimits,
  GraphQLCheckoutLinkResponse,
  SettingsInfoResponse,
} from 'src/MessengerTypes';
import { ExecuteGraphQLRequest } from 'src/gen/squareup/messenger/v3/messenger_service';
import Services from 'src/services/Services';
import { callV3Rpc } from 'src/utils/apiUtils';
import { checkoutLinkGraphQLToProto } from 'src/utils/transcriptUtils';
import { settingsInfoUrl } from 'src/utils/url';
import Logger from 'src/Logger';

/**
 * Min and max allowable checkout link amounts for USD and CAD.
 */
export const MIN_CHECKOUT_LINK_AMOUNT = 100;
export const MAX_CHECKOUT_LINK_AMOUNT = 5000000;

/**
 * Api relating to checkout links, which is powered by Weebly.
 */
export default class WeeblyApi {
  private _services: Services;

  constructor(services: Services) {
    this._services = services;
  }

  /**
   * Make a request via GraphQL to get all the logged-in user's reusable item checkout links.
   *
   * @param {string} [cursor]
   */
  listCheckoutLinks = async (
    cursor?: string,
  ): Promise<[CheckoutLink[], string | undefined]> => {
    let args = '';
    if (cursor) {
      // The first query param is for a search query, which can be added
      // at a later time
      args = `('', ${cursor})`;
    }

    const response = await callV3Rpc({
      name: 'ExecuteGraphQL - ListCheckoutLinks',
      rpc: (x) => this._services.messagesV3.executeGraphQL(x),
      request: ExecuteGraphQLRequest.create({
        query: `{
            checkoutLinks${args} {
              checkoutLinks {
                type
                id
                url
                imageUrl
                title
                amount {
                  amount
                  currency
                }
              }
            }
          }`,
      }),
    });

    if (response == null || response.response == null) {
      throw new Error('No response received when fetching checkout links');
    }

    const graphql = JSON.parse(response.response);
    if (
      graphql.data &&
      graphql.data.checkoutLinks &&
      graphql.data.checkoutLinks.checkoutLinks
    ) {
      const nextCursor = graphql.data.checkoutLinks.nextCursor;
      const checkoutLinks = graphql.data.checkoutLinks.checkoutLinks.map(
        (rawCheckoutLink: GraphQLCheckoutLinkResponse) => {
          const checkoutLink: GraphQLCheckoutLinkResponse = {
            type: rawCheckoutLink.type,
            id: rawCheckoutLink.id,
            url: rawCheckoutLink.url,
            // The following fields come back with a value, or with null, or not present.
            // We standardize to `undefined` for all of them if they are either null or
            // not present.
            amount: rawCheckoutLink.amount ?? undefined,
            imageUrl: rawCheckoutLink.imageUrl ?? undefined,
            title: rawCheckoutLink.title ?? undefined,
          };
          return checkoutLinkGraphQLToProto(checkoutLink);
        },
      );

      return [checkoutLinks, nextCursor];
    } else {
      throw new Error('Unable to parse response when fetching checkout links');
    }
  };

  /**
   * Resolves to the minimum and maximum allowable checkout link amounts.
   *
   * @param {string} merchantToken
   * @returns {CheckoutLinkLimits}
   */
  getCheckoutLinkLimits = async (
    merchantToken: string,
  ): Promise<CheckoutLinkLimits> => {
    try {
      const response: SettingsInfoResponse =
        await this._services.fetchParsedJson(settingsInfoUrl(merchantToken));

      return {
        minAmount: response.settings_info.currency_limits.min_amount.amount,
        maxAmount: response.settings_info.currency_limits.max_amount.amount,
      };
    } catch (error) {
      Logger.logWithSentry('Failed to fetch checkout link limits', 'error', {
        error,
      });

      // Return sensible defaults, even though we couldn't hit the endpoint to check the checkout
      // link limits.
      // NOTE(eblaine): In a small percentage of cases (~0.1% or smaller), the browser blocks
      // this request. After some investigation, I suspect it's due to AdBlock or some browser
      // setting, since Weebly is a 3rd party site.
      return {
        minAmount: MIN_CHECKOUT_LINK_AMOUNT,
        maxAmount: MAX_CHECKOUT_LINK_AMOUNT,
      };
    }
  };
}
