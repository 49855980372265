import React, { ReactElement, ReactNode } from 'react';
import { observer } from 'mobx-react';
import './GeneralEventBanner.scss';

export type GeneralEventBannerProps = {
  children: ReactNode;
};

/**
 * Banner that overlays the Transcript View Page. Contains info such as
 * upcoming appointments or customer details. It should appear sticky to
 * the top in a transcript view page. Multiple GeneralEventBannerRow components
 * can be used as children.
 */
const GeneralEventBanner = observer(
  ({ children }: GeneralEventBannerProps): ReactElement => (
    <div className="GeneralEventBanner">{children}</div>
  ),
);

export default GeneralEventBanner;
