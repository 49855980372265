/**
 * An icon representing coupon.
 */
import React, { ReactElement } from 'react';

function EstimateIcon(): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <title>Estimate</title>
      <path
        fill="#000"
        fillOpacity=".9"
        d="M13 2H8C6.34 2 5 3.34 5 5v14c0 1.66 1.34 3 3 3h8c1.66 0 3-1.34 3-3V8l-6-6Zm3.17 6H13V4.83L16.17 8ZM17 19c0 .55-.45 1-1 1H8c-.55 0-1-.45-1-1V5c0-.55.45-1 1-1h3v6h6v9Z"
      />
    </svg>
  );
}

export default EstimateIcon;
