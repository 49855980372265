/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../Fixes';
import Builder from '../../../Builder';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "appointments": {
        "nested": {
          "merchant": {
            "nested": {
              "ClientMessage": {
                "fields": {
                  "customClientMessageBody": {
                    "type": "string",
                    "id": 1,
                    "rule": "optional"
                  },
                  "sendSms": {
                    "type": "bool",
                    "id": 2,
                    "rule": "optional"
                  },
                  "sendEmail": {
                    "type": "bool",
                    "id": 3,
                    "rule": "optional"
                  },
                  "sendCustomClientMessageBodyToClient": {
                    "type": "bool",
                    "id": 4,
                    "rule": "optional"
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.appointments?.nested?.merchant?.nested?.ClientMessage) {
	$root.addJSON(jsonData);
}

/**
 * To generate protos after editing:
 * $ bundle exec rake sq:protos:compile
 *
 * SOURCE ClientMessage @ squareup/appointments/merchant/service.proto at 16:1
 */
export const ClientMessage = $root.lookupType('squareup.appointments.merchant.ClientMessage');
fixType(ClientMessage);
Builder.createAndAttachToType(ClientMessage);
