import React, { cloneElement, ReactElement } from 'react';
import { observer } from 'mobx-react';
import { MarketEmptyState } from 'src/components/Market';
import './EmptyState.scss';
import classNames from 'classnames';

export type EmptyStateProps = {
  icon: ReactElement;
  title: string;
  description?: string;
  button?: ReactElement;
  secondaryButton?: ReactElement;
  compact?: boolean;
  dataCy?: string;
};

/**
 * Generic empty state component to simplify creation of various empty states.
 *
 * @param {ReactElement} icon
 * The icon to display.
 * @param {string} title
 * The title to display.
 * @param {string} [description]
 * (Optional) The description to display.
 * @param {ReactElement} [button]
 * (Optional) The primary button to display.
 * This will show to the right of the secondary button if available.
 * @param {ReactElement} [secondaryButton]
 * (Optional) The secondary button to display.
 * This will show to the left of the primary button if available.
 * @param {boolean} [compact]
 * (Optional) If true, it will not take full height
 * @param {string} [dataCy]
 * (Optional) A test id used for Cypress e2e test.
 */
const EmptyState = observer(
  ({
    icon,
    title,
    description,
    button,
    secondaryButton,
    compact = false,
    dataCy,
  }: EmptyStateProps): ReactElement => (
    <MarketEmptyState
      className={classNames('EmptyState', {
        EmptyState__compact: compact,
      })}
      data-testid="EmptyState"
      data-cy={dataCy}
    >
      <div slot="primary-text">
        {icon}
        <h3>{title}</h3>
      </div>
      {description && (
        <p className="paragraph-30" slot="secondary-text">
          {description}
        </p>
      )}
      {secondaryButton &&
        cloneElement(secondaryButton, { slot: 'actions', rank: 'secondary' })}
      {button && cloneElement(button, { slot: 'actions', rank: 'primary' })}
    </MarketEmptyState>
  ),
);

export default EmptyState;
