import { observer } from 'mobx-react';
import React, { ChangeEvent, FocusEvent, ReactElement, useState } from 'react';
import {
  textToCurrencyAmount,
  amountToCurrencyString,
  textToCurrencyString,
} from 'src/utils/moneyUtils';
import { SupportedLocale } from 'src/MessengerTypes';
import { MarketInputText } from 'src/components/Market';

export type InputMoneyProps = {
  label: string;
  initialAmount?: number;
  currencyCode: string;
  locale: SupportedLocale;
  onChange: (newAmount: number) => void;
  onBlur?: () => void;
  disabled?: boolean;
};

/**
 * A MarketInputText that focus on formatting money input. It will show the input value
 * as string, but the onChange will be called with the number value based on the smallest
 * denomination of the currency.
 *
 * @example
 * Basic usage:
 * <InputMoney
 *   label="Amount"
 *   currencyCode="USD"
 *   locale="en-US"
 *   onChange={(val: number) => setAmount(val)}
 *  />
 * @param {string} label
 * The label for this input
 * @param {number} [initialAmount]
 * (Optional) An amount to set initially, if desired
 * @param {string} currencyCode
 * The currency, e.g. 'USD'
 * @param {SupportedLocale} locale
 * The locale for currency formatting
 * @param {(number) => void} onChange
 * Function to call when the input changes
 * @param {() => void} [onBlur]
 * (Optional) function to call when the input field receives
 * a 'blur' event; this is in addition to the standard currency formatting that the input field
 * does on blur.
 * @param {boolean} [disabled]
 * (Optional) Whether the input field should appear as disabled.
 * @author klim
 */
const InputMoney = observer((props: InputMoneyProps): ReactElement => {
  const {
    label,
    initialAmount,
    currencyCode,
    locale,
    onChange,
    onBlur,
    disabled,
  } = props;

  const initialCurrencyString = initialAmount
    ? amountToCurrencyString(initialAmount, currencyCode, locale)
    : '';

  const [currencyString, setCurrencyString] = useState(initialCurrencyString);

  return (
    <MarketInputText
      data-testid="InputMoney"
      disabled={disabled || undefined}
      value={currencyString}
    >
      <label>{label}</label>
      <input
        slot="input"
        data-testid="InputMoney__input"
        type="text"
        value={currencyString}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          const amount = textToCurrencyAmount(
            e.target.value,
            currencyCode,
            locale,
          );
          onChange(amount);
          setCurrencyString(e.target.value);
        }}
        onBlur={(e: FocusEvent<HTMLInputElement>) => {
          const formattedString = textToCurrencyString(
            e.target.value,
            currencyCode,
            locale,
          );

          setCurrencyString(formattedString);

          if (onBlur) {
            onBlur();
          }
        }}
      />
    </MarketInputText>
  );
});

export default InputMoney;
