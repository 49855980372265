/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../../Fixes';
import Builder from '../../../../Builder';
import '../common/money';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "connect": {
        "nested": {
          "v2": {
            "nested": {
              "resources": {
                "nested": {
                  "AdditionalRecipient": {
                    "fields": {
                      "locationId": {
                        "type": "string",
                        "id": 1,
                        "rule": "optional"
                      },
                      "description": {
                        "type": "string",
                        "id": 2,
                        "rule": "optional"
                      },
                      "amountMoney": {
                        "type": "squareup.connect.v2.common.Money",
                        "id": 3,
                        "rule": "optional"
                      },
                      "receivableId": {
                        "type": "string",
                        "id": 4,
                        "rule": "optional"
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.resources?.nested?.AdditionalRecipient) {
	$root.addJSON(jsonData);
}

/**
 * @desc Represents an additional recipient (other than the merchant) receiving a portion of this tender.
 *
 * SOURCE AdditionalRecipient @ squareup/connect/v2/resources/additional_recipient.proto at 21:1
 */
export const AdditionalRecipient = $root.lookupType('squareup.connect.v2.resources.AdditionalRecipient');
fixType(AdditionalRecipient, {
  locationId: { notEmpty: true },
  amountMoney: { required: true },
});
Builder.createAndAttachToType(AdditionalRecipient);
