// Shared constants, types, and utils for the ExperimentStore.

// -------------------- Mobile project experiment names -------------------- //
// These are experiments in the Mobile Optimizely project:
// https://app.optimizely.com/v2/projects/17149011059
export const MOBILE_EXPERIMENT_NAMES: readonly string[] = [] as const;

// ------------------ Dashboard project experiment names ------------------ //
// These are experiments in the Dashboard Optimizely project:
// https://app.optimizely.com/v2/projects/16952400626
export const DASHBOARD_EXPERIMENT_NAMES: readonly string[] = [] as const;

export type ExperimentName =
  | typeof MOBILE_EXPERIMENT_NAMES[number]
  | typeof DASHBOARD_EXPERIMENT_NAMES[number];
