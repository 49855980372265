import { Component, Host, h, Prop, Event, EventEmitter, Element } from '@stencil/core';

@Component({
  tag: 'market-date-picker-date',
  styleUrl: 'market-date-picker-date.css',
  shadow: true,
})
export class MarketDatePickerDate {
  @Element() el: HTMLMarketDatePickerDateElement;

  /**
   * Functionally and visually disables the date picker date
   */
  @Prop({ reflect: true }) readonly disabled: boolean = false;

  /**
   * String for setting date picker date selection type
   */
  @Prop({ reflect: true }) readonly selection: 'none' | 'single' | 'range-first' | 'range-middle' | 'range-last' =
    'none';

  /**
   * Handles whether or not date picker date is for today's date
   */
  @Prop({ reflect: true }) readonly today: boolean = false;

  /**
   * Handles whether or not date picker date is selected
   */
  @Prop({ reflect: true }) readonly selected: boolean = false;

  /**
   * String for user to pass in day of the date
   */
  @Prop({ reflect: true }) readonly day: string | null;

  /**
   * Emitted when the date picker date is selected
   */
  @Event() marketDatePickerDateSelected: EventEmitter<{ date: HTMLMarketDatePickerDateElement }>;

  /**
   * Emitted when the date picker date is hovered or moused over
   */
  @Event() marketDatePickerDateMousedOver: EventEmitter<{ date: HTMLMarketDatePickerDateElement }>;

  /**
   * Emitted when the date picker date is unhovered or moused out
   */
  @Event() marketDatePickerDateMousedOut: EventEmitter;

  /**
   * Interaction handler that passes events back to the market-date-picker component
   */
  handleInteraction(e: Event) {
    if (this.disabled || this.day.length === 0) {
      e.stopImmediatePropagation();
      return;
    }

    if (e.type === 'click') {
      this.marketDatePickerDateSelected.emit({ date: this.el });
    }
    if (e.type === 'mouseover') {
      this.marketDatePickerDateMousedOver.emit({ date: this.el });
    }
    if (e.type === 'mouseout') {
      this.marketDatePickerDateMousedOut.emit();
    }
  }

  render() {
    return (
      <Host
        class="market-date-picker-date"
        aria-disabled={this.disabled}
        onClick={(e: Event) => {
          this.handleInteraction(e);
        }}
        onmouseover={(e: Event) => {
          this.handleInteraction(e);
        }}
        onmouseout={(e: Event) => {
          this.handleInteraction(e);
        }}
      >
        {this.day}
      </Host>
    );
  }
}
