/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';
import '../common/client';
import './agenda';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "agenda": {
        "nested": {
          "Qualifier": {
            "fields": {
              "buildType": {
                "type": "squareup.common.client.Product.BuildType",
                "id": 1,
                "rule": "optional"
              },
              "minVersion": {
                "type": "squareup.common.client.Version",
                "id": 2,
                "rule": "optional"
              },
              "maxVersion": {
                "type": "squareup.common.client.Version",
                "id": 3,
                "rule": "optional"
              },
              "userTokens": {
                "type": "string",
                "id": 4,
                "rule": "repeated"
              }
            }
          },
          "ForcedUpgradeRule": {
            "fields": {
              "qualifier": {
                "type": "squareup.agenda.Qualifier",
                "id": 1,
                "rule": "optional"
              },
              "notification": {
                "type": "squareup.agenda.CheckApplicationNotification",
                "id": 2,
                "rule": "optional"
              }
            }
          },
          "ForcedUpgradeRules": {
            "fields": {
              "rule": {
                "type": "squareup.agenda.ForcedUpgradeRule",
                "id": 1,
                "rule": "repeated"
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.agenda?.nested?.Qualifier && !$root.nested?.squareup?.nested?.agenda?.nested?.ForcedUpgradeRule && !$root.nested?.squareup?.nested?.agenda?.nested?.ForcedUpgradeRules) {
	$root.addJSON(jsonData);
}

/**
 * SOURCE Qualifier @ squareup/agenda/dynamicflags.proto at 69:1
 */
export const Qualifier = $root.lookupType('squareup.agenda.Qualifier');
fixType(Qualifier, {
  buildType: { required: true },
});
Builder.createAndAttachToType(Qualifier);
/**
 * Rule to check if we need to upgrade a client.
 * If the qualifier matches, then we send batch the notification.
 *
 * SOURCE ForcedUpgradeRule @ squareup/agenda/dynamicflags.proto at 86:1
 */
export const ForcedUpgradeRule = $root.lookupType('squareup.agenda.ForcedUpgradeRule');
fixType(ForcedUpgradeRule);
Builder.createAndAttachToType(ForcedUpgradeRule);
/**
 * This is a list of rules that we check if a client needs to upgrade. The first one we match, we send the "Notification" object back to the client.
 * If none match, the client doesn't need to be upgraded.
 *
 * SOURCE ForcedUpgradeRules @ squareup/agenda/dynamicflags.proto at 93:1
 */
export const ForcedUpgradeRules = $root.lookupType('squareup.agenda.ForcedUpgradeRules');
fixType(ForcedUpgradeRules);
Builder.createAndAttachToType(ForcedUpgradeRules);
