import React, { ReactElement, ReactNode } from 'react';
import { observer } from 'mobx-react';
import { MarketLink } from 'src/components/Market';
import { onKeyDownEnter } from 'src/utils/keyboardUtils';

export type LinkProps = {
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
  children: ReactNode;
};

/**
 * Simple wrapper around the MarketLink component that handles default click behaviour
 * by mapping it for keyboard handling.
 *
 * @param {string} [className]
 * Class name to apply to the link.
 * @param {() => void} [onClick]
 * Callback to be called when either the click or keydown event is triggered on the link
 * @param {boolean} [disabled]
 * Flag that indicates the link should appear as disabled when provided.
 * @param {ReactNode} children
 * The content to apply the link styling to.
 */
const Link = observer(
  ({ className, onClick, disabled, children }: LinkProps): ReactElement => (
    <MarketLink
      href=""
      className={className}
      onClick={(e) => {
        e.preventDefault();
        onClick?.();
      }}
      onKeyDown={(e) =>
        onKeyDownEnter(e, () => {
          e.preventDefault();
          onClick?.();
        })
      }
      disabled={disabled || undefined}
    >
      {children}
    </MarketLink>
  ),
);

export default Link;
