/**
 * A polar star icon to symbolize Square assistant or automation.
 */

import React, { ReactElement } from 'react';

function AssistantIcon(): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <title>Square Assistant</title>
      <path
        fill="#000"
        fillOpacity=".9"
        fillRule="evenodd"
        d="M12 2a1 1 0 0 1 .894.553l2.851 5.702 5.702 2.85a1 1 0 0 1 0 1.79l-5.702 2.85-2.85 5.702a1 1 0 0 1-1.79 0l-2.85-5.702-5.702-2.85a1 1 0 0 1 0-1.79l5.702-2.85 2.85-5.702A1 1 0 0 1 12 2Zm0 3.236L9.894 9.447a1 1 0 0 1-.447.447L5.237 12l4.21 2.106a1 1 0 0 1 .447.447L12 18.763l2.106-4.21a1 1 0 0 1 .447-.447L18.763 12l-4.21-2.106a1 1 0 0 1-.447-.447L12 5.237Z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default AssistantIcon;
