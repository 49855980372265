import { isAxiosError } from 'axios';
import { ErrorResult, Status } from './types';

export const ERROR_MESSAGE_MAP = {
  [Status.UNKNOWN]: 'An unknown error has occurred.',
  [Status.SUCCESS]: 'The request was successful.',
  [Status.SDK_ERROR]: 'There was an error with Optimizely.',
  [Status.INVALID_EXPERIMENT_REQUEST]:
    'The request for the experiment was invalid in some way',
  [Status.EXPERIMENT_NOT_FOUND]: 'The experiment was not found in Optimizely.',
  [Status.VARIANT_NAME_MISMATCH]:
    'The variant does not exist not in the Optimizely experiment.',
  [Status.IMPRESSION_LIMIT_EXCEEDED]:
    'Either the user has already visited the experiment or the experiment is paused/ended.',
  [Status.USER_NOT_IN_EXPERIMENT]: 'The user is not in the experiment.',
};

export function createErrorResult(
  errorType: string,
  customMessage?: string
): ErrorResult {
  const errorMessage =
    ERROR_MESSAGE_MAP[errorType as Status] ||
    customMessage ||
    ERROR_MESSAGE_MAP[Status.UNKNOWN];

  return {
    success: false,
    error: {
      type: errorType,
      message: errorMessage,
    },
  };
}

export function handleAxiosError(
  err: unknown,
  hasUnsafeDefaults?: boolean
): ErrorResult {
  const errorType = isAxiosError(err) && err.code ? err.code : Status.UNKNOWN;
  const errorMessage = isAxiosError(err) ? err.message : undefined;

  if (hasUnsafeDefaults) {
    throw new Error(
      `[@squareup/browser-experiments] ${errorType}: ${errorMessage}`
    );
  }

  return createErrorResult(errorType, errorMessage);
}
