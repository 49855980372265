/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../Fixes';
import Builder from '../../../Builder';
import '../../common/token';
import '../../common/currency';
import '../../common/payment';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "checkoutfe": {
        "nested": {
          "extensions": {
            "nested": {
              "BillModelMirrors": {
                "nested": {
                  "CreatorDetails": {
                    "fields": {
                      "mobileStaff": {
                        "type": "squareup.checkoutfe.extensions.BillModelMirrors.CreatorDetails.MobileStaff",
                        "id": 1,
                        "rule": "optional"
                      },
                      "employee": {
                        "type": "squareup.checkoutfe.extensions.BillModelMirrors.Employee",
                        "id": 2,
                        "rule": "optional"
                      },
                      "deviceCredential": {
                        "type": "squareup.checkoutfe.extensions.BillModelMirrors.CreatorDetails.DeviceCredential",
                        "id": 3,
                        "rule": "optional"
                      },
                      "sourceAppId": {
                        "type": "string",
                        "id": 4,
                        "rule": "optional"
                      }
                    },
                    "nested": {
                      "MobileStaff": {
                        "fields": {
                          "userToken": {
                            "type": "string",
                            "id": 1,
                            "rule": "optional"
                          },
                          "readOnlyName": {
                            "type": "string",
                            "id": 2,
                            "rule": "optional"
                          },
                          "readOnlyProfilePhotoUrl": {
                            "type": "string",
                            "id": 3,
                            "rule": "optional"
                          },
                          "readOnlyMobileStaffId": {
                            "type": "string",
                            "id": 4,
                            "rule": "optional"
                          }
                        }
                      },
                      "DeviceCredential": {
                        "fields": {
                          "token": {
                            "type": "string",
                            "id": 1,
                            "rule": "optional"
                          },
                          "name": {
                            "type": "string",
                            "id": 2,
                            "rule": "optional"
                          }
                        }
                      }
                    }
                  },
                  "Employee": {
                    "fields": {
                      "employeeToken": {
                        "type": "string",
                        "id": 1,
                        "rule": "optional"
                      },
                      "readOnlyFullName": {
                        "type": "string",
                        "id": 2,
                        "rule": "optional"
                      },
                      "readOnlyProfilePhotoUrl": {
                        "type": "string",
                        "id": 3,
                        "rule": "optional"
                      },
                      "firstName": {
                        "type": "string",
                        "id": 4,
                        "rule": "optional"
                      },
                      "lastName": {
                        "type": "string",
                        "id": 5,
                        "rule": "optional"
                      },
                      "readOnlyEmployeeNumber": {
                        "type": "string",
                        "id": 6,
                        "rule": "optional"
                      }
                    }
                  },
                  "Event": {
                    "fields": {
                      "uid": {
                        "type": "string",
                        "id": 1,
                        "rule": "optional"
                      },
                      "eventTokenPair": {
                        "type": "squareup.common.token.TokenPair",
                        "id": 2,
                        "rule": "optional"
                      },
                      "eventType": {
                        "type": "squareup.checkoutfe.extensions.BillModelMirrors.Event.EventType",
                        "id": 3,
                        "rule": "optional"
                      },
                      "createdAt": {
                        "type": "string",
                        "id": 4,
                        "rule": "optional"
                      },
                      "creatorDetails": {
                        "type": "squareup.checkoutfe.extensions.BillModelMirrors.CreatorDetails",
                        "id": 5,
                        "rule": "optional"
                      },
                      "reason": {
                        "type": "string",
                        "id": 6,
                        "rule": "optional"
                      },
                      "originalLineItem": {
                        "type": "squareup.checkoutfe.extensions.BillModelMirrors.Event.LineItemReference",
                        "id": 7,
                        "rule": "optional"
                      },
                      "childLineItemUids": {
                        "type": "string",
                        "id": 8,
                        "rule": "repeated"
                      }
                    },
                    "nested": {
                      "EventType": {
                        "values": {
                          "EVENT_TYPE_DO_NOT_USE": 0,
                          "CREATION": 1,
                          "COMP": 2,
                          "VOID": 3,
                          "UNCOMP": 4,
                          "DELETE": 5,
                          "SPLIT": 6,
                          "FIRE": 7,
                          "SEND": 8,
                          "DISCOUNT": 9,
                          "REMOVE_DISCOUNT": 10,
                          "PRINT": 11,
                          "MOVE": 12,
                          "AMENDED_REOPEN": 13,
                          "REFUNDED_REOPEN": 14,
                          "SPLIT_ITEM_PAYMENT": 15,
                          "UNSPLIT_ITEM_PAYMENT": 16
                        }
                      },
                      "LineItemReference": {
                        "fields": {
                          "lineItemUid": {
                            "type": "string",
                            "id": 3,
                            "rule": "optional"
                          },
                          "orderId": {
                            "type": "string",
                            "id": 1
                          },
                          "ticketTokenPair": {
                            "type": "squareup.common.token.TokenPair",
                            "id": 2
                          }
                        },
                        "nested": {
                          "open_ticket_id": {
                            "oneof": [
                              "orderId",
                              "ticketTokenPair"
                            ]
                          }
                        }
                      }
                    }
                  },
                  "EmployeeAttribution": {
                    "fields": {
                      "uid": {
                        "type": "string",
                        "id": 1,
                        "rule": "optional"
                      },
                      "employee": {
                        "type": "squareup.checkoutfe.extensions.BillModelMirrors.Employee",
                        "id": 2,
                        "rule": "optional"
                      }
                    }
                  },
                  "ReceiptDisplayDetails": {
                    "fields": {
                      "displaySignatureLineOnPaperReceipt": {
                        "type": "bool",
                        "id": 1,
                        "rule": "optional"
                      },
                      "displayTipOptionsOnPaperReceipt": {
                        "type": "bool",
                        "id": 2,
                        "rule": "optional"
                      },
                      "tippingPreferences": {
                        "type": "squareup.checkoutfe.extensions.BillModelMirrors.ReceiptDisplayDetails.TippingPreferences",
                        "id": 3,
                        "rule": "optional"
                      },
                      "displayQuickTipOptions": {
                        "type": "bool",
                        "id": 4,
                        "rule": "optional"
                      },
                      "displayCustomAmount": {
                        "type": "bool",
                        "id": 5,
                        "rule": "optional"
                      },
                      "remainingTenderBalanceMoney": {
                        "type": "squareup.common.Money",
                        "id": 6,
                        "rule": "optional"
                      },
                      "buyerSelectedLocale": {
                        "type": "string",
                        "id": 7,
                        "rule": "optional"
                      },
                      "buyerSignature": {
                        "type": "squareup.common.payment.SignatureType",
                        "id": 8,
                        "rule": "optional",
                        "options": {
                          "default": "UNKNOWN"
                        }
                      },
                      "paperSignatureTipType": {
                        "type": "squareup.checkoutfe.extensions.BillModelMirrors.ReceiptDisplayDetails.PaperSignatureTipType",
                        "id": 9,
                        "rule": "optional",
                        "options": {
                          "default": "UNSET"
                        }
                      }
                    },
                    "nested": {
                      "TippingPreferences": {
                        "fields": {
                          "useSmartTipping": {
                            "type": "bool",
                            "id": 1,
                            "rule": "optional"
                          },
                          "smartTippingThresholdMoney": {
                            "type": "squareup.common.Money",
                            "id": 2,
                            "rule": "optional"
                          },
                          "smartTippingUnderThresholdOptions": {
                            "type": "squareup.checkoutfe.extensions.BillModelMirrors.ReceiptDisplayDetails.TippingPreferences.TipOption",
                            "id": 3,
                            "rule": "repeated"
                          },
                          "smartTippingOverThresholdOptions": {
                            "type": "squareup.checkoutfe.extensions.BillModelMirrors.ReceiptDisplayDetails.TippingPreferences.TipOption",
                            "id": 4,
                            "rule": "repeated"
                          },
                          "tippingOptions": {
                            "type": "squareup.checkoutfe.extensions.BillModelMirrors.ReceiptDisplayDetails.TippingPreferences.TipOption",
                            "id": 5,
                            "rule": "repeated"
                          },
                          "allowManualTipEntry": {
                            "type": "bool",
                            "id": 6,
                            "rule": "optional"
                          },
                          "manualTipEntrySmallestMaxMoney": {
                            "type": "squareup.common.Money",
                            "id": 7,
                            "rule": "optional"
                          },
                          "manualTipEntryLargestMaxMoney": {
                            "type": "squareup.common.Money",
                            "id": 8,
                            "rule": "optional"
                          },
                          "manualTipEntryMaxPercentage": {
                            "type": "double",
                            "id": 9,
                            "rule": "optional"
                          },
                          "clientCalculatedTipOption": {
                            "type": "squareup.checkoutfe.extensions.BillModelMirrors.ReceiptDisplayDetails.TippingPreferences.TipOption",
                            "id": 10,
                            "rule": "repeated"
                          }
                        },
                        "nested": {
                          "TipOption": {
                            "fields": {
                              "uid": {
                                "type": "string",
                                "id": 1,
                                "rule": "optional"
                              },
                              "label": {
                                "type": "string",
                                "id": 2,
                                "rule": "optional"
                              },
                              "tipMoney": {
                                "type": "squareup.common.Money",
                                "id": 3,
                                "rule": "optional"
                              },
                              "percentage": {
                                "type": "double",
                                "id": 4,
                                "rule": "optional"
                              },
                              "isRemainingBalance": {
                                "type": "bool",
                                "id": 5,
                                "rule": "optional"
                              }
                            }
                          }
                        }
                      },
                      "PaperSignatureTipType": {
                        "values": {
                          "UNSET": 0,
                          "TRADITIONAL": 1,
                          "QUICK_TIP": 2,
                          "GRATUITY_FREE": 3
                        }
                      }
                    }
                  }
                }
              },
              "LineItemModelExtension": {
                "fields": {
                  "event": {
                    "type": "squareup.checkoutfe.extensions.BillModelMirrors.Event",
                    "id": 1,
                    "rule": "repeated"
                  },
                  "employeeAttributions": {
                    "type": "squareup.checkoutfe.extensions.BillModelMirrors.EmployeeAttribution",
                    "id": 2,
                    "rule": "repeated"
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.checkoutfe?.nested?.extensions?.nested?.BillModelMirrors?.nested?.CreatorDetails && !$root.nested?.squareup?.nested?.checkoutfe?.nested?.extensions?.nested?.BillModelMirrors?.nested?.CreatorDetails?.nested?.MobileStaff && !$root.nested?.squareup?.nested?.checkoutfe?.nested?.extensions?.nested?.BillModelMirrors?.nested?.CreatorDetails?.nested?.DeviceCredential && !$root.nested?.squareup?.nested?.checkoutfe?.nested?.extensions?.nested?.BillModelMirrors?.nested?.Employee && !$root.nested?.squareup?.nested?.checkoutfe?.nested?.extensions?.nested?.BillModelMirrors?.nested?.Event && !$root.nested?.squareup?.nested?.checkoutfe?.nested?.extensions?.nested?.BillModelMirrors?.nested?.Event?.nested?.EventType && !$root.nested?.squareup?.nested?.checkoutfe?.nested?.extensions?.nested?.BillModelMirrors?.nested?.Event?.nested?.LineItemReference && !$root.nested?.squareup?.nested?.checkoutfe?.nested?.extensions?.nested?.BillModelMirrors?.nested?.EmployeeAttribution && !$root.nested?.squareup?.nested?.checkoutfe?.nested?.extensions?.nested?.BillModelMirrors?.nested?.ReceiptDisplayDetails && !$root.nested?.squareup?.nested?.checkoutfe?.nested?.extensions?.nested?.BillModelMirrors?.nested?.ReceiptDisplayDetails?.nested?.TippingPreferences && !$root.nested?.squareup?.nested?.checkoutfe?.nested?.extensions?.nested?.BillModelMirrors?.nested?.ReceiptDisplayDetails?.nested?.TippingPreferences?.nested?.TipOption && !$root.nested?.squareup?.nested?.checkoutfe?.nested?.extensions?.nested?.BillModelMirrors?.nested?.ReceiptDisplayDetails?.nested?.PaperSignatureTipType && !$root.nested?.squareup?.nested?.checkoutfe?.nested?.extensions?.nested?.LineItemModelExtension) {
	$root.addJSON(jsonData);
}

/**
 * SOURCE BillModelMirrors @ squareup/checkoutfe/extensions/extensions.proto at 15:1
 */
export const BillModelMirrors = {};
/**
 * squareup.bills.CreatorDetails
 *
 * SOURCE CreatorDetails @ squareup/checkoutfe/extensions/extensions.proto at 18:3
 */
BillModelMirrors.CreatorDetails = $root.lookupType('squareup.checkoutfe.extensions.BillModelMirrors.CreatorDetails');
fixType(BillModelMirrors.CreatorDetails);
Builder.createAndAttachToType(BillModelMirrors.CreatorDetails);
/**
 * squareup.bills.MobileStaff
 *
 * SOURCE MobileStaff @ squareup/checkoutfe/extensions/extensions.proto at 21:5
 */
BillModelMirrors.CreatorDetails.MobileStaff = $root.lookupType('squareup.checkoutfe.extensions.BillModelMirrors.CreatorDetails.MobileStaff');
fixType(BillModelMirrors.CreatorDetails.MobileStaff, {
  userToken: { notEmpty: true },
});
Builder.createAndAttachToType(BillModelMirrors.CreatorDetails.MobileStaff);
/**
 * squareup.bills.DeviceCredential
 *
 * SOURCE DeviceCredential @ squareup/checkoutfe/extensions/extensions.proto at 31:5
 */
BillModelMirrors.CreatorDetails.DeviceCredential = $root.lookupType('squareup.checkoutfe.extensions.BillModelMirrors.CreatorDetails.DeviceCredential');
fixType(BillModelMirrors.CreatorDetails.DeviceCredential, {
  token: { notEmpty: true },
});
Builder.createAndAttachToType(BillModelMirrors.CreatorDetails.DeviceCredential);
/**
 * squareup.bills.Employee
 *
 * SOURCE Employee @ squareup/checkoutfe/extensions/extensions.proto at 41:3
 */
BillModelMirrors.Employee = $root.lookupType('squareup.checkoutfe.extensions.BillModelMirrors.Employee');
fixType(BillModelMirrors.Employee, {
  employeeToken: { notEmpty: true },
});
Builder.createAndAttachToType(BillModelMirrors.Employee);
/**
 * squareup.bills.Itemization.Event
 *
 * SOURCE Event @ squareup/checkoutfe/extensions/extensions.proto at 51:3
 */
BillModelMirrors.Event = $root.lookupType('squareup.checkoutfe.extensions.BillModelMirrors.Event');
fixType(BillModelMirrors.Event, {
  uid: { notEmpty: true },
  eventTokenPair: { required: true },
  eventType: { required: true },
  createdAt: { required: true },
});
Builder.createAndAttachToType(BillModelMirrors.Event);
/**
 * SOURCE EventType @ squareup/checkoutfe/extensions/extensions.proto at 54:5
 */
BillModelMirrors.Event.EventType = $root.lookupEnum('squareup.checkoutfe.extensions.BillModelMirrors.Event.EventType').values;
/**
 * A reference to a line item on a different order.
 *
 * SOURCE LineItemReference @ squareup/checkoutfe/extensions/extensions.proto at 108:5
 */
BillModelMirrors.Event.LineItemReference = $root.lookupType('squareup.checkoutfe.extensions.BillModelMirrors.Event.LineItemReference');
fixType(BillModelMirrors.Event.LineItemReference, {
  lineItemUid: { notEmpty: true },
});
Builder.createAndAttachToType(BillModelMirrors.Event.LineItemReference);
/**
 * squareup.bills.Itemization.EmployeeAttribution
 *
 * SOURCE EmployeeAttribution @ squareup/checkoutfe/extensions/extensions.proto at 129:3
 */
BillModelMirrors.EmployeeAttribution = $root.lookupType('squareup.checkoutfe.extensions.BillModelMirrors.EmployeeAttribution');
fixType(BillModelMirrors.EmployeeAttribution, {
  uid: { notEmpty: true },
  employee: { required: true },
});
Builder.createAndAttachToType(BillModelMirrors.EmployeeAttribution);
/**
 * squareup.bills.Tender.CompleteTenderDetails.ReceiptDisplayDetails
 *
 * SOURCE ReceiptDisplayDetails @ squareup/checkoutfe/extensions/extensions.proto at 135:3
 */
BillModelMirrors.ReceiptDisplayDetails = $root.lookupType('squareup.checkoutfe.extensions.BillModelMirrors.ReceiptDisplayDetails');
fixType(BillModelMirrors.ReceiptDisplayDetails);
Builder.createAndAttachToType(BillModelMirrors.ReceiptDisplayDetails);
/**
 * SOURCE TippingPreferences @ squareup/checkoutfe/extensions/extensions.proto at 139:5
 */
BillModelMirrors.ReceiptDisplayDetails.TippingPreferences = $root.lookupType('squareup.checkoutfe.extensions.BillModelMirrors.ReceiptDisplayDetails.TippingPreferences');
fixType(BillModelMirrors.ReceiptDisplayDetails.TippingPreferences);
Builder.createAndAttachToType(BillModelMirrors.ReceiptDisplayDetails.TippingPreferences);
/**
 * SOURCE TipOption @ squareup/checkoutfe/extensions/extensions.proto at 143:7
 */
BillModelMirrors.ReceiptDisplayDetails.TippingPreferences.TipOption = $root.lookupType('squareup.checkoutfe.extensions.BillModelMirrors.ReceiptDisplayDetails.TippingPreferences.TipOption');
fixType(BillModelMirrors.ReceiptDisplayDetails.TippingPreferences.TipOption, {
  uid: { notEmpty: true },
});
Builder.createAndAttachToType(BillModelMirrors.ReceiptDisplayDetails.TippingPreferences.TipOption);
/**
 * SOURCE PaperSignatureTipType @ squareup/checkoutfe/extensions/extensions.proto at 168:5
 */
BillModelMirrors.ReceiptDisplayDetails.PaperSignatureTipType = $root.lookupEnum('squareup.checkoutfe.extensions.BillModelMirrors.ReceiptDisplayDetails.PaperSignatureTipType').values;
/**
 * SOURCE LineItemModelExtension @ squareup/checkoutfe/extensions/extensions.proto at 178:1
 */
export const LineItemModelExtension = $root.lookupType('squareup.checkoutfe.extensions.LineItemModelExtension');
fixType(LineItemModelExtension);
Builder.createAndAttachToType(LineItemModelExtension);
