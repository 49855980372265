/**
 * A magnifying glass icon to use to denote search results.
 */
import React, { ReactElement } from 'react';

function MagnifyIcon(): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      viewBox="0 0 192 192"
    >
      <title>Search</title>
      <defs>
        <linearGradient
          id="MagnifyIcon__a"
          x1="131.46"
          x2="91.08"
          y1="46.29"
          y2="116.24"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".15" stopColor="#e4ff80" />
          <stop offset=".29" stopColor="#caf236" />
          <stop offset=".55" stopColor="#006aff" />
        </linearGradient>
      </defs>
      <path
        d="m57.16 112.21-.02.02h.02v-.02Zm22.63 22.63a63.813 63.813 0 0 1-12.74-9.89c-3.9-3.9-7.19-8.18-9.88-12.72h-.02l-22.61 22.61-16.19 16.19c-3.12 3.12-3.12 8.19 0 11.31l11.31 11.31c3.12 3.12 8.19 3.12 11.31 0l38.82-38.82Z"
        className="cls-4"
      />
      <path fill="#006aff" d="M79.79 112.23h-.35l.35.34v-.34z" />
      <path
        d="M157.25 34.75c-24.99-24.99-65.52-24.99-90.51 0-21.09 21.09-24.38 53.23-9.89 77.77v.02l-.04.04c2.7 4.53 6.01 8.8 9.92 12.69 3.91 3.88 8.19 7.15 12.73 9.82v.06c24.54 14.49 56.68 11.2 77.77-9.89 24.99-24.99 24.99-65.52 0-90.51Z"
        className="cls-4"
      />
      <path
        fill="#fff"
        d="M64 80s17.07 32 48 32 48-32 48-32-17.07-32-48-32-48 32-48 32Z"
      />
      <path
        fill="url(#MagnifyIcon__a)"
        d="M112 104c-13.23 0-24-10.77-24-24s10.77-24 24-24 24 10.77 24 24-10.77 24-24 24Z"
      />
      <circle cx="112" cy="80" r="12" className="cls-4" />
    </svg>
  );
}

export default MagnifyIcon;
