import { SHADOW_REACT_ROOT_ID } from 'src/utils/shadowDomUtils';
import Logger from 'src/Logger';
import { Environment } from 'src/MessengerTypes';

/**
 * Removes the loading spinner returned with the initial HTML for the
 * full page Messages app.
 */
export const removePageLoadingSpinner = (): void => {
  document.getElementById('messenger-loading-spinner')?.remove();
};

/**
 * Utility helper to initialize the shadow root to render the Messages
 * React application in.
 *
 * @param {Element} container
 * The container to use as the shadow root.
 * @returns {HTMLDivElement}
 * The element to render the corresponding React tree in.
 */
export const initMessagesShadowRoot = (container: Element): HTMLDivElement => {
  // Get our shadow root
  let shadow: ShadowRoot | null = container.shadowRoot;
  if (!shadow) {
    // create the shadow DOM root if it doesn't already exist
    shadow = container.attachShadow({ mode: 'open' });
  }
  // Note that we make a div here rather than embedding directly into the
  // shadow root in order to not clobber all the CSS styles that have been
  // also loaded into the shadow root.
  const reactRoot: HTMLDivElement = document.createElement('div');
  reactRoot.id = SHADOW_REACT_ROOT_ID;
  reactRoot.dataset.testid = SHADOW_REACT_ROOT_ID;
  // give our app access to market fonts
  reactRoot.dataset.marketFeatures = 'typography';
  shadow.appendChild(reactRoot);

  return reactRoot;
};

/**
 * Infer environment based on provided parameter or window.location.
 * Used for API keys that differ between staging and production.
 * Different from process.env.NODE_ENV, which is 'development' | 'production'.
 *
 * @param {string} [environmentOverride] - explicitly set environment,
 * if not provided, will look at the URL
 * @returns {Environment}
 */
export const getEnvironment = (environmentOverride?: string): Environment => {
  const envOverrideLowercase = (environmentOverride || '').toLowerCase();
  let environment: Environment;
  if (envOverrideLowercase === 'stage' || envOverrideLowercase === 'staging') {
    environment = 'staging';
  } else if (envOverrideLowercase === 'production') {
    environment = 'production';
  } else {
    // infer our environment
    const {
      location: { hostname },
    } = window;
    if (
      hostname === 'squareup.com' ||
      hostname === 'inbox.squareup.com' ||
      hostname === 'app.squareup.com'
    ) {
      environment = 'production';
    } else {
      environment = 'staging';
    }
    Logger.warn(`Inferred environment as ${environment}`);
  }
  return environment;
};
