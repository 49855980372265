/**
 * An icon for request payment smart action.
 */
import React, { ReactElement } from 'react';

function GoogleIcon(): ReactElement {
  return (
    <svg
      className="GoogleIcon"
      width="18"
      height="20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Google</title>
      <path
        d="M17.893 8.42a.792.792 0 0 0-.778-.651H9.658a.792.792 0 0 0-.791.791v3.063a.792.792 0 0 0 .791.791h3.137a2.89 2.89 0 0 1-.896.94c-.669.425-1.449.641-2.24.622a3.907 3.907 0 0 1-3.679-2.722 3.882 3.882 0 0 1 0-2.508v-.001a3.907 3.907 0 0 1 3.678-2.721 3.465 3.465 0 0 1 2.48.964.792.792 0 0 0 1.106-.012l2.271-2.271a.792.792 0 0 0-.02-1.139 8.41 8.41 0 0 0-5.837-2.274 8.668 8.668 0 0 0-7.78 4.796l-.002.002A8.646 8.646 0 0 0 .95 10a8.77 8.77 0 0 0 .926 3.91l.001.002a8.668 8.668 0 0 0 7.781 4.796 8.332 8.332 0 0 0 5.775-2.127h.002v-.001a8.541 8.541 0 0 0 2.615-6.4c0-.59-.052-1.18-.157-1.76ZM9.659 2.875a6.873 6.873 0 0 1 4.124 1.324L12.63 5.35a5.117 5.117 0 0 0-2.973-.908 5.452 5.452 0 0 0-4.741 2.81l-.847-.655-.463-.36a7.088 7.088 0 0 1 6.051-3.361Zm-6.745 9.424a7.148 7.148 0 0 1 0-4.597l1.41 1.094a5.426 5.426 0 0 0 0 2.408l-1.41 1.095Zm6.745 4.826a7.087 7.087 0 0 1-6.05-3.361l.299-.233 1.01-.783a5.45 5.45 0 0 0 4.741 2.812 5.762 5.762 0 0 0 2.617-.595l1.34 1.04a7.037 7.037 0 0 1-3.957 1.12Zm5.167-2.186-.145-.112-1.09-.847a4.438 4.438 0 0 0 1.107-2.213.79.79 0 0 0-.778-.936H10.45V9.352h5.976c.027.274.04.55.04.828a7.166 7.166 0 0 1-1.641 4.76Z"
        fill="#0055CC"
      />
    </svg>
  );
}

export default GoogleIcon;
