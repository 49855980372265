import { Loader } from '@googlemaps/js-api-loader';
import { getEnvironment } from 'src/utils/initUtils';

const GOOGLE_MAPS_API_KEY_STAGING = 'AIzaSyA2YkosEC6IeXlC1mkz94-EefMQm21ybJA';
const GOOGLE_MAPS_API_KEY_PRODUCTION =
  'AIzaSyDE6V9TF-eUZ2w66Q6PSD5hR95_fxfpiIE';

/**
 * A wrapper class around Google Maps API that provides promises to their endpoints
 * and shapes the responses into a data model that is digestable by our app.
 */
export default class GoogleMapsService {
  private autoCompleteService?: google.maps.places.AutocompleteService;
  private placesService?: google.maps.places.PlacesService;
  private loadPromise?: Promise<void>;

  /**
   * Fetch and loads the Javascript of the API. Must be called in the other functions
   * of this class to enable lazy loading.
   *
   * This can be called independently if we want to load the JS bundle in an early
   * lifecycle before using the getters.
   */
  init(): Promise<void> {
    if (this.loadPromise) {
      return this.loadPromise;
    }

    if (process.env.JEST_WORKER_ID === undefined) {
      const apiKey =
        getEnvironment() === 'production'
          ? GOOGLE_MAPS_API_KEY_PRODUCTION
          : GOOGLE_MAPS_API_KEY_STAGING;

      const loader = new Loader({
        apiKey,
        version: 'weekly',
        libraries: ['places'],
      });
      this.loadPromise = loader.load().then(() => {
        this.autoCompleteService = new google.maps.places.AutocompleteService();
        this.placesService = new google.maps.places.PlacesService(
          document.createElement('div'),
        );
      });
    } else {
      // During tests, we pretend the script is loaded
      this.loadPromise = Promise.resolve();
    }

    return this.loadPromise;
  }

  /**
   * For a given search query, search businesses related to it that is an establishment.
   *
   * @param {google.maps.places.AutocompletionRequest} request
   * @returns {Promise<google.maps.places.AutocompleteResponse>}
   */
  getPlacePredictions(
    request: google.maps.places.AutocompletionRequest,
  ): Promise<google.maps.places.AutocompleteResponse> {
    return this.init().then(() =>
      (
        this.autoCompleteService as google.maps.places.AutocompleteService
      ).getPlacePredictions(request),
    );
  }

  /**
   * Get the business details from a given Google placeId.
   *
   * @param {google.maps.places.PlaceDetailsRequest} request
   * @returns {Promise<google.maps.places.PlaceResult>}
   */
  getPlaceDetails(
    request: google.maps.places.PlaceDetailsRequest,
  ): Promise<google.maps.places.PlaceResult> {
    return this.init().then(
      () =>
        new Promise((resolve, reject) => {
          (this.placesService as google.maps.places.PlacesService).getDetails(
            request,
            (response, status) => {
              if (
                response &&
                status === google.maps.places.PlacesServiceStatus.OK
              ) {
                resolve(response);
              }
              reject(new Error('Failed to retrieve Google place details'));
            },
          );
        }),
    );
  }
}
