import SqOneTrust, { ALERT_BOX_CLOSED_COOKIE_KEY } from '@square/one-trust';
import { set, get } from 'js-cookie';
import {
  GroupConsentHash,
  OneTrustHeaders,
  MessagesSqOneTrust,
} from 'src/MessengerTypes';

/**
 * Custom event fired by our host app (e.g dashboard) when window.SqOneTrust instance change.
 * This happens when the the cookie preferences are changed in the host app.
 */
export const SQ_ONE_TRUST_INSTANCE_CHANGE =
  'dashboardSqOneTrustInstanceChangedEvent';

/**
 * Custom event to indicate that the preferences have changed. Only fired on the
 * Messages Full Page app. Similar logic in Dashboard and Appointments
 * https://github.com/squareup/appointments/blob/c2195edd19c45b163b37373e221a2d87fbe9a6d7/merchant/app/instance-initializers/onetrust.js
 */
export const ONE_TRUST_PREFERENCE_CHANGE =
  'messagesOneTrustPreferencesChangedEvent';

/**
 * These are the default values of the cookie preferences in the event
 * where window.SqOneTrust is not present. When not present, it is
 * assumed that the host app does not have OneTrust integration, and it
 * is safe to allow all cookies.
 */
export const FUNCTIONALITY_COOKIES_BLOCKED_DEFAULT = false;
export const ANALYTICS_COOKIES_BLOCKED_DEFAULT = false;
export const RETARGETING_COOKIES_BLOCKED_DEFAULT = false;
export const NECESSARY_COOKIES_BLOCKED_DEFAULT = false;
export const GROUP_CONSENT_HASH_DEFAULT: GroupConsentHash = {
  C0001: '1',
  C0002: '1',
  C0003: '1',
  C0004: '1',
};

/**
 * This class provides helpers cookie preferences for GDPR compliance by
 * extracting information from window.SqOneTrust that is set by our host
 * app, such as Dashboard. An instance of this should be created in
 * Services.
 * A user can set their preferences for 4 different types of cookies:
 * - Strictly necessary
 * - Functionality
 * - Analytics / tracking
 * - Retargeting / advertizing
 */
export default class SqOneTrustService {
  /**
   * Subset of the SqOneTrust instance that is always kept in-sync with the one
   * in window. If window.SqOneTrust is not present, the properties will
   * remain in its default values.
   */
  private _sqOneTrust: MessagesSqOneTrust = {
    strictlyNecessaryCookiesBlocked: NECESSARY_COOKIES_BLOCKED_DEFAULT,
    functionalityCookiesBlocked: FUNCTIONALITY_COOKIES_BLOCKED_DEFAULT,
    performanceAndAnalyticsCookiesBlocked: ANALYTICS_COOKIES_BLOCKED_DEFAULT,
    retargetingOrAdvertisingCookiesBlocked: RETARGETING_COOKIES_BLOCKED_DEFAULT,
    groupConsentHash: GROUP_CONSENT_HASH_DEFAULT,
  };

  constructor() {
    this._populatePreferenceFromWindow();

    document.addEventListener(ONE_TRUST_PREFERENCE_CHANGE, () => {
      this._ensureAlertBoxCookieExists();

      // This needs to create a new instance of SqOneTrust every time consent changes
      window.SqOneTrust = new SqOneTrust();

      // Notify ecosystem-header that the SquareOneTrust instance has changed
      document.dispatchEvent(new CustomEvent(SQ_ONE_TRUST_INSTANCE_CHANGE));

      this._populatePreferenceFromWindow();
    });

    /**
     * TODO(klim): Uncomment below when we need to track OneTrust preference
     * changes when our app is running. In the meantime preferences set at the
     * start of the app is definitive, and the behavior when preferences change
     * is as follows:
     * blocked --> unblocked: continue to block until hard refresh
     * unblocked --> blocked: continue using cookies, send tracking etc, until
     *                        hard refresh
     */
    // window.addEventListener(SQ_ONE_TRUST_INSTANCE_CHANGE, () => {
    //   this._populatePreferenceFromWindow();
    // });
  }

  /**
   * Populate our instance of SqOneTrust with the one in window. This is
   * called when the instance is first created, and subsequently when
   * the event SQ_ONE_TRUST_INSTANCE_CHANGE is triggered by our host apps.
   */
  private _populatePreferenceFromWindow = (): void => {
    if (window.SqOneTrust) {
      this._sqOneTrust.strictlyNecessaryCookiesBlocked =
        window.SqOneTrust.strictlyNecessaryCookiesBlocked;
      this._sqOneTrust.functionalityCookiesBlocked =
        window.SqOneTrust.functionalityCookiesBlocked;
      this._sqOneTrust.performanceAndAnalyticsCookiesBlocked =
        window.SqOneTrust.performanceAndAnalyticsCookiesBlocked;
      this._sqOneTrust.retargetingOrAdvertisingCookiesBlocked =
        window.SqOneTrust.retargetingOrAdvertisingCookiesBlocked;
      this._sqOneTrust.groupConsentHash = window.SqOneTrust
        .groupConsentHash as GroupConsentHash;
    }
  };

  /**
   * Certain browser extensions "I don't care about cookies" for example, broke header loading after consent.
   * This sets the cookie `OptanonAlertBoxClosed` if an external script is blocking the Onetrust modal.
   * https://github.com/squareup/appointments/blob/c2195edd19c45b163b37373e221a2d87fbe9a6d7/merchant/app/instance-initializers/onetrust.js#L35
   */
  private _ensureAlertBoxCookieExists = (): void => {
    const existingCookie = get(ALERT_BOX_CLOSED_COOKIE_KEY);
    if (existingCookie) {
      return;
    }

    const oneTrustSdkElement = document.getElementById('onetrust-consent-sdk');
    const isHidden = oneTrustSdkElement
      ? window.getComputedStyle(oneTrustSdkElement).visibility === 'hidden'
      : false;
    if (isHidden) {
      set(ALERT_BOX_CLOSED_COOKIE_KEY, new Date().toISOString());
    }
  };

  /**
   * Get the SqOneTrust instance as a Proxy so that it can be stored elsewhere in
   * the code while still keeping it updated whenever we re-populate from window
   * since we are passing by reference. However, as we intercept the setter here,
   * no where outside this controller can mutate the properties of the instance.
   */
  getSqOneTrust = (): MessagesSqOneTrust => {
    const getter = (
      target: MessagesSqOneTrust,
      key: string | number | symbol,
    ): boolean => {
      if (key === 'strictlyNecessaryCookiesBlocked') {
        return target.strictlyNecessaryCookiesBlocked;
      }
      if (key === 'functionalityCookiesBlocked') {
        return target.functionalityCookiesBlocked;
      }
      if (key === 'performanceAndAnalyticsCookiesBlocked') {
        return target.performanceAndAnalyticsCookiesBlocked;
      }
      if (key === 'retargetingOrAdvertisingCookiesBlocked') {
        return target.retargetingOrAdvertisingCookiesBlocked;
      }

      return false;
    };

    return new Proxy(this._sqOneTrust, {
      get: getter,
      set: getter,
    });
  };

  /**
   * Get a list of headers to append to requests. Documentation can be found here:
   * https://docs.google.com/document/d/141w7iDe38ygMR8wqeJuPH0rGJ-EyG4QUs3AN4o4K9jE/edit#
   * Essentially, X-Block-Cookies is a backend flag for whether GDPR should be
   * enforced at all. X-Allow-Cookies will be present once the user consents
   * to any type of cookie (at a minimum, this includes strictly necessary, i.e. C0001).
   */
  getCookieFilterHeaders = (): OneTrustHeaders => {
    let allowHeaderValue = '';
    const allowedCookies: string[] = [];
    for (const cookieName of Object.keys(this._sqOneTrust.groupConsentHash)) {
      if (this._sqOneTrust.groupConsentHash[cookieName] === '1') {
        // '1' = true (cookie type is allowed)
        allowedCookies.push(cookieName);
      }
    }
    allowHeaderValue = allowedCookies.join(', ');

    return {
      'X-Allow-Cookies': allowHeaderValue,
      'X-Block-Cookies': 'true',
    };
  };
}
