/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "bills": {
        "nested": {
          "CashDrawerDetails": {
            "fields": {
              "shiftId": {
                "type": "string",
                "id": 1,
                "rule": "optional"
              },
              "clientEventId": {
                "type": "string",
                "id": 2,
                "rule": "optional"
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.bills?.nested?.CashDrawerDetails) {
	$root.addJSON(jsonData);
}

/**
 * Details about the cash drawer provided by clients at the time of the payment or refund.
 *
 * SOURCE CashDrawerDetails @ squareup/bills/cash_drawer_details.proto at 10:1
 */
export const CashDrawerDetails = $root.lookupType('squareup.bills.CashDrawerDetails');
fixType(CashDrawerDetails);
Builder.createAndAttachToType(CashDrawerDetails);
