import { GoogleMapsPlaceDetails } from 'src/MessengerTypes';
import Services from 'src/services/Services';
import { IAddress } from 'src/gen/squareup/smsphoneregistry/verification';

const ADDRESS_COMPONENTS_TYPE_INDEX = 0;

/**
 * Api responsible for google related APIs.
 */
export default class GoogleApi {
  private _services: Services;

  constructor(services: Services) {
    this._services = services;
  }

  /**
   * For a given search query, search businesses related to it that is an establishment.
   *
   * @param {string} query
   * @param {string[]} [types]
   * Overrides the place types in the request. If not provided, defaults to only
   * establishment.
   * https://developers.google.com/maps/documentation/javascript/places-autocomplete#constrain-place-types
   * @returns {Promise<GoogleMapsPlaceDetails>[]}
   */
  getPlacePredictions = (
    query: string,
    types: string[] = ['establishment'],
  ): Promise<GoogleMapsPlaceDetails[]> => {
    return this._services.googleMaps
      .getPlacePredictions({
        input: query,
        types,
      })
      .then((response) => {
        return response.predictions.map((prediction) => {
          return {
            name: prediction.structured_formatting.main_text,
            address: prediction.structured_formatting.secondary_text,
            placeId: prediction.place_id,
          };
        });
      });
  };

  /**
   * Get the business details from a given Google placeId.
   *
   * @param {string} placeId
   * @returns {Promise<GoogleMapsPlaceDetails>}
   */
  getPlaceDetails = (placeId: string): Promise<GoogleMapsPlaceDetails> => {
    return this._services.googleMaps
      .getPlaceDetails({
        placeId,
      })
      .then((response) => {
        return {
          name: response.name ?? '',
          address: response.formatted_address ?? '',
          placeId,
        };
      });
  };

  /**
   * Get the address parts from a given Google placeId.
   * The address component type names can be found here:
   * https://developers.google.com/maps/documentation/javascript/geocoding#GeocodingAddressTypes
   *
   * @param {string} placeId
   * @returns {Promise<IAddress>}
   */
  getPlaceAddressParts = (placeId: string): Promise<IAddress> => {
    return this._services.googleMaps
      .getPlaceDetails({
        placeId,
      })
      .then((response) => {
        const address: IAddress = {
          address1: '',
          address2: '',
          city: '',
          state: '',
          zipCode: '',
        };
        response.address_components?.forEach((component) => {
          switch (component.types[ADDRESS_COMPONENTS_TYPE_INDEX]) {
            case 'street_number':
              address.address1 = component.long_name;
              break;
            case 'route':
              address.address1 += ` ${component.long_name}`;
              break;
            case 'subpremise':
              address.address2 = component.long_name;
              break;
            case 'locality':
              address.city = component.long_name;
              break;
            case 'administrative_area_level_1':
              address.state = component.short_name;
              break;
            case 'postal_code':
              address.zipCode = component.long_name;
              break;
            default:
          }
        });

        // Trim address1 because 'street_number' can be missing sometimes, leaving a leading
        // whitespace when we set address1 with 'route'. Other fields do not need trimming
        // because it only correspond to one Google address component each.
        address.address1 = address.address1?.trim();
        return address;
      });
  };
}
