import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import UtteranceBubble from 'src/pages/TranscriptViewPage/components/Utterance/components/UtteranceBubble/UtteranceBubble';
import {
  Utterance as UtteranceProto,
  Utterance,
} from 'src/gen/squareup/messenger/v3/messenger_service';
import { LocalUtterance, Photo } from 'src/MessengerTypes';
import {
  getPhotosFromAttachments,
  getPhotosFromLocalUtterance,
} from 'src/utils/photoUtils';
import UtterancePhotos from 'src/pages/TranscriptViewPage/components/Utterance/components/UtterancePhotos/UtterancePhotos';
import UtteranceText from 'src/pages/TranscriptViewPage/components/Utterance/components/UtteranceText/UtteranceText';
import './UtteranceContent.scss';
import { getFilesFromLocalUtterance } from 'src/utils/fileUtils';
import UtteranceFiles from 'src/pages/TranscriptViewPage/components/Utterance/components/UtteranceFiles/UtteranceFiles';

export type UtteranceContentProps = {
  localUtterance: LocalUtterance;
  hasError?: boolean;
};

/**
 * Main content to display for an individual utterance.
 *
 * @example <UtteranceContent localUtterance={utterance} />
 * @param {LocalUtterance} localUtterance
 * The utterance to display.
 * @param {boolean} [hasError]
 * (Optional) If true, display a red background to indicate the utterance failed.
 * @returns {ReactElement | null}
 * @author wdetlor
 */
const UtteranceContent = observer(
  ({
    localUtterance,
    hasError,
  }: UtteranceContentProps): ReactElement | null => {
    const { speakerType, plainText, metadata, id } = localUtterance.utterance;

    if (!speakerType) {
      return null;
    }

    const photos: Photo[] = getPhotosFromLocalUtterance(localUtterance);
    const files = getFilesFromLocalUtterance(localUtterance);
    const isCustomer = speakerType === UtteranceProto.SpeakerType.CUSTOMER;
    const repliedToUtterance = metadata?.replyToUtterance;

    // Render Utterance with reply separately as the photos need to be within UtteranceBubble
    if (repliedToUtterance) {
      const speakerTypeClassName = isCustomer ? 'customer' : 'merchant';
      return (
        <UtteranceBubble
          speakerType={
            speakerType || Utterance.SpeakerType.SPEAKER_TYPE_UNRECOGNIZED
          }
          id={id}
        >
          <div
            className={`UtteranceContent__replied-to-${speakerTypeClassName}`}
          >
            {repliedToUtterance.attachments &&
              repliedToUtterance.attachments.length > 0 && (
                <UtterancePhotos
                  photos={getPhotosFromAttachments(
                    repliedToUtterance.attachments,
                  )}
                  alignment="LEFT"
                />
              )}
            {repliedToUtterance.plainText && repliedToUtterance.speakerType && (
              <UtteranceText
                text={repliedToUtterance.plainText}
                speakerType={repliedToUtterance.speakerType}
                id={repliedToUtterance.id}
              />
            )}
          </div>
          {photos.length > 0 && (
            <div className="UtteranceContent__photos">
              <UtterancePhotos photos={photos} alignment="LEFT" />
            </div>
          )}
          {plainText && speakerType && (
            <UtteranceText text={plainText} speakerType={speakerType} id={id} />
          )}
        </UtteranceBubble>
      );
    }

    return (
      <>
        {photos.length > 0 && (
          <UtterancePhotos
            photos={photos}
            alignment={isCustomer ? 'LEFT' : 'RIGHT'}
          />
        )}
        {files.length > 0 && (
          <UtteranceFiles files={files} hasError={hasError} utteranceId={id} />
        )}
        {plainText && (
          <UtteranceBubble
            speakerType={speakerType}
            hasError={hasError}
            id={id}
          >
            <UtteranceText text={plainText} speakerType={speakerType} id={id} />
          </UtteranceBubble>
        )}
      </>
    );
  },
);

export default UtteranceContent;
