export type OS = 'Windows' | 'Mac' | 'Linux' | 'Android' | 'Unknown';

/**
 * Get the OS of the user.
 * https://www.whatismybrowser.com/guides/the-latest-user-agent/
 */
export function getOS(): OS {
  const userAgent = navigator.userAgent;
  let os: OS;

  if (userAgent.includes('Windows')) {
    os = 'Windows';
  } else if (userAgent.includes('Mac')) {
    // includes iOS
    os = 'Mac';
  } else if (userAgent.includes('Linux')) {
    os = 'Linux';
  } else if (userAgent.includes('Android')) {
    os = 'Android';
  } else {
    os = 'Unknown';
  }

  return os;
}
