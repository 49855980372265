/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "risk": {
        "nested": {
          "RiskManagerEnrollmentStatus": {
            "values": {
              "NOT_ENROLLED": 0,
              "ENROLLED": 1,
              "ENROLLED_FREE_TRIAL": 2,
              "ENROLLED_FREE": 3,
              "ENROLLED_DISCOUNTED": 4,
              "ENROLLED_CBP": 5
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.risk?.nested?.RiskManagerEnrollmentStatus) {
	$root.addJSON(jsonData);
}

/**
 * Risk Manager enrollment status. Used in the UnitPaymentData/Risk Metadata for tracking enrollment
 * on a unit basis.
 *
 * SOURCE RiskManagerEnrollmentStatus @ squareup/risk-metadata-protos/risk_manager_enrollment_status.proto at 11:1
 */
export const RiskManagerEnrollmentStatus = $root.lookupEnum('squareup.risk.RiskManagerEnrollmentStatus').values;
