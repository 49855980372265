import { SUPPORTED_LOCALE, SupportedLocale, Unit } from 'src/MessengerTypes';
import { UnitDedicatedNumber } from 'src/gen/squareup/messenger/v3/messenger_service';

/**
 * Compare units by the name, i.e. sort by alphabetical order.
 *
 * @param {Unit} a
 * @param {Unit} b
 */
export const unitsComparator = (a: Unit, b: Unit): number => {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
};

/**
 * Filter a list of units with the given filter function, and only return active
 * units which are sorted in alphabetically order.
 *
 * @param {Unit[]} units
 * @param {(unit: Unit) => boolean} filter
 * @returns {Unit[]}
 */
export const filterAndSortUnits = (
  units: Unit[],
  filter: (unit: Unit) => boolean,
): Unit[] => {
  return units
    .filter(filter)
    .filter((unit) => unit.isActive)
    .sort(unitsComparator);
};

/**
 * Check if a string is a supported locale.
 *
 * @param {string} locale
 * @returns {boolean}
 */
export const isSupportedLocale = (
  locale: string,
): locale is SupportedLocale => {
  return SUPPORTED_LOCALE.includes(locale as SupportedLocale);
};

/**
 * Check if a unit dedicated number status is considered in pending TFV.
 *
 * @param {UnitDedicatedNumber.Status} status
 * @returns {boolean}
 */
export const isPendingDedicatedNumberStatus = (
  status: UnitDedicatedNumber.Status | undefined,
): boolean => {
  return (
    status === UnitDedicatedNumber.Status.PENDING ||
    status === UnitDedicatedNumber.Status.UNVERIFIED ||
    status === UnitDedicatedNumber.Status.FAILED_RETRYABLE ||
    status === UnitDedicatedNumber.Status.CANCELED_PENDING ||
    status === UnitDedicatedNumber.Status.FAILED_NONRETRYABLE
  );
};
