import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { MarketBanner } from 'src/components/Market';
import { useTranslation } from 'react-i18next';
import { getReactivateLocationUrl } from 'src/utils/url';

type InactiveBannerProps = {
  unitToken: string;
};

/**
 * A banner to show when the unit of the transcript is deactivated, and no messages
 * can be sent.
 *
 * @param {string} unitToken
 * The unit token of the transcript we are on.
 */
const InactiveBanner = observer(
  ({ unitToken }: InactiveBannerProps): ReactElement => {
    const { t } = useTranslation();

    return (
      <MarketBanner variant="info" data-testid="InactiveBanner">
        {t('InactiveBanner.description')}
        <a
          slot="action"
          href={getReactivateLocationUrl(unitToken)}
          target="_blank"
          rel="noreferrer"
        >
          {t('InactiveBanner.button_text')}
        </a>
      </MarketBanner>
    );
  },
);

export default InactiveBanner;
