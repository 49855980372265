/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../Fixes';
import Builder from '../../../Builder';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "appointments": {
        "nested": {
          "common": {
            "nested": {
              "ReservationSegment": {
                "fields": {
                  "lineItemId": {
                    "type": "string",
                    "id": 1,
                    "rule": "optional"
                  },
                  "ordinal": {
                    "type": "int32",
                    "id": 2,
                    "rule": "optional"
                  },
                  "teamMemberId": {
                    "type": "string",
                    "id": 3,
                    "rule": "optional"
                  },
                  "resourceId": {
                    "type": "string",
                    "id": 4,
                    "rule": "optional"
                  },
                  "serviceTimeSeconds": {
                    "type": "int32",
                    "id": 5,
                    "rule": "optional"
                  },
                  "intermissionStartOffsetSeconds": {
                    "type": "int32",
                    "id": 6,
                    "rule": "optional"
                  },
                  "intermissionEndOffsetSeconds": {
                    "type": "int32",
                    "id": 7,
                    "rule": "optional"
                  },
                  "itemName": {
                    "type": "string",
                    "id": 8,
                    "rule": "optional"
                  },
                  "itemToken": {
                    "type": "string",
                    "id": 9,
                    "rule": "optional"
                  },
                  "itemVersion": {
                    "type": "int64",
                    "id": 10,
                    "rule": "optional"
                  },
                  "itemVariationName": {
                    "type": "string",
                    "id": 11,
                    "rule": "optional"
                  },
                  "itemVariationToken": {
                    "type": "string",
                    "id": 12,
                    "rule": "optional"
                  },
                  "itemVariationVersion": {
                    "type": "int64",
                    "id": 13,
                    "rule": "optional"
                  },
                  "anyAvailableTeamMember": {
                    "type": "bool",
                    "id": 14,
                    "rule": "optional",
                    "options": {
                      "default": false
                    }
                  },
                  "teamMemberFirstName": {
                    "type": "string",
                    "id": 15,
                    "rule": "optional"
                  },
                  "teamMemberLastName": {
                    "type": "string",
                    "id": 16,
                    "rule": "optional"
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.appointments?.nested?.common?.nested?.ReservationSegment) {
	$root.addJSON(jsonData);
}

/**
 * SOURCE ReservationSegment @ squareup/appointments/common/model.proto at 7:1
 */
export const ReservationSegment = $root.lookupType('squareup.appointments.common.ReservationSegment');
fixType(ReservationSegment);
Builder.createAndAttachToType(ReservationSegment);
