import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import 'src/pages/TranscriptViewPage/components/Utterance/Utterance.scss';
import AssistantIcon from 'src/svgs/AssistantIcon';
import './ServiceUtteranceSendStatus.scss';

export type ServiceUtteranceSendStatusProps = {
  hasError: boolean;
};

/**
 * Given a service utterance, render either the "Automated" footer or a send status
 * depending on whether the utterance was delivered successfully.
 *
 * @example
 * Basic usage:
 * <ServiceUtteranceSendStatus hasError=false />
 * @param {boolean} hasError
 * True if the utterance failed to send
 * @author eblaine
 */
const ServiceUtteranceSendStatus = observer(
  (props: ServiceUtteranceSendStatusProps): ReactElement => {
    const { hasError } = props;
    const { t } = useTranslation();

    return (
      <span className="Utterance__footer ServiceUtteranceSendStatus">
        {hasError ? (
          <>
            <span className="Utterance__footer__text Utterance__footer__text--failed">
              {t('Utterance.notDelivered')}
            </span>
          </>
        ) : (
          <>
            <AssistantIcon />
            <span className="Utterance__footer__text">
              {t('Utterance.automated')}
            </span>
          </>
        )}
      </span>
    );
  },
);

export default ServiceUtteranceSendStatus;
