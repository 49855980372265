/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';
import '../common/token';
import './types';
import './cart';
import './itemized_refund';
import './creator_details';
import './client_details';
import './tender';
import './refund_state';
import '../common/currency';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "bills": {
        "nested": {
          "BillRefund": {
            "fields": {
              "refundTokenPair": {
                "type": "squareup.common.token.TokenPair",
                "id": 1,
                "rule": "optional"
              },
              "createdAt": {
                "type": "squareup.bills.DatePair",
                "id": 2,
                "rule": "optional"
              },
              "refundReason": {
                "type": "string",
                "id": 3,
                "rule": "optional"
              },
              "amounts": {
                "type": "squareup.bills.Cart.Amounts",
                "id": 4,
                "rule": "optional"
              },
              "cartDetails": {
                "type": "squareup.bills.ItemizedRefundCartDetails",
                "id": 5,
                "rule": "optional"
              },
              "tenderRefund": {
                "type": "squareup.bills.TenderRefund",
                "id": 6,
                "rule": "repeated"
              },
              "overridingRefundTender": {
                "type": "squareup.bills.OverridingRefundTender",
                "id": 7,
                "rule": "optional"
              },
              "creatorDetails": {
                "type": "squareup.bills.CreatorDetails",
                "id": 8,
                "rule": "optional"
              },
              "clientDetails": {
                "type": "squareup.bills.ClientDetails",
                "id": 9,
                "rule": "optional"
              },
              "clientCashDrawerShiftId": {
                "type": "string",
                "id": 10,
                "rule": "optional"
              },
              "type": {
                "type": "squareup.bills.BillRefund.RefundType",
                "id": 11,
                "rule": "optional"
              },
              "isOmnibusMastered": {
                "type": "bool",
                "id": 12,
                "rule": "optional",
                "options": {
                  "default": false
                }
              },
              "billServerToken": {
                "type": "string",
                "id": 13,
                "rule": "optional"
              }
            },
            "nested": {
              "RefundType": {
                "values": {
                  "FULL_REFUND": 1,
                  "AMOUNT_PARTIAL_REFUND": 2,
                  "ITEMIZED_PARTIAL_REFUND": 3
                }
              }
            }
          },
          "TenderRefund": {
            "fields": {
              "tenderRefundToken": {
                "type": "string",
                "id": 1,
                "rule": "optional"
              },
              "tenderTokenPair": {
                "type": "squareup.common.token.TokenPair",
                "id": 2,
                "rule": "optional"
              },
              "amounts": {
                "type": "squareup.bills.Tender.Amounts",
                "id": 3,
                "rule": "optional"
              },
              "refundState": {
                "type": "squareup.bills.RefundState",
                "id": 4,
                "rule": "optional"
              },
              "stateUpdatedAt": {
                "type": "squareup.bills.ISO8601Date",
                "id": 5,
                "rule": "optional"
              },
              "forceApproved": {
                "type": "bool",
                "id": 6,
                "rule": "optional"
              },
              "validationOptions": {
                "type": "squareup.bills.TenderRefund.ValidationOptions",
                "id": 8,
                "rule": "optional"
              }
            },
            "nested": {
              "ValidationOptions": {
                "fields": {
                  "ignoreForAmountValidation": {
                    "type": "bool",
                    "id": 1,
                    "rule": "optional"
                  },
                  "ignoreForDateValidation": {
                    "type": "bool",
                    "id": 2,
                    "rule": "optional"
                  }
                }
              }
            }
          },
          "OverridingRefundTender": {
            "fields": {
              "tenderType": {
                "type": "squareup.bills.Tender.Type",
                "id": 1,
                "rule": "optional"
              },
              "cardTender": {
                "type": "squareup.bills.OverridingRefundTender.OverridingCardTender",
                "id": 2,
                "rule": "optional"
              }
            },
            "nested": {
              "OverridingCardTender": {
                "fields": {
                  "card": {
                    "type": "squareup.bills.CardTender.Card",
                    "id": 1,
                    "rule": "optional"
                  },
                  "giftCardToken": {
                    "type": "string",
                    "id": 2,
                    "rule": "optional"
                  }
                }
              }
            }
          },
          "RefundV1": {
            "fields": {
              "refundTokenPair": {
                "type": "squareup.common.token.TokenPair",
                "id": 1,
                "rule": "optional"
              },
              "state": {
                "type": "squareup.bills.RefundV1.State",
                "id": 2,
                "rule": "optional"
              },
              "createdAt": {
                "type": "squareup.bills.DatePair",
                "id": 3,
                "rule": "optional"
              },
              "refundReason": {
                "type": "string",
                "id": 4,
                "rule": "optional"
              },
              "amounts": {
                "type": "squareup.bills.RefundV1.Amounts",
                "id": 5,
                "rule": "optional"
              },
              "creatorDetails": {
                "type": "squareup.bills.CreatorDetails",
                "id": 6,
                "rule": "optional"
              },
              "tenderTokenPair": {
                "type": "squareup.common.token.TokenPair",
                "id": 7,
                "rule": "optional"
              },
              "refundUuid": {
                "type": "string",
                "id": 8,
                "rule": "optional"
              }
            },
            "nested": {
              "State": {
                "values": {
                  "UNKNOWN": 0,
                  "REQUESTED": 1,
                  "APPROVED": 2,
                  "COMPLETED": 3,
                  "REJECTED": 4,
                  "FAILED": 5
                }
              },
              "Amounts": {
                "fields": {
                  "refundedMoney": {
                    "type": "squareup.common.Money",
                    "id": 1,
                    "rule": "optional"
                  },
                  "refundedProcessingFeeMoney": {
                    "type": "squareup.common.Money",
                    "id": 2,
                    "rule": "optional"
                  },
                  "refundedProcessingFeeAmounts": {
                    "type": "squareup.bills.RefundV1.Amounts.ProcessingFeeAmounts",
                    "id": 3,
                    "rule": "optional"
                  }
                },
                "nested": {
                  "ProcessingFeeAmounts": {
                    "fields": {
                      "squareProcessingFeeMoney": {
                        "type": "squareup.common.Money",
                        "id": 1,
                        "rule": "optional"
                      },
                      "taxOnProcessingFeeMoney": {
                        "type": "squareup.common.Money",
                        "id": 2,
                        "rule": "optional"
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.bills?.nested?.BillRefund && !$root.nested?.squareup?.nested?.bills?.nested?.BillRefund?.nested?.RefundType && !$root.nested?.squareup?.nested?.bills?.nested?.TenderRefund && !$root.nested?.squareup?.nested?.bills?.nested?.TenderRefund?.nested?.ValidationOptions && !$root.nested?.squareup?.nested?.bills?.nested?.OverridingRefundTender && !$root.nested?.squareup?.nested?.bills?.nested?.OverridingRefundTender?.nested?.OverridingCardTender && !$root.nested?.squareup?.nested?.bills?.nested?.RefundV1 && !$root.nested?.squareup?.nested?.bills?.nested?.RefundV1?.nested?.State && !$root.nested?.squareup?.nested?.bills?.nested?.RefundV1?.nested?.Amounts && !$root.nested?.squareup?.nested?.bills?.nested?.RefundV1?.nested?.Amounts?.nested?.ProcessingFeeAmounts) {
	$root.addJSON(jsonData);
}

/**
 * Represents a refund associated with a bill.
 * TODO(anjan, wei): add translation in Kelly so that we can delete deprecated fields.
 * Next tag: 14
 *
 * SOURCE BillRefund @ squareup/bills/refund.proto at 23:1
 */
export const BillRefund = $root.lookupType('squareup.bills.BillRefund');
fixType(BillRefund, {
  refundTokenPair: { required: true },
  createdAt: { required: true },
  refundReason: { notEmpty: true },
  amounts: { required: true },
  clientDetails: { required: true },
});
Builder.createAndAttachToType(BillRefund);
/**
 * SOURCE RefundType @ squareup/bills/refund.proto at 80:3
 */
BillRefund.RefundType = $root.lookupEnum('squareup.bills.BillRefund.RefundType').values;
/**
 * A BillRefund contains a TenderRefund for each tender being refunded. In case
 * an overriding_refund_tender is specified, the overriding tender will be credited.
 *
 * SOURCE TenderRefund @ squareup/bills/refund.proto at 107:1
 */
export const TenderRefund = $root.lookupType('squareup.bills.TenderRefund');
fixType(TenderRefund, {
  tenderTokenPair: { required: true },
  amounts: { required: true },
});
Builder.createAndAttachToType(TenderRefund);
/**
 * SOURCE ValidationOptions @ squareup/bills/refund.proto at 135:3
 */
TenderRefund.ValidationOptions = $root.lookupType('squareup.bills.TenderRefund.ValidationOptions');
fixType(TenderRefund.ValidationOptions);
Builder.createAndAttachToType(TenderRefund.ValidationOptions);
/**
 * Metadata for when refunding to a new tender.
 *
 * SOURCE OverridingRefundTender @ squareup/bills/refund.proto at 158:1
 */
export const OverridingRefundTender = $root.lookupType('squareup.bills.OverridingRefundTender');
fixType(OverridingRefundTender, {
  tenderType: { required: true },
});
Builder.createAndAttachToType(OverridingRefundTender);
/**
 * SOURCE OverridingCardTender @ squareup/bills/refund.proto at 168:3
 */
OverridingRefundTender.OverridingCardTender = $root.lookupType('squareup.bills.OverridingRefundTender.OverridingCardTender');
fixType(OverridingRefundTender.OverridingCardTender, {
  card: { required: true },
});
Builder.createAndAttachToType(OverridingRefundTender.OverridingCardTender);
/**
 * SOURCE RefundV1 @ squareup/bills/refund.proto at 185:1
 */
export const RefundV1 = $root.lookupType('squareup.bills.RefundV1');
fixType(RefundV1, {
  refundTokenPair: { required: true },
  state: { required: true },
  createdAt: { required: true },
  amounts: { required: true },
  tenderTokenPair: { required: true },
});
Builder.createAndAttachToType(RefundV1);
/**
 * SOURCE State @ squareup/bills/refund.proto at 199:3
 */
RefundV1.State = $root.lookupEnum('squareup.bills.RefundV1.State').values;
/**
 * SOURCE Amounts @ squareup/bills/refund.proto at 216:3
 */
RefundV1.Amounts = $root.lookupType('squareup.bills.RefundV1.Amounts');
fixType(RefundV1.Amounts);
Builder.createAndAttachToType(RefundV1.Amounts);
/**
 * This is the breakdown for refunded_processsing_fee_money.
 * refunded_processing_fee_money = square_processing_fee_money + tax_on_processing_fee_money
 *
 * SOURCE ProcessingFeeAmounts @ squareup/bills/refund.proto at 226:5
 */
RefundV1.Amounts.ProcessingFeeAmounts = $root.lookupType('squareup.bills.RefundV1.Amounts.ProcessingFeeAmounts');
fixType(RefundV1.Amounts.ProcessingFeeAmounts);
Builder.createAndAttachToType(RefundV1.Amounts.ProcessingFeeAmounts);
