/**
 * This file is a centralized place to put all the url references used in Messenger.
 *
 * URLs should be defined as constants where possible. If dynamic parameters are necessary,
 * a function should be used instead.
 */
import { getEnvironment } from './initUtils';
import { MessengerUrlState, MessengerPageName } from 'src/MessengerTypes';
import Logger from 'src/Logger';

export const INBOX_STAGING_ORIGIN = 'https://inbox.squareupstaging.com';
export const INBOX_PROD_ORIGIN = 'https://inbox.squareup.com';
export const FULL_PAGE_INBOX_URL =
  getEnvironment() === 'staging' ? INBOX_STAGING_ORIGIN : INBOX_PROD_ORIGIN;

export const APP_SQ_STAGING_ORIGIN = 'https://app.squareupstaging.com';
export const API_SQ_STAGING_ORIGIN = 'https://api.squareupstaging.com';

export const APP_SQ_PROD_ORIGIN = 'https://app.squareup.com';
export const API_SQ_PROD_ORIGIN = 'https://api.squareup.com';

export const isInboxSubdomain = (): boolean =>
  window.location.origin === INBOX_PROD_ORIGIN ||
  window.location.origin === INBOX_STAGING_ORIGIN;

export const isInboxSubdomainOrDevEnv = (): boolean =>
  isInboxSubdomain() || process.env.NODE_ENV === 'development';

// URLs with relative pathing should be wrapped by this function
const prependOriginIfNeeded = (path: string): string => {
  if (window.location.origin === INBOX_STAGING_ORIGIN) {
    return `${APP_SQ_STAGING_ORIGIN}${path}`;
  }
  if (window.location.origin === INBOX_PROD_ORIGIN) {
    return `${APP_SQ_PROD_ORIGIN}${path}`;
  }
  // If not on inbox subdomain, return only the path to use the current page origin
  return path;
};

const prependApiOriginIfNeeded = (path: string): string => {
  if (window.location.origin === INBOX_STAGING_ORIGIN) {
    return `${API_SQ_STAGING_ORIGIN}${path}`;
  }
  if (window.location.origin === INBOX_PROD_ORIGIN) {
    return `${API_SQ_PROD_ORIGIN}${path}`;
  }
  // If not on inbox subdomain, return only the path to use the current page origin
  return path;
};

export const getOrigin = (): string => {
  if (getEnvironment() === 'production') {
    return API_SQ_PROD_ORIGIN;
  } else {
    return API_SQ_STAGING_ORIGIN;
  }
};

// Link to support contact page
export const SUPPORT_URL = prependOriginIfNeeded('/help/contact');

// Link to Messages get started page
export const GET_STARTED_URL = prependOriginIfNeeded(
  '/help/article/7370-square-messages-get-started-guide',
);

export const CONSENT_URL = prependOriginIfNeeded(
  '/help/article/7370-square-messages-get-started-guide#f7f87b953583fd6b7b60dd318435b7ab',
);

// Link to using assistant with appointments
export const ASSISTANT_ON_APPOINTMENTS_URL = prependOriginIfNeeded(
  '/help/article/6731-get-started-with-square-assistant-on-appointments',
);

// Link to use checkout links with Online Checkout
export const getCheckoutLinksUrl = prependOriginIfNeeded(
  '/dashboard/ecom/online-checkout/checkout-links',
);

// Link to learn about Online Checkout setup
// TODO(eblaine): Replace this with the correct link once its ready
export const GET_STARTED_CHECKOUT_LINKS_URL = prependOriginIfNeeded(
  '/help/article/7370-square-messages-get-started-guide',
);

// Link to verify identity
export const getVerifyIdentityUrl = prependOriginIfNeeded(
  '/signup?v=verify-your-identity',
);

// Relative URL to customer directory
export const getCustomerDirectoryUrl = prependOriginIfNeeded(
  '/dashboard/customers/directory',
);

// Relative URL to team permissions
export const getTeamPermissionsUrl = prependOriginIfNeeded(
  '/dashboard/team/permissions',
);

// Relative URl to invoice creation
export const getCreateInvoiceBaseUrl = prependOriginIfNeeded(
  '/dashboard/invoices/new',
);

// Relative URL to estimate creation
export const getCreateEstimateBaseUrl = prependOriginIfNeeded(
  '/dashboard/invoices/estimates/new',
);

export const getCustomerMarketingUrl = prependOriginIfNeeded(
  '/dashboard/customers/marketing',
);

// URL to Google review page
export const GOOGLE_REVIEW_URL =
  'https://search.google.com/local/writereview?placeid=';

// URL to Google business landing page
export const GOOGLE_BUSINESS_URL = 'https://www.google.com/business/';

export const getLaunchPadUrl = prependOriginIfNeeded('/launchpad');

export const getDashboardPricingAndSubscriptionsUrl = prependOriginIfNeeded(
  '/dashboard/account/pricing',
);

export const getMPlusSubscriptionDashboardUrl = prependOriginIfNeeded(
  '/dashboard/account/pricing?source=messages-manage&planToken=messages-plus',
);

export const getDashboardSubscriptionsUpdatePaymentUrl = prependOriginIfNeeded(
  '/dashboard/account/pricing?source=messages-update-payment&planToken=messages-plus',
);

export const getDashboardSubscriptionsAddPaymentUrl = prependOriginIfNeeded(
  '/dashboard/account/pricing?source=messages-update-payment-during-subscription&planToken=messages-plus',
);

export const FULL_PAGE_INBOX_SHARE_FEEDBACK_URL =
  'https://squareinsights.getfeedback.com/r/tRGuQsgd';

export const getLoginPageWithReturnToUrl = (): string =>
  prependOriginIfNeeded(
    `/login?return_to=${encodeURIComponent(window.location.href)}`,
  );

export const getCreateAppointmentBaseUrl = prependOriginIfNeeded(
  '/appointments/reservations/new',
);

export const getBuyerBookingFlowUrl = (merchantToken: string): string =>
  prependOriginIfNeeded(`/book/business/${merchantToken}`);

// A link to appointments where merchants can enable their buyer booking site
export const getBookingChannelsUrl = prependOriginIfNeeded(
  `/dashboard/appointments/booking/channels`,
);

// A link to manage location sheet on Dashboard where a location can be reactivated
export const getReactivateLocationUrl = (unitToken: string): string =>
  prependOriginIfNeeded(`/dashboard/locations/${unitToken}`);

// https://git.sqcorp.co/projects/SQ/repos/web/browse/app/identities/controllers/api/v2/merchant_subunits_controller.rb#81
export const UNITS_URL = prependApiOriginIfNeeded(
  '/api/v2/multiunit/subunits?all_merchant_units=false&include_profile=true',
);

export const MULTIPASS_URL = prependOriginIfNeeded('/mp');

export const MULTIPASS_STATUS_URL = prependApiOriginIfNeeded('/mp/status');

export const INBOX_TRANSCRIPT_VIEW_URL_REGEX = new RegExp(
  '^\\/t\\/(\\d+)\\/?$',
);

export const INBOX_MESSAGES_PLUS_UNIT_VERIFICATION_REGEX = new RegExp(
  '^\\/messages-plus\\/verify-units\\/([a-zA-Z0-9]+)$',
);

// Returns unit token from path param in first index of match
export const INBOX_EDIT_VOICEMAIL_URL_REGEX = new RegExp(
  '^\\/settings\\/voicemail\\/([a-zA-Z0-9]+)$',
);

export const INBOX_NEW_URL = '/new';

export const INBOX_SETTINGS_URL = '/settings';

export const INBOX_BUSINESS_NUMBERS_URL = '/settings/business-numbers';

export const INBOX_SQ_ONLINE_SETTINGS_URL =
  '/settings/messages-plugin/sq-online-websites';

export const INBOX_MESSAGES_PLUS_PRICING_URL = '/messages-plus/pricing';

/**
 * @deprecated Redirects to /messages-plus/verify-units/success
 * TODO (teresalin) Remove support after 2024-07-01 and from Fastly
 */
export const INBOX_MESSAGES_PLUS_SUCCESS_URL = '/messages-plus/success';

/**
 * @deprecated Redirects to /messages-plus/verify-units/success
 * TODO (teresalin) Remove support after 2024-07-01 and from Fastly
 */
export const INBOX_MESSAGES_PLUS_SUCCESS_NUMBERS_URL = '/messages-plus/numbers';

export const INBOX_MESSAGES_PLUS_UNITS_TO_VERIFY_URL =
  '/messages-plus/verify-units';

export const INBOX_MESSAGES_PLUS_UNIT_VERIFICATION_SUCCESS_URL =
  '/messages-plus/verify-units/success';

export const INBOX_MESSAGES_PLUS_START_VERIFICATION_URL =
  '/messages-plus/start-verification';

export const INBOX_HOME_URL = '/';

export const INBOX_ASSISTANT_URL = '/assistant';

// @deprecated Previously launched URL that now redirects to /settings
export const LINK_PLUGIN_URL = '/link-plugin';

export const INBOX_SEARCH_URL = '/search';

export const SHEET_PAGE_ID_TO_URL: Record<string, string> = {
  SETTINGS: INBOX_SETTINGS_URL,
  MESSAGES_PLUS_PRICING: INBOX_MESSAGES_PLUS_PRICING_URL,
  BUSINESS_NUMBERS: INBOX_BUSINESS_NUMBERS_URL,
  UNIT_VERIFICATION_SUCCESS: INBOX_MESSAGES_PLUS_UNIT_VERIFICATION_SUCCESS_URL,
  MESSAGES_PLUS_START_VERIFICATION: INBOX_MESSAGES_PLUS_START_VERIFICATION_URL,
  SQ_ONLINE_SETTINGS: INBOX_SQ_ONLINE_SETTINGS_URL,
};

export const PLUGIN_FEEDBACK_URL =
  'https://squareinsights.getfeedback.com/r/g740eUsV/';

export const PLUGIN_HELP_URL = prependOriginIfNeeded(
  '/help/article/7926-get-started-with-square-messages-plugin',
);

export const M_PLUS_FAQ_URL = prependOriginIfNeeded(
  '/help/article/8011-square-messages-plus-faq',
);

export const M_PLUS_BUSINESS_NUMBER_FAQ_URL = prependOriginIfNeeded(
  '/help/article/8011-square-messages-plus-faq#5fd592ee351b3f474208cd6ad975f111',
);

export const M_PLUS_SUBSCRIPTION_FAQ_URL = prependOriginIfNeeded(
  '/help/article/8011-square-messages-plus-faq#787ad0b7a17de4ad6b1711bbf8d79fcb',
);

export const M_PLUS_GET_STARTED_URL = prependOriginIfNeeded(
  '/help/article/7985-get-started-with-square-messages-plus',
);

export const M_PLUS_TERMS_URL = prependOriginIfNeeded('/legal/general/pos');

export const M_PLUS_PRIVACY_POLICY_URL =
  prependOriginIfNeeded('/legal/privacy');

export const MESSAGES_PLUGIN_OPT_OUT_FORM_URL =
  'https://forms.gle/JqzW2J6kqiMNbRhQ6';

export const MESSAGES_PLUGIN_STAGING_JS_BUNDLE_URL =
  'https://conversations-staging-f.squarecdn.com/messages-plugin.js';

export const MESSAGES_PLUGIN_PROD_JS_BUNDLE_URL =
  'https://conversations-production-f.squarecdn.com/messages-plugin.js';

export const getUnitVerificationFormUrl = (unitToken: string): string =>
  `${INBOX_MESSAGES_PLUS_UNITS_TO_VERIFY_URL}/${unitToken}`;

export const getEditVoicemailUrl = (unitToken: string): string =>
  `${INBOX_SETTINGS_URL}/voicemail/${unitToken}`;

// Relative URL to Square Go dashboard
export const getSquareGoDashboardUrl = prependOriginIfNeeded(
  '/dashboard/appointments/marketplace?source=conversations',
);

export const getInboxTranscriptViewUrl = (id: number): string => `/t/${id}`;

export const getInboxTranscriptViewUrlWithOrigin = (id: number): string =>
  `${FULL_PAGE_INBOX_URL}${getInboxTranscriptViewUrl(id)}`;

// Hardcoded to always call the app subdomain. Currently only used for the Messages service
// and basing whether to use the app subdomain on a feature flag is not possible in the services file.
export const getServicesUrl = (service: string, rpc: string): string =>
  prependApiOriginIfNeeded(`/services/${service}/${rpc}`);

export const getEditAppointmentUrl = (reservationId: string): string =>
  prependOriginIfNeeded(`/appointments/reservations/${reservationId}/edit`);

export const getEditRecurringAppointmentUrl = (
  reservationId: string,
  dateStart: string,
): string =>
  prependOriginIfNeeded(
    `/appointments/reservations/${reservationId}/r/${dateStart}/edit`,
  );

export const getCouponUrl = (couponId: string): string =>
  prependOriginIfNeeded(`/outreach/rewards/${couponId}`);

export const getTransactionLink = (paymentToken: string): string =>
  prependOriginIfNeeded(`/dashboard/sales/transactions/${paymentToken}`);

export const getCustomerInDirectoryLink = (customerToken: string): string =>
  `${getCustomerDirectoryUrl}/customer/${customerToken}`;

export const getSearchCustomerInDirectoryLink = (query: string): string =>
  `${getCustomerDirectoryUrl}/all?query=${encodeURIComponent(query)}`;

export const getCreateInvoiceUrl = (
  customerToken: string,
  unitToken: string,
  transcriptId: number,
): string =>
  `${getCreateInvoiceBaseUrl}?contactToken=${customerToken}&currentUnitToken=${unitToken}&transcript_id=${transcriptId}`;

export const getCreateEstimateUrl = (
  customerToken: string,
  unitToken: string,
  transcriptId: number,
): string =>
  `${getCreateEstimateBaseUrl}?contactToken=${customerToken}&currentUnitToken=${unitToken}&transcript_id=${transcriptId}`;

export const getCreateAppointmentUrl = (
  customerToken: string,
  unitToken: string,
  transcriptId: number,
): string =>
  `${getCreateAppointmentBaseUrl}?contactToken=${customerToken}&unitToken=${unitToken}&transcript_id=${transcriptId}`;

export const getGoogleReviewUrl = (placeId: string): string =>
  `${GOOGLE_REVIEW_URL}${placeId}`;

// For checkout link limit
export const settingsInfoUrl = (merchantToken: string): string =>
  prependOriginIfNeeded(
    `/v2/online-checkout-internal/settings-info/${merchantToken}`,
  );

export const INVOICES_DASHBOARD_URL = prependOriginIfNeeded(
  '/dashboard/invoices',
);

export const ESTIMATES_DASHBOARD_URL = prependOriginIfNeeded(
  '/dashboard/invoices/estimates',
);

export const PROJECTS_DASHBOARD_URL = prependOriginIfNeeded(
  '/dashboard/projects',
);

export const CONTRACTS_DASHBOARD_URL = prependOriginIfNeeded(
  '/dashboard/contracts',
);

/**
 * Sets the URL of the page to match the provided URL, if not already set.
 *
 * @param {string} url
 * The URL to set the page to.
 * @param {object} data
 * Metadata uniquely identifying the page.
 * @param {MessengerPageName} data.page
 * The messenger page name this URL corresponds to.
 * @param {number | string} [data.id]
 * A unique ID associated with the messenger page (i.e. transcript ID).
 */
export const setUrl = (url: string, data: MessengerUrlState): void => {
  if (window.location.pathname !== url) {
    window.history.pushState(data, '', url);
  }
};

export type UrlQueryParam =
  | 'DEV_TOOLS'
  | 'UTTERANCE_ID'
  | 'MESSAGES_PLUS_SOURCE'
  | 'UNIT_TOKENS'
  | 'SOURCE';

export const URL_QUERY_PARAMS: Record<UrlQueryParam, string> = {
  DEV_TOOLS: 'devtools',
  UTTERANCE_ID: 'u',
  MESSAGES_PLUS_SOURCE: 'messagesPlusSource',
  UNIT_TOKENS: 'ut',
  SOURCE: 'source',
};

/**
 * Helper function to check if the devtools argument is present in the query string
 * and if so, set the global logger to show the dev tools.
 */
export const checkDevToolsInQueryString = (): void => {
  const urlParams = new URLSearchParams(window.location.search); // The polyfill import ensures this exists
  const devTools: string | null = urlParams.get(URL_QUERY_PARAMS.DEV_TOOLS);

  if (devTools === 'true') {
    // Case: We want to activate dev tools. Note that the param is not cleared so that
    // dev tools will automatically open after a refresh. If the feature flag is not turned
    // on, this will do nothing as the DeveloperTools component will not be rendered.
    Logger.showDevTools = true;
  }
};

/**
 * Helper function to extract the utterance ID from the query string.
 * Returns undefined if no utterance ID is present in the query string.
 */
export const getUtteranceIdFromQueryString = (): number | undefined => {
  const urlParams = new URLSearchParams(window.location.search);
  const utteranceIdParam = urlParams.get(URL_QUERY_PARAMS.UTTERANCE_ID);
  if (utteranceIdParam) {
    try {
      const utteranceId = Number.parseInt(utteranceIdParam, 10);
      if (Number.isNaN(utteranceId)) {
        return undefined;
      }
      return utteranceId;
    } catch {
      return undefined;
    }
  }
  return undefined;
};

/**
 * Helper function to extract the source from the query string.
 * Returns undefined if no source is present in the query string.
 */
export const getSourceFromQueryString = (): string | undefined => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(URL_QUERY_PARAMS.SOURCE) || undefined;
};

/**
 * Helper function to extract the source of M+ onboarding from the query string.
 * Returns undefined if param is not found.
 */
export const getMessagesPlusSourceFromQueryString = (): string | undefined => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(URL_QUERY_PARAMS.MESSAGES_PLUS_SOURCE) || undefined;
};

/**
 * Helper function to extract the list of unit tokens from the query string.
 */
export const getUnitTokensFromQueryString = (): string[] => {
  const urlParams = new URLSearchParams(window.location.search);
  const unitTokens = urlParams.get(URL_QUERY_PARAMS.UNIT_TOKENS);
  return unitTokens?.split(',') || [];
};

/**
 * Helper function to determine if a provided URL is malformed
 *
 * @param {string} url
 * The URL to validate.
 */
export const isMalformedUrl = (url: string): boolean => {
  try {
    new URL(url); // will throw an error if the URL is malformed
    return false;
  } catch {
    return true;
  }
};

/**
 * Appends the protocol to the Square Online (ECOM) url, as it is not automatically provided:
 * https://prototype.sqprod.co/#/docs/squareup.connect.v2.ecom_connect.resources.Site#domain
 *
 * If protocol is already present, the url is returned as is.
 *
 * @param {string} url
 * Domain of the site without the protocol.
 */
export const getSquareOnlineUrl = (url: string): string => {
  if (url.startsWith('http://') || url.startsWith('https://')) {
    return url;
  }
  return `https://${url}`;
};
