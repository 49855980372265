import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import MessengerModalDialog from 'src/components/MessengerModalDialog/MessengerModalDialog';
import { MarketButton } from 'src/components/Market';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';

/**
 * A modal to let the merchant know that they sent too many messages
 * in a short period of time, used for spam protection.
 *
 * @example
 * Basic usage:
 * <SendLimitModal />
 * @author klim
 */
const SendLimitModal = observer((): ReactElement => {
  const { modal, event } = useMessengerControllerContext();
  const { closeModal } = modal;
  const { t } = useTranslation();

  return (
    <MessengerModalDialog
      title={t('SendLimitModal.title')}
      close={closeModal}
      primaryButton={
        <MarketButton
          rank="primary"
          onClick={() => {
            event.track('Click Send Limit Modal Dismiss');
            closeModal();
          }}
        >
          {t('common.dismiss')}
        </MarketButton>
      }
    >
      <p>{t('SendLimitModal.description')}</p>
    </MessengerModalDialog>
  );
});

export default SendLimitModal;
