import { Currency, IMoney } from 'src/gen/squareup/connect/v2/common/money';
import { Payment, SupportedLocale } from 'src/MessengerTypes';
import currency, { Options as CurrencyOptions } from 'currency.js';

/**
 * Non-breaking space, mainly used for fr-CA formatting.
 */
export const NBSP = ' ';

/**
 * Get the currency options used for parsing and formatting.
 *
 * @param {string} currencyCode - the currency to use for formatting, as
 * a string, e.g. 'USD'
 * @param {SupportedLocale} locale - the locale string, e.g. 'en-US'
 * @returns {currency.Options} the options
 */
export function _getCurrencyOptions(
  currencyCode: string,
  locale: SupportedLocale,
): CurrencyOptions {
  let symbol;
  let precision;
  switch (currencyCode) {
    case 'JPY':
      symbol = '¥';
      precision = 0;
      break;
    case 'USD':
    case 'CAD':
    default:
      symbol = '$';
      precision = 2;
  }

  let decimal;
  let separator;
  let pattern;
  let negativePattern;
  switch (locale) {
    case 'fr-CA':
      decimal = ',';
      separator = NBSP;
      pattern = `#${NBSP}!`; // amount + non-breaking space + currency symbol
      negativePattern = `-#${NBSP}!`;
      break;
    case 'en-US':
    case 'es-US':
    case 'en-CA':
    default: // currency symbol + amount
      decimal = '.';
      separator = ',';
      pattern = '!#';
      negativePattern = '-!#';
  }

  return {
    symbol,
    precision,
    decimal,
    separator,
    pattern,
    negativePattern,
  };
}

/**
 * Converts a currency key to the enum code.
 * e.g. 'USD' --> Currency.USD
 *
 * @param {string} currency - the key in string
 * @returns {Currency} the enum code
 */
export function currencyKeyToCode(currency: string): Currency {
  const key = currency as keyof typeof Currency;
  return Currency[key] ?? Currency.UNKNOWN_CURRENCY;
}

/**
 * Converts a currency enum code to key.
 * e.g. Currency.USD --> 'USD'
 *
 * @param {Currency} currency - the enum code
 * @returns {string} the key in string
 */
export function currencyCodeToKey(currency: Currency): string {
  return Currency[currency];
}

/**
 * Get the currency string from an amount. e.g. 1000 -> $10.00
 *
 * @param {number} amount - number value of currency
 * @param {string} currencyCode - the currency to use for formatting, as
 * a string, e.g. 'USD'
 * @param {SupportedLocale} locale - the locale string, e.g. 'en-US'
 * @param {object} options
 * @param {number} options.precision - number of decimal places to show
 * @returns {string} the formatted currency string
 */
export function amountToCurrencyString(
  amount: number,
  currencyCode: string,
  locale: SupportedLocale,
  options?: { precision?: number },
): string {
  const currencyOptions = _getCurrencyOptions(currencyCode, locale);
  return currency(amount, {
    fromCents: true,
    ...currencyOptions,
    ...options,
  }).format();
}

/**
 * Get the currency string from a Payment object.
 *
 * @param {Payment} money - object containing the amount and currency code
 * @param {SupportedLocale} locale - the locale string, e.g. 'en-US'
 * @returns {string} the formatted currency string
 */
export function paymentToCurrencyString(
  money: Payment,
  locale: SupportedLocale,
): string {
  const { amount, currencyCode } = money;
  return amountToCurrencyString(amount ?? 0, currencyCode ?? 'USD', locale);
}

/**
 * Get the currency string from a Money object.
 *
 * @param {Money} money - object containing the amount and currency code
 * @param {SupportedLocale} locale - the locale string, e.g. 'en-US'
 * @returns {string} the formatted currency string
 */
export function moneyToCurrencyString(
  money: IMoney,
  locale: SupportedLocale,
): string {
  const { amount, currency } = money;
  const currencyCode = Currency[currency ?? Currency.USD];
  return amountToCurrencyString(amount ?? 0, currencyCode, locale);
}

/**
 * Find a currency string in a text and return the amount based on the
 * smallest denominator of the country's currency. The text may contain
 * other strings that are not related to currency, e.g. "a donut for $1"
 *
 * @param {string} text - the text to extract currency from, e.g. "$1.00"
 * @param {string} currencyCode - the currency to use for formatting, as
 * a string, e.g. 'USD'
 * @param {SupportedLocale} locale - the locale string, e.g. 'en-US'
 * @returns {number} the amount of the currency
 */
export function textToCurrencyAmount(
  text: string,
  currencyCode: string,
  locale: SupportedLocale,
): number {
  const currencyOptions = _getCurrencyOptions(currencyCode, locale);
  return currency(text, currencyOptions).intValue ?? 0;
}

/**
 * Formats a text into a currency string for the specified locale, e.g.
 * $XX.XX for *-US or en-CA. If the string cannot be parsed as a float
 * or currency string, it returns an empty string.
 *
 * @param {string} text - the input string to format, e.g. "$1.00"
 * @param {string} currencyCode - the currency to use for formatting, as
 * a string, e.g. 'USD'
 * @param {SupportedLocale} locale - the locale string, e.g. 'en-US'
 * @returns {string} the formatted currency string
 */
export function textToCurrencyString(
  text: string,
  currencyCode: string,
  locale: SupportedLocale,
): string {
  const amount = textToCurrencyAmount(text, currencyCode, locale);
  if (amount) {
    return amountToCurrencyString(amount, currencyCode, locale);
  }
  return '';
}

/**
 * Converts currency code to symbol.
 *
 * @param {string} currencyCode
 * @param {SupportedLocale} locale
 */
export function currencyCodeToSymbol(
  currencyCode: string,
  locale: SupportedLocale,
): string {
  const currencyOptions = _getCurrencyOptions(currencyCode, locale);
  return currency(0, {
    fromCents: true,
    ...currencyOptions,
    pattern: '!',
  }).format();
}
