import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Medium } from 'src/gen/squareup/messenger/v3/messenger_service';
import { MarketBanner } from 'src/components/Market';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';

/**
 * Banner that is displayed above the input bar in the Transcript View when a transcript is blocked.
 */
const BlockedBanner = observer(() => {
  const { event, user, transcriptView } = useMessengerControllerContext();
  const { transcript } = transcriptView;
  const { t } = useTranslation();

  return (
    <MarketBanner>
      {t('BlockedBanner.message', {
        medium:
          transcript.medium === Medium.EMAIL
            ? t('common.mediumLongForm.email')
            : t('common.mediumLongForm.sms'),
      })}
      <button
        slot="action"
        onClick={() => {
          event.track('Click Unblock', {
            merchant_token: user.merchantToken,
            transcript_ids: [transcript.id],
          });
          transcriptView.unblockTranscript();
        }}
      >
        {t('BlockedBanner.action_label')}
      </button>
    </MarketBanner>
  );
});

export default BlockedBanner;
