
var content = require("!!../../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-5-1!../../../node_modules/postcss-loader/src/index.js??postcss!../../../node_modules/resolve-url-loader/index.js??ref--5-oneOf-5-3!../../../node_modules/sass-loader/dist/cjs.js??ref--5-oneOf-5-4!./MessengerToast.scss");

if(typeof content === 'string') content = [[module.id, content, '']];

var transform;
var insertInto;



var options = {"hmr":true}

options.transform = transform
options.insertInto = function () {
            // We default to querying against document to find where Messenger should render.
            // If a hostElement is available, we will render within it's shadowRoot
            let messengerHost = document;

            const hostElement = document.querySelector(
              `#${process.env.MESSENGER_HOST_ELEMENT_ID}`,
            );
            if (hostElement && hostElement.shadowRoot) {
              messengerHost = hostElement.shadowRoot;
            }

            // Embed into Messenger's shadow DOM root; create it if needed
            const messengerElement = messengerHost.querySelector(
              `#${process.env.MESSENGER_CONTENT_ELEMENT_ID}`,
            );
            if (messengerElement) {
              let shadow = messengerElement.shadowRoot;
              if (!shadow) {
                // create the shadow DOM root if it doesn't already exist
                shadow = messengerElement.attachShadow({ mode: 'open' });
              }
              return shadow;
            } else {
              throw Error(
                'No #messenger elem to embed Messenger CSS into (see webpack.config.js)',
              );
            }
          };

var update = require("!../../../node_modules/style-loader/lib/addStyles.js")(content, options);

if(content.locals) module.exports = content.locals;

if(module.hot) {
	module.hot.accept("!!../../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-5-1!../../../node_modules/postcss-loader/src/index.js??postcss!../../../node_modules/resolve-url-loader/index.js??ref--5-oneOf-5-3!../../../node_modules/sass-loader/dist/cjs.js??ref--5-oneOf-5-4!./MessengerToast.scss", function() {
		var newContent = require("!!../../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-5-1!../../../node_modules/postcss-loader/src/index.js??postcss!../../../node_modules/resolve-url-loader/index.js??ref--5-oneOf-5-3!../../../node_modules/sass-loader/dist/cjs.js??ref--5-oneOf-5-4!./MessengerToast.scss");

		if(typeof newContent === 'string') newContent = [[module.id, newContent, '']];

		var locals = (function(a, b) {
			var key, idx = 0;

			for(key in a) {
				if(!b || a[key] !== b[key]) return false;
				idx++;
			}

			for(key in b) idx--;

			return idx === 0;
		}(content.locals, newContent.locals));

		if(!locals) throw new Error('Aborting CSS HMR due to changed css-modules locals.');

		update(newContent);
	});

	module.hot.dispose(function() { update(); });
}