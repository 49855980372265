import React, { ReactElement } from 'react';
import BaseSvgProps from 'src/types/BaseSvgProps';

/**
 * Icon representing the action to record audio (microphone).
 */
const MicrophoneIcon = (props: BaseSvgProps): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="20"
    fill="none"
    viewBox="0 0 14 20"
    {...props}
  >
    <title>Microphone</title>
    <path
      fill="#000"
      fillOpacity=".9"
      fillRule="evenodd"
      d="M10 9c0 1.65-1.35 3-3 3s-3-1.35-3-3V3c0-1.65 1.35-3 3-3s3 1.35 3 3v6ZM7 2c-.55 0-1 .45-1 1v6c0 .55.45 1 1 1s1-.45 1-1V3c0-.55-.45-1-1-1Zm5 7h2c0 3.52-2.61 6.43-6 6.92V20H6v-4.08c-3.39-.49-6-3.4-6-6.92h2c0 2.76 2.24 5 5 5s5-2.24 5-5Z"
      clipRule="evenodd"
    />
  </svg>
);

export default MicrophoneIcon;
