import React, { ReactElement, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import MessengerModalPartial from 'src/components/MessengerModalPartial/MessengerModalPartial';
import { COUNTRIES } from 'src/i18n/countries';
import { i18nPartialPhoneNumber } from 'src/i18n/formatValues';
import { SupportedCountry } from 'src/MessengerTypes';
import { onKeyDownEnter } from 'src/utils/keyboardUtils';
import {
  MarketButton,
  MarketField,
  MarketInputText,
  MarketList,
  MarketRow,
  MarketSelect,
} from 'src/components/Market';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import { isValidEmail } from 'src/utils/validationUtils';
import { SHADOW_REACT_ROOT_SELECTOR } from 'src/utils/shadowDomUtils';

export type NewCustomerModalOnSaveArgs = {
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
};

export type NewCustomerModalProps = {
  initialFirstName?: string;
  initialLastName?: string;
  initialEmail?: string;
  initialPhoneNumber?: string;
  onSave: (args: NewCustomerModalOnSaveArgs) => Promise<void>;
};

/**
 * A modal that allows the user to create a new customer through
 * Rolodex. Validates input and does some minimal phone formatting.
 * - Needs either first or last name
 * - Needs either phone or email
 *
 * @param {(NewCustomerModalOnSaveArgs) => Promise<void>} onSave
 * A function called when save button is clicked
 * @param {string} [initialFirstName]
 * (Optional) Value initially displayed in the first name field.
 * @param {string} [initialLastName]
 * (Optional) Value initially displayed in the last name field.
 * @param {string} [initialEmail]
 * (Optional) Value initially displayed in the email field.
 * @param {string} [initialPhoneNumber]
 * (Optional) Value initially displayed in the phone number field.
 */
const NewCustomerModal = observer(
  (props: NewCustomerModalProps): ReactElement => {
    const {
      initialFirstName,
      initialLastName,
      initialEmail,
      initialPhoneNumber,
      onSave,
    } = props;
    const { modal, status, event } = useMessengerControllerContext();
    const { t } = useTranslation();

    const [firstName, setFirstName] = useState(initialFirstName ?? '');
    const [lastName, setLastName] = useState(initialLastName ?? '');
    const [email, setEmail] = useState(initialEmail ?? '');
    const [country, setCountry] = useState<SupportedCountry>('US');
    const formattedInitialPhone = initialPhoneNumber
      ? i18nPartialPhoneNumber(initialPhoneNumber, country)
      : '';
    // NOTE: It doesn't matter for contact creation because Rolodex
    // does its own phone normalization on the backend, but the phone
    // should always be properly formatted in onChange for readability.
    const [phone, setPhone] = useState(formattedInitialPhone);

    const [hasError, setHasError] = useState(false);
    const [isCreating, setIsCreating] = useState(false);
    const namesAreValid = Boolean(firstName) || Boolean(lastName);
    const hasAtLeast1Contact = Boolean(email) || Boolean(phone);

    const save = (): void => {
      setHasError(false);
      status.clear();

      if (!namesAreValid) {
        // Case: Missing names. They need at least 1 name.
        setHasError(true);
        status.setModalError({
          label: t('NewCustomer.modal.form.first_name.error'),
        });
        return;
      }

      if (!hasAtLeast1Contact) {
        // Case: Missing all contact info. Warning suggests adding phone or email.
        setHasError(true);
        status.setModalError({
          label: t('NewCustomer.modal.form.phone.error'),
        });
        return;
      }

      if (Boolean(email) && !isValidEmail(email)) {
        // Case: They put in some contacts, but at least one is invalid. Tell them to correct it.
        setHasError(true);
        status.setModalError({
          label: t('NewCustomer.modal.form.email.error'),
        });
        return;
      }

      setIsCreating(true);
      event.track('Click New Customer Modal Save', {
        first_name: firstName,
        last_name: lastName,
        maybe_first_name: initialFirstName,
        maybe_last_name: initialLastName,
      });
      onSave({
        firstName,
        lastName,
        email: email?.trim(),
        phoneNumber: phone,
      })
        .then(() => {
          modal.closeModal();
        })
        .catch(() => {
          if (!status.value) {
            status.setModalError();
          }
        })
        .finally(() => {
          setIsCreating(false);
        });
    };

    const primaryButton = (
      <MarketButton
        rank="primary"
        onClick={save}
        onKeyDown={(e) => onKeyDownEnter(e, save)}
        isLoading={isCreating || undefined}
      >
        {t('NewCustomer.modal.save_button')}
      </MarketButton>
    );

    // If there are one or more errors, we have to decide what to show in the
    // status banner. The order is:
    // 1. If we're missing both names, tell them to enter a name
    // 2. If we're missing both contacts, tell them to enter a contact
    // 3. If the email is invalid, tell them to fix it, regardless of
    //    whether there's already a phone number
    // else if (!hasValidData) {
    //   // Case: They put in some contacts, but at least one is invalid. Tell them to correct it.
    //   errorLabel = t('NewCustomer.modal.form.email.error');
    // }

    return (
      <MessengerModalPartial
        title={t('NewCustomer.modal.title')}
        primaryButton={primaryButton}
        close={() => {
          event.track('Click New Customer Modal Dismiss');
          modal.closeModal();
          status.clear();
        }}
        status={status.value}
        overflow
      >
        <form
          className="market-grid-container"
          onSubmit={(event) => {
            event.preventDefault();
          }}
        >
          <MarketField
            className="market-grid-item-medium"
            invalid={hasError && !namesAreValid}
          >
            <MarketInputText
              value={firstName}
              onMarketInputValueChange={(e) => setFirstName(e.detail.value)}
              data-testid="NewCustomerModal__first-name"
            >
              <label>{t('NewCustomer.modal.form.first_name.label')}</label>
            </MarketInputText>
          </MarketField>
          <MarketField
            className="market-grid-item-medium"
            invalid={hasError && !namesAreValid}
          >
            <MarketInputText
              value={lastName}
              onMarketInputValueChange={(e) => setLastName(e.detail.value)}
              data-testid="NewCustomerModal__last-name"
            >
              <label>{t('NewCustomer.modal.form.last_name.label')}</label>
            </MarketInputText>
          </MarketField>
          <MarketField className="market-grid-item-medium">
            <MarketSelect
              value={country}
              onMarketSelectValueDidChange={(e) =>
                setCountry(e.detail.value as SupportedCountry)
              }
              onClick={(e) => e.stopPropagation()}
              data-testid="NewCustomerModal__country"
              popoverContainer={SHADOW_REACT_ROOT_SELECTOR}
            >
              <label>{t('NewCustomer.modal.form.country')}</label>
              <MarketList slot="list">
                {COUNTRIES.map((country) => {
                  const { code, translationKey } = country;
                  return (
                    <MarketRow value={code} key={code}>
                      {t(translationKey)}
                    </MarketRow>
                  );
                })}
              </MarketList>
            </MarketSelect>
          </MarketField>
          <MarketField
            className="market-grid-item-medium"
            invalid={hasError && !hasAtLeast1Contact}
          >
            {/* Pass value to MarketInputText to prevent bug where Market only applies styling if value is not empty */}
            <MarketInputText value={phone}>
              <label>{t('NewCustomer.modal.form.phone.label')}</label>
              <input
                slot="input"
                value={phone}
                onChange={(e) =>
                  setPhone(i18nPartialPhoneNumber(e.target.value, country))
                }
                data-testid="NewCustomerModal__phone"
              />
            </MarketInputText>
          </MarketField>
          <MarketField
            className="market-grid-item-full"
            invalid={
              hasError &&
              (!hasAtLeast1Contact || (Boolean(email) && !isValidEmail(email)))
            }
          >
            <MarketInputText
              value={email}
              onMarketInputValueChange={(e) => setEmail(e.detail.value)}
              data-testid="NewCustomerModal__email"
            >
              <label>{t('NewCustomer.modal.form.email.label')}</label>
            </MarketInputText>
          </MarketField>
        </form>
      </MessengerModalPartial>
    );
  },
);

export default NewCustomerModal;
