import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import './TranscriptsListSkeletonState.scss';

// Reasonable estimate to cover the height of most pages although not a problem to have white space at the end of the list.
const SKELETON_TRANSCRIPTS_LENGTH = 15;

export type TranscriptsListSkeletonStateProps = {
  showHeader?: boolean;
};

/**
 * Skeleton state for the transcripts list to show while the list of transcripts is loading.
 *
 * @example <TranscriptsListSkeletonState />
 * @param {boolean} [showHeader]
 * Shows the skeleton state for the header when true.
 * @returns {ReactElement}
 */
const TranscriptsListSkeletonState = observer(
  ({ showHeader }: TranscriptsListSkeletonStateProps): ReactElement => (
    <div>
      {showHeader && <div className="TranscriptsListSkeletonState__header" />}
      <div
        className="TranscriptsListSkeletonState__container"
        data-testid="TranscriptsListSkeletonState"
      >
        {Array.from({ length: SKELETON_TRANSCRIPTS_LENGTH }).map((_, index) => (
          <div
            key={index}
            className="TranscriptsListSkeletonState__item-container"
          >
            <div className="TranscriptsListSkeletonState__image" />
            <div className="TranscriptsListSkeletonState__text-container">
              <div className="TranscriptsListSkeletonState__title" />
              <div className="TranscriptsListSkeletonState__text" />
            </div>
            <div className="TranscriptsListSkeletonState__date" />
          </div>
        ))}
      </div>
    </div>
  ),
);

export default TranscriptsListSkeletonState;
