import Services from 'src/services/Services';
import { callRpc } from 'src/utils/apiUtils';
import {
  ListDirection,
  ListReservationsForContactRequest,
} from 'src/gen/squareup/appointments/api/common_service';
import { PastAppointment } from 'src/MessengerTypes';

const PAST_APPOINTMENTS_PAGE_SIZE = 25;

/**
 * Api responsible for retrieving appointments related data.
 */
class AppointmentsApi {
  private _services: Services;

  constructor(services: Services) {
    this._services = services;
  }

  /**
   * Fetches the past appointments for a customer.
   *
   * @param {string} customerToken
   * The customer token to filter the results of the past appointments contained in the list by.
   * @param {number} page
   * The page number to load.
   * @returns {Promise<[PastAppointment[], boolean]>}
   * A tuple containing the list of past appointments and a boolean indicating if there are more pages to load.
   */
  getPastAppointments = async (
    customerToken: string,
    page: number,
  ): Promise<[PastAppointment[], boolean]> => {
    const response = await callRpc({
      name: 'ListReservationsForContactRequest',
      rpc: (x) => this._services.appointments.listReservationsForContact(x),
      request: ListReservationsForContactRequest.create({
        contactToken: customerToken,
        pagingLimit: PAST_APPOINTMENTS_PAGE_SIZE,
        pagingOffset: page * PAST_APPOINTMENTS_PAGE_SIZE,
        direction: ListDirection.PAST,
      }),
      isExternalService: true,
    });

    const appointments = response.reservations.map((reservation) => {
      const dateStart = reservation.schedule?.dateStart?.instantUsec;
      const firstReservationSegment = reservation.reservationSegments?.[0];
      return {
        reservationId: reservation.reservationId,
        timestampMillis: dateStart ? dateStart / 1000 : Date.now(),
        isRecurring: Boolean(reservation.schedule?.rrule),
        // Adds only a single staff name to match previous behaviour provided by BE
        // https://github.com/squareup/conv-conversations/blob/master/bot/src/main/java/com/squareup/conversations/messenger/GraphQLEngine.java#L345-L349
        staffName: firstReservationSegment
          ? `${firstReservationSegment.teamMemberFirstName} ${firstReservationSegment.teamMemberLastName}`
          : '',
        itemNames: reservation.reservationSegments.map(
          (segment) => segment.itemName,
        ),
      };
    });

    return [appointments, response.hasMoreReservations];
  };
}

export default AppointmentsApi;
