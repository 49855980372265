import { Medium } from 'src/gen/squareup/messenger/v3/messenger_service';
import { mediumToString } from 'src/utils/transcriptUtils';

/**
 * Returns the formatted medium label to be used in the MessageInput component.
 *
 * @param {Medium} medium
 * Medium to get the label for.
 * @returns {string} Formatted medium label.
 */
export const getMessageInputMediumLabel = (medium: Medium): string =>
  mediumToString(medium).toLowerCase();
