import { makeAutoObservable, runInAction } from 'mobx';
import type MessengerController from 'src/MessengerController';
import { LoadingStatus, UtteranceSearchResult } from 'src/MessengerTypes';
import Api from 'src/api/Api';
import {
  Cursor,
  Utterance,
} from 'src/gen/squareup/messenger/v3/messenger_service';

export type UtteranceSearchOptions = {
  query: string;
  includeAutomatedUtterances: boolean;
};

/**
 * Represents an individual utterance search for a given query. Exists for the life of a single query.
 */
class UtteranceSearch {
  private _stores: MessengerController;
  private _api: Api;

  /** Search query */
  readonly query: string;

  /** Flag indicating if automated utterances should also be included in the search results */
  private _includeAutomatedUtterances: boolean;

  /** Loading/error state for fetching the initial page of utterance search results */
  status: LoadingStatus = 'NOT_STARTED';

  /** Loading/error state for fetching the next pages of results */
  loadNextStatus: LoadingStatus = 'NOT_STARTED';

  /** Utterance search results that match the search query */
  results: UtteranceSearchResult[] = [];

  /** Cursor used to paginate the utterance search results */
  cursor?: Cursor;

  constructor(
    stores: MessengerController,
    { query, includeAutomatedUtterances }: UtteranceSearchOptions,
  ) {
    makeAutoObservable(this);

    this._stores = stores;
    this._api = stores.api;
    this.query = query;
    this._includeAutomatedUtterances = includeAutomatedUtterances;
  }

  /**
   * Retrieves the initial set of search results for the query associated with this search.
   */
  get = async (): Promise<void> => {
    if (this.query === '') {
      return;
    }

    this.status = 'LOADING';
    try {
      const [utteranceMatches, cursor] =
        await this._api.search.searchUtterances({
          query: this.query,
          includeAutomatedUtterances: this._includeAutomatedUtterances,
        });

      runInAction(() => {
        this.results = [...utteranceMatches];
        this.cursor = cursor;
        this.status = 'SUCCESS';
      });
    } catch {
      runInAction(() => {
        this.results = [];
        this.cursor = undefined;
        this.status = 'ERROR';
      });
    }
  };

  /**
   * Retrieves the next page of results for the query associated with this search, if available.
   */
  next = async (): Promise<void> => {
    if (!this.hasNextPage) {
      return;
    }
    this._stores.searchLogger.logInteraction();

    this.loadNextStatus = 'LOADING';
    try {
      const [utteranceMatches, cursor] =
        await this._api.search.searchUtterances({
          query: this.query,
          includeAutomatedUtterances: this._includeAutomatedUtterances,
          cursor: this.cursor,
        });

      runInAction(() => {
        this.results = [...this.results, ...utteranceMatches];
        this.cursor = cursor;
        this.loadNextStatus = 'SUCCESS';
      });
    } catch {
      runInAction(() => {
        this.loadNextStatus = 'ERROR';
      });
    }
  };

  get hasNextPage(): boolean {
    return Boolean(this.cursor);
  }

  get hasAutomatedResults(): boolean {
    return this.results.some(
      (result) =>
        result.utterance.speakerType === Utterance.SpeakerType.SERVICE ||
        result.utterance.speakerType === Utterance.SpeakerType.BOT,
    );
  }
}

export default UtteranceSearch;
