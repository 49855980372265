/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';
import './model';
import '../common/location';
import '../privacyvault/annotations';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "simple_instrument_store": {
        "nested": {
          "api": {
            "nested": {
              "CardSummary": {
                "fields": {
                  "lastFour": {
                    "type": "string",
                    "id": 1,
                    "rule": "optional"
                  },
                  "brand": {
                    "type": "squareup.simple_instrument_store.Brand",
                    "id": 2,
                    "rule": "optional"
                  },
                  "cardholderName": {
                    "type": "string",
                    "id": 3,
                    "rule": "optional"
                  },
                  "bin": {
                    "type": "string",
                    "id": 4,
                    "rule": "optional"
                  },
                  "expirationDate": {
                    "type": "squareup.simple_instrument_store.ExpirationDate",
                    "id": 5,
                    "rule": "optional"
                  }
                }
              },
              "BankSummary": {
                "fields": {}
              },
              "InstrumentSummary": {
                "fields": {
                  "token": {
                    "type": "string",
                    "id": 1,
                    "rule": "optional"
                  },
                  "active": {
                    "type": "bool",
                    "id": 3,
                    "rule": "optional"
                  },
                  "nickname": {
                    "type": "string",
                    "id": 5,
                    "rule": "optional"
                  },
                  "metadata": {
                    "type": "string",
                    "id": 4,
                    "rule": "optional"
                  },
                  "billingAddress": {
                    "type": "squareup.common.location.GlobalAddress",
                    "id": 6,
                    "rule": "optional"
                  },
                  "ordinal": {
                    "type": "int32",
                    "id": 7,
                    "rule": "optional"
                  },
                  "linkedAt": {
                    "type": "int64",
                    "id": 8,
                    "rule": "optional"
                  },
                  "vaultedData": {
                    "type": "squareup.privacyvault.VaultedData",
                    "id": 10,
                    "rule": "optional"
                  },
                  "referenceId": {
                    "type": "string",
                    "id": 12,
                    "rule": "optional"
                  },
                  "card": {
                    "type": "squareup.simple_instrument_store.api.CardSummary",
                    "id": 2
                  },
                  "bank": {
                    "type": "squareup.simple_instrument_store.api.BankSummary",
                    "id": 9
                  }
                },
                "nested": {
                  "instrument_data": {
                    "oneof": [
                      "card",
                      "bank"
                    ]
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.simple_instrument_store?.nested?.api?.nested?.CardSummary && !$root.nested?.squareup?.nested?.simple_instrument_store?.nested?.api?.nested?.BankSummary && !$root.nested?.squareup?.nested?.simple_instrument_store?.nested?.api?.nested?.InstrumentSummary) {
	$root.addJSON(jsonData);
}

/**
 * A display version of a Card instrument that is impossible to charge or use to personally
 * identify the card holder.
 *
 * SOURCE CardSummary @ squareup/simple_instrument_store/api.proto at 83:1
 */
export const CardSummary = $root.lookupType('squareup.simple_instrument_store.api.CardSummary');
fixType(CardSummary, {
  lastFour: { required: true },
  brand: { required: true },
  bin: { required: true },
});
Builder.createAndAttachToType(CardSummary);
/**
 * SOURCE BankSummary @ squareup/simple_instrument_store/api.proto at 122:1
 */
export const BankSummary = $root.lookupType('squareup.simple_instrument_store.api.BankSummary');
fixType(BankSummary);
Builder.createAndAttachToType(BankSummary);
/**
 * A version of the Instrument that's intended for display purposes.
 *
 * There's no PAN fidelius information so it's impossible to charge without coming back to this
 * service to request the full Instrument via the token.
 * There's nothing that links this instrument back to a person (i.e. no account token, user token or
 * linking dates).
 *
 * SOURCE InstrumentSummary @ squareup/simple_instrument_store/api.proto at 133:1
 */
export const InstrumentSummary = $root.lookupType('squareup.simple_instrument_store.api.InstrumentSummary');
fixType(InstrumentSummary, {
  token: { notEmpty: true },
  active: { required: true },
});
Builder.createAndAttachToType(InstrumentSummary);
