import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { MarketButton } from 'src/components/Market';
import MessengerModalDialog from 'src/components/MessengerModalDialog/MessengerModalDialog';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';

export type UnsavedModalProps = {
  onConfirm: () => void;
};

/**
 * Modal to warn users that there are unsaved changes on the settings page
 * and giving the options to go back or discard.
 *
 * @param {() => void} onConfirm
 * Callback to be executed when the user confirms they want to navigate away and discard changes.
 */
const UnsavedModal = observer(
  ({ onConfirm }: UnsavedModalProps): ReactElement | null => {
    const { modal } = useMessengerControllerContext();
    const { t } = useTranslation();

    const primaryButton = (
      <MarketButton rank="primary" onClick={modal.closeModal}>
        {t('UnsavedModal.stay')}
      </MarketButton>
    );

    const secondaryButton = (
      <MarketButton rank="secondary" onClick={onConfirm}>
        {t('UnsavedModal.discard')}
      </MarketButton>
    );

    return (
      <MessengerModalDialog
        title={t('UnsavedModal.title')}
        primaryButton={primaryButton}
        secondaryButton={secondaryButton}
        close={close}
      >
        <p>{t('UnsavedModal.description')}</p>
      </MessengerModalDialog>
    );
  },
);

export default UnsavedModal;
