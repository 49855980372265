import { MarketField, MarketInputText } from 'src/components/Market';
import { observer } from 'mobx-react';
import React, { ReactElement, cloneElement } from 'react';
import {
  GetUnitsInformationResponse,
  IUnitInformation,
} from 'src/gen/squareup/messenger/v3/messenger_service';
import {
  mapFieldForReviewEnumToUnitInformationKey,
  missingUnitFieldToInputLabel,
} from './utils';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';

export type TextInputProps = {
  field: Exclude<
    GetUnitsInformationResponse.FieldForReview,
    | GetUnitsInformationResponse.FieldForReview.UNRECOGNIZED
    | GetUnitsInformationResponse.FieldForReview.ADDRESS
  >;
  placeholder?: string;
  trailingButton?: ReactElement;
};

/**
 * The input boxes for all fields except for Business Address in the UnitVerificationForm.
 *
 * @example
 * Basic usage:
 * <TextInput
 *   field={GetUnitsInformationResponse.FieldForReview.WEBSITE_URL}
 * />
 *
 * With optional:
 * <TextInput
 *   field={GetUnitsInformationResponse.FieldForReview.WEBSITE_URL}
 *   placeholder={'john@abc.com'}
 * />
 * @param {GetUnitsInformationResponse.FieldForReview} field
 * Field that needs more information.
 * GetUnitsInformationResponse.FieldForReview.UNRECOGNIZED is not valid.
 * @param {string} placeholder
 * (Optional) Placeholder to show in the input box
 * i.e. for phone numbers: "(XXX) XXX-XXXX"
 * @param {ReactElement} trailingButton
 * (Optional) Button to show on the right side of the input box.
 * The button will be disabled if any errors are present.
 * @author teresalin
 */
const TextInput = observer(
  ({ field, placeholder, trailingButton }: TextInputProps): ReactElement => {
    const {
      unitVerification: {
        setUnitInformation,
        errors,
        getDisplayValue,
        unitToken,
      },
    } = useMessengerControllerContext();

    const unitInfoKey = mapFieldForReviewEnumToUnitInformationKey[
      field
    ] as Exclude<keyof IUnitInformation, 'address'>;

    const handleMarketInputValueChange = (value: string): void => {
      setUnitInformation({ unitInfoKey }, value);
    };

    const error = errors?.[unitInfoKey];
    const displayValue = getDisplayValue(unitInfoKey);
    return (
      <MarketField
        class="market-grid-item-full"
        invalid={Boolean(error) || undefined}
        data-testid={`TextInput__${field}`}
      >
        <MarketInputText
          key={`${unitToken}-${field}`}
          placeholder={placeholder}
          value={displayValue ?? ''}
        >
          <label>{missingUnitFieldToInputLabel(field)}</label>
          <input
            slot="input"
            value={displayValue ?? ''}
            onChange={(e) => handleMarketInputValueChange(e.target.value)}
            data-testid={`TextInput__${field}__input`}
          />
          {trailingButton &&
            cloneElement(trailingButton, {
              slot: 'trailing-accessory',
              disabled: Boolean(error) || undefined,
            })}
        </MarketInputText>
        {Boolean(error) && <small slot="error">{error}</small>}
      </MarketField>
    );
  },
);

export default TextInput;
