/**
 * 2 chat bubbles, used as null state for list page.
 */

import React, { ReactElement } from 'react';

function ChatBubblesIcon(): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      viewBox="0 0 192 192"
    >
      <title>Chat</title>
      <defs>
        <linearGradient
          id="ChatBubblesIcon__a"
          x1="88.9"
          x2="25.38"
          y1="1.95"
          y2="111.97"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#e4ff80" />
          <stop offset=".3" stopColor="#caf236" />
          <stop offset=".82" stopColor="#006aff" />
        </linearGradient>
      </defs>
      <path
        fill="url(#ChatBubblesIcon__a)"
        d="M112 8H8c-4.42 0-8 3.58-8 8v72c0 4.42 3.58 8 8 8h8v24l24-24h72c4.42 0 8-3.58 8-8V16c0-4.42-3.58-8-8-8Z"
      />
      <circle cx="60" cy="52" r="8" className="cls-2" fill="white" />
      <circle cx="28" cy="52" r="8" className="cls-2" fill="white" />
      <circle cx="92" cy="52" r="8" className="cls-2" fill="white" />
      <path
        fill="#121212"
        d="M80 64h104c4.42 0 8 3.58 8 8v72c0 4.42-3.58 8-8 8h-8v24l-24-24H80c-4.42 0-8-3.58-8-8V72c0-4.42 3.58-8 8-8Z"
      />
      <circle cx="132" cy="108" r="8" className="cls-2" fill="white" />
      <circle cx="100" cy="108" r="8" className="cls-2" fill="white" />
      <circle cx="164" cy="108" r="8" className="cls-2" fill="white" />
    </svg>
  );
}

export default ChatBubblesIcon;
