import React, { ReactElement, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { MarketButton } from 'src/components/Market';
import MessengerModalDialog from 'src/components/MessengerModalDialog/MessengerModalDialog';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';

export type MessageBlockedModalProps = {
  closeModal: () => void;
};

/**
 * Modal to inform the merchant that their message could not be sent
 * as the customer is blocked. Includes a button to unblock the current
 * customer.
 *
 * @example
 * Basic usage:
 * <MessageBlockedModal
 *    closeModal={messenger.modal.closeModal}
 * />
 * @param {() => void} closeModal
 * Callback to clear the current modal
 * @author wdetlor
 */
const MessageBlockedModal = observer(
  ({ closeModal }: MessageBlockedModalProps): ReactElement | null => {
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();
    const { transcriptView } = useMessengerControllerContext();

    const onUnblock = (): void => {
      setIsLoading(true);
      transcriptView.unblockTranscript().finally(() => {
        setIsLoading(false);
        closeModal();
      });
    };

    const primaryButton = (
      <MarketButton
        rank="primary"
        onClick={onUnblock}
        isLoading={isLoading || undefined}
      >
        {t('MessageBlockedModal.unblock')}
      </MarketButton>
    );

    const secondaryButton = (
      <MarketButton rank="secondary" onClick={closeModal}>
        {t('MessageBlockedModal.dismiss')}
      </MarketButton>
    );

    return (
      <MessengerModalDialog
        title={t('MessageBlockedModal.title')}
        primaryButton={primaryButton}
        secondaryButton={secondaryButton}
        close={closeModal}
      >
        <p>{t('MessageBlockedModal.description')}</p>
      </MessengerModalDialog>
    );
  },
);

export default MessageBlockedModal;
