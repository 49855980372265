import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import CaretIcon from 'src/svgs/CaretIcon';
import { onKeyDownEnter } from 'src/utils/keyboardUtils';
import 'src/pages/SearchPage/components/CustomerSearchResultList/CustomerSearchResultList.scss';
import CustomerSearchResult from 'src/pages/SearchPage/components/CustomerSearchResult/CustomerSearchResult';

const DISPLAY_CARET_SCROLL_BUFFER_PIXELS = 50;

/**
 * Component that renders the horizontal list of customer search results.
 */
const CustomerSearchResultList = observer((): ReactElement | null => {
  const { search, searchLogger } = useMessengerControllerContext();
  const customerListRef = useRef<HTMLUListElement>(null);
  const [isLeadingCaretShown, setIsLeadingCaretShown] = useState(false);
  const [isTrailingCaretShown, setIsTrailingCaretShown] = useState(true);

  const customers = search.customers.results;

  useEffect(() => {
    const customerListRefElement = customerListRef.current;
    if (!customerListRefElement) {
      return undefined;
    }
    const onChange = (): void => {
      const scrollDistance = customerListRefElement.scrollLeft;
      const scrollArea =
        customerListRefElement.scrollWidth - customerListRefElement.offsetWidth;
      const isTrailingCaretShownNext =
        scrollDistance < scrollArea - DISPLAY_CARET_SCROLL_BUFFER_PIXELS;
      setIsTrailingCaretShown(isTrailingCaretShownNext);
      const isLeadingCaretShownNext =
        scrollDistance > DISPLAY_CARET_SCROLL_BUFFER_PIXELS;
      setIsLeadingCaretShown(isLeadingCaretShownNext);
    };
    onChange();
    customerListRefElement.addEventListener('scroll', onChange);
    return () => {
      customerListRefElement.removeEventListener('scroll', onChange);
    };
  }, [customerListRef, customers.length]);

  if (customers.length === 0) {
    return null;
  }

  const onClickLeadingCaret = (): void => {
    searchLogger.logInteraction();

    customerListRef?.current?.scrollTo({
      left: Math.max(
        customerListRef?.current?.scrollLeft -
          customerListRef?.current?.clientWidth,
        0,
      ),
      behavior: 'smooth',
    });
  };

  const onClickTrailingCaret = (): void => {
    searchLogger.logInteraction();

    customerListRef?.current?.scrollTo({
      left: Math.min(
        customerListRef?.current?.scrollLeft +
          customerListRef?.current?.clientWidth,
        customerListRef?.current?.scrollWidth,
      ),
      behavior: 'smooth',
    });
  };

  return (
    <div className="CustomerSearchResultList__container">
      {isLeadingCaretShown && (
        <div
          className="CustomerSearchResultList__leading-caret-container"
          tabIndex={0}
          onClick={onClickLeadingCaret}
          onKeyDown={(e) => onKeyDownEnter(e, onClickLeadingCaret)}
          data-testid="CustomerSearchResultList__leading-caret"
        >
          <CaretIcon />
        </div>
      )}
      <ul
        className={classNames('CustomerSearchResultList__customers-list', {
          'CustomerSearchResultList__customers-list-leading-fade':
            isLeadingCaretShown,
          'CustomerSearchResultList__customers-list-trailing-fade':
            isTrailingCaretShown,
        })}
        ref={customerListRef}
        data-testid="CustomerSearchResultList__customers-list"
      >
        {customers.map((customer, index) => (
          <CustomerSearchResult
            key={customer.token}
            customer={customer}
            onClick={() => {
              searchLogger.logInteraction({
                target: 'contact',
                contactToken: customer.token,
                rank: index + 1,
                medium:
                  customer.contactMethods.length === 1
                    ? customer.contactMethods[0].medium
                    : undefined,
              });
            }}
          />
        ))}
      </ul>
      {isTrailingCaretShown && (
        <div
          className="CustomerSearchResultList__trailing-caret-container"
          tabIndex={0}
          onClick={onClickTrailingCaret}
          onKeyDown={(e) => onKeyDownEnter(e, onClickTrailingCaret)}
          data-testid="CustomerSearchResultList__trailing-caret"
        >
          <CaretIcon />
        </div>
      )}
    </div>
  );
});

export default CustomerSearchResultList;
