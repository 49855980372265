/**
 * A checkmark indicating a success.
 */

import React, { ReactElement } from 'react';
import BaseSvgProps from 'src/types/BaseSvgProps';

function CheckmarkIcon(props?: BaseSvgProps): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="12"
      fill="none"
      viewBox="0 0 19 12"
      {...props}
    >
      <title>Check</title>
      <path
        fill="#006AFF"
        d="M7.669 12c.26 0 .51-.1.71-.29l10-10L16.969.3l-9.3 9.29-5.29-5.29-1.41 1.41 6 6c.19.19.44.29.7.29Z"
      />
    </svg>
  );
}

export default CheckmarkIcon;
