import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import { Suggestion } from 'src/gen/squareup/messenger/v3/messenger_service';
import { useTranslation } from 'react-i18next';
import {
  amountToCurrencyString,
  currencyCodeToKey,
} from 'src/utils/moneyUtils';
import CouponPercentageIcon from 'src/svgs/CouponPercentageIcon';
import SuggestionRow from 'src/pages/TranscriptViewPage/components/Suggestions/components/SuggestionRow/SuggestionRow';
import { CouponMetadata } from 'src/MessengerTypes';
import { KEY_MESSAGES_PLUS } from 'src/stores/FeatureFlagStore';

export type CouponSuggestionProps = {
  suggestion: Suggestion;
  trackClickSuggestion: () => void;
};

/**
 * Renders a coupon suggestion, which opens the coupon modal if marketing
 * consent is granted, or the request consent modal if not on SMS medium.
 * Will prefill the coupon modal depends on the suggestion metadata, amount or percentage.
 *
 * @example
 * Basic usage:
 * <CouponSuggestion
 *   suggestion={suggestion}
 *   trackClickSuggestions={() => {...}}
 * />
 * @param {Suggestion} suggestion
 * The suggestion details.
 * @param {Function} trackClickSuggestions
 * The function to track when the suggestion is clicked.
 * @author klim
 */
const CouponSuggestion = observer(
  ({
    suggestion,
    trackClickSuggestion,
  }: CouponSuggestionProps): ReactElement => {
    const { t } = useTranslation();
    const { user, transcriptView, featureFlag, subscription, event } =
      useMessengerControllerContext();
    const { locale } = user;
    const {
      isNotSubscribedToMPlus,
      openCouponModal,
      isPendingOrFailedRetryable,
    } = transcriptView;

    if (featureFlag.get(KEY_MESSAGES_PLUS) && subscription.isProhibited) {
      return <></>;
    }

    const amount = suggestion.suggestionMetadata?.coupon?.amount;
    const percentage = suggestion.suggestionMetadata?.coupon?.percentage;
    let paymentText;
    let couponMetadata: CouponMetadata | undefined;
    if (amount) {
      paymentText = t('SmartSuggestions.coupon_amount', {
        amount: amountToCurrencyString(
          amount.amount,
          currencyCodeToKey(amount.currency),
          locale,
        ),
      });
      couponMetadata = { type: 'AMOUNT', value: amount.amount };
    } else if (percentage) {
      paymentText = t('SmartSuggestions.coupon_percentage', {
        percentage,
      });
      couponMetadata = { type: 'PERCENTAGE', value: percentage };
    } else {
      paymentText = t('SmartSuggestions.coupon');
    }

    return (
      <SuggestionRow
        onClick={() => {
          trackClickSuggestion();
          if (isNotSubscribedToMPlus) {
            event.track('Click Messages Plus Subscribe', {
              referral_page_name: 'smart_action_coupon',
            });
          }
          openCouponModal(couponMetadata);
        }}
        text={paymentText}
        icon={<CouponPercentageIcon color="#0055CC" />}
        isDisabled={isPendingOrFailedRetryable}
        showUpgradePill={isNotSubscribedToMPlus}
      />
    );
  },
);

export default CouponSuggestion;
