/**
 * This icon is used in a button that allows users to jump to the
 * bottom of the component they're on.
 */

import React, { ReactElement } from 'react';
import BaseSvgProps from 'src/types/BaseSvgProps';

const MarketArrowDownIcon = (props: BaseSvgProps): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="15"
    viewBox="0 0 16 15"
    fill="none"
    {...props}
  >
    <title>Down</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.70711 14.7071C8.31658 15.0976 7.68342 15.0976 7.29289 14.7071L0.292894 7.7071L1.70711 6.29289L7 11.5858L7 -7.612e-07L9 -5.86354e-07L9 11.5858L14.2929 6.29289L15.7071 7.70711L8.70711 14.7071Z"
    />
  </svg>
);

export default MarketArrowDownIcon;
