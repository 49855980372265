import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import CustomerImage from 'src/components/CustomerImage/CustomerImage';
import { MarketTooltip } from 'src/components/Market';
import { onKeyDownEnter } from 'src/utils/keyboardUtils';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import './CustomerSearchResult.scss';
import { Contact } from 'src/gen/squareup/messenger/v3/messenger_auxiliary_service';

export type CustomerSearchResultProps = {
  customer: Contact;
  onClick?: () => void;
};

/**
 * Component that renders an individual customer search result.
 *
 * @param {Contact} customer
 * The data for the customer that should be rendered as a search result.
 * @param {() => void} [onClick]
 * Callback to be executed when the result is clicked.
 */
const CustomerSearchResult = observer(
  ({ customer, onClick }: CustomerSearchResultProps): ReactElement | null => {
    const { user, modal, navigation, searchLogger } =
      useMessengerControllerContext();

    if (!customer.name) {
      return null;
    }

    const onClickResult = async (): Promise<void> => {
      onClick?.();

      if (customer.contactMethods.length !== 1 || !user.isSingleUnit) {
        modal.openSelectContactMethodModal(
          customer,
          'contact_click_from_search',
          undefined,
          searchLogger.logContactMethodClick,
        );
        return;
      }

      const contact = customer.contactMethods?.[0];

      const transcript = await navigation.openTranscriptByContactMethod(
        {
          contactHandle: contact.contactHandle,
          medium: contact?.medium,
          sellerKey: user.units.keys().next().value,
        },
        'contact_click_from_search',
      );

      transcript?.set({
        contactId: contact?.contactId,
        displayName: {
          name: customer.name,
        },
        customerTokens: [...transcript.customerTokens, customer.token],
      });
    };

    return (
      <li
        key={customer.name}
        className="CustomerSearchResult__customer"
        role="button"
        tabIndex={0}
        onClick={onClickResult}
        onKeyDown={(e) => onKeyDownEnter(e, onClickResult)}
        data-testid="CustomerSearchResult"
      >
        <CustomerImage
          customerInitials={customer.initials}
          customerName={customer.name}
        />
        <MarketTooltip className="CustomerSearchResult__tooltip">
          <span className="CustomerSearchResult__customer-name" slot="trigger">
            {customer.name}
          </span>
          <div slot="content">{customer.name}</div>
        </MarketTooltip>
      </li>
    );
  },
);

export default CustomerSearchResult;
