/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';
import '../api/sync/sync';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "cogs": {
        "nested": {
          "IndexDescriptor": {
            "fields": {
              "unique": {
                "type": "bool",
                "id": 1,
                "rule": "optional",
                "options": {
                  "default": false
                }
              },
              "keyField": {
                "type": "squareup.api.sync.ObjectWrapper",
                "id": 2,
                "rule": "repeated"
              },
              "name": {
                "type": "string",
                "id": 3,
                "rule": "optional"
              }
            }
          },
          "EntityDescriptor": {
            "fields": {
              "index": {
                "type": "squareup.cogs.IndexDescriptor",
                "id": 1,
                "rule": "repeated"
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.cogs?.nested?.IndexDescriptor && !$root.nested?.squareup?.nested?.cogs?.nested?.EntityDescriptor) {
	$root.addJSON(jsonData);
}

/**
 * SOURCE IndexDescriptor @ squareup/cogs/schema.proto at 20:1
 */
export const IndexDescriptor = $root.lookupType('squareup.cogs.IndexDescriptor');
fixType(IndexDescriptor);
Builder.createAndAttachToType(IndexDescriptor);
/**
 * SOURCE EntityDescriptor @ squareup/cogs/schema.proto at 32:1
 */
export const EntityDescriptor = $root.lookupType('squareup.cogs.EntityDescriptor');
fixType(EntityDescriptor);
Builder.createAndAttachToType(EntityDescriptor);
