import React, { ReactElement, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { MarketButton } from 'src/components/Market';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import MessengerModalPartial from 'src/components/MessengerModalPartial/MessengerModalPartial';
import { onKeyDownEnter } from 'src/utils/keyboardUtils';
import { UnitSetting } from 'src/MessengerTypes';
import VoicemailRecorder from 'src/pages/EditVoicemailPage/components/VoicemailRecorder/VoicemailRecorder';
import VoicemailPlayer from 'src/pages/EditVoicemailPage/components/VoicemailPlayer/VoicemailPlayer';

export type RecordNewVoicemailGreetingModalProps = {
  unitSetting: UnitSetting;
};

/**
 * Modal to record a new voicemail greeting.
 *
 * @param {UnitSetting} unitSetting
 * The unit setting to record the new voicemail greeting for.
 * @returns {ReactElement | null}
 */
const RecordNewVoicemailGreetingModal = observer(
  ({
    unitSetting,
  }: RecordNewVoicemailGreetingModalProps): ReactElement | null => {
    const { modal, status, settings, soundRecording } =
      useMessengerControllerContext();
    const { t } = useTranslation();
    const [isRecordingComplete, setIsRecordingComplete] = useState(false);
    const [errorText, setErrorText] = useState<string>('');
    const unitToken = unitSetting.unitToken;

    const onRecordingEnd = async (blob: Blob): Promise<void> => {
      await settings.uploadVoicemailGreeting(unitToken, blob);
      setIsRecordingComplete(true);
      setErrorText('');
    };

    const onSave = async (): Promise<void> => {
      if (soundRecording.isRecording) {
        try {
          settings.voicemailSaveStatus = 'LOADING';
          const audioBlob: Blob = await soundRecording.stop();
          await onRecordingEnd(audioBlob);
        } catch {
          settings.voicemailSaveStatus = 'ERROR';
          status.setModalError({
            label: t('EditVoicemailPage.greeting_saved_error_text'),
          });
          return;
        }
      } else if (!isRecordingComplete) {
        setErrorText(t('EditVoicemailPage.recording_required_message'));
        return;
      }
      try {
        const voicemailSettings =
          settings.userUnitSettings.get(unitToken)?.voicemail;

        if (!voicemailSettings) {
          throw new Error('No unit settings to save for this unit token.');
        }

        await settings.saveVoicemailUnitSettings(unitToken, voicemailSettings);
      } catch {
        status.setModalError({
          label: t('EditVoicemailPage.greeting_saved_error_text'),
        });
        return;
      }
      status.setSuccess({
        label: t('EditVoicemailPage.greeting_saved_success_text'),
      });
      modal.closeModal();
    };

    return (
      <MessengerModalPartial
        title={t('EditVoicemailPage.record_new_greeting')}
        close={() => {
          settings.clearUserVoicemailUnitSettings(unitToken);
          modal.closeModal();
        }}
        primaryButton={
          <MarketButton
            rank="primary"
            onClick={onSave}
            onKeyDown={(e) => onKeyDownEnter(e, onSave)}
            isLoading={settings.voicemailSaveStatus === 'LOADING' || undefined}
          >
            {t('common.save')}
          </MarketButton>
        }
        status={status.value}
      >
        {!isRecordingComplete ? (
          <VoicemailRecorder
            unitToken={unitToken}
            onRecordingEnd={onRecordingEnd}
            errorText={errorText}
          />
        ) : (
          <VoicemailPlayer
            unitSetting={unitSetting}
            onClickDeleteButton={() => {
              setIsRecordingComplete(false);
              settings.setUserUnitSetting({
                ...unitSetting,
                voicemail: {
                  ...unitSetting.voicemail,
                  customVoicemailGreeting: undefined,
                  customVoicemailId: '',
                },
              });
            }}
          />
        )}
      </MessengerModalPartial>
    );
  },
);

export default RecordNewVoicemailGreetingModal;
