import { createContext, useContext } from 'react';
import type MessengerController from 'src/MessengerController';

/**
 * The context that contains MessengerController so that any component that has a
 * parent with the context provider can access the controller's property. Note that
 * the type here is a partial so that we do not have to set a new controller as the
 * default value (that will cause initialization endpoints to be called twice) and
 * testing can be made easy by only defining properties we need.
 */
const MessengerControllerContext = createContext<Partial<MessengerController>>(
  {},
);

/**
 * A wrapper around useContext for MessengerController to return a non-partial controller.
 * All components that uses MessengerControllerContext must use this function instead of
 * useContext directly.
 *
 * Components that use this function MUST have a parent component that provides a
 * full value of MessengerController and not a partial (unless in testing).
 */
export const useMessengerControllerContext = (): MessengerController => {
  return useContext(MessengerControllerContext) as MessengerController;
};

export default MessengerControllerContext;
