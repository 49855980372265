import { RefObject, useEffect, useRef } from 'react';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import { MessengerSheetSectionName } from 'src/MessengerTypes';

/**
 * Scroll to a specific section on a sheet page.
 *
 * @param {MessengerSheetSectionName} section
 * The section name to scroll to.
 * @returns {RefObject}
 * a ref to attach to the heading element of the section.
 */
const useSheetSectionScrollTo = (
  section: MessengerSheetSectionName,
): RefObject<HTMLHeadingElement> => {
  const { navigation } = useMessengerControllerContext();
  const ref = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    if (navigation.sheet.section === section) {
      ref.current?.scrollIntoView({
        block: 'start',
        inline: 'nearest',
        behavior: 'smooth',
      });
    }
  }, [navigation.sheet.section, section]);

  return ref;
};

export default useSheetSectionScrollTo;
