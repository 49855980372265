/**
 * A sad face that is used in a contextual event
 * where a negative feedback is received.
 */
import React, { ReactElement } from 'react';

function SadIcon(): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <title>Negative</title>
      <path
        fill="#000"
        fillOpacity=".9"
        fillRule="evenodd"
        d="M3 12a9 9 0 1 1 18.001.001A9 9 0 0 1 3 12Zm2 0c0 3.86 3.14 7 7 7s7-3.14 7-7-3.14-7-7-7-7 3.14-7 7Zm2.38 3.61A4.987 4.987 0 0 1 12 12.49c2.03 0 3.85 1.23 4.62 3.12l-1.86.76A2.98 2.98 0 0 0 12 14.49c-1.21 0-2.29.74-2.76 1.88l-1.86-.76ZM14.5 11a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3ZM11 9.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SadIcon;
