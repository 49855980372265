/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';
import './mobile_staff';
import './employee';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "bills": {
        "nested": {
          "CreatorDetails": {
            "fields": {
              "mobileStaff": {
                "type": "squareup.bills.MobileStaff",
                "id": 1,
                "rule": "optional"
              },
              "employee": {
                "type": "squareup.bills.Employee",
                "id": 2,
                "rule": "optional"
              },
              "deviceCredential": {
                "type": "squareup.bills.DeviceCredential",
                "id": 3,
                "rule": "optional"
              },
              "sourceAppId": {
                "type": "string",
                "id": 4,
                "rule": "optional"
              }
            }
          },
          "DeviceCredential": {
            "fields": {
              "token": {
                "type": "string",
                "id": 1,
                "rule": "optional"
              },
              "name": {
                "type": "string",
                "id": 2,
                "rule": "optional"
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.bills?.nested?.CreatorDetails && !$root.nested?.squareup?.nested?.bills?.nested?.DeviceCredential) {
	$root.addJSON(jsonData);
}

/**
 * Only one of these fields will be set.
 *
 * SOURCE CreatorDetails @ squareup/bills/creator_details.proto at 12:1
 */
export const CreatorDetails = $root.lookupType('squareup.bills.CreatorDetails');
fixType(CreatorDetails);
Builder.createAndAttachToType(CreatorDetails);
/**
 * SOURCE DeviceCredential @ squareup/bills/creator_details.proto at 34:1
 */
export const DeviceCredential = $root.lookupType('squareup.bills.DeviceCredential');
fixType(DeviceCredential);
Builder.createAndAttachToType(DeviceCredential);
