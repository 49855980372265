import { makeAutoObservable } from 'mobx';
import { t } from 'i18next';
import type MessengerController from 'src/MessengerController';
import { Status } from 'src/MessengerTypes';

/**
 * A default time, in milliseconds, before a status get dismissed.
 */
export const DEFAULT_STATUS_TIMEOUT = 5000;

class StatusStore {
  private _stores: MessengerController;

  /**
   * Global app status that should supersede any other status pre-defined in @computed
   * status and will be shown first as either a StatusBanner or StatusScreen.
   */
  value: Status | null = null;

  /**
   * This tracks the current status timeout value, if it exists. Used to prevent issues
   * where a new status is set but the old timeout has not been cleared.
   */
  private _timeout: NodeJS.Timeout | null = null;

  constructor(stores: MessengerController) {
    makeAutoObservable(this);

    this._stores = stores;

    window.addEventListener('online', this.handleOnlineStatus);
    window.addEventListener('offline', this.handleOfflineStatus);
  }

  /**
   * When there is a need to show a status/error whose condition cannot be tracked by
   * a Lazy, use this function to set an on demand status. You must provide a way to
   * dismiss this status by either:
   *
   * 1) Provide action in the param - it will be wrapped to clear the status when that
   * action is being called.
   *
   * 2) Provide timer in the param - the status will automatically clear after the
   * specified time.
   *
   * @param {Status} status
   * The new status object value to set.
   */
  set = (status: Status): void => {
    this.clear();
    this.value = status;
    const action = status.action;
    if (this.value.action && action) {
      // Wrap the action to clear this status after it is being called
      const actionWrapper: () => Promise<void> | null = () => {
        const result = action.action();
        if (result) {
          return result.finally(() => this.clear());
        } else {
          this.clear();
        }
        return result;
      };
      this.value.action.action = actionWrapper;
    }

    if (status.timerMillis) {
      this._timeout = setTimeout(() => this.clear(), status.timerMillis);
    }
  };

  /**
   * Sets the status with default success properties added.
   *
   * @param {Status} [status]
   * Optional overrides to the default success status.
   */
  setSuccess = (status?: Partial<Status>): void => {
    this.set({
      label: t('common.done'),
      type: 'SUCCESS',
      display: 'TOAST',
      scope: 'WINDOW',
      action: {
        action: async () => {
          await this.clear();
        },
      },
      timerMillis: DEFAULT_STATUS_TIMEOUT,
      ...status,
    });
  };

  /**
   * Sets the status with default error properties added.
   *
   * @param {Status} [status]
   * Optional overrides to the default error status.
   */
  setError = (status?: Partial<Status>): void => {
    this.set({
      label: t('common.error.try_again'),
      type: 'ERROR',
      display: 'TOAST',
      scope: 'WINDOW',
      action: {
        action: async () => {
          await this.clear();
        },
      },
      ...status,
    });
  };

  /**
   * Sets the modal status message with default error properties added.
   *
   * @param {Status} [status]
   * Optional overrides to status.
   */
  setModalError = (status?: Partial<Status>): void => {
    this.setError({
      display: 'BANNER',
      scope: 'MODAL',
      ...status,
    });
  };

  /**
   * Clear the global app status.
   */
  clear = (): void => {
    if (this._timeout !== null) {
      clearTimeout(this._timeout);
      this._timeout = null;
    }
    this.value = null;
  };

  /**
   * Called when online status changes to online. Show a banner
   * to indicate to that the connection is restored.
   */
  handleOnlineStatus = (): void => {
    this.setSuccess({
      label: t('common.onlineStatus.online'),
    });
  };

  /**
   * Called when online status changes to offline. Show a banner to
   * indicate that internet connection is lost.
   */
  handleOfflineStatus = (): void => {
    this.setError({
      label: t('common.onlineStatus.offline'),
    });
  };
}

export default StatusStore;
