/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';
import '../roster/service';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "bills": {
        "nested": {
          "Employee": {
            "fields": {
              "employeeToken": {
                "type": "string",
                "id": 1,
                "rule": "optional"
              },
              "readOnlyRosterEmployee": {
                "type": "squareup.roster.service.EmployeeLookupResponse",
                "id": 2,
                "rule": "optional"
              },
              "readOnlyFullName": {
                "type": "string",
                "id": 3,
                "rule": "optional"
              },
              "readOnlyProfilePhotoUrl": {
                "type": "string",
                "id": 4,
                "rule": "optional"
              },
              "firstName": {
                "type": "string",
                "id": 5,
                "rule": "optional"
              },
              "lastName": {
                "type": "string",
                "id": 6,
                "rule": "optional"
              },
              "readOnlyEmployeeNumber": {
                "type": "string",
                "id": 7,
                "rule": "optional"
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.bills?.nested?.Employee) {
	$root.addJSON(jsonData);
}

/**
 * Employees represents the person who is taking action in one of Square's features.
 * An Employee is different from mobile staff because are not be a full user
 * in Square's system (able to log in).
 *
 * SOURCE Employee @ squareup/bills/employee.proto at 15:1
 */
export const Employee = $root.lookupType('squareup.bills.Employee');
fixType(Employee);
Builder.createAndAttachToType(Employee);
