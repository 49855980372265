import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import DefaultCustomerIcon from 'src/svgs/DefaultCustomerIcon';
import './CustomerImage.scss';

export type CustomerImageProps = {
  customerInitials?: string;
  imageUrl?: string;
  customerName?: string;
};

/**
 * A square icon that displays the customer's initials (if ordinary
 * conversation), default avatar (if synthetic or orphaned
 * conversation), or a profile picture if provided.
 *
 * @example
 * Basic usage:
 * <CustomerImage
 *  customerInitials="SC"
 * >
 *
 * With optionals (used in <TranscriptsListItem/>):
 * <CustomerImage
 *  customerInitials="SC"
 * >
 *
 * With profile picture:
 * <CustomerImage
 *  customerInitials="SC"
 *  imageUrl="myImageUrl.png"
 *  customerName="First Last"
 * />
 * @param {string} [customerInitials]
 * (Optional) A string denoting the customer initials
 * @param {string} [imageUrl]
 * (Optional) Url of an image to be displayed. If provided, will display this instead of any
 * customer initials.
 * @param {string} [customerName]
 * (Optional) Name for the customer.
 * @author klim
 */
const CustomerImage = observer(
  ({
    customerInitials,
    imageUrl,
    customerName,
  }: CustomerImageProps): ReactElement => {
    const { t } = useTranslation();

    const imageAltText = t('CustomerImage.alt_text', { customerName });

    let content;
    if (imageUrl) {
      content = (
        <img
          className="CustomerImage__image"
          src={imageUrl}
          alt={imageAltText}
        />
      );
    } else if (customerInitials) {
      content = customerInitials;
    } else {
      content = <DefaultCustomerIcon />;
    }

    return (
      <div data-testid="CustomerImage" className="CustomerImage">
        {content}
      </div>
    );
  },
);

export default CustomerImage;
