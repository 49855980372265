/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';
import '../sub2/common';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "roster": {
        "nested": {
          "common": {
            "nested": {
              "SubscriptionFeatureStatus": {
                "fields": {
                  "feature": {
                    "type": "string",
                    "id": 1,
                    "rule": "optional"
                  },
                  "status": {
                    "type": "squareup.sub2.common.SubscriptionStatus",
                    "id": 2,
                    "rule": "optional"
                  },
                  "subscriptionToken": {
                    "type": "string",
                    "id": 3,
                    "rule": "optional"
                  }
                }
              },
              "TippingPreference": {
                "fields": {
                  "enabled": {
                    "type": "bool",
                    "id": 1,
                    "rule": "optional"
                  },
                  "useCustomPercentages": {
                    "type": "bool",
                    "id": 2,
                    "rule": "optional"
                  },
                  "customPercentages": {
                    "type": "double",
                    "id": 3,
                    "rule": "repeated"
                  },
                  "useManualTipEntry": {
                    "type": "bool",
                    "id": 4,
                    "rule": "optional"
                  },
                  "calculationPhase": {
                    "type": "squareup.roster.common.TippingPreference.CalculationPhase",
                    "id": 5,
                    "rule": "optional"
                  }
                },
                "nested": {
                  "CalculationPhase": {
                    "values": {
                      "POST_TAX_TIP_CALCULATION": 0,
                      "PRE_TAX_TIP_CALCULATION": 1
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.roster?.nested?.common?.nested?.SubscriptionFeatureStatus && !$root.nested?.squareup?.nested?.roster?.nested?.common?.nested?.TippingPreference && !$root.nested?.squareup?.nested?.roster?.nested?.common?.nested?.TippingPreference?.nested?.CalculationPhase) {
	$root.addJSON(jsonData);
}

/**
 * SOURCE SubscriptionFeatureStatus @ squareup/roster/common.proto at 11:1
 */
export const SubscriptionFeatureStatus = $root.lookupType('squareup.roster.common.SubscriptionFeatureStatus');
fixType(SubscriptionFeatureStatus);
Builder.createAndAttachToType(SubscriptionFeatureStatus);
/**
 * SOURCE TippingPreference @ squareup/roster/common.proto at 19:1
 */
export const TippingPreference = $root.lookupType('squareup.roster.common.TippingPreference');
fixType(TippingPreference);
Builder.createAndAttachToType(TippingPreference);
/**
 * SOURCE CalculationPhase @ squareup/roster/common.proto at 20:3
 */
TippingPreference.CalculationPhase = $root.lookupEnum('squareup.roster.common.TippingPreference.CalculationPhase').values;
