import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { MarketButton } from 'src/components/Market';
import { useTranslation } from 'react-i18next';
import { M_PLUS_BUSINESS_NUMBER_FAQ_URL } from 'src/utils/url';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import InProgressIcon from 'src/svgs/InProgressIcon';
import EmptyState from 'src/components/EmptyState/EmptyState';

/**
 * An empty state to show when the unit's M+ subscription is still pending
 * TFN verification in the M+ v2 onboarding flow.
 */
const VerificationInProgressState = observer((): ReactElement => {
  const { t } = useTranslation();
  const { navigation, event } = useMessengerControllerContext();

  return (
    <EmptyState
      icon={<InProgressIcon />}
      title={t('VerificationInProgress.title')}
      description={t('VerificationInProgress.description')}
      button={
        <MarketButton
          onClick={() => {
            event.track('Click Verification In Progress Page', {
              action_type_name: 'view_status_in_settings',
            });
            navigation.openSheet('SETTINGS');
          }}
        >
          {t('VerificationInProgress.settings_button')}
        </MarketButton>
      }
      secondaryButton={
        <MarketButton
          href={M_PLUS_BUSINESS_NUMBER_FAQ_URL}
          target="_blank"
          onClick={() => {
            event.track('Click Verification In Progress Page', {
              action_type_name: 'learn_more',
            });
          }}
        >
          {t('VerificationInProgress.learn_more')}
        </MarketButton>
      }
      dataCy="VerificationInProgressState"
    />
  );
});

export default VerificationInProgressState;
