/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../Fixes';
import Builder from '../../../Builder';
import '../../common/time';
import '../merchant/model';
import '../../connect/v2/resources/error';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "appointments": {
        "nested": {
          "api": {
            "nested": {
              "ListDirection": {
                "values": {
                  "PAST": 1,
                  "FUTURE": 2
                }
              },
              "ListReservationsForContactRequest": {
                "fields": {
                  "contactToken": {
                    "type": "string",
                    "id": 1,
                    "rule": "optional"
                  },
                  "pagingLimit": {
                    "type": "int32",
                    "id": 2,
                    "rule": "optional"
                  },
                  "pagingOffset": {
                    "type": "int32",
                    "id": 3,
                    "rule": "optional"
                  },
                  "direction": {
                    "type": "squareup.appointments.api.ListDirection",
                    "id": 4,
                    "rule": "optional"
                  },
                  "startAt": {
                    "type": "squareup.common.time.DateTime",
                    "id": 5,
                    "rule": "optional"
                  },
                  "endAt": {
                    "type": "squareup.common.time.DateTime",
                    "id": 6,
                    "rule": "optional"
                  },
                  "unitToken": {
                    "type": "string",
                    "id": 7,
                    "rule": "optional"
                  }
                }
              },
              "ListReservationsForContactResponse": {
                "fields": {
                  "reservations": {
                    "type": "squareup.appointments.merchant.Reservation",
                    "id": 1,
                    "rule": "repeated"
                  },
                  "merchantActivityStatus": {
                    "type": "squareup.appointments.api.MerchantActivityStatus",
                    "id": 2,
                    "rule": "optional"
                  },
                  "hasMultipleStaff": {
                    "type": "bool",
                    "id": 3,
                    "rule": "optional"
                  },
                  "hasMoreReservations": {
                    "type": "bool",
                    "id": 4,
                    "rule": "optional"
                  },
                  "errors": {
                    "type": "squareup.connect.v2.resources.Error",
                    "id": 5,
                    "rule": "repeated"
                  }
                }
              },
              "MerchantActivityStatus": {
                "values": {
                  "NEVER_ACTIVE": 1,
                  "ACTIVE": 2,
                  "ACTIVE_IN_PAST": 3
                }
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.appointments?.nested?.api?.nested?.ListDirection && !$root.nested?.squareup?.nested?.appointments?.nested?.api?.nested?.ListReservationsForContactRequest && !$root.nested?.squareup?.nested?.appointments?.nested?.api?.nested?.ListReservationsForContactResponse && !$root.nested?.squareup?.nested?.appointments?.nested?.api?.nested?.MerchantActivityStatus) {
	$root.addJSON(jsonData);
}

/**
 * SOURCE ListDirection @ squareup/appointments/api/common_service.proto at 12:1
 */
export const ListDirection = $root.lookupEnum('squareup.appointments.api.ListDirection').values;
/**
 * SOURCE ListReservationsForContactRequest @ squareup/appointments/api/common_service.proto at 17:1
 */
export const ListReservationsForContactRequest = $root.lookupType('squareup.appointments.api.ListReservationsForContactRequest');
fixType(ListReservationsForContactRequest, {
  contactToken: { required: true },
  pagingLimit: { required: true },
  pagingOffset: { required: true },
  direction: { required: true },
});
Builder.createAndAttachToType(ListReservationsForContactRequest);
/**
 * SOURCE ListReservationsForContactResponse @ squareup/appointments/api/common_service.proto at 38:1
 */
export const ListReservationsForContactResponse = $root.lookupType('squareup.appointments.api.ListReservationsForContactResponse');
fixType(ListReservationsForContactResponse);
Builder.createAndAttachToType(ListReservationsForContactResponse);
/**
 * SOURCE MerchantActivityStatus @ squareup/appointments/api/common_service.proto at 46:1
 */
export const MerchantActivityStatus = $root.lookupEnum('squareup.appointments.api.MerchantActivityStatus').values;
