import React, { ReactElement, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import MessengerModalPartial from 'src/components/MessengerModalPartial/MessengerModalPartial';
import { CheckoutLink } from 'src/MessengerTypes';
import './CheckoutLinkModal.scss';
import Logger from 'src/Logger';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import {
  MarketButton,
  MarketTab,
  MarketTabs,
  MarketTabList,
} from 'src/components/Market';
import OneTimeLinkTab from './components/OneTimeLinkTab/OneTimeLinkTab';
import ExistingLinkTab from './components/ExistingLinkTab/ExistingLinkTab';
import { toJS } from 'mobx';

// Indicator for which tab is selected, "amount link" mode or "existing link" mode
export const TAB_ONE_TIME = 'CheckoutLinkModalTabOneTime';
export const TAB_EXISTING = 'CheckoutLinkModalTabExisting';
type TabPage = typeof TAB_ONE_TIME | typeof TAB_EXISTING;

/**
 * A modal for the merchant to send a checkout link to the selected customer.
 *
 * @example
 * Basic usage:
 * <CheckoutLinkModal />
 * @author eblaine, klim
 */
const CheckoutLinkModal = observer((): ReactElement => {
  const { t } = useTranslation();
  const { event, modal, user, transcriptView, api, checkoutLinks, status } =
    useMessengerControllerContext();
  const { closeModal: close, checkoutLinkAmount } = modal;
  const { currencyCode, isSingleUnit } = user;

  // This component has 2 "pages", controlled by tabs.
  // 1. The "one time link" page. This is available for everybody.
  // 2. The "existing link" page. As of June 2021, this is only available for single-
  //    unit businesses.
  const [currentPage, setCurrentPage] = useState<TabPage>(TAB_ONE_TIME);

  // States and wrapper function to handle loading indicator and errors.
  const [isCreating, setIsCreating] = useState(false);
  const [linkAmount, setLinkAmount] = useState(checkoutLinkAmount || 0);
  const [selectedCheckoutLink, setSelectedCheckoutLink] = useState<
    CheckoutLink | undefined
  >(undefined);

  // One-time links have a currency-dependent max amount.
  // For CAD and USD, this is $50,000.
  // TODO(eblaine): Add other amounts based on this chart:
  // https://wiki.sqprod.co/display/SOC/Checkout+link+payment+and+donation+limits
  const { minAmount, maxAmount } = checkoutLinks.checkoutLinkLimits;

  const createAndSendCheckoutLink = (): void => {
    setIsCreating(true);
    event.track('Click Checkout Link Modal Send');

    let createPromise: Promise<CheckoutLink> | undefined;
    if (currentPage === TAB_ONE_TIME) {
      createPromise = api.messaging.createCheckoutLink({
        unitToken: transcriptView.transcript.sellerKey,
        amount: {
          amount: linkAmount,
          currency: currencyCode,
        },
      });
    } else if (selectedCheckoutLink) {
      createPromise = Promise.resolve(toJS(selectedCheckoutLink));
    } else {
      createPromise = Promise.reject(new Error('No selected checkout link'));
    }

    createPromise
      .then((link) => {
        return transcriptView.confirmConsent().then((isConsentConfirmed) => {
          transcriptView.sendMessage({
            message: link.title ?? '',
            metadata: {
              checkoutLink: link,
            },
            isConsentConfirmed,
          });
        });
      })
      .then(() => {
        close();
      })
      .catch(() => {
        Logger.warn(
          'Failed to send checkout link. This can happen if the seller does not confirm consent.',
        );
      })
      .finally(() => {
        setIsCreating(false);
      });
  };

  return (
    <MessengerModalPartial
      title={t('CheckoutLinkModal.title')}
      close={() => {
        event.track('Click Checkout Link Modal Dismiss');
        close();
      }}
      primaryButton={
        <MarketButton
          rank="primary"
          onClick={createAndSendCheckoutLink}
          isLoading={isCreating || undefined}
          disabled={
            (currentPage === TAB_ONE_TIME
              ? linkAmount < minAmount || linkAmount > maxAmount
              : selectedCheckoutLink === undefined) || undefined
          }
        >
          {t('common.send')}
        </MarketButton>
      }
      status={status.value}
    >
      <p>{t('CheckoutLinkModal.description')}</p>
      {isSingleUnit && (
        <MarketTabs>
          <MarketTabList
            className="CheckoutLinkModal__tab-list"
            onMarketTabListSelectedTabChanged={(e) => {
              setCurrentPage(e.detail.value as TabPage);
              setLinkAmount(0);
              setSelectedCheckoutLink(undefined);
            }}
            data-testid="CheckoutLinkModal__tab-list"
          >
            <MarketTab id={TAB_ONE_TIME}>
              {t('CheckoutLinkModal.onetime.title')}
            </MarketTab>
            <MarketTab id={TAB_EXISTING}>
              {t('CheckoutLinkModal.existing.title')}
            </MarketTab>
          </MarketTabList>
        </MarketTabs>
      )}
      {currentPage === TAB_ONE_TIME ? (
        <OneTimeLinkTab
          minAmount={minAmount}
          maxAmount={maxAmount}
          setAmount={setLinkAmount}
        />
      ) : (
        <ExistingLinkTab setSelectedCheckoutLink={setSelectedCheckoutLink} />
      )}
    </MessengerModalPartial>
  );
});

export default CheckoutLinkModal;
