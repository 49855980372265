import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Medium } from 'src/gen/squareup/messenger/v3/messenger_service';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import Link from 'src/components/Link/Link';
import {
  amountToCurrencyString,
  currencyCodeToKey,
} from 'src/utils/moneyUtils';
import { getCustomerInDirectoryLink } from 'src/utils/url';
import { CustomerDetailMessengerPage } from 'src/MessengerTypes';
import { getPrimaryContactMethod } from 'src/utils/transcriptUtils';
import './CustomerInfo.scss';
import { Contact } from 'src/gen/squareup/messenger/v3/messenger_auxiliary_service';

export type CustomerInfoProps = {
  contact: Contact;
};

/**
 * The component containing all the UI associated with rendering the customer's information
 * on the customer detail page.
 *
 * @param {Contact} contact
 * The contact data object for the details page that this menu is rendering on.
 */
const CustomerInfo = observer(
  ({ contact }: CustomerInfoProps): ReactElement => {
    const { navigation, user, event, transcripts } =
      useMessengerControllerContext();
    const { t } = useTranslation();

    const nav = navigation.tertiary.isOpen
      ? navigation.tertiary
      : navigation.primary;
    const page = nav.currentPage as CustomerDetailMessengerPage;
    const transcript = transcripts.get(page.transcriptId);

    const onClickBuyerSummaryDetails = (): void => {
      event.track('Click Buyer Summary Details');

      window.open(getCustomerInDirectoryLink(contact.token), '_blank');
    };

    const emailContact = getPrimaryContactMethod(contact, Medium.EMAIL);
    const phoneContact = getPrimaryContactMethod(contact, Medium.SMS);

    return (
      <>
        {emailContact && (
          <Link
            className="paragraph-30"
            onClick={() => {
              event.track('Click Customer Details Contact ID', {
                medium: 'EMAIL',
              });
              navigation.openTranscriptByContactMethod(
                {
                  contactHandle: emailContact.contactHandle,
                  medium: Medium.EMAIL,
                  sellerKey: transcript.sellerKey,
                },
                'customers',
              );
            }}
          >
            {emailContact.displayContact}
          </Link>
        )}
        {phoneContact && (
          <Link
            className="paragraph-30"
            onClick={() => {
              event.track('Click Customer Details Contact ID', {
                medium: 'SMS',
              });
              navigation.openTranscriptByContactMethod(
                {
                  contactHandle: phoneContact.contactHandle,
                  medium: Medium.SMS,
                  sellerKey: transcript.sellerKey,
                },
                'customers',
              );
            }}
          >
            {phoneContact.displayContact}
          </Link>
        )}
        <div className="CustomerInfo__spend-links-container">
          <Link onClick={onClickBuyerSummaryDetails}>
            {t('CustomerDetailPage.transaction_count_label', {
              transactionCount: contact.transactionCount,
            })}
          </Link>
          <svg
            width="2"
            height="10"
            viewBox="0 0 2 10"
            className="CustomerInfo__horizontal-divider"
          >
            <rect id="box" x="0" y="0" width="2" height="10" />
          </svg>
          <Link onClick={onClickBuyerSummaryDetails}>
            {t('CustomerDetailPage.total_spend_label', {
              amount: amountToCurrencyString(
                contact.totalGpv?.amount || 0,
                contact.totalGpv?.currency
                  ? currencyCodeToKey(contact.totalGpv.currency)
                  : user.currencyCode,
                user.locale,
              ),
            })}
          </Link>
        </div>
      </>
    );
  },
);

export default CustomerInfo;
