/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "common": {
        "nested": {
          "gateways": {
            "nested": {
              "GatewayName": {
                "values": {
                  "unknown": 0,
                  "chase_seller_v12": 1,
                  "chase_test_v12": 2,
                  "eero_payment_service_v1": 3,
                  "cad_stratus": 4,
                  "test_eero_payment_service_v1": 5,
                  "test_cad_stratus": 6,
                  "fake": 7,
                  "amex_payment_service": 8,
                  "amex_cad_payment_service": 9,
                  "amex_jpy_payment_service_1": 10,
                  "amex_jpy_payment_service_2": 11,
                  "test_amex_payment_service": 12,
                  "test_amex_cad_payment_service": 13,
                  "test_amex_jpy_payment_service": 14,
                  "certification": 15,
                  "cafis_1": 16,
                  "cafis_2": 17,
                  "cafis_3": 18,
                  "visa_payment_service_v1": 19,
                  "test_visa_payment_service_v1": 20,
                  "tivo": 21,
                  "simulator": 22,
                  "amex_aud_payment_service": 23,
                  "test_amex_aud_payment_service": 24,
                  "giftcard_service": 25,
                  "simulator_online": 30,
                  "stardebit_payment_service": 31,
                  "test_stardebit_payment_service": 32,
                  "cafis_jcb_1": 33,
                  "cafis_jcb_2": 34,
                  "cafis_jcb_3": 35,
                  "cuscal_payment_service": 36,
                  "test_cuscal_payment_service": 37,
                  "shazam_payment_service": 38,
                  "test_shazam_payment_service": 39,
                  "nyce_via_stardebit_payment_service": 40,
                  "test_nyce_via_stardebit_payment_service": 41,
                  "chase_seller": 42,
                  "ipc_salem_direct_v13": 43,
                  "authorize_net": 44,
                  "leap": 45,
                  "maestro_via_shazam_payment_service": 46,
                  "pulse_via_shazam_payment_service": 47,
                  "maestro_via_stardebit_payment_service": 48,
                  "cafis_sompo_1": 49,
                  "cafis_sompo_2": 50,
                  "cafis_sompo_3": 51,
                  "balance_service": 52,
                  "omnipay_payment_service": 53,
                  "everlink_payment_service": 54,
                  "acculynk_payment_service": 55,
                  "felica_payment_service": 56,
                  "sandbox_gateway": 57,
                  "alipay_payment_service": 58,
                  "nyce_payment_service": 59,
                  "capital_service": 60,
                  "mastercardsend_payment_service": 61,
                  "visa_dex_payment_service": 62,
                  "pay_with_cash_payment_service": 63,
                  "paypay_payment_service": 64,
                  "mastercard_mip_payment_service": 65,
                  "adyen_payment_service": 66,
                  "everlink_in_app_payment_service": 67,
                  "nyce_ebt_payment_service": 68,
                  "afterpay_payment_service": 69,
                  "simulator_uneditable": 70,
                  "stardebit_northeast_payment_service": 71,
                  "nyce_via_stardebit_northeast_payment_service": 72,
                  "maestro_via_stardebit_northeast_payment_service": 73,
                  "square_account_payment_service": 74,
                  "jcn_payment_service": 75,
                  "chase_mdex_payment_service": 76,
                  "dgft_payment_service": 77,
                  "discover_payment_service": 78,
                  "jcn_smcc_payment_service": 79,
                  "startergateway_payment_service": 80,
                  "adyen_payment_service_v2": 81
                }
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.common?.nested?.gateways?.nested?.GatewayName) {
	$root.addJSON(jsonData);
}

/**
 * This enum serves as the common source for the name of a particular
 * gateway route. Currently used for gateway hints but should eventually
 * be used throughout both the payments stack and in sq/web.
 *
 * SOURCE GatewayName @ squareup/common/gateways.proto at 10:1
 */
export const GatewayName = $root.lookupEnum('squareup.common.gateways.GatewayName').values;
