/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "smsphoneregistry": {
        "nested": {
          "Address": {
            "fields": {
              "address1": {
                "type": "string",
                "id": 1,
                "rule": "optional"
              },
              "address2": {
                "type": "string",
                "id": 2,
                "rule": "optional"
              },
              "city": {
                "type": "string",
                "id": 3,
                "rule": "optional"
              },
              "state": {
                "type": "string",
                "id": 4,
                "rule": "optional"
              },
              "zipCode": {
                "type": "string",
                "id": 5,
                "rule": "optional"
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.smsphoneregistry?.nested?.Address) {
	$root.addJSON(jsonData);
}

/**
 * SOURCE Address @ squareup/smsphoneregistry/verification.proto at 80:1
 */
export const Address = $root.lookupType('squareup.smsphoneregistry.Address');
fixType(Address, {
  address1: { required: true, notEmpty: true },
  city: { required: true, notEmpty: true },
  state: { required: true },
  zipCode: { required: true },
});
Builder.createAndAttachToType(Address);
