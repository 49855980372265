import React, { ChangeEvent, useState } from 'react';
import { observer } from 'mobx-react';
import Sound from 'src/stores/objects/Sound';
import { formatDurationTime } from 'src/utils/timeUtils';
import './SoundScrubber.scss';

const SEEK_KEYS = new Set(['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown']);

export type SoundScrubberProps = {
  sound: Sound;
  onScrub?: () => void;
};

/**
 * Component that renders the UI controls to change the audio position of a sound.
 *
 * @param {Sound} sound
 * The sound instance associated with this instance of the scrubber.
 * @param {() => void} [onScrub]
 * Callback to be executed when the sound scrubber is scrubbed.
 */
const SoundScrubber = observer(({ sound, onScrub }: SoundScrubberProps) => {
  // sliderPosition represents the user selected slider position (i.e. by moving the slider handle)
  // while sound.position is the actual spot of where the sound is playing
  const [sliderPosition, setSliderPosition] = useState<number | null>(null);

  const duration = sound.duration || 1; // avoid divide by zero error if duration has not yet loaded
  const max = Math.max(Math.round(duration), 100);
  const value = sliderPosition ?? (sound.position / duration) * max;
  const sliderFillPercentage = (Math.round(value) / max) * 100;

  const seek = (): void => {
    onScrub?.();
    if (sliderPosition !== null) {
      sound.seek((sliderPosition / max) * sound.duration);
      setSliderPosition(null);
    }
  };

  const seekPosition =
    sliderPosition !== null
      ? (sliderPosition / max) * sound.duration
      : sound.position;
  const seekPositionLabel = formatDurationTime(seekPosition);
  const durationLabel = formatDurationTime(sound.duration);
  const timeLabel = seekPosition > 0 ? seekPositionLabel : durationLabel;

  const isDisabled = sound.status === 'LOADING' || sound.status === 'ERROR';

  return (
    <div className="SoundScrubber__container">
      <input
        type="range"
        className="SoundScrubber__slider"
        min="0"
        max={max}
        value={value}
        style={{
          backgroundSize: `${sliderFillPercentage}% 100%`,
        }}
        onInput={(event: ChangeEvent<HTMLInputElement>) => {
          setSliderPosition(Number.parseInt(event.target.value, 10));
        }}
        onMouseUp={seek}
        onTouchEnd={seek}
        onKeyUp={(event) => {
          if (SEEK_KEYS.has(event.key)) {
            seek();
          }
        }}
        disabled={isDisabled}
      />
      <p className="SoundScrubber__time paragraph-20">
        {isDisabled ? '--:--' : timeLabel}
      </p>
    </div>
  );
});

export default SoundScrubber;
