/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';
import '../../google/protobuf/struct';
import '../connect/v2/common/source_application';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "devscustomattr": {
        "nested": {
          "service": {
            "nested": {
              "CustomAttributeParentResourceType": {
                "values": {
                  "CA_PARENT_RESOURCE_TYPE_DO_NOT_USE": 0,
                  "CUSTOM_ATTRIBUTES_TEST_RESOURCE": 1,
                  "CUSTOM_ATTRIBUTES_TEST_RESOURCE_2": 2,
                  "CUSTOMER": 3,
                  "BOOKING": 4,
                  "ORDER": 5,
                  "LOCATION": 6,
                  "ORGANIZATION": 7,
                  "MERCHANT": 8,
                  "CHANNEL": 9,
                  "TEAM_MEMBER": 10,
                  "PAYMENT": 11
                }
              },
              "CustomAttributeDefinition": {
                "fields": {
                  "id": {
                    "type": "string",
                    "id": 1,
                    "rule": "optional"
                  },
                  "key": {
                    "type": "string",
                    "id": 2,
                    "rule": "optional"
                  },
                  "schema": {
                    "type": "google.protobuf.Struct",
                    "id": 15,
                    "rule": "optional"
                  },
                  "name": {
                    "type": "string",
                    "id": 4,
                    "rule": "optional"
                  },
                  "description": {
                    "type": "string",
                    "id": 5,
                    "rule": "optional"
                  },
                  "sourceApplication": {
                    "type": "squareup.connect.v2.common.SourceApplication",
                    "id": 6,
                    "rule": "optional"
                  },
                  "visibility": {
                    "type": "squareup.devscustomattr.service.CustomAttributeDefinition.Visibility",
                    "id": 17,
                    "rule": "optional"
                  },
                  "version": {
                    "type": "int32",
                    "id": 11,
                    "rule": "optional"
                  },
                  "parentResourceConfig": {
                    "type": "squareup.devscustomattr.service.CustomAttributeDefinition.ParentResourceConfig",
                    "id": 12,
                    "rule": "optional"
                  },
                  "merchantToken": {
                    "type": "string",
                    "id": 13,
                    "rule": "optional"
                  },
                  "updatedAt": {
                    "type": "string",
                    "id": 14,
                    "rule": "optional"
                  },
                  "createdAt": {
                    "type": "string",
                    "id": 16,
                    "rule": "optional"
                  },
                  "definitionScope": {
                    "type": "squareup.devscustomattr.service.CustomAttributeDefinition.DefinitionScope",
                    "id": 18,
                    "rule": "optional"
                  }
                },
                "nested": {
                  "Visibility": {
                    "values": {
                      "VISIBILITY_DO_NOT_USE": 0,
                      "VISIBILITY_HIDDEN": 1,
                      "VISIBILITY_READ_ONLY": 2,
                      "VISIBILITY_READ_WRITE_VALUES": 3,
                      "VISIBILITY_1P_READ_WRITE_VALUES": 4
                    }
                  },
                  "ParentResourceConfig": {
                    "fields": {
                      "type": {
                        "type": "squareup.devscustomattr.service.CustomAttributeParentResourceType",
                        "id": 1,
                        "rule": "optional"
                      },
                      "webhookNamespace": {
                        "type": "string",
                        "id": 2,
                        "rule": "optional"
                      },
                      "resourceOwningApp": {
                        "type": "string",
                        "id": 3,
                        "rule": "optional"
                      }
                    }
                  },
                  "DefinitionScope": {
                    "values": {
                      "LOCAL": 0,
                      "MERCHANT_GLOBAL": 1
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.devscustomattr?.nested?.service?.nested?.CustomAttributeParentResourceType && !$root.nested?.squareup?.nested?.devscustomattr?.nested?.service?.nested?.CustomAttributeDefinition && !$root.nested?.squareup?.nested?.devscustomattr?.nested?.service?.nested?.CustomAttributeDefinition?.nested?.Visibility && !$root.nested?.squareup?.nested?.devscustomattr?.nested?.service?.nested?.CustomAttributeDefinition?.nested?.ParentResourceConfig && !$root.nested?.squareup?.nested?.devscustomattr?.nested?.service?.nested?.CustomAttributeDefinition?.nested?.DefinitionScope) {
	$root.addJSON(jsonData);
}

/**
 * SOURCE CustomAttributeParentResourceType @ squareup/devscustomattr/datatypes.proto at 32:1
 */
export const CustomAttributeParentResourceType = $root.lookupEnum('squareup.devscustomattr.service.CustomAttributeParentResourceType').values;
/**
 * --
 * @desc Represents a definition for custom attribute values. A custom attribute definition
 * specifies the key, visibility, schema, and other properties for a custom attribute.
 * --
 *
 * SOURCE CustomAttributeDefinition @ squareup/devscustomattr/datatypes.proto at 75:1
 */
export const CustomAttributeDefinition = $root.lookupType('squareup.devscustomattr.service.CustomAttributeDefinition');
fixType(CustomAttributeDefinition);
Builder.createAndAttachToType(CustomAttributeDefinition);
/**
 * --
 * @desc The level of permission that a seller or other applications requires to
 * view this custom attribute definition.
 * The `Visibility` field controls who can read and write the custom attribute values
 * and custom attribute definition.
 * --
 *
 * SOURCE Visibility @ squareup/devscustomattr/datatypes.proto at 133:3
 */
CustomAttributeDefinition.Visibility = $root.lookupEnum('squareup.devscustomattr.service.CustomAttributeDefinition.Visibility').values;
/**
 * SOURCE ParentResourceConfig @ squareup/devscustomattr/datatypes.proto at 175:3
 */
CustomAttributeDefinition.ParentResourceConfig = $root.lookupType('squareup.devscustomattr.service.CustomAttributeDefinition.ParentResourceConfig');
fixType(CustomAttributeDefinition.ParentResourceConfig, {
  type: { required: true },
});
Builder.createAndAttachToType(CustomAttributeDefinition.ParentResourceConfig);
/**
 * --
 * @desc DefinitionScope indicates the ownership of a definition. Definitions are scoped based on
 * three attributes parent resource, merchant, and application. Depending on these attributes the
 * scope of the definition might be different.
 * --
 *
 * SOURCE DefinitionScope @ squareup/devscustomattr/datatypes.proto at 216:3
 */
CustomAttributeDefinition.DefinitionScope = $root.lookupEnum('squareup.devscustomattr.service.CustomAttributeDefinition.DefinitionScope').values;
