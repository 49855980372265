/**
 * The type of the token
 */
export enum TokenType {
  /**
   * DO NOT USE
   */
  UNKNOWN = 'UNKNOWN',
  /**
   * Unit Token
   */
  UNIT = 'UNIT',
  /**
   * Merchant Token
   */
  MERCHANT = 'MERCHANT',
  /**
   * Device Installation ID
   */
  DEVICE_ID = 'DEVICE_ID',
  /**
   * Anonymous Visitor Token
   */
  ANONYMOUS_VISITOR = 'ANONYMOUS_VISITOR',
  /**
   * Person Token
   */
  PERSON = 'PERSON',
}

/**
 * SOURCE Token @ squareup/feature/relay/common/token.proto at 10:1
 */
export interface Token {
  /**
   * The token value
   *
   * SOURCE token @ squareup/feature/relay/common/token.proto at 12:3
   */
  token?: string;
  /**
   * SOURCE type @ squareup/feature/relay/common/token.proto at 29:3
   */
  type: TokenType;
}
