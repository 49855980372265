/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "roster": {
        "nested": {
          "business_hours": {
            "nested": {
              "BusinessHours": {
                "fields": {
                  "periods": {
                    "type": "squareup.roster.business_hours.BusinessHoursPeriod",
                    "id": 1,
                    "rule": "repeated"
                  }
                }
              },
              "BusinessHoursPeriod": {
                "fields": {
                  "dayOfWeek": {
                    "type": "squareup.roster.business_hours.DayOfWeek",
                    "id": 1,
                    "rule": "optional"
                  },
                  "startLocalTime": {
                    "type": "string",
                    "id": 2,
                    "rule": "optional"
                  },
                  "endLocalTime": {
                    "type": "string",
                    "id": 3,
                    "rule": "optional"
                  }
                }
              },
              "DayOfWeek": {
                "values": {
                  "SUN": 0,
                  "MON": 1,
                  "TUE": 2,
                  "WED": 3,
                  "THU": 4,
                  "FRI": 5,
                  "SAT": 6
                }
              },
              "SpecialBusinessHours": {
                "fields": {
                  "periods": {
                    "type": "squareup.roster.business_hours.SpecialBusinessHoursPeriod",
                    "id": 1,
                    "rule": "repeated"
                  }
                }
              },
              "SpecialBusinessHoursPeriod": {
                "fields": {
                  "startDate": {
                    "type": "string",
                    "id": 1,
                    "rule": "optional"
                  },
                  "endDate": {
                    "type": "string",
                    "id": 2,
                    "rule": "optional"
                  },
                  "timePeriods": {
                    "type": "squareup.roster.business_hours.TimePeriod",
                    "id": 3,
                    "rule": "repeated"
                  },
                  "daysOfWeek": {
                    "type": "squareup.roster.business_hours.DayOfWeek",
                    "id": 4,
                    "rule": "repeated"
                  },
                  "reason": {
                    "type": "string",
                    "id": 5,
                    "rule": "optional"
                  },
                  "isClosed": {
                    "type": "bool",
                    "id": 6,
                    "rule": "optional"
                  }
                }
              },
              "TimePeriod": {
                "fields": {
                  "startLocalTime": {
                    "type": "string",
                    "id": 1,
                    "rule": "optional"
                  },
                  "endLocalTime": {
                    "type": "string",
                    "id": 2,
                    "rule": "optional"
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.roster?.nested?.business_hours?.nested?.BusinessHours && !$root.nested?.squareup?.nested?.roster?.nested?.business_hours?.nested?.BusinessHoursPeriod && !$root.nested?.squareup?.nested?.roster?.nested?.business_hours?.nested?.DayOfWeek && !$root.nested?.squareup?.nested?.roster?.nested?.business_hours?.nested?.SpecialBusinessHours && !$root.nested?.squareup?.nested?.roster?.nested?.business_hours?.nested?.SpecialBusinessHoursPeriod && !$root.nested?.squareup?.nested?.roster?.nested?.business_hours?.nested?.TimePeriod) {
	$root.addJSON(jsonData);
}

/**
 * The regular hours of operation for a location.
 * This proto is Roster's version of squareup.connect.v2.resources.BusinessHours, and is currently identical.
 * These protos must be updated together so they are always in sync.
 * See: https://github.com/squareup/go-square/blob/master/xp/connect-public-protos/protos/squareup/connect/v2/resources/location.proto
 *
 * SOURCE BusinessHours @ squareup/roster/business_hours.proto at 14:1
 */
export const BusinessHours = $root.lookupType('squareup.roster.business_hours.BusinessHours');
fixType(BusinessHours);
Builder.createAndAttachToType(BusinessHours);
/**
 * Represents a period of time during which a business location is open.
 *
 * SOURCE BusinessHoursPeriod @ squareup/roster/business_hours.proto at 20:1
 */
export const BusinessHoursPeriod = $root.lookupType('squareup.roster.business_hours.BusinessHoursPeriod');
fixType(BusinessHoursPeriod);
Builder.createAndAttachToType(BusinessHoursPeriod);
/**
 * SOURCE DayOfWeek @ squareup/roster/business_hours.proto at 31:1
 */
export const DayOfWeek = $root.lookupEnum('squareup.roster.business_hours.DayOfWeek').values;
/**
 * The special hours of operation for a location
 * This proto is Roster's version of squareup.connect.v2.resources.SpecialBusinessHours, and is currently identical.
 * These protos must be updated together so they are always in sync.
 * See: https://github.com/squareup/go-square/blob/master/xp/connect-public-protos/protos/squareup/connect/v2/resources/location.proto
 *
 * SOURCE SpecialBusinessHours @ squareup/roster/business_hours.proto at 45:1
 */
export const SpecialBusinessHours = $root.lookupType('squareup.roster.business_hours.SpecialBusinessHours');
fixType(SpecialBusinessHours);
Builder.createAndAttachToType(SpecialBusinessHours);
/**
 * A date range for which the location has special hours
 *
 * SOURCE SpecialBusinessHoursPeriod @ squareup/roster/business_hours.proto at 53:1
 */
export const SpecialBusinessHoursPeriod = $root.lookupType('squareup.roster.business_hours.SpecialBusinessHoursPeriod');
fixType(SpecialBusinessHoursPeriod);
Builder.createAndAttachToType(SpecialBusinessHoursPeriod);
/**
 * SOURCE TimePeriod @ squareup/roster/business_hours.proto at 79:1
 */
export const TimePeriod = $root.lookupType('squareup.roster.business_hours.TimePeriod');
fixType(TimePeriod);
Builder.createAndAttachToType(TimePeriod);
