import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { MarketButton } from 'src/components/Market';
import MessengerModalDialog from 'src/components/MessengerModalDialog/MessengerModalDialog';

/**
 * Dialog that informs the user that an unexpected error occurred when loading the page.
 */
const UnexpectedErrorDialog = observer((): ReactElement => {
  const { t } = useTranslation();

  return (
    <MessengerModalDialog
      title={t('UnexpectedErrorDialog.title')}
      primaryButton={
        <MarketButton
          rank="primary"
          onClick={() => {
            window.location.reload();
          }}
        >
          {t('UnexpectedErrorDialog.reload')}
        </MarketButton>
      }
    >
      {t('UnexpectedErrorDialog.description')}
    </MessengerModalDialog>
  );
});

export default UnexpectedErrorDialog;
