import { post } from './api';
import { createUserToken, formatAttributes, isSuccess } from './utils';
import { handleAxiosError, createErrorResult } from './error-handling';
import {
  ErrorResult,
  GetExperimentsOptions,
  GetExperimentsRequest,
  GetExperimentsResponse,
  Result,
  TokenType,
} from './types';

function attachErrorResultcreateErrorResponseToExperiments(
  experimentNames: string[],
  errorResponse: ErrorResult
) {
  return experimentNames.map((experimentName) => ({
    experimentName,
    variantName: undefined,
    ...errorResponse,
  }));
}

export async function getExperiments({
  experimentNames,
  token,
  tokenType = TokenType.ANONYMOUS_VISITOR,
  userAttributes,
  projectId,
  env,
  hasUnsafeDefaults = false,
}: GetExperimentsOptions): Promise<
  (Result & {
    experimentName: string;
    variantName: string | undefined;
  })[]
> {
  const userToken = createUserToken(token, tokenType);
  const formattedAttributes = formatAttributes(userAttributes);

  const data = {
    experimentRequests: experimentNames.map((experimentName) => ({
      experimentName,
      userToken,
    })),
    ...(formattedAttributes && {
      userAttributes: { userAttributes: formattedAttributes },
    }),
    projectId,
  };

  try {
    const { experimentResponses, status } = await post<
      GetExperimentsRequest,
      GetExperimentsResponse
    >('get-experiments', data, env);

    if (experimentResponses === undefined || !isSuccess(status)) {
      return attachErrorResultcreateErrorResponseToExperiments(
        experimentNames,
        createErrorResult(status)
      );
    }

    return experimentResponses.map(
      ({ experimentName, variantName, status: experimentStatus }) => {
        if (isSuccess(experimentStatus)) {
          return { success: true, experimentName, variantName };
        }
        return {
          experimentName,
          variantName,
          ...createErrorResult(experimentStatus),
        };
      }
    );
  } catch (err: unknown) {
    return attachErrorResultcreateErrorResponseToExperiments(
      experimentNames,
      handleAxiosError(err, hasUnsafeDefaults)
    );
  }
}
