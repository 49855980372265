import React, { ReactElement, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import {
  MarketButton,
  MarketList,
  MarketRadio,
  MarketRow,
} from 'src/components/Market';
import MessengerModalPartial from 'src/components/MessengerModalPartial/MessengerModalPartial';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import { Medium } from 'src/gen/squareup/messenger/v3/messenger_service';
import { MarketListSelectionsDidChangeEvent } from 'src/MarketTypes';
import { mediumToString } from 'src/utils/transcriptUtils';
import './SelectContactMethodModal.scss';
import { OpenTranscriptEventLoggingSource } from 'src/MessengerTypes';
import LocationSelector from 'src/components/LocationSelector/LocationSelector';
import { Contact } from 'src/gen/squareup/messenger/v3/messenger_auxiliary_service';

/**
 * Modal that appears when a contact has multiple contact methods and the user
 * needs to select one in order to know the correct conversation to open.
 */
const SelectContactMethodModal = observer((): ReactElement => {
  const { modal, status, navigation, user } = useMessengerControllerContext();
  const {
    closeModal,
    selectContactMethodContact,
    selectContactMethodUnitToken,
    selectContactMethodCallback,
    selectContactMethodSource,
  } = modal;
  const { activeUnits } = user;
  const { t } = useTranslation();
  const [selectedUnitToken, setSelectedUnitToken] = useState<string>(
    activeUnits[0].token,
  );

  const { name, contactMethods } = selectContactMethodContact as Contact;

  // Use contact handle in case user does not have viewing permissions
  const [selectedContactHandle, setSelectedContactHandle] = useState<
    string | undefined
  >(contactMethods[0]?.contactHandle);

  return (
    <MessengerModalPartial
      title={t('SelectContactMethodModal.title')}
      primaryButton={
        <MarketButton
          rank="primary"
          onClick={(): void => {
            const contactMethod = contactMethods.find(
              (contactMethod) =>
                contactMethod.contactHandle === selectedContactHandle,
            );

            const sellerKey = selectContactMethodUnitToken || selectedUnitToken;

            selectContactMethodCallback?.(
              selectContactMethodContact?.token,
              contactMethod?.medium,
              sellerKey,
            );

            navigation.openTranscriptByContactMethod(
              {
                contactHandle: contactMethod?.contactHandle,
                medium: contactMethod?.medium as Medium,
                sellerKey,
              },
              selectContactMethodSource as OpenTranscriptEventLoggingSource,
            );
            closeModal();
          }}
        >
          {t('common.next')}
        </MarketButton>
      }
      close={closeModal}
      status={status.value}
      overflow
    >
      <p>{t('SelectContactMethodModal.desc', { displayName: name })}</p>
      <MarketList
        value={selectedContactHandle}
        onMarketListSelectionsDidChange={(
          event: MarketListSelectionsDidChangeEvent,
        ) => setSelectedContactHandle(event.detail.newSelectionValue)}
        interactive
      >
        {contactMethods.map(({ displayContact, medium, contactHandle }) => (
          <MarketRow value={contactHandle} key={contactHandle} interactive>
            <label slot="label">{mediumToString(medium)}</label>
            <p slot="subtext">{displayContact}</p>
            <MarketRadio slot="control" />
          </MarketRow>
        ))}
      </MarketList>
      {!selectContactMethodUnitToken && (
        <LocationSelector
          label={t('SelectContactMethodModal.location')}
          unitToken={selectedUnitToken}
          setUnitToken={setSelectedUnitToken}
          className="SelectContactMethodModal__unit_selector"
        />
      )}
    </MessengerModalPartial>
  );
});

export default SelectContactMethodModal;
