import { t } from 'i18next';
import { reaction, makeAutoObservable, runInAction } from 'mobx';
import Logger from 'src/Logger';
import type MessengerController from 'src/MessengerController';
import {
  LoadingStatus,
  UnitVerificationFormMessengerPage,
} from 'src/MessengerTypes';
import Api from 'src/api/Api';
import {
  CreateOrUpdateSubscriptionRequest,
  GetUnitsInformationResponse,
  IUnitInformation,
  UnitDedicatedNumber,
} from 'src/gen/squareup/messenger/v3/messenger_service';
import { e164PhoneNumber } from 'src/i18n/formatValues';
import { getValidationSchema, REQUIRED_ADDRESS_FIELDS } from './utils';
import { IAddress } from 'src/gen/squareup/smsphoneregistry/verification';
import { SubscriptionStatus } from 'src/gen/squareup/sub2/common';

// Amount of time we show the success banner before moving on to the next
// verification form
export const SUBMISSION_SUCCESS_BANNER_TIMER = 1500;

const EMPTY_ADDRESS: IAddress = {
  address1: '',
  address2: '',
  city: '',
  state: '',
  zipCode: '',
};

const EMPTY_UNIT_INFORMATION: IUnitInformation = {
  businessName: '',
  websiteUrl: '',
  contactFirstName: '',
  contactLastName: '',
  contactEmail: '',
  contactPhoneNumber: '',
  address: { ...EMPTY_ADDRESS },
};

type UnitInformationErrors = {
  address?: {
    address1?: string;
    address2?: string;
    city?: string;
    state?: string;
    zipCode?: string;
  };
} & {
  [Property in Exclude<keyof IUnitInformation, 'address'>]?: string;
};

/**
 * Stores that contains state used throughout the Messages Plus TFN (Toll-Free Number)
 * unit verification flow, which includes unit information we already have, what the user
 * has submitted via the <UnitVerificationForm /> component, and validation/error states
 * associated with the form.
 *
 * What belongs here:
 * - Unit information data, what units are missing information, and information pertinent
 * to unit verification flow
 * - Unit information form states (error, validation)
 * What doesn't belong here:
 * - Component-specific state
 */
class UnitVerificationStore {
  private _stores: MessengerController;
  private _api: Api;

  /**
   * Loading state for fetching units information via GetUnitsInformation.
   */
  status: LoadingStatus = 'NOT_STARTED';

  /**
   * Tracks unit information we have on the backend with any user-entered information.
   */
  unitInformation: IUnitInformation = { ...EMPTY_UNIT_INFORMATION };

  /**
   * Tracks whether the user checked the checkbox stating that they manually verified
   * that the website is valid. This is only available in M+ version 2.
   */
  manuallyVerifiedWebsite = false;

  /**
   * The scroll position on UnitsToVerifyPage. Used to maintain the scroll
   * when navigating back and forth from UnitVerificationForm.
   */
  scrollPosition = 0;

  /**
   * If true, show validation errors in the form.
   */
  showError = false;

  constructor(stores: MessengerController) {
    makeAutoObservable(this, {
      // Does not mutate any class properties
      submitUpdatedUnitInformation: false,
    });

    this._stores = stores;
    this._api = stores.api;

    // Reset fields when the UnitVerificationForm is loaded for a new unit token
    reaction(
      () => `${this.unitToken}_${this.status}`,
      () => this.reset(),
    );
  }

  reset = (): void => {
    const knownUnitInformation = this._stores.user.units.get(
      this.unitToken ?? '',
    )?.subscription?.verificationInfoToReview?.unitInformation;

    this.unitInformation = {
      ...EMPTY_UNIT_INFORMATION,
      ...knownUnitInformation,
      address: {
        ...EMPTY_ADDRESS,
        ...knownUnitInformation?.address,
      },
    };
    this.manuallyVerifiedWebsite = false;
    this.showError = false;
    // No need to reset status because getUnitsVerificationInformation()
    // gets information for all missing units
  };

  /**
   * Saves/updates the missing unit information field with user-provided input
   *
   * @param {object} key
   * Information about the unit information field
   * @param {keyof IUnitInformation} key.unitInfoKey
   * Top level unit information key
   * @param {keyof IAddress} key.addressKey
   * (Optional) If `key.unitInfoKey` is 'address', then the specific address field
   * name must be provided as well
   * @param {string} value
   * User-provided input value to set for the unit information field
   */
  setUnitInformation = (
    key: {
      unitInfoKey: keyof IUnitInformation;
      addressKey?: keyof IAddress;
    },
    value: string,
  ): void => {
    if (!this.unitToken) return;

    const { unitInfoKey, addressKey } = key;

    let unitInformationValue: string | IAddress = value;
    if (unitInfoKey === 'contactPhoneNumber') {
      // Backend expects us to provide phone numbers in E.164 format
      unitInformationValue = e164PhoneNumber(value as string) ?? value;
    } else if (unitInfoKey === 'address' && addressKey) {
      unitInformationValue = {
        ...this.unitInformation.address,
        [addressKey]: value,
      };
    }

    this.unitInformation = {
      ...this.unitInformation,
      [unitInfoKey]: unitInformationValue,
    };
  };

  /**
   * For a given google place id, look up the address parts and set it as the address
   * in unitInformation.
   *
   * @param {string} placeId
   * @param {string} address - the address associated to the placeId
   */
  setAddressFromGooglePlaceId = async (
    placeId: string,
    address: string,
  ): Promise<void> => {
    try {
      const address = await this._api.google.getPlaceAddressParts(placeId);
      this.unitInformation = {
        ...this.unitInformation,
        address,
      };
    } catch {
      // If we can't get the address parts, just show all the fields and let
      // user fill it up themselves
      this.unitInformation = {
        ...this.unitInformation,
        address: {
          ...EMPTY_ADDRESS,
          address1: address,
        },
      };
    }
  };

  setShowError = (value: boolean): void => {
    this.showError = value;
  };

  /**
   * Computes display values based on latest unit information.
   * Display values may be formatted differently from raw values or latest
   * user input.
   */
  get displayValues(): IUnitInformation {
    const displayValues: IUnitInformation = {};

    for (const [key, information] of Object.entries(this.unitInformation)) {
      if (key === 'address') {
        if (!displayValues[key]) {
          displayValues[key] = {};
        }

        for (const [addressKey, addressInformation] of Object.entries(
          information,
        )) {
          const validationSchema = getValidationSchema({
            unitInfoKey: key,
            addressKey: addressKey as keyof IAddress,
          });

          (displayValues[key] as IAddress)[addressKey as keyof IAddress] =
            validationSchema
              ? validationSchema.getValue(
                  addressInformation as string,
                  this._stores.user.countryCode,
                )
              : (addressInformation as string);
        }
      } else {
        const validationSchema = getValidationSchema({
          unitInfoKey: key as keyof IUnitInformation,
        });

        displayValues[key as keyof IUnitInformation] = validationSchema
          ? validationSchema.getValue(information)
          : information;
      }
    }

    return displayValues;
  }

  /**
   * Gets display value for a specified unit information key
   *
   * @param {string} key Unit information key (i.e. 'address' or 'contactEmail')
   * @param {string} addressKey
   * (Optional) Address key (i.e. 'address1' or 'city')
   * If `key` is 'address', then the specific address key must be provided as well
   * @returns The value to display in the UI
   */
  getDisplayValue = (
    key: keyof IUnitInformation,
    addressKey?: keyof IAddress,
  ): string | undefined => {
    if (key === 'address' && addressKey === undefined) {
      return undefined;
    } else if (key === 'address' && addressKey) {
      return this.displayValues[key]?.[addressKey];
    }

    // Key cannot be 'address' so this.displayValues[key] cannot be IAddress
    return this.displayValues[key] as string | undefined;
  };

  private _getUnitInformationErrors = (
    unitInformation: IUnitInformation,
  ): UnitInformationErrors => {
    const errors: UnitInformationErrors = {};

    for (const [key, information] of Object.entries(unitInformation)) {
      if (key === 'address') {
        if (!errors[key]) {
          errors[key] = {};
        }

        for (const [addressKey, addressInformation] of Object.entries(
          information,
        )) {
          const validationSchema = getValidationSchema({
            unitInfoKey: key,
            addressKey: addressKey as keyof IAddress,
          });

          (errors[key] as IAddress)[addressKey as keyof IAddress] =
            validationSchema?.isValid(addressInformation as string)
              ? undefined
              : t(validationSchema?.errorKey ?? '');
        }
      } else {
        const validationSchema = getValidationSchema({
          unitInfoKey: key as keyof IUnitInformation,
        });

        errors[key as keyof IUnitInformation] = validationSchema?.isValid(
          information,
        )
          ? undefined
          : t(validationSchema?.errorKey ?? '');
      }
    }

    return errors;
  };

  /**
   * Returns potential errors for each unit information field.
   */
  get errors(): UnitInformationErrors {
    return this.showError
      ? this._getUnitInformationErrors(this.unitInformation)
      : {};
  }

  /**
   * Fetches unit information for the provided unit tokens and saves it to the UserStore.
   *
   * @param {string[]} unitTokens
   * The list of tokens to fetch unit information for.
   */
  getUnitsVerificationInformation = async (
    unitTokens: string[],
  ): Promise<void> => {
    this.status = 'LOADING';

    if (unitTokens.length === 0) {
      // Skip getUnitsInformation() call because it will error if called on empty array
      // Page will instead just redirect to Numbers list page
      this.status = 'SUCCESS';
      return;
    }

    try {
      const unitsInformation = await this._api.subscription.getUnitsInformation(
        unitTokens,
      );

      Object.entries(unitsInformation).forEach(
        ([unitToken, verificationInfoToReview]) => {
          const existingSubscriptionDetails =
            this._stores.user.units.get(unitToken)?.subscription;

          this._stores.user.setUnit(unitToken, {
            subscription: {
              isSubscribed: true,
              ...existingSubscriptionDetails,
              verificationInfoToReview,
            },
          });
        },
      );

      runInAction(() => {
        this.status = 'SUCCESS';
      });
    } catch (error) {
      Logger.logWithSentry(
        'SubscriptionStore:getUnitsVerificationInformation - Unable to fetch units information',
        'error',
        {
          error,
        },
      );

      runInAction(() => {
        this.status = 'ERROR';
      });
      this._stores.status.setError({
        display: 'BANNER',
        type: 'ERROR',
        scope: 'SHEET',
        label: t('UnitsToVerifyPage.error_getting_units'),
      });
    }
  };

  /**
   * Handles submission for a bulk set of unit tokens for either the multi-unit verification page
   * or the single unit verification form. Numbers that are already verified will have a subscription
   * created and others will be submitted for number verification. Only used with the V2 onboarding flow.
   *
   * @param {string[]} unitTokens
   * The list of unit tokens to submit.
   */
  submitUpdatedUnitInformation = async (
    unitTokens: string[],
  ): Promise<void> => {
    const newUnitTokensToSubscribe = unitTokens.filter((unitToken) => {
      const unit = this._stores.user.units.get(unitToken);
      return (
        unit?.subscription?.dedicatedNumber?.status ===
        UnitDedicatedNumber.Status.VERIFIED
      );
    });
    const unitTokensForNumberVerification = unitTokens.filter(
      (unitToken) => !newUnitTokensToSubscribe.includes(unitToken),
    );

    if (unitTokensForNumberVerification.length > 0) {
      const unitDedicatedNumbers =
        await this._api.subscription.ensureUnitDedicatedNumbers(
          unitTokensForNumberVerification,
        );

      unitDedicatedNumbers.forEach((dedicatedNumber) => {
        const existingSubscription = this._stores.user.units.get(
          dedicatedNumber.unitToken,
        )?.subscription;

        this._stores.user.setUnit(dedicatedNumber.unitToken, {
          subscription: {
            ...existingSubscription,
            isSubscribed: existingSubscription?.isSubscribed ?? false,
            dedicatedNumber,
          },
        });
      });
    }

    if (newUnitTokensToSubscribe.length > 0) {
      const subscribedUnitTokensNotPendingCancellation =
        this._stores.user.unitsWithMessagesPlus
          .filter((unit) => !unit.subscription?.isPendingCancellation)
          .map((unit) => unit.token);

      const isSubscriptionPendingCancellation =
        this._stores.subscription.subscriptionStatus ===
          SubscriptionStatus.ACTIVE_PENDING_CANCELLATION ||
        this._stores.subscription.subscriptionStatus ===
          SubscriptionStatus.FREE_TRIAL_PENDING_CANCELLATION;
      const isAtLeastOneNewUnitPendingCancellation =
        newUnitTokensToSubscribe.some((unitToken) =>
          this._stores.subscription.isUnitPendingCancellation(unitToken),
        );

      await this._api.subscription.createOrUpdateSubscription({
        unitTokens: [
          ...new Set([
            ...subscribedUnitTokensNotPendingCancellation,
            ...newUnitTokensToSubscribe,
          ]),
        ],
        action:
          isSubscriptionPendingCancellation &&
          isAtLeastOneNewUnitPendingCancellation
            ? CreateOrUpdateSubscriptionRequest.SubscriptionStatusActionType
                .RESUBSCRIBE
            : undefined,
      });
    }
  };

  /**
   * Update the unit info in the units store before navigating away from the unit verification form in V2
   */
  updateLocalUnitInformation = (): void => {
    if (!this.unitToken) {
      return;
    }

    const unit = this._stores.user.units.get(this.unitToken);
    this._stores.user.setUnit(this.unitToken, {
      subscription: {
        ...unit?.subscription,
        isSubscribed: unit?.subscription?.isSubscribed || false,
        verificationInfoToReview:
          GetUnitsInformationResponse.UnitInformationForReview.create({
            ...unit?.subscription?.verificationInfoToReview,
            unitInformation: {
              ...this.unitInformation,
            },
            fields: [],
          }),
      },
    });
  };

  /**
   * Used in the M+ V2 onboarding flow to save unit information updates on the backend,
   * by calling the UpdateUnitInfo API.
   *
   * @param {string[]} unitTokens
   * This list of unit tokens to update info for.
   */
  private _saveUnitInformation = async (
    unitTokens: string[],
  ): Promise<void> => {
    try {
      // Only update info for units without a verified number already
      const unitTokensForNumberVerification = unitTokens.filter(
        (unitToken) =>
          this._stores.user.units.get(unitToken)?.subscription?.dedicatedNumber
            ?.status !== UnitDedicatedNumber.Status.VERIFIED,
      );

      const updateInfoPromises = unitTokensForNumberVerification.map(
        (unitToken) => {
          const unitInformation =
            this._stores.user.units.get(unitToken)?.subscription
              ?.verificationInfoToReview?.unitInformation;
          if (!unitInformation) {
            throw new Error(
              `Unit information missing for the unit token of ${unitToken}`,
            );
          }
          return this._api.subscription.updateUnitInformation(
            unitToken,
            unitInformation,
          );
        },
      );

      await Promise.all(updateInfoPromises);
    } catch (error) {
      this._stores.status.setError({
        display: 'BANNER',
        type: 'ERROR',
        scope: 'SHEET',
        label: t('UnitVerificationForm.submission_error'),
      });

      throw error;
    }
  };

  /**
   * Saves the unit information to the backend and decides which page
   * to navigate to on the onboarding flow.
   *
   * @param {string[]} unitTokens
   * This list of unit tokens to update info for.
   */
  saveUnitInformationAndNavigate = async (
    unitTokens: string[],
  ): Promise<void> => {
    await this._saveUnitInformation(unitTokens);

    const isAllFailedRetryable = unitTokens.every((unitToken) =>
      this._stores.subscription.isUnitRetryableFailure(unitToken),
    );
    const isAllPendingCancellation = unitTokens.every((unitToken) =>
      this._stores.subscription.isUnitPendingCancellation(unitToken),
    );

    // Bypass the subscription modal if the units selected in the onboarding flow are:
    // Case 1 -  all resubmissions for failed retryable
    // Case 2 - all resubscribing while pending cancellation, but the overall subscription
    // status is NOT pending cancellation
    if (
      isAllFailedRetryable ||
      (isAllPendingCancellation && !this._stores.subscription.isExpiring)
    ) {
      try {
        await this.submitUpdatedUnitInformation(unitTokens);
      } catch (e) {
        this._stores.status.setError({
          label: t('UnitVerificationForm.submission_error'),
        });
        throw e;
      }
      this._stores.navigation.sheet.navigateTo('UNIT_VERIFICATION_SUCCESS');
    } else {
      this._stores.modal.openMessagesPlusSubscriptionModal(unitTokens);
    }
  };

  /**
   * Unit token the user is currently validating in <UnitVerificationForm/>
   */
  get unitToken(): string | undefined {
    return (
      this._stores.navigation.sheet
        .currentPage as UnitVerificationFormMessengerPage
    )?.unitToken;
  }

  private _isUnitInformationComplete = (
    unitInformation: IUnitInformation,
  ): boolean => {
    for (const [key, error] of Object.entries(
      this._getUnitInformationErrors(unitInformation),
    )) {
      if (key === 'address') {
        for (const addressError of Object.values(error)) {
          if (addressError) return false;
        }
      } else if (error) return false;
    }

    for (const [key, information] of Object.entries(unitInformation)) {
      if (key === 'address') {
        for (const requiredAddressField of REQUIRED_ADDRESS_FIELDS) {
          if (!information[requiredAddressField]) return false;
        }
      } else if (!information) return false;
    }

    return true;
  };

  /**
   * Determines if the current unit information form is ready to be submitted.
   * This means that all required fields must be non-empty and that there are no validation errors.
   */
  get isUnitInformationComplete(): boolean {
    if (!this.unitToken) return false;

    const isComplete = this._isUnitInformationComplete(this.unitInformation);

    if (!this.manuallyVerifiedWebsite) return false;

    return isComplete;
  }

  /**
   * Determines if some unit is missing information.
   *
   * @param {string[]} unitTokens
   * The list of unit tokens to check whether they are missing information.
   */
  isUnitInformationMissing = (unitTokens: string[]): boolean => {
    return unitTokens.some((unitToken) => {
      const unitInformation =
        this._stores.user.units.get(unitToken)?.subscription
          ?.verificationInfoToReview?.unitInformation;

      if (!unitInformation) {
        return true;
      }

      return !this._isUnitInformationComplete({
        ...EMPTY_UNIT_INFORMATION,
        ...unitInformation,
        address: {
          ...EMPTY_ADDRESS,
          ...unitInformation?.address,
        },
      });
    });
  };

  /**
   * Gets the information for a specific unit, that needs to be reviewed and verified.
   *
   * @param {string} unitToken Unit token for the unit to review
   * @returns {GetUnitsInformationResponse.UnitInformationForReview} Unit information that needs re-verification
   */
  unitInformationForReview(
    unitToken: string,
  ): GetUnitsInformationResponse.UnitInformationForReview | undefined {
    return this._stores.user.units.get(unitToken)?.subscription
      ?.verificationInfoToReview;
  }

  get isRetryableFailure(): boolean {
    return this._stores.subscription.isUnitRetryableFailure(
      this.unitToken ?? '',
    );
  }

  /**
   * Callback when user clicks on the checkbox to confirm whether the
   * website has been verified. This is only relevant in M+ v2.
   *
   * @param {boolean} verified
   * Whether the user checked the checkbox for manually verifying the
   * business website url.
   */
  setManuallyVerifiedWebsite = (verified: boolean): void => {
    this.manuallyVerifiedWebsite = verified;
  };

  get verificationFormHasChanges(): boolean {
    const knownUnitInformation = this._stores.user.units.get(
      this.unitToken ?? '',
    )?.subscription?.verificationInfoToReview?.unitInformation;

    return Object.keys(this.unitInformation).some((stringKey) => {
      const key = stringKey as keyof IUnitInformation;
      if (
        key === 'address' &&
        this.unitInformation.address &&
        knownUnitInformation?.address
      ) {
        return Object.keys(this.unitInformation.address).some(
          (stringAddressKey) => {
            const addressKey = stringAddressKey as keyof IAddress;
            return (
              this.unitInformation.address?.[addressKey] !==
              knownUnitInformation.address?.[addressKey]
            );
          },
        );
      }
      return knownUnitInformation?.[key] !== this.unitInformation[key];
    });
  }

  setScrollPosition = (position: number): void => {
    this.scrollPosition = position;
  };

  resetScrollPosition = (): void => {
    this.scrollPosition = 0;
  };
}

export default UnitVerificationStore;
