import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import MessengerModalDialog from 'src/components/MessengerModalDialog/MessengerModalDialog';
import { useTranslation } from 'react-i18next';
import { MarketButton } from 'src/components/Market';
import { onKeyDownEnter } from 'src/utils/keyboardUtils';

/**
 * A dialog to show that files and photos must be sent in separate messages.
 *
 * @author klim
 */
const FileAndPhotoMutuallyExclusiveDialog = observer((): ReactElement => {
  const { modal, event } = useMessengerControllerContext();
  const { t } = useTranslation();

  const onClick = (): void => {
    event.track('Click File and Photo Sent Separately Modal');

    modal.closeModal();
  };

  return (
    <MessengerModalDialog
      title={t('FileAndPhotoMutuallyExclusiveDialog.title')}
      close={modal.closeModal}
      primaryButton={
        <MarketButton
          rank="primary"
          onClick={onClick}
          onKeyDown={(e) => onKeyDownEnter(e, onClick)}
        >
          {t('common.dismiss')}
        </MarketButton>
      }
    >
      {modal.fileAndPhotoMutuallyExclusiveVariant === 'PHOTO'
        ? t('FileAndPhotoMutuallyExclusiveDialog.description_photo')
        : t('FileAndPhotoMutuallyExclusiveDialog.description_file')}
    </MessengerModalDialog>
  );
});

export default FileAndPhotoMutuallyExclusiveDialog;
