import React, { ReactElement } from 'react';

/**
 * A globe icon.
 */
const GlobeIcon = (): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="19"
    fill="none"
    viewBox="0 0 18 19"
  >
    <title>Globe</title>
    <path
      fill="#000"
      fillOpacity=".9"
      d="M9 .367c-4.96 0-9 4.04-9 9s4.03 9 8.99 9h.02c4.96 0 8.99-4.04 8.99-9s-4.04-9-9-9Zm5.45 4.61c-.56.21-1.18.39-1.84.53-.2-.95-.47-1.81-.8-2.55a7.1 7.1 0 0 1 2.64 2.02ZM16 9.367c0 .94-.19 1.83-.52 2.65-.77-.32-1.63-.57-2.57-.76.06-.61.09-1.24.09-1.89 0-.65-.03-1.28-.09-1.88.93-.19 1.8-.45 2.57-.76.33.81.52 1.7.52 2.64Zm-7 6.99c-.4-.19-1.16-1.37-1.62-3.41.53-.05 1.07-.07 1.62-.07s1.09.03 1.62.07c-.46 2.04-1.22 3.22-1.62 3.41Zm0-5.49c-.66 0-1.3.04-1.93.09-.04-.5-.07-1.02-.07-1.59 0-.57.03-1.09.07-1.59.63.06 1.27.09 1.93.09.66 0 1.3-.04 1.93-.09.04.5.07 1.02.07 1.59 0 .57-.03 1.09-.07 1.59-.63-.06-1.27-.09-1.93-.09Zm-7-1.5c0-.94.19-1.83.52-2.65.77.32 1.63.57 2.57.76-.06.61-.09 1.24-.09 1.89 0 .65.03 1.28.09 1.88-.93.19-1.8.45-2.57.76A6.98 6.98 0 0 1 2 9.367Zm7-6.99c.4.19 1.16 1.37 1.62 3.41-.53.05-1.07.08-1.62.08s-1.09-.03-1.62-.07c.46-2.05 1.22-3.23 1.62-3.42Zm-2.81.58c-.33.73-.6 1.59-.8 2.55-.66-.14-1.28-.32-1.84-.53a7.1 7.1 0 0 1 2.64-2.02Zm-2.64 10.8c.56-.21 1.17-.39 1.84-.53.2.95.47 1.81.8 2.55a7.1 7.1 0 0 1-2.64-2.02Zm8.26 2.02c.33-.73.6-1.59.8-2.55.66.14 1.28.32 1.84.53a7.1 7.1 0 0 1-2.64 2.02Z"
    />
  </svg>
);

export default GlobeIcon;
