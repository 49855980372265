/**
 * An icon for request review in input dropdown.
 */
import React, { ReactElement } from 'react';

function RequestReviewIcon(): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <title>Google review</title>
      <path
        fill="#000"
        fillOpacity=".9"
        d="M19.944 10.129a.792.792 0 0 0-.78-.651h-7.456a.791.791 0 0 0-.791.791v3.063a.792.792 0 0 0 .791.791h3.137a2.89 2.89 0 0 1-.896.94 4.006 4.006 0 0 1-2.24.622 3.908 3.908 0 0 1-3.679-2.722v-.001a3.882 3.882 0 0 1 0-2.507v-.002a3.907 3.907 0 0 1 3.678-2.72 3.465 3.465 0 0 1 2.48.964.792.792 0 0 0 1.106-.013l2.271-2.27a.79.79 0 0 0-.02-1.14A8.41 8.41 0 0 0 11.708 3a8.668 8.668 0 0 0-7.78 4.797l-.002.001A8.646 8.646 0 0 0 3 11.708a8.771 8.771 0 0 0 .926 3.911l.001.002a8.668 8.668 0 0 0 7.781 4.796 8.333 8.333 0 0 0 5.775-2.127l.002-.001a8.539 8.539 0 0 0 2.615-6.4c0-.59-.052-1.18-.157-1.76Zm-8.236-5.545a6.873 6.873 0 0 1 4.124 1.324l-1.151 1.15a5.117 5.117 0 0 0-2.973-.909A5.452 5.452 0 0 0 6.967 8.96l-.847-.656-.463-.359a7.088 7.088 0 0 1 6.051-3.361Zm-6.745 9.423a7.148 7.148 0 0 1 0-4.597l1.41 1.094a5.426 5.426 0 0 0 0 2.409l-1.41 1.094Zm6.745 4.827a7.089 7.089 0 0 1-6.05-3.362l.299-.232 1.01-.784a5.45 5.45 0 0 0 4.741 2.812 5.76 5.76 0 0 0 2.617-.594l1.34 1.04a7.038 7.038 0 0 1-3.957 1.12Zm5.167-2.186-.145-.112-1.09-.847a4.44 4.44 0 0 0 1.107-2.213.792.792 0 0 0-.778-.936H12.5v-1.48h5.976c.027.275.04.551.04.829a7.166 7.166 0 0 1-1.641 4.76Z"
      />
    </svg>
  );
}

export default RequestReviewIcon;
