import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import './StatusBanner.scss';
import { StatusType, StatusAction } from 'src/MessengerTypes';
import { MarketBanner } from 'src/components/Market';

export type StatusBannerProps = {
  label: string;
  type: StatusType;
  action?: StatusAction;
};

/**
 * Our implementation of flash-banner from glass-ui. It is a bar that spans
 * the width of the parent, that includes a label/message indicating the
 * status. 'ERROR' type is red, 'WARNING' type is yellow, 'SUCCESS' type is
 * green.
 *
 * @example
 * Basic usage:
 * <StatusBanner
 *  label="Failed to load"
 *  type="ERROR"
 * />
 *
 * With optional:
 * <StatusBanner
 *  label="Failed to load"
 *  type="ERROR"
 *  action={{ action: () => this.refresh() }}
 * />
 * @param {string} label
 * Main description of the status.
 * @param {StatusType} type
 * Type of status, which changes the color of the banner.
 * @param {StatusAction} [action]
 * (Optional) If present, shows an 'X' and executes the action
 * when it is clicked. The button property is ignored here.
 * @author klim
 */
const StatusBanner = observer((props: StatusBannerProps): ReactElement => {
  const { label, type, action } = props;

  let variant: 'info' | 'critical' | 'success' | 'warning' = 'info';
  switch (type) {
    case 'ERROR':
      variant = 'critical';
      break;
    case 'SUCCESS':
      variant = 'success';
      break;
    case 'WARNING':
      variant = 'warning';
      break;
    default:
  }

  return (
    <div className="StatusBanner">
      <MarketBanner
        variant={variant}
        onMarketBannerDismissed={() => action?.action()}
        dismissable={action != null}
        data-testid="StatusBanner"
      >
        {label}
      </MarketBanner>
    </div>
  );
});

export default StatusBanner;
