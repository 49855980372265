import { CountryCodeAndDisplay, SupportedCountry } from 'src/MessengerTypes';
/**
 * All of the countries that Square supports,
 * with translation keys for their human-readable
 * names.
 * NOTE: Keep these in alphabetical order.
 */
export const COUNTRIES: CountryCodeAndDisplay[] = [
  {
    code: 'AU',
    translationKey: 'common.countries.AU',
  },
  {
    code: 'CA',
    translationKey: 'common.countries.CA',
  },
  {
    code: 'JP',
    translationKey: 'common.countries.JP',
  },
  {
    code: 'GB',
    translationKey: 'common.countries.GB',
  },
  {
    code: 'US',
    translationKey: 'common.countries.US',
  },
];

/**
 * All of the countries that Messages Plus currently
 * operates in. Any country added to this list must
 * also have the corresponding:
 * 1. Record of "states" with "code" and "translationKey" (see ./usStates.ts for an example)
 * 2. Translations under "UnitInformationFieldForReview.business_address"
 * 3. Case in `getStateOptionsForCountry` util function (/pages/UnitVerificationForm/utils.ts)
 */
export const MPLUS_SUPPORTED_COUNTRIES: SupportedCountry[] = ['US'];
