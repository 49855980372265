import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import NewMessagePage from './pages/NewMessagePage/NewMessagePage';
import StatusPage from './components/StatusPage/StatusPage';
import TranscriptViewPage from './pages/TranscriptViewPage/TranscriptViewPage';
import CustomerDetailPage from './pages/CustomerDetailPage/CustomerDetailPage';
import MergeCustomersPage from './pages/MergeCustomersPage/MergeCustomersPage';
import useTransition from './hooks/useTransition';
import { useMessengerControllerContext } from './context/MessengerControllerContext';
import { MarketButton, MarketHeader } from './components/Market';
import MarketCloseIcon from './svgs/MarketCloseIcon';
import SubscriptionErrorScreen from './components/SubscriptionErrorScreen/SubscriptionErrorScreen';
import LoadingIndicator from './components/LoadingIndicator/LoadingIndicator';
import TranscriptsListPageContainer from 'src/components/TranscriptsListPageContainer/TranscriptsListPageContainer';
import PastAppointmentsPage from 'src/pages/PastAppointmentsPage/PastAppointmentsPage';
import ConversationHistoryPage from 'src/pages/ConversationHistoryPage/ConversationHistoryPage';

/**
 * Reusable component to dynamically render the app content based on the page
 * set in the navigation state.
 */
const AppContent = observer((): ReactElement => {
  const { t } = useTranslation();
  const { subscription, navigation } = useMessengerControllerContext();
  const [transitionDirection] = useTransition(navigation.primary);

  if (subscription.status === 'LOADING' || subscription.status === 'ERROR') {
    return (
      <>
        <MarketHeader>
          <MarketButton
            slot="navigation"
            aria-label={t('common.close')}
            onClick={navigation.primary.navigateBack}
          >
            <MarketCloseIcon slot="icon" />
          </MarketButton>
          <h2>{t('common.messenger')}</h2>
        </MarketHeader>
        {subscription.status === 'LOADING' ? (
          <LoadingIndicator />
        ) : (
          <SubscriptionErrorScreen />
        )}
      </>
    );
  }

  switch (navigation.primary.currentPageName) {
    case 'TRANSCRIPTS_LIST':
    case 'ASSISTANT_TRANSCRIPTS_LIST':
    case 'SEARCH':
      return (
        <TranscriptsListPageContainer
          transitionDirection={transitionDirection}
        />
      );
    case 'TRANSCRIPT_VIEW':
      return <TranscriptViewPage transitionDirection={transitionDirection} />;
    case 'NEW_MESSAGE':
      return <NewMessagePage transitionDirection={transitionDirection} />;
    case 'CUSTOMER_DETAIL':
      return <CustomerDetailPage transitionDirection={transitionDirection} />;
    case 'CUSTOMER_MERGE_VIEW':
      return <MergeCustomersPage transitionDirection={transitionDirection} />;
    case 'PAST_APPOINTMENTS':
      return <PastAppointmentsPage transitionDirection={transitionDirection} />;
    case 'CONVERSATION_HISTORY':
      return (
        <ConversationHistoryPage transitionDirection={transitionDirection} />
      );
    case 'STATUS':
    default:
      return <StatusPage transitionDirection={transitionDirection} />;
  }
});

export default AppContent;
