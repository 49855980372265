/**
 * A very permissive regex for matching emails
 */
export const emailRegex = /[\w%+.-]+@[\d.a-z-]+\.[a-z]{2,}/i;

/**
 * A very permissive regex for matching phone numbers. Not to
 * be used for phone formatting, but rather is useful to check
 * if some user input might be a phone number.
 */
export const phoneRegex = /^[+\d-(\s)]+$/;

/**
 * A very basic check for whether a string is a valid email
 *
 * @param {string} maybeEmail - the email to validate
 * @returns {boolean} - true if this is plausibly an email
 */
export function isValidEmail(maybeEmail: string): boolean {
  return emailRegex.test(maybeEmail);
}

/**
 * A regex for matching URLs, that is not very restrictive.
 * Taken from: https://regexr.com/3e6m0
 */
export const urlRegex =
  /(http(s)?:\/\/.)?(www\.)?[\w#%+.:=@~-]{2,256}\.[a-z]{2,6}\b([\w#%&+./:=?@~-]*)/;
