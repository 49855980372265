import React, { ReactElement, useEffect } from 'react';
import { observer } from 'mobx-react';
import { MarketBanner } from 'src/components/Market';
import { useTranslation } from 'react-i18next';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import { M_PLUS_BUSINESS_NUMBER_FAQ_URL } from 'src/utils/url';

export type VerificationInProgressBannerProps = {
  dismissable?: boolean;
};

/**
 * Banner for informing the user that their M+ subscription is still pending TFN
 * verification. This banner is only used in the M+ v2 onboarding flow.
 */
const VerificationInProgressBanner = observer((): ReactElement => {
  const { t } = useTranslation();
  const { navigation, event } = useMessengerControllerContext();

  useEffect(() => {
    event.track('View Verification In Progress Banner');
  }, [event]);

  return (
    <MarketBanner variant="info" data-testid="VerificationInProgressBanner">
      <span slot="title">{t('VerificationInProgress.title')}</span>
      {t('VerificationInProgress.description')}
      <button
        slot="action"
        onClick={() => {
          event.track('Click Verification In Progress Banner', {
            action_type_name: 'view_status_in_settings',
          });
          navigation.openSheet('SETTINGS');
        }}
      >
        {t('VerificationInProgress.settings_button')}
      </button>
      <a
        slot="action"
        href={`${M_PLUS_BUSINESS_NUMBER_FAQ_URL}`}
        target="_blank"
        rel="noreferrer"
        onClick={() => {
          event.track('Click Verification In Progress Banner', {
            action_type_name: 'learn_more',
          });
        }}
      >
        {t('VerificationInProgress.learn_more')}
      </a>
    </MarketBanner>
  );
});

export default VerificationInProgressBanner;
