/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "simple_instrument_store": {
        "nested": {
          "Brand": {
            "values": {
              "INVALID": 0,
              "UNKNOWN": 1,
              "VISA": 2,
              "MASTERCARD": 3,
              "AMEX": 4,
              "DISCOVER": 5,
              "DISCOVER_DINERS": 6,
              "JCB": 7,
              "UNION_PAY": 8,
              "SQUARE_GIFT_CARD_V2": 9,
              "INTERAC": 10,
              "SQUARE_CAPITAL_CARD": 11,
              "EFTPOS": 12,
              "FELICA": 13,
              "ALIPAY": 14,
              "CASH_APP": 15,
              "EBT": 16,
              "PAYPAY": 17,
              "AFTERPAY": 18,
              "SQUARE_ACCOUNT_BALANCE": 19,
              "AU_PAY": 20,
              "WECHAT_PAY": 21,
              "D_BARAI": 22,
              "MERPAY": 23,
              "RAKUTEN_PAY": 24
            }
          },
          "ExpirationDate": {
            "fields": {
              "year": {
                "type": "uint32",
                "id": 1,
                "rule": "optional"
              },
              "month": {
                "type": "uint32",
                "id": 2,
                "rule": "optional"
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.simple_instrument_store?.nested?.Brand && !$root.nested?.squareup?.nested?.simple_instrument_store?.nested?.ExpirationDate) {
	$root.addJSON(jsonData);
}

/**
 * This is explicitly different from squareup.common.instrument.InstrumentType. The latter
 * represents instrument types the payment stack can charge or process in different ways.
 * e.g. Balance (giftcards) is mixed in that enum.
 *
 * SOURCE Brand @ squareup/simple_instrument_store/model.proto at 115:1
 */
export const Brand = $root.lookupEnum('squareup.simple_instrument_store.Brand').values;
/**
 * SOURCE ExpirationDate @ squareup/simple_instrument_store/model.proto at 315:1
 */
export const ExpirationDate = $root.lookupType('squareup.simple_instrument_store.ExpirationDate');
fixType(ExpirationDate, {
  year: { required: true },
  month: { required: true },
});
Builder.createAndAttachToType(ExpirationDate);
