import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import './BetaPill.scss';
import { MarketPill } from 'src/components/Market';
import { useTranslation } from 'react-i18next';

/**
 * A pill to denote that a feature is in beta stage.
 *
 * @example
 * Basic usage:
 * <BetaPill />
 * @author klim
 */
const BetaPill = observer((): ReactElement => {
  const { t } = useTranslation();

  return (
    <MarketPill className="BetaPill" variant="success">
      {t('common.beta')}
    </MarketPill>
  );
});

export default BetaPill;
