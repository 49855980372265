/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';
import '../common/countries';
import '../common/currency_codes';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "merchants": {
        "nested": {
          "api": {
            "nested": {
              "UserLocale": {
                "fields": {
                  "countryCode": {
                    "type": "squareup.common.countries.Country",
                    "id": 1,
                    "rule": "optional"
                  },
                  "languageCode": {
                    "type": "string",
                    "id": 2,
                    "rule": "optional"
                  },
                  "currencyCode": {
                    "type": "squareup.common.CurrencyCode",
                    "id": 3,
                    "rule": "optional"
                  }
                }
              },
              "TransactionFund": {
                "fields": {
                  "name": {
                    "type": "string",
                    "id": 1,
                    "rule": "optional"
                  },
                  "merchantId": {
                    "type": "string",
                    "id": 2,
                    "rule": "optional"
                  },
                  "division": {
                    "type": "string",
                    "id": 3,
                    "rule": "optional"
                  }
                }
              },
              "FeeStructure": {
                "fields": {
                  "discountBasisPoints": {
                    "type": "int32",
                    "id": 1,
                    "rule": "optional"
                  },
                  "interchangeCents": {
                    "type": "int32",
                    "id": 2,
                    "rule": "optional"
                  }
                }
              },
              "UserFees": {
                "fields": {
                  "cardPresent": {
                    "type": "squareup.merchants.api.FeeStructure",
                    "id": 1,
                    "rule": "optional"
                  },
                  "cardNotPresent": {
                    "type": "squareup.merchants.api.FeeStructure",
                    "id": 2,
                    "rule": "optional"
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.merchants?.nested?.api?.nested?.UserLocale && !$root.nested?.squareup?.nested?.merchants?.nested?.api?.nested?.TransactionFund && !$root.nested?.squareup?.nested?.merchants?.nested?.api?.nested?.FeeStructure && !$root.nested?.squareup?.nested?.merchants?.nested?.api?.nested?.UserFees) {
	$root.addJSON(jsonData);
}

/**
 * SOURCE UserLocale @ squareup/merchants/api.proto at 8:1
 */
export const UserLocale = $root.lookupType('squareup.merchants.api.UserLocale');
fixType(UserLocale);
Builder.createAndAttachToType(UserLocale);
/**
 * SOURCE TransactionFund @ squareup/merchants/api.proto at 19:1
 */
export const TransactionFund = $root.lookupType('squareup.merchants.api.TransactionFund');
fixType(TransactionFund);
Builder.createAndAttachToType(TransactionFund);
/**
 * SOURCE FeeStructure @ squareup/merchants/api.proto at 25:1
 */
export const FeeStructure = $root.lookupType('squareup.merchants.api.FeeStructure');
fixType(FeeStructure);
Builder.createAndAttachToType(FeeStructure);
/**
 * SOURCE UserFees @ squareup/merchants/api.proto at 30:1
 */
export const UserFees = $root.lookupType('squareup.merchants.api.UserFees');
fixType(UserFees);
Builder.createAndAttachToType(UserFees);
