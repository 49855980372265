/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "roster": {
        "nested": {
          "mds": {
            "nested": {
              "MdsTokenType": {
                "values": {
                  "DO_NOT_USE": 0,
                  "LEGAL_ENTITY": 1,
                  "MERCHANT": 2,
                  "UNIT": 3,
                  "EMPLOYMENT": 4,
                  "PERSON": 5,
                  "ADDRESS": 6,
                  "FIDELIUS": 7,
                  "BANK_ACCOUNT": 8,
                  "EMPLOYEE_ROLE": 9,
                  "PLATFORM_ACCOUNT": 10,
                  "LE_STRUCTURE": 11,
                  "ORGANIZATION": 12,
                  "OAUTH_CLIENT": 13
                }
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.roster?.nested?.mds?.nested?.MdsTokenType) {
	$root.addJSON(jsonData);
}

/**
 * SOURCE MdsTokenType @ squareup/roster/mds_extensions.proto at 9:1
 */
export const MdsTokenType = $root.lookupEnum('squareup.roster.mds.MdsTokenType').values;
