import React, { ReactElement, ReactNode } from 'react';
import { observer } from 'mobx-react';
import { Utterance } from 'src/gen/squareup/messenger/v3/messenger_service';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import { HighlightSegment } from 'src/MessengerTypes';
import {
  getHighlightSegmentsForSubstring,
  getTextWithHighlights,
} from 'src/pages/TranscriptViewPage/components/Utterance/components/UtteranceText/utils';
import './UtteranceText.scss';

/**
 * Source: https://medium.com/better-programming/detecting-external-links-in-a-paragraph-of-text-with-javascript-automatically-3c15537f4997
 * [wdetlor 27/07/2022] Manually added dollar sign in regex to allow URLs with the `$` character
 */
const URL_REGEX =
  /(https?:\/\/)?[\w\-~]*[a-z][\w\-~]*(\.[\w\-~]*[a-z][\w\-~]*)+(\/[\w\-~@:%$]*)*(#[\w\-]*)?(\.\w*)?(\?[^\s]*)?/gi; // eslint-disable-line unicorn/better-regex, no-useless-escape

/**
 * Used internally for housekeeping as we loop over a blob of text,
 * storing each fragment of a message by its "type". This allows
 * for .map() to the appropriate elements when ready to render.
 */
type UrlMatch = {
  text: string;
  type: 'URL' | 'PLAINTEXT';
  highlightSegments: HighlightSegment[]; // Inclusive start index and exclusive end index
};

export type UtteranceTextProps = {
  text: string;
  speakerType: Utterance.SpeakerType;
  id?: number;
};

/**
 * Prepends the scheme http: to urls without a scheme. This is necessary because
 * a string that matched the URL regex but doesn't have a scheme (http:, for example)
 * will be interpreted as a relative path. So we end up with links like
 * https://squareup.com/squareup.com/appointments. See CONV-776.
 *
 * @param {string} url - the URL as it appeared in the utterance
 * @returns {string} - a fully specified URL. NOTE: This won't work for email addresses,
 * but we currently don't match emails.
 */
function convertToAbsoluteUrl(url: string): string {
  if (url.includes(':')) {
    return url;
  }
  return `http://${url}`;
}

/**
 * Render the text content of an utterance, with special styles between
 * URLs and plaintext. Emails render as plaintext for now
 * and would require a bit of refactoring to support fully. Also,
 * it's not obvious what the right behavior for emails is
 * inside a messaging application that does emails, but can't actually
 * send messages by email address.
 * Component is used inside of <Utterance />.
 *
 * NOTE: This component is a stopgap until we're ready for
 * rich text and media in Messages.
 *
 * Basic usage:
 * <UtteranceText
 *   text={'What is the URL for google.com?'}
 *   speakerType={Utterance.SpeakerType.CUSTOMER}
 * />
 *
 * @param {string} text - the raw text to split up by part
 * and render as <a>'s and/or <span>'s
 * @param {string} speakerType - the speaker type enum value, used
 * to determine the right link styling (blue for customer, white otherwise)
 * @param {number} [id] - the id of the utterance
 */
const UtteranceText = observer(
  ({ id, text, speakerType }: UtteranceTextProps): ReactElement => {
    const { transcriptView } = useMessengerControllerContext();

    const getHighlightSegments = (
      startIndex: number,
      endIndex: number,
    ): HighlightSegment[] =>
      getHighlightSegmentsForSubstring({
        substringStartIndex: startIndex,
        substringEndIndex: endIndex,
        isHighlightEnabled: transcriptView.transcript.seekUtteranceId === id,
        highlightSegments: transcriptView.seekedUtteranceHighlightSegments,
      });

    // Look over the raw text and create an array that's a mix of
    // spans of regular text and URLs
    const regex = new RegExp(URL_REGEX);
    const splitText: UrlMatch[] = [];
    // Match has a cast to RegExpExecArray because [] is missing some
    // params -- that should be OK, since `match` will be updated right away
    let match: RegExpExecArray | null = [] as unknown as RegExpExecArray;
    let startIndex = 0;
    while ((match = regex.exec(text)) != null) {
      const currentUrl: UrlMatch = {
        text: match[0],
        type: 'URL',
        highlightSegments: getHighlightSegments(
          match.index,
          match.index + match[0].length,
        ),
      };
      const currentPlaintext: UrlMatch = {
        text: text.slice(startIndex, match.index),
        type: 'PLAINTEXT',
        highlightSegments: getHighlightSegments(startIndex, match.index),
      };

      if (match.input.charAt(match.index - 1) === '@') {
        // Case: Match is an email, treat it like plaintext
        // TODO(eblaine): Update this function to work for emails, once
        // we hear feedback about what an email link should do
        currentUrl.type = 'PLAINTEXT';
      } else if (match.input.charAt(regex.lastIndex - 1) === '?') {
        // Case: Match has a "?" at the end. This is either a url
        // at the end of a question or someone included the trailing '?'.
        // Should be harmless either way, but this will mark it as
        // plaintext later
        currentUrl.text = match[0].slice(0, -1);
        regex.lastIndex -= 1;
      }

      splitText.push(currentPlaintext, currentUrl);
      startIndex = regex.lastIndex;
    }

    // Fencepost: If the text ends with plaintext, the above loop won't have
    // included it in `splitText` yet. This line adds the remaining text if present.
    if (startIndex < text.length) {
      splitText.push({
        text: text.slice(startIndex),
        type: 'PLAINTEXT',
        highlightSegments: getHighlightSegments(startIndex, text.length),
      });
    }

    const content = splitText.map((item, index) => {
      const { text, type, highlightSegments } = item;

      const formattedText: ReactNode | ReactNode[] = getTextWithHighlights(
        text,
        highlightSegments,
      );

      if (type === 'PLAINTEXT') {
        return (
          <span
            key={`${index}_${text}`}
            className={'UtteranceText__text'}
            data-testid={'UtteranceText__text'}
          >
            {formattedText}
          </span>
        );
      } else {
        const subclass =
          speakerType === Utterance.SpeakerType.CUSTOMER
            ? 'UtteranceText__link--customer'
            : 'UtteranceText__link--merchant';
        return (
          <a
            href={convertToAbsoluteUrl(text)}
            key={`${index}_${text}`}
            className={`UtteranceText__link ${subclass}`}
            data-testid={'UtteranceText__link'}
            target="_blank"
            rel="noreferrer noopener"
          >
            {formattedText}
          </a>
        );
      }
    });

    return <>{content}</>;
  },
);

export default UtteranceText;
