/**
 * An icon representing the edit action.
 */
import React, { ReactElement } from 'react';

function EditIcon(): ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
      <title>Edit</title>
      <path
        fill="#006AFF"
        fillRule="evenodd"
        d="M13 .17c.72 0 1.45.28 2 .83 1.1 1.1 1.1 2.9 0 4l-2 2-9 9H0v-4l9-9 2-2c.55-.55 1.28-.83 2-.83Zm.01 2c-.13 0-.38.03-.59.24l-.58.6 1.17 1.17.59-.59c.21-.22.24-.46.24-.59 0-.13-.02-.38-.24-.59a.843.843 0 0 0-.59-.24ZM2 14h1.17l8.41-8.41-1.17-1.17L2 12.83V14Zm6 0h8v2H8v-2Z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default EditIcon;
