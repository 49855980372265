/* eslint-disable no-param-reassign */

import { HTMLStencilElement } from '@market/react/react-component-lib/createComponent';

/**
 * A wrapper function to deal with browser compatibility with scroll() function.
 * Fall back to setting scrollTop if browser doesn't support scroll().
 *
 * @param {HTMLElement} element - the element to scroll
 * @param {number} top - the y position to scroll to, in pixels
 * @param {boolean} [smooth]
 * True if want the scroll to be smooth. Use this when the current scroll position
 * and the position we want to scroll to is small, giving it a nice subtle transition.
 * False if we want to 'jump' to the position immediately. Use this when we want to scroll
 * a large amount, and do not want the smooth transition as it takes time to animate.
 */
export function scrollElementTo(
  element: HTMLElement,
  top: number,
  smooth?: boolean,
): void {
  if (element.scroll != null) {
    element.scroll({
      behavior: smooth ? 'smooth' : 'auto',
      left: 0,
      top,
    });
  } else {
    element.scrollTop = top;
  }
}
/* eslint-enable no-param-reassign */

/**
 * Returns a promise that resolves after all the Market components of a specified
 * tag is hydrated within a parent element.
 *
 * @param {HTMLElement} parent
 * @param {keyof HTMLElementTagNameMap} marketTagName
 */
export function marketComponentsOnReady(
  parent: HTMLElement,
  marketTagName: string,
): Promise<HTMLElement> {
  const elements = parent.querySelectorAll(marketTagName);
  const promises: Promise<HTMLStencilElement>[] = [];
  elements.forEach((element) => {
    if ('componentOnReady' in element) {
      promises.push((element as HTMLStencilElement).componentOnReady());
    }
  });
  return Promise.all(promises).then(() => parent);
}
