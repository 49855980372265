import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import {
  MarketCheckbox,
  MarketList,
  MarketRow,
  MarketSelect,
} from 'src/components/Market';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';

export type UnitSelectorProps = {
  onChange: (selectedUnits: string[]) => void;
};

/**
 * Component that renders the unit selection dropdown on the Start Verification page.
 *
 * @param {(selectedUnits: string[]) => void} onChange
 * Callback executed when the selected units change.
 */
const UnitSelector = observer(
  ({ onChange }: UnitSelectorProps): ReactElement => {
    const { subscription } = useMessengerControllerContext();
    const { t } = useTranslation();

    return (
      <MarketSelect
        onMarketSelectValueDidChange={(e) => {
          const values = e.detail.value as string;
          if (values) {
            onChange(values.split(','));
          } else {
            onChange([]);
          }
        }}
        popoverContainer=".MessengerModalContext"
        value={[]}
        multiselect
        onClick={(e) => e.stopPropagation()}
        data-testid="UnitSelector__select"
      >
        <label>{t('MessagesPlusStartVerificationPage.locations_label')}</label>
        <MarketList slot="list">
          <MarketRow value="all" slot="control-row">
            <label slot="label">
              {t('MessagesPlusStartVerificationPage.select_all_label')}
            </label>
            <MarketCheckbox slot="control" />
          </MarketRow>
          {subscription.unitsAvailableForMPlusRegistration.map((unit) => (
            <MarketRow key={unit.token} value={unit.token}>
              <label slot="label">{unit.name}</label>
              <MarketCheckbox slot="control" />
            </MarketRow>
          ))}
        </MarketList>
      </MarketSelect>
    );
  },
);

export default UnitSelector;
