/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "bills": {
        "nested": {
          "ContactDetails": {
            "fields": {
              "contactToken": {
                "type": "string",
                "id": 1,
                "rule": "optional"
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.bills?.nested?.ContactDetails) {
	$root.addJSON(jsonData);
}

/**
 * SOURCE ContactDetails @ squareup/bills/contact_details.proto at 7:1
 */
export const ContactDetails = $root.lookupType('squareup.bills.ContactDetails');
fixType(ContactDetails);
Builder.createAndAttachToType(ContactDetails);
