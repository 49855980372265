import { Utterance } from 'src/gen/squareup/messenger/v3/messenger_service';

type IReaction = Utterance.Metadata.IReaction;

export const formattedEmojis: Record<string, string> = {
  '❤': '❤️',
};

/**
 * Function that returns the unique set of reactions from a given list.
 *
 * @param {IReaction[]} reactions
 * A list of reactions.
 * @returns {IReaction[]}
 * A list of the unique reactions provided from the original list.
 */
export const getUniqueReactions = (
  reactions: readonly IReaction[],
): IReaction[] =>
  reactions.reduce((uniqueReactions: IReaction[], reaction: IReaction) => {
    if (
      !uniqueReactions.some(
        (uniqueReaction) => uniqueReaction.emoji === reaction.emoji,
      )
    ) {
      uniqueReactions.push(reaction);
    }
    return uniqueReactions;
  }, []);
