import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import TranscriptsListSkeletonState from 'src/components/TranscriptsListSkeletonState/TranscriptsListSkeletonState';
import './CustomerDetailSkeletonState.scss';

/**
 * Renders a skeleton loading state representing the customer detail page.
 */
const CustomerDetailSkeletonState = observer((): ReactElement => {
  return (
    <>
      <div>
        <div className="CustomerDetailSkeletonState__contact-id" />
        <div className="CustomerDetailSkeletonState__contact-id" />
        <div className="CustomerDetailSkeletonState__buyer-summary" />
        <div className="CustomerDetailSkeletonState__conv-history-title" />
      </div>
      <TranscriptsListSkeletonState />
    </>
  );
});

export default CustomerDetailSkeletonState;
