/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "electronicmail": {
        "nested": {
          "Personalization": {
            "fields": {
              "type": {
                "type": "squareup.electronicmail.Personalization.PersonalizationType",
                "id": 1,
                "rule": "optional"
              },
              "emailAddress": {
                "type": "string",
                "id": 2,
                "rule": "optional"
              },
              "name": {
                "type": "string",
                "id": 3,
                "rule": "optional"
              }
            },
            "nested": {
              "PersonalizationType": {
                "values": {
                  "DO_NOT_USE": 0,
                  "TO": 1,
                  "CC": 2,
                  "BCC": 3,
                  "FROM": 4,
                  "REPLY_TO": 5
                }
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.electronicmail?.nested?.Personalization && !$root.nested?.squareup?.nested?.electronicmail?.nested?.Personalization?.nested?.PersonalizationType) {
	$root.addJSON(jsonData);
}

/**
 * email address, name, and type of personalization
 *
 * SOURCE Personalization @ squareup/electronicmail/service.proto at 117:1
 */
export const Personalization = $root.lookupType('squareup.electronicmail.Personalization');
fixType(Personalization);
Builder.createAndAttachToType(Personalization);
/**
 * SOURCE PersonalizationType @ squareup/electronicmail/service.proto at 121:3
 */
Personalization.PersonalizationType = $root.lookupEnum('squareup.electronicmail.Personalization.PersonalizationType').values;
