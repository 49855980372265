/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';
import '../common/token';
import './merchant';
import './tender';
import './cart';
import './refund';
import '../common/payment';
import './square_product_attributes';
import './processing_mode';
import './refund_v3';
import './electronic_signature_details';
import '../omg/model/orders';
import './ato';
import './types';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "bills": {
        "nested": {
          "Bill": {
            "fields": {
              "billTokenPair": {
                "type": "squareup.common.token.TokenPair",
                "id": 1,
                "rule": "optional"
              },
              "merchant": {
                "type": "squareup.bills.Merchant",
                "id": 2,
                "rule": "optional"
              },
              "tender": {
                "type": "squareup.bills.Tender",
                "id": 4,
                "rule": "repeated"
              },
              "cart": {
                "type": "squareup.bills.Cart",
                "id": 5,
                "rule": "optional"
              },
              "dates": {
                "type": "squareup.bills.Bill.Dates",
                "id": 6,
                "rule": "optional"
              },
              "readOnlyRefund": {
                "type": "squareup.bills.RefundV1",
                "id": 8,
                "rule": "repeated"
              },
              "squareProduct": {
                "type": "squareup.common.payment.SquareProduct",
                "id": 9,
                "rule": "optional"
              },
              "squareProductAttributes": {
                "type": "squareup.bills.SquareProductAttributes",
                "id": 10,
                "rule": "optional"
              },
              "processingMode": {
                "type": "squareup.bills.ProcessingMode",
                "id": 11,
                "rule": "optional",
                "options": {
                  "default": "ONLINE"
                }
              },
              "billRefund": {
                "type": "squareup.bills.BillRefund",
                "id": 12,
                "rule": "repeated"
              },
              "refund": {
                "type": "squareup.bills.Refund",
                "id": 15,
                "rule": "repeated"
              },
              "isWebMastered": {
                "type": "bool",
                "id": 18,
                "rule": "optional"
              },
              "readOnlyElectronicSignatureDetails": {
                "type": "squareup.bills.ElectronicSignatureDetails",
                "id": 19,
                "rule": "optional"
              },
              "order": {
                "type": "squareup.omg.model.Order",
                "id": 20,
                "rule": "optional"
              },
              "atoDetails": {
                "type": "squareup.bills.AtoDetails",
                "id": 21,
                "rule": "optional"
              },
              "currentAmendmentClientToken": {
                "type": "string",
                "id": 22,
                "rule": "optional"
              },
              "sequentialNumber": {
                "type": "string",
                "id": 23,
                "rule": "optional"
              }
            },
            "nested": {
              "Dates": {
                "fields": {
                  "createdAt": {
                    "type": "squareup.bills.DatePair",
                    "id": 1,
                    "rule": "optional"
                  },
                  "completedAt": {
                    "type": "squareup.bills.DatePair",
                    "id": 3,
                    "rule": "optional"
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.bills?.nested?.Bill && !$root.nested?.squareup?.nested?.bills?.nested?.Bill?.nested?.Dates) {
	$root.addJSON(jsonData);
}

/**
 * A Bill is detailed model about a transaction containing itemizations and tenders.
 *
 * SOURCE Bill @ squareup/bills/bill.proto at 24:1
 */
export const Bill = $root.lookupType('squareup.bills.Bill');
fixType(Bill);
Builder.createAndAttachToType(Bill);
/**
 * SOURCE Dates @ squareup/bills/bill.proto at 37:3
 */
Bill.Dates = $root.lookupType('squareup.bills.Bill.Dates');
fixType(Bill.Dates);
Builder.createAndAttachToType(Bill.Dates);
