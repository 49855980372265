import React, { ReactElement, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { MarketButton } from 'src/components/Market';
import MessengerModalDialog from 'src/components/MessengerModalDialog/MessengerModalDialog';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';

export type ConfirmBlockModalProps = {
  closeModal: () => void;
  onConfirm?: () => void;
};

/**
 * The ConfirmBlockModal component is responsible for conditionally rendering a
 * dialog UI that allows the user to confirm blocking a customer, depending on
 * the application state. It also contains the logic for any state mutations
 * (i.e. event handlers).
 *
 * @example
 * Basic usage:
 * <ConfirmBlockModal
 *  closeModal={messenger.modal.closeModal}
 *  onConfirm={() => track('Click Confirm Block')}
 * />
 * @param {() => void} closeModal
 * Callback to clear the current modal
 * @param {() => void} onConfirm
 * Callback to be called when the Confirm button is clicked
 * @author wdetlor
 */
const ConfirmBlockModal = observer(
  ({ closeModal, onConfirm }: ConfirmBlockModalProps): ReactElement | null => {
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();
    const { transcriptView } = useMessengerControllerContext();

    const handleOnConfirm = (): void => {
      setIsLoading(true);
      onConfirm && onConfirm();
      transcriptView.blockTranscript().finally(() => {
        setIsLoading(false);
        closeModal();
      });
    };

    const primaryButton = (
      <MarketButton
        rank="primary"
        onClick={handleOnConfirm}
        isLoading={isLoading || undefined}
      >
        {t('ConfirmBlockModal.confirm')}
      </MarketButton>
    );

    const secondaryButton = (
      <MarketButton rank="secondary" onClick={closeModal}>
        {t('ConfirmBlockModal.cancel')}
      </MarketButton>
    );

    return (
      <MessengerModalDialog
        title={t('ConfirmBlockModal.title')}
        primaryButton={primaryButton}
        secondaryButton={secondaryButton}
        close={closeModal}
      >
        <p>{t('ConfirmBlockModal.description')}</p>
      </MessengerModalDialog>
    );
  },
);

export default ConfirmBlockModal;
