/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "bills": {
        "nested": {
          "TenderTag": {
            "fields": {
              "key": {
                "type": "string",
                "id": 1,
                "rule": "optional"
              },
              "value": {
                "type": "string",
                "id": 2,
                "rule": "optional"
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.bills?.nested?.TenderTag) {
	$root.addJSON(jsonData);
}

/**
 * Key-value tags on the Tender. These tags can be set in the AddTenderRequest.
 *
 * SOURCE TenderTag @ squareup/bills/tender_tag.proto at 10:1
 */
export const TenderTag = $root.lookupType('squareup.bills.TenderTag');
fixType(TenderTag, {
  key: { notEmpty: true },
  value: { notEmpty: true },
});
Builder.createAndAttachToType(TenderTag);
