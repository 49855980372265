/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "common": {
        "nested": {
          "RefundPolicy": {
            "values": {
              "DEFAULT_REFUND_POLICY_DO_NOT_USE": 0,
              "PROPORTIONAL_REFUND": 1,
              "NO_FEES_REFUND": 2,
              "FULL_REFUND": 3
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.common?.nested?.RefundPolicy) {
	$root.addJSON(jsonData);
}

/**
 * The refund policy we support
 *
 * SOURCE RefundPolicy @ squareup/common/fee.proto at 64:1
 */
export const RefundPolicy = $root.lookupEnum('squareup.common.RefundPolicy').values;
