import React, { PropsWithChildren, ReactElement } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { MarketContentCard, MarketField } from 'src/components/Market';
import './SoundControlsContainer.scss';

export type SoundControlsContainerProps = {
  isError: boolean;
  errorText: string;
  className?: string;
};

/**
 * Common container used in the sound player and sound recorder components.
 *
 * @param {boolean} isError
 * Flag indicating if the container should be shown in the error state.
 * @param {string} errorText
 * Text that should be displayed in the error state.
 * @param {string} [className]
 * (Optional) Classname added to the root container to allow custom styling.
 */
const SoundControlsContainer = observer(
  ({
    isError,
    errorText,
    className,
    children,
  }: PropsWithChildren<SoundControlsContainerProps>): ReactElement => (
    <MarketField invalid={isError}>
      <MarketContentCard
        className={classNames('SoundControlsContainer', className, {
          SoundControlsContainer__error: isError,
        })}
      >
        {children}
      </MarketContentCard>
      <small slot="error">{errorText}</small>
    </MarketField>
  ),
);

export default SoundControlsContainer;
