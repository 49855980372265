import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { Transition } from 'src/MessengerTypes';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import MessengerContent from 'src/components/MessengerContent/MessengerContent';
import TranscriptsListSkeletonState from 'src/components/TranscriptsListSkeletonState/TranscriptsListSkeletonState';
import SearchPageContent from 'src/pages/SearchPage/components/SearchPageContent/SearchPageContent';

export type SearchPageProps = {
  transitionDirection: Transition;
};

/**
 * The top level page component for the search view. Contains nested components that render
 * individual portions of the search view.
 *
 * @param {Transition} transitionDirection
 * Determines which direction the content slides in from.
 */
const SearchPage = observer(
  ({ transitionDirection }: SearchPageProps): ReactElement => {
    const { status, search } = useMessengerControllerContext();

    return (
      <MessengerContent
        transitionDirection={transitionDirection}
        status={status.value}
        isLoading={search.status === 'LOADING'}
        loadingIndicator={<TranscriptsListSkeletonState />}
      >
        <SearchPageContent />
      </MessengerContent>
    );
  },
);

export default SearchPage;
