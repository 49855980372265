import React, { ReactNode } from 'react';
import { observer } from 'mobx-react';
import './MessengerModalPartial.scss';
import { Status } from 'src/MessengerTypes';
import StatusBanner from 'src/components/StatusBanner/StatusBanner';
import {
  MarketButton,
  MarketHeader,
  MarketModalPartial,
} from 'src/components/Market';
import MarketCloseIcon from 'src/svgs/MarketCloseIcon';
import MessengerModalContext from 'src/components/MessengerModalContext';

export type MessengerModalPartialProps = {
  title: ReactNode;
  children: ReactNode;
  close: () => void;
  primaryButton?: ReactNode;
  secondaryButton?: ReactNode;
  status: Status | null;
  overflow?: boolean;
  compactHeader?: boolean;
};

/**
 * A modal that appears in the middle of the screen, surrounded by a veil.
 * The modal contains a header with the title and close icon, the body, and
 * header with left and right buttons. Typically used when user needs to fill
 * up some information.
 *
 * Note that regardless of where this component is used, it will always be
 * rendered as a child of the shadow root using React portal.
 *
 * @example
 * Basic usage:
 * <MessengerModalPartial
 *   title="Send Payment"
 *   close={() => closeModal()}
 *   primaryButton={<MarketButton label="Submit" />}
 * >
 *   {paymentForm}
 * </MessengerModalPartial>
 *
 * With optional:
 * <MessengerModalPartial
 *   title="Send Payment"
 *   close={() => closeModal()}
 *   primaryButton={<MarketButton label="Submit" />}
 *   secondaryButton={<MarketButton label="Cancel" />}
 * >
 *   {paymentForm}
 * </MessengerModalPartial>
 * @param {ReactNode} title
 * Title of the modal.
 * @param {ReactNode} children
 * Component to render in the body.
 * @param {() => void} close
 * Function to call when close icon is clicked.
 * @param {ReactNode} [primaryButton]
 * A button that appears on the right of the footer.
 * @param {ReactNode} [secondaryButton]
 * (Optional) A button that appears on the left of the footer.
 * @param {Status} status
 * The status to be shown in the modal, if any. Supports display of a
 * banner below the title, useful for error handling.
 * @param {boolean} [overflow]
 * (Optional) Allow contents to overflow, useful when market-select
 * is used within.
 * TODO(klim): remove this prop when Market 3.0 fix overflow issue
 * @param {boolean} [compactHeader]
 * (Optional) Whether the compact header should always be shown,
 * @author klim
 */
const MessengerModalPartial = observer(
  ({
    title,
    children,
    close,
    primaryButton,
    secondaryButton,
    status,
    overflow,
    compactHeader,
  }: MessengerModalPartialProps) => {
    let banner = null;
    if (status?.scope === 'MODAL' && status.display === 'BANNER') {
      banner = (
        <StatusBanner
          label={status.label}
          type={status.type}
          action={status.action}
        />
      );
    }

    return (
      <MessengerModalContext close={close}>
        <MarketModalPartial
          className={`MessengerModalPartial${
            overflow ? ' MessengerModalPartial__overflow' : ''
          }`}
          data-testid="MessengerModalPartial"
        >
          <MarketHeader showNavigation compact={compactHeader}>
            <MarketButton
              rank="secondary"
              slot="navigation"
              onClick={close}
              data-testid="MessengerModalPartial__close"
            >
              <MarketCloseIcon slot="icon" />
            </MarketButton>
            <h2>{title}</h2>
            {secondaryButton ? (
              <div slot="actions">{secondaryButton}</div>
            ) : null}
            <div slot="actions">{primaryButton}</div>
          </MarketHeader>
          <main>
            {banner}
            {children}
          </main>
        </MarketModalPartial>
      </MessengerModalContext>
    );
  },
);

export default MessengerModalPartial;
