/**
 * This icon is used in a button and should be used in navigation
 * where we want to go back to a previous page.
 */

import React, { ReactElement } from 'react';
import BaseSvgProps from 'src/types/BaseSvgProps';

const MarketBackIcon = (props: BaseSvgProps): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="16"
    fill="none"
    viewBox="0 0 15 16"
    {...props}
  >
    <title>Back</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.293 7.293a1 1 0 0 0 0 1.414l7 7 1.414-1.414L3.414 9H15V7H3.414l5.293-5.293L7.293.293l-7 7Z"
    />
  </svg>
);

export default MarketBackIcon;
