import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import './NewCustomerButton.scss';
import NewCustomerModal, {
  NewCustomerModalOnSaveArgs,
} from 'src/components/NewCustomerModal/NewCustomerModal';
import { SearchQueryType } from 'src/MessengerTypes';
import { MarketButton, MarketLink } from 'src/components/Market';
import { onKeyDownEnter } from 'src/utils/keyboardUtils';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import { Contact } from 'src/gen/squareup/messenger/v3/messenger_auxiliary_service';

export type NewCustomerButtonProps = {
  query: string;
  selectConversationByContact: (contact: Contact) => void;
  queryType?: SearchQueryType;
  isInNullState?: boolean;
};

/**
 * A button that appears underneath the search bar on the Conversations List
 * and New Message pages. Its text says 'Create Customer "{{query}}"' and
 * it opens a modal that allows the user to create a customer with Rolodex.
 *
 * @param {string} query
 * The current query that the user typed into the search bar. Is displayed
 * by this component and passed to the modal.
 * @param {Function} selectConversationByContact
 * Selects a conversation based on a contact.
 * @param {SearchQueryType} [queryType]
 * (Optional) The type of query.
 * @param {boolean} [isInNullState]
 * If true, this button is in a null state which should be primary in rank and slotted
 * into 'actions'.
 */
const NewCustomerButton = observer(
  (props: NewCustomerButtonProps): ReactElement => {
    const { queryType, query, isInNullState, selectConversationByContact } =
      props;
    const { modal, event, status, api, newMessageSearch } =
      useMessengerControllerContext();
    const { t } = useTranslation();

    const onSave = async ({
      firstName,
      lastName,
      email,
      phoneNumber,
    }: NewCustomerModalOnSaveArgs): Promise<void> => {
      event.track('Click New Customer Modal Save', {
        first_name: firstName,
        last_name: lastName,
      });
      try {
        const contact = await api.contacts.createContact({
          givenName: firstName || '',
          surname: lastName || '',
          emailAddress: email || '',
          phoneNumber: phoneNumber || '',
        });

        if (contact === null) {
          throw new Error('Contact returned is null.');
        }
        newMessageSearch.setQuery('');
        if (contact) {
          selectConversationByContact(contact);
        }
      } catch {
        status.setModalError({
          label: t('NewCustomer.modal.failed_to_create'),
        });
        throw new Error('Failed to create contact.');
      }
    };

    const newCustomerModal = (
      <NewCustomerModal
        initialFirstName={queryType === 'NAME' ? query : undefined}
        initialEmail={queryType === 'EMAIL' ? query : undefined}
        initialPhoneNumber={queryType === 'PHONE' ? query : undefined}
        onSave={onSave}
      />
    );

    return (
      <>
        {isInNullState ? (
          <MarketButton
            rank="primary"
            slot="actions"
            onClick={() => modal.openNewCustomerModal()}
            onKeyDown={(e) =>
              onKeyDownEnter(e, () => modal.openNewCustomerModal())
            }
            data-testid="NewCustomerButton"
          >
            {t('NewCustomer.create_cta')}
          </MarketButton>
        ) : (
          <MarketLink
            className="NewCustomerButton__link"
            href=""
            onClick={(e) => {
              e.preventDefault();
              modal.openNewCustomerModal();
            }}
            onKeyDown={(e) =>
              onKeyDownEnter(e, () => {
                e.preventDefault();
                modal.openNewCustomerModal();
              })
            }
          >
            {t('NewCustomer.create_cta')}
            {query ? ` "${query}"` : ''}
          </MarketLink>
        )}
        {modal.currentModal === 'NEW_CUSTOMER' ? newCustomerModal : null}
      </>
    );
  },
);

export default NewCustomerButton;
