import React, { ReactElement, useEffect } from 'react';
import { observer } from 'mobx-react';
import { MarketBanner } from 'src/components/Market';
import { useTranslation } from 'react-i18next';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import { useLongDateFromMicroseconds } from 'src/utils/timeUtils';

/**
 * Banner for informing the user that their M+ subscription is now active.
 * This banner is only used in the M+ v2 onboarding flow.
 */
const MessagesPlusSuccessBanner = observer((): ReactElement => {
  const { t } = useTranslation();
  const { tooltip, navigation, subscription, event } =
    useMessengerControllerContext();

  useEffect(() => {
    event.track('View Successfully Verified Banner');
  }, [event]);

  const { freeTrialEndAt } = subscription;

  return (
    <MarketBanner
      variant="success"
      dismissable
      onMarketBannerDismissed={() => {
        tooltip.dismiss('MESSAGES_PLUS_SUCCESS_BANNER');
      }}
      data-testid="MessagesPlusSuccessBanner"
    >
      <span slot="title">{t('MessagesPlusSuccessBanner.title')}</span>
      {freeTrialEndAt
        ? t('MessagesPlusSuccessBanner.free_trial_description', {
            date: useLongDateFromMicroseconds(freeTrialEndAt),
          })
        : t('MessagesPlusSuccessBanner.active_subscription_description')}
      <button
        slot="action"
        onClick={() => {
          event.track('Click Successfully Verified Banner');
          navigation.navStoreForUrl.navigateTo('NEW_MESSAGE');
        }}
      >
        {t('MessagesPlusSuccessBanner.button_text')}
      </button>
    </MarketBanner>
  );
});

export default MessagesPlusSuccessBanner;
