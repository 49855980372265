import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import './GeneralEventCard.scss';
import EventCard, {
  SharedEventCardProps,
} from 'src/pages/TranscriptViewPage/components/EventCard/EventCard';

export type GeneralEventCardProps = SharedEventCardProps;

/**
 * Represents a general event that happened between the merchant and buyer.
 * For example, appointment completed in the past. Appears in a conversation
 * view page. This is used when an event is initiated either by (both merchant
 * and customer) or (neither merchant or customer).
 *
 * @example
 * Basic usage:
 * <GeneralEventCard
 *  icon={<AppointmentIcon />}
 *  title="Appointment Completed"
 *  subtitle="Full House Clean ・ Sandra Walker"
 * />
 *
 * With optional:
 * <GeneralEventCard
 *  timestampMillis={123781231000}
 *  icon={<AppointmentIcon />}
 *  title="Appointment Completed"
 *  subtitle="Full House Clean ・ Sandra Walker"
 *  link="/appointments/reservation/123"
 * />
 * @param {number} timestampMillis
 * Time when this event happened, in millisecond. Shown when hovered.
 * @param {ReactElement} icon
 * Icon representing this event. It should generally be a square of size 38x38.
 * @param {string} title
 * Title of event.
 * @param {string} subtitle
 * Usually additional information of the event.
 * @param {string} [link]
 * (Optional) Link to webpage when component is clicked.
 * @param {string} [linkText]
 * (Optional) The text of the button for the link.
 * @param {Function} [track]
 * (Optional) If present, call this function when link is clicked. Used mainly to
 * trigger a track on an event.
 * @author klim
 */
const GeneralEventCard = observer(
  (props: GeneralEventCardProps): ReactElement => {
    const { timestampMillis, icon, title, subtitle, link, linkText, track } =
      props;

    return (
      <div
        className="GeneralEventCard Utterance__timestamp-container"
        data-testid="GeneralEventCard"
      >
        <EventCard
          timestampMillis={timestampMillis}
          icon={icon}
          title={title}
          subtitle={subtitle}
          link={link}
          linkText={linkText}
          track={track}
        />
      </div>
    );
  },
);

export default GeneralEventCard;
