import { observer } from 'mobx-react';
import React, { ReactElement } from 'react';
import { GetUnitsInformationResponse } from 'src/gen/squareup/messenger/v3/messenger_service';
import TextInput from './TextInput';
import { useTranslation } from 'react-i18next';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import {
  MarketButton,
  MarketCheckbox,
  MarketField,
  MarketRow,
  MarketTooltip,
} from 'src/components/Market';
import { isMalformedUrl } from 'src/utils/url';
import './OnlineBusinessPageSection.scss';

/**
 * The "Online business page" section in the individual unit verification form for
 * M+ Onboarding v2. This section covers the website and the manual confirmation checkbox.
 *
 * @example
 * Usage:
 * <OnlineBusinessPageSection />
 */
const OnlineBusinessPageSection = observer((): ReactElement => {
  const { t } = useTranslation();
  const {
    unitVerification: {
      manuallyVerifiedWebsite,
      setManuallyVerifiedWebsite,
      getDisplayValue,
      showError,
    },
  } = useMessengerControllerContext();

  const displayValue = getDisplayValue('websiteUrl');
  const hasCheckboxError = showError && !manuallyVerifiedWebsite;

  const previewButton = (
    <MarketButton
      size="small"
      onClick={() => {
        if (displayValue) window.open(displayValue, '_blank');
      }}
      data-testid="TextInput__preview-website-btn"
      slot={
        displayValue && isMalformedUrl(displayValue) ? 'trigger' : undefined
      }
      disabled={(displayValue && isMalformedUrl(displayValue)) || undefined}
    >
      {t('OnlineBusinessPageSection.website_preview_button')}
    </MarketButton>
  );

  return (
    <>
      <h3 className="UnitVerificationForm__section__title">
        {t('OnlineBusinessPageSection.title')}
      </h3>
      <div className="UnitVerificationForm__content__form__input-description">
        {t('OnlineBusinessPageSection.description')}
      </div>
      <form
        className="market-grid-container UnitVerificationForm__section__form"
        onSubmit={(event) => {
          event.preventDefault();
        }}
      >
        <TextInput
          field={GetUnitsInformationResponse.FieldForReview.WEBSITE_URL}
          trailingButton={
            displayValue && isMalformedUrl(displayValue) ? (
              <MarketTooltip className="UnitVerificationForm__preview-button-tooltip">
                {previewButton}
                <div slot="content">
                  {t('OnlineBusinessPageSection.malformed_url_text')}
                </div>
              </MarketTooltip>
            ) : (
              previewButton
            )
          }
        />
      </form>
      <MarketField
        className="OnlineBusinessPageSection__confirmation-field"
        invalid={hasCheckboxError || undefined}
      >
        <MarketRow
          control-position="leading"
          interactive
          onMarketRowSelected={() => setManuallyVerifiedWebsite(true)}
          onMarketRowDeselected={() => setManuallyVerifiedWebsite(false)}
          data-testid="OnlineBusinessPageSection__checkbox"
        >
          <label slot="label">
            {t('OnlineBusinessPageSection.website_confirmation')}
          </label>
          <MarketCheckbox
            slot="control"
            invalid={hasCheckboxError || undefined}
          ></MarketCheckbox>
        </MarketRow>
        {hasCheckboxError && (
          <small slot="error">
            {t('UnitVerificationForm.validation_errors.website_check')}
          </small>
        )}
      </MarketField>
    </>
  );
});

export default OnlineBusinessPageSection;
