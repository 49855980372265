import React, { ReactNode, Ref, forwardRef } from 'react';
import { observer } from 'mobx-react';
import StatusBanner from 'src/components/StatusBanner/StatusBanner';
import { Status } from 'src/MessengerTypes';
import LoadingIndicator from 'src/components/LoadingIndicator/LoadingIndicator';
import StatusScreen from 'src/components/StatusScreen/StatusScreen';
import { useMessengerModalFullContext } from 'src/components/MessengerModalFull/context/MessengerModalFullContext';
import './MessengerModalFullContent.scss';

export type MessengerModalFullContentProps = {
  status: Status | null;
  isLoading?: boolean;
  children: ReactNode;
};

/**
 * Component that contains the main content of a MessengerModalFull instance.
 * This component cannot be wrapped in a container, as it must be a direct child
 * of <MarketModalFull /> in order to receive host styling.
 *
 * @param {Status} status
 * The status to be shown in the modal, if any. Supports display of a
 * banner below the title, useful for error handling.
 * @param {boolean} [isLoading]
 * (Optional) If true, show loading indicator instead of content.
 * @param {ReactNode} children
 * Component to render in the body.
 */
const MessengerModalFullContent = forwardRef(function (
  { status, isLoading, children }: MessengerModalFullContentProps,
  ref: Ref<HTMLElement>,
) {
  const { isAnimationEnded } = useMessengerModalFullContext();
  let banner;
  if (status?.scope === 'SHEET' && status.display === 'BANNER') {
    banner = (
      <StatusBanner
        label={status.label}
        type={status.type}
        action={status.action}
      />
    );
  }

  let content;
  if (isAnimationEnded) {
    if (isLoading) {
      content = <LoadingIndicator />;
    } else if (status?.scope === 'SHEET' && status.display === 'SCREEN') {
      content = (
        <StatusScreen
          action={status.action?.action}
          actionLabel={status.actionLabel}
        >
          {status.label}
        </StatusScreen>
      );
    } else {
      content = children;
    }
  }

  return (
    <main className="MessengerModalFullContent" ref={ref}>
      {banner}
      {content}
    </main>
  );
});

MessengerModalFullContent.displayName = 'MessengerModalFullContent';
export default observer(MessengerModalFullContent);
