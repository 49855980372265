import React, {
  ReactElement,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import { observer } from 'mobx-react';
import './InputSearchDropdown.scss';
import {
  MarketField,
  MarketInputText,
  MarketList,
  MarketPopover,
  MarketRow,
} from 'src/components/Market';
import { useDebouncedCallback } from 'use-debounce';
import LoadingIndicator from 'src/components/LoadingIndicator/LoadingIndicator';
import { getReactRoot } from 'src/utils/shadowDomUtils';

// The delay (in ms) before we call the search function.
const DEFAULT_INPUT_TYPE_DELAY = 500;

export type InputSearchDropdownItem = {
  id: string;
  name: string;
};

export type InputSearchDropdownProps<T extends InputSearchDropdownItem> = {
  query: string;
  onQueryChange: (query: string) => void;
  label: string;
  search: (query: string) => Promise<void>;
  items: T[];
  onSelect: (id: string, name: string) => void;
  inputDelay?: number;
  nullItem?: ReactNode;
  errorText?: string;
};

/**
 * An input that searches after a delay, and list down the results in a dropdown.
 * The dropdown will be hidden in the following cases:
 * 1) If the query is empty
 * 2) If a selection is made
 * 3) If the user click outside of the component
 *
 * It is up to the caller how they want to control the items list. If a
 * selection is made and the results list should be emptied, the logic
 * should be coded by the caller.
 *
 * @example
 * Basic usage:
 * <InputSearchDropdown
 *   label="Address"
 *   search={(query) => getPlacePredictions(query)}
 *   items={placeResults}
 *   onSelect={(id) => setAddress(id)}
 * />
 * @param {string} query
 * The value of the search query.
 * @param {string} onQueryChange
 * Function to call when the user types something. Note that this is a separate
 * function from searching as that requires debouncing.
 * @param {string} label
 * The label of the input.
 * @param {(string) => Promise<void>} search
 * The function to call when the user types in the input.
 * @param {InputSearchDropdownItem[]} items
 * The results to list in the dropdown.
 * @param {(string, string) => void} onSelect
 * Function to call when an item in the dropdown is selected.
 * @param {number} [inputDelay]
 * (Optional) Time to delay calling search after a query is typed, in ms.
 * @param {ReactNode} [nullItem]
 * (Optional) If there is a query but no results, show this element in the dropdown.
 * @param {string} [errorText]
 * (Optional) If present, show a red error text and red border.
 * @author klim
 */
const InputSearchDropdown = observer(
  <T extends InputSearchDropdownItem>(
    props: InputSearchDropdownProps<T>,
  ): ReactElement => {
    const {
      query,
      onQueryChange,
      label,
      search,
      items,
      onSelect,
      inputDelay,
      nullItem,
      errorText,
    } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [showPopover, setShowPopover] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);

    const debouncedSearch = useDebouncedCallback(async (text) => {
      setIsLoading(true);
      setShowPopover(true);
      await search(text);
      setIsLoading(false);
    }, inputDelay || DEFAULT_INPUT_TYPE_DELAY);

    // Set up mouse event handler when we click outside of component
    useEffect(() => {
      const onClickOutside = (event: MouseEvent): void => {
        if (!containerRef?.current || !(event.target instanceof Node)) {
          return;
        }

        if (!containerRef.current.contains(event.target)) {
          debouncedSearch.cancel();
          setShowPopover(false);
        }
      };

      const rootElement = getReactRoot() ?? document.body;
      rootElement.addEventListener('click', onClickOutside);
      return () => {
        debouncedSearch.cancel();
        rootElement.removeEventListener('click', onClickOutside);
      };
      // Intentionally blank to trigger only on mount
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Always close popover when query is empty
    useEffect(() => {
      if (!query) {
        setShowPopover(false);
      }
    }, [query]);

    return (
      <div
        className="InputSearchDropdown"
        ref={containerRef}
        data-testid="InputSearchDropdown"
      >
        <MarketField invalid={Boolean(errorText) || undefined}>
          <MarketInputText
            value={query}
            onMarketInputValueChange={(event) => {
              const text = event.detail.value;
              onQueryChange(text);

              if (text) {
                debouncedSearch(text);
              }
            }}
            onFocus={() => {
              if (query) {
                setShowPopover(true);
              }
            }}
            data-testid="InputSearchDropdown__input"
          >
            <label>{label}</label>
          </MarketInputText>
          <small slot="error">{errorText}</small>
        </MarketField>
        {showPopover && (items.length > 0 || nullItem) && (
          <MarketPopover
            className="InputSearchDropdown__popover"
            data-testid="InputSearchDropdown__popover"
          >
            <MarketList
              interactive={!isLoading}
              onMarketListSelectionsDidChange={(event) => {
                const id = event.detail.newSelectionValue;
                const name = (items.find((item) => item.id === id) as T).name;
                onQueryChange(name);
                setShowPopover(false);
                onSelect(id, name);
              }}
              data-testid="InputSearchDropdown__popover__list"
            >
              {isLoading ? (
                <MarketRow size="small">
                  <LoadingIndicator isSmall />
                </MarketRow>
              ) : (
                items.map((item) => (
                  <MarketRow key={item.id} value={item.id} size="small">
                    {item.name}
                  </MarketRow>
                ))
              )}
            </MarketList>
            {query !== '' && items.length === 0 && !isLoading && nullItem}
          </MarketPopover>
        )}
      </div>
    );
  },
);

export default InputSearchDropdown;
