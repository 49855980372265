import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { MarketButton, MarketEmptyState } from 'src/components/Market';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import ChatBubblesIcon from 'src/svgs/ChatBubblesIcon';
import { GET_STARTED_URL } from 'src/utils/url';
import { KEY_MESSAGES_PLUS } from 'src/stores/FeatureFlagStore';
import UpgradeEmptyState from 'src/components/UpgradeEmptyState/UpgradeEmptyState';
import './MessengerEmptyState.scss';
import VerificationInProgressState from 'src/pages/TranscriptViewPage/components/VerificationInProgressState/VerificationInProgressState';
import MessagesPlusSuccessEmptyState from 'src/components/MessagesPlusSuccessEmptyState/MessagesPlusSuccessEmptyState';
import VerificationFailedRetryableState from 'src/pages/TranscriptViewPage/components/VerificationFailedRetryableState/VerificationFailedRetryableState';

/**
 * Component that renders the default empty state for the Message application.
 * This is shown when the merchant has no transcripts, in either the
 * transcripts list page (blade or Inbox mobile-web) or the transcript view page (Inbox-only).
 */
const MessengerEmptyState = observer((): ReactElement => {
  const { event, navigation, featureFlag, subscription } =
    useMessengerControllerContext();
  const { t } = useTranslation();

  if (
    featureFlag.get(KEY_MESSAGES_PLUS) &&
    subscription.unitTokensFailedRetryable.length > 0
  ) {
    return (
      <VerificationFailedRetryableState
        unitTokens={subscription.unitTokensFailedRetryable}
      />
    );
  }

  if (
    featureFlag.get(KEY_MESSAGES_PLUS) &&
    subscription.hasUnitsPendingOrFailedNonretryableNotProhibited
  ) {
    return <VerificationInProgressState />;
  }

  if (featureFlag.get(KEY_MESSAGES_PLUS) && subscription.hasSubscribedUnit) {
    return <MessagesPlusSuccessEmptyState />;
  }

  if (featureFlag.get(KEY_MESSAGES_PLUS) && !subscription.isSubscribed) {
    return <UpgradeEmptyState />;
  }

  return (
    <MarketEmptyState className="MessengerEmptyState__content">
      <div slot="primary-text">
        <ChatBubblesIcon />
        <h3>{t('MessengerEmptyState.title')}</h3>
      </div>
      <p slot="secondary-text">{t('MessengerEmptyState.description')}</p>
      <MarketButton slot="actions" href={GET_STARTED_URL} target="_blank">
        {t('MessengerEmptyState.secondary_button_text')}
      </MarketButton>
      <MarketButton
        rank="primary"
        slot="actions"
        onClick={() => {
          event.track('Click Conversations List Null State Send Message');
          navigation.navStoreForUrl.navigateTo('NEW_MESSAGE');
        }}
      >
        {t('MessengerEmptyState.primary_button_text')}
      </MarketButton>
    </MarketEmptyState>
  );
});

export default MessengerEmptyState;
