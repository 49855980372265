/**
 * An icon representing photo.
 */
import React, { ReactElement } from 'react';

function PhotoIcon(props: { color?: string }): ReactElement {
  const { color } = props;
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Photo</title>
      <path
        d="M3 15L7 11L10 14M10 14L9 15M10 14L15 9L21 15M3 3H21V21H3V3Z"
        stroke={color ?? '#000'}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="9.5" cy="7.5" r="1.5" fill={color ?? 'black'} />
    </svg>
  );
}

export default PhotoIcon;
