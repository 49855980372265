import { makeAutoObservable } from 'mobx';
import { t } from 'i18next';
import type MessengerController from 'src/MessengerController';
import Api from 'src/api/Api';
import {
  CheckoutLink,
  CheckoutLinkLimits,
  LoadingStatus,
} from 'src/MessengerTypes';
import {
  MAX_CHECKOUT_LINK_AMOUNT,
  MIN_CHECKOUT_LINK_AMOUNT,
} from 'src/api/WeeblyApi';

/**
 * Store responsible for getting checkout links.
 */
export default class CheckoutLinksStore {
  private _stores: MessengerController;
  private _api: Api;

  /**
   * The min and max amount for checkout links.
   */
  checkoutLinkLimits: CheckoutLinkLimits = {
    minAmount: MIN_CHECKOUT_LINK_AMOUNT,
    maxAmount: MAX_CHECKOUT_LINK_AMOUNT,
  };

  existingLinks: CheckoutLink[] = [];

  status: LoadingStatus = 'NOT_STARTED';

  loadNextStatus: LoadingStatus = 'NOT_STARTED';

  cursor?: string;

  constructor(stores: MessengerController) {
    makeAutoObservable(this);

    this._stores = stores;
    this._api = stores.api;
  }

  init = (): void => {
    this.getCheckoutLinkLimits();
  };

  private _onError = (): void => {
    this._stores.status.setModalError({
      label: t('CheckoutLinkModal.error.failed_to_load'),
    });
  };

  /**
   * Loads the initial list of checkout links.
   */
  getCheckoutLinks = async (): Promise<void> => {
    try {
      this.status = 'LOADING';
      const [links, nextCursor] = await this._api.weebly.listCheckoutLinks();

      this.status = 'SUCCESS';
      this.existingLinks = links;
      this.cursor = nextCursor;
    } catch {
      this.status = 'ERROR';
      this._onError();
    }
  };

  /**
   * Loads further pages of checkout links. As of 2024-01-30 pagination is not
   * supported in the UI yet.
   */
  getMoreCheckoutLinks = async (): Promise<void> => {
    if (!this.cursor) {
      return;
    }

    try {
      this.loadNextStatus = 'LOADING';
      const [links, nextCursor] = await this._api.weebly.listCheckoutLinks(
        this.cursor,
      );

      this.loadNextStatus = 'SUCCESS';
      this.existingLinks = links;
      this.cursor = nextCursor;
    } catch {
      this.loadNextStatus = 'ERROR';
      this._onError();
    }
  };

  /**
   * Thin wrapper around {@link WeeblyApi#getCheckoutLinkLimits}. Fetches the
   * min and max amount limits for checkout links.
   */
  getCheckoutLinkLimits = async (): Promise<void> => {
    this.checkoutLinkLimits = await this._api.weebly.getCheckoutLinkLimits(
      this._stores.user.merchantToken,
    );
  };
}
