/**
 * An icon representing a generic file.
 */
import React, { ReactElement } from 'react';

function GenericFileIcon(): ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="20" fill="none">
      <title>File</title>
      <path
        fill="#000"
        fillOpacity=".9"
        fillRule="evenodd"
        d="M3 0a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3V5.586L8.414 0H3ZM2 3a1 1 0 0 1 1-1h4v5h5v10a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3Zm8.586 2L9 3.414V5h1.586Z"
        clipRule="evenodd"
      />
      <path fill="#000" fillOpacity=".9" d="M10 16H4v-2h6v2ZM4 12h6v-2H4v2Z" />
    </svg>
  );
}

export default GenericFileIcon;
