import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { MarketButton } from 'src/components/Market';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import { onKeyDownEnter } from 'src/utils/keyboardUtils';
import { UnitSetting } from 'src/MessengerTypes';
import MessengerModalDialog from 'src/components/MessengerModalDialog/MessengerModalDialog';
import 'src/pages/EditVoicemailPage/components/DeleteVoicemailConfirmationDialog/DeleteVoicemailConfirmationDialog.scss';

export type DeleteVoicemailConfirmationDialogProps = {
  unitSetting: UnitSetting;
};

/**
 * Modal to confirm deleting a voicemail.
 *
 * @param {UnitSetting} unitSetting
 * The unit setting to delete the voicemail from.
 * @returns {ReactElement | null}
 */
const DeleteVoicemailConfirmationDialog = observer(
  ({
    unitSetting,
  }: DeleteVoicemailConfirmationDialogProps): ReactElement | null => {
    const { modal, status, settings } = useMessengerControllerContext();
    const { t } = useTranslation();

    const unitToken = unitSetting.unitToken;

    const onSave = async (): Promise<void> => {
      try {
        await settings.saveVoicemailUnitSettings(unitToken, {
          ...unitSetting.voicemail,
          customVoicemailGreeting: undefined,
          customVoicemailId: '',
        });
      } catch {
        status.setModalError({
          label: t('EditVoicemailPage.edit_toggle_error_text'),
        });
        return;
      }
      status.setSuccess({
        label: t(
          'EditVoicemailPage.delete_voicemail_confirmation_success_text',
        ),
      });
      modal.closeModal();
    };

    const onDismiss = (): void => {
      modal.closeModal();
    };

    return (
      <MessengerModalDialog
        title={t('EditVoicemailPage.delete_voicemail_confirmation_title')}
        close={modal.closeModal}
        primaryButton={
          <MarketButton
            rank="primary"
            variant="destructive"
            onClick={onSave}
            onKeyDown={(e) => onKeyDownEnter(e, onSave)}
            isLoading={settings.voicemailSaveStatus === 'LOADING' || undefined}
          >
            {t('EditVoicemailPage.delete_voicemail_confirmation_delete')}
          </MarketButton>
        }
        secondaryButton={
          <MarketButton
            onClick={onDismiss}
            onKeyDown={(e) => onKeyDownEnter(e, onDismiss)}
          >
            {t('common.dismiss')}
          </MarketButton>
        }
        status={status.value}
      >
        <p className="DeleteVoicemailConfirmationDialog__description">
          {t('EditVoicemailPage.delete_voicemail_confirmation_description')}
        </p>
      </MessengerModalDialog>
    );
  },
);

export default DeleteVoicemailConfirmationDialog;
