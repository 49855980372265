import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import './SearchAssistantToggle.scss';
import { useTranslation } from 'react-i18next';
import { MarketDivider, MarketToggle } from 'src/components/Market';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';

/**
 * A toggle to show/hide automated ("Assistant") messages in the search results.
 */
const SearchAssistantToggle = observer((): ReactElement => {
  const { t } = useTranslation();
  const { search } = useMessengerControllerContext();
  const {
    utterances: { setIncludeAutomatedUtterances, includeAutomatedUtterances },
  } = search;

  return (
    <>
      <div
        className="SearchAssistantToggle"
        data-testid="SearchAssistantToggle"
      >
        <p className="semibold-20">{t('SearchBar.show_assistant_messages')}</p>
        <MarketToggle
          checked={includeAutomatedUtterances || undefined}
          onMarketToggleChange={(event) => {
            setIncludeAutomatedUtterances(event.detail.current);
          }}
          data-testid="SearchAssistantToggle__toggle"
        />
      </div>
      <MarketDivider
        className="SearchAssistantToggle__divider"
        margin="small"
      />
    </>
  );
});

export default SearchAssistantToggle;
