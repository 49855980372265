import {
  Attributes,
  Attribute,
  TokenType,
  Token,
  Status,
  ExclusionReason,
} from './types';

export function isSuccess(status: Status) {
  return [Status.SUCCESS, Status.IMPRESSION_LIMIT_EXCEEDED].includes(status);
}

export function formatAttributes(
  attributes?: Attributes
): readonly Attribute[] | undefined {
  if (!attributes || Object.keys(attributes).length <= 0) {
    return undefined;
  }

  return Object.entries(attributes).map(([key, value]) => {
    if (typeof value === 'boolean') {
      return { name: key, bool_value: value };
    }

    if (typeof value === 'number') {
      return Number.isInteger(value)
        ? { name: key, int_value: value }
        : { name: key, double_value: value };
    }

    return { name: key, string_value: value.toString() };
  });
}

export function createUserToken(token: string, tokenType: TokenType): Token {
  return {
    token,
    type: tokenType,
  };
}

export function getExclusionReason(status: Status): ExclusionReason {
  switch (status) {
    case Status.EXPERIMENT_NOT_FOUND: {
      return ExclusionReason.MISSING_EXPERIMENT;
    }
    case Status.USER_NOT_IN_EXPERIMENT: {
      return ExclusionReason.EXCLUDED_FROM_EXPERIMENT;
    }
    default: {
      return ExclusionReason.EXPERIMENT_MISCONFIGURED;
    }
  }
}
