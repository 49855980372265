import { observer } from 'mobx-react';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { MarketButton } from 'src/components/Market';
import MarketArrowDownIcon from 'src/svgs/MarketArrowDownIcon';
import './JumpToBottomButton.scss';

export type JumpToBottomButtonProps = {
  onClick: () => void;
  hasUnreadMessages?: boolean;
  show?: boolean;
};

/**
 * Button that allows user to jump to the bottom of a transcript.
 * This is used when rendering the <TranscriptViewItemsList />
 * and only appears when a user is not at the bottom of the transcript.
 * Text indicating that a new message has been received will be rendered
 * alongside the arrow in the button, if the user is not at the bottom
 * of the transcript.
 *
 * @example
 * Basic usage:
 * <JumpToBottomButton onClick={() => clickFunction()} />
 *
 * With optional:
 * <JumpToBottomButton
 *    onClick={() => clickFunction()}
 *    hasUnreadMessages={false}
 *    show
 * />
 * @param {Function} onClick
 * Function to call when the button is clicked.
 * @param {boolean} hasUnreadMessages
 * Whether or not to show additional text with the button indicating the
 * presence of at least 1 unread message.
 * @param {boolean} [show=true]
 * (Optional) Whether or not to show the button.
 * By default, the button is shown if this prop is not specified.
 * @author teresalin
 */
const JumpToBottomButton = observer(
  ({
    hasUnreadMessages,
    onClick,
    show = true,
  }: JumpToBottomButtonProps): ReactElement => {
    const { t } = useTranslation();
    return show ? (
      <div className="TranscriptViewItemsList__jump-to-bottom-container">
        <MarketButton
          className="TranscriptViewItemsList__jump-to-bottom-button"
          data-testid="JumpToBottomButton"
          onClick={onClick}
          size="small"
        >
          <MarketArrowDownIcon slot="icon" />
          {hasUnreadMessages && (
            <span>{t('TranscriptViewPage.new_message')}</span>
          )}
        </MarketButton>
      </div>
    ) : (
      <></>
    );
  },
);

export default JumpToBottomButton;
