/**
 * Exports `renderMessenger`, the function that Ember will use to render messenger
 * in Dashboard.
 */
import React from 'react';
import { render } from 'react-dom';
import SqOneTrust from '@square/one-trust';
import App from './App';
import setupI18n from './i18n/setupI18n';
import Logger from './Logger';
import { MessengerMode, SupportedLocale } from './MessengerTypes';
import 'url-search-params-polyfill'; // Edge doesn't have URLSearchParams
import { SdkMedium, MessagesConfig } from './SdkTypes';
import MessagesSDKController from './MessagesSDKController';
import MessengerController from './MessengerController';
import FullPageApp from './FullPageApp';
import {
  removePageLoadingSpinner,
  initMessagesShadowRoot,
  getEnvironment,
} from './utils/initUtils';
import { checkDevToolsInQueryString } from 'src/utils/url';
import { MESSAGES_MARKET_COMPONENT_PREFIX } from 'src/components/Market';
import '@market/web-components/dist/market/market.css';
import {
  applyPolyfills,
  defineCustomElements,
} from '@market/web-components/loader';
/**
 * Stencil supports multiple outputs of its functionality to support different use cases.
 * To isolate multiple versions of market on a single page we must use the `transformTagName`
 * option; unfortunately this option is only implemented for the `dist` output type which, confusingly,
 * means we must import from `/loader` rather than `/dist`.
 */
import { setTransformTagName } from '@market/web-components/dist';
import { when } from 'mobx';
import { sdkMediumToMedium } from './utils/transcriptUtils';

const transformTagName = (tagName: string): string =>
  `${MESSAGES_MARKET_COMPONENT_PREFIX}-${tagName}`;

const SQUARE_MESSAGES_READY_EVENT = 'SquareMessagesReady';

/**
 * Extends Window to have the functions we attach to the window
 */
declare global {
  interface Window {
    // Available on window object to provide messages-sdk a way to call the renderMessengerDrawer function
    renderMessengerDrawer: (div: Element) => void;
    // Available on window object to provide Full Page HTML a way to call renderMessagesFullPage function
    renderMessagesFullPage: () => void;
    renderMessengerDevTools: () => void;
    openMessenger: (
      mode: MessengerMode,
      token?: string,
      medium?: SdkMedium,
      contactId?: string,
      unitToken?: string,
      contactHandle?: string,
    ) => void;
    closeMessenger: () => void;
    messagesSdkController: MessagesSDKController;
    SqOneTrust?: SqOneTrust;
    applyFocusVisiblePolyfill: (shadow: ShadowRoot) => void;
    DD_RUM: {
      addError: (error: Error, data?: Record<string, unknown>) => void;
    };
  }
}

// Sets up the Market components
applyPolyfills()
  .then(() => {
    setTransformTagName(transformTagName);
    return defineCustomElements(window, {
      transformTagName,
    });
  })
  .catch((err: Error) => {
    // We will always get here when running under Jest. Workaround: To test the behavior of
    // Market components, manually fire the relevant custom events.
    Logger.log(
      `MARKET_ERROR: Failed to defineCustomElements with error ${err}`,
    );
  });

const messengerController = new MessengerController();

/**
 * A public function for opening Messenger to a particular page. This mirrors
 * {@link MessengerController.openMessenger}, with additional checks on the validity
 * of the arguments, since it'll be called from outside of a typesafe context.
 *
 * This is attached to window.openMessenger.
 *
 * @param {MessengerMode} mode
 * @param {string} token
 * @param {SdkMedium} initialMedium
 * @param {string} contactId
 * The contact ID to open a conversation with. Expects initial medium that the
 * contact ID corresponds with to also be provided.
 * @param {string} unitToken
 * (Optional) Unit token to open the conversation view to.
 * @param {string} [contactHandle]
 * (Optional) An opaque pointer to a contact id.
 */
export function openMessenger(
  mode: MessengerMode,
  token?: string,
  initialMedium?: SdkMedium,
  contactId?: string,
  unitToken?: string,
  contactHandle?: string,
): void {
  // Run all of the argument checks on the provided arguments. After this block, we should
  // again be in a typesafe world.
  if (mode === undefined) {
    throw new Error('Usage: window.openMessenger(mode, token, tokenType)');
  }
  if (typeof mode !== 'string') {
    throw new TypeError(
      `Messenger mode must be a string. Got ${mode} : ${typeof mode} instead.`,
    );
  }
  if (mode !== 'SINGLE_CONVERSATION' && mode !== 'FULL_MESSENGER') {
    throw new Error(`Unrecognized messenger mode: ${mode}`);
  }
  if (token !== undefined && typeof token !== 'string') {
    throw new Error(
      `Token must be a string. Got ${token} : ${typeof token} instead.`,
    );
  }
  if (contactId && !initialMedium) {
    throw new Error(
      `Initial medium must be provided if contactId is passed. Received a contactId of ${contactId} and initialMedium of ${initialMedium}`,
    );
  }

  messengerController.init();

  when(() => messengerController.featureFlag.isInitialized).then(() => {
    switch (mode) {
      case 'FULL_MESSENGER':
        messengerController.event.track('Click Messages Icon');
        messengerController.navigation.openMessenger();
        break;
      case 'SINGLE_CONVERSATION':
        if (!token && (contactId || contactHandle) && initialMedium) {
          messengerController.navigation.openMessengerByContactMethod({
            contactId,
            contactHandle,
            medium: sdkMediumToMedium[initialMedium],
            sellerKey: unitToken,
          });
          break;
        }
        messengerController.navigation.openMessengerByCustomerToken(token);
        break;
      default:
    }
  });
}

window.openMessenger = openMessenger;

/**
 * A public function for closing Messenger from outside of the Messenger codebase. This is attached
 * to window.closeMessenger.
 */
export function closeMessenger(): void {
  messengerController.navigation.primary.close();
}

window.closeMessenger = closeMessenger;

// Used to monitor whether renderMessengerDrawer is called multiple times.
// See this slack thread:
// https://square.slack.com/archives/CJFAGKPDJ/p1628790332060600
let hasRenderedDrawer = false;

/**
 * Render an instance of Messenger inside an element. This only includes the main
 * Messenger drawer. The host app should be responsible in creating the icon/button
 * that opens the drawer.
 *
 * This is attached to window.renderMessengerDrawer.
 *
 * @param {Element} div - The element to render messenger. #root in development
 * @param {string} [environmentOverride] - Set the environment
 * (staging or production) for API keys.
 * @param {SupportedLocale} [localeOverride] - An optional language param
 * for use with the Messages SDK
 */
export const renderMessengerDrawer = (
  div: Element,
  environmentOverride?: string,
  localeOverride?: SupportedLocale,
): void => {
  // Check if this function is incorrectly being called multiple times
  if (hasRenderedDrawer) {
    Logger.logWithSentry(
      "renderMessengerDrawer: renderMessengerDrawer() called multiple times. This shouldn't happen",
      'error',
      {
        hostname: window.location.hostname,
        containerId: div.getAttribute('id') ?? div.getAttribute('class'),
      },
    );
    return;
  }
  hasRenderedDrawer = true;
  // Check for errors
  if (div === undefined) {
    throw new Error(
      'Usage: window.renderMessengerDrawer(document.getElementById(...))',
    );
  }

  // Configure our environment
  const environment = getEnvironment(environmentOverride);

  // Set up app to listen to push updates
  messengerController.notifications.init(environment);

  // Set up multipass session monitoring
  messengerController.session.monitorMultipassStatus();

  // Set up i18n and feature flags.
  // Since setup is asynchronous, we have a race condition to set up
  // between this function and renderMessengerDrawer. However, setup must
  // be complete to render either the drawer or the icon. Hence, both
  // functions must call the setup function.
  setupI18n(localeOverride || messengerController.user.locale)
    .then(() => when(() => messengerController.featureFlag.isInitialized))
    .then(() => {
      // Init user event tracking
      messengerController.event.init(environment, 'BLADE');

      const reactRoot = initMessagesShadowRoot(div);

      render(<App messenger={messengerController} />, reactRoot);

      // If we have a deep-link, open Messenger to that conversation, or to
      // the list if linked to "all"
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString); // The polyfill import ensures this exists
      const messageLink: string | null = urlParams.get('message');
      const transcriptStr: string | null = urlParams.get('transcript_id');
      const transcriptId: number | null = transcriptStr
        ? Number.parseInt(transcriptStr, 10)
        : null;

      if (messageLink || transcriptId != null) {
        messengerController.event.track(
          'View Messages via URL Query String Parameter',
          {
            messageLink: messageLink || undefined,
            transcriptId: transcriptId || undefined,
          },
        );
        const messageLinkLowerCase = messageLink?.toLocaleLowerCase();
        if (messageLinkLowerCase === 'all') {
          // Case: This is the string `all`. The merchant probably got here
          // from support content or another "Try Messages" link.
          openMessenger('FULL_MESSENGER');
        } else if (messageLinkLowerCase === 'settings') {
          // Case: This is the string `settings`. The merchant probably got here
          // from an "Unsubscribe" link in a merchant-facing notification email.
          openMessenger('FULL_MESSENGER');
          messengerController.navigation.openSheet('SETTINGS');
        } else if (messageLink) {
          // Case: This is a customer token. The merchant probably got here
          // from an email about a customer or some other customer-specific,
          // dashboard-external avenue.
          // Open Messenger to our deep-linked customer
          openMessenger('SINGLE_CONVERSATION', messageLink);
        } else if (transcriptId != null) {
          // Case: Transcript ID provided. Do our best to pick
          // a conversation to open. May be non-deterministic,
          // since there's no 1:1 relationship between Rolodex customer
          // and transcript
          messengerController.init();
          messengerController.navigation.openMessengerByTranscript(
            transcriptId,
          );
        } else {
          Logger.warn(
            `Malformed query params, ignoring. Customer: ${messageLink}, Transcript: ${transcriptId}`,
          );
        }
        // Clear the query param so that a page refresh doesn't re-open this conversation
        urlParams.delete('message');
        urlParams.delete('transcript_id');
        const newParams = urlParams.toString();
        window.history.replaceState(
          null,
          document.title,
          newParams === ''
            ? window.location.pathname
            : `${window.location.pathname}?${newParams}`,
        );
      }

      checkDevToolsInQueryString();
    });
};

window.renderMessengerDrawer = renderMessengerDrawer;

/**
 * Renders an instance of the Messages Full Page React Application.
 */
const renderMessagesFullPage = (): void => {
  const container = document.getElementById('messenger') as HTMLDivElement;
  const reactRoot = initMessagesShadowRoot(container);
  reactRoot.setAttribute('class', 'SquareMessagesFullPage');

  removePageLoadingSpinner();

  render(<FullPageApp messenger={messengerController} />, reactRoot);

  checkDevToolsInQueryString();
};

window.renderMessagesFullPage = renderMessagesFullPage;

/**
 * A wrapper function to open the dev tools, used for debugging purposes only.
 */
function renderMessengerDevTools(): void {
  Logger.showDevTools = true;
}

window.renderMessengerDevTools = renderMessengerDevTools;

window.messagesSdkController = new MessagesSDKController(
  messengerController,
  (config: MessagesConfig) => {
    renderMessengerDrawer(
      config.container,
      config.environment,
      // TODO(eblaine): Debug why languageCode is incorrect when coming from Dashboard Header
      // config.languageCode as SupportedLocale,
    );
  },
);

// Notifies that the Messages React bundle has been loaded and parsed
window.dispatchEvent(new Event(SQUARE_MESSAGES_READY_EVENT));
