/**
 * This icon is used in a button and should be used in the
 * header to denote settings.
 */

import React, { ReactElement } from 'react';
import BaseSvgProps from 'src/types/BaseSvgProps';

const SettingsIcon = (props: BaseSvgProps): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    fill="none"
    viewBox="0 0 22 22"
    {...props}
  >
    <title>Settings</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.879 1.808a3 3 0 0 1 4.242 0l.9.9a1 1 0 0 0 .707.292H16a3 3 0 0 1 3 3v1.272a1 1 0 0 0 .293.707l.9.9a3 3 0 0 1 0 4.242l-.9.9a1 1 0 0 0-.293.707V16a3 3 0 0 1-3 3h-1.272a1 1 0 0 0-.707.293l-.9.9a3 3 0 0 1-4.242 0l-.9-.9A1 1 0 0 0 7.272 19H6a3 3 0 0 1-3-3v-1.272a1 1 0 0 0-.293-.707l-.9-.9a3 3 0 0 1 0-4.242l.9-.9A1 1 0 0 0 3 7.272V6a3 3 0 0 1 3-3h1.272a1 1 0 0 0 .707-.293l.9-.9Zm2.828 1.414a1 1 0 0 0-1.414 0l-.9.9A3 3 0 0 1 7.273 5H6a1 1 0 0 0-1 1v1.272a3 3 0 0 1-.879 2.121l-.9.9a1 1 0 0 0 0 1.414l.9.9A3 3 0 0 1 5 14.728V16a1 1 0 0 0 1 1h1.272a3 3 0 0 1 2.121.879l.9.9a1 1 0 0 0 1.414 0l.9-.9A3 3 0 0 1 14.728 17H16a1 1 0 0 0 1-1v-1.272a3 3 0 0 1 .879-2.121l.9-.9a1 1 0 0 0 0-1.414l-.9-.9A3 3 0 0 1 17 7.273V6a1 1 0 0 0-1-1h-1.272a3 3 0 0 1-2.121-.879l-.9-.9Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 9a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm-4 2a4 4 0 1 1 8 0 4 4 0 0 1-8 0Z"
    />
  </svg>
);

export default SettingsIcon;
