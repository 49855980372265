/**
 * An icon representing an image file.
 */
import React, { ReactElement } from 'react';

function PdfFileIcon(): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <title>PDF</title>
      <path
        fill="#000"
        fillOpacity=".9"
        fillRule="evenodd"
        d="M8.25 9H5v6h1.5v-2h1.75a.75.75 0 0 0 .75-.75v-2.5A.75.75 0 0 0 8.25 9Zm-.75 2.5h-1v-1h1v1Z"
        clipRule="evenodd"
      />
      <path
        fill="#000"
        fillOpacity=".9"
        d="M15 9h4v1.5h-2.5v1h2V13h-2v2H15V9Z"
      />
      <path
        fill="#000"
        fillOpacity=".9"
        fillRule="evenodd"
        d="M12.25 9H10v6h2.25A1.75 1.75 0 0 0 14 13.25v-2.5A1.75 1.75 0 0 0 12.25 9Zm-.75 4.5v-3h.75a.25.25 0 0 1 .25.25v2.5a.25.25 0 0 1-.25.25h-.75Z"
        clipRule="evenodd"
      />
      <path
        fill="#000"
        fillOpacity=".9"
        fillRule="evenodd"
        d="M5 2a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V5a3 3 0 0 0-3-3H5ZM4 5a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V5Z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default PdfFileIcon;
