/**
 * A small x button to remove photo from input.
 */

import React, { ReactElement } from 'react';

function RemovePhotoIcon(): ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none">
      <title>Remove</title>
      <circle cx="16" cy="16" r="8" fill="#000" fillOpacity=".9" />
      <g fillRule="evenodd" clipRule="evenodd" filter="url(#RemovePhotoIcon)">
        <path
          fill="#fff"
          d="M7 16a9 9 0 1 1 18.001.001A9 9 0 0 1 7 16Zm2 0c0 3.86 3.14 7 7 7s7-3.14 7-7-3.14-7-7-7-7 3.14-7 7Zm7-1.41 2.29-2.3 1.42 1.42-2.3 2.29 2.3 2.29-1.42 1.42L16 17.42l-2.29 2.29-1.42-1.42 2.3-2.29-2.3-2.29 1.42-1.42 2.29 2.3Z"
        />
        <path
          fill="#D9D9D9"
          d="M7 16a9 9 0 1 1 18.001.001A9 9 0 0 1 7 16Zm2 0c0 3.86 3.14 7 7 7s7-3.14 7-7-3.14-7-7-7-7 3.14-7 7Zm7-1.41 2.29-2.3 1.42 1.42-2.3 2.29 2.3 2.29-1.42 1.42L16 17.42l-2.29 2.29-1.42-1.42 2.3-2.29-2.3-2.29 1.42-1.42 2.29 2.3Z"
        />
      </g>
      <defs>
        <filter
          id="RemovePhotoIcon"
          width="32"
          height="32"
          x="0"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2189_1568"
          />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
          <feBlend
            in2="effect1_dropShadow_2189_1568"
            result="effect2_dropShadow_2189_1568"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_2189_1568"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

export default RemovePhotoIcon;
