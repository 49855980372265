import { makeAutoObservable, runInAction } from 'mobx';
import Logger from 'src/Logger';
import type MessengerController from 'src/MessengerController';
import { MultipassStatusResponse } from 'src/MessengerTypes';
import { MULTIPASS_STATUS_URL } from 'src/utils/url';

/**
 * The amount of idle time allowed before a user's Multipass session ends.
 * This is 75 minutes, minus a few seconds for clock skew. Unit: milliseconds.
 */
export const MULTIPASS_ALLOWED_IDLE_MINUTES = 75;
export const MULTIPASS_ALLOWED_IDLE_TIME =
  MULTIPASS_ALLOWED_IDLE_MINUTES * 60000 - 3000;

/**
 * Store that manages the user session, mainly the multipass session to ensure
 * that the user is still logged in.
 */
export class SessionStore {
  private _stores: MessengerController;

  /**
   * Whether the current session is expired.
   */
  isExpired = false;

  /**
   * Whether the user has interacted with Messages, either indirectly through the SDK
   * or by opening Messages directly. Mainly used to skip pusher updates.
   */
  isUsingMessages = false;

  constructor(stores: MessengerController) {
    makeAutoObservable(this, {
      isUsingMessages: false,
    });

    this._stores = stores;
  }

  /**
   * Check on the multipass session. If the session is active,
   * this will return the full session object. If the session
   * has expired (if /mp/status 401's), then it will return
   * { expired: true }. If the request failed for some other reason,
   * this function will return null.
   * Otherwise, it will return the rest of the multipass status.
   *
   * @returns {Promise<MultipassStatusResponse | null>}
   */
  private _checkMultipassStatus =
    async (): Promise<MultipassStatusResponse | null> => {
      try {
        const response: Response = await this._stores.services.fetchJson(
          MULTIPASS_STATUS_URL,
        );
        if (response.status === 401) {
          // The multipass session has expired
          return { expired: true };
        }
        // You could update the CSRF token in HTML from the _js_csrf cookie in the response
        return response.json() as MultipassStatusResponse;
      } catch (e) {
        Logger.error(`[[Multipass failure]] ${e}`);
        // Some error occurred other than a 4xx or 5xx
        return null;
      }
    };

  /**
   * Monitor the multipass session to make sure the user gets
   * logged out when their session ends. We use this strategy:
   * - Schedule the initial status check for MULTIPASS_ALLOWED_IDLE_MINUTES from
   *   page load.
   * - For each check after:
   *   - Schedule the next check for MULTIPASS_ALLOWED_IDLE_MINUTES - current_idle_minutes,
   *     if that variable is available and the session is active
   *   - If the session is expired, stop monitoring and stop
   *     responding to Pusher
   *   - If an error occurs, schedule a check for MULTIPASS_ALLOWED_IDLE_MINUTES in the
   *     future. (We'd rather have spurious UNAUTHENTICATED errors in
   *     our logs than stop getting Pusher updates due to transient issues.)
   */
  monitorMultipassStatus = (): void => {
    let timeout: NodeJS.Timeout;
    const timeoutCallback = (): void => {
      this._checkMultipassStatus().then(
        (response: MultipassStatusResponse | null) => {
          clearTimeout(timeout);
          if (response == null) {
            // An unknown error occurred. It could be a transient error.
            // Schedule another check for MULTIPASS_ALLOWED_IDLE_MINUTES in the future.
            timeout = setTimeout(timeoutCallback, MULTIPASS_ALLOWED_IDLE_TIME);
            return;
          }
          if (response && response.current_idle_minutes != null) {
            // Our session is still valid. Until current_idle_minutes to
            // schedule the next session check
            const currentIdleMillis = response.current_idle_minutes * 60000;
            timeout = setTimeout(
              timeoutCallback,
              // Enforce a minimum value to prevent calling Multipass too quickly
              Math.max(MULTIPASS_ALLOWED_IDLE_TIME - currentIdleMillis, 60000),
            );
            return;
          }
          if (response.expired) {
            Logger.warn('Session expired. Unsubscribing to pusher');
            // NOTE: This treats ignores unknown errors and only stops Pusher
            // if we know the session is expired.
            runInAction(() => {
              this.isExpired = true;
            });
            this._stores.notifications.stop();
          }
        },
      );
    };
    timeout = setTimeout(timeoutCallback, MULTIPASS_ALLOWED_IDLE_TIME);
  };
}
