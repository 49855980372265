import React, { ReactElement, useEffect } from 'react';
import { observer } from 'mobx-react';
import './VerificationSuccessDialog.scss';
import { MarketButton } from 'src/components/Market';
import MessengerModalDialog from 'src/components/MessengerModalDialog/MessengerModalDialog';
import { Trans, useTranslation } from 'react-i18next';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import useIsMobile from 'src/hooks/useIsMobile';

/**
 * Dialog to inform the user that an M+ subscription and dedicated number have
 * been successfully activated and assigned.
 */
const VerificationSuccessDialog = observer((): ReactElement => {
  const { t } = useTranslation();
  const { tooltip, navigation, subscription, event } =
    useMessengerControllerContext();
  const isMobile = useIsMobile();

  useEffect(() => {
    event.track('View Verification Result');
  }, [event]);

  const dedicatedNumber =
    subscription.recentlyVerifiedAndSubscribedNumbers[0].displayDedicatedNumber;

  return (
    <MessengerModalDialog
      title={t('VerificationSuccessDialog.title')}
      primaryButton={
        <MarketButton
          rank="primary"
          onClick={() => {
            event.track('Click Verification Result', {
              action_type_name: 'send_a_message',
            });
            navigation.navStoreForUrl.navigateTo('NEW_MESSAGE');
            tooltip.dismiss('VERIFICATION_SUCCESS_DIALOG');
          }}
        >
          {t('VerificationSuccessDialog.send_message_button_text')}
        </MarketButton>
      }
      secondaryButton={
        <MarketButton
          rank="secondary"
          onClick={() => {
            event.track('Click Verification Result', {
              action_type_name: 'not_now',
            });
            tooltip.dismiss('VERIFICATION_SUCCESS_DIALOG');
          }}
        >
          {t('VerificationSuccessDialog.dismiss_button_text')}
        </MarketButton>
      }
      buttonGroupAlign={isMobile ? 'stack' : 'fill'}
    >
      <p className="VerificationSuccessDialog__description paragraph-30">
        <Trans
          i18nKey="VerificationSuccessDialog.description"
          components={{
            1: <strong />,
          }}
          values={{ number: dedicatedNumber }}
        />
      </p>
    </MessengerModalDialog>
  );
});

export default VerificationSuccessDialog;
