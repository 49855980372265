import React, { ReactElement, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { i18nPhoneNumberOrRedacted } from 'src/i18n/formatValues';
import { MarketButton } from 'src/components/Market';
import MessengerModalDialog from 'src/components/MessengerModalDialog/MessengerModalDialog';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import { Medium } from 'src/gen/squareup/messenger/v3/messenger_service';

/**
 * A modal for the merchant to confirm that they have consent to send
 * text messages to the customer. Clicking confirm in this modal will
 * send a message with confirmedConsent in the request.
 *
 * @example
 * Basic usage:
 * <ConfirmConsentModal />
 * @author klim
 */
const ConfirmConsentModal = observer((): ReactElement => {
  const { modal, event, transcriptView } = useMessengerControllerContext();
  const { closeModal, onConfirmConsent, onRejectConsent } = modal;
  const { transcript } = transcriptView;
  const { t } = useTranslation();

  useEffect(() => {
    event.track('View Confirm Consent Modal', { transcript_id: transcript.id });
  }, [event, transcript.id]);

  let phoneNumber: string | undefined;
  if (transcript.medium === Medium.SMS) {
    phoneNumber = transcript.contactId;
  }

  // TODO(klim): support other countries apart from US
  const contact = i18nPhoneNumberOrRedacted(phoneNumber ?? '', 'US');

  const onConfirm = (): void => {
    event.track('Click Confirm Consent Modal Confirm', {
      transcript_id: transcript.id,
    });
    if (onConfirmConsent) {
      onConfirmConsent();
    }
    closeModal();
  };
  const onClose = (): void => {
    if (onRejectConsent) {
      onRejectConsent();
    }
    event.track('Click Confirm Consent Modal Dismiss', {
      transcript_id: transcript.id,
    });
    closeModal();
  };

  const primaryButton = (
    <MarketButton rank="primary" onClick={onConfirm}>
      {t('ConfirmConsentModal.confirm')}
    </MarketButton>
  );

  const secondaryButton = (
    <MarketButton rank="secondary" onClick={onClose}>
      {t('common.dismiss')}
    </MarketButton>
  );

  return (
    <MessengerModalDialog
      title={t('ConfirmConsentModal.title')}
      primaryButton={primaryButton}
      secondaryButton={secondaryButton}
      close={onClose}
    >
      <p>
        {t('ConfirmConsentModal.description', {
          contact,
        })}
      </p>
    </MessengerModalDialog>
  );
});

export default ConfirmConsentModal;
