import React, { HTMLAttributes, ReactElement, ReactNode } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { MarketButton, MarketHeader } from 'src/components/Market';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import MarketCloseIcon from 'src/svgs/MarketCloseIcon';
import MarketBackIcon from 'src/svgs/MarketBackIcon';
import './MessengerModalFullHeader.scss';

// HTML attributes that can be assigned to the header component.
// This is limited to `className` for now because we don't anticipate much of a use case
// for many of the other attributes (e.g. event listeners)
type LimitedHTMLAttributes = Pick<
  Partial<HTMLAttributes<HTMLMarketHeaderElement>>,
  'className'
>;

export type MessengerModalFullHeaderProps = {
  title?: ReactNode;
  navigationVariant?: 'CLOSE' | 'BACK';
  onNavigateClick?: () => void;
  primaryButton?: ReactNode;
  secondaryButton?: ReactNode;
  compactTitle?: boolean;
  headerAttributes?: LimitedHTMLAttributes;
};

/**
 * Component representing the header portion of a MessengerModalFull.
 * This component cannot be wrapped in a container, as it must be a direct child
 * of <MarketModalFull /> in order to receive host styling.
 *
 * @param {ReactNode} [title]
 * (Optional) Title of the modal.
 * @param {'CLOSE' | 'BACK'} [navigationVariant='CLOSE']
 * (Optional) Variant of the navigation button. The only two options are:
 *   - 'BACK' which will show an arrow back icon
 *   - 'CLOSE' which will show an X icon
 * @param {() => void} onNavigateClick
 * (Optional) Function to call when navigation icon (back or close) is clicked.
 * If no function is provided, it will check the navigationVariant (which if not provided,
 * defaults to 'CLOSE') and call NavigationStore to:
 *   - Navigate to the previous sheet (or close if no previous) if navigationVariant='BACK'
 *   - Close the sheet if navigationVariant='CLOSE'
 * @param {ReactNode} [primaryButton]
 * (Optional) A button that appears on the right of the footer.
 * @param {ReactNode} [secondaryButton]
 * (Optional) A button that appears on the left of the footer.
 * @param {boolean} [compactTitle]
 * (Optional) If true, the title will be put into compact slot.
 * @param {LimitedHTMLAttributes} [headerAttributes]
 * (Optional) HTML attributes to set on the underlying <MarketHeader/> component
 */
const MessengerModalFullHeader = observer(
  ({
    title,
    navigationVariant = 'CLOSE',
    onNavigateClick,
    primaryButton,
    secondaryButton,
    compactTitle,
    headerAttributes,
  }: MessengerModalFullHeaderProps): ReactElement => {
    const { navigation } = useMessengerControllerContext();

    const onClick =
      onNavigateClick ??
      (navigationVariant === 'BACK'
        ? () => {
            navigation.sheet.navigateBack();
          }
        : () => {
            navigation.closeSheet();
          });

    return (
      <MarketHeader
        {...headerAttributes}
        showNavigation
        compact={compactTitle || undefined}
        className={classNames(headerAttributes?.className, {
          'MessengerModalFullHeader__full-height':
            !title && !primaryButton && !secondaryButton,
        })}
      >
        <MarketButton
          rank="secondary"
          slot="navigation"
          onClick={onClick}
          data-testid="MessengerModalFullHeader__navigate"
        >
          {navigationVariant === 'BACK' ? (
            <MarketBackIcon
              slot="icon"
              data-testid="MessengerModalFullHeader__back-icon"
            />
          ) : (
            <MarketCloseIcon
              slot="icon"
              data-testid="MessengerModalFullHeader__close-icon"
            />
          )}
        </MarketButton>
        {title && <h2>{title}</h2>}
        {secondaryButton && <div slot="actions">{secondaryButton}</div>}
        {primaryButton && <div slot="actions">{primaryButton}</div>}
      </MarketHeader>
    );
  },
);

export default MessengerModalFullHeader;
