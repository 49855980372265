import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import {
  amountToCurrencyString,
  currencyCodeToKey,
} from 'src/utils/moneyUtils';
import DefaultCustomerIcon from 'src/svgs/DefaultCustomerIcon';
import GeneralEventBannerRow from 'src/pages/TranscriptViewPage/components/GeneralEventBanner/GeneralEventBannerRow';
import { Contact } from 'src/gen/squareup/messenger/v3/messenger_auxiliary_service';

/**
 * The UI for the customer details banner, which is displayed above the text of a conversation.
 */
const CustomerDetailEventBanner = observer((): ReactElement | null => {
  const { navigation, user, transcriptView, customers, event } =
    useMessengerControllerContext();
  const { t } = useTranslation();
  const { transcript } = transcriptView;

  const customersData: Contact[] = transcript.customerTokens
    .map((token) => customers.get(token).contact)
    .filter((contact) => contact !== undefined) as Contact[];

  if (customersData.length === 0) {
    return null;
  }

  let amount = 0;
  let customerCount = 0;
  let transactionCount = 0;
  customersData.forEach((customer) => {
    amount += customer.totalGpv?.amount || 0;
    transactionCount += customer.transactionCount;
    customerCount++;
  });

  return (
    <GeneralEventBannerRow
      icon={<DefaultCustomerIcon />}
      title={t('CustomerDetailEventBanner.title', { customerCount })}
      subtext={t('CustomerDetailPage.transaction_count_label', {
        transactionCount,
      })}
      subtitle={t('CustomerDetailPage.total_spend_label', {
        amount: amountToCurrencyString(
          amount,
          (customersData[0].totalGpv?.currency &&
            currencyCodeToKey(customersData[0].totalGpv.currency)) ||
            user.currencyCode,
          user.locale,
        ),
      })}
      onClick={() => {
        event.track('Click Customer Detail Banner');
        if (transcript.customerTokens.length === 1) {
          navigation.openCustomerDetailView(
            transcript.id,
            customersData[0].token,
          );
          return;
        }
        navigation.primary.navigateTo({
          name: 'CUSTOMER_MERGE_VIEW',
          transcriptId: transcript.id,
        });
      }}
      variant="CUSTOMER_DETAIL_EVENT"
    />
  );
});

export default CustomerDetailEventBanner;
