import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import NewCustomerModal, {
  NewCustomerModalOnSaveArgs,
} from 'src/components/NewCustomerModal/NewCustomerModal';

/**
 * Component that wraps the NewCustomer modal for the purposes of creating a new customer given
 * a "Create Customer" suggested action.
 */
const CreateCustomerSuggestionModal = observer((): ReactElement => {
  const { modal, api, status, transcriptView, event } =
    useMessengerControllerContext();
  const { t } = useTranslation();

  const onSave = async ({
    firstName,
    lastName,
    email,
    phoneNumber,
  }: NewCustomerModalOnSaveArgs): Promise<void> => {
    event.track('Click New Customer Modal Save', {
      first_name: firstName,
      last_name: lastName,
      maybe_first_name: modal.createCustomerSuggestionMetadata?.firstName,
      maybe_last_name: modal.createCustomerSuggestionMetadata?.lastName,
    });
    try {
      const contact = await api.contacts.createContact({
        givenName: firstName || '',
        surname: lastName || '',
        emailAddress: email || '',
        phoneNumber: phoneNumber || '',
      });
      if (contact === null) {
        throw new Error('Contact returned is null.');
      }
      transcriptView.transcript.set({
        customerTokens: [
          ...transcriptView.transcript.customerTokens,
          contact.token,
        ],
        displayName: {
          name: contact.name,
        },
      });
    } catch {
      status.setModalError({
        label: t('NewCustomer.modal.failed_to_create'),
      });
      throw new Error('Failed to create contact.');
    }
  };

  return (
    <NewCustomerModal
      initialFirstName={modal.createCustomerSuggestionMetadata?.firstName}
      initialLastName={modal.createCustomerSuggestionMetadata?.lastName}
      initialPhoneNumber={modal.createCustomerSuggestionMetadata?.phoneNumber}
      initialEmail={modal.createCustomerSuggestionMetadata?.email}
      onSave={onSave}
    />
  );
});

export default CreateCustomerSuggestionModal;
