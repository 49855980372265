/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "common": {
        "nested": {
          "payment": {
            "nested": {
              "EntryMethod": {
                "values": {
                  "UNKNOWN_ENTRY_METHOD": 0,
                  "SWIPED": 1,
                  "KEYED": 2,
                  "EMV": 3,
                  "ON_FILE": 4,
                  "CONTACTLESS": 5,
                  "CRYPTO": 6,
                  "QR_CODE": 8,
                  "REDIRECT": 9,
                  "SMS": 10
                }
              },
              "SquareProduct": {
                "values": {
                  "UNKNOWN_DO_NOT_SET": 0,
                  "REGISTER": 1,
                  "CASH": 2,
                  "MARKET": 3,
                  "ORDER": 4,
                  "EXTERNAL_API": 5,
                  "BILLING": 6,
                  "APPOINTMENTS": 7,
                  "STORE": 8,
                  "INVOICES": 9,
                  "ONLINE_STORE": 10,
                  "STARBUCKS": 11,
                  "WHITE_LABEL": 12,
                  "PAYROLL": 13,
                  "CAPITAL": 14,
                  "TERMINAL": 15,
                  "EGIFTING": 16,
                  "REGISTER_HARDWARE": 17,
                  "BUYER_SUBSCRIPTIONS": 18,
                  "FNB_KIOSK": 19,
                  "BILLPAY": 20
                }
              },
              "SignatureType": {
                "values": {
                  "UNKNOWN": 0,
                  "NO_SIGNATURE": 1,
                  "DIGITAL_SIGNATURE": 2,
                  "PAPER_SIGNATURE": 3
                }
              },
              "PeripheralMetadata": {
                "fields": {
                  "appRelease": {
                    "type": "string",
                    "id": 1,
                    "rule": "optional"
                  },
                  "appVersion": {
                    "type": "string",
                    "id": 2,
                    "rule": "optional"
                  },
                  "transactionRemoteId": {
                    "type": "string",
                    "id": 3,
                    "rule": "optional"
                  },
                  "transactionLocalId": {
                    "type": "string",
                    "id": 7,
                    "rule": "optional"
                  },
                  "sourceDeviceInstallationId": {
                    "type": "string",
                    "id": 4,
                    "rule": "optional"
                  },
                  "connectedPeripherals": {
                    "type": "squareup.common.payment.ConnectedPeripheral",
                    "id": 5,
                    "rule": "repeated"
                  },
                  "unknownPeripherals": {
                    "type": "squareup.common.payment.ConnectedPeripheral",
                    "id": 6,
                    "rule": "repeated"
                  }
                }
              },
              "ConnectedPeripheral": {
                "fields": {
                  "uniqueIdentifier": {
                    "type": "string",
                    "id": 1,
                    "rule": "optional"
                  },
                  "deviceType": {
                    "type": "string",
                    "id": 2,
                    "rule": "optional"
                  },
                  "connectionType": {
                    "type": "squareup.common.payment.ConnectedPeripheral.ConnectionType",
                    "id": 3,
                    "rule": "optional"
                  },
                  "manufacturerName": {
                    "type": "string",
                    "id": 4,
                    "rule": "optional"
                  },
                  "modelName": {
                    "type": "string",
                    "id": 5,
                    "rule": "optional"
                  }
                },
                "nested": {
                  "ConnectionType": {
                    "values": {
                      "NETWORK": 0,
                      "USB": 1,
                      "BLUETOOTH": 2,
                      "BLE": 3,
                      "AUDIO": 4,
                      "EMBEDDED": 5,
                      "UNKNOWN": 100
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.common?.nested?.payment?.nested?.EntryMethod && !$root.nested?.squareup?.nested?.common?.nested?.payment?.nested?.SquareProduct && !$root.nested?.squareup?.nested?.common?.nested?.payment?.nested?.SignatureType && !$root.nested?.squareup?.nested?.common?.nested?.payment?.nested?.PeripheralMetadata && !$root.nested?.squareup?.nested?.common?.nested?.payment?.nested?.ConnectedPeripheral && !$root.nested?.squareup?.nested?.common?.nested?.payment?.nested?.ConnectedPeripheral?.nested?.ConnectionType) {
	$root.addJSON(jsonData);
}

/**
 * An enumeration of all the different entry methods that may pertain to a
 * payment, including shorthands for CP (card present) and CNP (card not present) payment types.
 *
 * SOURCE EntryMethod @ squareup/common/payment.proto at 10:1
 */
export const EntryMethod = $root.lookupEnum('squareup.common.payment.EntryMethod').values;
/**
 * An enumeration of Square products that create payments.
 *
 * SOURCE SquareProduct @ squareup/common/payment.proto at 57:1
 */
export const SquareProduct = $root.lookupEnum('squareup.common.payment.SquareProduct').values;
/**
 * Whether an authorizing signature was captured during a transaction.
 *
 * SOURCE SignatureType @ squareup/common/payment.proto at 139:1
 */
export const SignatureType = $root.lookupEnum('squareup.common.payment.SignatureType').values;
/**
 * Information about system state and connected peripherals when taking a payment.
 *
 * SOURCE PeripheralMetadata @ squareup/common/payment.proto at 164:1
 */
export const PeripheralMetadata = $root.lookupType('squareup.common.payment.PeripheralMetadata');
fixType(PeripheralMetadata);
Builder.createAndAttachToType(PeripheralMetadata);
/**
 * Contains identifying information about a peripheral present when taking a payment.
 *
 * SOURCE ConnectedPeripheral @ squareup/common/payment.proto at 211:1
 */
export const ConnectedPeripheral = $root.lookupType('squareup.common.payment.ConnectedPeripheral');
fixType(ConnectedPeripheral);
Builder.createAndAttachToType(ConnectedPeripheral);
/**
 * Enumeration of different ways a peripheral could be connected.
 *
 * SOURCE ConnectionType @ squareup/common/payment.proto at 215:3
 */
ConnectedPeripheral.ConnectionType = $root.lookupEnum('squareup.common.payment.ConnectedPeripheral.ConnectionType').values;
