import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { Transition } from 'src/MessengerTypes';
import MessengerContent from 'src/components/MessengerContent/MessengerContent';
import { MarketButton, MarketHeader } from 'src/components/Market';
import MarketBackIcon from 'src/svgs/MarketBackIcon';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import { useTranslation } from 'react-i18next';
import TranscriptViewSkeletonState from 'src/pages/TranscriptViewPage/components/TranscriptViewSkeletonState/TranscriptViewSkeletonState';

export type StatusPageProps = {
  transitionDirection: Transition;
};

/**
 * Component that displays either a loading state or status screen
 * based on the global application status state. Used as the default
 * page when Messages is opened but has not yet navigated to a page.
 *
 * @example <StatusPage transitionDirection="NONE" />
 * @param {Transition} transitionDirection
 * Determines which direction the content slides in from.
 * @returns {ReactElement}
 * StatusPage component
 * @author wdetlor
 */
const StatusPage = observer(
  ({ transitionDirection }: StatusPageProps): ReactElement => {
    const { t } = useTranslation();
    const { navigation, status } = useMessengerControllerContext();

    const showBackButton =
      !navigation.secondary.isOpen && navigation.navStoreForUrl.canNavigateBack;

    return (
      <>
        {showBackButton && (
          <MarketHeader className="MessagesPageHeader" compact>
            <MarketButton
              slot="navigation"
              aria-label={t('common.back')}
              onClick={() => {
                navigation.navStoreForUrl.navigateBack();
              }}
            >
              <MarketBackIcon slot="icon" />
            </MarketButton>
          </MarketHeader>
        )}
        <MessengerContent
          transitionDirection={transitionDirection}
          isLoading={
            !(
              status?.value?.scope === 'BLADE' &&
              status?.value?.display === 'SCREEN'
            )
          }
          loadingIndicator={
            <TranscriptViewSkeletonState showHeader={!showBackButton} />
          }
          status={status?.value}
        >
          {/** Intentionally empty content. This page is only intended to be shown for either a loading or error state */}
          <></>
        </MessengerContent>
      </>
    );
  },
);

export default StatusPage;
