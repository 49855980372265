import React, { ReactElement, RefObject, useRef } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import {
  MarketAccessory,
  MarketButton,
  MarketButtonDropdown,
  MarketList,
  MarketRow,
} from 'src/components/Market';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import PlusIcon from 'src/svgs/PlusIcon';
import {
  KEY_EMAIL_FILES_ENABLED,
  KEY_MESSAGES_PLUS,
  KEY_REQUEST_GOOGLE_REVIEW,
} from 'src/stores/FeatureFlagStore';
import { Medium } from 'src/gen/squareup/messenger/v3/messenger_service';
import {
  getCreateAppointmentUrl,
  getCreateEstimateUrl,
  getCreateInvoiceUrl,
} from 'src/utils/url';
import AppointmentIcon from 'src/svgs/AppointmentIcon';
import EstimateIcon from 'src/svgs/EstimateIcon';
import InvoiceIcon from 'src/svgs/InvoiceIcon';
import PhotoIcon from 'src/svgs/PhotoIcon';
import RequestReviewIcon from 'src/svgs/RequestReviewIcon';
import AnnouncementTooltip from 'src/components/AnnouncementTooltip/AnnouncementTooltip';
import { onKeyDownEnter } from 'src/utils/keyboardUtils';
import UpgradePill from 'src/components/UpgradePill/UpgradePill';
import './InputMenu.scss';
import AvailableForMessagesPlusTooltip from 'src/pages/TranscriptViewPage/components/PendingVerificationTooltipPill/PendingVerificationTooltipPill';
import AttachmentIcon from 'src/svgs/AttachmentIcon';
import CouponPercentageIcon from 'src/svgs/CouponPercentageIcon';
import FileEmailOnlyDialog from 'src/pages/TranscriptViewPage/components/MessageInput/components/FileEmailOnlyDialog/FileEmailOnlyDialog';
import FileFirstTimeModal from 'src/pages/TranscriptViewPage/components/MessageInput/components/FileFirstTimeModal/FileFirstTimeModal';
import FileAndPhotoMutuallyExclusiveDialog from 'src/pages/TranscriptViewPage/components/MessageInput/components/FileAndPhotoMutuallyExclusiveDialog/FileAndPhotoMutuallyExclusiveDialog';

export type InputMenuProps = {
  photoInputRef: RefObject<HTMLInputElement>;
  fileInputRef: RefObject<HTMLInputElement>;
  inputHasPhotos: boolean;
  inputHasFiles: boolean;
};

/**
 * The '+' menu in the input bar that allows merchant to create from other
 * Square product integrations like appointments and invoices.
 *
 * @param {RefObject} photoInputRef
 * The reference to the input for photos.
 * @param {RefObject} fileInputRef
 * The reference to the input for files.
 * @param {boolean} inputHasPhotos
 * True if the input has photos selected.
 * @param {boolean} inputHasFiles
 * True if the input has files selected.
 */
const InputMenu = observer((props: InputMenuProps): ReactElement => {
  const { photoInputRef, fileInputRef, inputHasPhotos, inputHasFiles } = props;
  const {
    featureFlag,
    event,
    modal,
    tooltip,
    transcriptView,
    subscription,
    navigation,
  } = useMessengerControllerContext();
  const {
    transcript,
    requestReview,
    isInputDisabled,
    isLoading,
    isNotSubscribedToMPlus,
    isPendingOrFailedRetryable,
  } = transcriptView;
  const { t } = useTranslation();
  const buttonDropdownRef = useRef<HTMLMarketButtonDropdownElement>(null);
  const isProhibited =
    featureFlag.get(KEY_MESSAGES_PLUS) && subscription.isProhibited;
  const content = [];

  let trailingAccessory;
  let disabled = false;
  if (isPendingOrFailedRetryable) {
    trailingAccessory = <AvailableForMessagesPlusTooltip />;
    disabled = true;
  } else if (isNotSubscribedToMPlus) {
    trailingAccessory = <UpgradePill />;
  }

  const closeDropdown = (): void => {
    const dropdownRef = buttonDropdownRef.current?.shadowRoot?.querySelector(
      '.market-dropdown',
    ) as HTMLMarketDropdownElement;
    dropdownRef?.closeDropdown?.();
  };

  // appointments
  const appointmentOnClick = (): void => {
    closeDropdown();
    event.track('Click Create Action', {
      action_type_name: 'create_appointment',
      needs_upgrade: false,
      transcript_id: transcript.id,
    });
    window.open(
      getCreateAppointmentUrl(
        transcript.customerToken,
        transcript.sellerKey,
        transcript.id,
      ),
      '_blank',
    );
  };
  content.push(
    <MarketRow
      key="appointment"
      data-testid="InputMenu__appointment"
      onClick={appointmentOnClick}
      onKeyDown={(e) => onKeyDownEnter(e, appointmentOnClick)}
    >
      <MarketAccessory slot="leading-accessory" size="icon">
        <AppointmentIcon />
      </MarketAccessory>
      <label slot="label">{t('MessageInput.inputMenu.appointment')}</label>
    </MarketRow>,
  );

  if (!isProhibited) {
    // coupon
    const couponOnClick = (): void => {
      closeDropdown();
      event.track('Click Create Action', {
        action_type_name: 'send_coupon',
        needs_upgrade: isNotSubscribedToMPlus,
        transcript_id: transcript.id,
      });
      if (isNotSubscribedToMPlus) {
        event.track('Click Messages Plus Subscribe', {
          referral_page_name: 'premium_feature_coupon',
        });
      }
      transcriptView.openCouponModal();
    };

    content.push(
      <MarketRow
        key="coupon"
        data-testid="InputMenu__coupon"
        onClick={couponOnClick}
        onKeyDown={(e) => onKeyDownEnter(e, couponOnClick)}
        disabled={disabled || undefined}
        className={classNames('InputMenu__coupon', {
          'InputMenu__row-disabled': disabled,
        })}
      >
        <MarketAccessory slot="leading-accessory" size="icon">
          {/* Color of icon should match the var(--row-disabled-state-text-color) property */}
          <CouponPercentageIcon
            color={disabled ? 'rgba(0,0,0,0.3)' : undefined}
          />
        </MarketAccessory>
        <label slot="label">{t('MessageInput.inputMenu.coupon')}</label>
        {trailingAccessory}
      </MarketRow>,
    );
  }

  if (transcript.type !== 'ORPHAN') {
    const estimateOnClick = (): void => {
      closeDropdown();
      event.track('Click Create Action', {
        action_type_name: 'send_estimate',
        needs_upgrade: false,
        transcript_id: transcript.id,
      });
      window.open(
        getCreateEstimateUrl(
          transcript.customerToken,
          transcript.sellerKey,
          transcript.id,
        ),
        '_blank',
      );
    };
    content.push(
      <MarketRow
        key="estimate"
        data-testid="InputMenu__estimate"
        onClick={estimateOnClick}
        onKeyDown={(e) => onKeyDownEnter(e, estimateOnClick)}
      >
        <MarketAccessory slot="leading-accessory" size="icon">
          <EstimateIcon />
        </MarketAccessory>
        <label slot="label">{t('MessageInput.inputMenu.estimate')}</label>
      </MarketRow>,
    );
  }

  if (
    featureFlag.get(KEY_MESSAGES_PLUS) &&
    featureFlag.get(KEY_EMAIL_FILES_ENABLED)
  ) {
    const filesOnClick = (): void => {
      closeDropdown();

      event.track('Click Create Action', {
        action_type_name: 'send_file',
        needs_upgrade: isNotSubscribedToMPlus,
        transcript_id: transcript.id,
      });

      if (isNotSubscribedToMPlus) {
        event.track('Click Messages Plus Subscribe', {
          referral_page_name: 'premium_feature_file',
        });
        navigation.openSheet('MESSAGES_PLUS_PRICING');
        return;
      }

      if (transcript.medium !== Medium.EMAIL) {
        modal.openFileEmailOnlyModal(transcript.id);
        return;
      }

      if (inputHasPhotos) {
        modal.openFileAndPhotoMutuallyExclusiveModal('PHOTO');
        return;
      }

      if (tooltip.isVisible('FILE')) {
        modal.openFileFirstTimeModal(() => fileInputRef.current?.click());
        return;
      }

      fileInputRef.current?.click();
    };
    content.push(
      <MarketRow
        key="file"
        data-testid="InputMenu__files"
        onClick={filesOnClick}
        onKeyDown={(e) => onKeyDownEnter(e, filesOnClick)}
        disabled={disabled || undefined}
        className={classNames('InputMenu__files', {
          'InputMenu__row-disabled': disabled,
        })}
      >
        <MarketAccessory slot="leading-accessory" size="icon">
          <AttachmentIcon color={disabled ? 'rgba(0,0,0,0.3)' : undefined} />
        </MarketAccessory>
        <label slot="label">{t('MessageInput.inputMenu.file')}</label>
        {trailingAccessory}
      </MarketRow>,
    );
  }

  // reviews
  const isReviewsEnabled =
    featureFlag.get(KEY_REQUEST_GOOGLE_REVIEW) && !isProhibited;
  if (isReviewsEnabled) {
    const reviewOnClick = (): void => {
      closeDropdown();
      event.track('Click Create Action', {
        action_type_name: 'request_review',
        needs_upgrade: false,
        transcript_id: transcript.id,
      });
      requestReview();
    };
    content.push(
      <MarketRow
        key="review"
        data-testid="InputMenu__review"
        onClick={reviewOnClick}
        onKeyDown={(e) => onKeyDownEnter(e, reviewOnClick)}
        className={classNames('InputMenu__review')}
      >
        <MarketAccessory slot="leading-accessory" size="icon">
          <RequestReviewIcon />
        </MarketAccessory>
        <label slot="label">{t('MessageInput.inputMenu.review')}</label>
      </MarketRow>,
    );
  }

  if (transcript.type !== 'ORPHAN') {
    const invoiceOnClick = (): void => {
      closeDropdown();
      event.track('Click Create Action', {
        action_type_name: 'send_invoice',
        needs_upgrade: false,
        transcript_id: transcript.id,
      });
      window.open(
        getCreateInvoiceUrl(
          transcript.customerToken,
          transcript.sellerKey,
          transcript.id,
        ),
        '_blank',
      );
    };
    content.push(
      <MarketRow
        key="invoice"
        data-testid="InputMenu__invoice"
        onClick={invoiceOnClick}
        onKeyDown={(e) => onKeyDownEnter(e, invoiceOnClick)}
      >
        <MarketAccessory slot="leading-accessory" size="icon">
          <InvoiceIcon />
        </MarketAccessory>
        <label slot="label">{t('MessageInput.inputMenu.invoice')}</label>
      </MarketRow>,
    );
  }

  // photos
  const photosOnClick = (): void => {
    closeDropdown();

    event.track('Click Create Action', {
      action_type_name: 'send_photo',
      needs_upgrade: false,
      transcript_id: transcript.id,
    });

    if (inputHasFiles) {
      modal.openFileAndPhotoMutuallyExclusiveModal('FILE');
      return;
    }

    photoInputRef.current?.click();
  };
  content.push(
    <MarketRow
      key="photos"
      data-testid="InputMenu__photos"
      onClick={photosOnClick}
      onKeyDown={(e) => onKeyDownEnter(e, photosOnClick)}
      className="InputMenu__photos"
    >
      <MarketAccessory slot="leading-accessory" size="icon">
        <PhotoIcon />
      </MarketAccessory>
      <label slot="label">{t('MessageInput.inputMenu.photos')}</label>
    </MarketRow>,
  );

  return (
    <>
      <MarketButtonDropdown
        popoverPlacement="top-start"
        data-testid="InputMenu"
        className="InputMenu"
        interaction="persistent"
        noCaret
        disabled={isInputDisabled || isLoading || undefined}
        ref={buttonDropdownRef}
      >
        <MarketButton
          slot="trigger"
          size="small"
          className="InputMenu__open-button"
        >
          <PlusIcon slot="icon" />
        </MarketButton>
        <MarketList slot="content">{content}</MarketList>
      </MarketButtonDropdown>
      {!isInputDisabled && isReviewsEnabled && tooltip.isVisible('REVIEWS') && (
        <AnnouncementTooltip
          text={t('MessageInput.inputMenu.reviews_tooltip_text')}
          onRender={() => {
            event.track('View Reviews Tooltip');
          }}
          onDismiss={() => {
            event.track('Click Reviews Tooltip Dismiss');
            tooltip.dismiss('REVIEWS');
          }}
        />
      )}
      {modal.currentModal === 'FILE_EMAIL_ONLY' && <FileEmailOnlyDialog />}
      {modal.currentModal === 'FILE_FIRST_TIME' && <FileFirstTimeModal />}
      {modal.currentModal === 'FILE_PHOTO_MUTUALLY_EXCLUSIVE' && (
        <FileAndPhotoMutuallyExclusiveDialog />
      )}
    </>
  );
});

export default InputMenu;
