/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';
import './types';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "bills": {
        "nested": {
          "ElectronicSignatureDetails": {
            "fields": {
              "certificateVersion": {
                "type": "uint32",
                "id": 1,
                "rule": "optional"
              },
              "electronicSignature": {
                "type": "string",
                "id": 2,
                "rule": "optional"
              },
              "signatureSequenceNumber": {
                "type": "uint64",
                "id": 3,
                "rule": "optional"
              },
              "displaySignature": {
                "type": "string",
                "id": 4,
                "rule": "optional"
              },
              "signedAt": {
                "type": "squareup.bills.ISO8601Date",
                "id": 5,
                "rule": "optional"
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.bills?.nested?.ElectronicSignatureDetails) {
	$root.addJSON(jsonData);
}

/**
 * The details of electronic signed signature.
 *
 * SOURCE ElectronicSignatureDetails @ squareup/bills/electronic_signature_details.proto at 13:1
 */
export const ElectronicSignatureDetails = $root.lookupType('squareup.bills.ElectronicSignatureDetails');
fixType(ElectronicSignatureDetails, {
  certificateVersion: { required: true },
  electronicSignature: { required: true },
  signatureSequenceNumber: { required: true },
  displaySignature: { required: true },
  signedAt: { required: true },
});
Builder.createAndAttachToType(ElectronicSignatureDetails);
