/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "mediamanager": {
        "nested": {
          "service": {
            "nested": {
              "MediaKey": {
                "fields": {
                  "id": {
                    "type": "string",
                    "id": 1,
                    "rule": "optional"
                  },
                  "namespace": {
                    "type": "string",
                    "id": 2,
                    "rule": "optional",
                    "options": {
                      "default": "GLOBAL"
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.mediamanager?.nested?.service?.nested?.MediaKey) {
	$root.addJSON(jsonData);
}

/**
 * SOURCE MediaKey @ squareup/mediamanager/service.proto at 11:1
 */
export const MediaKey = $root.lookupType('squareup.mediamanager.service.MediaKey');
fixType(MediaKey, {
  id: { notEmpty: true },
  namespace: { notEmpty: true },
});
Builder.createAndAttachToType(MediaKey);
