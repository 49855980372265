/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "paymentreporter": {
        "nested": {
          "service": {
            "nested": {
              "TaxType": {
                "values": {
                  "TT_DO_NOT_USE": 0,
                  "VAT": 1,
                  "GST": 2,
                  "PST": 3,
                  "QST": 4,
                  "HST": 5,
                  "SALES_TAX": 6,
                  "JCT": 7
                }
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.paymentreporter?.nested?.service?.nested?.TaxType) {
	$root.addJSON(jsonData);
}

/**
 * The different tax types that may be present for fees.
 *
 * SOURCE TaxType @ squareup/paymentreporter/transaction_fee_tax_report.proto at 343:1
 */
export const TaxType = $root.lookupEnum('squareup.paymentreporter.service.TaxType').values;
