/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../Fixes';
import Builder from '../../../Builder';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "opt": {
        "nested": {
          "objc": {
            "nested": {
              "Entity": {
                "fields": {
                  "name": {
                    "type": "string",
                    "id": 2,
                    "rule": "optional"
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.opt?.nested?.objc?.nested?.Entity) {
	$root.addJSON(jsonData);
}

/**
 * Describes how the protobuf message maps to the Core Data Entity
 *
 * SOURCE Entity @ squareup/opt/objc/objc.proto at 12:1
 */
export const Entity = $root.lookupType('squareup.opt.objc.Entity');
fixType(Entity);
Builder.createAndAttachToType(Entity);
