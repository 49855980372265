import {
  MarketField,
  MarketInputText,
  MarketSelect,
  MarketList,
  MarketRow,
} from 'src/components/Market';
import { observer } from 'mobx-react';
import React, { ReactElement, useState } from 'react';
import { IAddress } from 'src/gen/squareup/smsphoneregistry/verification';
import { SHADOW_REACT_ROOT_SELECTOR } from 'src/utils/shadowDomUtils';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import { useTranslation } from 'react-i18next';
import {
  getStateOptionsForCountry,
  missingAddressToInputLabels,
} from './utils';
import AddressAutocompleteInput from './AddressAutocompleteInput';

/**
 * The input boxes for the Business Address field in the UnitVerificationForm.
 *
 * @example
 * Usage:
 * <BusinessAddressInputs />
 * @author teresalin
 */
const BusinessAddressInputs = observer((): ReactElement => {
  const {
    user,
    unitVerification: {
      setUnitInformation,
      errors,
      unitToken,
      getDisplayValue,
    },
  } = useMessengerControllerContext();
  const { t } = useTranslation();

  const [useManualAddress, setUseManualAddress] = useState(false);

  const { address1, address2, city, state, zipCode } =
    missingAddressToInputLabels(user.countryCode);

  const states = getStateOptionsForCountry(user.countryCode);

  const handleMarketInputValueChange = (
    value: string,
    addressField: keyof IAddress,
  ): void => {
    setUnitInformation(
      {
        unitInfoKey: 'address',
        addressKey: addressField,
      },
      value,
    );
  };

  const addressErrors = errors?.address;

  if (!getDisplayValue('address', 'address1') && !useManualAddress) {
    return (
      <div className="market-grid-item-full">
        <AddressAutocompleteInput
          setUseManualAddress={() => setUseManualAddress(true)}
        />
      </div>
    );
  }

  return (
    <>
      {Object.entries({ address1, address2, city }).map(
        ([addressField, label]) => {
          const error = addressErrors?.[addressField as keyof IAddress];
          return (
            <MarketField
              className="market-grid-item-full"
              key={`${unitToken}-address-${addressField}`}
              invalid={Boolean(error) || undefined}
              data-testid={`BusinessAddressInputs__${addressField}`}
            >
              <MarketInputText
                value={
                  getDisplayValue('address', addressField as keyof IAddress) ??
                  ''
                }
              >
                <label>{label}</label>
                <input
                  slot="input"
                  data-testid={`BusinessAddressInputs__${addressField}__input`}
                  type="text"
                  onChange={(e) => {
                    handleMarketInputValueChange(
                      e.target.value,
                      addressField as keyof IAddress,
                    );
                  }}
                  value={
                    getDisplayValue(
                      'address',
                      addressField as keyof IAddress,
                    ) ?? ''
                  }
                />
              </MarketInputText>
              {Boolean(error) && <small slot="error">{error}</small>}
            </MarketField>
          );
        },
      )}
      <MarketField
        className="market-grid-item-medium"
        data-testid="BusinessAddressInputs__state"
        invalid={Boolean(addressErrors?.['state']) || undefined}
      >
        <MarketSelect
          key={`${unitToken}-address-state`}
          onMarketSelectValueDidChange={(e) =>
            handleMarketInputValueChange(e.detail.value as string, 'state')
          }
          popoverContainer={SHADOW_REACT_ROOT_SELECTOR}
          value={getDisplayValue('address', 'state') ?? ''}
          data-testid="BusinessAddressInputs__state__select"
        >
          <label>{state}</label>
          <MarketList slot="list">
            {states.map(({ code, translationKey }) => (
              <MarketRow value={code} key={code}>
                <label slot="label">{t(translationKey)}</label>
              </MarketRow>
            ))}
          </MarketList>
        </MarketSelect>
        <small slot="error">{addressErrors?.['state']}</small>
      </MarketField>
      <MarketField
        className="market-grid-item-medium"
        invalid={Boolean(addressErrors?.['zipCode']) || undefined}
        data-testid="BusinessAddressInputs__zipCode"
      >
        <MarketInputText
          key={`${unitToken}-address-zipCode`}
          value={getDisplayValue('address', 'zipCode') ?? ''}
        >
          <label>{zipCode}</label>
          <input
            slot="input"
            data-testid="BusinessAddressInputs__zipCode__input"
            type="text"
            onChange={(e) => {
              handleMarketInputValueChange(e.target.value, 'zipCode');
            }}
            value={getDisplayValue('address', 'zipCode') ?? ''}
          />
        </MarketInputText>
        {Boolean(addressErrors?.['zipCode']) && (
          <small slot="error">{addressErrors?.['zipCode']}</small>
        )}
      </MarketField>
    </>
  );
});

export default BusinessAddressInputs;
