/**
 * An icon representing file attachment.
 */
import React, { ReactElement } from 'react';

function AttachmentIcon({ color }: { color?: string }): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="12"
      fill="none"
      viewBox="0 0 24 12"
    >
      <title>File</title>
      <path
        fill={color || '#000'}
        fillOpacity=".9"
        d="M19.5 12H6c-3.31 0-6-2.69-6-6s2.69-6 6-6h11v2H6C3.79 2 2 3.79 2 6s1.79 4 4 4h13.5a2.5 2.5 0 0 0 0-5H9c-.55 0-1 .45-1 1s.45 1 1 1h8v2H9C7.35 9 6 7.65 6 6s1.35-3 3-3h10.5C21.98 3 24 5.02 24 7.5S21.98 12 19.5 12Z"
      />
    </svg>
  );
}

export default AttachmentIcon;
