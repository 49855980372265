/**
 * The React app that Conversations embeds into Dashboard, Payroll,
 * and Appointments. The main functionality is wrapped in a component
 * called SideDrawer, patterned after the Dashboard team's Notifications
 * component.
 */
import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import MessengerBlade from './components/MessengerBlade/MessengerBlade';
import MessengerController from './MessengerController';
import AppContent from './AppContent';
import AppOverlays from './AppOverlays';
import './App.scss';
import { onKeyDownEnter } from './utils/keyboardUtils';
import MessengerControllerContext from './context/MessengerControllerContext';

type AppProps = {
  messenger: MessengerController;
};

/**
 * Top level component for the Square Messenger application.
 *
 * @param {AppProps} props
 * @param {MessengerController} props.messenger
 */
const App = observer(
  ({ messenger }: AppProps): ReactElement => (
    <MessengerControllerContext.Provider value={messenger}>
      <MessengerBlade isOpen={messenger.navigation.primary.isOpen}>
        <AppContent />
      </MessengerBlade>
      <div
        data-testid="MessengerVeil"
        className={`veil veil--visual veil--popover${
          messenger.navigation.primary.isOpen ? ' veil--is-active' : ''
        }`}
        role="button"
        aria-label="Veil"
        tabIndex={0}
        onClick={() => messenger.navigation.primary.close()}
        onKeyDown={(e) => onKeyDownEnter(e, messenger.navigation.primary.close)}
      />
      <AppOverlays messenger={messenger} />
    </MessengerControllerContext.Provider>
  ),
);

export default App;
