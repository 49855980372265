import React, { ReactElement, useState } from 'react';
import { observer } from 'mobx-react';
import { MarketField } from 'src/components/Market';
import InputMoney from 'src/components/InputMoney/InputMoney';
import { amountToCurrencyString } from 'src/utils/moneyUtils';
import { useTranslation } from 'react-i18next';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';

export type OneTimeTabLinkProps = {
  minAmount: number;
  maxAmount: number;
  setAmount: (value: number) => void;
};

/**
 * The page to render when the one time tab is selected in <CheckoutLinkModal />.
 * Handles the input and formatting of currency.
 *
 * @example
 * Basic usage:
 * <OneTimeTab
 *   minAmount={1}
 *   maxAmount={2000}
 *   setAmount={setAmount}
 * />
 * @param {number} minAmount
 * The minimum amount for the input.
 * @param {number} maxAmount
 * The maximum amount for the input.
 * @param {Function} setAmount
 * Function to call when input value changes.
 * @author klim
 */
const OneTimeLinkTab = observer((props: OneTimeTabLinkProps): ReactElement => {
  const { minAmount, maxAmount, setAmount } = props;
  const { user, modal } = useMessengerControllerContext();
  const { checkoutLinkAmount } = modal;
  const { t } = useTranslation();

  const isAmountValid = (amount?: number): boolean =>
    amount === undefined || (amount <= maxAmount && amount >= minAmount);

  const { currencyCode, locale } = user;
  const [hasAmountValidationError, setHasAmountValidationError] = useState(
    !isAmountValid(checkoutLinkAmount),
  );

  return (
    <MarketField
      invalid={hasAmountValidationError || undefined}
      data-testid="CheckoutLinkModal__one-time__field"
    >
      <InputMoney
        label={t('CheckoutLinkModal.onetime.title')}
        currencyCode={currencyCode}
        locale={locale}
        initialAmount={checkoutLinkAmount}
        onChange={(newAmount) => {
          setAmount(newAmount);
          setHasAmountValidationError(!isAmountValid(newAmount));
        }}
      />
      <small slot="error">
        {t('CheckoutLinkModal.onetime.maxAmount', {
          min: amountToCurrencyString(minAmount, currencyCode, locale),
          max: amountToCurrencyString(maxAmount, currencyCode, locale),
        })}
      </small>
    </MarketField>
  );
});

export default OneTimeLinkTab;
