/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';
import '../coupons/v2/model_v2';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "waiter": {
        "nested": {
          "data": {
            "nested": {
              "VectorClock": {
                "fields": {
                  "clockList": {
                    "type": "squareup.waiter.data.VectorClock.Clock",
                    "id": 1,
                    "rule": "repeated"
                  }
                },
                "nested": {
                  "Clock": {
                    "fields": {
                      "name": {
                        "type": "string",
                        "id": 1,
                        "rule": "optional"
                      },
                      "version": {
                        "type": "int64",
                        "id": 2,
                        "rule": "optional"
                      }
                    }
                  }
                }
              },
              "WaiterExtension": {
                "fields": {
                  "deleted": {
                    "type": "bool",
                    "id": 1,
                    "rule": "optional"
                  }
                }
              },
              "WaiterFeatureDetailsExtension": {
                "fields": {
                  "otNameContext": {
                    "type": "squareup.waiter.data.WaiterFeatureDetailsExtension.MutableFieldContext",
                    "id": 1,
                    "rule": "optional"
                  },
                  "otNoteContext": {
                    "type": "squareup.waiter.data.WaiterFeatureDetailsExtension.MutableFieldContext",
                    "id": 2,
                    "rule": "optional"
                  },
                  "otTicketOwnerContext": {
                    "type": "squareup.waiter.data.WaiterFeatureDetailsExtension.MutableFieldContext",
                    "id": 3,
                    "rule": "optional"
                  },
                  "transientData": {
                    "type": "squareup.waiter.data.Transient",
                    "id": 4,
                    "rule": "optional"
                  },
                  "transientCouponContext": {
                    "type": "squareup.waiter.data.WaiterFeatureDetailsExtension.MutableFieldContext",
                    "id": 5,
                    "rule": "optional"
                  },
                  "otPredefinedTicketContext": {
                    "type": "squareup.waiter.data.WaiterFeatureDetailsExtension.MutableFieldContext",
                    "id": 6,
                    "rule": "optional"
                  },
                  "buyerInfoContext": {
                    "type": "squareup.waiter.data.WaiterFeatureDetailsExtension.MutableFieldContext",
                    "id": 7,
                    "rule": "optional"
                  },
                  "pricingEngineStateContext": {
                    "type": "squareup.waiter.data.WaiterFeatureDetailsExtension.MutableFieldContext",
                    "id": 8,
                    "rule": "optional"
                  },
                  "closedByDeviceId": {
                    "type": "string",
                    "id": 9,
                    "rule": "optional"
                  }
                },
                "nested": {
                  "MutableFieldContext": {
                    "fields": {
                      "clientVectorClock": {
                        "type": "squareup.waiter.data.VectorClock",
                        "id": 1,
                        "rule": "optional"
                      },
                      "processorDatacenter": {
                        "type": "string",
                        "id": 2,
                        "rule": "optional"
                      }
                    }
                  }
                }
              },
              "Transient": {
                "fields": {
                  "coupon": {
                    "type": "squareup.coupons.v2.CouponV2",
                    "id": 1,
                    "rule": "optional"
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.waiter?.nested?.data?.nested?.VectorClock && !$root.nested?.squareup?.nested?.waiter?.nested?.data?.nested?.VectorClock?.nested?.Clock && !$root.nested?.squareup?.nested?.waiter?.nested?.data?.nested?.WaiterExtension && !$root.nested?.squareup?.nested?.waiter?.nested?.data?.nested?.WaiterFeatureDetailsExtension && !$root.nested?.squareup?.nested?.waiter?.nested?.data?.nested?.WaiterFeatureDetailsExtension?.nested?.MutableFieldContext && !$root.nested?.squareup?.nested?.waiter?.nested?.data?.nested?.Transient) {
	$root.addJSON(jsonData);
}

/**
 * SOURCE VectorClock @ squareup/waiter/data.proto at 129:1
 */
export const VectorClock = $root.lookupType('squareup.waiter.data.VectorClock');
fixType(VectorClock);
Builder.createAndAttachToType(VectorClock);
/**
 * SOURCE Clock @ squareup/waiter/data.proto at 130:3
 */
VectorClock.Clock = $root.lookupType('squareup.waiter.data.VectorClock.Clock');
fixType(VectorClock.Clock);
Builder.createAndAttachToType(VectorClock.Clock);
/**
 * SOURCE WaiterExtension @ squareup/waiter/data.proto at 138:1
 */
export const WaiterExtension = $root.lookupType('squareup.waiter.data.WaiterExtension');
fixType(WaiterExtension);
Builder.createAndAttachToType(WaiterExtension);
/**
 * SOURCE WaiterFeatureDetailsExtension @ squareup/waiter/data.proto at 144:1
 */
export const WaiterFeatureDetailsExtension = $root.lookupType('squareup.waiter.data.WaiterFeatureDetailsExtension');
fixType(WaiterFeatureDetailsExtension);
Builder.createAndAttachToType(WaiterFeatureDetailsExtension);
/**
 * For testing
 *
 * SOURCE MutableFieldContext @ squareup/waiter/data.proto at 147:3
 */
WaiterFeatureDetailsExtension.MutableFieldContext = $root.lookupType('squareup.waiter.data.WaiterFeatureDetailsExtension.MutableFieldContext');
fixType(WaiterFeatureDetailsExtension.MutableFieldContext);
Builder.createAndAttachToType(WaiterFeatureDetailsExtension.MutableFieldContext);
/**
 * The transient message is used to represent data that is only relevant to clients but also needs
 * to be synced between them.
 *
 * SOURCE Transient @ squareup/waiter/data.proto at 222:1
 */
export const Transient = $root.lookupType('squareup.waiter.data.Transient');
fixType(Transient);
Builder.createAndAttachToType(Transient);
