/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';
import '../common/token';
import './cart';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "bills": {
        "nested": {
          "ItemizedRefundCartDetails": {
            "fields": {
              "itemization": {
                "type": "squareup.bills.ItemizationRefund",
                "id": 1,
                "rule": "repeated"
              },
              "discount": {
                "type": "squareup.bills.DiscountLineItemRefund",
                "id": 2,
                "rule": "repeated"
              },
              "fee": {
                "type": "squareup.bills.FeeLineItemRefund",
                "id": 3,
                "rule": "repeated"
              },
              "roundingAdjustment": {
                "type": "squareup.bills.RoundingAdjustmentLineItemRefund",
                "id": 4,
                "rule": "optional"
              }
            }
          },
          "ItemizationRefund": {
            "fields": {
              "itemizationTokenPair": {
                "type": "squareup.common.token.TokenPair",
                "id": 1,
                "rule": "optional"
              },
              "quantity": {
                "type": "string",
                "id": 2,
                "rule": "optional"
              },
              "refundCustomNote": {
                "type": "string",
                "id": 3,
                "rule": "optional"
              },
              "amounts": {
                "type": "squareup.bills.Itemization.Amounts",
                "id": 4,
                "rule": "optional"
              }
            }
          },
          "DiscountLineItemRefund": {
            "fields": {
              "discountLineItemTokenPair": {
                "type": "squareup.common.token.TokenPair",
                "id": 1,
                "rule": "optional"
              },
              "amounts": {
                "type": "squareup.bills.DiscountLineItem.Amounts",
                "id": 2,
                "rule": "optional"
              }
            }
          },
          "FeeLineItemRefund": {
            "fields": {
              "feeLineItemTokenPair": {
                "type": "squareup.common.token.TokenPair",
                "id": 1,
                "rule": "optional"
              },
              "amounts": {
                "type": "squareup.bills.FeeLineItem.Amounts",
                "id": 2,
                "rule": "optional"
              }
            }
          },
          "RoundingAdjustmentLineItemRefund": {
            "fields": {
              "roundingAdjustmentLineItemTokenPair": {
                "type": "squareup.common.token.TokenPair",
                "id": 1,
                "rule": "optional"
              },
              "amounts": {
                "type": "squareup.bills.RoundingAdjustmentLineItem.Amounts",
                "id": 2,
                "rule": "optional"
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.bills?.nested?.ItemizedRefundCartDetails && !$root.nested?.squareup?.nested?.bills?.nested?.ItemizationRefund && !$root.nested?.squareup?.nested?.bills?.nested?.DiscountLineItemRefund && !$root.nested?.squareup?.nested?.bills?.nested?.FeeLineItemRefund && !$root.nested?.squareup?.nested?.bills?.nested?.RoundingAdjustmentLineItemRefund) {
	$root.addJSON(jsonData);
}

/**
 * Itemized refund cart details.
 *
 * NOTE: A bill with existing amount based partial refunds is not allowed to take
 * itemized partial refunds.
 *
 * SOURCE ItemizedRefundCartDetails @ squareup/bills/itemized_refund.proto at 17:1
 */
export const ItemizedRefundCartDetails = $root.lookupType('squareup.bills.ItemizedRefundCartDetails');
fixType(ItemizedRefundCartDetails);
Builder.createAndAttachToType(ItemizedRefundCartDetails);
/**
 * SOURCE ItemizationRefund @ squareup/bills/itemized_refund.proto at 49:1
 */
export const ItemizationRefund = $root.lookupType('squareup.bills.ItemizationRefund');
fixType(ItemizationRefund, {
  itemizationTokenPair: { required: true },
  quantity: { required: true },
  amounts: { required: true },
});
Builder.createAndAttachToType(ItemizationRefund);
/**
 * SOURCE DiscountLineItemRefund @ squareup/bills/itemized_refund.proto at 71:1
 */
export const DiscountLineItemRefund = $root.lookupType('squareup.bills.DiscountLineItemRefund');
fixType(DiscountLineItemRefund, {
  discountLineItemTokenPair: { required: true },
  amounts: { required: true },
});
Builder.createAndAttachToType(DiscountLineItemRefund);
/**
 * SOURCE FeeLineItemRefund @ squareup/bills/itemized_refund.proto at 77:1
 */
export const FeeLineItemRefund = $root.lookupType('squareup.bills.FeeLineItemRefund');
fixType(FeeLineItemRefund, {
  feeLineItemTokenPair: { required: true },
  amounts: { required: true },
});
Builder.createAndAttachToType(FeeLineItemRefund);
/**
 * SOURCE RoundingAdjustmentLineItemRefund @ squareup/bills/itemized_refund.proto at 83:1
 */
export const RoundingAdjustmentLineItemRefund = $root.lookupType('squareup.bills.RoundingAdjustmentLineItemRefund');
fixType(RoundingAdjustmentLineItemRefund, {
  roundingAdjustmentLineItemTokenPair: { required: true },
  amounts: { required: true },
});
Builder.createAndAttachToType(RoundingAdjustmentLineItemRefund);
