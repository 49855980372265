import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Utterance as UtteranceProto } from 'src/gen/squareup/messenger/v3/messenger_service';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import { EcomFormEntry, EcomFormQuestionAnswer } from 'src/MessengerTypes';
import CustomerEventCard from 'src/pages/TranscriptViewPage/components/CustomerEventCard/CustomerEventCard';
import FormSubmissionIcon from 'src/svgs/FormSubmissionIcon';
import { BaseContextualEventCardProps } from 'src/types/BaseContextualEventCardProps';
import { renderFormAnswer } from './formSubmissionUtils';
import './FormSubmissionEventCard.scss';

/**
 * The component for a buyer form submission styled card.
 */
const FormSubmissionEventCard = observer(
  ({
    item,
    customerImage,
    cardRef,
  }: BaseContextualEventCardProps): ReactElement => {
    const { t } = useTranslation();
    // TODO(eblaine, #3507): Add functionality to switch medium when contact is clicked
    const { timestampMillis, data, attachedUtterance } = item;
    const { formTitle, questionAnswers: questionAnswersOptional } =
      data as EcomFormEntry;

    const utteranceId = attachedUtterance?.utterance.id ?? 0;

    const questionAnswers = questionAnswersOptional || [];

    // Get a preview of the dates in this form to help with time rendering later.
    const allDates = questionAnswers.filter(
      ({ type }) =>
        type ===
        UtteranceProto.Metadata.EcomFormEntry.EcomFormQuestionAnswer.Type.DATE,
    );
    // For time answers, we need a calendar date to be able to render them correctly. Ideally,
    // this form submission also has a date, and we can use that. If not, we fall back to the
    // current date.
    let dateUsedForTimeRendering = new Date(Date.now())
      .toISOString()
      .slice(0, 10); // YYYY-MM-DD
    if (allDates.length === 1 && allDates[0].answer) {
      dateUsedForTimeRendering = allDates[0].answer;
    }

    const { user } = useMessengerControllerContext();

    const renderRow = (
      questionAnswer: EcomFormQuestionAnswer,
    ): ReactElement => {
      const { questionName } = questionAnswer;
      return (
        <div className="TranscriptViewItem__ecom__row" key={questionName}>
          <div className="medium-30">{questionName}</div>
          <div className="paragraph-30">
            {renderFormAnswer(
              questionAnswer,
              dateUsedForTimeRendering,
              utteranceId,
              user.countryCode,
              user.timezone,
            )}
          </div>
        </div>
      );
    };
    const body = <>{questionAnswers.map((item) => renderRow(item))}</>;

    return (
      <CustomerEventCard
        timestampMillis={timestampMillis}
        icon={<FormSubmissionIcon />}
        title={t('ContextualEvent.form.title')}
        subtitle={formTitle ?? ''} // Note: Forms are required to have a title
        customerImage={customerImage}
        body={body}
        cardRef={cardRef}
        id={utteranceId}
      />
    );
  },
);

export default FormSubmissionEventCard;
