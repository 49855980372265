/**
 * An icon representing invoice.
 */
import React, { ReactElement } from 'react';

function InvoiceIcon(props: { color?: string }): ReactElement {
  const { color } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <title>Invoice</title>
      <path
        fill={color || '#000'}
        fillRule="evenodd"
        d="M16.5 4a1 1 0 1 0 0-2h-9a1 1 0 1 0 0 2h9Zm-12 4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h15a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1h-15Zm8 2h-7v4.134A.995.995 0 0 1 6 14h6.5v-4Zm-7 10v-4.134A.995.995 0 0 0 6 16h6.5v4h-7Zm9 0v-4H18a.995.995 0 0 0 .5-.134V20h-4Zm4-10v4.134A.995.995 0 0 0 18 14h-3.5v-4h4Z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default InvoiceIcon;
