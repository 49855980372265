import { LocalFile, LocalUtterance } from 'src/MessengerTypes';
import {
  Attachment,
  IAttachment,
} from 'src/gen/squareup/messenger/v3/messenger_service';
import { getOS } from './navigatorUtils';

const DECIMAL_BASE = 1000;
const BINARY_BASE = 1024;

/**
 * Convert numerical bytes to human readable string. Max of 2 decimal places.
 * e.g. Binary based OS
 *      1024 ==> 1 KB
 *      1124 ==> 1.1 KB
 *      11244234 ==> 10.72 MB
 *
 * @param {number} bytes
 * The bytes to convert.
 */
export function bytesToSizeLabel(bytes: number): string {
  let base;
  switch (getOS()) {
    case 'Mac':
      base = DECIMAL_BASE;
      break;
    case 'Windows':
    case 'Linux':
    case 'Android':
    default:
      base = BINARY_BASE;
  }

  const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 B';
  const i = Math.floor(Math.log(bytes) / Math.log(base));
  return `${Math.round((bytes / Math.pow(base, i)) * 100) / 100} ${sizes[i]}`;
}

/**
 * Returns a list of files for a given utterance.
 *
 * @param {LocalUtterance} localUtterance
 * Utterance to get the files from.
 */
export const getFilesFromLocalUtterance = (
  localUtterance: LocalUtterance,
): IAttachment[] => {
  const { utterance, files } = localUtterance;

  if (files && files.length > 0) {
    return files;
  }

  if (utterance.attachments && utterance.attachments.length > 0) {
    return utterance.attachments.filter(
      (attachment) => attachment.type === Attachment.AttachmentType.FILE,
    );
  }

  return [];
};

/**
 * Get the sum of file size for an array of files.
 *
 * @param {LocalFile[]} files
 */
export const getTotalFileSize = (files: LocalFile[]): number => {
  return files.reduce((accumulator, file) => accumulator + file.file.size, 0);
};
