/**
 * An icon for the visual representation of adding a new customer.
 */
import React, { ReactElement } from 'react';

const NewCustomerIcon = (): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <title>New Customer Icon</title>
    <path
      fill="#0055CC"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 2a2 2 0 1 0 0 4 2 2 0 0 0 0-4ZM4 4a4 4 0 1 1 8 0 4 4 0 0 1-8 0Zm3.941 6c-1.625 0-3.114.646-4.253 1.726a1 1 0 0 1-1.376-1.452C3.796 8.867 5.77 8 7.942 8c.814 0 1.603.122 2.35.35a1 1 0 0 1-.584 1.913A6.025 6.025 0 0 0 7.94 10ZM14 11a1 1 0 1 0-2 0v1h-1a1 1 0 0 0 0 2h1v1a1 1 0 0 0 2 0v-1h1a1 1 0 1 0 0-2h-1v-1Z"
    />
  </svg>
);

export default NewCustomerIcon;
