/**
 * An icon representing appointment, used to render contextual events (i.e. TranscriptViewItem)
 */
import React, { ReactElement } from 'react';

function AppointmentIcon(): ReactElement {
  return (
    <svg
      className="AppointmentIcon"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <title>Appointment</title>
      <path
        fill="#000"
        fillOpacity=".9"
        fillRule="evenodd"
        d="M17 3h-2v2H9V3H7v2C5.34 5 4 6.34 4 8v10c0 1.66 1.34 3 3 3h10c1.66 0 3-1.34 3-3V8c0-1.66-1.34-3-3-3V3Zm1 5c0-.55-.45-1-1-1H7c-.55 0-1 .45-1 1v1h12V8ZM6 11v7c0 .55.45 1 1 1h10c.55 0 1-.45 1-1v-7H6Z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default AppointmentIcon;
