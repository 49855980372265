import { Token } from '../common/token';
import { UserAttributes } from '../common/user-attributes';
import { Status } from './status';

export enum ExclusionReason {
  UNKNOWN = 'UNKNOWN',
  EXCLUDED_FROM_EXPERIMENT = 'EXCLUDED_FROM_EXPERIMENT',
  MISSING_EXPERIMENT = 'MISSING_EXPERIMENT',
  EXPERIMENT_MISCONFIGURED = 'EXPERIMENT_MISCONFIGURED',
}

/**
 * SOURCE RecordExclusionRequest @ squareup/feature/relay/experiments/message/record_exclusion.proto at 13:1
 */
export interface RecordExclusionRequest {
  /**
   * The name of the experiment that the user is excluded from. This must match the Optimizely experiment name.
   *
   * SOURCE experiment_name @ squareup/feature/relay/experiments/message/record_exclusion.proto at 15:3
   */
  experimentName?: string;
  /**
   * The token that represents the user. This corresponds to the user_id within Optimizely.
   *
   * SOURCE user_token @ squareup/feature/relay/experiments/message/record_exclusion.proto at 18:3
   */
  userToken?: Token;
  /**
   * The reason that a user was excluded from the experiment
   *
   * SOURCE exclusion_reason @ squareup/feature/relay/experiments/message/record_exclusion.proto at 28:3
   */
  exclusionReason?: ExclusionReason;
  /**
   * The user attributes used for this experiment. These must match the ones used in GetExperiments.
   *
   * SOURCE user_attributes @ squareup/feature/relay/experiments/message/record_exclusion.proto at 31:3
   */
  userAttributes?: UserAttributes;
  /**
   * The CDP source API key
   *
   * SOURCE cdp_key @ squareup/feature/relay/experiments/message/record_exclusion.proto at 33:3
   */
  cdpKey?: string;
}

/**
 * SOURCE RecordExclusionResponse @ squareup/feature/relay/experiments/message/record_exclusion.proto at 36:1
 */
export interface RecordExclusionResponse {
  /**
   * The result of this API call.
   *
   * SOURCE status @ squareup/feature/relay/experiments/message/record_exclusion.proto at 38:3
   */
  status: Status;
}
