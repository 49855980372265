import { Component, Host, State, Prop, Element, Event, Watch, EventEmitter, h } from '@stencil/core';

import { getNamespacedTagFor } from '../../utils/namespace';
import { TMarketHeaderNavigateEventDetail } from './events';

/**
 * @slot - The title. The position of the title varies based on the presence of other slots
 * @slot navigation - navigation buttons, ex close, back, forward, on the left side of the header
 * @slot wayfinding - the smaller "eyebrow" text displayed above the title, e.g. "Step 1 of 2"
 * @slot actions - for button(s) to perform actions, on the right side of the header
 * @part heading - the heading area where the default and wayfinding spot is contained in
 */
@Component({
  tag: 'market-header',
  styleUrl: 'market-header.css',
  shadow: true,
})
export class MarketHeader {
  @Element() el: HTMLMarketHeaderElement;
  customNavEl: any;

  /**
   * Whether or not the navigation slot is shown
   */
  @Prop({ mutable: true, reflect: true }) showNavigation: boolean | null = null;

  /**
   * String to use for the aria-label accessibility attribute of the default close "x" button.
   */
  @Prop({ reflect: true }) readonly closeButtonAriaLabel: string = 'Close';

  /**
   * Disables the default close "x" button.
   */
  @Prop() readonly disableCloseButton: boolean = false;

  /**
   * Whether or not the header is in compact mode
   * when navigation is present.
   */
  @Prop({ reflect: true }) readonly compact: boolean = false;

  @State() showActions: boolean = false;

  /**
   * Emitted when the close icon in the navigation slot is clicked.
   */
  @Event() marketHeaderNavigate: EventEmitter<TMarketHeaderNavigateEventDetail>;

  @Watch('compact')
  handleCompact() {
    if (this.compact) {
      const compact = this.el.shadowRoot.querySelector('.grid .compact');
      const minHeight = Number.parseInt(getComputedStyle(compact).height, 10);
      this.el.style.height = minHeight !== undefined ? `${minHeight}px` : '';
    } else {
      this.el.style.height = '';
    }
  }

  handleSlottedNavigation() {
    // If there is custom navigation passed in, we need to
    // attach event handlers to the slotted elements manually
    const customNavEl = this.el.querySelector('[slot="navigation"]');
    if (customNavEl) {
      this.showNavigation = true;
      customNavEl.addEventListener('click', this.handleSlottedNavClick.bind(this));
    }
  }

  handleSlottedActions() {
    const actions = this.el.querySelector('[slot="actions"]');
    this.showActions = actions ? true : false;
  }

  handleCloseButtonClick(event) {
    this.marketHeaderNavigate.emit({
      event,
      action: 'close',
    });
  }

  handleSlottedNavClick(event) {
    this.marketHeaderNavigate.emit({
      event,
      action: 'custom',
    });
  }

  componentWillLoad() {
    this.checkForNavigation();
    this.cloneDefaultHeadingToCompactHeading();

    // watch for changes in slotted children content
    const observer = new MutationObserver(this.handleMutation.bind(this));
    observer.observe(this.el, { childList: true, characterData: true, subtree: true });
  }

  componentDidRender() {
    // this function requires layout computation,
    // so we need to wait until render to call.
    this.handleCompact();
  }

  handleMutation(mutationList) {
    // We don't want to re-clone the default slot content if
    // the mutation was already the result of the cloning
    // because then we would get into an infinite loop!
    // So we have to look at the mutation data to check if a
    // compact slotted element was added to prevent re-cloning.
    let shouldClone = true;
    mutationList.forEach((mutation) => {
      if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
        mutation.addedNodes.forEach((node) => {
          if (node.slot === 'compact') {
            shouldClone = false;
          }
        });
      }
    });

    if (shouldClone) {
      this.cloneDefaultHeadingToCompactHeading();
    }
  }

  checkForNavigation() {
    // We want to force the header to be navigable when slotted in modals
    const hasModalParent = this.el.closest(`
      ${getNamespacedTagFor('market-modal-full')},
      ${getNamespacedTagFor('market-modal-partial')}
    `);

    // The same if custom navigation is slotted in
    const hasCustomNavigation = this.el.querySelector('[slot="navigation"]');

    // Only set this if the prop hasn't been passed in manually
    if (this.showNavigation === null) {
      this.showNavigation = hasModalParent || hasCustomNavigation ? true : false;
    }
  }

  cloneDefaultHeadingToCompactHeading() {
    // clear existing compact slotted content
    const compactSlottedContent = this.el.querySelectorAll('[slot="compact"]');
    for (const node of compactSlottedContent) {
      this.el.removeChild(node);
    }

    // clone the wayfinding slot into compact slot
    const wayfindingEl = this.el.querySelector('[slot="wayfinding"]');
    if (wayfindingEl) {
      const clone = wayfindingEl.cloneNode(true) as HTMLElement;
      clone.slot = 'compact';
      clone.classList.add('wayfinding');
      this.el.appendChild(clone);
    }

    // clone the rest of the default slot content into compact slot
    const defaultSlotContent = [...this.el.children].filter((el) => !el.getAttribute('slot'));
    for (const node of defaultSlotContent) {
      const clone = node.cloneNode(true) as HTMLElement;
      clone.slot = 'compact';
      this.el.appendChild(clone);
    }
  }

  renderDefaultNavigation() {
    const MarketButtonTagName = getNamespacedTagFor('market-button');
    const MarketAccessoryTagName = getNamespacedTagFor('market-accessory');

    return (
      <MarketButtonTagName
        rank="secondary"
        slot="navigation"
        aria-label={this.closeButtonAriaLabel}
        disabled={this.disableCloseButton}
        onClick={(event) => this.handleCloseButtonClick(event)}
      >
        <MarketAccessoryTagName slot="icon">
          <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.71004 18.71L12 13.41L17.29 18.71L18.71 17.29L13.41 12L18.71 6.71004L17.29 5.29004L12 10.59L6.71004 5.29004L5.29004 6.71004L10.59 12L5.29004 17.29L6.71004 18.71Z" />
          </svg>
        </MarketAccessoryTagName>
      </MarketButtonTagName>
    );
  }

  render() {
    return (
      <Host class="market-header" show-actions={this.showActions}>
        <div class="grid">
          <div class="navigation">
            <nav>
              <slot name="navigation" onSlotchange={() => this.handleSlottedNavigation()}>
                {this.renderDefaultNavigation()}
              </slot>
            </nav>
          </div>
          <div class="compact">
            <slot name="compact"></slot>
          </div>
          <div class="actions">
            <menu>
              <slot name="actions" onSlotchange={() => this.handleSlottedActions()}></slot>
            </menu>
          </div>
        </div>
        <div class="heading" part="heading">
          <slot name="wayfinding"></slot>
          <slot></slot>
        </div>
      </Host>
    );
  }
}
