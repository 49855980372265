import { getEnvironment } from './initUtils';

// App schemes for the Square Register app, i.e. SPOS
const SPOS_STAGING_APP_SCHEME = 'square-register-debug://messages';
const SPOS_PROD_APP_SCHEME = 'square-register://messages';
export const SPOS_APP_SCHEME =
  getEnvironment() === 'staging'
    ? SPOS_STAGING_APP_SCHEME
    : SPOS_PROD_APP_SCHEME;

// App schemes for the Appointments app, i.e. APOS
const APPOINTMENTS_STAGING_APP_SCHEME =
  'square-appointments-dogfood://messages';
const APPOINTMENTS_PROD_APP_SCHEME = 'square-appointments://messages';
export const APPOINTMENTS_APP_SCHEME =
  getEnvironment() === 'staging'
    ? APPOINTMENTS_STAGING_APP_SCHEME
    : APPOINTMENTS_PROD_APP_SCHEME;

// App scheme for Invoices app, staging and prod use the same scheme
export const INVOICES_APP_SCHEME = 'square-invoices://messages';

// App scheme for Retail app, staging and prod use the same scheme
export const RETAIL_APP_SCHEME = 'square-retail://messages';

// App scheme for Dashboard app
export const DASHBOARD_STAGING_APP_SCHEME =
  'square-dashboard-canary://messages';
export const DASHBOARD_PROD_APP_SCHEME = 'square-dashboard://messages';
export const DASHBOARD_APP_SCHEME =
  getEnvironment() === 'staging'
    ? DASHBOARD_STAGING_APP_SCHEME
    : DASHBOARD_PROD_APP_SCHEME;

// App scheme for Restaurant app
export const RESTAURANT_STAGING_APP_SCHEME =
  'square-restaurant-debug://messages';
export const RESTAURANT_PROD_APP_SCHEME = 'square-restaurant://messages';
export const RESTAURANT_APP_SCHEME =
  getEnvironment() === 'staging'
    ? RESTAURANT_STAGING_APP_SCHEME
    : RESTAURANT_PROD_APP_SCHEME;

export const MOBILE_APP_NAME = [
  'appointments',
  'spos',
  'invoices',
  'retail',
  'dashboard',
  'restaurant',
] as const;
export type MobileAppName = typeof MOBILE_APP_NAME[number];
export const MESSAGES_PLUS_SOURCE_TO_APP_SCHEME: Record<MobileAppName, string> =
  {
    appointments: APPOINTMENTS_APP_SCHEME,
    spos: SPOS_APP_SCHEME,
    invoices: INVOICES_APP_SCHEME,
    retail: RETAIL_APP_SCHEME,
    dashboard: DASHBOARD_APP_SCHEME,
    restaurant: RESTAURANT_APP_SCHEME,
  };
