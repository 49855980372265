import React, { ReactElement, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import { MarketButton, MarketRow } from 'src/components/Market';
import SoundIcon from 'src/svgs/SoundIcon';
import StopIcon from 'src/svgs/StopIcon';
import { Unit } from 'src/MessengerTypes';
import { onKeyDownEnter } from 'src/utils/keyboardUtils';
import './VoicemailNumberRow.scss';
import classNames from 'classnames';

export type VoicemailNumberRowProps = {
  unit: Unit;
  isPlaying: boolean;
  onPlay: () => void;
  onStop: () => void;
};

/**
 * Row displaying a number for a given unit, with options to play the current voicemail or edit the voicemail.
 *
 * @param {Unit} unit
 * The unit of the dedicated number to display in this row.
 * @param {boolean} isPlaying
 * Whether the voicemail is currently playing.
 * @param {() => void} onPlay
 * Callback executed when the voicemail is played.
 * @param {() => void} onStop
 * Callback executed when the voicemail is stopped.
 */
const VoicemailNumberRow = observer(
  ({
    unit,
    isPlaying,
    onPlay,
    onStop,
  }: VoicemailNumberRowProps): ReactElement => {
    const { subscription, settings, user, navigation, event } =
      useMessengerControllerContext();
    const { t } = useTranslation();

    const isDisabled = subscription.isSubscriptionDelinquent;
    const unitSetting = settings.allUnitSettings.get(unit.token);
    const greetingText = unitSetting?.voicemail?.customVoicemailGreeting
      ? t('VoicemailNumberRow.custom_greeting')
      : t('VoicemailNumberRow.default_greeting');

    const {
      defaultVoicemailGreeting,
      customVoicemailGreeting,
      appliesToCurrentEmployee,
    } = unitSetting?.voicemail || {};
    const defaultVoicemail = defaultVoicemailGreeting?.url
      ? settings.voicemails.get(defaultVoicemailGreeting?.url)
      : undefined;
    const customVoicemail = customVoicemailGreeting?.url
      ? settings.voicemails.get(customVoicemailGreeting?.url)
      : undefined;
    const voicemail = customVoicemail || defaultVoicemail;

    useEffect(() => {
      return () => {
        voicemail?.stop();
      };
    }, [voicemail]);

    const onSoundButtonClick = (): void => {
      if (isPlaying) {
        onStop();
        voicemail?.stop();
        return;
      }

      settings.voicemails.forEach((sound) => sound?.stop()); // Stop all other sounds before playing
      onPlay();
      voicemail?.play();
      event.track('Click Play Voicemail Greeting', { unit_token: unit.token });
    };
    const onEditVoicemailButtonClick = (): void => {
      navigation.sheet.navigateTo({
        name: 'EDIT_VOICEMAIL',
        unitToken: unit.token,
      });
      event.track('Click Edit Voicemail Greeting', { unit_token: unit.token });
    };

    const showEditVoicemailButton = appliesToCurrentEmployee !== false;

    return (
      <MarketRow
        disabled={isDisabled || undefined}
        data-testid="VoicemailNumberRow"
      >
        <label slot="label">
          {unit.subscription?.dedicatedNumber?.displayDedicatedNumber}
        </label>
        <p slot="subtext">{user.isMultiUnit ? unit.name : greetingText}</p>
        <div
          slot="trailing-accessory"
          className="VoicemailNumberRow__trailing-accessory"
        >
          <MarketButton
            size="small"
            className={classNames('VoicemailNumberRow__sound-button', {
              'VoicemailNumberRow__sound-button__with-edit':
                showEditVoicemailButton,
            })}
            onClick={onSoundButtonClick}
            onKeyDown={(e) => onKeyDownEnter(e, onSoundButtonClick)}
          >
            {isPlaying ? <StopIcon slot="icon" /> : <SoundIcon slot="icon" />}
          </MarketButton>
          {showEditVoicemailButton && (
            <MarketButton
              size="small"
              onClick={onEditVoicemailButtonClick}
              onKeyDown={(e) => onKeyDownEnter(e, onEditVoicemailButtonClick)}
            >
              {t('VoicemailNumberRow.edit_voicemail')}
            </MarketButton>
          )}
        </div>
      </MarketRow>
    );
  },
);

export default VoicemailNumberRow;
