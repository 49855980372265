import type MessengerController from 'src/MessengerController';
import SearchContactsStore from 'src/stores/SearchContactsStore';
import SearchUtterancesStore from 'src/stores/SearchUtterancesStore';
import { makeAutoObservable, reaction } from 'mobx';
import { isTranscriptMessengerPage, LoadingStatus } from 'src/MessengerTypes';
import { CompleteSessionReason } from './SearchLoggingStore';

/**
 * Store tracking both utterance and contact search results, for a given search query.
 */
class SearchStore {
  private _stores: MessengerController;

  /** Store tracking customer search results, for a given search query */
  customers: SearchContactsStore;

  /** Store tracking utterance search results, for a given search query */
  utterances: SearchUtterancesStore;

  /** Search query */
  query = '';

  constructor(stores: MessengerController) {
    makeAutoObservable(this);

    this._stores = stores;
    this.customers = new SearchContactsStore(stores, false);
    this.utterances = new SearchUtterancesStore(stores);

    reaction(
      () => this.status,
      () => this._logSearchActivity(),
    );
  }

  init = (): void => {
    this.utterances.init();
    this.customers.init(); // Don't need to await this because it skips the data fetch
  };

  /**
   * Log start/end of search session based on the current search status.
   */
  private _logSearchActivity = (): void => {
    if (this.status === 'SUCCESS') {
      // Fetch completed --> Start the session
      this._stores.searchLogger.startSession(this.query);

      // If no results, end session immediately
      if (!this.hasResults) this._stores.searchLogger.endSession('no_results');
    } else if (this.status === 'LOADING') {
      // New search fetch in progress --> End previous session and log
      // Sessions can be ended on any number of reasons (i.e. timeout, query change, etc.).
      // Those are all being handled explicitly, so theoretically this call should not
      // result in the session being logged. However, if we add another reason to end the
      // session without explicitly handling it, this will help us catch it and log to Sentry.
      this._stores.searchLogger.endSession();
    } else if (this.status === 'ERROR') {
      // Fetch failed --> End previous session and log
      if (!this._stores.searchLogger.hasSessionStartTime) {
        // First search query will not have started a session yet
        this._stores.searchLogger.startSession(this.query);
      }

      this._stores.searchLogger.endSession('fetch_failed');
    }
  };

  /**
   * Used to set the search query and kick off the debounced data fetch for both customer and utterance results
   *
   * @param {string} newQuery
   * The new query to use.
   * @param {CompleteSessionReason} [endSessionReason]
   * (Optional) Reason to end the current search session.
   */
  setQuery = (
    newQuery: string,
    endSessionReason?: CompleteSessionReason,
  ): void => {
    if (this.query === newQuery) return;
    this._stores.searchLogger.endSession(endSessionReason ?? 'query_changed');
    this._clearPrevSeekedUtterance();

    this.query = newQuery;

    this.customers.setQuery(newQuery);
    this.utterances.setQuery(newQuery);
  };

  _clearPrevSeekedUtterance = (): void => {
    const page = this._stores.navigation.navStoreForUrl.currentPage;
    if (page && isTranscriptMessengerPage(page)) {
      const transcript = this._stores.transcripts.get(page?.transcriptId || 0);
      transcript.seekUtteranceId = undefined;
    }
  };

  /**
   * Retry fetching both customer and utterance search results for the current query.
   */
  retry = (): void => {
    this.customers.retry();
    this.utterances.retry();
  };

  /**
   * Combined loading/error state for customer and utterance data fetching
   */
  get status(): LoadingStatus {
    if (
      this.customers.status === 'ERROR' ||
      this.utterances.search.status === 'ERROR'
    ) {
      return 'ERROR';
    } else if (
      this.customers.status === 'LOADING' ||
      this.utterances.search.status === 'LOADING'
    ) {
      return 'LOADING';
    } else if (
      this.customers.status === 'SUCCESS' &&
      this.utterances.search.status === 'SUCCESS'
    ) {
      return 'SUCCESS';
    }
    return 'NOT_STARTED';
  }

  get hasResults(): boolean {
    return (
      this.utterances.search.results.length > 0 ||
      this.customers.results.length > 0
    );
  }
}

export default SearchStore;
