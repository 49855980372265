/**
 * An icon representing marketing, used to render contextual events (i.e. TranscriptViewItem)
 */
import React, { ReactElement } from 'react';

function MarketingIcon(): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <title>Marketing</title>
      <path
        fill="#000"
        fillOpacity=".9"
        d="M19 5H5C3.34 5 2 6.34 2 8v8c0 1.66 1.34 3 3 3h14c1.66 0 3-1.34 3-3V8c0-1.66-1.34-3-3-3Zm-.17 2L12 11.78 5.17 7h13.66ZM19 17H5c-.55 0-1-.45-1-1V8.62l7.43 5.2a.988.988 0 0 0 1.14 0L20 8.62V16c0 .55-.45 1-1 1Z"
      />
    </svg>
  );
}

export default MarketingIcon;
