/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';
import '../roster/service';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "bills": {
        "nested": {
          "Buyer": {
            "fields": {
              "buyerToken": {
                "type": "string",
                "id": 1,
                "rule": "optional"
              },
              "readOnlyRosterPayer": {
                "type": "squareup.roster.service.PayerLookupResponse",
                "id": 2,
                "rule": "optional"
              },
              "readOnlyFullName": {
                "type": "string",
                "id": 3,
                "rule": "optional"
              },
              "readOnlyPhotoUrl": {
                "type": "string",
                "id": 4,
                "rule": "optional"
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.bills?.nested?.Buyer) {
	$root.addJSON(jsonData);
}

/**
 * Represents buyer information like name and photo_url.
 *
 * SOURCE Buyer @ squareup/bills/buyer.proto at 11:1
 */
export const Buyer = $root.lookupType('squareup.bills.Buyer');
fixType(Buyer);
Builder.createAndAttachToType(Buyer);
