import { Suggestion } from 'src/gen/squareup/messenger/v3/messenger_service';

/**
 * Get tracking metadata for suggestions (replies and actions).
 *
 * @param {Suggestion} suggestion
 */
export const getSuggestionTrackingMetadata = (
  suggestion: Suggestion,
): { suggestion_id: number; action_type_name: string } => {
  return {
    suggestion_id: suggestion.suggestionId,
    action_type_name:
      Suggestion.SuggestionType[suggestion.suggestionType].toLowerCase(),
  };
};
