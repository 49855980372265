/**
 * An icon for adding customer to directory.
 */
import React, { ReactElement } from 'react';

function AddCustomerIcon(): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <title>Add customer</title>
      <g fill="#1C1C1C" clipPath="url(#AddCustomerIcon__a)">
        <path
          fillRule="evenodd"
          d="M5 7a4 4 0 1 1 8 0 4 4 0 0 1-8 0Zm4-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4Z"
          clipRule="evenodd"
        />
        <path d="M17.997 11V8h2v3h3v2h-3v3h-2v-3h-3v-2h3ZM3 21a6 6 0 0 1 12 0h2a8 8 0 1 0-16 0h2Z" />
      </g>
      <defs>
        <clipPath id="AddCustomerIcon__a">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default AddCustomerIcon;
