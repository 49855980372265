import React, { ReactElement } from 'react';
import BaseSvgProps from 'src/types/BaseSvgProps';

/**
 * Icon representing a pause button (two parallel vertical lines).
 */
const PauseIcon = (props: BaseSvgProps): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="16"
    fill="none"
    viewBox="0 0 10 16"
    {...props}
  >
    <title>Pause</title>
    <path
      fill="#000"
      fillOpacity=".9"
      fillRule="evenodd"
      d="M10 16V0H7v16h3Zm-7 0V0H0v16h3Z"
      clipRule="evenodd"
    />
  </svg>
);

export default PauseIcon;
