/**
 * An in progress icon to show when the unit's M+ subscription is still
 * undergoing verification.
 */

import React, { ReactElement } from 'react';

function InProgressIcon(): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="96"
      height="96"
      fill="none"
      viewBox="0 0 96 96"
    >
      <title>In Progress</title>
      <defs>
        <path
          id="reuse-0"
          fill="#EAEAEA"
          d="M80 16H52a8 8 0 0 0-8 8v28a8 8 0 0 0 8 8h28a8 8 0 0 0 8-8V24a8 8 0 0 0-8-8Z"
        />
      </defs>
      <g clipPath="url(#clip0_2235_267)">
        <use xlinkHref="#reuse-0" />
        <path
          fill="#121212"
          d="M60 4H24a8 8 0 0 0-8 8v72a8 8 0 0 0 8 8h36a8 8 0 0 0 8-8V12a8 8 0 0 0-8-8Z"
        />
        <path fill="url(#pattern0)" d="M26-2h80v80H26z" />
        <use xlinkHref="#reuse-0" opacity=".2" />
        <path
          fill="#006AFF"
          d="M58 76H26a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h32a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2Z"
        />
        <path
          fill="url(#paint0_linear_2235_267)"
          d="M66 54c8.837 0 16-7.163 16-16s-7.163-16-16-16-16 7.163-16 16 7.163 16 16 16Z"
        />
        <path
          fill="#fff"
          fillOpacity=".95"
          d="m70 48 3-3.076-5.745-5.911V29H63v10.907c0 .589.234 1.134.617 1.549L70 48Z"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2235_267"
          x1="54.545"
          x2="75.4"
          y1="57.845"
          y2="21.72"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E3FF80" />
          <stop offset=".25" stopColor="#C9F136" />
          <stop offset=".65" stopColor="#006AFF" />
        </linearGradient>
        <clipPath id="clip0_2235_267">
          <path fill="#fff" d="M0 0h96v96H0z" />
        </clipPath>
        <pattern
          id="pattern0"
          width="1"
          height="1"
          patternContentUnits="objectBoundingBox"
        >
          <use xlinkHref="#image0_2235_267" transform="scale(.00625)" />
        </pattern>
      </defs>
    </svg>
  );
}

export default InProgressIcon;
