/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "sub2": {
        "nested": {
          "common": {
            "nested": {
              "SubscriptionStatus": {
                "values": {
                  "INVALID": 0,
                  "PENDING": 1,
                  "FREE_TRIAL": 2,
                  "FREE_TRIAL_PENDING_CANCELLATION": 3,
                  "FREE_TRIAL_NO_OBLIGATION": 9,
                  "NO_OBLIGATION_FREE_TRIAL_EXPIRED": 10,
                  "ACTIVE": 4,
                  "ACTIVE_PENDING_CANCELLATION": 5,
                  "ACTIVE_PENDING_PAUSE": 11,
                  "PAUSED": 12,
                  "CANCELED": 6,
                  "IN_GRACE": 8,
                  "DELINQUENT": 7,
                  "PRESALE": 13
                }
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.sub2?.nested?.common?.nested?.SubscriptionStatus) {
	$root.addJSON(jsonData);
}

/**
 * NOTE: This file exists so that basic services like Roster can reference SubscriptionStatus enum in their protos
 * without introducing cyclic BUILD dependency. Hence, be mindful of the dependencies added here.
 * SubscriptionStatus describes the various states a subscription can be in.
 *
 * SOURCE SubscriptionStatus @ squareup/sub2/common.proto at 10:1
 */
export const SubscriptionStatus = $root.lookupEnum('squareup.sub2.common.SubscriptionStatus').values;
