import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { MarketBanner } from 'src/components/Market';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';

/**
 * Banner notifying the user about the launch of the voicemail customization feature.
 */
const VoicemailCustomizationLaunchBanner = observer((): ReactElement => {
  const { t } = useTranslation();
  const { tooltip, navigation, transcriptView } =
    useMessengerControllerContext();

  const onDismiss = (): void => {
    tooltip.dismiss('VOICEMAIL_CUSTOMIZATION_LAUNCH_BANNER');
  };

  return (
    <MarketBanner
      variant="info"
      dismissable
      onMarketBannerDismissed={onDismiss}
    >
      <span slot="title">{t('VoicemailCustomizationLaunchBanner.title')}</span>
      {t('VoicemailCustomizationLaunchBanner.description')}
      <button
        slot="action"
        onClick={() => {
          navigation.openSheet({
            name: 'EDIT_VOICEMAIL',
            unitToken: transcriptView.transcript.sellerKey,
          });
          onDismiss();
        }}
      >
        {t('VoicemailCustomizationLaunchBanner.primary_button_text')}
      </button>
    </MarketBanner>
  );
});

export default VoicemailCustomizationLaunchBanner;
