import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { MarketPill } from 'src/components/Market';
import BaseSvgProps from 'src/types/BaseSvgProps';

/**
 * A pill to denote that a feature is only available with Messages Plus.
 *
 * @example
 * Basic usage:
 * <UpgradePill />
 * @param {string} [slot]
 * (Optional) A market slot property.
 * @author klim
 */
const UpgradePill = observer(
  ({ slot = 'trailing-accessory' }: BaseSvgProps): ReactElement => {
    const { t } = useTranslation();

    return (
      <MarketPill className="UpgradePill" variant="emphasis" slot={slot}>
        {t('MessagesPlus.upgrade')}
      </MarketPill>
    );
  },
);

export default UpgradePill;
