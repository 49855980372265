import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { MarketBanner } from 'src/components/Market';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import { getSearchCustomerInDirectoryLink } from 'src/utils/url';
import './MergeCustomersBanner.scss';

type MergeCustomersBannerProps = {
  contactId: string;
};

/**
 * Banner that notifies the user that they have duplicate entries for a given contact ID
 * in the customer directory.
 *
 * @param {string} contactId
 * The contact ID that there are multiple entries for.
 */
const MergeCustomersBanner = observer(
  ({ contactId }: MergeCustomersBannerProps): ReactElement => {
    const { tooltip, event } = useMessengerControllerContext();
    const { t } = useTranslation();

    const onDismiss = (): void => {
      tooltip.dismiss('MERGE_CUSTOMERS');
    };

    return (
      <MarketBanner
        variant="info"
        dismissable
        onMarketBannerDismissed={onDismiss}
        className="MergeCustomersBanner__banner"
      >
        {t('MergeCustomersBanner.text')}
        <a
          href={getSearchCustomerInDirectoryLink(contactId)}
          onClick={() => {
            event.track('Click Merge Customer Button');
            onDismiss();
          }}
          target="_blank"
          slot="action"
          rel="noreferrer"
          data-testid="MergeCustomersBanner__button"
        >
          {t('MergeCustomersBanner.button_text')}
        </a>
      </MarketBanner>
    );
  },
);

export default MergeCustomersBanner;
