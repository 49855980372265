import React, { ReactElement, useEffect } from 'react';
import { observer } from 'mobx-react';
import { MarketAccessory, MarketList, MarketRow } from 'src/components/Market';
import { useTranslation } from 'react-i18next';
import ReviewSetupModal from 'src/pages/SettingsPage/components/ReviewSetupModal/ReviewSetupModal';
import { getShadowRoot } from 'src/utils/shadowDomUtils';
import LocationPinIcon from 'src/svgs/LocationPinIcon';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import './UnitSettings.scss';
import LoadingIndicator from 'src/components/LoadingIndicator/LoadingIndicator';

const UNIT_SETTING_ROW_ID_PREFIX = 'UnitSetting__';

/**
 * The section to control unit settings within SettingsPage.
 */
const UnitSettings = observer((): ReactElement => {
  const { modal, user, event, settings } = useMessengerControllerContext();
  const { track } = event;
  const { t } = useTranslation();

  useEffect(() => {
    if (settings.googleReviewsAddressesStatus === 'NOT_STARTED') {
      settings.fetchGoogleReviewsAddresses();
    }
  }, [settings.googleReviewsAddressesStatus, settings]);

  // When the review setup modal is opened, we scroll the settings page to
  // show the corresponding row for that unit.
  useEffect(() => {
    if (modal.currentModal === 'REVIEW_SETUP') {
      const element = getShadowRoot()?.getElementById(
        `${UNIT_SETTING_ROW_ID_PREFIX}${modal.reviewSetupUnitSetting?.unitToken}`,
      );
      element?.scrollIntoView({
        block: 'start',
        inline: 'nearest',
        behavior: 'smooth',
      });
    }
    // TODO (#5429): re-enable eslint rule in the next line, or remove this TODO
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal.currentModal]);

  const content = (
    <>
      <h3>{t('SettingsPage.reviews.title')}</h3>
      <p className="paragraph-30">{t('SettingsPage.reviews.description')}</p>
      {settings.googleReviewsAddressesStatus === 'LOADING' ? (
        <LoadingIndicator isSmall className="UnitSettings__loading-indicator" />
      ) : (
        <MarketList transient interactive>
          {[...settings.allUnitSettings.values()].map((unit) => {
            const { unitToken, placeId, address } = unit;
            if (unitToken) {
              const name = user.units.get(unitToken)?.name;
              const isConfigured = placeId !== '';

              return (
                <MarketRow
                  id={`${UNIT_SETTING_ROW_ID_PREFIX}${unitToken}`}
                  key={unitToken}
                  onClick={() => {
                    modal.openReviewSetupModal(unit);
                    track('View Review Setup Modal', { unitToken });
                  }}
                >
                  <MarketAccessory
                    className="UnitSettings__icon"
                    slot="leading-accessory"
                    size="image"
                  >
                    <LocationPinIcon
                      color={!isConfigured ? 'rgba(0,0,0,0.3)' : undefined}
                    />
                  </MarketAccessory>
                  <label
                    slot="label"
                    className={
                      !isConfigured ? 'UnitSettings__not-configured__label' : ''
                    }
                  >
                    {name}
                  </label>
                  <p slot="subtext" className="UnitSettings__address">
                    {address}
                  </p>
                  <label slot="side-label">
                    {isConfigured ? (
                      <span className="UnitSettings__edit">
                        {t('SettingsPage.reviews.edit')}
                      </span>
                    ) : (
                      <span className="UnitSettings__connect">
                        {t('SettingsPage.reviews.connect')}
                      </span>
                    )}
                  </label>
                </MarketRow>
              );
            }
            return null;
          })}
        </MarketList>
      )}
    </>
  );

  const reviewSetupModal = modal.currentModal === 'REVIEW_SETUP' && (
    <ReviewSetupModal />
  );
  return (
    <>
      {content}
      {reviewSetupModal}
    </>
  );
});

export default UnitSettings;
