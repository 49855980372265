import Services from 'src/services/Services';
import {
  CurrentUser,
  SubunitEntity,
  SubunitsResponse,
  SupportedCountry,
  Unit,
} from 'src/MessengerTypes';
import {
  ExecuteGraphQLRequest,
  ExecuteGraphQLResponse,
  Status,
} from 'src/gen/squareup/messenger/v3/messenger_service';
import { callAuxiliaryRpc, callV3Rpc } from 'src/utils/apiUtils';
import { throwError } from 'src/utils/handleResponseStatus';
import {
  MessagesAuthenticationError,
  MessagesAuthorizationError,
} from 'src/types/Errors';
import Logger from 'src/Logger';
import { UNITS_URL } from 'src/utils/url';
import { MerchantCompletedIdvRequest } from 'src/gen/squareup/messenger/v3/messenger_auxiliary_service';
import { isAuthError } from 'src/utils/transcriptUtils';

/**
 * Api responsible for retrieving and updating any merchant specific data.
 */
class MerchantApi {
  private _services: Services;

  constructor(services: Services) {
    this._services = services;
  }

  /**
   * Retrieves the current user's IDV completion status.
   * This is needed before users can send messages or sign up for subscriptions.
   *
   * @returns {Promise<boolean | null>}
   * Whether or not the user has completed IDV. If null, an error occurred.
   */
  getIdvCompletionStatus = async (): Promise<boolean | null> => {
    try {
      const { completedIdv } = await callAuxiliaryRpc({
        name: 'MerchantCompletedIdv',
        rpc: (x) => this._services.messagesAuxiliary.merchantCompletedIdv(x),
        request: MerchantCompletedIdvRequest.create(),
      });
      return completedIdv;
    } catch (error) {
      if (!isAuthError(error, true)) {
        Logger.logWithSentry(
          'MerchantApi:getIdvCompletionStatus - failed to retrieve IDV completion status',
          'error',
          { error },
        );
      }
      return null;
    }
  };

  /**
   * Retrieves metadata about the current user.
   *
   * @returns {Promise<CurrentUser>} - Metadata about the current user.
   */
  getCurrentUserData = async (): Promise<CurrentUser> => {
    const request = ExecuteGraphQLRequest.create({
      query: `
        {
          roster {
            employee {
              token
              isAccountOwner
              canManageSubscriptions
              canManageOnlineCheckout
            }
            merchant {
              token
              mainUnitToken
              countryCode
              currencyCode
              businessName
            }
          }
        }
      `,
    });

    let response: ExecuteGraphQLResponse;
    try {
      response = await callV3Rpc({
        name: 'ExecuteGraphQL - roster',
        rpc: (x) => this._services.messagesV3.executeGraphQL(x),
        request,
      });
    } catch (error) {
      if (
        error instanceof MessagesAuthenticationError ||
        error instanceof MessagesAuthorizationError
      ) {
        // Error is already transformed into an auth error; no need to look at response.
        // This happens when we get a 401 or 403 from Envoy SAFE.
        throw error;
      }

      const code = (error as ExecuteGraphQLResponse)?.status?.code;
      if (code === Status.Code.UNAUTHORIZED) {
        throw new MessagesAuthenticationError('User is not authenticated.');
      }
      if (code === Status.Code.FORBIDDEN) {
        throw new MessagesAuthorizationError('User missing permissions.');
      }
      return throwError(
        'MerchantApi:getCurrentUserData - Error occurred calling GraphQL.',
        {
          request,
          error,
        },
      );
    }

    if (response != null && response.response != null) {
      let graphql;
      try {
        graphql = JSON.parse(response.response);
      } catch (error) {
        return throwError(
          'MerchantApi:getCurrentUserData - Error parsing JSON response from GraphQL call.',
          {
            request,
            response,
            error,
          },
        );
      }

      if (
        !graphql.data ||
        !graphql.data.roster ||
        !graphql.data.roster.merchant ||
        !graphql.data.roster.employee
      ) {
        return throwError(
          'MerchantApi:getCurrentUserData - Response unexpectedly missing data.',
          {
            request,
            responseJson: graphql,
          },
        );
      }

      const { merchant, employee } = graphql.data.roster;
      return {
        merchantToken: merchant.token,
        mainUnitToken: merchant.mainUnitToken,
        countryCode: merchant.countryCode as SupportedCountry,
        currencyCode: merchant.currencyCode,
        businessName: merchant.businessName,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        currentEmployee: {
          employeeToken: employee.token,
          isOwner: employee.isAccountOwner,
          canManageSubscriptions: employee.canManageSubscriptions,
          canManageOnlineCheckout: employee.canManageOnlineCheckout,
        },
      };
    }

    return throwError(
      'MerchantApi:getCurrentUserData - Response returned unexpectedly empty.',
      {
        request,
        response,
      },
    );
  };

  /**
   * Get all unit tokens and names for this merchant.
   * The response of this call can be found here:
   * https://git.sqcorp.co/projects/SQ/repos/web/browse/app/identities/controllers/api/v2/merchant_subunits_controller.rb#81
   *
   * @returns {Promise<Unit[]>} - An array of unit information
   */
  getUnits = async (): Promise<Unit[]> => {
    const response: SubunitsResponse = await this._services.fetchParsedJson(
      UNITS_URL,
    );
    if ((response as unknown as Response)?.status >= 500) {
      return throwError('MerchantApi:getUnits - Server error', {
        response,
      });
    }
    if (
      (response as unknown as Response)?.status === 401 ||
      response.message === 'UnauthorizedError'
    ) {
      throw new MessagesAuthenticationError(
        'MerchantApi:getUnits - User is unauthenticated',
      );
    }
    if (response.success === false) {
      return throwError('MerchantApi:getUnits - Error fetching subunits', {
        response,
      });
    }
    if (!response.entities) {
      return throwError(
        'MerchantApi:getUnits - response entities is undefined',
        {
          response,
        },
      );
    }

    const units: Unit[] = [];
    response.entities
      .sort((a, b) => a.nickname.localeCompare(b.nickname))
      .forEach((unit: SubunitEntity) => {
        const { token, nickname, merchant_profile, unit_active } = unit;
        let addressParts = [
          merchant_profile.street1,
          merchant_profile.street2,
          merchant_profile.city,
          merchant_profile.state,
          merchant_profile.postal_code,
          merchant_profile.country_code,
        ];
        addressParts = addressParts.filter((value) => value !== undefined);
        const address = addressParts.join(' ');

        units.push({
          token,
          name: nickname,
          address,
          isActive: unit_active,
          businessName: merchant_profile.name ?? '',
        });
      });

    return units;
  };
}

export default MerchantApi;
