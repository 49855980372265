export enum Status {
  /**
   * DO NOT USE
   */
  UNKNOWN = 'UNKNOWN',
  /**
   * The request was successful
   */
  SUCCESS = 'SUCCESS',
  /**
   * There was an error with Optimizely
   */
  SDK_ERROR = 'SDK_ERROR',
  /**
   * The request for the experiment was invalid in some way
   */
  INVALID_EXPERIMENT_REQUEST = 'INVALID_EXPERIMENT_REQUEST',
  /**
   * The experiment was not found in Optimizely
   */
  EXPERIMENT_NOT_FOUND = 'EXPERIMENT_NOT_FOUND',
  /**
   * Variant not in Optimizely experiment
   */
  VARIANT_NAME_MISMATCH = 'VARIANT_NAME_MISMATCH',
  /**
   * Impression Limit Exceeded
   */
  IMPRESSION_LIMIT_EXCEEDED = 'IMPRESSION_LIMIT_EXCEEDED',
  /**
   * User not in experiment
   */
  USER_NOT_IN_EXPERIMENT = 'USER_NOT_IN_EXPERIMENT',
}
