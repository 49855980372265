/**
 * A small x button to remove file from input.
 */

import React, { ReactElement } from 'react';

function RemoveFileIcon(): ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none">
      <title>Remove</title>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M0 9a9 9 0 1 1 18.001.001A9 9 0 0 1 0 9Zm2 0c0 3.86 3.14 7 7 7s7-3.14 7-7-3.14-7-7-7-7 3.14-7 7Zm7-1.41 2.29-2.3 1.42 1.42L10.41 9l2.3 2.29-1.42 1.42L9 10.42l-2.29 2.29-1.42-1.42L7.59 9l-2.3-2.29 1.42-1.42L9 7.59Z"
        clipRule="evenodd"
      />
      <path
        fill="#000"
        fillOpacity=".42"
        fillRule="evenodd"
        d="M0 9a9 9 0 1 1 18.001.001A9 9 0 0 1 0 9Zm2 0c0 3.86 3.14 7 7 7s7-3.14 7-7-3.14-7-7-7-7 3.14-7 7Zm7-1.41 2.29-2.3 1.42 1.42L10.41 9l2.3 2.29-1.42 1.42L9 10.42l-2.29 2.29-1.42-1.42L7.59 9l-2.3-2.29 1.42-1.42L9 7.59Z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default RemoveFileIcon;
