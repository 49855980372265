@import "../../styles/mixins/component-base.css";

:host {
  display: contents;

  & > ol {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    list-style: none;
  }
}
