import React, { ReactElement, useState, useEffect, ReactNode } from 'react';
import { observer } from 'mobx-react';
import './StatusScreen.scss';
import { MarketButton } from 'src/components/Market';

export type StatusScreenProps = {
  /**
   * Informational content that describes what happened.
   */
  children: ReactNode;
  /**
   * If present, show a blue button. The action prop must be provided as well.
   */
  actionLabel?: string;
  /**
   * An optional function that is called when actionLabel is present
   */
  action?: (() => void) | (() => Promise<void>) | null;
};

/**
 * A page will fill up with this component if the primary page content:
 * 1) Completely failed to load. This is a major error (e.g. the entire conversations
 * list failed to load), not a smaller error (e.g. a subsequent page of
 * the conversations list failed to load).
 * 2) Null state. There is nothing to show (e.g. the conversations list is empty)
 *
 * @example
 * Basic usage:
 * <StatusScreen>Failed to load</StatusScreen>
 *
 * With optional:
 *  <StatusScreen
 *   actionLabel="Retry"
 *   action={() => doRetry()}
 *  >
 *    Failed to load
 *  </StatusScreen>
 * @param {ReactNode} children
 * The main content of the status screen, usually a string but can be any React component.
 * @param {string} [actionLabel]
 * (Optional) If present, shows a SqButton with this label. The action prop then must be provided.
 * @param {() => void | () => Promise<void> | null} [action]
 * (Optional) A function to call when clicking the SqButton
 * if provided.
 * @author eblaine
 */
const StatusScreen = observer((props: StatusScreenProps): ReactElement => {
  const { children, action, actionLabel } = props;
  const [isLoading, setIsLoading] = useState(false);

  const actionWrapper =
    action == null
      ? null
      : () => {
          setIsLoading(true);
          const result = action();
          // check if it is a promise
          if (result?.then) {
            result.finally(() => {
              if (isLoading) {
                // useEffect cleanup may race with this finally()
                setIsLoading(false);
              }
            });
          }
        };

  /**
   * Ensure isLoading is false when the component unmounts. This prevents
   * "cannot call setState() on unmounted component" warnings in the
   * action().finally() above.
   */
  useEffect(() => {
    return () => {
      setIsLoading(false);
    };
  });

  return (
    <div className="StatusScreen">
      <div
        className="StatusScreen__message"
        data-testid="StatusScreen__message"
      >
        {children}
      </div>
      {actionLabel && actionWrapper ? (
        <MarketButton
          onClick={actionWrapper}
          rank="primary"
          isLoading={isLoading || undefined}
        >
          {actionLabel}
        </MarketButton>
      ) : null}
    </div>
  );
});

export default StatusScreen;
