import React, { ReactElement, ReactNode, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import MessengerContent from 'src/components/MessengerContent/MessengerContent';
import { MessengerPageName, Transition } from 'src/MessengerTypes';
import MessengerEmptyState from 'src/components/MessengerEmptyState/MessengerEmptyState';
import MessengerEmptyAssistantState from 'src/components/MessengerEmptyAssistantState/MessengerEmptyAssistantState';
import TranscriptsListSkeletonState from 'src/components/TranscriptsListSkeletonState/TranscriptsListSkeletonState';
import { KEY_MESSAGES_PLUS } from 'src/stores/FeatureFlagStore';
import TranscriptsListStore from 'src/stores/TranscriptsListStore';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import AssistantComponent from './components/AssistantComponent/AssistantComponent';
import OutageBanner from './components/OutageBanner/OutageBanner';
import TranscriptsBucketList from './components/TranscriptsBucketList/TranscriptsBucketList';
import InGraceSubscriptionBanner from 'src/components/InGraceSubscriptionBanner/InGraceSubscriptionBanner';
import DelinquentSubscriptionBanner from 'src/components/DelinquentSubscriptionBanner/DelinquentSubscriptionBanner';
import MessagesPlusExpiringBanner from 'src/components/MessagesPlusExpiringBanner/MessagesPlusExpiringBanner';
import VerificationFailedRetryableBanner from 'src/pages/TranscriptViewPage/components/VerificationFailedRetryableBanner/VerificationFailedRetryableBanner';
import './TranscriptsListPage.scss';

export type TranscriptsListPageProps = {
  id: MessengerPageName;
  transcriptsList: TranscriptsListStore;
  transitionDirection: Transition;
  isAssistantPage?: boolean;
};

/**
 * Top level UI component for the Transcripts List Page. It includes the
 * list of transcripts and the "new message" button.
 *
 * @example
 * <TranscriptsListPage id="TRANSCRIPTS_LIST"  transcriptsList={transcriptsList} />
 * @param {MessengerPageName} id
 * The associated page ID.
 * @param {TranscriptsListStore} transcriptsList
 * The list of transcripts to display.
 * @param {Transition} transitionDirection
 * Determines which direction the content slides in from.
 * If true, the content transitions from the right edge
 * of the drawer into the view. Else, transition from
 * the left edge of the drawer. The logic is handled in
 * App.tsx.
 * @param {boolean} [isAssistantPage]
 * If true, only show transcripts handled by assistant.
 */
const TranscriptsListPage = observer(
  ({
    id,
    transitionDirection,
    isAssistantPage,
    transcriptsList,
  }: TranscriptsListPageProps): ReactElement => {
    const { navigation, event, tooltip, featureFlag, status, subscription } =
      useMessengerControllerContext();
    const { t } = useTranslation();

    useEffect(() => {
      if (transcriptsList.status === 'ERROR') {
        status.set({
          action: { action: transcriptsList.init },
          actionLabel: t('StatusScreen.retry'),
          display: 'SCREEN',
          label: t('TranscriptsListPage.error_loading_first'),
          type: 'ERROR',
          scope: 'BLADE',
        });
        // Clean up function to clear status if we navigate away from the list page
        return () => {
          status.clear();
        };
      }
      return undefined;
      // TODO (#5429): re-enable eslint rule in the next line, or remove this TODO
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transcriptsList.status]);

    useEffect(() => {
      // NOTE[eblaine, 4/28/22]: The product team is interested in doing a tutorial for
      // new Messages users and would like to know how often users are shown an empty (main)
      // transcripts list.
      if (
        transcriptsList.status === 'SUCCESS' &&
        transcriptsList.size === 0 &&
        !isAssistantPage
      ) {
        event.track('View Conversations List Null State');
      }
      // TODO (#5429): re-enable eslint rule in the next line, or remove this TODO
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transcriptsList.status, isAssistantPage]);

    let content: ReactNode;
    if (transcriptsList.size === 0) {
      // Case: Set up null state if the list is empty
      if (isAssistantPage) {
        content = (
          <div className="TranscriptsListPage__empty-state">
            <div className="TranscriptsListPage__empty-state__container">
              <MessengerEmptyAssistantState track={event.track} />
            </div>
          </div>
        );
      } else if (navigation.secondary.isOpen) {
        // Empty state shown separately for Transcripts List Full Page
        content = <div className="TranscriptsListPage__full-page-empty" />;
      } else {
        content = (
          <div className="TranscriptsListPage__empty-state">
            <div className="TranscriptsListPage__empty-state__container">
              <MessengerEmptyState />
            </div>
          </div>
        );
      }
    } else {
      // Case: We have transcripts (i.e. should show the transcripts list)
      content = (
        <TranscriptsBucketList id={id} transcriptsList={transcriptsList} />
      );
    }

    return (
      <MessengerContent
        transitionDirection={transitionDirection}
        isLoading={transcriptsList.status === 'LOADING'}
        loadingIndicator={<TranscriptsListSkeletonState />}
        status={status.value}
      >
        <div
          className={classNames('TranscriptsListPage__content', {
            'TranscriptsListPage__transcripts-list': !isAssistantPage,
          })}
          data-testid="TranscriptsListPage"
        >
          <OutageBanner />
          {featureFlag.get(KEY_MESSAGES_PLUS) &&
            subscription.unitTokensFailedRetryable.length > 0 &&
            !navigation.secondary.isOpen &&
            transcriptsList.size > 0 && (
              <VerificationFailedRetryableBanner
                unitTokens={subscription.unitTokensFailedRetryable}
              />
            )}
          {featureFlag.get(KEY_MESSAGES_PLUS) &&
            subscription.inGraceWithActiveSubscription &&
            tooltip.isVisible('SUBSCRIPTION_IN_GRACE_BANNER') && (
              <InGraceSubscriptionBanner
                onClick={() => {
                  event.track('Click Update Payment', {
                    referral_page_name: 'conversations_list',
                  });
                }}
              />
            )}
          {featureFlag.get(KEY_MESSAGES_PLUS) &&
            subscription.isSubscriptionDelinquent &&
            tooltip.isVisible('SUBSCRIPTION_DELINQUENT_BANNER') && (
              <DelinquentSubscriptionBanner
                onClick={() => {
                  event.track('Click Resubscribe', {
                    referral_page_name: 'conversations_list',
                  });
                }}
              />
            )}
          <MessagesPlusExpiringBanner />
          <AssistantComponent isAssistantPage={Boolean(isAssistantPage)} />
          {content}
        </div>
      </MessengerContent>
    );
  },
);

export default TranscriptsListPage;
