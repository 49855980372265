/**
 * An icon of 3 dots to indicate more options. An example use is in
 * the TranscriptViewPage as the right button on the header.
 */
import React, { ReactElement } from 'react';
import BaseSvgProps from 'src/types/BaseSvgProps';

function MoreIcon(props: BaseSvgProps): ReactElement {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>More</title>
      <path
        d="M4 2C4 3.10455 3.10461 4 2 4C0.895386 4 0 3.10455 0 2C0 0.895447 0.895386 0 2 0C3.10461 0 4 0.895447 4 2Z"
        fill="#343B42"
      />
      <path
        d="M12 2C12 3.10455 11.1046 4 10 4C8.89539 4 8 3.10455 8 2C8 0.895447 8.89539 0 10 0C11.1046 0 12 0.895447 12 2Z"
        fill="#343B42"
      />
      <path
        d="M18 4C19.1046 4 20 3.10455 20 2C20 0.895447 19.1046 0 18 0C16.8954 0 16 0.895447 16 2C16 3.10455 16.8954 4 18 4Z"
        fill="#343B42"
      />
    </svg>
  );
}

export default MoreIcon;
