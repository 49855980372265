import React, { ReactElement, useEffect } from 'react';
import { observer } from 'mobx-react';
import { MarketBanner } from 'src/components/Market';
import { useTranslation } from 'react-i18next';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import { onKeyDownEnter } from 'src/utils/keyboardUtils';

export type VerificationFailedRetryableBannerProps = {
  unitTokens: string[];
};

/**
 * Banner alerting the user that their verification failed (under M+ v2) and that they can retry.
 *
 * @param {string[]} unitTokens
 * Unit tokens that failed verification.
 * If only one unit is provided, the banner will link to the verification form for that unit.
 * Otherwise, the banner will link to the table of all units requiring more information.
 */
const VerificationFailedRetryableBanner = observer(
  ({ unitTokens }: VerificationFailedRetryableBannerProps): ReactElement => {
    const { t } = useTranslation();
    const { navigation, event } = useMessengerControllerContext();

    useEffect(() => {
      event.track('View Verification Failed Retryable Banner');
    }, [event]);

    const onClick = (): void => {
      event.track('Click Verification Failed Retryable Banner');
      navigation.openUnitVerificationView(unitTokens);
    };

    return (
      <MarketBanner
        variant="critical"
        data-testid="VerificationFailedRetryableBanner"
      >
        <span slot="title">{t('VerificationFailedRetryableBanner.title')}</span>
        {t('VerificationFailedRetryableBanner.description')}
        <button
          slot="action"
          onClick={onClick}
          onKeyDown={(e) => onKeyDownEnter(e, onClick)}
        >
          {t('VerificationFailedRetryableBanner.button_text')}
        </button>
      </MarketBanner>
    );
  },
);

export default VerificationFailedRetryableBanner;
