import React, { ReactElement, ReactNode } from 'react';
import { observer } from 'mobx-react';
import { Utterance } from 'src/gen/squareup/messenger/v3/messenger_service';
import './UtteranceBubble.scss';
import { MarketContentCard } from 'src/components/Market';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import classNames from 'classnames';

const SPEAKER_TYPE_TO_CLASS_NAME: Record<Utterance.SpeakerType, string> = {
  [Utterance.SpeakerType.CUSTOMER]: 'customer',
  [Utterance.SpeakerType.MERCHANT]: 'merchant',
  [Utterance.SpeakerType.SERVICE]: 'other',
  [Utterance.SpeakerType.BOT]: 'other',
  [Utterance.SpeakerType.SPEAKER_TYPE_UNRECOGNIZED]: '',
};

export type UtteranceBubbleProps = {
  speakerType: Utterance.SpeakerType;
  hasError?: boolean;
  children?: ReactNode;
  id?: number;
};

/**
 * Component that renders the styled bubble around an utterance in the conversation view.
 *
 * @example <UtteranceBubble speakerType={Utterance.SpeakerType.MERCHANT}><p>Some text</p></UtteranceBubble>
 * @param {Utterance.SpeakerType} speakerType
 * The speaker type associated with the utterance.
 * @param {boolean} [hasError]
 * Flag indicating whether the bubble should appear in the error state.
 * @param {ReactNode} [children]
 * The child components to render inside the bubble.
 * @param {number} [id]
 * The utteranceId of the utterance.
 * @returns {ReactElement}
 * @author wdetlor
 */
const UtteranceBubble = observer(
  ({
    speakerType,
    hasError,
    children,
    id,
  }: UtteranceBubbleProps): ReactElement => {
    const { transcriptView } = useMessengerControllerContext();
    const {
      transcript: { seekUtteranceId },
    } = transcriptView;
    const errorClassName = hasError ? ' UtteranceBubble__error' : '';
    const speakerTypeClassName = SPEAKER_TYPE_TO_CLASS_NAME[speakerType];
    const isSelectedUtterance = id !== undefined && id === seekUtteranceId;

    if (!speakerType) {
      return <></>;
    }

    return (
      <MarketContentCard
        className={classNames(
          `UtteranceBubble UtteranceBubble__${speakerTypeClassName}${errorClassName}`,
          {
            'UtteranceBubble__with-overlay': isSelectedUtterance,
          },
        )}
        data-testid="UtteranceBubble"
      >
        {isSelectedUtterance ? (
          <MarketContentCard className="UtteranceBubble UtteranceBubble__overlay">
            {children}
          </MarketContentCard>
        ) : (
          children
        )}
      </MarketContentCard>
    );
  },
);

export default UtteranceBubble;
