/**
 * An icon of a paper and pen, symbolizing composing a new message.
 */

import React, { ReactElement } from 'react';
import BaseSvgProps from 'src/types/BaseSvgProps';

const ComposeIcon = (props: BaseSvgProps): ReactElement => (
  <svg
    width="21"
    height="21"
    fill="none"
    viewBox="0 0 21 21"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>Compose</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.144 0c.728 0 1.466.283 2.022.839a2.867 2.867 0 0 1 0 4.043L9.044 16H5v-4.043L16.122.839A2.866 2.866 0 0 1 18.144 0Zm.01 2.021a.838.838 0 0 0-.597.243l-.586.607 1.183 1.182.596-.596a.852.852 0 0 0 .243-.597.795.795 0 0 0-.243-.596.853.853 0 0 0-.596-.243ZM7.022 13.978h1.183l8.503-8.5-1.183-1.182-8.503 8.5v1.183Z"
    />
    <path d="M4 3a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9l2-2v10a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V5a4 4 0 0 1 4-4h10l-2 2H4Z" />
  </svg>
);

export default ComposeIcon;
