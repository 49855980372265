import { makeAutoObservable } from 'mobx';
import type MessengerController from 'src/MessengerController';
import Api from 'src/api/Api';
import { Medium } from 'src/gen/squareup/messenger/v3/messenger_service';
import { getPrimaryContactMethod } from 'src/utils/transcriptUtils';
import { Contact } from 'src/gen/squareup/messenger/v3/messenger_auxiliary_service';
import Customer from 'src/stores/objects/Customer';

/**
 * Store containing all the cached customer metadata across the life of the application.
 */
class CustomersStore {
  private _stores: MessengerController;
  private _api: Api;

  // Mapping of customer token to customer object
  private _customers = new Map<string, Customer>();

  constructor(stores: MessengerController) {
    makeAutoObservable(this);

    this._stores = stores;
    this._api = stores.api;
  }

  loadCustomers = async (customerTokens: string[]): Promise<void> => {
    const newCustomerTokens = customerTokens.filter(
      (token) =>
        !this._customers.has(token) ||
        this._customers.get(token)?.contact === undefined,
    );

    if (newCustomerTokens.length === 0) {
      // If there are no new customer tokens, return early to prevent an unnecessary API call
      return;
    }

    const contacts = await this._api.contacts.getContacts(newCustomerTokens);

    contacts.forEach((contact: Contact) => {
      const customer = this.get(contact.token);
      customer.setContact(contact);
    });
  };

  /**
   * Returns the customer object for a given customer token. If the customer object does not exist, it is created.
   *
   * @param {string} customerToken
   * The token of the customer to retrieve.
   */
  get(customerToken: string): Customer {
    if (this._customers.has(customerToken)) {
      return this._customers.get(customerToken) as Customer;
    }
    const customer = new Customer(this._stores, customerToken);
    this._customers.set(customerToken, customer);
    return customer;
  }

  /**
   * Get all the primary contact methods of a specific medium based on a list of customer tokens.
   *
   * @param {string[]} customerTokens
   * @param {Medium} medium
   */
  getAllContactMethods(
    customerTokens: string[],
    medium: Medium,
  ): Contact.ContactMethod[] {
    const contactMethods: Contact.ContactMethod[] = [];

    // Get all primary email contacts
    customerTokens.forEach((customerToken) => {
      const { contact } = this.get(customerToken);
      const contactMethod = contact && getPrimaryContactMethod(contact, medium);
      if (contactMethod) contactMethods.push(contactMethod);
    });

    // Deduplicate email contacts
    return contactMethods.filter(
      (contact, index) =>
        index ===
        contactMethods.findIndex(
          (otherContact) =>
            otherContact.contactHandle === contact.contactHandle,
        ),
    );
  }

  /**
   * Clears the transcripts history for every customer in the store.
   * Expected to be called when a subscription change occurs and the application state needs to be reset.
   */
  clearAllHistory = (): void => {
    this._customers.forEach((customer) => customer.history.clear());
  };
}

export default CustomersStore;
