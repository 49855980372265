import React, { ReactElement } from 'react';
import BaseSvgProps from 'src/types/BaseSvgProps';

/**
 * An icon used to represent sound.
 */
const SoundIcon = (props: BaseSvgProps): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="16"
    fill="none"
    viewBox="0 0 20 16"
    {...props}
  >
    <title>Sound</title>
    <path
      fill="#000"
      fillOpacity=".9"
      fillRule="evenodd"
      d="m16.76 0-1.48 1.45c3.53 3.61 3.53 9.48 0 13.09L16.76 16c4.32-4.41 4.32-11.59 0-16ZM13.6 3.23l-1.48 1.45c1.79 1.83 1.79 4.8 0 6.63l1.48 1.45c2.57-2.62 2.57-6.9 0-9.53ZM7 4.83v6.34L5.42 9.59 4.83 9H2V7h2.83l.58-.59L7 4.83ZM4 11l5 5V0L4 5H0v6h4Z"
      clipRule="evenodd"
    />
  </svg>
);

export default SoundIcon;
