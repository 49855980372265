import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import {
  ConsentStatus,
  Medium,
  Utterance,
} from 'src/gen/squareup/messenger/v3/messenger_service';
import { LocalUtterance } from 'src/MessengerTypes';
import Logger from 'src/Logger';
import {
  hasConsentToTypeMessage,
  hasFullGrantedConsent,
} from 'src/utils/transcriptUtils';
import { useTranslation } from 'react-i18next';
import 'src/pages/TranscriptViewPage/components/Utterance/Utterance.scss';
import { onKeyDownEnter } from 'src/utils/keyboardUtils';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import { toJS } from 'mobx';

export type MerchantUtteranceSendStatusProps = {
  localUtterance: LocalUtterance;
  showSentStatus?: boolean;
};

/**
 * Given a merchant utterance, render the send status of it. A coupon will also render
 * this by using its attached utterance. The send status is responsible for
 * allowing failed utterance to be retried. If this utterance is failed and the
 * associated transcript does not have consent to retry, then this component should
 * allow consent to be requested.
 *
 * @example
 * Basic usage:
 * <MerchantUtteranceSendStatus
 *   utterance={ ... }
 * />
 *
 * With optional:
 * <MerchantUtteranceSendStatus
 *   utterance={ ... }
 *   showSentStatus
 * />
 * @param {LocalUtterance} utterance
 * The utterance we want to compute the send status from.
 * @param {boolean} [showSendStatus]
 * (Optional) If true, show the send status of this event if the status is SENT.
 * Generally, only the last item in the list with SENT will show the status.
 * @author klim
 */
const MerchantUtteranceSendStatus = observer(
  ({
    localUtterance,
    showSentStatus,
  }: MerchantUtteranceSendStatusProps): ReactElement => {
    const { utterance, messageDestination, externalAttachments } =
      localUtterance;
    const { t } = useTranslation();
    const { transcriptView, modal, api, event } =
      useMessengerControllerContext();
    const { openRequestConsentModal, openPaymentAlternativesModal } = modal;
    const { transcript } = transcriptView;
    const { id, consentStatus, medium, sellerKey, lastViewItemUtteranceId } =
      transcript;

    /**
     * Set up function to retry a failed message.
     */
    const retrySendMessage = (): void => {
      // Track for user reliability metrics, intended to happen
      // right after retry send is initiated, and before consent,
      // photo uploads, etc. happens
      event.track('Start Outbound Merchant Message', {
        client_id: utterance.metadata?.clientId,
        transcript_id: id,
        unit_token: sellerKey,
        context_utterance_id: lastViewItemUtteranceId ?? undefined,
      });

      const hasText = utterance.plainText !== '';
      const hasPhotos =
        utterance.attachments && utterance.attachments.length > 0;
      const hasExternalAttachments =
        externalAttachments && externalAttachments.length > 0;
      if (hasText || hasPhotos || hasExternalAttachments) {
        if (!messageDestination && medium !== Medium.MEDIUM_UNRECOGNIZED) {
          // Case: Utterance previously denied by AI
          api.transcripts.softDeleteUtterance(utterance.id as number);
        }
        transcriptView
          .confirmConsent()
          .then((isConsentConfirmed) => {
            // `utterance` and `externalAttachments` come from `Transcript.viewItems`
            // which can be a MobX proxy object.
            // sendMessage() stores the contents of the message in IndexedDB by calling
            // IndexedDB.set(), which is incapable of storing MobX proxy arrays.
            // Therefore, the MobX arrays such as `utterance.attachments` and
            // `externalAttachments` must be converted to JS objects first.
            transcriptView.sendMessage({
              message: utterance.plainText ?? '',
              metadata: utterance.metadata,
              attachments: toJS(utterance.attachments),
              isConsentConfirmed,
              externalAttachments: toJS(externalAttachments),
            });
          })
          .catch(() => {
            // only time this will happen is when user cancels
            // confirm consent, so do nothing here.
          });
      } else {
        Logger.error(
          'Unable to retry send message without text/photo or medium',
        );
      }
    };

    const onClick = (): void =>
      openRequestConsentModal(
        {
          medium,
          transcriptId: id,
        },
        utterance.id,
      );
    /**
     * Prepare the send status, if available.
     * Only show send status for MERCHANT speaker type.
     * PENDING = Utterance is being send to the backend. Always show when sending.
     * SENT = Utterance is received by the backend. Only show for last utterance.
     * FAILED = Unable to send to backend. Always show.
     * BLOCKED = Unable to send because denied by ai. Always show.
     * This is only applicable for speakerType === MERCHANT
     */
    let component = null;
    if (utterance.speakerType === Utterance.SpeakerType.MERCHANT) {
      switch (utterance.sendStatus) {
        case Utterance.SendStatus.PENDING:
          component = (
            <span className="Utterance__footer">
              <div className="Utterance__footer__text">
                {t('Utterance.sending')}
              </div>
            </span>
          );
          break;
        case Utterance.SendStatus.UPLOADING:
          component = (
            <span className="Utterance__footer">
              <div className="Utterance__footer__text">
                {t('Utterance.uploading')}
              </div>
            </span>
          );
          break;
        case Utterance.SendStatus.SENT:
          if (showSentStatus) {
            component = (
              <span className="Utterance__footer">
                <div className="Utterance__footer__text">
                  {t('Utterance.sent')}
                </div>
              </span>
            );
          }
          break;
        case Utterance.SendStatus.FAILED:
          if (utterance?.metadata?.pansRedacted) {
            component = (
              <span className="Utterance__footer Utterance__footer-column Utterance__outbound">
                <span className="Utterance__footer__text Utterance__footer__text--failed">
                  {t('Utterance.panRedactedOutbound')}
                </span>
                {hasConsentToTypeMessage(consentStatus) && (
                  <span
                    className="Utterance__footer__text Utterance__footer__text--retry"
                    onClick={openPaymentAlternativesModal}
                    onKeyDown={(e) =>
                      onKeyDownEnter(e, openPaymentAlternativesModal)
                    }
                    role="button"
                    tabIndex={0}
                  >
                    {t('Utterance.paymentAlternatives')}
                  </span>
                )}
              </span>
            );
          } else {
            component = (
              <span className="Utterance__footer">
                <span className="Utterance__footer__text Utterance__footer__text--failed">
                  {t('Utterance.failed')}
                </span>
                {hasConsentToTypeMessage(consentStatus) ? (
                  <span
                    className="Utterance__footer__text Utterance__footer__text--retry"
                    onClick={retrySendMessage}
                    onKeyDown={(e) => onKeyDownEnter(e, retrySendMessage)}
                    role="button"
                    tabIndex={0}
                  >
                    {t('Utterance.retry')}
                  </span>
                ) : null}
              </span>
            );
          }
          break;
        case Utterance.SendStatus.UNDELIVERABLE:
          component = (
            <span className="Utterance__footer">
              <span className="Utterance__footer__text Utterance__footer__text--failed">
                {t('Utterance.notDelivered')}
              </span>
            </span>
          );
          break;
        case Utterance.SendStatus.BLOCKED:
        case Utterance.SendStatus.BLOCKED_FOR_MARKETING:
        case Utterance.SendStatus.BLOCKED_NUMBER_NOT_VERIFIED:
        default:
          component = (
            <span className="Utterance__footer">
              <span className="Utterance__footer__text Utterance__footer__text--failed">
                {t('Utterance.notDelivered')}
              </span>
              {hasFullGrantedConsent(consentStatus) ? (
                <span
                  className="Utterance__footer__text Utterance__footer__text--retry"
                  onClick={retrySendMessage}
                  onKeyDown={(e) => onKeyDownEnter(e, retrySendMessage)}
                  role="button"
                  tabIndex={0}
                >
                  {t('Utterance.resend')}
                </span>
              ) : consentStatus === ConsentStatus.DENIED_CONSENT_REQUESTED ? (
                <span className="Utterance__footer__text Utterance__footer__text--consent-required">
                  {t('Utterance.consentRequested')}
                </span>
              ) : consentStatus === ConsentStatus.DENIED_BY_DEFAULT ||
                consentStatus === ConsentStatus.GRANTED_TRANSACTIONAL ? (
                <span
                  className="Utterance__footer__text Utterance__footer__text--retry"
                  onClick={onClick}
                  onKeyDown={(e) => onKeyDownEnter(e, onClick)}
                  role="button"
                  tabIndex={0}
                >
                  {t('Utterance.requestConsent')}
                </span>
              ) : null}
            </span>
          );
      }
    }

    return <>{component}</>;
  },
);

export default MerchantUtteranceSendStatus;
