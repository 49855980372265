import React, { ReactNode, ReactElement, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { MarketBlade } from 'src/components/Market';
import './MessengerBlade.scss';

export type MessengerBladeProps = {
  isOpen: boolean;
  children: ReactNode;
};

/**
 * This is the main component where Messenger will be rendered on.
 * Visually, it is a drawer/blade that slides in from the right side of the screen.
 * Also contains the veil that can be clicked to close the drawer.
 *
 * Its children should include <MarketHeader></MarketHeader>
 * and <MessengerContent></MessengerContent>.
 *
 * @example
 * Basic usage:
 * <MessengerBlade
 *   isOpen={false}
 * >
 *   <MarketHeader>...</MarketHeader>
 *   <MessengerContent>...</MessengerContent>
 * </MessengerBlade>
 * @param {boolean} isOpen
 * If true, show the drawer.
 * @param {ReactNode} children
 * Stuff rendered in the drawer, i.e. the header and contents.
 * @author klim
 */
const MessengerBlade = observer((props: MessengerBladeProps): ReactElement => {
  const { isOpen, children } = props;

  const { t } = useTranslation();

  // The className is a state because we want to set display to none after the
  // closing animation. If we don't do so, the blade can be seen when the user
  // print the webpage, despite it being visually hidden on the live page.
  const [className, setClassName] = useState(
    'MessengerBlade MessengerBlade__hidden MessengerBlade__display-none',
  );

  useEffect(() => {
    setClassName(
      isOpen ? 'MessengerBlade' : 'MessengerBlade MessengerBlade__hidden',
    );
  }, [isOpen]);

  return (
    <MarketBlade
      className={className}
      aria-label={t('common.blade.title')}
      data-testid="MessengerBlade"
      hidden={isOpen ? undefined : true}
      onAnimationEnd={() => {
        if (className.includes('MessengerBlade__hidden')) {
          setClassName(
            'MessengerBlade MessengerBlade__hidden MessengerBlade__display-none',
          );
        }
      }}
    >
      {isOpen ? children : null}
    </MarketBlade>
  );
});

export default MessengerBlade;
