import {
  CreateOrUpdateSubscriptionRequest,
  EnsureUnitDedicatedNumbersRequest,
  GetSubscriptionDetailsRequest,
  GetSubscriptionDetailsResponse,
  GetUnitsInformationRequest,
  IUnitInformation,
  UnitDedicatedNumber,
  UpdateUnitInformationRequest,
} from 'src/gen/squareup/messenger/v3/messenger_service';
import { UnitsInformationForReview } from 'src/MessengerTypes';
import { callRpc, callV3Rpc } from 'src/utils/apiUtils';
import Services from 'src/services/Services';
import { ExternalListAvailablePaymentInstrumentsRequest } from 'src/gen/squareup/billing/service';

const MESSAGES_PLUS_PLAN_TOKEN = 'messages-plus';

/**
 * Api responsible for retrieving and updating Messages Plus subscription related data.
 */
class SubscriptionApi {
  private _services: Services;

  constructor(services: Services) {
    this._services = services;
  }

  /**
   * Fetches the subscription and plan information of M+, including the subscription
   * status and dedicated number of each unit, if any. The response would only return
   * a subscription object if there is an active or canceled subscription. The plan
   * object should be used as the source of truth for pricing.
   *
   * @returns {Promise<GetSubscriptionDetailsResponse>}
   */
  getSubscriptionDetails = (): Promise<GetSubscriptionDetailsResponse> => {
    return callV3Rpc({
      name: 'GetSubscriptionDetails',
      rpc: (x) => this._services.messagesV3.getSubscriptionDetails(x),
      request: GetSubscriptionDetailsRequest.create({
        planToken: MESSAGES_PLUS_PLAN_TOKEN,
      }),
    });
  };

  /**
   * Ensure the dedicated numbers of the units, which attempts to assign a dedicated
   * number if that unit is subscribed to M+ and does not already have one.
   *
   * @param {string[]} [unitTokens]
   * The units we want to ensure. If left undefined, we will ensure all the units that
   * the user has access to.
   * @returns {Promise<UnitDedicatedNumber[]>}
   */
  ensureUnitDedicatedNumbers = async (
    unitTokens?: string[],
  ): Promise<UnitDedicatedNumber[]> => {
    const response = await callV3Rpc({
      name: 'EnsureUnitDedicatedNumbers',
      rpc: (x) => this._services.messagesV3.ensureUnitDedicatedNumbers(x),
      request: EnsureUnitDedicatedNumbersRequest.create({
        unitTokens,
      }),
    });
    return response.unitDedicatedNumbers as UnitDedicatedNumber[];
  };

  /**
   * Get the unit information for units that need review to get their dedicated numbers verified.
   * This is specific for dedicated numbers only, for general unit information, look at MerchantApi.getUnits.
   * Returns a record of key = unit token, value = information to review
   *
   * @param {string[]} [unitTokens]
   * The units we want to get information. If left undefined, we will get all the units that
   * the user has access to.
   * @returns {Promise<UnitsInformationForReview>}
   */
  getUnitsInformation = async (
    unitTokens?: string[],
  ): Promise<UnitsInformationForReview> => {
    const response = await callV3Rpc({
      name: 'GetUnitsInformation',
      rpc: (x) => this._services.messagesV3.getUnitsInformation(x),
      request: GetUnitsInformationRequest.create({
        unitTokens,
      }),
    });
    return response.unitInformation;
  };

  /**
   * Update the unit information for a single unit for dedicated number verification.
   * This is specific for dedicated numbers only, general unit information can not be edited via Messages.
   *
   * @param {string} unitToken - the unit token to update
   * @param {IUnitInformation} unitInformation
   * The unit information to update, need to include all fields, including the ones returned by GetUnitsInformation.
   * @returns {Promise<void>}
   */
  updateUnitInformation = async (
    unitToken: string,
    unitInformation: IUnitInformation,
  ): Promise<void> => {
    await callV3Rpc({
      name: 'UpdateUnitInformation',
      rpc: (x) => this._services.messagesV3.updateUnitInformation(x),
      request: UpdateUnitInformationRequest.create({
        unitToken,
        unitInformation,
      }),
    });
  };

  /**
   * Get the saved payment method used for subscriptions.
   *
   * @returns {Promise<string | undefined>}
   * This display string of the payment method, or undefined if none is found.
   */
  getSavedPaymentMethod = async (): Promise<string | undefined> => {
    const response = await callRpc({
      name: 'ExternalListAvailablePaymentInstruments',
      rpc: (x) =>
        this._services.billing.externalListAvailablePaymentInstruments(x),
      request: ExternalListAvailablePaymentInstrumentsRequest.create(),
      isExternalService: true,
    });

    /**
     * We have to check if response actually has defaultInstrumentIndex property
     * because is not set to optional at the proto level, so wirejs will default undefined
     * value to 0, which is a legit index.
     */
    if (
      Object.prototype.hasOwnProperty.call(
        response,
        'defaultInstrumentIndex',
      ) &&
      response.paymentInstruments.length > 0
    ) {
      return response.paymentInstruments[response.defaultInstrumentIndex]
        .display;
    }

    return undefined;
  };

  /**
   * Creates an M+ subscription for a set of units.
   *
   * @param {object} args
   * @param {string[]} args.unitTokens
   * The tokens for the units to subscribe to M+. Note this should include all units
   * that should be included in the subscription, including ones already subscribed previously.
   * @param {CreateOrUpdateSubscriptionRequest.SubscriptionStatusActionType} [args.action]
   * The type of action to take on the status of this subscription.
   */
  createOrUpdateSubscription = async ({
    unitTokens,
    action,
  }: {
    unitTokens: string[];
    action?: CreateOrUpdateSubscriptionRequest.SubscriptionStatusActionType;
  }): Promise<void> => {
    if (unitTokens.length === 0) {
      throw new Error('Unexpected empty array of unit tokens provided.');
    }

    await callV3Rpc({
      name: 'CreateOrUpdateSubscription',
      rpc: (x) => this._services.messagesV3.createOrUpdateSubscription(x),
      request: CreateOrUpdateSubscriptionRequest.create({
        planToken: MESSAGES_PLUS_PLAN_TOKEN,
        unitTokens,
        subscriptionStatusAction: action,
      }),
    });
  };
}

export default SubscriptionApi;
