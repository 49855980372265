/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../Fixes';
import Builder from '../../../Builder';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "riskevaluation": {
        "nested": {
          "external": {
            "nested": {
              "Level": {
                "values": {
                  "UNKNOWN_LEVEL": 0,
                  "NORMAL": 1,
                  "MODERATE": 2,
                  "HIGH": 3
                }
              },
              "Status": {
                "values": {
                  "UNKNOWN_STATUS": 0,
                  "NOT_ELIGIBLE": 1,
                  "PENDING": 2,
                  "ACTIVE": 3
                }
              },
              "Reason": {
                "values": {
                  "UNKNOWN_REASON": 0,
                  "CHARGEBACKS_PAYMENT_CARD": 1,
                  "LOCATION_BUYER_IP": 2,
                  "RISKY_SIGNALS": 3,
                  "DECLINES_BROWSER_FINGERPRINT": 4,
                  "DECLINES_BUYER_IP": 5,
                  "AVS_PAYMENT": 6,
                  "CHARGEBACKS_BUYER_IP": 7,
                  "DECLINES_PAYMENT_CARD": 8,
                  "AVS_BUYER_IP": 9,
                  "AVS_BROWSER_FINGERPRINT": 10,
                  "AVS_PAYMENT_CARD": 11,
                  "CHARGEBACKS_BROWSER_FINGERPRINT": 12,
                  "FREQUENCY_HIGH_BUYER_IP": 13,
                  "FREQUENCY_HIGH_BROWSER_FINGERPRINT": 14,
                  "FREQUENCY_HIGH_PAYMENT_CARD": 15,
                  "FREQUENCY_LOW_BUYER_IP": 16,
                  "FREQUENCY_LOW_BROWSER_FINGERPRINT": 17,
                  "FREQUENCY_LOW_PAYMENT_CARD": 18,
                  "LOCATION_BROWSER_FINGERPRINT": 19,
                  "LOCATION_PAYMENT_CARD": 20
                }
              },
              "Action": {
                "values": {
                  "DO_NOT_USE": 0,
                  "DECLINE": 1,
                  "ACCEPT_AND_ALERT": 2,
                  "DECLINE_FAILED_AND_ALERT": 3,
                  "THREE_DS_FAILED_AND_ALERT": 4,
                  "THREE_DS_SOFT_FAIL_AND_ALERT": 5
                }
              },
              "EvaluationResult": {
                "fields": {
                  "status": {
                    "type": "squareup.riskevaluation.external.Status",
                    "id": 1,
                    "rule": "optional"
                  },
                  "createdAtMs": {
                    "type": "int64",
                    "id": 2,
                    "rule": "optional"
                  },
                  "level": {
                    "type": "squareup.riskevaluation.external.Level",
                    "id": 3,
                    "rule": "optional"
                  },
                  "numericScore": {
                    "type": "double",
                    "id": 4,
                    "rule": "optional"
                  },
                  "reasons": {
                    "type": "squareup.riskevaluation.external.Reason",
                    "id": 5,
                    "rule": "repeated"
                  },
                  "rulesFlagged": {
                    "type": "string",
                    "id": 6,
                    "rule": "repeated"
                  },
                  "action": {
                    "type": "squareup.riskevaluation.external.Action",
                    "id": 7,
                    "rule": "optional"
                  }
                }
              },
              "EvaluationDetails": {
                "fields": {
                  "currentResult": {
                    "type": "squareup.riskevaluation.external.EvaluationResult",
                    "id": 1,
                    "rule": "optional"
                  },
                  "resultHistory": {
                    "type": "squareup.riskevaluation.external.EvaluationResult",
                    "id": 2,
                    "rule": "repeated"
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.riskevaluation?.nested?.external?.nested?.Level && !$root.nested?.squareup?.nested?.riskevaluation?.nested?.external?.nested?.Status && !$root.nested?.squareup?.nested?.riskevaluation?.nested?.external?.nested?.Reason && !$root.nested?.squareup?.nested?.riskevaluation?.nested?.external?.nested?.Action && !$root.nested?.squareup?.nested?.riskevaluation?.nested?.external?.nested?.EvaluationResult && !$root.nested?.squareup?.nested?.riskevaluation?.nested?.external?.nested?.EvaluationDetails) {
	$root.addJSON(jsonData);
}

/**
 * Risk evaluation level, these levels may not map directly to externally exposed risk levels
 * For example, we may add more levels for additional granularity but not yet expose it
 * to merchants.
 *
 * WARNING: be very careful when adding new levels, as it may break clients.
 *
 * SOURCE Level @ squareup/riskevaluation/external/external.proto at 14:1
 */
export const Level = $root.lookupEnum('squareup.riskevaluation.external.Level').values;
/**
 * Risk evaluation status.
 *
 * WARNING: be very careful when adding new entries, as it may break clients.
 *
 * SOURCE Status @ squareup/riskevaluation/external/external.proto at 26:1
 */
export const Status = $root.lookupEnum('squareup.riskevaluation.external.Status').values;
/**
 * Reason for the Risk level.
 *
 * WARNING: be very careful when adding new entries, as it may break clients.
 * Descriptions here: https://docs.google.com/spreadsheets/d/1mf5Va19i2LmX0zQ0ZwR72qhMCo14AI6ZQxSEdBo8VKA/edit#gid=958028614
 *
 * SOURCE Reason @ squareup/riskevaluation/external/external.proto at 54:1
 */
export const Reason = $root.lookupEnum('squareup.riskevaluation.external.Reason').values;
/**
 * Action taken on this payment as the result of the evaluation. Not populated if no action was taken.
 *
 * SOURCE Action @ squareup/riskevaluation/external/external.proto at 113:1
 */
export const Action = $root.lookupEnum('squareup.riskevaluation.external.Action').values;
/**
 * Representation of a risk evaluation of an event (e.g. payment), for consumption
 * by end-user clients (e.g. Connect V2) so that the data can ultimately be shown to merchants.
 * The proto should be generic enough so that it can be used by non-payment events as well.
 *
 * For payment, the data is visible to merchants through endpoints such as Connect V2 and Dashboard.
 * Services like Beemo also need to consume the data to allow aggregation queries.
 * See go/external-risk-evaluation-design.
 *
 * The externalized version for the v2/transactions API is here:
 * https://git.sqcorp.co/projects/GO/repos/square/browse/xp/connect-public-protos/protos/squareup/connect/v2/resources/tender.proto
 * The externalized version for the v2/payments API is in the risk_evaluation.proto file in the
 * same directory as this one.
 *
 * SOURCE EvaluationResult @ squareup/riskevaluation/external/external.proto at 146:1
 */
export const EvaluationResult = $root.lookupType('squareup.riskevaluation.external.EvaluationResult');
fixType(EvaluationResult, {
  status: { required: true },
  createdAtMs: { required: true },
});
Builder.createAndAttachToType(EvaluationResult);
/**
 * Container for EvaluationResult that holds both current and historical EvaluationResult.
 *
 * A payment or tender may have multiple results. For example, we initially evaluate a payment as
 * having NORMAL level of risk. But we later realize that there has been a fraud trend and we want
 * to alert the customers by raising the risk level.
 *
 * SOURCE EvaluationDetails @ squareup/riskevaluation/external/external.proto at 163:1
 */
export const EvaluationDetails = $root.lookupType('squareup.riskevaluation.external.EvaluationDetails');
fixType(EvaluationDetails);
Builder.createAndAttachToType(EvaluationDetails);
