import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { MarketBanner } from 'src/components/Market';
import { useTranslation } from 'react-i18next';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import { M_PLUS_FAQ_URL } from 'src/utils/url';

type UpgradeBannerProps = {
  unitToken: string;
};

/**
 * A banner to show when the merchant is unable to send messages without
 * subscribing to Messages Plus, with a CTA to open the upsell sheet or TFV form.
 *
 * @param {string} unitToken
 * The unit token of the transcript we are on.
 */
const UpgradeBanner = observer(
  ({ unitToken }: UpgradeBannerProps): ReactElement => {
    const { t } = useTranslation();
    const { navigation, subscription } = useMessengerControllerContext();

    return (
      <MarketBanner variant="info" data-testid="UpgradeBanner">
        {t('MessagesPlus.empty_state.transcript_description')}
        <button
          slot="action"
          onClick={() => {
            if (subscription.isSubscribed) {
              navigation.openSheet({
                name: 'UNIT_VERIFICATION_FORM',
                unitToken,
              });
              return;
            }
            navigation.openSheet('MESSAGES_PLUS_PRICING');
          }}
        >
          {subscription.hasPreviouslySubscribed
            ? t('MessagesPlus.subscribe')
            : t('MessagesPlus.try')}
        </button>
        <a slot="action" href={M_PLUS_FAQ_URL} target="_blank" rel="noreferrer">
          {t('VerificationInProgress.learn_more')}
        </a>
      </MarketBanner>
    );
  },
);

export default UpgradeBanner;
