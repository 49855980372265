import { debounce } from 'debounce';
import type MessengerController from 'src/MessengerController';
import type Api from 'src/api/Api';
import { makeAutoObservable, runInAction } from 'mobx';
import { LoadingStatus } from 'src/MessengerTypes';
import { Contact } from 'src/gen/squareup/messenger/v3/messenger_auxiliary_service';

const SEARCH_CONTACTS_DEBOUNCE_TIMEOUT = 200;

/**
 * Store responsible for tracking the state of a list of contacts being searched.
 */
class SearchContactsStore {
  private _stores: MessengerController;
  private _api: Api;
  /**
   * Same as _getContactsFromQueryAndSetResults but waits for a short delay before
   * executing. This function is memoized so calling this again will reset the
   * debounce timer.
   */
  private _debounceGetContactsFromQueryAndSetResults?: () => Promise<void>;

  /**
   * Whether or not data is fetched on an empty query.
   * For the compose message search, this is true because we want to show the list of
   * contacts when the user clicks on the new message button.
   * For the transcripts list search (version 2), this is false because we don't want to
   * show a list of contacts when the search query is empty.
   */
  private _loadOnEmptyQuery = true;

  status: LoadingStatus = 'NOT_STARTED';

  query = '';

  results: Contact[] = [];

  constructor(stores: MessengerController, loadOnEmptyQuery = true) {
    makeAutoObservable(this);

    this._stores = stores;
    this._api = stores.api;
    this._loadOnEmptyQuery = loadOnEmptyQuery;
  }

  init = async (): Promise<void> => {
    this._debounceGetContactsFromQueryAndSetResults = debounce(
      this._getContactsFromQueryAndSetResults,
      SEARCH_CONTACTS_DEBOUNCE_TIMEOUT,
    );
    await this._getContactsFromQueryAndSetResults();
  };

  /**
   * Updates the search query used to filter the list of contacts on.
   *
   * @param {string} newQuery
   * The new query to use.
   */
  setQuery = (newQuery: string): void => {
    if (newQuery === this.query) {
      return;
    }
    this.query = newQuery;
    this.status =
      newQuery === '' && !this._loadOnEmptyQuery ? 'NOT_STARTED' : 'LOADING';
    this._debounceGetContactsFromQueryAndSetResults?.();
  };

  /**
   * Get the list of contacts based on the query and set the results.
   *
   * @returns {Promise<void>}
   */
  private _getContactsFromQueryAndSetResults = async (): Promise<void> => {
    if (this.query === '' && !this._loadOnEmptyQuery) {
      return;
    }

    const querySubmitted = this.query;
    this.status = 'LOADING';
    try {
      const results = await this._api.contacts.searchContacts(querySubmitted);
      if (this.query !== querySubmitted) {
        // The query has changed since this request was made, ignore the results
        return;
      }
      runInAction(() => {
        this.results = results;
        this.status = 'SUCCESS';
      });
    } catch {
      runInAction(() => {
        this.results = [];
        this.status = 'ERROR';
      });
    }
  };

  /**
   * Retry the current query.
   */
  retry = (): void => {
    this._getContactsFromQueryAndSetResults();
  };
}

export default SearchContactsStore;
