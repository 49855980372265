import { Component, Host, Prop, h, Element } from '@stencil/core';

import { getNamespacedTagFor } from '../../utils/namespace';

/**
 * @slot - Default slot for `<market-progress-tracker-step>`s
 */
@Component({
  tag: 'market-progress-tracker',
  styleUrl: 'market-progress-tracker.css',
  shadow: true,
})
export class MarketProgressTracker {
  @Element() el: HTMLMarketProgressTrackerElement;

  /**
   * Number of the current step of the tracker
   */
  @Prop() readonly currentStepIndex: number;

  /**
   * **DEPRECATED (v4.14.0)** Use `currentStepIndex` instead.
   * @deprecated
   *
   * Number for setting number of **completed steps** of the tracker
   */
  @Prop() readonly completedSteps: number; // will be reinterpreted as `currentStepIndex`

  /**
   * Steps' size
   *
   * Setting this will **override** all of the steps' `size` properties
   */
  @Prop() readonly size: 'large' | 'medium' | 'small';

  /**
   * **DEPRECATED (v4.14.0)** Use `indicator` instead.
   * @deprecated
   *
   * String for setting the icons used to indicate progress
   */
  @Prop() readonly variant: 'circle' | 'check';

  /**
   * Type of icon used to indicate progress on all of the steps
   *
   * Setting this will **override** all of the steps' `indicator` properties,
   * but is disregarded on a step level when it has a `[slot="icon"]` provided.
   */
  @Prop() readonly indicator: 'circle' | 'check';

  /**
   * Whether or not the direction of the progress indicators is reversed
   *
   * @default false
   */
  @Prop() readonly reversed: boolean = false;

  /**
   * Whether or not the bars between steps are hidden
   *
   * @default false
   */
  @Prop() readonly barless: boolean = false;

  setStepProps() {
    const { barless, completedSteps, currentStepIndex, el, indicator, size, reversed, variant } = this;
    const stepEls = [
      ...el.querySelectorAll<HTMLMarketProgressTrackerStepElement>(getNamespacedTagFor('market-progress-tracker-step')),
    ];
    if (!stepEls?.length) {
      return;
    }

    const currentIndex = currentStepIndex ?? completedSteps ?? -1;
    (reversed ? [...stepEls].reverse() : stepEls)?.forEach((stepEl, index) => {
      stepEl.size = size ?? stepEl.size;
      if (variant ?? indicator) {
        stepEl.indicator = variant ?? indicator;
      }

      const isCompleted = index < currentIndex;
      const isActive = index === currentIndex;
      stepEl.completed = isCompleted;
      stepEl.active = isActive;

      if (isCompleted || (isActive && reversed)) {
        stepEl.bar = 'active';
      } else {
        stepEl.bar = 'inactive';
      }
    });

    // remove bar if barless or last step
    stepEls.forEach((stepEl, index) => {
      if (barless || index + 1 === stepEls.length) {
        stepEl.bar = 'hidden';
      }
    });
  }

  handleDefaultSlotChange() {
    this.setStepProps();
  }

  connectedCallback() {
    this.handleDefaultSlotChange();
  }

  render() {
    return (
      <Host class="market-progress-tracker">
        <ol>
          <slot onSlotchange={() => this.handleDefaultSlotChange()}></slot>
        </ol>
      </Host>
    );
  }
}
