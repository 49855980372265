/**
 * This icon is used in a button and should be used in navigation
 * where we want to close the component we are on.
 */

import React, { ReactElement } from 'react';
import BaseSvgProps from 'src/types/BaseSvgProps';

const MarketCloseIcon = (props: BaseSvgProps): ReactElement => (
  <svg
    width="14"
    height="14"
    fill="none"
    viewBox="0 0 14 14"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>Close</title>
    <path d="M1.71 13.71 7 8.41l5.29 5.3 1.42-1.42L8.41 7l5.3-5.29L12.29.29 7 5.59 1.71.29.29 1.71 5.59 7l-5.3 5.29 1.42 1.42Z" />
  </svg>
);

export default MarketCloseIcon;
