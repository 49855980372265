import {
  Hub,
  BrowserClient,
  Scope,
  defaultStackParser,
  defaultIntegrations,
  makeFetchTransport,
} from '@sentry/browser';
import { Environment } from './MessengerTypes';
/**
 * The *production* client key (DSN) for the messenger Sentry project. This
 * allows us to keep our events separate from Dashboard.
 */
const MESSENGER_DSN_PRODUCTION =
  'https://8977fd7b69c246fd92c324aef5d057c6@o160250.ingest.sentry.io/6415864';

/**
 * Staging version of {@link MESSENGER_DSN_PRODUCTION}.
 */
const MESSENGER_DSN_STAGING =
  'https://9a2026a4f5444270a664bde2634cb6d1@o160250.ingest.sentry.io/6415864';

/**
 * Sentry is our remote logging system that allows us to track any kind of warnings
 * or errors which should not have occurred. An example use case is to log when the
 * backend is not returning a piece of information that is required by the frontend.
 * The log can be access via go/sentry, and should also propagate to
 * #conversations-web-sentry.
 */
export default class Sentry {
  messengerSentry: Hub | null = null;

  init(environment: Environment): void {
    this.messengerSentry = new Hub(
      new BrowserClient({
        transport: makeFetchTransport,
        stackParser: defaultStackParser,
        integrations: defaultIntegrations,
        environment,
        dsn:
          environment === 'production'
            ? MESSENGER_DSN_PRODUCTION
            : MESSENGER_DSN_STAGING,
        beforeSend(event) {
          if (window.location.pathname.startsWith('/login')) {
            // filter events from login page
            return null;
          }
          return event;
        },
      }),
    );
  }

  /**
   * Log either an major event or a breadcrumb leading up to a major event.
   *
   * @param {string | Error} event - the message to pass to sentry or an
   * Error that Sentry can display a trace for
   * @param {string} level - what kind of alert this is
   * @param {Record<string, ?>} data - any additional data you'd like to
   * send to Sentry with this message
   */
  logEvent(
    event: string | Error,
    level?: 'warning' | 'error',
    data?: Record<string, unknown>,
  ): void {
    const sentry = this.messengerSentry;
    if (sentry == null) {
      return;
    }

    sentry.withScope((scope: Scope) => {
      // Sentry events can have a message or an Error (with its own message), and
      // either type of event can have extra data to display on the event's page,
      // e.g. a transcript ID. Note: Sentry does not handle nested data well.
      if (data) {
        for (const extra of Object.keys(data)) {
          scope.setExtra(extra, data[extra]);
        }
      }
      if (typeof event === 'string') {
        sentry.captureMessage(event, level);
      } else {
        sentry.captureException(event);
      }
    });
  }
}
