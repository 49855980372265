/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "common": {
        "nested": {
          "tender": {
            "nested": {
              "ServerCompleted": {
                "nested": {
                  "ServerCompletedVersion": {
                    "values": {
                      "UNKNOWN_VERSION": 0,
                      "CASH_APP_V0": 1,
                      "CASH_APP_V1": 2
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.common?.nested?.tender?.nested?.ServerCompleted?.nested?.ServerCompletedVersion) {
	$root.addJSON(jsonData);
}

/**
 * SOURCE ServerCompleted @ squareup/common/tender.proto at 596:1
 */
export const ServerCompleted = {};
/**
 * SOURCE ServerCompletedVersion @ squareup/common/tender.proto at 621:3
 */
ServerCompleted.ServerCompletedVersion = $root.lookupEnum('squareup.common.tender.ServerCompleted.ServerCompletedVersion').values;
