import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { t } from 'i18next';
import { useRelativeDateWithTimestamp } from 'src/utils/timeUtils';
import { MediumTimestamp as MediumTimestampObjectType } from 'src/MessengerTypes';
import './MediumTimestamp.scss';

export type MediumTimestampProps = {
  timestampMillis: number;
  medium?: MediumTimestampObjectType['medium'];
  unitName?: string;
};

const APPOINTMENT_RELATIVE_DATE_FORMAT_OPTIONS = {
  omitDateForToday: true,
  useDayOfWeekForYesterday: true,
};

/**
 * Gets the time displayed with grouped utterances in the transcript view.
 * For Appointment cards, the display time returned is:
 * 'Appointment at {time}' for today
 * 'Appointment on {relativeDay} at {time}' for other days
 *
 * For all other utterances, the display time returned is:
 * '{relativeDay} at {time}'
 *
 * @param {string | undefined} relativeDate Relative day returned by `useRelativeDateWithTimestamp`
 * This can be "Today", "Yesterday", a day of the week, month and day, full date,
 * or `undefined` if it should be left out.
 * @param {string} timestamp Timestamp in HH:MM XM (i.e. 2:30 PM)
 * @param {MediumTimestampObjectType['medium']} medium
 * (Optional) Medium of the utterance, which can be sms, email, appointment, etc.
 * @returns Time that should be displayed in the UI
 */
const getDisplayTime = (
  relativeDate: string | undefined,
  timestamp: string,
  medium?: MediumTimestampObjectType['medium'],
): string => {
  if (medium === 'APPOINTMENT') {
    // Render 'Appointment on {date} at {time}'
    // or 'Appointment at {time}' (if today)
    if (relativeDate) {
      return t('ContextualEvent.appointment.appointment_on_date_at_time', {
        date: relativeDate,
        time: timestamp,
      });
    }
    return t('ContextualEvent.appointment.appointment_at_time', {
      time: timestamp,
    });
  } else {
    if (relativeDate) {
      return t('common.time.date_at_time', {
        date: relativeDate,
        time: timestamp,
      });
    }
    return timestamp;
  }
};

/**
 * A label that acts as a header for a set of utterances that is grouped by date.
 * Displays the relative date and timestamp for the first utterance in that set.
 *
 * @example
 * Basic usage:
 * <MediumTimestamp timestampMillis={1352342312} medium={Medium.SMS} />
 * @param {number} timestampMillis
 * Timestamp in milliseconds.
 * @param {MediumTimestampObjectType['medium']} medium
 * Medium of what this label represents, i.e. 'SMS', 'EMAIL', or 'APPOINTMENT'
 * @author klim
 */
const MediumTimestamp = observer(
  (props: MediumTimestampProps): ReactElement => {
    const { timestampMillis, medium, unitName } = props;

    const [relativeDate, timestamp] = useRelativeDateWithTimestamp(
      timestampMillis,
      medium === 'APPOINTMENT'
        ? APPOINTMENT_RELATIVE_DATE_FORMAT_OPTIONS
        : undefined,
    );

    const displayTime = getDisplayTime(relativeDate, timestamp, medium);

    return (
      <div
        className="MediumTimestamp"
        data-testid="MediumTimestamp"
        key={`${unitName}_${medium}_${timestampMillis}`}
      >
        <span className="MediumTimestamp__time">{displayTime}</span>
        {unitName ? ` | ${unitName}` : null}
      </div>
    );
  },
);

export default MediumTimestamp;
