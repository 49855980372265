import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { MarketBanner } from 'src/components/Market';
import { getMPlusSubscriptionDashboardUrl } from 'src/utils/url';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';

export type DelinquentSubscriptionBannerProps = {
  onClick?: () => void;
};

/**
 * Banner that is displayed when the merchant's M+ subscription has become delinquent.
 * This occurs when the merchant has failed to pay for their subscription for a period of time
 * and have had their M+ subscription revoked. This component includes a CTA to re-subscribe.
 *
 * @param {() => void} [onClick]
 * Callback executed when the re-subscribe button is clicked.
 */
const DelinquentSubscriptionBanner = observer(
  ({ onClick }: DelinquentSubscriptionBannerProps): ReactElement => {
    const { t } = useTranslation();
    const { tooltip } = useMessengerControllerContext();

    return (
      <MarketBanner
        variant="warning"
        className="DelinquentSubscriptionBanner"
        dismissable
        onMarketBannerDismissed={() => {
          tooltip.dismiss('SUBSCRIPTION_DELINQUENT_BANNER');
        }}
        data-testid="DelinquentSubscriptionBanner"
      >
        {t('SettingsPage.subscription.terminated_text')}
        <a
          href={getMPlusSubscriptionDashboardUrl}
          target="_self"
          slot="action"
          onClick={onClick}
        >
          {t('SettingsPage.subscription.subscribe_again')}
        </a>
      </MarketBanner>
    );
  },
);

export default DelinquentSubscriptionBanner;
