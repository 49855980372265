/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "client": {
        "nested": {
          "AppVersionRange": {
            "fields": {
              "since": {
                "type": "string",
                "id": 2,
                "rule": "optional"
              },
              "until": {
                "type": "string",
                "id": 3,
                "rule": "optional"
              }
            }
          },
          "AppVersionRanges": {
            "fields": {
              "value": {
                "type": "squareup.client.AppVersionRange",
                "id": 1,
                "rule": "repeated"
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.client?.nested?.AppVersionRange && !$root.nested?.squareup?.nested?.client?.nested?.AppVersionRanges) {
	$root.addJSON(jsonData);
}

/**
 * Message to annotate a proto file, message, field, enum, enum value, service or RPC
 * to document the client App version for which it was introduced and the last version
 * after which its use is stopped.
 *
 * SOURCE AppVersionRange @ squareup/client/options.proto at 36:1
 */
export const AppVersionRange = $root.lookupType('squareup.client.AppVersionRange');
fixType(AppVersionRange);
Builder.createAndAttachToType(AppVersionRange);
/**
 * SOURCE AppVersionRanges @ squareup/client/options.proto at 69:1
 */
export const AppVersionRanges = $root.lookupType('squareup.client.AppVersionRanges');
fixType(AppVersionRanges);
Builder.createAndAttachToType(AppVersionRanges);
