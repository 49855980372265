import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import './CustomerUtteranceFooter.scss';
import { LocalUtterance } from 'src/MessengerTypes';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import { useTranslation } from 'react-i18next';
import { onKeyDownEnter } from 'src/utils/keyboardUtils';

export type CustomerUtteranceFooterProps = {
  localUtterance: LocalUtterance;
};

/**
 * Footer attached below an inbound customer utterance.
 * This is similar to <MerchantUtteranceSendStatus /> but has less to do with the actual
 * delivery status since any customer inbound shown in the transcript will have been
 * successfully delivered. Currently, this is only used for notifying the merchant of
 * messages containing sensitive payment information.
 *
 * @example
 * Basic usage:
 * <CustomerUtteranceFooter localUtterance={...} />
 * @param {number} localUtterance
 * The inbound utterance we want to compute the footer from.
 * @author teresalin
 */
const CustomerUtteranceFooter = observer(
  ({ localUtterance }: CustomerUtteranceFooterProps): ReactElement | null => {
    const { utterance } = localUtterance;
    const { modal } = useMessengerControllerContext();
    const { openPaymentAlternativesModal } = modal;
    const { t } = useTranslation();

    if (!utterance.metadata?.pansRedacted) {
      return null;
    }
    return (
      <div className="CustomerUtteranceFooter Utterance__footer Utterance__footer-column Utterance__inbound">
        <span className="Utterance__footer__text Utterance__footer__text--failed">
          {t('Utterance.panRedactedInbound')}
        </span>
        <span
          className="Utterance__footer__text Utterance__footer__text--retry"
          onClick={openPaymentAlternativesModal}
          onKeyDown={(e) => onKeyDownEnter(e, openPaymentAlternativesModal)}
          role="button"
          tabIndex={0}
        >
          {t('Utterance.paymentAlternatives')}
        </span>
      </div>
    );
  },
);

export default CustomerUtteranceFooter;
