import React, { ReactElement } from 'react';
import BaseSvgProps from 'src/types/BaseSvgProps';

/**
 * Icon representing the delete operation (garbage can).
 */
const DeleteIcon = (props: BaseSvgProps): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="20"
    fill="none"
    viewBox="0 0 18 20"
    {...props}
  >
    <title>Delete</title>
    <path
      fill="#000"
      fillOpacity=".3"
      fillRule="evenodd"
      d="M18 3h-6c0-1.65-1.35-3-3-3S6 1.35 6 3H0v2h2v11.75C2 18.54 3.66 20 5.7 20h6.6c2.04 0 3.7-1.46 3.7-3.25V5h2V3ZM9 2c.55 0 1 .45 1 1H8c0-.55.45-1 1-1Zm5 14.75c0 .68-.78 1.25-1.7 1.25H5.7c-.92 0-1.7-.57-1.7-1.25V5h10v11.75ZM12 7h-2v9h2V7ZM6 7h2v9H6V7Z"
      clipRule="evenodd"
    />
  </svg>
);

export default DeleteIcon;
