import {
  ExecuteGraphQLRequest,
  GetMessengerSettingsRequest,
  IMessengerSettings,
  MessengerSettings,
  Status,
  UpdateMessengerSettingsRequest,
  UpdateMessengerSettingsResponse,
  ValidIMessengerSettings,
} from 'src/gen/squareup/messenger/v3/messenger_service';
import Logger from 'src/Logger';
import Services from 'src/services/Services';
import { callV3Rpc } from 'src/utils/apiUtils';
import {
  MessagesMarketingClassifierError,
  MessagesShaftCError,
} from 'src/types/Errors';

/**
 * Api responsible for settings and google reviews setup.
 * TODO(klim): Migrate other settings functions to here
 */
export default class SettingsApi {
  private _services: Services;

  constructor(services: Services) {
    this._services = services;
  }

  /**
   * Issue a GetMessengerSettings request.
   */
  getMessengerSettings: () => Promise<
    (MessengerSettings & ValidIMessengerSettings) | undefined
  > = () => {
    return callV3Rpc({
      name: 'GetMessengerSettings',
      rpc: () => this._services.messagesV3.getMessengerSettings({}),
      request: GetMessengerSettingsRequest.create({}),
    }).then((response) => response.messengerSettings);
  };

  /**
   * Saves new Messenger Settings.
   *
   * @param {IMessengerSettings} messengerSettings
   */
  saveSettings = async (
    messengerSettings: IMessengerSettings,
  ): Promise<void> => {
    const requestData = UpdateMessengerSettingsRequest.create({
      messengerSettings,
    });
    try {
      await callV3Rpc({
        name: 'UpdateMessengerSettings',
        rpc: (x) => this._services.messagesV3.updateMessengerSettings(x),
        request: UpdateMessengerSettingsRequest.create(requestData),
      });
    } catch (error) {
      const code = (error as UpdateMessengerSettingsResponse)?.status?.code;
      if (code === Status.Code.CONSENT_DENIED_BY_AI) {
        throw new MessagesMarketingClassifierError(
          'Settings update contains marketing content',
        );
      }
      if (code === Status.Code.SHAFTC_VIOLATION) {
        throw new MessagesShaftCError(
          'Settings update contains content that violates SHAFT C rules',
        );
      }
      throw error;
    }
  };

  /**
   * Converts a url into a short one, e.g. bit.ly/abc123
   *
   * @param {string} url - original URl to convert to a short one
   * @returns {Promise<string>} - A promise that resolves to the short url
   */
  getReviewShortUrl = (url: string): Promise<string> => {
    const request = ExecuteGraphQLRequest.create({
      query: `
        mutation {
          createShortenedUrl(
            originalUrl: "${url}"
          )
        }
      `,
    });

    return callV3Rpc({
      name: 'ExecuteGraphQL',
      rpc: (x) => this._services.messagesV3.executeGraphQL(x),
      request,
    })
      .then((response) => {
        if (response && response.response) {
          const graphql = JSON.parse(response.response);
          if (graphql.data && graphql.data.createShortenedUrl) {
            return graphql.data.createShortenedUrl;
          }
        }

        return '';
      })
      .catch((error) => {
        Logger.logWithSentry('Unable to shorten url', 'error', {
          url,
          error,
        });
      });
  };
}
