import React, { ReactElement, ReactNode } from 'react';
import { observer } from 'mobx-react';
import './MessengerModalDialog.scss';
import MessengerModalContext from 'src/components/MessengerModalContext';
import {
  MarketButtonGroup,
  MarketDialog,
  MarketFooter,
} from 'src/components/Market';
import StatusBanner from 'src/components/StatusBanner/StatusBanner';
import { Status } from 'src/MessengerTypes';
import LoadingIndicator from 'src/components/LoadingIndicator/LoadingIndicator';

export type MessengerModalDialogProps = {
  title: string;
  children: ReactNode;
  close?: () => void;
  primaryButton: ReactNode;
  secondaryButton?: ReactNode;
  tertiaryButton?: ReactNode;
  buttonGroupAlign?: React.ComponentProps<
    typeof MarketButtonGroup
  >['alignment'];
  icon?: ReactNode;
  status?: Status | null;
  isLoading?: boolean;
};

/**
 * A modal that appears in the middle of the screen, surrounded by a veil.
 * The modal contains a header with the title, the body, and footer with buttons.
 *
 * Note that regardless of where this component is used, it will always be
 * rendered as a child of the shadow root using React portal.
 *
 * @example
 * Basic usage:
 * <MessengerModalDialog
 *   title="Send Payment"
 *   close={() => closeModal()}
 *   primaryButton={<MarketButton label="Submit" />}
 * >
 *   {paymentForm}
 * </MessengerModalDialog>
 *
 * With optional:
 * <MessengerModalDialog
 *   title="Send Payment"
 *   close={() => closeModal()}
 *   primaryButton={<MarketButton label="Submit" />}
 *   secondaryButton={<MarketButton label="Cancel" />}
 * >
 *   {paymentForm}
 * </MessengerModalDialog>
 * @param {string} title
 * Title of the modal.
 * @param {ReactNode} icon
 * (Optional) Icon to render in the header, above the title.
 * @param {ReactNode} children
 * Component to render in the body.
 * @param {() => void} [close]
 * (Optional) Function to call when close icon is clicked.
 * @param {MarketButtonGroup['alignment']} [buttonGroupAlign='fill']
 * (Optional) How the buttons should be aligned.
 * @param {ReactNode} primaryButton
 * A button that appears in the footer. Where it appears depends on
 * the `buttonGroupAlign`. If `buttonGroupAlign` is not specified, it
 * will appear at the far right side of the footer.
 * @param {ReactNode} [secondaryButton]
 * (Optional) A secondary button that appears in the footer, depending on
 * the value of `buttonGroupAlign`. If `buttonGroupAlign` is not specified,
 * it will appear to the left of the primary button.
 * @param {ReactNode} [tertiaryButton]
 * (Optional) A tertiary button that appears in the footer, depending on
 * the value of `buttonGroupAlign`. If `buttonGroupAlign` is not specified,
 * it will appear to the left of the secondary button, in a popover.
 * @param {Status} [status]
 * (Optional) The status to be shown in the modal, if any. Supports display of a
 * banner below the title, useful for error handling.
 * @param {boolean} [isLoading]
 * (Optional) If true, show a loading indicator.
 * @author klim
 */
const MessengerModalDialog = observer(
  ({
    title,
    children,
    close,
    primaryButton,
    secondaryButton,
    tertiaryButton,
    buttonGroupAlign = 'fill',
    icon,
    status,
    isLoading,
  }: MessengerModalDialogProps): ReactElement => {
    let banner = null;
    if (status?.scope === 'MODAL' && status.display === 'BANNER') {
      banner = (
        <StatusBanner
          label={status.label}
          type={status.type}
          action={status.action}
        />
      );
    }

    return (
      <MessengerModalContext close={close}>
        <MarketDialog className="MessengerModalDialog">
          {isLoading ? (
            <LoadingIndicator />
          ) : (
            <>
              {icon}
              <h2>{title}</h2>

              <main>
                {banner}
                {children}
              </main>
              <MarketFooter>
                <MarketButtonGroup alignment={buttonGroupAlign}>
                  {primaryButton}
                  {secondaryButton}
                  {tertiaryButton}
                </MarketButtonGroup>
              </MarketFooter>
            </>
          )}
        </MarketDialog>
      </MessengerModalContext>
    );
  },
);

export default MessengerModalDialog;
