/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';
import './currency';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "common": {
        "nested": {
          "tipping": {
            "nested": {
              "TipOption": {
                "fields": {
                  "label": {
                    "type": "string",
                    "id": 1,
                    "rule": "optional"
                  },
                  "tipMoney": {
                    "type": "squareup.common.Money",
                    "id": 2,
                    "rule": "optional"
                  },
                  "percentage": {
                    "type": "double",
                    "id": 3,
                    "rule": "optional"
                  },
                  "isRemainingBalance": {
                    "type": "bool",
                    "id": 4,
                    "rule": "optional"
                  }
                }
              },
              "TippingPreferences": {
                "fields": {
                  "useSmartTipping": {
                    "type": "bool",
                    "id": 1,
                    "rule": "optional"
                  },
                  "smartTippingThresholdMoney": {
                    "type": "squareup.common.Money",
                    "id": 2,
                    "rule": "optional"
                  },
                  "smartTippingUnderThresholdOptions": {
                    "type": "squareup.common.tipping.TipOption",
                    "id": 3,
                    "rule": "repeated"
                  },
                  "smartTippingOverThresholdOptions": {
                    "type": "squareup.common.tipping.TipOption",
                    "id": 4,
                    "rule": "repeated"
                  },
                  "tippingOptions": {
                    "type": "squareup.common.tipping.TipOption",
                    "id": 5,
                    "rule": "repeated"
                  },
                  "allowManualTipEntry": {
                    "type": "bool",
                    "id": 6,
                    "rule": "optional"
                  },
                  "manualTipEntrySmallestMaxMoney": {
                    "type": "squareup.common.Money",
                    "id": 7,
                    "rule": "optional"
                  },
                  "manualTipEntryLargestMaxMoney": {
                    "type": "squareup.common.Money",
                    "id": 8,
                    "rule": "optional"
                  },
                  "manualTipEntryMaxPercentage": {
                    "type": "double",
                    "id": 9,
                    "rule": "optional"
                  },
                  "clientCalculatedTipOption": {
                    "type": "squareup.common.tipping.TipOption",
                    "id": 10,
                    "rule": "repeated"
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.common?.nested?.tipping?.nested?.TipOption && !$root.nested?.squareup?.nested?.common?.nested?.tipping?.nested?.TippingPreferences) {
	$root.addJSON(jsonData);
}

/**
 * Represents an available tipping amount.
 *
 * Display tip options using the following guidelines:
 *  - If there's a percentage, format it as a percentage and use that.
 *  - Otherwise, show the formatted tip_money, omitting the cents if there are none ($1, not $1.00).
 *
 * SOURCE TipOption @ squareup/common/tipping.proto at 15:1
 */
export const TipOption = $root.lookupType('squareup.common.tipping.TipOption');
fixType(TipOption);
Builder.createAndAttachToType(TipOption);
/**
 * Tipping preferences for a merchant.
 *
 * In addition to the restrictions specified on each field, to be considered valid at least one of the following must be true:
 *  - tipping.tipping_options is non-empty.
 *  - tipping.use_smart_tipping is true.
 *  - allow_manual_tip_entry is true.
 *
 * See https://docs.google.com/a/squareup.com/document/d/19VNrjFmRB4is2FLb7Arx-NSBIj3Hb0hnuS48tiq1aLU/edit
 *
 * SOURCE TippingPreferences @ squareup/common/tipping.proto at 45:1
 */
export const TippingPreferences = $root.lookupType('squareup.common.tipping.TippingPreferences');
fixType(TippingPreferences);
Builder.createAndAttachToType(TippingPreferences);
