import { when } from 'mobx';
import Logger from 'src/Logger';
import type MessengerController from 'src/MessengerController';
import { LocalUtterance } from 'src/MessengerTypes';
import {
  IUtterance,
  Utterance,
} from 'src/gen/squareup/messenger/v3/messenger_service';
import IndexedDB from 'src/utils/IndexedDB';
import { getExistingClientIds } from 'src/utils/transcriptUtils';

/**
 * The table name for IndexedDB in which we store local (failed+pending) utterances for a given
 * customer. Changing this table name requires a version bump to IndexedDB.DB_VERSION.
 */
export const TABLE_LOCAL_UTTERANCE = 'local_utterances';

/**
 * The minimum amount of time to wait until a message is marked as failed. Note that we
 * must have gotten an update from the backend to validate the failure, if indeed we're to
 * call it a failure. This value therefore represents the maximum amount of time over which
 * we may receive updates from the backend that are not expected to contain our new utterance
 * (i.e., are in-flight requests when we sent the message).
 */
export const PENDING_SEND_TIMEOUT = 5000; // in ms

/**
 * A small helper to return the key into indexedDB, which is the transcript id that
 * the utterance is associated to.
 * This id should be previously created by transcriptUtils.createUtteranceClientId().
 *
 * @param {string} utteranceClientId
 */
export function getTranscriptIdFromUtteranceClientId(
  utteranceClientId: string,
): string {
  let transcriptId = '';
  try {
    transcriptId = JSON.parse(utteranceClientId).transcriptId;
  } catch {
    Logger.warn(
      `Failed to parse client id ${utteranceClientId}; expected JSON`,
    );
  }
  return transcriptId;
}

/**
 * The store that governs the local utterances that are saved in IndexedDB.
 */
export class LocalUtterancesStore {
  private _stores: MessengerController;

  /**
   * An underlying IndexedDB instance for locally stored utterances.
   * The key is the transcript id.
   */
  private _localUtterances = new IndexedDB<string, LocalUtterance[]>(
    TABLE_LOCAL_UTTERANCE,
  );

  constructor(stores: MessengerController) {
    this._stores = stores;

    // On every app start, clear invalid data from IndexedDB.
    when(
      () => this._localUtterances.isStorageReady,
      () => {
        this._clearInvalidData();
      },
    );
  }

  /**
   * Remove all local utterances that have invalid data. That includes:
   * 1) With photos attached - photos will have broken local url which
   * will render a broken image
   */
  private _clearInvalidData = async (): Promise<void> => {
    const transcriptIds = await this._localUtterances.getAllKeys();

    transcriptIds.forEach(async (id) => {
      const localUtterances = await this._localUtterances.get(id);

      localUtterances?.forEach(async (localUtterance) => {
        if (
          ((localUtterance.photos && localUtterance.photos?.length > 0) ||
            (localUtterance.files && localUtterance.files.length > 0)) &&
          localUtterance.utterance.metadata?.clientId
        ) {
          await this.remove(localUtterance.utterance.metadata?.clientId);
        }
      });
    });
  };

  /**
   * A local utterance is considered invalid if it already exists on the server or if it is
   * out of page, in which case we remove it from IndexedDB. If a local utterance has timed out,
   * set it to failed.
   *
   * @param {IUtterance[]} utterances
   * The list of server provided utterances.
   * @param {LocalUtterance[]} localUtterances
   * The list of locally stored utterances.
   * @param {number} earliestTimestamp
   * The earliest timestamp to search for local utterances for.
   * @param {number} [latestTimestamp]
   * The latest timestamp to search for local utterances for.
   */
  private _filterInvalid = async (
    utterances: IUtterance[],
    localUtterances: LocalUtterance[],
    earliestTimestamp: number,
    latestTimestamp?: number,
  ): Promise<LocalUtterance[]> => {
    // Create a copy to prevent mutation when replacing updated utterances
    const localUtterancesCopy: LocalUtterance[] = [...localUtterances];
    const serverSideClientIds = getExistingClientIds(utterances);

    // Do a reverse loop so that we can splice along the way
    for (let i = localUtterancesCopy.length - 1; i >= 0; i--) {
      const utterance = localUtterancesCopy[i].utterance;
      const clientId = utterance.metadata?.clientId;

      if (clientId && serverSideClientIds.has(clientId)) {
        // The utterance already exist on the server side, remove it
        localUtterancesCopy.splice(i, 1);
        try {
          await this.remove(clientId);
        } catch {
          Logger.warn(`Failed to remove utterance with client ID ${clientId}`);
        }
      } else if (
        utterance.spokenAtMillis &&
        utterance.spokenAtMillis < earliestTimestamp
      ) {
        // The utterance is out of page, remove it
        localUtterancesCopy.splice(i, 1);
      } else if (
        utterance.spokenAtMillis &&
        latestTimestamp &&
        utterance.spokenAtMillis > latestTimestamp
      ) {
        // The utterance is out of page, remove it
        localUtterancesCopy.splice(i, 1);
      } else if (
        utterance.sendStatus === Utterance.SendStatus.PENDING &&
        (utterance.spokenAtMillis ?? 0) < Date.now() - PENDING_SEND_TIMEOUT
      ) {
        // The pending utterance exceeds the timeout and should be failed now
        Logger.log(
          `(Pending -> Failed) for local utterance with clientId ${clientId}`,
        );
        const failedUtterance: LocalUtterance = {
          utterance: {
            ...utterance,
            sendStatus: Utterance.SendStatus.FAILED,
          },
          messageDestination: localUtterancesCopy[i].messageDestination,
          photos: localUtterancesCopy[i].photos,
        };
        try {
          await this.set(failedUtterance);
          localUtterancesCopy[i] = failedUtterance;
        } catch {
          Logger.warn(`Failed to update local failed utterance ${clientId}`);
        }
      }
    }

    return localUtterancesCopy;
  };

  /**
   * Returns the filtered list of local utterances for a given transcript.
   * Specifically, it retrieves local utterances stored in IndexedDB, and then
   * removes/updates these local utterances based on the server provided utterances
   * (i.e. if a local utterance already exists on the server, remove it, if a local
   * utterance is out of page, remove it, if a local utterance has timed out, set it
   * to failed, etc.).
   *
   * @param {number} transcriptId
   * The transcript ID to query local utterances for.
   * @param {IUtterance[]} utterances
   * The list of server provided utterances.
   * @param {number} earliestTimestamp
   * The earliest timestamp to search for local utterances for.
   * @param {number} [latestTimestamp]
   * The latest timestamp to search for local utterances for.
   */
  get = async (
    transcriptId: number,
    utterances: IUtterance[],
    earliestTimestamp: number,
    latestTimestamp?: number,
  ): Promise<LocalUtterance[]> => {
    try {
      const localUtterances =
        (await this._localUtterances.get(transcriptId.toString())) ?? [];
      return this._filterInvalid(
        utterances,
        localUtterances,
        earliestTimestamp,
        latestTimestamp,
      );
    } catch (error) {
      Logger.warn(`Failed to get local utterances: ${error}`);
      return [];
    }
  };

  /**
   * Either adds or updates the local utterance list with the given utterance.
   * If an utterance with this local (i.e., "client-side") ID already exists, then this will update
   * that utterance. Otherwise, it'll add this utterance to the list of local utterances.
   * <b>NOTE: the check here is done on local id, not the utterance primary key id</b>.
   *
   * @param {LocalUtterance} utterance - The utterance to add / mutate to
   */
  set = (utterance: LocalUtterance): Promise<LocalUtterance[]> => {
    const clientId = utterance.utterance.metadata?.clientId;
    const key = getTranscriptIdFromUtteranceClientId(clientId ?? '');
    return this._localUtterances
      .get(key)
      .then((utterances: LocalUtterance[] | null) => {
        // eslint-disable-next-line no-param-reassign
        utterances = utterances || [];
        const utteranceIndex = utterances.findIndex(
          (u) => u.utterance.metadata?.clientId === clientId,
        );
        if (utteranceIndex < 0) {
          utterances.push(utterance);
        } else {
          // eslint-disable-next-line no-param-reassign
          utterances[utteranceIndex] = utterance;
        }
        return this._localUtterances.set(key, utterances);
      });
  };

  /**
   * Remove an utterance based on its id from the list of utterances from
   * the local storage of a specific conversation.
   *
   * @param {string} utteranceClientId
   * The client id of utterance to remove.
   * @see Utterance.Metadata.clientId
   */
  remove = (utteranceClientId: string): Promise<void | LocalUtterance[]> => {
    const key = getTranscriptIdFromUtteranceClientId(utteranceClientId);
    return this._localUtterances
      .get(key)
      .then((utterances: LocalUtterance[] | null) => {
        if (!utterances) {
          return [];
        }
        const utteranceIndex = utterances.findIndex(
          (u) => u.utterance.metadata?.clientId === utteranceClientId,
        );
        if (utteranceIndex === -1) {
          Logger.warn(
            `Utterance to remove is not found in store with key ${key} and local (client-side) ID ${utteranceClientId}`,
          );
          return utterances;
        }
        utterances.splice(utteranceIndex, 1);
        if (utterances.length === 0) {
          return this._localUtterances.remove(key).then(() => []);
        } else {
          return this._localUtterances.set(key, utterances);
        }
      });
  };
}
