import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Photo, SquareGoReview } from 'src/MessengerTypes';
import CustomerEventCard from 'src/pages/TranscriptViewPage/components/CustomerEventCard/CustomerEventCard';
import SquareGoIcon from 'src/svgs/SquareGoIcon';
import { useRelativeDateWithTimestamp } from 'src/utils/timeUtils';
import { getPhotosFromLocalUtterance } from 'src/utils/photoUtils';
import UtterancePhotos from 'src/pages/TranscriptViewPage/components/Utterance/components/UtterancePhotos/UtterancePhotos';
import { getSquareGoDashboardUrl } from 'src/utils/url';
import { BaseContextualEventCardProps } from 'src/types/BaseContextualEventCardProps';
import './SquareGoReviewEventCard.scss';

const STAR = '★';
const MAX_NUMBER_STARS = 5;

/**
 * The component for a Square Go review event card.
 */
const SquareGoReviewEventCard = observer(
  ({
    item,
    customerImage,
    cardRef,
  }: BaseContextualEventCardProps): ReactElement => {
    const { t } = useTranslation();
    const { data, timestampMillis, attachedUtterance } = item;

    const { stars, message, reviewedService, publicReplyUrl, updatedAtMillis } =
      data as SquareGoReview;

    const [relativeDate, timestamp] = useRelativeDateWithTimestamp(
      updatedAtMillis || 0,
      {
        abbreviated: true,
        useDayOfWeekForYesterday: true,
        omitDateForToday: true,
      },
    );

    let subtitle = t('SquareGoReviewEventCard.subtitle');
    if (updatedAtMillis) {
      // In the event that we are including 'today' in the updated at text, we need a separate translation key.
      // 1) In en_us, we want the string to have a lowercase today, but useRelativeDateWithTimestamp returns it in uppercase
      // 2) In en_es, days of the week have definite articles (el Lunes => "the Monday") but today (hoy) does not get a definite article.
      // Separating into one key that needs the definite article and one that doesn't.
      if (relativeDate) {
        subtitle = t('SquareGoReviewEventCard.updated_at', {
          relativeDate,
          timestamp,
        });
      } else {
        subtitle = t('SquareGoReviewEventCard.updated_today_at', {
          timestamp,
        });
      }
    }

    let normalizedStars = stars !== undefined ? Math.floor(stars) : null;
    if (normalizedStars !== null && normalizedStars < 0) {
      normalizedStars = 0;
    } else if (normalizedStars !== null && normalizedStars > MAX_NUMBER_STARS) {
      normalizedStars = MAX_NUMBER_STARS;
    }

    const photos: Photo[] = attachedUtterance
      ? getPhotosFromLocalUtterance(attachedUtterance)
      : [];

    return (
      <CustomerEventCard
        className="SquareGoReviewEventCard__card"
        timestampMillis={timestampMillis}
        title={t('SquareGoReviewEventCard.title')}
        subtitle={subtitle}
        icon={
          <div
            className="SquareGoReviewEventCard__go-icon"
            onClick={() => {
              window.open(getSquareGoDashboardUrl, '_blank');
            }}
            data-testid="SquareGoReviewEventCard__go-icon"
          >
            <SquareGoIcon />
          </div>
        }
        customerImage={customerImage}
        body={
          <div className="SquareGoReviewEventCard__content">
            <div className="SquareGoReviewEventCard__body_heading">
              {t('SquareGoReviewEventCard.review_header')}
            </div>
            {normalizedStars && (
              <>
                <div className="SquareGoReviewEventCard__stars">
                  <span
                    className="SquareGoReviewEventCard__gold_star"
                    data-testid="SquareGoReviewEventCard__filled_stars"
                  >
                    {STAR.repeat(normalizedStars)}
                  </span>
                  <span
                    className="SquareGoReviewEventCard__grey_star"
                    data-testid="SquareGoReviewEventCard__unfilled_stars"
                  >
                    {STAR.repeat(MAX_NUMBER_STARS - normalizedStars)}
                  </span>
                </div>
              </>
            )}
            {message && <div>{message}</div>}

            {reviewedService && (
              <div className="SquareGoReviewEventCard__service_block">
                <div className="SquareGoReviewEventCard__body_heading">
                  {t('SquareGoReviewEventCard.service_header')}
                </div>
                <div>{reviewedService}</div>
              </div>
            )}

            {photos.length > 0 && (
              <div className="SquareGoReviewEventCard__photos">
                <UtterancePhotos photos={photos} alignment="LEFT" />
              </div>
            )}
          </div>
        }
        link={publicReplyUrl}
        linkText={t('SquareGoReviewEventCard.reply_button_label')}
        cardRef={cardRef}
        id={attachedUtterance?.utterance.id}
      />
    );
  },
);

export default SquareGoReviewEventCard;
