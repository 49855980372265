import { makeAutoObservable } from 'mobx';
import { MessengerPage } from 'src/MessengerTypes';

/**
 * Holds information and history of pages in a Stack.
 */
export default class NavigationStack {
  /** A Stack that keeps track of page history that the user has navigated. */
  _pages: MessengerPage[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  /** The number of pages in the stack. */
  get currentPageCount(): number {
    return this._pages.length;
  }

  /** The current page, if any. */
  get currentPage(): MessengerPage | null {
    return this.currentPageCount > 0
      ? this._pages[this.currentPageCount - 1]
      : null;
  }

  /**
   * Add a page to the Stack.
   * Pages added by this will transition in from the right edge of the drawer.
   *
   * @param {MessengerPage} page - the page to transition to
   */
  navigateTo = (page: MessengerPage): void => {
    this._pages.push(page);
  };

  /**
   * Removes the top level page in the Stack.
   * The page 'below' the top level page will transition in from the left edge of the drawer.
   */
  navigateBack: () => void = () => {
    this._pages.pop();
  };

  /**
   * Remove all pages from the Stack.
   */
  clearNavigation: () => void = () => {
    this._pages = [];
  };

  /**
   * Check what page we came from. Some titles depend on the previous page.
   */
  get previousPage(): MessengerPage | null {
    return this.currentPageCount > 1
      ? this._pages[this.currentPageCount - 2]
      : null;
  }
}
