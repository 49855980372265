import { debounce } from 'debounce';
import { makeAutoObservable } from 'mobx';
import type MessengerController from 'src/MessengerController';
import Api from 'src/api/Api';
import UtteranceSearch from 'src/stores/objects/UtteranceSearch';

// If no debounce timeout is provided, use this default, in milliseconds
const DEFAULT_SEARCH_DEBOUNCE_TIMEOUT = 200;

/**
 * Top level store that tracks search results for utterances. UtteranceSearch is an underlying object
 * that is unique per search instance (i.e. unique combination of query and if automated utterances are included)
 * while SearchUtterancesStore has knowledge of things across individual search instances.
 */
class SearchUtterancesStore {
  private _stores: MessengerController;
  private _api: Api;

  /**
   * Same as _executeNewSearch but waits for a short delay before executing.
   * This function is memoized so calling this again will reset the debounce timer.
   */
  private _debounceUtteranceSearch?: () => Promise<void>;

  search: UtteranceSearch;

  /**
   * Whether to fetch automated utterances.
   */
  includeAutomatedUtterances = true;

  constructor(stores: MessengerController) {
    makeAutoObservable(this);

    this._stores = stores;
    this._api = stores.api;
    this.search = new UtteranceSearch(this._stores, {
      query: '',
      includeAutomatedUtterances: this.includeAutomatedUtterances,
    });
  }

  init = (): void => {
    this._debounceUtteranceSearch = debounce(
      this._executeNewSearch,
      DEFAULT_SEARCH_DEBOUNCE_TIMEOUT,
    );
  };

  /**
   * Used to set the search query and kick off the debounced data fetch for both customer and utterance results
   *
   * @param {string} query
   * The new query to use.
   */
  setQuery = (query: string): void => {
    if (this.search.query === query) return;
    this.search = new UtteranceSearch(this._stores, {
      query,
      includeAutomatedUtterances: this.includeAutomatedUtterances,
    });
    if (query !== '') {
      // Set the loading state here immediately to account for the debounce delay
      this.search.status = 'LOADING';
    }
    this._debounceUtteranceSearch?.();
  };

  /**
   * Kicks off a call to fetch search results for the given search query.
   * This is called when the search query changes or when the user clicks the retry button.
   */
  private _executeNewSearch = async (): Promise<void> => {
    await this.search.get();
  };

  /**
   * Retries the current search query.
   */
  retry = async (): Promise<void> => {
    await this._executeNewSearch();
  };

  /**
   * Sets whether to include automated utterances in the search results.
   *
   * @param {boolean} includeAutomatedUtterances
   * Whether to include automated utterances in the search results.
   */
  setIncludeAutomatedUtterances = async (
    includeAutomatedUtterances: boolean,
  ): Promise<void> => {
    this._stores.searchLogger.endSession('assistant_toggle');

    this.search = new UtteranceSearch(this._stores, {
      query: this.search.query,
      includeAutomatedUtterances,
    });
    this.includeAutomatedUtterances = includeAutomatedUtterances;
    await this.search.get();
  };
}

export default SearchUtterancesStore;
