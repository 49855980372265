import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { observe } from 'mobx';
import { Transition } from 'src/MessengerTypes';
import NavigationStore from 'src/stores/navigation/NavigationStore';

/**
 * Determines the corresponding transition direction to use based off the current navigation state.
 *
 * @param {NavigationStore} navigationStore
 * The navigation that corresponds to the view we are checking the transition direction for.
 */
const useTransition = (
  navigationStore: NavigationStore,
): [Transition, Dispatch<SetStateAction<Transition>>] => {
  const [transitionDirection, setTransitionDirection] =
    useState<Transition>('NONE');

  /**
   * Determines the transition direction during navigation by
   * actively observing the stack movement. (See NavigationStack.ts)
   * If the stack decreases in size, it is equivalent as navigating back,
   * and the page needs to transition from left to right.
   * If the stack increases in size, it is equivalent as navigating forward,
   * and the page will transition from right to left.
   * If messenger is just opened, there shouldn't be any transition on the content
   * as the drawer itself is already animating.
   */
  useEffect(() => {
    observe(navigationStore, 'currentPageCount', (change) => {
      // Avoid setting transition direction if page count changes from 0 to 1 or 1 to 0
      // as this is handled by isMessengerOpen observer below
      if (change.oldValue && change.newValue) {
        if (change.oldValue > change.newValue) {
          setTransitionDirection('LEFT');
        } else if (change.oldValue < change.newValue) {
          setTransitionDirection('RIGHT');
        }
      }
    });
    observe(navigationStore, 'isOpen', (change) => {
      if (change.oldValue != null) {
        if (!change.oldValue && change.newValue) {
          setTransitionDirection('NONE');
        }
      }
    });
    // TODO (#5429): re-enable eslint rule in the next line, or remove this TODO
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [transitionDirection, setTransitionDirection];
};

export default useTransition;
