import React, { ReactNode, Component, createRef } from 'react';
import { observer } from 'mobx-react';
import {
  PHOTOS_INTERSECTION_OBSERVER_ROOT_ID,
  PHOTOS_THUMBNAIL_QUERY_PARAM,
} from 'src/utils/photoUtils';
import { getShadowRoot } from 'src/utils/shadowDomUtils';
import { Photo } from 'src/MessengerTypes';
import './UtterancePhotos.scss';
import { MarketContentCard } from 'src/components/Market';
import MessengerControllerContext from 'src/context/MessengerControllerContext';
import MessengerController from 'src/MessengerController';

export type UtterancePhotosProps = {
  photos: Photo[];
  alignment: 'LEFT' | 'RIGHT';
};

type UtterancePhotosState = {
  isVisible: boolean;
};

const INTERSECTION_ROOT_MARGIN = '100px'; // Increase intersection region by 100px
const INTERSECTION_THRESHOLD = 0.01; // Start loading photo when intersects at least 1%

/**
 * Renders the set of photos attached to an utterance. This component uses
 * the IntersectionObserverAPI to lazily load the images when they get in view.
 * It also detects if the attachment token is expired and refresh it before loading.
 * Clicking on the thumbnail should open the gallery mode.
 *
 * @example
 * Basic usage:
 * <UtterancePhoto
 *   photos={getPhotosFromAttachments(utterance.attachments)}
 * />
 * @param {Photo[]} photos
 * The photos to render.
 * @param {string} alignment
 * Determine if the photos are all left or right aligned.
 * @author klim
 */
class UtterancePhotos extends Component<
  UtterancePhotosProps,
  UtterancePhotosState
> {
  static contextType = MessengerControllerContext;
  declare context: MessengerController;

  intersectionObserver: IntersectionObserver | undefined;
  containerRef = createRef<HTMLDivElement>();

  constructor(props: UtterancePhotosProps) {
    super(props);

    this.state = {
      isVisible: false,
    };
  }

  componentDidMount(): void {
    // Get the root element of TranscriptViewItemsList
    let root = null;
    const shadow = getShadowRoot();
    if (shadow) {
      root = shadow.getElementById(PHOTOS_INTERSECTION_OBSERVER_ROOT_ID);
    }
    this.intersectionObserver = new IntersectionObserver(this.onVisible, {
      root,
      rootMargin: INTERSECTION_ROOT_MARGIN,
      threshold: INTERSECTION_THRESHOLD,
    });

    if (this.containerRef && this.containerRef.current) {
      this.intersectionObserver.observe(this.containerRef.current);
    }
  }

  componentWillUnmount(): void {
    if (this.intersectionObserver) {
      this.intersectionObserver.disconnect();
    }
  }

  /**
   * The callback function when intersection is triggered, i.e. when the photo is
   * visible on the viewport.
   *
   * @param {IntersectionObserverEntry[]} entries
   */
  onVisible = (entries: IntersectionObserverEntry[]): void => {
    const { isVisible } = this.state;
    const intersectionEntry = entries[0];

    // If we are in view and not loaded previously
    if (intersectionEntry.isIntersecting && !isVisible) {
      this.setState({ isVisible: true });
    }
  };

  render(): ReactNode {
    const { photos, alignment } = this.props;
    const { isVisible } = this.state;
    const {
      modal: { openPhotosGallery },
      event: { track },
      transcriptView,
    } = this.context;

    let photosComponent;
    if (!isVisible) {
      // Render placeholder before images are loaded
      photosComponent = photos.map((photo, index) => {
        return (
          <div
            className="UtterancePhotos__photo"
            data-testid="UtterancePhotos__placeholder"
            key={index}
          />
        );
      });
    } else {
      photosComponent = photos.map((photo, index) => {
        let url = photo.url;
        if (!photo.isLocal) {
          url += PHOTOS_THUMBNAIL_QUERY_PARAM;
        }
        return (
          <MarketContentCard className="UtterancePhotos__photo" key={index}>
            <img
              className="UtterancePhotos__photo__img"
              data-testid="UtterancePhotos__photos"
              src={url}
              onClick={() => {
                track('Click Photo in Transcript View');
                transcriptView.setSelectedPhoto(photo);
                openPhotosGallery();
              }}
            />
          </MarketContentCard>
        );
      });
    }

    let className = 'UtterancePhotos';
    if (alignment === 'RIGHT') {
      className += ' UtterancePhotos--right';
    } else {
      className += ' UtterancePhotos--left';
    }

    return (
      <div className={className} ref={this.containerRef}>
        {photosComponent}
      </div>
    );
  }
}

export default observer(UtterancePhotos);
