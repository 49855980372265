/**
 * A link icon to display in the checkout link null state.
 */

import React, { ReactElement } from 'react';

function CheckoutLinkNullStateIcon(): ReactElement {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Take payments</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 60C47.464 60 60 47.464 60 32C60 16.536 47.464 4 32 4C16.536 4 4 16.536 4 32C4 47.464 16.536 60 32 60ZM32 64C49.6731 64 64 49.6731 64 32C64 14.3269 49.6731 0 32 0C14.3269 0 0 14.3269 0 32C0 49.6731 14.3269 64 32 64ZM37.6887 20.3127C40.0915 19.7324 42.7392 20.3789 44.6213 22.261C47.4937 25.1333 47.4937 29.7903 44.6213 32.6627L39.5724 37.7116C36.7001 40.584 32.043 40.584 29.1707 37.7116C28.3896 36.9306 28.3896 35.6642 29.1707 34.8832C29.9517 34.1021 31.218 34.1021 31.9991 34.8832C33.3094 36.1934 35.4337 36.1934 36.744 34.8832L41.7929 29.8343C43.1031 28.524 43.1031 26.3997 41.7929 25.0894C40.9367 24.2332 39.7324 23.9341 38.6277 24.2009C37.554 24.4602 36.4734 23.8 36.2141 22.7263C35.9548 21.6526 36.615 20.5719 37.6887 20.3127ZM19.2263 42.4724C21.1084 44.3544 23.7562 45.0009 26.1589 44.4207C27.2326 44.1614 27.8929 43.0808 27.6336 42.0071C27.3743 40.9333 26.2937 40.2731 25.22 40.5324C24.1152 40.7992 22.9109 40.5001 22.0548 39.6439C20.7445 38.3337 20.7445 36.2093 22.0548 34.899L27.1037 29.8501C28.4139 28.5399 30.5383 28.5399 31.8486 29.8501C32.6296 30.6312 33.8959 30.6312 34.677 29.8501C35.458 29.0691 35.458 27.8028 34.677 27.0217C31.8046 24.1494 27.1476 24.1494 24.2752 27.0217L19.2264 32.0706C16.354 34.943 16.354 39.6 19.2263 42.4724Z"
        fill="#006AFF"
      />
    </svg>
  );
}

export default CheckoutLinkNullStateIcon;
