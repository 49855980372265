import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { MarketTooltip, MarketPill } from 'src/components/Market';
import './PendingVerificationTooltipPill.scss';

/**
 * Component that renders a tooltip indicating that the item is disabled because they are
 * not on M+ subscription while TFV is in progress.
 */
const PendingVerificationTooltipPill = observer((): ReactElement => {
  const { t } = useTranslation();

  return (
    <MarketTooltip
      data-testid="PendingVerificationTooltipPill"
      className="PendingVerificationTooltipPill"
      slot="trailing-accessory"
      interaction="hover"
      popoverPlacement="top"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div slot="content" className="PendingVerificationTooltipPill__content">
        {t('PendingVerificationTooltipPill.text')}
      </div>
      <MarketPill
        className="PendingVerificationTooltipPill__pill"
        variant="normal"
        interactive={false}
        slot="trigger"
      >
        {t('MessagesPlus.upgrade')}
      </MarketPill>
    </MarketTooltip>
  );
});

export default PendingVerificationTooltipPill;
