/**
 * This utils provide functions to quickly get the shadow root and the react
 * root (which is the top level component inside our shadow dom).
 */

// When rendering within a shadowRoot,
// this ID is used to determine the host element to query against
export const MESSENGER_HOST_ID =
  process.env.MESSENGER_HOST_ELEMENT_ID || 'messenger-host';
// This should be the id of the component in our host app that we embed into
// We should use the environment variable primarily, and use this otherwise.
export const MESSENGER_DEFAULT_ID = 'messenger';
// The id of the top level component within the shadow root.
export const SHADOW_REACT_ROOT_ID = 'SquareMessenger';
// Selector that uniquely identifies the shadow root.
export const SHADOW_REACT_ROOT_SELECTOR = `#${SHADOW_REACT_ROOT_ID}`;

/**
 * Get the shadow root, used mainly for looking up descendant components via getElementById.
 */
export function getShadowRoot(): ShadowRoot | undefined {
  // The messenger element may be rendered within a shadowRoot
  const messengerHost =
    document.querySelector(`#${MESSENGER_HOST_ID}`)?.shadowRoot || document;

  const messenger = messengerHost.querySelector(
    `#${process.env.MESSENGER_CONTENT_ELEMENT_ID || MESSENGER_DEFAULT_ID}`,
  );

  return messenger?.shadowRoot ?? undefined;
}

/**
 * Get the react root, used to insert components at the top level, such as modals.
 */
export function getReactRoot(): HTMLElement | undefined {
  return getShadowRoot()?.getElementById(SHADOW_REACT_ROOT_ID) ?? undefined;
}
