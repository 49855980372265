import { EcomFormQuestionAnswer, SupportedCountry } from 'src/MessengerTypes';
import Logger from 'src/Logger';
import { Utterance as UtteranceProto } from 'src/gen/squareup/messenger/v3/messenger_service';
import { renderDate, renderTime } from 'src/utils/timeUtils';
import { i18nPhoneNumber } from 'src/i18n/formatValues';

/**
 * Render a raw form answer in a format that's more legible for the seller.
 *
 * @param {EcomFormQuestionAnswer} questionAnswer
 * The Q&A pair to render an answer for.
 * @param {string} dateUsedForTimeRendering
 * The calendar date in the format YYYY-MM-DD used to render the time.  The calendar date is necessary to convert the provided time into the local time of the merchant.
 * @param {number} utteranceId
 * The utterance ID for the whole form submission. For error logging only.
 * @param {SupportedCountry} countryCode
 * The locale for rendering phone numbers in.
 * @param {string} timezone
 * The timezone for rendering times in.
 * @returns {string} The displayable answer.
 */
export function renderFormAnswer(
  questionAnswer: EcomFormQuestionAnswer,
  dateUsedForTimeRendering: string,
  utteranceId: number,
  countryCode: SupportedCountry,
  timezone: string,
): string {
  const { questionName, answer, type } = questionAnswer;
  let renderedAnswer = '';

  if (typeof answer !== 'string') {
    Logger.logWithSentry(
      `MALFORMED_FORM_ANSWER for utterance id ${utteranceId}. Question name: ${questionName} AI: Look at utterance metadata and see why the answer isn't a string`,
    );
  } else {
    switch (type) {
      case UtteranceProto.Metadata.EcomFormEntry.EcomFormQuestionAnswer.Type
        .DATE:
        // The T00:00 forces Date to use local time instead of UTC
        renderedAnswer = renderDate(Date.parse(`${answer}T00:00`));
        break;
      case UtteranceProto.Metadata.EcomFormEntry.EcomFormQuestionAnswer.Type
        .TIME:
        // answer format is a UTC offset time, e.g. 14:00:00-04:00, so it can be
        // concatenated with the date chosen above and passed to renderTime().
        renderedAnswer = renderTime(
          new Date(`${dateUsedForTimeRendering} ${answer}`).valueOf(),
          timezone,
        );
        break;
      case UtteranceProto.Metadata.EcomFormEntry.EcomFormQuestionAnswer.Type
        .PHONE:
        renderedAnswer = i18nPhoneNumber(answer as string, countryCode) ?? '';
        break;
      default:
        renderedAnswer = answer;
    }
  }
  return renderedAnswer;
}
