/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../Fixes';
import Builder from '../../../Builder';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "client": {
        "nested": {
          "orders": {
            "nested": {
              "OrderChannel": {
                "fields": {
                  "channels": {
                    "type": "squareup.client.orders.MappedChannelData",
                    "id": 1,
                    "rule": "repeated"
                  }
                }
              },
              "MappedChannelData": {
                "fields": {
                  "id": {
                    "type": "string",
                    "id": 1,
                    "rule": "optional"
                  },
                  "name": {
                    "type": "string",
                    "id": 2,
                    "rule": "optional"
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.client?.nested?.orders?.nested?.OrderChannel && !$root.nested?.squareup?.nested?.client?.nested?.orders?.nested?.MappedChannelData) {
	$root.addJSON(jsonData);
}

/**
 * SOURCE OrderChannel @ squareup/client/orders/order-channel.proto at 7:1
 */
export const OrderChannel = $root.lookupType('squareup.client.orders.OrderChannel');
fixType(OrderChannel);
Builder.createAndAttachToType(OrderChannel);
/**
 * SOURCE MappedChannelData @ squareup/client/orders/order-channel.proto at 11:1
 */
export const MappedChannelData = $root.lookupType('squareup.client.orders.MappedChannelData');
fixType(MappedChannelData);
Builder.createAndAttachToType(MappedChannelData);
