import {
  parsePhoneNumberFromString,
  AsYouType,
  getExampleNumber,
} from 'libphonenumber-js';
import examples from 'libphonenumber-js/examples.mobile.json';
import { SupportedCountry } from 'src/MessengerTypes';

/**
 * Takes a phone number string and returns it in E.164 format
 * If a country is provided, it will output the country code;
 * otherwise, it will default to the US.
 *
 * For example:
 * e164PhoneNumber('(415) 872-8738', 'US') will return '+14158728738'
 *
 * @param {string} phoneNumber Phone number string
 * @param {SupportedCountry} country Country of phone number
 * @returns phone number in E.164 format
 */
export function e164PhoneNumber(
  phoneNumber: string,
  country?: SupportedCountry,
): string | undefined {
  const parsedNumber = parsePhoneNumberFromString(phoneNumber, country ?? 'US');
  return parsedNumber?.format('E.164');
}

/**
 * This function takes in a string phone number and outputs a formatted phone number
 * defaulted to the US format, if possible.
 * If a country is provided, it will output in the country's format instead.
 *
 * @param {string} contact
 * @param {SupportedCountry} [country]
 */
export function i18nPhoneNumber(
  contact: string,
  country?: SupportedCountry,
): string | null {
  const parsedNumber = parsePhoneNumberFromString(contact, country ?? 'US');
  if (parsedNumber) {
    return parsedNumber.formatNational();
  }
  return null;
}

/**
 * Returns a formatted phone number based on the country provided,
 * or a redacted placeholder number.
 *
 * @param {string} phone - unformatted phone number
 * @param {SupportedCountry} [country]
 */
export function i18nPhoneNumberOrRedacted(
  phone: string,
  country: SupportedCountry,
): string {
  switch (country) {
    case 'US':
    case 'CA':
      return i18nPhoneNumber(phone, country) ?? 'XXX-XXX-XXXX';
    default:
      return phone;
  }
}

/**
 * Formats a partial phone number.
 * Example:
 * For input 'US' and '23442' => '(234) 42'
 *
 * @param {string} partial - the number that the user
 * has typed so far
 * @param {SupportedCountry} country - the country to format
 * the number for
 * @returns {string} - a partial phone number, formatted
 * according to the country
 */
export function i18nPartialPhoneNumber(
  partial: string,
  country: SupportedCountry,
): string {
  const asYouType = new AsYouType(country);
  const userInput = asYouType.input(partial) ?? '';
  // US/CA number compatibility with <input>: If the input is
  // (XXX) or X (XXX), then trim the last )
  if (userInput.endsWith(')')) {
    return userInput.slice(0, -1);
  }
  return userInput;
}

/**
 * Returns a template for a country's phone number.
 * For example, if 'US', returns '(000) 000-0000'.
 *
 * @param {SupportedCountry} country - the country whose
 * template we should use
 * @param {string} [placeholderCharacter] - optional,
 * specify a character to use in the template. default is 0.
 */
export function i18nPhonePlaceholder(
  country: SupportedCountry,
  placeholderCharacter?: string,
): string {
  // This example has numbers, which we want to replace
  // with '0'
  const example = getExampleNumber(country, examples);
  if (!example || !example.nationalNumber) {
    return '';
  }

  return i18nPartialPhoneNumber(
    example?.nationalNumber as string,
    country,
  ).replace(/\d/g, placeholderCharacter ?? '0');
}
