/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../../Fixes';
import Builder from '../../../../Builder';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "connect": {
        "nested": {
          "v2": {
            "nested": {
              "common": {
                "nested": {
                  "MeasurementUnit": {
                    "fields": {
                      "customUnit": {
                        "type": "squareup.connect.v2.common.MeasurementUnit.Custom",
                        "id": 1,
                        "rule": "optional"
                      },
                      "areaUnit": {
                        "type": "squareup.connect.v2.common.MeasurementUnit.Area",
                        "id": 2,
                        "rule": "optional"
                      },
                      "lengthUnit": {
                        "type": "squareup.connect.v2.common.MeasurementUnit.Length",
                        "id": 3,
                        "rule": "optional"
                      },
                      "volumeUnit": {
                        "type": "squareup.connect.v2.common.MeasurementUnit.Volume",
                        "id": 4,
                        "rule": "optional"
                      },
                      "weightUnit": {
                        "type": "squareup.connect.v2.common.MeasurementUnit.Weight",
                        "id": 5,
                        "rule": "optional"
                      },
                      "genericUnit": {
                        "type": "squareup.connect.v2.common.MeasurementUnit.Generic",
                        "id": 6,
                        "rule": "optional"
                      },
                      "timeUnit": {
                        "type": "squareup.connect.v2.common.MeasurementUnit.Time",
                        "id": 7,
                        "rule": "optional"
                      },
                      "type": {
                        "type": "squareup.connect.v2.common.MeasurementUnit.UnitType",
                        "id": 8,
                        "rule": "optional"
                      }
                    },
                    "nested": {
                      "Custom": {
                        "fields": {
                          "name": {
                            "type": "string",
                            "id": 1,
                            "rule": "optional"
                          },
                          "abbreviation": {
                            "type": "string",
                            "id": 2,
                            "rule": "optional"
                          }
                        }
                      },
                      "Area": {
                        "values": {
                          "INVALID_AREA": 0,
                          "IMPERIAL_ACRE": 4,
                          "IMPERIAL_SQUARE_INCH": 1,
                          "IMPERIAL_SQUARE_FOOT": 5,
                          "IMPERIAL_SQUARE_YARD": 6,
                          "IMPERIAL_SQUARE_MILE": 7,
                          "METRIC_SQUARE_CENTIMETER": 2,
                          "METRIC_SQUARE_METER": 3,
                          "METRIC_SQUARE_KILOMETER": 8
                        }
                      },
                      "Length": {
                        "values": {
                          "INVALID_LENGTH": 0,
                          "IMPERIAL_INCH": 1,
                          "IMPERIAL_FOOT": 4,
                          "IMPERIAL_YARD": 5,
                          "IMPERIAL_MILE": 6,
                          "METRIC_MILLIMETER": 2,
                          "METRIC_CENTIMETER": 7,
                          "METRIC_METER": 3,
                          "METRIC_KILOMETER": 8
                        }
                      },
                      "Volume": {
                        "values": {
                          "INVALID_VOLUME": 0,
                          "GENERIC_FLUID_OUNCE": 1,
                          "GENERIC_SHOT": 4,
                          "GENERIC_CUP": 5,
                          "GENERIC_PINT": 6,
                          "GENERIC_QUART": 7,
                          "GENERIC_GALLON": 8,
                          "IMPERIAL_CUBIC_INCH": 9,
                          "IMPERIAL_CUBIC_FOOT": 10,
                          "IMPERIAL_CUBIC_YARD": 11,
                          "METRIC_MILLILITER": 2,
                          "METRIC_LITER": 3
                        }
                      },
                      "Weight": {
                        "values": {
                          "INVALID_WEIGHT": 0,
                          "IMPERIAL_WEIGHT_OUNCE": 1,
                          "IMPERIAL_POUND": 5,
                          "IMPERIAL_STONE": 6,
                          "METRIC_MILLIGRAM": 2,
                          "METRIC_GRAM": 3,
                          "METRIC_KILOGRAM": 4
                        }
                      },
                      "Generic": {
                        "values": {
                          "INVALID_GENERIC_UNIT": 0,
                          "UNIT": 1
                        }
                      },
                      "Time": {
                        "values": {
                          "INVALID_TIME": 0,
                          "GENERIC_MILLISECOND": 1,
                          "GENERIC_SECOND": 2,
                          "GENERIC_MINUTE": 3,
                          "GENERIC_HOUR": 4,
                          "GENERIC_DAY": 5
                        }
                      },
                      "UnitType": {
                        "values": {
                          "INVALID_TYPE": 0,
                          "TYPE_CUSTOM": 1,
                          "TYPE_AREA": 2,
                          "TYPE_LENGTH": 3,
                          "TYPE_VOLUME": 4,
                          "TYPE_WEIGHT": 5,
                          "TYPE_TIME": 7,
                          "TYPE_GENERIC": 6
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.common?.nested?.MeasurementUnit && !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.common?.nested?.MeasurementUnit?.nested?.Custom && !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.common?.nested?.MeasurementUnit?.nested?.Area && !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.common?.nested?.MeasurementUnit?.nested?.Length && !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.common?.nested?.MeasurementUnit?.nested?.Volume && !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.common?.nested?.MeasurementUnit?.nested?.Weight && !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.common?.nested?.MeasurementUnit?.nested?.Generic && !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.common?.nested?.MeasurementUnit?.nested?.Time && !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.common?.nested?.MeasurementUnit?.nested?.UnitType) {
	$root.addJSON(jsonData);
}

/**
 * --
 * @desc Represents a unit of measurement to use with a quantity, such as ounces
 * or inches. Exactly one of the following fields are required: `custom_unit`,
 * `area_unit`, `length_unit`, `volume_unit`, and `weight_unit`.
 * --
 *
 * SOURCE MeasurementUnit @ squareup/connect/v2/common/measurement_units.proto at 24:1
 */
export const MeasurementUnit = $root.lookupType('squareup.connect.v2.common.MeasurementUnit');
fixType(MeasurementUnit);
Builder.createAndAttachToType(MeasurementUnit);
/**
 * --
 * @desc The information needed to define a custom unit, provided by the seller.
 * --
 *
 * SOURCE Custom @ squareup/connect/v2/common/measurement_units.proto at 54:3
 */
MeasurementUnit.Custom = $root.lookupType('squareup.connect.v2.common.MeasurementUnit.Custom');
fixType(MeasurementUnit.Custom, {
  name: { required: true },
  abbreviation: { required: true },
});
Builder.createAndAttachToType(MeasurementUnit.Custom);
/**
 * --
 * The below enums represent the available standard family types.
 *
 * https://en.wikipedia.org/wiki/Imperial_units
 * https://en.wikipedia.org/wiki/United_States_customary_units
 * https://en.wikipedia.org/wiki/Cooking_weights_and_measures
 *
 * Each enum value is named with the following convention:
 *
 * SYSTEM _ TYPE _ VALUE_NAME
 *
 * SYSTEM: Is required. It is the measurement system, like Imperial, Metric, or US Customary.
 * For units which have overlapping names in multiple systems, use the prefix "GENERIC".
 *
 * TYPE: Is optional. It is used to differentiate between systems with overlapping value names. Eg fluid ounce vs. weight ounce.
 *
 *
 * VALUE_NAME: Is required. It is the actual name of the unit.
 *
 * Examples
 * --------------------------------------------------------------------------------
 * - METRIC_KILOGRAM:     METRIC   (System)                   KILOGRAM (Value Name)
 * - METRIC_GRAM:         METRIC   (System)                   GRAM     (Value Name)
 * - GENERIC_FLUID_OUNCE: GENERIC  (System)   FLUID (Type)    OUNCE    (Value Name)
 *
 * Unit list should be kept in sync with https://docs.google.com/spreadsheets/d/1RKnk01b3D2yA36etIitL7TuaOxHRE_QckpWRdW9Kq_k
 * --
 * --
 * @desc Unit of area used to measure a quantity.
 * --
 *
 * SOURCE Area @ squareup/connect/v2/common/measurement_units.proto at 113:3
 */
MeasurementUnit.Area = $root.lookupEnum('squareup.connect.v2.common.MeasurementUnit.Area').values;
/**
 * --
 * @desc The unit of length used to measure a quantity.
 * --
 *
 * SOURCE Length @ squareup/connect/v2/common/measurement_units.proto at 162:3
 */
MeasurementUnit.Length = $root.lookupEnum('squareup.connect.v2.common.MeasurementUnit.Length').values;
/**
 * --
 * @desc The unit of volume used to measure a quantity.
 * --
 *
 * SOURCE Volume @ squareup/connect/v2/common/measurement_units.proto at 211:3
 */
MeasurementUnit.Volume = $root.lookupEnum('squareup.connect.v2.common.MeasurementUnit.Volume').values;
/**
 * --
 * @desc Unit of weight used to measure a quantity.
 * --
 *
 * SOURCE Weight @ squareup/connect/v2/common/measurement_units.proto at 275:3
 */
MeasurementUnit.Weight = $root.lookupEnum('squareup.connect.v2.common.MeasurementUnit.Weight').values;
/**
 * SOURCE Generic @ squareup/connect/v2/common/measurement_units.proto at 311:3
 */
MeasurementUnit.Generic = $root.lookupEnum('squareup.connect.v2.common.MeasurementUnit.Generic').values;
/**
 * --
 * @desc Unit of time used to measure a quantity (a duration).
 * --
 *
 * SOURCE Time @ squareup/connect/v2/common/measurement_units.proto at 323:3
 */
MeasurementUnit.Time = $root.lookupEnum('squareup.connect.v2.common.MeasurementUnit.Time').values;
/**
 * --
 * @desc Describes the type of this unit and indicates which field contains the unit information. This is an ‘open’ enum.
 * --
 *
 * SOURCE UnitType @ squareup/connect/v2/common/measurement_units.proto at 357:3
 */
MeasurementUnit.UnitType = $root.lookupEnum('squareup.connect.v2.common.MeasurementUnit.UnitType').values;
