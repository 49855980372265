import React, { ReactElement } from 'react';

/**
 * An icon representing the email medium.
 */
const EmailIcon = (): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="14"
    fill="none"
    viewBox="0 0 20 14"
  >
    <title>Email</title>
    <path
      fill="#000"
      fillOpacity=".9"
      d="M17 0H3C1.34 0 0 1.34 0 3v8c0 1.66 1.34 3 3 3h14c1.66 0 3-1.34 3-3V3c0-1.66-1.34-3-3-3Zm-.17 2L10 6.78 3.17 2h13.66ZM17 12H3c-.55 0-1-.45-1-1V3.62l7.43 5.2a.988.988 0 0 0 1.14 0L18 3.62V11c0 .55-.45 1-1 1Z"
    />
  </svg>
);

export default EmailIcon;
