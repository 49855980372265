import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import './FileFirstTimeModal.scss';
import MessengerModalPartial from 'src/components/MessengerModalPartial/MessengerModalPartial';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import {
  MarketAccessory,
  MarketButton,
  MarketList,
  MarketRow,
} from 'src/components/Market';
import { useTranslation } from 'react-i18next';
import GenericFileIcon from 'src/svgs/GenericFileIcon';
import { Medium } from 'src/gen/squareup/messenger/v3/messenger_service';
import { bytesToSizeLabel } from 'src/utils/fileUtils';
import { onKeyDownEnter } from 'src/utils/keyboardUtils';
import SensitiveInformationIcon from 'src/svgs/SensitiveInformationIcon';

/**
 * A modal shown to users the first time they access the file sending feature.
 * Includes limitations to file type and warning in sending sensitive information.
 *
 * @author klim
 */
const FileFirstTimeModal = observer((): ReactElement => {
  const { modal, featureFlag, tooltip } = useMessengerControllerContext();
  const { t } = useTranslation();

  const size = bytesToSizeLabel(
    featureFlag.getMediumFlags(Medium.EMAIL).maxTotalFilesSizeBytes,
  );

  const onClick = (): void => {
    modal.fileFirstTimeOnContinue?.();
    modal.closeModal();
    tooltip.dismiss('FILE');
  };

  return (
    <MessengerModalPartial
      title={t('FileFirstTimeModal.title')}
      close={() => {
        modal.closeModal();
        tooltip.dismiss('FILE');
      }}
      primaryButton={
        <MarketButton
          rank="primary"
          onClick={onClick}
          onKeyDown={(e) => onKeyDownEnter(e, onClick)}
        >
          {t('common.continue')}
        </MarketButton>
      }
      status={null}
    >
      {t('FileFirstTimeModal.description')}
      <h3>{t('FileFirstTimeModal.subtitle')}</h3>
      <MarketList>
        <MarketRow>
          <label slot="label">{t('FileFirstTimeModal.format_label')}</label>
          <p slot="subtext">
            {t('FileFirstTimeModal.format_subtext', { size })}
          </p>
          <MarketAccessory
            className="FileFirstTimeModal__accessory"
            slot="leading-accessory"
            size="image"
          >
            <GenericFileIcon />
          </MarketAccessory>
        </MarketRow>
        <MarketRow>
          <label slot="label">
            {t('FileFirstTimeModal.sensitive_infomation_label')}
          </label>
          <p slot="subtext">
            {t('FileFirstTimeModal.sensitive_infomation_subtext')}
          </p>
          <MarketAccessory
            className="FileFirstTimeModal__accessory"
            slot="leading-accessory"
            size="image"
          >
            <SensitiveInformationIcon />
          </MarketAccessory>
        </MarketRow>
      </MarketList>
    </MessengerModalPartial>
  );
});

export default FileFirstTimeModal;
