/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "common": {
        "nested": {
          "instrument": {
            "nested": {
              "InstrumentType": {
                "values": {
                  "UNKNOWN": 1,
                  "VISA": 2,
                  "MASTER_CARD": 3,
                  "AMERICAN_EXPRESS": 4,
                  "DISCOVER": 5,
                  "DISCOVER_DINERS": 6,
                  "JCB": 7,
                  "BALANCE": 8,
                  "UNION_PAY": 9,
                  "SQUARE_GIFT_CARD_V2": 10,
                  "INTERAC": 11,
                  "SQUARE_CAPITAL_CARD": 12,
                  "EFTPOS": 13,
                  "FELICA": 14,
                  "ALIPAY": 15,
                  "CASH_APP": 16,
                  "EBT": 17,
                  "PAYPAY": 18,
                  "AFTERPAY": 19,
                  "SQUARE_ACCOUNT_BALANCE": 20,
                  "AU_PAY": 21,
                  "MERPAY": 22,
                  "RAKUTEN_PAY": 23,
                  "D_BARAI": 24,
                  "WECHAT_PAY": 25
                }
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.common?.nested?.instrument?.nested?.InstrumentType) {
	$root.addJSON(jsonData);
}

/**
 * A form of payment that Square accepts for money transfer.
 *
 * SOURCE InstrumentType @ squareup/common/instrument.proto at 9:1
 */
export const InstrumentType = $root.lookupEnum('squareup.common.instrument.InstrumentType').values;
