import React, { ReactElement, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Trans, useTranslation } from 'react-i18next';
import {
  MarketButton,
  MarketEmptyState,
  MarketLink,
} from 'src/components/Market';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import { M_PLUS_SUBSCRIPTION_FAQ_URL } from 'src/utils/url';
import { getReferralPageName } from 'src/utils/transcriptUtils';
import './ProhibitedState.scss';

/**
 * Component that renders a message in place of the input bar describing that the user is unable to
 * subscribe to M+ due to the nature of their business.
 */
const ProhibitedState = observer((): ReactElement => {
  const { event, navigation, transcriptView } = useMessengerControllerContext();
  const { t } = useTranslation();

  useEffect(() => {
    event.track('View CBD Null State', {
      referral_page_name: 'conversation',
    });
  }, [event]);

  return (
    <MarketEmptyState className="ProhibitedState" data-testid="ProhibitedState">
      <h3 className="heading-30" slot="primary-text">
        {t('MessagesPlus.empty_state.prohibited.title')}
      </h3>
      <p className="paragraph-30" slot="secondary-text">
        <Trans
          i18nKey="MessagesPlus.empty_state.prohibited.description"
          components={{
            1: (
              <MarketLink href={M_PLUS_SUBSCRIPTION_FAQ_URL} target="_blank" />
            ),
          }}
        />
      </p>
      <MarketButton
        rank="primary"
        slot="actions"
        href={M_PLUS_SUBSCRIPTION_FAQ_URL}
        target="_blank"
        onClick={() => {
          event.track('Click CBD Learn More', {
            referral_page_name: getReferralPageName({
              isTranscriptView:
                navigation.navStoreForUrl.currentPageName === 'TRANSCRIPT_VIEW',
              isEmpty: transcriptView.transcript.viewItems.length === 0,
              isActive: transcriptView.transcript.isActive,
            }),
          });
        }}
      >
        {t('common.learn_more')}
      </MarketButton>
    </MarketEmptyState>
  );
});

export default ProhibitedState;
