/**
 * An icon representing a percentage coupon.
 */
import React, { ReactElement } from 'react';

const CouponPercentageIcon = (props: { color?: string }): ReactElement => {
  const { color } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      data-testid="CouponPercentageIcon"
    >
      <title>Percentage coupon</title>
      <path
        stroke={color || '#000'}
        strokeOpacity=".9"
        strokeWidth="2"
        d="m7.692 20 1.885-.823a.66.66 0 0 1 .538 0L12 20l1.885-.823a.66.66 0 0 1 .538 0l1.885.823 1.885-.823a.66.66 0 0 1 .537 0l1.169.51a.062.062 0 0 0 .024.005c.043 0 .077-.045.077-.1V4.408a.125.125 0 0 0-.004-.032c-.013-.053-.057-.081-.097-.064l-1.17.51a.66.66 0 0 1-.536 0L16.308 4l-1.886.822a.661.661 0 0 1-.537 0L12 4l-1.885.822a.66.66 0 0 1-.537 0L7.692 4l-1.885.822a.66.66 0 0 1-.537 0l-1.169-.51a.06.06 0 0 0-.024-.005c-.043 0-.077.045-.077.1v15.185c0 .01.001.021.004.032.013.052.057.08.097.063l1.169-.51a.66.66 0 0 1 .537 0L7.692 20Z"
        clipRule="evenodd"
      />
      <path
        fill={color || '#000'}
        fillOpacity=".9"
        d="M14.707 10.707a1 1 0 0 0-1.414-1.414l1.414 1.414Zm-5.414 2.586a1 1 0 1 0 1.414 1.414l-1.414-1.414Zm4-4-4 4 1.414 1.414 4-4-1.414-1.414Z"
      />
      <circle cx="14" cy="14" r="1" fill={color || '#000'} fillOpacity=".9" />
      <circle
        cx="14"
        cy="14"
        r=".5"
        stroke={color || '#000'}
        strokeOpacity=".9"
      />
      <circle cx="10" cy="10" r="1" fill={color || '#000'} fillOpacity=".9" />
      <circle
        cx="10"
        cy="10"
        r=".5"
        stroke={color || '#000'}
        strokeOpacity=".9"
      />
    </svg>
  );
};

export default CouponPercentageIcon;
