import { SupportedCountry } from 'src/MessengerTypes';
import { IUnitInformation } from 'src/gen/squareup/messenger/v3/messenger_service';
import {
  IAddress,
  ValidIAddress,
} from 'src/gen/squareup/smsphoneregistry/verification';
import { i18nPartialPhoneNumber } from 'src/i18n/formatValues';
import { phoneRegex, isValidEmail, urlRegex } from 'src/utils/validationUtils';

type ValidationSchema = {
  isValid: (input?: string) => boolean;
  getValue: (
    input?: string,
    countryCode?: SupportedCountry,
  ) => string | undefined;
  errorKey: string;
};

// Validation schema for UnitInformation fields
export const VALIDATION_SCHEMA: {
  address: {
    address1: ValidationSchema;
    address2: ValidationSchema;
    city: ValidationSchema;
    state: ValidationSchema;
    zipCode: ValidationSchema;
  };
} & {
  [Property in Exclude<keyof IUnitInformation, 'address'>]: ValidationSchema;
} = {
  address: {
    address1: {
      isValid: (input?: string) => Boolean(input),
      getValue: (input?: string) => input,
      errorKey: 'UnitVerificationForm.validation_errors.business_address',
    },
    address2: {
      isValid: () => true, // address2 is optional
      getValue: (input?: string) => input,
      errorKey: 'UnitVerificationForm.validation_errors.business_address',
    },
    city: {
      isValid: (input?: string) => Boolean(input),
      getValue: (input?: string) => input,
      errorKey: 'UnitVerificationForm.validation_errors.business_address',
    },
    state: {
      isValid: (input?: string) => Boolean(input),
      getValue: (input?: string) => input,
      errorKey: 'UnitVerificationForm.validation_errors.business_address',
    },
    zipCode: {
      isValid: (input?: string) => Boolean(input),
      getValue: (input?: string) => input,
      errorKey: 'UnitVerificationForm.validation_errors.business_address',
    },
  },
  contactPhoneNumber: {
    isValid: (input?: string) => (input ? phoneRegex.test(input) : false),
    getValue: (input?: string, countryCode?: SupportedCountry) =>
      input
        ? i18nPartialPhoneNumber(input, countryCode ?? 'US') || input
        : input,
    errorKey: 'UnitVerificationForm.validation_errors.phone_number',
  },
  contactEmail: {
    isValid: (input?: string) => (input ? isValidEmail(input) : false),
    getValue: (input?: string) => input,
    errorKey: 'UnitVerificationForm.validation_errors.email',
  },
  websiteUrl: {
    isValid: (input?: string) => (input ? urlRegex.test(input) : false),
    getValue: (input?: string) => {
      if (!input) return input;

      // Check if url has protocol; if not, add it so that the Preview
      // button will open the url correctly.
      try {
        new URL(input);
        // If URL() does not throw, then it's not malformed and must have a protocol
        return input;
      } catch {
        if (!input.startsWith('https://') && !input.startsWith('http://')) {
          return `https://${input}`;
        }
        // Otherwise it could be malformed for some other reason that we are not
        // aware of, so just return the user input
        return input;
      }
    },
    errorKey: 'UnitVerificationForm.validation_errors.website',
  },
  businessName: {
    isValid: (input?: string) => Boolean(input),
    getValue: (input?: string) => input,
    errorKey: 'UnitVerificationForm.validation_errors.default',
  },
  contactFirstName: {
    isValid: (input?: string) => Boolean(input),
    getValue: (input?: string) => input,
    errorKey: 'UnitVerificationForm.validation_errors.default',
  },
  contactLastName: {
    isValid: (input?: string) => Boolean(input),
    getValue: (input?: string) => input,
    errorKey: 'UnitVerificationForm.validation_errors.default',
  },
};

// Required sub-fields for address unit information field
export const REQUIRED_ADDRESS_FIELDS: (keyof ValidIAddress)[] = [
  'address1',
  'city',
  'state',
  'zipCode',
];

/**
 * Retrieves the validation schema for a specific unit information field.
 *
 * @param {object} key information about the unit information field
 * @param {keyof IUnitInformation} key.unitInfoKey Top level unit information key
 * @param {keyof IAddress} key.addressKey
 * (Optional) If `key.unitInfoKey` is 'address', then the specific address field
 * name must be provided as well
 * @returns {ValidationSchema | undefined} validation schema if the unit information
 * key is valid; undefined if not
 */
export const getValidationSchema = (key: {
  unitInfoKey: keyof IUnitInformation;
  addressKey?: keyof IAddress;
}): ValidationSchema | undefined => {
  const { unitInfoKey, addressKey } = key;

  let validationSchema;
  if (unitInfoKey === 'address') {
    if (!addressKey) return undefined;
    validationSchema = VALIDATION_SCHEMA[unitInfoKey][addressKey];
  } else {
    validationSchema = VALIDATION_SCHEMA[unitInfoKey];
  }

  return validationSchema;
};
