/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';
import '../common/currency';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "fees": {
        "nested": {
          "PaymentPriceAllocationAttributes": {
            "fields": {
              "paymentPriceSpec": {
                "type": "squareup.fees.PaymentPriceAllocationSpec",
                "id": 1,
                "rule": "optional"
              },
              "appPlatformToken": {
                "type": "string",
                "id": 2,
                "rule": "optional"
              }
            }
          },
          "PaymentPriceAllocationSpec": {
            "fields": {
              "party": {
                "type": "string",
                "id": 1,
                "rule": "optional"
              },
              "priceSelectors": {
                "type": "string",
                "id": 2,
                "rule": "repeated"
              },
              "feeCalculationAmount": {
                "type": "squareup.common.Money",
                "id": 3,
                "rule": "optional"
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.fees?.nested?.PaymentPriceAllocationAttributes && !$root.nested?.squareup?.nested?.fees?.nested?.PaymentPriceAllocationSpec) {
	$root.addJSON(jsonData);
}

/**
 * --
 * @desc Encapsulates attributes clients need to provide with the payment in order to use payment prices.
 * --
 *
 * SOURCE PaymentPriceAllocationAttributes @ squareup/fees/models.proto at 120:1
 */
export const PaymentPriceAllocationAttributes = $root.lookupType('squareup.fees.PaymentPriceAllocationAttributes');
fixType(PaymentPriceAllocationAttributes, {
  paymentPriceSpec: { required: true },
  appPlatformToken: { required: true },
});
Builder.createAndAttachToType(PaymentPriceAllocationAttributes);
/**
 * SOURCE PaymentPriceAllocationSpec @ squareup/fees/models.proto at 132:1
 */
export const PaymentPriceAllocationSpec = $root.lookupType('squareup.fees.PaymentPriceAllocationSpec');
fixType(PaymentPriceAllocationSpec, {
  party: { notEmpty: true },
  priceSelectors: { required: true },
});
Builder.createAndAttachToType(PaymentPriceAllocationSpec);
