import compress, { ImageMimeType } from 'sq-image-compressor';
import {
  Attachment,
  IStagedAttachment,
  IUploadAttachmentRequest,
  IUploadAttachmentResponse,
  IUploadVoicemailGreetingRequest,
  IUploadVoicemailGreetingResponse,
  IVoicemailGreeting,
  Medium,
  UploadAttachmentRequest,
  UploadVoicemailGreetingRequest,
} from 'src/gen/squareup/messenger/v3/messenger_service';
import Services from 'src/services/Services';
import { LocalFile } from 'src/MessengerTypes';
import { callV3Rpc } from 'src/utils/apiUtils';

/**
 * Api responsible for containing calls related to file management (i.e. upload, compression, etc.).
 */
class FileApi {
  private _services: Services;

  constructor(services: Services) {
    this._services = services;
  }

  /**
   * Upload local files to our server, returning a StagedAttachment representing the file.
   *
   * @param {object} args
   * @param {Blob} args.blob
   * The file to upload.
   * @param {string} args.sellerKey
   * The seller key we are sending this photo to.
   * @param {Medium} args.medium
   * The medium the photo will be sent to.
   * @param {Attachment.AttachmentType} args.type
   * Type of the attachment.
   * @param {string} [args.name]
   * (Optional) Name of the file.
   */
  upload = async ({
    blob,
    sellerKey,
    medium,
    type,
    name,
  }: {
    blob: Blob;
    sellerKey: string;
    medium: Medium;
    type: Attachment.AttachmentType;
    name?: string;
  }): Promise<IStagedAttachment> => {
    // Get the array buffer of the compressed file
    const arrayBuffer = await blob.arrayBuffer();

    const request: IUploadAttachmentRequest = {
      data: new Uint8Array(arrayBuffer),
      mimeType: blob.type,
      sellerKey,
      lastChunk: true, // always true as we only do single chunk upload for now
      medium,
      type,
      name,
    };

    // Make the actual request and get staged attachment
    const response: IUploadAttachmentResponse = await callV3Rpc({
      name: 'UploadAttachment',
      rpc: (x) => this._services.messagesV3.uploadAttachment(x),
      request: UploadAttachmentRequest.create(request),
    });

    if (response.stagedAttachment) {
      return response.stagedAttachment;
    }
    throw new Error(response.status?.debugText);
  };

  /**
   * Upload local photos to our server, which returns StagedAttachment that can be
   * added to a SendMessageRequest. It converts the photos to JPEG format and downsizes them
   * to fit the max attachment size before actually uploading.
   *
   * @param {object} args
   * @param {LocalFile} args.localPhoto
   * File information of the photo.
   * @param {string} args.sellerKey
   * The seller key we are sending this photo to.
   * @param {Medium} args.medium
   * The medium the photo will be sent to.
   * @param {number} args.attachmentSizeLimit
   * The attachment size limit.
   */
  uploadPhoto = async ({
    localPhoto,
    sellerKey,
    medium,
    attachmentSizeLimit,
  }: {
    localPhoto: LocalFile;
    sellerKey: string;
    medium: Medium;
    attachmentSizeLimit: number;
  }): Promise<IStagedAttachment> => {
    // Compress the image, it will skip the compression itself if the file
    // is already smaller than the limit
    const blob = await compress(localPhoto.file, {
      maxSizeInBytes: attachmentSizeLimit,
      outputMimeType: ImageMimeType.JPEG,
    });

    return this.upload({
      blob,
      sellerKey,
      medium,
      type: Attachment.AttachmentType.IMAGE,
    });
  };

  /**
   * Uploads a voicemail greeting recording to the server.
   *
   * @param {Blob} blob
   * The blob containing the data of the voicemail greeting to upload.
   * @returns {[string, IVoicemailGreeting]}
   * Returns the custom ID of the uploaded voicemail greeting (used to set the voicemail in SaveMessengerSettings)
   * as well as the object containing the voicemail greeting itself.
   */
  uploadVoicemailGreeting = async (
    blob: Blob,
  ): Promise<[string, IVoicemailGreeting]> => {
    const arrayBuffer = await blob.arrayBuffer();

    const request: IUploadVoicemailGreetingRequest = {
      data: new Uint8Array(arrayBuffer),
      mimeType: blob.type,
    };

    const {
      customVoicemailId,
      voicemailGreeting,
    }: IUploadVoicemailGreetingResponse = await callV3Rpc({
      name: 'UploadVoicemailGreeting',
      rpc: (x) => this._services.messagesV3.uploadVoicemailGreeting(x),
      request: UploadVoicemailGreetingRequest.create(request),
    });

    if (!customVoicemailId) {
      throw new Error(
        'Error uploading voicemail greeting - customVoicemailId missing.',
      );
    }

    if (!voicemailGreeting) {
      throw new Error(
        'Error uploading voicemail greeting - voicemailGreeting missing.',
      );
    }

    return [customVoicemailId, voicemailGreeting];
  };
}

export default FileApi;
