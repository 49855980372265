/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';
import './currency_codes';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "common": {
        "nested": {
          "Money": {
            "fields": {
              "amount": {
                "type": "int64",
                "id": 2,
                "rule": "optional"
              },
              "currencyCode": {
                "type": "squareup.common.CurrencyCode",
                "id": 3,
                "rule": "optional",
                "options": {
                  "default": "USD"
                }
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.common?.nested?.Money) {
	$root.addJSON(jsonData);
}

/**
 * Next id: 4
 * See https://git.sqcorp.co/projects/SQ/repos/docs/browse/api/money.md
 *
 * SOURCE Money @ squareup/common/currency.proto at 14:1
 */
export const Money = $root.lookupType('squareup.common.Money');
fixType(Money);
Builder.createAndAttachToType(Money);
