/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "validation": {
        "nested": {
          "Range": {
            "fields": {
              "min": {
                "type": "double",
                "id": 1,
                "rule": "optional"
              },
              "max": {
                "type": "double",
                "id": 2,
                "rule": "optional"
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.validation?.nested?.Range) {
	$root.addJSON(jsonData);
}

/**
 * A range of numeric values
 *
 * SOURCE Range @ squareup/common/validation.proto at 77:1
 */
export const Range = $root.lookupType('squareup.validation.Range');
fixType(Range);
Builder.createAndAttachToType(Range);
