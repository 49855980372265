/**
 * An icon representing an image file.
 */
import React, { ReactElement } from 'react';

function ImageFileIcon(): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <title>Image</title>
      <path
        fill="#000"
        fillOpacity=".9"
        d="M12 9a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z"
      />
      <path
        fill="#000"
        fillOpacity=".9"
        fillRule="evenodd"
        d="M5 2a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V5a3 3 0 0 0-3-3H5Zm14 2H5a1 1 0 0 0-1 1v11.586l4-4 2.5 2.5 5.5-5.5 4 4V5a1 1 0 0 0-1-1Zm1 12.414-4-4-5.5 5.5-2.5-2.5-3.937 3.937A1 1 0 0 0 5 20h14a1 1 0 0 0 1-1v-2.586Z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default ImageFileIcon;
