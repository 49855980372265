import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import './UtteranceFiles.scss';
import { IAttachment } from 'src/gen/squareup/messenger/v3/messenger_service';
import FileEventCard from 'src/pages/TranscriptViewPage/components/FileEventCard/FileEventCard';

export type UtteranceFilesProps = {
  files: IAttachment[];
  hasError?: boolean;
  utteranceId?: number;
};

/**
 * Renders the set of files attached to an utterance.
 *
 * @param {IAttachment[]} files
 * The attachement files to render.
 * @param {boolean} [hasError]
 * If true, show a red border around the file cards.
 * @param {number} [utteranceId]
 * The id of utterance that the file is uploaded to. Used for tracking.
 * @author klim
 */
const UtteranceFiles = observer((props: UtteranceFilesProps): ReactElement => {
  const { files, hasError, utteranceId } = props;

  return (
    <div className="UtteranceFiles">
      {files.map((file, index) => (
        <FileEventCard
          key={file.id ?? index}
          file={file}
          hasError={hasError}
          utteranceId={utteranceId}
        />
      ))}
    </div>
  );
});

export default UtteranceFiles;
