import React, { ReactElement } from 'react';

/**
 * A detailed visual icon of the search magnifying glass. Used to help visualize an empty search result.
 */
const SearchGraphicIcon = (): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="96"
    height="96"
    fill="none"
    viewBox="0 0 96 96"
  >
    <title>Search</title>
    <path
      fill="#121212"
      d="m28.58 56.105-.01.01h.01v-.01ZM39.895 67.42a31.906 31.906 0 0 1-6.37-4.945 32.192 32.192 0 0 1-4.94-6.36h-.01L17.27 67.42l-8.095 8.095a4.002 4.002 0 0 0 0 5.655l5.655 5.655a4.002 4.002 0 0 0 5.655 0l19.41-19.41v.005Z"
    />
    <path fill="#006AFF" d="M39.895 56.115h-.175l.175.17v-.17Z" />
    <path
      fill="#121212"
      d="M78.625 17.375C66.13 4.88 45.865 4.88 33.37 17.375 22.825 27.92 21.18 43.99 28.425 56.26v.01l-.02.02A32.213 32.213 0 0 0 39.73 67.545v.03c12.27 7.245 28.34 5.6 38.885-4.945 12.495-12.495 12.495-32.76 0-45.255h.01Z"
    />
    <path
      fill="#fff"
      d="M32 40s8.535 16 24 16 24-16 24-16-8.535-16-24-16-24 16-24 16Z"
    />
    <path
      fill="url(#SearchGraphicIcon_paint0_linear_1400_1897)"
      d="M56 52c-6.615 0-12-5.385-12-12s5.385-12 12-12 12 5.385 12 12-5.385 12-12 12Z"
    />
    <path fill="#121212" d="M56 46a6 6 0 1 0 0-12 6 6 0 0 0 0 12Z" />
    <defs>
      <linearGradient
        id="SearchGraphicIcon_paint0_linear_1400_1897"
        x1="61.735"
        x2="48.675"
        y1="30.065"
        y2="52.69"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E3FF80" />
        <stop offset=".25" stopColor="#C9F136" />
        <stop offset=".75" stopColor="#006AFF" />
      </linearGradient>
    </defs>
  </svg>
);

export default SearchGraphicIcon;
