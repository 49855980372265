import { Status } from 'src/gen/squareup/messenger/v3/messenger_service';

/**
 * Takes a Messenger status code and returns a frustration signal in lowercase kebab-case.
 *
 * @example
 * messengerStatusToFrustrationSignal(Status.Code.SUCCESS) => 'messenger-status-success'
 * @example
 * messengerStatusToFrustrationSignal(Status.Code.CONSENT_DENIED_BY_AI) => 'messenger-status-consent-denied-by-ai'
 * @param {Status.Code} status Status code
 */
export const messengerStatusToFrustrationSignal = (
  status: Status.Code,
): string => {
  const code = Status.Code[status];
  return `messenger-status-${code.toLowerCase().replaceAll('_', '-')}`;
};
