import React, { ReactElement } from 'react';

const FormSubmissionIcon = (): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="20"
    fill="none"
    viewBox="0 0 14 20"
  >
    <title>Form Submission</title>
    <path
      fill="#000"
      d="M12 3c0-.55-.45-1-1-1H3c-.55 0-1 .45-1 1v14c0 .55.45 1 1 1h8c.55 0 1-.45 1-1V3ZM3 0h8c1.66 0 3 1.34 3 3v14c0 1.66-1.34 3-3 3H3c-1.66 0-3-1.34-3-3V3c0-1.66 1.34-3 3-3Z"
    />
    <path fill="#000" d="M10 15H4v-2h6v2Zm-6-4h6V9H4v2Zm0-4h6V5H4v2Z" />
  </svg>
);

export default FormSubmissionIcon;
