import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { MarketContentCard } from 'src/components/Market';
import UpgradePill from 'src/components/UpgradePill/UpgradePill';
import './SuggestionRow.scss';
import AvailableForMessagesPlusTooltip from 'src/pages/TranscriptViewPage/components/PendingVerificationTooltipPill/PendingVerificationTooltipPill';

export type SuggestionRowProps = {
  onClick: () => void;
  text: string;
  icon?: ReactElement;
  isDisabled?: boolean;
  showUpgradePill?: boolean;
};

/**
 * Component that renders a smart suggestion row, such as a
 * smart reply or a request for payment.
 *
 * @example
 * Basic usage:
 * <SuggestionRow
 *  onClick={() => { ... }}
 *  text="Button Label"
 * />
 * @param {() => void} onClick
 * Handler called when the button is clicked.
 * @param {string} text
 * Label to display in the button to the user.
 * @param {ReactElement} [icon]
 * (Optional) Icon to display next to the label.
 * @param {boolean} [isDisabled]
 * (Optional) Indicates whether the suggestion should appear as disabled.
 * @param {boolean} [showUpgradePill]
 * (Optional) When true, show upgrade pill to the right of text.
 * @author klim
 */
const SuggestionRow = observer(
  ({
    onClick,
    text,
    icon,
    isDisabled,
    showUpgradePill,
  }: SuggestionRowProps): ReactElement => (
    <div className="SuggestionRow">
      <MarketContentCard
        className={classNames('SuggestionRow__button', {
          'SuggestionRow__button-disabled': isDisabled,
        })}
        onClick={!isDisabled ? onClick : undefined}
      >
        {icon && <div className="SuggestionRow__icon">{icon}</div>}
        <div className="SuggestionRow__content-container">
          {text}
          {isDisabled && <AvailableForMessagesPlusTooltip />}
          {showUpgradePill && (
            <div className="SuggestionRow__upgrade-pill">
              <UpgradePill />
            </div>
          )}
        </div>
      </MarketContentCard>
    </div>
  ),
);

export default SuggestionRow;
