import { post } from './api';
import { createUserToken, formatAttributes, isSuccess } from './utils';
import { createErrorResult, handleAxiosError } from './error-handling';
import {
  TokenType,
  RecordExclusionOptions,
  RecordExclusionRequest,
  RecordExclusionResponse,
  Result,
} from './types';

export async function recordExclusion({
  experimentName,
  exclusionReason,
  token,
  tokenType = TokenType.ANONYMOUS_VISITOR,
  userAttributes,
  cdpKey,
  env,
}: RecordExclusionOptions): Promise<Result> {
  const userToken = createUserToken(token, tokenType);
  const formattedAttributes = formatAttributes(userAttributes);

  const data = {
    experimentName,
    exclusionReason,
    userToken,
    ...(formattedAttributes && {
      userAttributes: { userAttributes: formattedAttributes },
    }),
    cdpKey,
  };

  try {
    const { status } = await post<
      RecordExclusionRequest,
      RecordExclusionResponse
    >('record-exclusion', data, env);

    if (isSuccess(status)) {
      return { success: true };
    }
    return createErrorResult(status);
  } catch (err: unknown) {
    return handleAxiosError(err);
  }
}
