import { parse } from 'date-fns';
import { TMarketTableV2SortStrategyCallback } from './types';

export const sortByNumber: TMarketTableV2SortStrategyCallback = ({ rowA, rowB, order, index }) => {
  const numberA = Number.parseFloat(rowA?.children[index]?.textContent?.trim());
  const numberB = Number.parseFloat(rowB?.children[index]?.textContent?.trim());

  if (order === 'ascending') {
    return numberA - numberB;
  } else {
    return numberB - numberA;
  }
};

export const sortByString: TMarketTableV2SortStrategyCallback = ({ rowA, rowB, order, index }) => {
  const contentA = rowA?.children[index]?.textContent?.trim()?.toUpperCase();
  const contentB = rowB?.children[index]?.textContent?.trim()?.toUpperCase();

  if (order === 'ascending') {
    if (contentA < contentB) return -1;
    if (contentA > contentB) return 1;
    return 0;
  } else {
    if (contentA < contentB) return 1;
    if (contentA > contentB) return -1;
    return 0;
  }
};

export const sortByDateTime: TMarketTableV2SortStrategyCallback = ({ rowA, rowB, order, index, format }) => {
  const contentA = rowA?.children[index]?.textContent?.trim();
  const contentB = rowB?.children[index]?.textContent?.trim();
  const dateA = parse(contentA, format, new Date());
  const dateB = parse(contentB, format, new Date());

  if (order === 'ascending') {
    if (dateA < dateB) return -1;
    if (dateA > dateB) return 1;
    return 0;
  } else {
    if (dateA < dateB) return 1;
    if (dateA > dateB) return -1;
    return 0;
  }
};
