/**
 * An icon representing a receipt, used to render contextual events (i.e. TranscriptViewItem)
 */
import React, { ReactElement } from 'react';

function ReceiptIcon(): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <title>Receipt</title>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M9 2a1 1 0 0 0-1 1H5a1 1 0 0 0-1 1H1a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h3a1 1 0 0 0 1 1h3a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H9ZM6 19V5h2v14H6ZM2 6h2v12H2V6Zm8-2v16h12V4H10Zm7 3a1 1 0 1 0-2 0v.38a2.74 2.74 0 0 0-.672.316c-.672.439-1.128 1.192-1.128 2.257 0 1.073.68 1.745 1.224 2.103.511.338 1.057.507 1.246.566l.03.01c.145.045.533.176.868.4.356.239.432.426.432.549a.995.995 0 0 1-1 1.023c-.435 0-.826-.346-1.138-.876a1 1 0 0 0-1.724 1.014c.297.504.897 1.325 1.862 1.684V17a1 1 0 1 0 2 0v-.571c1.096-.39 2-1.41 2-2.848 0-1.122-.757-1.834-1.318-2.21a5.545 5.545 0 0 0-1.381-.647l-.013-.004c-.182-.057-.493-.155-.762-.333-.273-.18-.326-.319-.326-.434 0-.235.046-.365.082-.431a.396.396 0 0 1 .14-.153c.148-.096.377-.142.578-.142.121 0 .33.042.548.153.216.109.368.248.449.38a1 1 0 0 0 1.706-1.043c-.32-.522-.791-.888-1.251-1.121A3.574 3.574 0 0 0 17 7.405V7Z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default ReceiptIcon;
