import {
  IUtterance,
  Medium,
  Utterance,
} from 'src/gen/squareup/messenger/v3/messenger_service';
import { TranscriptViewItem, LocalUtterance } from 'src/MessengerTypes';
import {
  createMediumTimestampItem,
  getDialogueConversationToken,
  needsMediumTimestamp,
} from './transcriptUtils';

/**
 * Filters any soft deleted utterances from a list of utterances.
 *
 * @param {IUtterance[]} utterances
 * The list of utterances.
 * @returns {IUtterance[]}
 * The filtered list of utterances.
 */
export const filterSoftDeletedUtterances = (
  utterances: IUtterance[],
): IUtterance[] =>
  utterances.filter((utterance: IUtterance) => {
    return !utterance.metadata?.softDeleted;
  });

/**
 * Convert regular utterances into LocalUtterance type and concat with local utterances.
 *
 * @param {IUtterance[]} utterances
 * List of utterances to convert to local utterance type.
 * @param {LocalUtterance[]} localUtterances
 * List of local utterances to merge with utterances.
 * @returns {LocalUtterance[]}
 * The combined list of utterances and local utterances, in the format of local utterances.
 */
export const mergeUtterancesAndLocalUtterances = (
  utterances: IUtterance[],
  localUtterances: LocalUtterance[],
): LocalUtterance[] => {
  const utterancesAsLocalUtterances = utterances.map((utterance) => ({
    utterance,
  }));
  return [...utterancesAsLocalUtterances, ...localUtterances];
};

/**
 * Extract dialogue utterances and attach them to contextual events by checking
 * if the utterance contains the metadata dialogueConversationToken.
 *
 * The comment of a dialogue event also exist as an utterance on the backend.
 * To prevent the same comment from appearing twice, we remove it from the
 * utterances array.
 *
 * @param {LocalUtterance[]} utterances
 * The merged list of utterances and local utterances to extract dialogue utterances from.
 * @param {TranscriptViewItem[]} contextualEvents
 * The contextual events to attach utterances to.
 * @returns {[LocalUtterance[], TranscriptViewItem[]]}
 * A copy of local utterances and contextual events with dialogue extracted.
 */
export const extractDialogueUtterancesAndAttachToContextualEvents = (
  utterances: LocalUtterance[],
  contextualEvents: TranscriptViewItem[],
): [LocalUtterance[], TranscriptViewItem[]] => {
  // Create a copy of contextual events to prevent mutation when adding attached utterance for feedback
  const contextualEventsCopy = [...contextualEvents];

  const actualUtterances: LocalUtterance[] = []; // temporary variable to store non-dialogue utterances
  for (const localUtterance of utterances) {
    const utterance = localUtterance.utterance;

    // We only care about customer initiated feedback. Merchant initiated feedback
    // contextual events are filtered out during retrieval. They will appear as
    // regular utterances.
    if (
      utterance.speakerType === Utterance.SpeakerType.CUSTOMER &&
      utterance.metadata?.dialogueConversationToken
    ) {
      const eventIndex = contextualEventsCopy.findIndex(
        (event) =>
          getDialogueConversationToken(event) ===
          utterance.metadata?.dialogueConversationToken,
      );
      // Case: This is part of a contextual event
      // If the target event already has an attached utterance, skip it.
      if (
        eventIndex !== -1 &&
        !contextualEventsCopy[eventIndex].attachedUtterance
      ) {
        contextualEventsCopy[eventIndex] = {
          ...contextualEventsCopy[eventIndex],
          // Prefer spokenAtMillis provided by the utterance if present, as it matches the timestamp
          // of the first comment on the feedback. This prevents a feedback comment as showing in the
          // past when the customer submits a comment later than the original feedback.
          // The timestampMillis on the contextual event refers to the `createdAt` value of the feedback.
          timestampMillis:
            localUtterance.utterance.spokenAtMillis ||
            contextualEventsCopy[eventIndex].timestampMillis,
          attachedUtterance: localUtterance,
        };
        continue;
      }
    }

    // Case: This is an actual utterance
    actualUtterances.push(localUtterance);
  }

  return [actualUtterances, contextualEventsCopy];
};

/**
 * Helper function that inserts medium timestamp labels into a list of view items.
 *
 * @param {TranscriptViewItem[]} itemsWithoutTimestamp
 * Items that have not yet had timestamps added to them.
 * @param {Medium} medium
 * The medium associated with the transcript of the item.
 * @returns {TranscriptViewItem[]}
 * The list of items with timestamps added.
 */
export const addTimestampToItems = (
  itemsWithoutTimestamp: TranscriptViewItem[],
  medium: Medium,
): TranscriptViewItem[] => {
  const items: TranscriptViewItem[] = [];

  // Special case for first item
  if (itemsWithoutTimestamp.length > 0) {
    const item = itemsWithoutTimestamp[0];
    items.push(createMediumTimestampItem(item, medium), item);
  }

  for (let i = 1; i < itemsWithoutTimestamp.length; i++) {
    const item = itemsWithoutTimestamp[i];
    const previousItem = itemsWithoutTimestamp[i - 1];

    if (needsMediumTimestamp(item, previousItem)) {
      items.push(createMediumTimestampItem(item, medium));
    }

    items.push(item);
  }

  return items;
};

/**
 * Loop through and add customer image when both conditions are met:
 * - There are consecutive customer cards or utterances (1 or more)
 * - The last item of that set
 *
 * @param {TranscriptViewItem[]} itemsWithoutCustomerImage
 * Items without the customer image added to them.
 * @returns {TranscriptViewItem[]}
 * Items that have had the customer image correctly applied to them.
 */
export const addCustomerImageToItems = (
  itemsWithoutCustomerImage: TranscriptViewItem[],
): TranscriptViewItem[] => {
  const items = [...itemsWithoutCustomerImage];

  for (let i = 0; i < items.length - 1; i++) {
    const item = items[i];
    const nextItem = items[i + 1];

    if (
      item.componentType === 'CUSTOMER_EVENT_CARD' ||
      item.componentType === 'UTTERANCE_CARD'
    ) {
      const nextItemDataAsLocalUtterance = nextItem.data as LocalUtterance;

      if (
        nextItem.componentType !== 'CUSTOMER_EVENT_CARD' &&
        !(
          nextItem.componentType === 'UTTERANCE_CARD' &&
          nextItemDataAsLocalUtterance.utterance.speakerType ===
            Utterance.SpeakerType.CUSTOMER
        )
      ) {
        items[i] = {
          ...items[i],
          showCustomerImage: true,
        };
      }
    }
  }
  // Special case for last item
  if (items.length > 0) {
    const lastItem = items[items.length - 1];
    if (
      lastItem.componentType === 'CUSTOMER_EVENT_CARD' ||
      lastItem.componentType === 'UTTERANCE_CARD'
    ) {
      items[items.length - 1] = {
        ...items[items.length - 1],
        showCustomerImage: true,
      };
    }
  }
  return items;
};

/**
 * To compare if 2 TranscriptViewItems are equal.
 *
 * @param {TranscriptViewItem} a
 * @param {TranscriptViewItem} b
 */
export const areItemsEqual = (
  a?: TranscriptViewItem,
  b?: TranscriptViewItem,
): boolean => {
  return JSON.stringify(a) === JSON.stringify(b);
};
