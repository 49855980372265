import { Component, Host, h, Prop, Element, Watch, Listen, Method, Event, EventEmitter } from '@stencil/core';
import { getNamespacedTagFor } from '../../../utils/namespace';
import {
  TMarketTableV2Selection,
  MarketTableV2SelectionChangeEventDetail,
  MarketTableV2SortOrderChangeDetail,
} from './types';
import { Reorderable, TMarketReorderableOptions, TMarketReorderEventDetail } from '../../../utils/reorderable';
import { isDraggable, TMarketDragEventDetail } from '../../../utils/draggable';
import { sortByNumber, sortByString, sortByDateTime } from './utils';

/**
 * @slot - Default slot for all rows.
 * @part table - The inner table element.
 */

@Component({
  tag: 'market-table-v2',
  styleUrl: 'market-table-v2.css',
  shadow: true,
})
export class MarketTableV2 {
  private rows: Array<HTMLMarketTableV2RowElement>;
  private groups: Array<HTMLMarketTableV2GroupElement>;
  private children: Array<HTMLMarketTableV2RowElement | HTMLMarketTableV2GroupElement>;
  private header: HTMLMarketTableV2RowElement;
  private footer: HTMLMarketTableV2RowElement;
  private reorder: Reorderable;

  @Element() el: HTMLMarketTableV2Element;

  /**
   * Sets the horizontal alignment.
   * Table alignment will be inherited by descendant rows & cells.
   */
  @Prop({ reflect: true }) readonly align: 'left' | 'center' | 'right';

  /**
   * Whether the slotted table groups are collapsible.
   */
  @Prop({ reflect: true }) readonly collapsible: boolean = false;

  /**
   * Sets the `table-layout` algorithm.
   * By default, the column widths are adjusted to fit the content.
   * If column widths are explicitly sized, use `fixed` to speed up render time.
   * See [table-layout](https://developer.mozilla.org/en-US/docs/Web/CSS/table-layout)
   * for more information.
   */
  @Prop({ reflect: true }) readonly layout: 'auto' | 'fixed' = 'auto';

  /**
   * Whether the table is reorderable or not.
   * Setting to `internal` enables reordering table rows internally
   * while `external` also allows dragging to & from other tables.
   */
  @Prop({ reflect: true }) readonly reorderable: TMarketReorderableOptions;

  /**
   * Whether the table is selected.
   * Relevant if the table has rows with a slotted controls.
   */
  @Prop({ mutable: true }) selected: TMarketTableV2Selection = 'false';

  /**
   * Sets the vertical alignment.
   * Table alignment will be inherited by descendant rows & cells.
   */
  @Prop({ reflect: true }) readonly valign: 'bottom' | 'middle' | 'top';

  /**
   * Fired when the table rows are reordered.
   * If a row was dropped into this table from an external table, `oldIndex` is `-1`.
   * If a row was removed from this table and dropped into an external table, `newIndex` is `-1`.
   */
  @Event({ bubbles: true, composed: true }) marketTableV2RowsReordered: EventEmitter<TMarketReorderEventDetail>;

  @Listen('marketInternalTableV2RowSelectionChange')
  @Listen('marketInternalTableV2GroupSelectionChange')
  async onMarketTableV2SelectionChange(e: CustomEvent<MarketTableV2SelectionChangeEventDetail>) {
    const { header, footer } = this;
    const { target, detail } = e;
    const eventSelected = detail.current;

    e.stopPropagation();

    if (target === header || target === footer) {
      // the target is the header or footer, so propagate values downward
      await this.setSelected(eventSelected, { silent: true });
    } else {
      // the target is a child, and it's complicated...
      await this.setSelectedFromChildEvent(e);
    }
  }

  @Listen('marketTableV2CellSortClicked')
  onMarketTableV2CellSortClicked(e: CustomEvent<MarketTableV2SortOrderChangeDetail>) {
    const { el, header, children, footer } = this;
    const sortedCell = e.target as HTMLMarketTableV2CellElement;

    // only allow sorting from the header row
    if (sortedCell.parentElement !== header) return;

    const { current: newSortOrder } = e.detail;
    const headerChildren = [...header.children];
    const sortedColumnIndex = headerChildren.indexOf(sortedCell);
    const { sortStrategy, sortStrategyFormat } = sortedCell;

    // sort the rows
    const sortedChildren = [...children];
    sortedChildren.sort((rowA: HTMLMarketTableV2RowElement, rowB: HTMLMarketTableV2RowElement) => {
      const params = { rowA, rowB, order: newSortOrder, index: sortedColumnIndex };
      if (typeof sortStrategy === 'function') {
        return sortStrategy(params);
      } else if (sortStrategy === 'number') {
        return sortByNumber(params);
      } else if (sortStrategy === 'datetime' && sortStrategyFormat) {
        return sortByDateTime({ ...params, format: sortStrategyFormat });
      } else {
        return sortByString(params);
      }
    });

    // set the header sort values
    sortedCell.sortOrder = newSortOrder;
    headerChildren.forEach((cell: HTMLMarketTableV2CellElement) => {
      cell.sortOrder = cell === sortedCell ? newSortOrder : undefined;
    });

    // render the sorted rows
    sortedChildren.forEach((row: HTMLMarketTableV2RowElement) => {
      el.append(row);
    });
    if (footer) el.append(footer);
  }

  @Listen('marketDragMove')
  onDragMove(e: CustomEvent<TMarketDragEventDetail>) {
    this.reorder.dragMove(e);
  }

  @Listen('marketDragLeave')
  onDragLeave() {
    this.reorder.dragLeave();
  }

  @Listen('marketDragEnd')
  onDragEnd(e: CustomEvent<TMarketDragEventDetail>) {
    this.reorder.dragEnd(e);
  }

  @Listen('marketDragDrop')
  onDragDrop(e: CustomEvent<TMarketDragEventDetail>) {
    this.reorder.dragDrop(e);
  }

  @Watch('collapsible')
  watchCollapsible() {
    const { rows, groups, collapsible } = this;

    groups.forEach((group) => {
      group.collapsible = collapsible;
      group.indent = 0;
    });

    rows.forEach((row) => {
      // per design, don't indent header or footer rows
      if (row.header || row.footer) return;
      // indent rows to line up with groups w/ carets
      row.indent = collapsible ? 1 : 0;
    });
  }

  @Watch('reorderable')
  watchReorderable() {
    const { el, reorder, reorderable, marketTableV2RowsReordered } = this;

    reorder?.destroy();

    const reorderEnabled = ['internal', 'external'].includes(reorderable);
    if (reorderEnabled) {
      const rowTagName = getNamespacedTagFor('market-table-v2-row');
      const groupTagName = getNamespacedTagFor('market-table-v2-group');

      this.reorder = new Reorderable({
        el,
        accepts: [`${rowTagName}:not([header]):not([footer]):not([slot="parent"])`, groupTagName],
        event: marketTableV2RowsReordered,
      });
    }

    this.syncDragEnabled();
  }

  /**
   * @internal
   * Sets selection on the table and propagates the value
   * downwards to its children rows and groups.
   */
  @Method()
  async setSelected(selected: TMarketTableV2Selection, { silent = false } = {}) {
    const { header, footer, children, selected: prevSelected } = this;

    // return if no values have changed
    if (prevSelected === selected) return Promise.resolve();

    // propagate the new values
    this.selected = selected;
    await header?.setSelected(selected, { silent });
    await footer?.setSelected(selected, { silent });
    children?.forEach(async (child) => {
      await child.setSelected(selected, { silent });
    });

    return Promise.resolve();
  }

  private async setSelectedFromChildEvent(e: CustomEvent<MarketTableV2SelectionChangeEventDetail>) {
    const { header, footer, children, selected: prevSelected } = this;
    const { target, detail } = e;
    const { current: childSelected } = detail;

    // get an array of what the children's selected values would be AFTER this event
    const childrenSelected = children.map((child) => {
      // if the target was THIS child, it will be new event value (not .selected)
      if (target === child) return childSelected;
      // otherwise, get the current value directly from this child
      return child.selected;
    });

    // what the table's selected value would be AFTER this event
    const tableSelected = childrenSelected.every((val) => val === 'true')
      ? 'true'
      : childrenSelected.every((val) => val === 'false')
      ? 'false'
      : 'indeterminate';

    // return if no values have changed
    if (prevSelected === tableSelected) return;

    // propagate the new value
    this.selected = tableSelected;
    await header?.setSelected(tableSelected, { silent: true });
    await footer?.setSelected(tableSelected, { silent: true });
  }

  private getElements() {
    this.rows = [...this.el.children].filter((child) => {
      return child.tagName === getNamespacedTagFor('market-table-v2-row').toUpperCase();
    }) as Array<HTMLMarketTableV2RowElement>;

    this.groups = [...this.el.children].filter((child) => {
      return child.tagName === getNamespacedTagFor('market-table-v2-group').toUpperCase();
    }) as Array<HTMLMarketTableV2GroupElement>;

    this.header = this.rows.find((row) => row.header);
    this.footer = this.rows.find((row) => row.footer);
    this.children = [...this.groups, ...this.rows.filter((row) => !row.header && !row.footer)];
  }

  private syncDragEnabled() {
    const { header, footer, rows, groups, reorderable } = this;

    const reorderEnabled = ['internal', 'external'].includes(reorderable);

    if (header) header.dragEnabled = reorderEnabled;
    if (footer) footer.dragEnabled = reorderEnabled;

    rows?.forEach((row) => {
      if (!isDraggable(row)) return;
      row.dragEnabled = reorderEnabled;
    });

    groups?.forEach((group) => {
      group.dragEnabled = reorderEnabled;
      group.reorderable = reorderable;
    });
  }

  private onSlotChange() {
    this.getElements();
    this.watchCollapsible();
    this.syncDragEnabled();
  }

  connectedCallback() {
    this.getElements();
    this.watchCollapsible();
    this.syncDragEnabled();
  }

  componentDidRender() {
    this.watchReorderable();
  }

  render() {
    return (
      <Host class="market-table-v2">
        <div role="table" part="table">
          <slot onSlotchange={() => this.onSlotChange()}></slot>
        </div>
      </Host>
    );
  }
}
