import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import { MessengerSettings } from 'src/gen/squareup/messenger/v3/messenger_service';
import {
  MarketButton,
  MarketDivider,
  MarketList,
  MarketRow,
  MarketSelect,
} from 'src/components/Market';
import { getBookingChannelsUrl } from 'src/utils/url';
import { SHADOW_REACT_ROOT_SELECTOR } from 'src/utils/shadowDomUtils';
import { onKeyDownEnter } from 'src/utils/keyboardUtils';
import {
  KEY_INVOICES_PLUGIN_ENABLED,
  KEY_SQ_ONLINE_PLUGIN_SETTING_ENABLED,
} from 'src/stores/FeatureFlagStore';
import useSheetSectionScrollTo from 'src/hooks/useSheetSectionScrollTo';
import SurfacesList from 'src/pages/SettingsPage/components/SurfacesList/SurfacesList';
import './MessagesPluginSettings.scss';

/**
 * Renders the UI for the Messages Plugin settings on the Settings Page.
 */
const MessagesPluginSettings = observer((): ReactElement => {
  const { featureFlag, settings, event } = useMessengerControllerContext();
  const { t } = useTranslation();

  const {
    pluginResponseTime,
    userPluginResponseTime,
    setUserPluginResponseTime,
    canViewSqOnlineSettings,
    canEnableOnlineBookingSite,
    canEnablePluginOnAppts,
  } = settings;

  const headerRef = useSheetSectionScrollTo('MESSAGES_PLUGIN');

  const onClickEnableOnlineBooking = (): void => {
    event.track('Click Enable Online Booking from Text Us');
  };

  return (
    <div>
      <h3 className="MessagesPluginSettings__title" ref={headerRef}>
        {t('MessagesPluginSettings.title')}
      </h3>
      <p className="paragraph-30 MessagesPluginSettings__description">
        {t('MessagesPluginSettings.description')}
      </p>
      <img
        src="https://conversations-production-f.squarecdn.com/resources/inbox-settings-plugin.png"
        alt={t('MessagesPluginSettings.image_alt_text')}
        className="MessagesPluginSettings__image"
      />
      {(featureFlag.get(KEY_INVOICES_PLUGIN_ENABLED) ||
        (featureFlag.get(KEY_SQ_ONLINE_PLUGIN_SETTING_ENABLED) &&
          canEnableOnlineBookingSite) ||
        canEnablePluginOnAppts ||
        canViewSqOnlineSettings) && (
        <>
          <SurfacesList />
          <MarketDivider margin="small" size="thin" />
          <h5>{t('MessagesPluginSettings.customize_title')}</h5>
          <MarketSelect
            popoverContainer={SHADOW_REACT_ROOT_SELECTOR}
            value={`${
              userPluginResponseTime ??
              pluginResponseTime.pluginResponseTimeValue
            }`}
            onMarketSelectValueDidChange={(e) => {
              const newResponseTimeValue = Number.parseInt(
                e.detail.value as string,
                10,
              );
              setUserPluginResponseTime(newResponseTimeValue);
              event.track('Update Response Time', {
                response_time:
                  MessengerSettings.PluginResponseTime[newResponseTimeValue],
              });
            }}
            data-testid="MessagesPluginSettings__response-time-select"
          >
            <label>{t('MessagesPluginSettings.response_time.title')}</label>
            <MarketList slot="list">
              <MarketRow value={`${MessengerSettings.PluginResponseTime.ASAP}`}>
                <label slot="label">
                  {t('MessagesPluginSettings.response_time.asap')}
                </label>
              </MarketRow>
              <MarketRow
                value={`${MessengerSettings.PluginResponseTime.FEW_HOURS}`}
              >
                <label slot="label">
                  {t('MessagesPluginSettings.response_time.few_hours')}
                </label>
              </MarketRow>
              <MarketRow
                value={`${MessengerSettings.PluginResponseTime.WITHIN_DAY}`}
              >
                <label slot="label">
                  {t('MessagesPluginSettings.response_time.within_day')}
                </label>
              </MarketRow>
            </MarketList>
          </MarketSelect>
        </>
      )}
      {!featureFlag.get(KEY_INVOICES_PLUGIN_ENABLED) &&
        !featureFlag.get(KEY_SQ_ONLINE_PLUGIN_SETTING_ENABLED) &&
        canEnableOnlineBookingSite && (
          <MarketButton
            rank="primary"
            className="MessagesPluginSettings__enable-online-booking-button"
            href={getBookingChannelsUrl}
            target="_blank"
            onClick={onClickEnableOnlineBooking}
            onKeyDown={(e) => onKeyDownEnter(e, onClickEnableOnlineBooking)}
            data-testid="MessagesPluginSettings__enable-online-booking-button"
          >
            {t('MessagesPluginSettings.enable_online_booking_button_label')}
          </MarketButton>
        )}
    </div>
  );
});

export default MessagesPluginSettings;
