import React, { ReactElement } from 'react';

/**
 * Icon that represents a project.
 */
const ProjectIcon = (): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <title>Project</title>
    <path
      fill="#000"
      fillOpacity=".9"
      fillRule="evenodd"
      d="M6 3a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h12a3 3 0 0 0 3-3V6a3 3 0 0 0-3-3H6ZM5 6a1 1 0 0 1 1-1h2.253v3.333H5V6Zm3.253 4.333H5v3.334h3.253v-3.334Zm2 3.334v-3.334H19v3.334h-8.747Zm-2 2H5V18a1 1 0 0 0 1 1h2.253v-3.333Zm2 3.333v-3.333H19V18a1 1 0 0 1-1 1h-7.747Zm0-10.667V5H18a1 1 0 0 1 1 1v2.333h-8.747Z"
      clipRule="evenodd"
    />
  </svg>
);

export default ProjectIcon;
