import React, { ReactElement, RefObject } from 'react';
import { observer } from 'mobx-react';
import { LocalUtterance } from 'src/MessengerTypes';
import MerchantUtteranceSendStatus from 'src/pages/TranscriptViewPage/components/Utterance/components/MerchantUtteranceSendStatus/MerchantUtteranceSendStatus';
import Reactions from 'src/pages/TranscriptViewPage/components/Reactions/Reactions';
import EventCard, {
  SharedEventCardProps,
} from 'src/pages/TranscriptViewPage/components/EventCard/EventCard';
import './MerchantEventCard.scss';
import { isFailedSendStatus } from 'src/utils/transcriptUtils';

export type MerchantEventCardProps = SharedEventCardProps & {
  utterance?: LocalUtterance;
  showSentStatus?: boolean;
  cardRef?: RefObject<HTMLDivElement>;
};

/**
 * Represents a merchant-initiated event that appears like a merchant utterance
 * with a different color. For example, a merchant sent coupon. Appears in a conversation
 * view page.
 *
 * @example
 * Basic usage:
 * <MerchantEventCard
 *   icon={<CouponPercentageIcon />}
 *   title="$5 coupon"
 *   subtitle="Expires Dec 1"
 * />
 *
 * With optional:
 * <MerchantEventCard
 *   timestampMillis={123553401000}
 *   icon={<CouponPercentageIcon />}
 *   title="$5 coupon"
 *   subtitle="Expires Dec 1"
 *   link="/dashboard/coupon"
 *   utterance={ ... }
 *   showSendStatus
 * />
 * @param {number} timestampMillis
 * Time when this event happened, in millisecond. Shown when hovered.
 * @param {ReactElement} icon
 * Icon representing this event. It should generally be a square of size 32x32.
 * @param {string} title
 * Title of event.
 * @param {string} subtitle
 * Usually additional information of the event.
 * @param {string} [link]
 * (Optional) If present, show a button that will open a new tab to the link.
 * @param {string} [linkText]
 * (Optional) Should be present if link is provided. Text to show in the link button.
 * @param {LocalUtterance} [utterance]
 * (Optional) The attached utterance to this event, if present. Used to compute the
 * send status component.
 * @param {boolean} [showSendStatus]
 * (Optional) If true, show the send status of this event.
 * @param {Function} [track]
 * (Optional) If present, call this function when link is clicked. Used mainly to
 * trigger a track on an event.
 * @param {RefObject<HTMLDivElement>} [cardRef]
 * (Optional) Ref that should be set on the root div element of the card.
 * @param {number} [id]
 * (Optional) Utterance id for this event card. Used to highlight the card when the user
 * clicks on a search result.
 * @author klim
 */
const MerchantEventCard = observer(
  (props: MerchantEventCardProps): ReactElement => {
    const {
      timestampMillis,
      icon,
      title,
      subtitle,
      link,
      linkText,
      utterance,
      showSentStatus,
      track,
      cardRef,
      id,
    } = props;

    /**
     * Send up send status component.
     */
    let sendStatusComponent = null;
    if (utterance) {
      sendStatusComponent = (
        <MerchantUtteranceSendStatus
          localUtterance={utterance}
          showSentStatus={showSentStatus}
        />
      );
    }

    const hasError = isFailedSendStatus(utterance?.utterance.sendStatus);

    const reactions = utterance?.utterance.metadata?.reactions?.reactions || [];

    return (
      <div className="Utterance" ref={cardRef}>
        <div className="Utterance__merchant">
          <div />
          <div className="MerchantEventCard__content Utterance__content Utterance__timestamp-container">
            <EventCard
              timestampMillis={timestampMillis}
              icon={icon}
              title={title}
              subtitle={subtitle}
              link={link}
              linkText={linkText}
              track={track}
              hasError={hasError}
              id={id}
            />
            {reactions.length > 0 && <Reactions reactions={reactions} />}
            {sendStatusComponent}
          </div>
        </div>
      </div>
    );
  },
);

export default MerchantEventCard;
