import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Medium } from 'src/gen/squareup/messenger/v3/messenger_service';
import { MarketButton } from 'src/components/Market';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import EmptyState from 'src/components/EmptyState/EmptyState';
import AlertIcon from 'src/svgs/AlertIcon';

/**
 * EmptyState that is displayed in the Transcript View when a transcript with no utterances is blocked.
 */
const BlockedEmptyState = observer(() => {
  const { event, user, transcriptView } = useMessengerControllerContext();
  const { transcript } = transcriptView;
  const { t } = useTranslation();

  return (
    <EmptyState
      icon={<AlertIcon />}
      title={t('BlockedEmptyState.message', {
        medium:
          transcript.medium === Medium.EMAIL
            ? t('common.mediumSentenceLongForm.email')
            : t('common.mediumSentenceLongForm.sms'),
      })}
      button={
        <MarketButton
          onClick={() => {
            event.track('Click Unblock', {
              merchant_token: user.merchantToken,
              transcript_ids: [transcript.id],
            });
            transcriptView.unblockTranscript();
          }}
        >
          {t('BlockedEmptyState.button_label')}
        </MarketButton>
      }
    />
  );
});

export default BlockedEmptyState;
