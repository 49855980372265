/**
 * throwError can be used as a shorthand to log to both Sentry
 * and throw an error with the same message.
 *
 * handleResponseStatus should be used to handle common error codes
 * from backend requests.
 */

import {
  IStatus,
  Status,
} from 'src/gen/squareup/messenger/v3/messenger_service';
import Logger from 'src/Logger';

/**
 * Utility method to throw an error and log it with Sentry.
 *
 * @param {string} message
 * Error message to display
 * @param {Record<string, ?>} [context]
 * Additional contextual information to log with the message
 */
export const throwError = (
  message: string,
  context?: Record<string, unknown>,
): never => {
  const error = new Error(message);
  Logger.logWithSentry(error, 'error', context);
  throw error;
};

/**
 * Generic handler for various error status codes
 *
 * @param {string} source
 * Source of the call to prepend any error message with
 * @param {Status} [status]
 * The status object returned as part of the API response
 * @param {Record<string, ?>} [context]
 * Any additional context to log with the response
 */
const handleResponseStatus = (
  source: string,
  status?: IStatus,
  context?: Record<string, unknown>,
): void => {
  if (!status) {
    throwError(`${source} - No status object returned.`, context);
  }

  const data = { ...context, debugText: status?.debugText };

  switch (status?.code) {
    case Status.Code.FAILED:
      throwError(`${source} - Request to API failed.`, data);
      break;
    case Status.Code.UNAUTHORIZED:
      throwError(
        `${source} - Request failed due to user being unauthorized.`,
        data,
      );
      break;
    case Status.Code.RATE_LIMITED:
      throwError(`${source} - Request failed due to rate limiting.`, data);
      break;
    default:
      break;
  }
};

export default handleResponseStatus;
